import { useQuery } from 'react-query';

import { eventbriteClient } from 'lib/apiClient';
import {
  IEventbriteGetOrgsRes, IEventbriteGetEventsRes, IEventbriteErrorRes, IEventbriteGetTicketsRes
} from 'types/Integration/index';

const EVENTBRITE_INTEGRATION = 'eventbriteIntegration';

export const getOrganizations = (token: string | null | undefined): Promise<IEventbriteGetOrgsRes> => eventbriteClient(token).getClient()
  .get('/users/me/organizations/')
  .then(res => res.data);

export const QUERY_KEY_EVENTBRITE_ORGS = [EVENTBRITE_INTEGRATION, 'getOrganizations'];
export function useGetOrganizations(token: string | null | undefined) {
  return useQuery<IEventbriteGetOrgsRes | undefined, IEventbriteErrorRes>(
    [...QUERY_KEY_EVENTBRITE_ORGS, token],
    () => getOrganizations(token),
    {
      enabled: !!token,
      retry: 2,
    }
  );
}

export const getEvents = (token: string | null | undefined, orgId: string | null): Promise<IEventbriteGetEventsRes> => eventbriteClient(token).getClient()
  .get(`/organizations/${orgId}/events/`)
  .then(res => res.data);

export const QUERY_KEY_EVENTBRITE_EVENTS = [EVENTBRITE_INTEGRATION, 'getEventsAfterOrgIsChosen'];
export function useGetEvents(token: string | null | undefined, orgId: string | null) {
  return useQuery<IEventbriteGetEventsRes | undefined, IEventbriteErrorRes>(
    [...QUERY_KEY_EVENTBRITE_EVENTS, token, orgId],
    () => getEvents(token, orgId),
    {
      enabled: ((orgId?.length || 0) > 0) && !!token,
      retry: 2,
    }
  );
}

export const getTickets = (token: string | null | undefined, eventId: string): Promise<IEventbriteGetTicketsRes> => eventbriteClient(token).getClient()
  .get(`/events/${eventId}/ticket_classes/`)
  .then(res => res.data);

export const QUERY_KEY_EVENTBRITE_TICKETS = [EVENTBRITE_INTEGRATION, 'getTickets'];
export function useGetTickets(token: string | null | undefined, eventId: string | null | undefined) {
  return useQuery<IEventbriteGetTicketsRes | undefined, IEventbriteErrorRes>(
    [...QUERY_KEY_EVENTBRITE_TICKETS, token, eventId],
    () => getTickets(token, eventId!),
    {
      enabled: ((eventId?.length || 0) > 0) && !!token,
      retry: 2,
    }
  );
}
