import React, { useMemo } from 'react';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import fabric from '../../../../../../../../components/Editor/helpers/fabric';
import { isDataUrl } from '../../../../../../../../components/Editor/helpers/file';
import css from './ImageContent.module.css';

export interface IImageContent {
  object: typeof fabric.ImageObject,
}

const ImageContent: React.FC<IImageContent> = ({ object }) => {
  const props = {
    src: object.src,
  };

  const hasUrl = useMemo(() => !isDataUrl(object.src), [object.src]);

  const onClick = () => window.open(object.src, '_blank');

  return (
    <div className={css.container}>
      <img alt={object.name} {...props} />
      { hasUrl ? (
        <Button
          buttonStyle="reducedHeight"
          buttonType="secondary"
          onClick={onClick}
        >{strings.opeImageUrlButton}</Button>
      ) : '' }
    </div>
  );
};

export default ImageContent;
