import { FormikErrors, FormikTouched } from 'formik';

import { TUser } from 'types';
import { IUserPatchInput } from 'hooks/useUser';
import { IChangePasswordValues } from './constants';

export const getFieldError = (
  fieldName: keyof IChangePasswordValues,
  touched: FormikTouched<IChangePasswordValues>,
  errors: FormikErrors<IChangePasswordValues>
): string | undefined => (touched[fieldName] && errors[fieldName]) || undefined;

export const prepareData = (user: TUser, values: IChangePasswordValues): IUserPatchInput => ({
  id: user.id,
  currentPassword: values.currentPassword,
  newPassword: values.newPassword
});
