import React from 'react';
import cls from 'classnames';

import Icon, { ICON_TYPES } from 'components/library/Icon';
import css from './SliderArrow.module.css';

interface ISliderArrow {
  type: 'next' | 'prev',
  className?: string,
  style?: {[key: string]: string | number},
  onClick?: (event: React.MouseEvent)=>void,
}

const SliderArrow: React.FC<ISliderArrow> = ({
  type, className = '', style = {}, onClick
}) => {
  const onCLickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={cls(className, css.arrow)}
      style={{ ...style }}
      onClick={onCLickHandler}
    >
      <Icon
        className={cls(css.arrowIcon, {
          [css.prevArrow]: type === 'prev',
          [css.nextArrow]: type === 'next',
        })}
        type={ICON_TYPES.arrowUp}
      />
    </div>
  );
};

export default SliderArrow;
