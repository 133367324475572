import React, { useMemo } from 'react';

import css from './Toolbar.module.css';
import useEditorData from '../../hooks/useEditorData';
import SideSelector from './components/SideSelector/SideSelector';
import ModeButton from './components/ModeButton';
import AddTextObjectButton from './components/AddTextObjectButton';
import AddCodeObjectButton from './components/AddCodeObjectButton';
import AddImageObjectButton from './components/AddImageObjectButton';
import ScaleSelector from './components/ScaleSelector';
import IntegrationMatching from './components/IntegrationMatching';
import AddNumberingObjectButton from './components/AddNumberingObjectButton';
import { ECanvasObjectTypes } from '../../../../../../types';

const Toolbar:React.FC = () => {
  const {
    pdfDocument,
    side,
    uploadingStatus,
    canvasLoadingStatus,
    frontJson,
    backJson,
  } = useEditorData();

  const isDisabled = useMemo<boolean>(
    () => !pdfDocument || !!uploadingStatus || canvasLoadingStatus,
    [pdfDocument, side, uploadingStatus, canvasLoadingStatus]
  );

  const isNumberingObjectAlreadyExists = useMemo<boolean>(
    () => !![...frontJson.objects || [], ...backJson?.objects || []].find(object => object.type === ECanvasObjectTypes.NUMBERING),
    [frontJson, backJson]
  );

  return (
    <div className={css.container}>
      <div className={css.actions}>
        <ModeButton isDisabled={isDisabled} />
        <AddTextObjectButton isDisabled={isDisabled} />
        <AddImageObjectButton isDisabled={isDisabled} />
        <AddCodeObjectButton isDisabled={isDisabled} />
        <AddNumberingObjectButton isDisabled={isDisabled || isNumberingObjectAlreadyExists} />
      </div>
      <div className={css.container}>
        <SideSelector />
        <ScaleSelector />
        <IntegrationMatching />
      </div>
    </div>
  );
};

export default Toolbar;
