import React, { useMemo, useState } from 'react';
import { ETemplateSides } from 'types';
import Button from 'components/library/Button';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Tooltip from 'components/library/Tooltip';
import Modal from 'components/library/Modal';
import strings from 'constants/localization';
import css from './DeleteButton.module.css';

export interface IDeleteButton {
  disabled?: boolean,
  side: ETemplateSides,
  onClick: (side: ETemplateSides) => void,
}

const DeleteButton: React.FC<IDeleteButton> = props => {
  const {
    disabled = false,
    side,
    onClick,
    ...rest
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const name = useMemo(() => {
    switch (side) {
      case ETemplateSides.FRONT:
        return strings.templateAccusativeSideNameFront;
      case ETemplateSides.BACK:
        return strings.templateAccusativeSideNameBack;
      default:
        return strings.templateAccusativeSideNameUnknown;
    }
  }, [side]);

  const handleClickDelete = () => {
    onClick(side);
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={strings.formatString(strings.templateDeleteModalTitle, name) as string}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        okLabel={strings.templateDeleteModalOkButton}
        onOk={handleClickDelete}
        okButtonProps={{ className: css.modalConfirm }}
      >
        <span>{strings.formatString(strings.templateDeleteModalContent, name)}</span>
      </Modal>
      <Tooltip title={strings.formatString(strings.templateDeleteButtonTooltip, name) as string} placement="top" arrow>
        <Button
          onClick={() => setIsModalOpen(true)}
          className={css.deleteButton}
          buttonType="secondary"
          disabled={disabled}
          {...rest}
        >
          <Icon type={ICON_TYPES.delete} />
        </Button>
      </Tooltip>
    </>
  );
};

export default DeleteButton;
