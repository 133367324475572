import React, { FocusEvent, useCallback, useMemo } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { FormikErrors } from 'formik';

import Input from 'components/library/Input';
import Sizes from 'components/PagePortfolio/AddEditPrintItem/components/ItemInformation/Sizes';

import {
  TPrintItemNestedField, TPrintItemSize
} from 'types';
import { IPrintItemFormik } from 'types/Portfolio';
import strings from 'constants/localization';
import { PRINT_ITEM_NESTED_FIELDS_MAPPER } from 'constants/constants';
import css from './ItemInformation.module.css';
import MultiSelectWithComment from '../MultiSelectWithComment';
import { getTouchedFieldError, handleMultiSelectChange, trimOnBlur } from '../../helpers';
import { getQuantityAndPaperError } from './helpers';
import { ITEM_INFORMATION } from '../../constants';

const ItemInformation: React.FC<IPrintItemFormik> = ({ formik }) => {
  const {
    setFieldValue,
    getFieldProps,
    touched: { itemInformation: itemInformationTouched },
    errors: { itemInformation: itemInformationErrors },
    values: { itemInformation: itemInformationValues, pricings },
    handleChange,
    setFieldTouched,
    handleBlur
  } = formik;

  const descriptionHelperText = useMemo<string>(
    () => strings.formatString(
      strings.inputDisplayCharactersLenHelper,
      itemInformationValues.description.length,
      120
    ) as string,
    [itemInformationValues.description]
  );

  const handleMultiSelect = useCallback(
    (selectValues: OnChangeValue<TPrintItemNestedField, true>, actionMeta: ActionMeta<TPrintItemNestedField>) => {
      const { name: fieldName } = actionMeta;
      if (fieldName) {
        const newValues = handleMultiSelectChange(selectValues, actionMeta, fieldName);
        if (newValues) {
          setFieldValue(fieldName, newValues);
        }
      }
    },
    [setFieldValue]
  );

  const printItemTitleOnBlur = (e: FocusEvent<HTMLInputElement>) => trimOnBlur({
    e, inputName: `${ITEM_INFORMATION}.title`, handleBlur, setFieldValue
  });

  return (
    <form noValidate>
      <Input
        className={css.input}
        label={strings.inputProductNameLabel}
        withError
        error={getTouchedFieldError(!!itemInformationTouched?.title, itemInformationErrors?.title)}
        placeholder={strings.inputProductNamePlaceholder}
        {...getFieldProps(`${ITEM_INFORMATION}.title`)}
        onBlur={printItemTitleOnBlur}
      />
      <Input
        className={css.input}
        inputClassName={css.description}
        withError
        error={getTouchedFieldError(!!itemInformationTouched?.description, itemInformationErrors?.description)}
        label={strings.inputDescriptionLabel}
        labelProps={{ optional: true }}
        placeholder={strings.inputDescriptionPlaceholder}
        description={descriptionHelperText}
        isMultiple
        rows={3}
        {...getFieldProps(`${ITEM_INFORMATION}.description`)}
      />
      {PRINT_ITEM_NESTED_FIELDS_MAPPER.map(({
        field, commentField, label, placeholder
      }) => {
        const itemInformationField = `${ITEM_INFORMATION}.${field}`;
        const onBlurHandler = () => setFieldTouched(itemInformationField, true);
        return (
          <MultiSelectWithComment
            key={field}
            selectFieldName={itemInformationField}
            selectFieldLabel={label()}
            selectFieldPlaceholder={placeholder()}
            selectFieldValue={itemInformationValues[field]}
            selectFieldError={getTouchedFieldError(!!itemInformationTouched?.[field], getQuantityAndPaperError(itemInformationErrors?.[field]))}
            commentFieldName={`${ITEM_INFORMATION}.${commentField}`}
            commentFieldValue={itemInformationValues[commentField]}
            commentFieldError={itemInformationErrors?.[commentField]}
            handleChange={handleMultiSelect}
            handleCommentChange={handleChange}
            handleBlur={onBlurHandler}
          />
        );
      })}
      <Sizes
        values={itemInformationValues}
        pricings={pricings}
        errors={itemInformationErrors?.sizes as FormikErrors<TPrintItemSize[]> | undefined}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        touched={itemInformationTouched}
        handleBlur={handleBlur}
      />
    </form>
  );
};

export default ItemInformation;
