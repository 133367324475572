import React from 'react';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import css from './Footer.module.css';

interface IFooter {
  onClickBack?: () => void,
  onCancel?: () => void,
  onApprove?: () => void,
}

const Footer:React.FC<IFooter> = ({ onClickBack, onApprove, onCancel }) => (
  <div className={css.footer}>
    {onCancel && <Button buttonType="quaternary" onClick={onCancel}>{strings.actionLabelCancel}</Button>}
    {onClickBack && (
      <>
        <div />
        <Button buttonType="primary" onClick={onClickBack}>{strings.actionLabelBack}</Button>
      </>
    )}
    {onApprove && <Button buttonType="primary" onClick={onApprove}>{strings.orderPageFooterButtonGoToCheckout}</Button>}
  </div>
);

export default Footer;
