import React, { useMemo } from 'react';
import cls from 'classnames';

import textCss from 'assets/styles/Text.module.css';
import css from './SelectItem.module.css';
import { ITicketType } from '../index';

interface ISelectItem {
  id: string,
  active: boolean,
  disabled: boolean,
  onSelect: (value: string) => void,
  title: string,
}

const SelectItem: React.FC<ISelectItem> = props => {
  const {
    id,
    active,
    disabled,
    onSelect,
    title,
  } = props;

  const containerStyles = useMemo(
    () => cls(css.container, { [css.active]: active, [css.disabled]: disabled }),
    [active, disabled]
  );

  const onClick = () => onSelect(id);

  return (
    <div
      key={id}
      className={containerStyles}
      onClick={onClick}
    >
      <div className={css.titleContainer}>
        <div className={cls(css.circle, { [css.circleActive]: active })} />
        <span className={cls(css.title, active ? textCss.h4 : textCss.pMedium2)}>{title}</span>
      </div>
      <div className={css.descriptionContainer}>
        {id}
      </div>
    </div>
  );
};

interface ISelectItemContainer {
  ticketType: ITicketType,
  matchingsTicket: ITicketType | null,
  chosenTicket: ITicketType | null,
  connectedTickets: string[],
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
}

const SelectItemContainer: React.FC<ISelectItemContainer> = props => {
  const {
    ticketType,
    matchingsTicket,
    chosenTicket,
    connectedTickets,
    setChosenTicket,
  } = props;

  const { id, name } = ticketType;

  const active = useMemo<boolean>(() => chosenTicket?.id === id, [chosenTicket, id]);
  const isDbTicket = useMemo<boolean>(() => matchingsTicket?.id === id, [matchingsTicket, id]);
  const isAlreadyConnected = useMemo<boolean>(
    () => connectedTickets.includes(id),
    [connectedTickets, id]
  );
  const disabled = useMemo(() => !active && !isDbTicket && isAlreadyConnected, [active, isDbTicket, isAlreadyConnected]);

  const onSelect = () => setChosenTicket(ticketType);

  return (
    <SelectItem
      id={id}
      title={name}
      active={active}
      disabled={disabled}
      onSelect={onSelect}
    />
  );
};

export default SelectItemContainer;
