/* eslint-disable max-len */
import { ILanguages } from '../types';
import IPortfolioStrings from '../types/PorfolioStrings';

const strings:ILanguages<IPortfolioStrings> = {
  en: {
    portfolioPageTitle: 'Portfolio',
    addPrintItemPageTitle: 'Add Print Item',
    editPrintItemPageTitle: 'Edit Print Item',

    portfolioPageButtonAddPrintItem: 'Add print item',

    printItemsDelete: 'Delete Print Item',
    printItemsDeleteConfirm: 'Do you really want to delete this Print Item?',

    emptyStateNoPrintItemsTitle: 'Looks like there are no Print Items yet',
    emptyStateNoPrintItemsDescription: "To get started, сlick on 'Add print item'",

    successResponsePrintItemDeleted: 'Print Item was successfully deleted',
    successResponsePrintItemCreated: 'Print Item was successfully created',
    successResponsePrintItemUpdated: 'Print Item was successfully updated',
    errorResponseUnableToDeletePrintItem: 'Unable to delete Print Item',
    errorResponseUnableToCreatePrintItem: 'Unable to create Print Item',
    errorResponseUnableToUpdatePrintItem: 'Unable to update Print Item',
    errorPrintItemFormValidation: 'Print Item form is not valid',
    errorPrintItemFormNoChanges: 'No changes in Print Item form',
    errorPrintItemFormSelectValueExists: "{0} with value '{1}' already exists",

    inputValidateUniqueSizeName: 'Size name is not unique',
    inputValidateNumberFormat: 'Max of numbers: 7 before and 2 after comma',
    inputValidateMinOnePaper: 'At least one Paper',
    inputValidateMinOneQuantity: 'At least one Quantity',
    inputValidateMinOneOptionValue: 'At least one Value',

    addPrintItemPageItemInformationTab: 'Item Information',
    addPrintItemPageOptionsTab: 'Options',
    addPrintItemPagePricingTab: 'Pricing',
    addPrintItemPageOptionalPricingTab: 'Optional Pricing',

    addOption: 'Add Option',
    printItemPageAddOptionModalAddButton: 'Add',
    addSize: 'Add Size',
  },
  de: {
    portfolioPageTitle: 'Druck-Portfolio',
    addPrintItemPageTitle: 'Druckprodukt anlegen',
    editPrintItemPageTitle: 'Druckprodukt konfigurieren',

    portfolioPageButtonAddPrintItem: 'Druckprodukt anlegen',

    printItemsDelete: 'Druckprodukt löschen',
    printItemsDeleteConfirm: 'Wollen Sie wirklich dieses Druckprodukt löschen?',

    emptyStateNoPrintItemsTitle: 'Bislang wurden keine Druckprodukte angelegt',
    emptyStateNoPrintItemsDescription: 'Über die Schaltfläche ‘Druckprodukt anlegen’ legen Sie universelle Druckprodukte wie Flyer oder Gutscheine an.',

    successResponsePrintItemDeleted: 'Das Druckprodukt wurde erfolgreich gelöscht',
    successResponsePrintItemCreated: 'Druckprodukt wurde erfolgreich angelegt',
    successResponsePrintItemUpdated: 'Druckprodukt wurde erfolgreich erweitert',
    errorResponseUnableToDeletePrintItem: 'Das Druckprodukt konnte nicht gelöscht werden, da es bereits in Artikeln verwendet wird',
    errorResponseUnableToCreatePrintItem: 'Das Druckprodukt konnte nicht angelegt werden',
    errorResponseUnableToUpdatePrintItem: 'Das Druckprodukt konnte nicht erweitert werden',
    errorPrintItemFormValidation: 'Mindestens ein Parameter des Druckprodukts ist ungültig',
    errorPrintItemFormNoChanges: 'Keine Änderungen des Druckprodukts',
    errorPrintItemFormSelectValueExists: "Der eingegebene Wert '{1}' ist doppelt (siehe {0})",

    inputValidateUniqueSizeName: 'Das Format gibt es bereits',
    inputValidateNumberFormat: 'Der eingegebene Preis ist zu groß (Es werden 7 Stellen vor und 2 Stellen nach dem Komma unterstützt)',
    inputValidateMinOnePaper: 'Es muss mindestens ein Papier konfiguriert werden',
    inputValidateMinOneQuantity: 'Es muss mindestens eine Auflage konfiguriert werden',
    inputValidateMinOneOptionValue: 'Es muss mindestens ein Wert konfiguriert werden',

    addPrintItemPageItemInformationTab: 'Basis-Daten',
    addPrintItemPageOptionsTab: 'Zusatzoptionen',
    addPrintItemPagePricingTab: 'Preise',
    addPrintItemPageOptionalPricingTab: 'Preise für Zusatzoptionen',

    addOption: 'Zusatzoption anlegen',
    printItemPageAddOptionModalAddButton: 'Anlegen',
    addSize: 'Format hinzufügen',
  },
  ru: {
    portfolioPageTitle: 'Портфолио',
    addPrintItemPageTitle: 'Создание печатного продукта',
    editPrintItemPageTitle: 'Редактировать печатный продукт ',

    portfolioPageButtonAddPrintItem: 'Создание печатного продукта',

    printItemsDelete: 'Удаление печатного продукта',
    printItemsDeleteConfirm: 'Вы действительно хотите удалить этот печатный продукт?',

    emptyStateNoPrintItemsTitle: 'Похоже, что печатная продукция еще не была создана',
    emptyStateNoPrintItemsDescription: 'Чтобы начать, нажмите на кнопку “Создание печатного продукта”',

    successResponsePrintItemDeleted: 'Печатный продукт  был успешно удален',
    successResponsePrintItemCreated: 'Печатный продукт был успешно создан',
    successResponsePrintItemUpdated: 'Печатный продукт был успешно обновлён',
    errorResponseUnableToDeletePrintItem: 'Не удается удалить печатный продукт ',
    errorResponseUnableToCreatePrintItem: 'Невозможно создать печатный продукт ',
    errorResponseUnableToUpdatePrintItem: 'Невозможно обновить печатный продукт ',
    errorPrintItemFormValidation: 'Форма печатного продукта недействительна',
    errorPrintItemFormNoChanges: 'В печатном продукте нет изменений',
    errorPrintItemFormSelectValueExists: "{0} со значением '{1}' уже существует",

    inputValidateUniqueSizeName: 'Формат с таким именем уже существует',
    inputValidateNumberFormat: 'Введенное значение цены слишком велико (поддерживается 7 цифр до и 2 цифры после запятой)',
    inputValidateMinOnePaper: 'Должна быть добавлена хотя бы одна бумага',
    inputValidateMinOneQuantity: 'Должен быть добавлен хотя бы один тираж',
    inputValidateMinOneOptionValue: 'Должно быть добавлено хотя бы одно значение',

    addPrintItemPageItemInformationTab: 'Информация о продукте',
    addPrintItemPageOptionsTab: 'Дополнительные опции',
    addPrintItemPagePricingTab: 'Цены',
    addPrintItemPageOptionalPricingTab: 'Цены для опций',

    addOption: 'Создать опцию',
    printItemPageAddOptionModalAddButton: 'Добавить',
    addSize: 'Добавление формата',
  },
};

export default strings;
