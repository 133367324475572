import { useMutation } from 'react-query';
import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';

const client = instance.getClient();

interface IResponseSuccess {
  success: boolean,
}

export interface ICreateRequestInput {
  email: string,
}
const createRequest = (data:ICreateRequestInput):Promise<IResponseSuccess> => client.post('/password', data).then(res => res.data);

export function useRequestResetPassword() {
  return useMutation<IResponseSuccess, IResponseError, ICreateRequestInput>(
    ['password', 'create-request'],
    data => createRequest(data)
  );
}

interface IUpdatePasswordInput {
  password: string,
  token: string
}
const updatePasswords = (data:IUpdatePasswordInput):Promise<IResponseSuccess> => client.patch('/password', data).then(res => res.data);

export function useResetPassword() {
  return useMutation<IResponseSuccess, IResponseError, IUpdatePasswordInput>(
    ['password', 'reset'],
    data => updatePasswords(data)
  );
}
