/* eslint-disable max-len */
import ILoginStrings from '../types/LoginStrings';
import { ILanguages } from '../types';

const strings:ILanguages<ILoginStrings> = {
  en: {
    loginPageLoginTitle: 'Log in',
    loginPageLoginKeepLoggedLabel: 'Keep me logged in',
    loginPageLoginButtonLogin: 'Log in',
    loginPageLoginButtonForgot: 'Forgot password?',
    loginPageRequestResetTitle: 'Reset password',
    loginPageRequestResetDescription: 'Enter the email you’ve registered with and we’ll send you the instructions there.',
    loginPageRequestResetButtonRequest: 'Send instructions',
    loginPageRequestLabelBackTo: 'Back to',
    loginPageRequestSentTitle: 'Check your email ...',
    loginPageRequestSentDescription: 'We have sent password recovery instructions to your email.',
    loginPageNewPassTitle: 'Create new password',
    loginPageNewPassButtonReset: 'Reset Password',
    loginNotifyPassHasBeenReset: 'Password has been successfully reset!',
    loginNotifyInvalidEmailPass: 'Invalid email or password',
    loginPageBlockedTitle: 'Your account has been suspended.',
    loginPageBlockedDescription: 'Please contact your print service provider {0} for more information.',
    loginPageRestrictedTitle: 'We are Sorry!',
    loginPageRestrictedDescription: 'You don’t have permission to view this page.',
  },
  de: {
    loginPageLoginTitle: 'Anmelden',
    loginPageLoginKeepLoggedLabel: 'Angemeldet bleiben',
    loginPageLoginButtonLogin: 'Anmelden',
    loginPageLoginButtonForgot: 'Zugangsdaten vergessen',
    loginPageRequestResetTitle: 'Neues Passwort anfordern ',
    loginPageRequestResetDescription: 'Geben Sie hier ihre Registrierungs-Email ein und wir senden Ihnen eine E-Mail zum Zurücksetzen ihres Passworts.',
    loginPageRequestResetButtonRequest: 'Passwort anfordern',
    loginPageRequestLabelBackTo: 'Zurück zur',
    loginPageRequestSentTitle: 'Überprüfen Sie ihren E-Mail-Eingang',
    loginPageRequestSentDescription: 'Wir haben Ihnen soeben eine E-Mail mit Anweisungen zum Zurücksetzten Ihres Passworts geschickt.',
    loginPageNewPassTitle: 'Neues Passwort vergeben',
    loginPageNewPassButtonReset: 'Passwort speichern',
    loginNotifyPassHasBeenReset: 'Dass Passwort wurde erfolgreich aktualisiert!',
    loginNotifyInvalidEmailPass: 'Ungültige Zugangsdaten',
    loginPageBlockedTitle: 'Ihr Konto wurde gesperrt',
    loginPageBlockedDescription: 'Bitte wenden Sie sich an ihren zuständigen Kundenkontakt {0} um weitere Informationen zu bekommen.',
    loginPageRestrictedTitle: 'Kein Zugriff!',
    loginPageRestrictedDescription: 'Ihnen fehlt die Berechtigung, die Inhalte dieser Seite zu sehen.',
  },
  ru: {
    loginPageLoginTitle: 'Вход в систему',
    loginPageLoginKeepLoggedLabel: 'Оставаться в системе',
    loginPageLoginButtonLogin: 'Войти в систему',
    loginPageLoginButtonForgot: 'Забыли пароль?',
    loginPageRequestResetTitle: 'Запросить новый пароль',
    loginPageRequestResetDescription: 'Введите адрес электронной почты, на которой вы зарегистрированы, и мы отправим вам электронное письмо для сброса вашего пароля.',
    loginPageRequestResetButtonRequest: 'Сброс пароля',
    loginPageRequestLabelBackTo: 'Вернуться к',
    loginPageRequestSentTitle: 'Проверьте свою электронную почту...',
    loginPageRequestSentDescription: 'Мы отправили инструкции по восстановлению пароля на вашу электронную почту.',
    loginPageNewPassTitle: 'Создать новый пароль',
    loginPageNewPassButtonReset: 'Сохранить пароль',
    loginNotifyPassHasBeenReset: 'Пароль был успешно обновлен!',
    loginNotifyInvalidEmailPass: 'Неверный адрес электронной почты или пароль',
    loginPageBlockedTitle: 'Ваша учетная запись была заблокирована.',
    loginPageBlockedDescription: 'Пожалуйста, свяжитесь с соответствующим контактным лицом {0} для получения дополнительной информации.',
    loginPageRestrictedTitle: 'Нам очень жаль!',
    loginPageRestrictedDescription: 'У вас нет разрешения на просмотр этой страницы.',
  },
};

export default strings;
