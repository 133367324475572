import React, { useRef, useState } from 'react';

import { NAME_ORDER_OPTIONS } from 'constants/constants';
import Icon from '../../../Icon';
import { E_ICON_TYPE } from '../../../Icon/types';
import Popover from '../../../Popover';
import Radio, { IRadioOption } from '../../../Radio';
import { TSort } from '../../types';
import css from './FilterHeader.module.css';
import SimpleHeader from '../SimpleHeader';

export interface IActionHeader {
  className: string,
  setOrder: (v: TSort) => void,
  title: string,
  order: TSort | null,
  orderOptions?: IRadioOption<TSort>[],
}
const FilterHeader = ({
  className, title, setOrder, order, orderOptions = NAME_ORDER_OPTIONS
}: IActionHeader) => {
  const [state, setState] = useState<boolean>(false);
  const ref = useRef<SVGSVGElement>(null);
  const handleFilterChange = (value: IRadioOption<TSort>) => {
    setOrder(value.value);
    setState(false);
  };
  const value = orderOptions.find(e => order && e.value[0] === order[0] && e.value[1] === order[1]);

  return (
    <div className={className}>
      <SimpleHeader title={title} />
      <Icon className={css.showIcon} passedRef={ref} type={E_ICON_TYPE.filter} onClick={() => setState(!state)} />
      <Popover
        open={state}
        anchorEl={ref.current}
        onClose={() => setState(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Radio<TSort>
          options={orderOptions}
          onChange={handleFilterChange}
          defaultValue={value}
        />
      </Popover>
    </div>
  );
};

export default FilterHeader;
