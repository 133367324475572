import { ROUTES } from 'constants/constants';

const Logout = () => {
  console.log('clear storage: logout');
  localStorage.removeItem('token');
  window.location.assign(ROUTES.USER_LOGIN);
  return null;
};

export default Logout;
