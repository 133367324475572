import React, {
  useCallback, useMemo, useState
} from 'react';
import debounce from 'lodash/debounce';

import PageContent from 'components/library/PageContent';
import Select from 'components/library/Select';
import strings from 'constants/localization';
import {
  BLOCK_FILTER_OPTIONS,
  DEFAULT_DEBOUNCE_DELAY,
  MIN_PAGE,
  PAGE_SIZE_OPTIONS,
  ROUTES,
} from 'constants/constants';
import { SingleValue } from 'react-select';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import Pagination from 'components/library/Pagination';
import Table from 'components/library/Table';
import Loader from 'components/library/Loader';
import { IUsersFindInput, useUsersFind } from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { TFilterBlocked, TUser } from 'types';
import { ICallback } from 'components/library/Table/components/ActionHeader';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import useColumns from './useColumns';
import css from './Printers.module.css';
import BlockUserModal from '../Modals/BlockUserModal';

function Printers() {
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [limit, setLimit] = useState<{ label: number, value: number }>(PAGE_SIZE_OPTIONS[0]);
  const [page, setPage] = useState<number>(1);
  const [filterBlocked, setFilterBlocked] = useState<TFilterBlocked | undefined>(BLOCK_FILTER_OPTIONS[1]);
  const [userToBlock, setUserToBlock] = useState<TUser | undefined>(undefined);
  const [search, setSearch] = useState('');

  const onToggleSearchActive = useCallback(() => setSearchActive(prev => !prev), [setSearchActive]);
  const onChangePage = useCallback((event:any, value:number) => setPage(value), [setPage]);
  const onChangeLimit = useCallback((value:SingleValue<{ label: number, value: number}>) => {
    setLimit(value || PAGE_SIZE_OPTIONS[0]);
    setPage(1);
  }, [setLimit, setPage]);
  const onSearch = useCallback(debounce(({ target: { value } }) => {
    setSearch(value);
    setPage(MIN_PAGE);
  }, DEFAULT_DEBOUNCE_DELAY), [setSearch]);
  const onClickRow = useCallback<ICallback>(id => navigate(id), [navigate]) as (id: string, row: unknown) => void;
  const onClickAddPrinter = useCallback(() => navigate(ROUTES.ROUTE_ADD), [navigate]);
  const onBlock = useCallback<ICallback>((id, user) => setUserToBlock(user), []);
  const closeBlockModal = useCallback(() => setUserToBlock(undefined), [setUserToBlock]);

  const columns = useColumns({ onBlock, filterBlocked, setFilterBlocked });
  const limitValue = limit?.value || PAGE_SIZE_OPTIONS[0].value;

  const query = useMemo<IUsersFindInput>(() => ({
    limit: limitValue,
    skip: (page - 1) * limitValue,
    search,
    blocked: filterBlocked?.value,
    role: 'printer',
    sort: ['createdAt', -1]
  }), [page, limitValue, search, filterBlocked]);

  const { data = { data: [], total: 0 }, isLoading, error } = useUsersFind(query);
  const disabled:boolean = isLoading || !!error;

  return (
    <PageContent pageTitle={strings.printersPageTitle}>
      <div className={css.pageContainer}>
        <div className={css.header}>
          <Select isDisabled={disabled} className={css.select} value={limit} onChange={onChangeLimit} options={PAGE_SIZE_OPTIONS} />
          <div className={css.right}>
            <Input
              className={css.search}
              searchActive={searchActive}
              toggleSearchActive={onToggleSearchActive}
              onChange={onSearch}
            />
            <Button buttonType="primary" onClick={onClickAddPrinter}>{strings.printersButtonAddPrinter}</Button>
          </div>
        </div>
        <div className={css.table}>
          <Table columns={columns} data={data.data} meta={{ onClickRow }} />
        </div>
        {!isLoading && data.data.length === 0 ? (
          <div className={css.noContent}>
            <EmptyState label={strings.emptyStateTitleNoResults} description={strings.emptyStateTitleNoResultsDescription} variant={EmptyStates.NO_RESULTS} />
          </div>
        ) : null}
        {isLoading && <div className={css.loader}><Loader /></div> }
        <Pagination
          className={css.pagination}
          disabled={disabled}
          page={page}
          count={Math.ceil(data.total / limitValue) || undefined}
          onChange={onChangePage}
        />
        <BlockUserModal
          isOpen={!!userToBlock}
          closeModal={closeBlockModal}
          userToBlock={userToBlock}
        />
      </div>
    </PageContent>
  );
}

export default Printers;
