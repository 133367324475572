import strings from 'constants/localization';
import { EOrderStatus, ERoles } from 'types';
import { ITab } from '../library/Tabs';
import css from './Orders.module.css';
import { TSort } from '../library/Table/types';
import { IRadioOption } from '../library/Radio';

export const TABS:ITab<EOrderStatus | 'ALL'>[] = [
  {
    value: 'ALL',
    label: () => strings.ordersPageStatusAll,
  },
  {
    value: EOrderStatus.CREATED,
    label: () => strings.orderStatusCreated,
  },
  {
    value: EOrderStatus.RECEIVED,
    label: () => strings.orderStatusReceived,
  },
  {
    value: EOrderStatus.SEND_TO_PRINTER,
    label: () => strings.orderStatusSendToPrinter,
  },
  {
    value: EOrderStatus.IN_PRINTING,
    label: () => strings.orderStatusInPrinting,
  },
  {
    value: EOrderStatus.IN_DELIVERY,
    label: () => strings.orderStatusInDelivery,
  },
  {
    value: EOrderStatus.ASSUMED_DELIVERED,
    label: () => strings.orderStatusAssumedDelivered,
  },
  {
    value: EOrderStatus.ARCHIVED,
    label: () => strings.orderStatusArchived,
  },
  {
    value: EOrderStatus.ISSUE,
    label: () => strings.orderStatusIssue,
  }
];

export const getTabValue = (index: number):EOrderStatus|undefined => {
  const tab = TABS[index];
  if (tab.value === 'ALL') return undefined;
  return tab.value;
};

export enum EColumnId {
  SHOW_NESTED = 'showNested',
  TITLE = 'title',
  STATUS = 'status',
  COST = 'cost',
  CREATED_AT = 'createdAt',
  CUSTOMER = 'customer',
  MANAGER = 'manager',
  READY_TO_PRINT = 'readyToPrint',
}

export const ROLE_TO_COLUMNS: Record<ERoles, EColumnId[]> = {
  [ERoles.ADMIN]: [],
  [ERoles.PRINTER]: Object.values(EColumnId),
  [ERoles.MANAGER]: Object.values(EColumnId).filter(col => col !== EColumnId.MANAGER),
  [ERoles.CUSTOMER]: [EColumnId.SHOW_NESTED, EColumnId.TITLE, EColumnId.STATUS, EColumnId.COST, EColumnId.CREATED_AT, EColumnId.READY_TO_PRINT],
};

export const ROLE_TO_COLUMN_STYLE: Record<ERoles, string> = {
  [ERoles.ADMIN]: '',
  [ERoles.PRINTER]: '',
  [ERoles.MANAGER]: css.managerViewColumn,
  [ERoles.CUSTOMER]: css.customerViewColumn,
};

export const USER_ORDER_OPTIONS = (key: 'manager' | 'customer'): IRadioOption<TSort>[] => [
  {
    label: () => strings.sortByNameAsc,
    domValue: `${key}sortByNameAsc`,
    value: [key, 1]
  },
  {
    label: () => strings.sortByNameDesc,
    domValue: `${key}sortByNameDesc`,
    value: [key, -1]
  }
];

export const ID_ORDER_OPTIONS: IRadioOption<TSort>[] = [
  {
    label: () => strings.sortByIdDesc,
    domValue: 'sortByIdDesc',
    value: ['id', -1]
  },
  {
    label: () => strings.sortByIdAsc,
    domValue: 'sortByIdAsc',
    value: ['id', 1]
  }
];

export const DATE_ORDER_OPTIONS: IRadioOption<TSort>[] = [
  {
    label: () => strings.sortByDateDesc,
    domValue: 'sortByDateDesc',
    value: ['createdAt', -1]
  },
  {
    label: () => strings.sortByDateAsc,
    domValue: 'sortByDateAsc',
    value: ['createdAt', 1]
  }
];

interface IProcessedOrders {
  idOrder: null | TSort,
  dateOrder: null | TSort,
  customerOrder: null | TSort,
  managerOrder: null | TSort,
}
export const processOrder = (order: TSort) => {
  const [key] = order;
  const res:IProcessedOrders = {
    idOrder: null,
    dateOrder: null,
    customerOrder: null,
    managerOrder: null,
  };
  if (key === 'id') res.idOrder = order;
  if (key === 'manager') res.managerOrder = order;
  if (key === 'customer') res.customerOrder = order;
  if (key === 'createdAt') res.dateOrder = order;
  return res;
};

export const createTableSubRows = <T extends { subRows?: T[] }> (data: {[key:string]: T[]}): T[] => Object.keys(data).map(key => {
  const dataToDisplay = data[key];
  const tableItem = { ...dataToDisplay[0] };
  if (dataToDisplay.length > 1) {
    tableItem.subRows = dataToDisplay;
  }
  return tableItem;
});
