import React, { useContext } from 'react';

import Error from 'components/library/Error';
import UserContext from 'contexts/ContextUser';
import { useUsersFind } from 'hooks/useUser';
import Loader from 'components/library/Loader';
import { TIncludedCustomer, TUser } from 'types';
import ReassignTable from './ReassignTable';
import { IModalContent } from './ModalContent';

interface IReassignCustomersTableWrapper {
  customers: TIncludedCustomer[],
  pspId: TUser['pspId'],
  managerId: TUser['id'],
  reassignedCustomers: IModalContent['reassignedCustomers'],
  setReassignedCustomers: IModalContent['setReassignedCustomers']
}
const ReassignTableWrapper: React.FC<IReassignCustomersTableWrapper> = ({
  pspId, customers, managerId, reassignedCustomers, setReassignedCustomers
}) => {
  const {
    data: managers = { data: [] },
    isLoading,
    error
  } = useUsersFind({
    includeCustomers: true,
    limit: 100,
    blocked: false,
    role: 'manager',
    pspId,
  });

  const printer = useContext(UserContext).user as TUser;

  if (isLoading) {
    return (<Loader />);
  }
  if (error) {
    return (
      <Error
        code={error.status}
        title={error.response?.data.className}
        description={error.response?.data.data?.reason || error.response?.data.message}
      />
    );
  }
  return (
    <ReassignTable
      customers={customers}
      managers={managers.data}
      printer={printer}
      managerId={managerId}
      reassignedCustomers={reassignedCustomers}
      setReassignedCustomers={setReassignedCustomers}
    />
  );
};

export default ReassignTableWrapper;
