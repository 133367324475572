import React, { useMemo } from 'react';
import cls from 'classnames';
import { IAlertAction } from 'contexts/ContextAlert';
import cssText from 'assets/styles/Text.module.css';
import Icon from 'components/library/Icon';
import css from './Alert.module.css';
import { E_ICON_TYPE } from '../Icon/types';

const Alert:React.FC<IAlertAction> = ({ message, severity, onClose }) => {
  const type = useMemo<E_ICON_TYPE>(() => {
    if (severity === 'success') return E_ICON_TYPE.correct;
    if (severity === 'error') return E_ICON_TYPE.error;
    if (severity === 'warning') return E_ICON_TYPE.warning;
    return E_ICON_TYPE.correct;
  }, [severity]);
  return (
    <div
      onClick={onClose}
      className={cls(css.alert, {
        [css.error]: severity === 'error',
        [css.success]: severity === 'success',
        [css.info]: severity === 'info',
        [css.warning]: severity === 'warning',
      })}
    >
      <div className={css.severityIcon}>
        <Icon type={type} />
      </div>
      <div className={cls(css.message, cssText.h5)}>{message}</div>
      <div className={css.closeIcon}>
        <Icon type={E_ICON_TYPE.close} />
      </div>
    </div>
  );
};

export default Alert;
