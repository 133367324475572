import React, { useMemo, useState } from 'react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import Input from 'components/library/Input';
import Select from 'components/library/Select';
import {
  OptionProps, components as rsComponents, GroupBase, SingleValueProps
} from 'react-select';
import css from './Tab.module.css';
import {
  IFormPersonalInfoValues, IFormsValues, Option, PERSONAL_INFO_FORM, PROFILE_LANGUAGE_OPTIONS
} from '../constants';
import Label from '../../library/Label';
import Icon from '../../library/Icon';
import Button from '../../library/Button';
import ChangePasswordModal from './ChangePasswordModal';
import { TUser } from '../../../types';
import { isAppEventbriteManager } from '../../../helpers';

interface ITabPersonalInfo {
  formik: FormikProps<IFormsValues>,
  readOnly: boolean,
  disabled?: boolean,
  isSelf: boolean,
  currentUser: TUser,
}
const getFieldName = (fieldName: keyof IFormPersonalInfoValues): string => `${PERSONAL_INFO_FORM}.${fieldName}`;
const getFieldError = (
  fieldName: keyof Omit<IFormPersonalInfoValues, 'lang'>,
  touched?: FormikTouched<IFormPersonalInfoValues>,
  errors?: FormikErrors<Omit<IFormPersonalInfoValues, 'lang'>>
): string | undefined => {
  const error = touched && touched[fieldName] && errors && errors[fieldName];
  return error || undefined;
};

const components = {
  SingleValue: ({ children, ...props }:SingleValueProps<Option>) => {
    const { icon } = props.data;
    return (
      <rsComponents.SingleValue<Option, boolean, GroupBase<Option>> {...props}>
        <div className={css.option}>
          {icon && <Icon className={css.optionIcon} type={icon} />}
          {children}
        </div>
      </rsComponents.SingleValue>
    );
  },
  Option: ({ children, ...props }:OptionProps<Option>) => {
    const { icon } = props.data;
    return (
      <rsComponents.Option<Option, boolean, GroupBase<Option>> {...props}>
        <div className={css.option}>
          {icon && <Icon className={css.optionIcon} type={icon} />}
          {children}
        </div>
      </rsComponents.Option>
    );
  }
};

const TabPersonalInfo:React.FC<ITabPersonalInfo> = ({
  readOnly, disabled, isSelf, formik, currentUser
}) => {
  const {
    getFieldProps, setFieldValue, setFieldTouched,
    touched: { PERSONAL_INFO_FORM: touched },
    errors: { PERSONAL_INFO_FORM: errors },
    values: { PERSONAL_INFO_FORM: values }
  } = formik;
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);

  const isAppUser = useMemo(
    () => isAppEventbriteManager(currentUser.userPermissions),
    [currentUser.userPermissions]
  );

  const surnameInputLabel = useMemo(
    () => isAppUser
      ? strings.profilePageInputEventDatesLabel
      : strings.profilePageInputLastNameLabel,
    [isAppUser]
  );

  return (
    <><span className={cls(textCss.pLight1)}>{strings.profileTabPersonalInfo}</span>
      <form noValidate>
        <Input
          {...getFieldProps(getFieldName('name'))}
          withError
          readOnly={readOnly}
          disabled={disabled}
          error={getFieldError('name', touched, errors)}
          className={css.input}
          label={strings.profilePageInputNameLabel}
          type="text"
          placeholder={strings.profilePageInputNameLabel}
        />
        <Input
          {...getFieldProps(getFieldName('surname'))}
          withError
          readOnly={readOnly}
          disabled={disabled}
          error={getFieldError('surname', touched, errors)}
          className={css.input}
          label={surnameInputLabel}
          type="text"
          placeholder={surnameInputLabel}
        />
        <Input
          {...getFieldProps(getFieldName('email'))}
          withError
          readOnly={readOnly}
          disabled={disabled}
          error={getFieldError('email', touched, errors)}
          className={css.input}
          label={strings.inputEmailLabel}
          type="email"
          placeholder={strings.inputEmailPlaceholder}
        />
        <Input
          {...getFieldProps(getFieldName('phoneNumber'))}
          withError
          readOnly={readOnly}
          disabled={disabled}
          error={getFieldError('phoneNumber', touched, errors)}
          className={css.input}
          label={strings.profilePageInputPhoneNumberLabel}
          type="tel"
          placeholder={strings.profilePageInputPhoneNumberLabel}
          labelProps={{ optional: true }}
        />
        <Label text={strings.profilePageInputLanguageLabel} />
        <Select<Option>
          isSearchable={false}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          menuShouldBlockScroll
          name={getFieldName('lang')}
          placeholder={strings.profilePageInputLanguageLabel}
          options={PROFILE_LANGUAGE_OPTIONS}
          onChange={value => setFieldValue(getFieldName('lang'), value)}
          onBlur={() => setFieldTouched(getFieldName('lang'), true)}
          getOptionLabel={option => option.label()}
          value={values.lang}
          components={components}
        />
      </form>
      {isSelf && (
        <Button
          className={css.changePasswordButton}
          buttonType="secondary"
          onClick={() => setChangePasswordModal(true)}
        >
          {strings.profileOpenChangePasswordModal}
        </Button>
      )}
      <ChangePasswordModal isOpen={changePasswordModal} setIsOpen={setChangePasswordModal} />
    </>
  );
};

export default TabPersonalInfo;
