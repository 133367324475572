import React, { useMemo } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { ECanvasObjectTypes } from 'types';
import fabric from '../../../../../../../components/Editor/helpers/fabric';
import css from './Content.module.css';
import TextContent from './components/TextContent';
import ImageContent from './components/ImageContent';
import CodeContent from './components/CodeContent';
import NumberingContent from './components/NumberingContent';

export interface IContent {
  object: typeof fabric.TextObject | typeof fabric.CodeObject | typeof fabric.ImageObject,
}

const Content: React.FC<IContent> = ({ object }) => {
  const icon = useMemo(() => {
    switch (object.type) {
      case ECanvasObjectTypes.TEXT:
        return ICON_TYPES.text;
      case ECanvasObjectTypes.NUMBERING:
        return ICON_TYPES.numbering;
      case ECanvasObjectTypes.IMAGE:
        return ICON_TYPES.image;
      case ECanvasObjectTypes.CODE:
        return ICON_TYPES.code;
      default:
        return ICON_TYPES.text;
    }
  }, [object.type]);

  const content = useMemo(() => {
    switch (object.type) {
      case ECanvasObjectTypes.TEXT:
        return <TextContent object={object} />;
      case ECanvasObjectTypes.NUMBERING:
        return <NumberingContent object={object} />;
      case ECanvasObjectTypes.IMAGE:
        return <ImageContent object={object} />;
      case ECanvasObjectTypes.CODE:
        return <CodeContent object={object} />;
      default:
        return object.name;
    }
  }, [object.type]);

  return (
    <Accordion
      disableGutters
      className={css.container}
      classes={{
        expanded: css.expanded,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: css.expandIconExpanded,
          content: css.summaryContent,
          expandIconWrapper: css.expandIconWrapper,
        }}
      >
        <div className={css.summaryIcon}>
          <Icon type={icon} />
        </div>
        <div className={css.summaryTitle}>
          { object.name }
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: css.details,
        }}
      >
        { content }
      </AccordionDetails>
    </Accordion>
  );
};

export default Content;
