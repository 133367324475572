import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import classNames from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import UserContext from 'contexts/ContextUser';
import {
  ECanvasObjectTypes, ERoles, ETemplateSides, TArticleDocument
} from 'types';
import strings from 'constants/localization';
import ContextAlert from 'contexts/ContextAlert';
import Button from 'components/library/Button';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import Tooltip from 'components/library/Tooltip';
import fabric, { fileToFabricSide } from '../../../../../../components/Editor/helpers/fabric';
import { IPdfBox } from '../../../../../../components/Editor/contexts/EditorContext';
import { isDataUrl } from '../../../../../../components/Editor/helpers/file';
import { dataUrlToAwsLink } from '../../../../../../components/Editor/helpers/aws';
import UploadArea from '../../../../../../components/Editor/components/UploadArea';
import UploadingLoader from '../../../../../../components/Editor/components/UploadingLoader';
import createPdfTemplate from '../../../../../../components/Editor/helpers/pdfTemplate';
import css from './Side.module.css';
import Thumbnail from './Thumbnail';
import Content from './Content';
import DeleteButton from './DeleteButton';

export interface ISide {
  side: ETemplateSides,
  pdfBox: IPdfBox,
  json: TArticleDocument['canvas'] | undefined,
  sideData: TArticleDocument | undefined,
  thumbnail: string,
  onSideDelete: (side: ETemplateSides) => void,
  onSideUpload: (newSide: TArticleDocument) => void,
  onChangeUploadStatus: (side: ETemplateSides, status: boolean) => void,
  disabled: boolean,
}

const Side: React.FC<ISide> = ({
  side,
  pdfBox,
  json,
  sideData,
  thumbnail,
  onSideDelete,
  onSideUpload,
  onChangeUploadStatus,
  disabled,
}) => {
  const { push } = useContext(ContextAlert);
  const contextUser = useContext(UserContext);
  const pspId = contextUser.user?.pspId;
  const role = contextUser.user?.role;

  const [objects, setObjects] = useState<typeof fabric.TextObject[] | typeof fabric.CodeObject[] | typeof fabric.ImageObject[]>(json?.objects ?? []);
  useEffect(() => setObjects(json?.objects ?? []), [json?.objects]);
  const unlockedObjects = useMemo<typeof fabric.TextObject[] | typeof fabric.CodeObject[] | typeof fabric.ImageObject[]>(
    () => objects.filter(({ locked }) => !locked),
    [objects]
  );

  const name = useMemo(() => {
    switch (side) {
      case ETemplateSides.FRONT:
        return strings.templateSideNameFront;
      case ETemplateSides.BACK:
        return strings.templateSideNameBack;
      default:
        return strings.templateSideNameUnknown;
    }
  }, [side]);

  const ref = useRef<HTMLDivElement>(null);

  const handleChangeUploadArea = async (file: File) => {
    setUploadingStatus(strings.templateLoadingStatus);
    try {
      const data = await fileToFabricSide(file, pdfBox, side, push);
      const uploadedData = await upload({ side, ...data });
      setUploadingStatus(false);
      push({ severity: 'success', message: strings.templateLoadingStatusDone });
      onSideUpload(uploadedData);
    } catch (e) {
      setUploadingStatus(false);
      const { message } = e as Error;
      push({ severity: 'error', message, timeout: 5000 });
    }
  };

  const [uploadingStatus, setUploadingStatus] = useState<boolean|string>(false);
  useEffect(() => onChangeUploadStatus(side, !!uploadingStatus), [uploadingStatus]);

  // TODO: move to Article AddEdit form save step
  const upload = async (data: TArticleDocument) => {
    if (!pspId) {
      return data;
    }

    setUploadingStatus(strings.templateLoadingStatusThumbnail);
    data.thumbnail = await dataUrlToAwsLink(data.thumbnail, `${data.side}-thumbnail`, pspId);

    // saving PDF file
    if (data.document.src && isDataUrl(data.document.src)) {
      setUploadingStatus(strings.templateLoadingStatusPdf);
      data.document.src = await dataUrlToAwsLink(data.document.src, `${data.side}-pdf`, pspId);
    }

    // saving background image from pdf file
    if (isDataUrl(data.canvas.backgroundImage.src)) {
      setUploadingStatus(strings.templateLoadingStatusBackground);
      data.canvas.backgroundImage.src = await dataUrlToAwsLink(data.canvas.backgroundImage.src, `${data.side}-background`, pspId);
    }

    // objectsList>ImageObject
    if (data.canvas.objects) {
      const objectsList = data.canvas.objects
        .filter((obj: any) => obj.type === ECanvasObjectTypes.IMAGE && isDataUrl(obj.src));

      await Promise.all(
        objectsList.map(async (object: any) => {
          setUploadingStatus(strings.formatString(strings.templateLoadingStatusImage, object.name) as string);
          object.src = await dataUrlToAwsLink(object.src, `${data.side}-image`, pspId);
          return object;
        })
      );
    }

    setUploadingStatus(false);
    return data;
  };

  const [processing, setProcessing] = useState<boolean>(false);
  const processPdf = () => {
    if (role === ERoles.PRINTER && sideData?.document?.src && pspId) {
      setProcessing(true);
      push({ severity: 'info', message: 'Started Re-Processing' });
      fetch(new URL(sideData?.document?.src))
        .then(response => response.blob())
        .then(blob => createPdfTemplate(pdfBox, blob as File))
        .then(base64 => dataUrlToAwsLink(base64, `${side}-pdf`, pspId)
          .then(src => onSideUpload({ ...sideData, document: { ...sideData.document, src } })))
        .then(() => push({ severity: 'success', message: 'Processed Successfully' }))
        .catch(() => push({ severity: 'error', message: 'Processing Error' }))
        .finally(() => setProcessing(false));
    }
  };

  const renderElement = () => {
    if (uploadingStatus) return <UploadingLoader status={uploadingStatus} />;
    if (json) {
      return (
        <>
          { !!thumbnail && <Thumbnail className={css.thumbnail} src={thumbnail} alt={strings.templateSideThumbnailAlt} /> }
          <div className={css.actions}>
            { !disabled && <DeleteButton onClick={onSideDelete} side={side} /> }
          </div>
        </>
      );
    }
    return !disabled
      ? <UploadArea side={side} handleChange={handleChangeUploadArea} />
      : strings.templateSideDisabledText;
  };

  return (
    <div className={css.container}>
      <div className={css.side} ref={ref}>
        <div className={css.viewport}>
          { renderElement() }
        </div>
        <div className={css.title}>
          { name }

          { role === ERoles.PRINTER && sideData?.document?.src && (
            <div className={css.reprocess}>
              <div>
                <Tooltip title={strings.templateSideOpenCurrentPdf} placement="top">
                  <a
                    role="button"
                    aria-label={strings.templateSideOpenCurrentPdf}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'flex' }}
                    href={sideData?.document?.src}
                  >
                    <Icon width={20} height={20} type={E_ICON_TYPE.pdf} />
                  </a>
                </Tooltip>
              </div>
              <div>
                <Tooltip title={strings.templateSideReprocessPdf} placement="top">
                  <Button
                    aria-label={strings.templateSideReprocessPdf}
                    buttonType="tertiary"
                    buttonStyle="square"
                    disabled={processing}
                    onClick={processPdf}
                  >
                    <Icon width={16} height={16} type={E_ICON_TYPE.reprocess} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
      {
        !!unlockedObjects.length
          && (
            <div className={css.contentContainer}>
              <span className={textCss.pLight1}>{strings.templateSideContentTitle}</span>
              <div className={css.content}>
                <span className={classNames(textCss.pMedium2, css.contentSubtitle)}>{ name }</span>
                <div className={css.contentList}>
                  { unlockedObjects.map(object => <Content key={object.id} object={object} />) }
                </div>
              </div>
            </div>
          )
      }
    </div>
  );
};

export default Side;
