import React from 'react';

import Button from 'components/library/Button';

import strings from 'constants/localization';
import css from './CustomControls.module.css';

interface IAddCustomerModalControls {
  closeModal: () => void,
  onSaveForLater: () => void,
  onSaveRedirect: () => void,
  isSubmitting: boolean,
}

const AddCustomerModalControls: React.FC<IAddCustomerModalControls> = ({
  closeModal, onSaveForLater, onSaveRedirect, isSubmitting
}) => (
  <div className={css.controls}>
    <Button
      type="button"
      buttonType="secondary"
      onClick={closeModal}
    >
      {strings.actionLabelCancel}
    </Button>
    <div className={css.submitButtons}>
      <Button
        type="submit"
        buttonType="secondary"
        onClick={onSaveForLater}
        disabled={isSubmitting}
      >
        {strings.actionLabelSaveForLater}
      </Button>
      <Button
        type="button"
        buttonType="primary"
        onClick={onSaveRedirect}
      >
        {strings.customersPageAddNewCustomerModalButtonNext}
      </Button>
    </div>
  </div>
);

export default AddCustomerModalControls;
