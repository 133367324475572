import React, { useContext, useMemo } from 'react';
import { ROUTES } from 'constants/constants';
import {
  matchRoutes,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
} from 'react-router-dom';
import UserContext from 'contexts/ContextUser';
import { EPermissions } from 'types';

interface IProtectedRouteMap {
  [key: string]: {
    path: string
    permissions: {
      allowed: EPermissions[],
      forbidden: EPermissions[],
    }
  }
}

// todo: find solution to link this map and Routes in Router to avoid an error below.
const MAP: IProtectedRouteMap = {
  BASE: {
    path: ROUTES.BASE,
    permissions: {
      allowed: Object.values(EPermissions),
      forbidden: []
    }
  },
  PORTFOLIO: {
    path: `/${ROUTES.PORTFOLIO}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    }
  },
  PRINT_ITEM_ADD: {
    path: `${ROUTES.PORTFOLIO}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    },
  },
  PRINT_ITEM_ID: {
    path: `${ROUTES.PORTFOLIO}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    },
  },
  PROFILE: {
    path: `/${ROUTES.PROFILE}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.CUSTOMER, EPermissions.ADMIN],
      forbidden: [EPermissions.APP_EVENTBRITE]
    }
  },
  CUSTOMERS: {
    path: `/${ROUTES.CUSTOMERS}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  CUSTOMERS_ID: {
    path: `/${ROUTES.CUSTOMERS}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  CUSTOMERS_ADD: {
    path: `/${ROUTES.CUSTOMERS}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  PRINTERS: {
    path: `/${ROUTES.PRINTERS}`,
    permissions: {
      allowed: [EPermissions.ADMIN],
      forbidden: []
    }
  },
  PRINTERS_ID: {
    path: `/${ROUTES.PRINTERS}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.ADMIN],
      forbidden: []
    }
  },
  PRINTERS_ADD: {
    path: `/${ROUTES.PRINTERS}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.ADMIN],
      forbidden: []
    }
  },
  ORDERS: {
    path: `/${ROUTES.ORDERS}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.CUSTOMER],
      forbidden: []
    }
  },
  ORDERS_ID: {
    path: `/${ROUTES.ORDERS}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.CUSTOMER],
      forbidden: []
    }
  },
  ORDERS_ADD: {
    path: `/${ROUTES.ORDERS}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.CUSTOMER],
      forbidden: []
    }
  },
  MANAGERS: {
    path: `/${ROUTES.MANAGERS}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    }
  },
  MANAGERS_ID: {
    path: `/${ROUTES.MANAGERS}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    }
  },
  MANAGERS_ADD: {
    path: `/${ROUTES.MANAGERS}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER],
      forbidden: []
    }
  },
  ARTICLES: {
    path: `/${ROUTES.ARTICLES}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.CUSTOMER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  ARTICLE_ADD: {
    path: `/${ROUTES.ARTICLES}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  ARTICLE_ADD_SELECT_PRINT_ITEM: {
    path: `/${ROUTES.ARTICLES}/${ROUTES.ROUTE_ADD}/${ROUTES.ARTICLES_SELECT_PRINT_ITEM}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  ARTICLE_EDIT_VIEW: {
    path: `/${ROUTES.ARTICLES}/${ROUTES.PARAM_ID}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.CUSTOMER],
      forbidden: []
    }
  },
  ARTICLE_EDIT_TEMPLATE_EDITOR: {
    path: `/${ROUTES.ARTICLES}/${ROUTES.PARAM_ID}/${ROUTES.TEMPLATE_EDITOR}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
  ADD_TO_ORDER: {
    path: `/${ROUTES.ORDERS}/${ROUTES.ROUTE_ADD}`,
    permissions: {
      allowed: [EPermissions.PRINTER, EPermissions.MANAGER],
      forbidden: []
    }
  },
};

const mapValues = Object.values(MAP);
const routes = mapValues.map(e => ({ path: e.path }));
const permissionMap = new Map<RouteObject, { allowed: EPermissions[], forbidden: EPermissions[] }>(routes.map((route, i) => [route, mapValues[i].permissions]));

const checkUserPermissions = (allowedPermissions: EPermissions[], forbiddenPermissions: EPermissions[], permissions: EPermissions[]): boolean => {
  const foundPermissions = permissions.filter(value => allowedPermissions.includes(value));
  const foundForbiddenPermissions = permissions.filter(value => forbiddenPermissions.includes(value));
  return !!foundPermissions.length && foundForbiddenPermissions.length === 0;
};

const ProtectedRoutes = (): JSX.Element => {
  const context = useContext(UserContext);
  const location = useLocation();
  const match = matchRoutes(routes, location);

  const [allowedPermissions, forbiddenPermissions] = useMemo(() => {
    let allowed: EPermissions[] = [];
    let forbidden: EPermissions[] = [];
    if (match) {
      const result = permissionMap.get(match[0].route) || { allowed: [], forbidden: [] };
      allowed = result.allowed;
      forbidden = result.forbidden;
    }
    if (process.env.NODE_ENV === 'development' && (!match || !allowed.length)) {
      // todo: error will not throw if an forgotten route has overload with /:param
      throw new Error(`ProtectedRoute: can't find described permission for route: ${location}`);
    }
    return [allowed, forbidden];
  }, [match, permissionMap]);

  const permissions = context.user?.userPermissions || [];
  if (!context.user) {
    return <Navigate to={ROUTES.USER_LOGIN} replace state={{ from: location }} />;
  }

  // todo: research: find solution for checking relations between user and displayed data
  // todo: case: customer tries open profile of another customer
  // todo: done on backend side, try to solve on front too
  if (allowedPermissions.length && permissions.length && checkUserPermissions(allowedPermissions, forbiddenPermissions, permissions)) {
    return <Outlet />;
  }

  return <Navigate to={`${ROUTES.USER_LOGIN}/${ROUTES.USER_RESTRICTED}`} replace />;
};

export default ProtectedRoutes;
