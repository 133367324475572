/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconWarning(props: IIconProps) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99935 4.83366C9.45768 4.83366 9.83268 5.20866 9.83268 5.66699V9.00033C9.83268 9.45866 9.45768 9.83366 8.99935 9.83366C8.54102 9.83366 8.16602 9.45866 8.16602 9.00033V5.66699C8.16602 5.20866 8.54102 4.83366 8.99935 4.83366ZM8.99102 0.666992C4.39102 0.666992 0.666016 4.40033 0.666016 9.00033C0.666016 13.6003 4.39102 17.3337 8.99102 17.3337C13.5993 17.3337 17.3327 13.6003 17.3327 9.00033C17.3327 4.40033 13.5993 0.666992 8.99102 0.666992ZM8.99935 15.667C5.31602 15.667 2.33268 12.6837 2.33268 9.00033C2.33268 5.31699 5.31602 2.33366 8.99935 2.33366C12.6827 2.33366 15.666 5.31699 15.666 9.00033C15.666 12.6837 12.6827 15.667 8.99935 15.667ZM9.83268 13.167H8.16602V11.5003H9.83268V13.167Z" fill="#2C3E66" />
    </svg>

  );
}

export default IconWarning;
