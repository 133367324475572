import fabric from './fabric';
import { DPI_IMAGE_ERROR_THRESHOLD } from '../../../../../constants/constants';
import { EBorderType } from '../components/Viewport/fabric/extendCustomObject';

export const getObjectWithBorder = (imgObject: typeof fabric.ImageObject, dpi:number) => {
  if (dpi < DPI_IMAGE_ERROR_THRESHOLD) {
    imgObject.set({ showBorder: EBorderType.ERROR });
  }
  if (dpi > DPI_IMAGE_ERROR_THRESHOLD) {
    imgObject.set({ showBorder: EBorderType.DEFAULT });
  }
  imgObject.canvas.renderAll();
  return imgObject;
};

export default getObjectWithBorder;
