import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import cls from 'classnames';
import _isEqual from 'lodash/isEqual';

import PageContent from 'components/library/PageContent';
import Tabs, { TOnChange } from 'components/library/Tabs';
import ItemInformation from 'components/PagePortfolio/AddEditPrintItem/components/ItemInformation';
import Pricing from 'components/PagePortfolio/AddEditPrintItem/components/Pricing';
import Options from 'components/PagePortfolio/AddEditPrintItem/components/Options';
import OptionalPricing from 'components/PagePortfolio/AddEditPrintItem/components/OptionalPricing';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';

import {
  TPrintItemForm, TPrintItem, TPrintItemsItem, TPrintItemsItemNotSaved, TPrintItemNestedField, EFooterSaveButtonDisplayModes
} from 'types';
import { EPrintItemTabs } from 'types/Portfolio';
import strings from 'constants/localization';
import { IResponseError } from 'constants/types';
import { ROUTES } from 'constants/constants';
import { getBackendErrors } from 'helpers';
import textCss from 'assets/styles/Text.module.css';
import usePrevious from 'hooks/usePrevious';
import { useCreatePrintItem } from 'hooks/Portfolio/useCreatePrintItem';
import useUpdatePrintItem from 'hooks/Portfolio/useUpdatePrintItem';
import UserContext from 'contexts/ContextUser';
import AlertContext from 'contexts/ContextAlert';
import css from './AddEditPrintItem.module.css';
import {
  OPTIONAL_PRICINGS, PRICINGS, PrintItemFormSchema, PRINT_ITEM_FORM_TABS
} from './constants';
import FooterContent from './components/FooterContent';
import { preparePrintItemData, preparePrintItemDataForSave, setTabWithError } from './helpers';
import { getNewPricings } from './components/Pricing/helpers';
import { getNewOptionalPricings } from './components/OptionalPricing/helpers';

interface IAddEditPrintItem {
  create?: boolean,
  data: TPrintItemsItem | TPrintItemForm,
}

const AddEditPrintItem: React.FC<IAddEditPrintItem> = ({ create, data }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const initialValues = useMemo<TPrintItemForm>(
    () => create ? data as TPrintItemForm : preparePrintItemData(data as TPrintItemsItem),
    [create, data]
  );

  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { push } = useContext(AlertContext);

  const { mutateAsync: createPrintItem } = useCreatePrintItem(user?.pspId);
  const { mutateAsync: updatePrintItem } = useUpdatePrintItem(user?.pspId);

  const onChangeTab:TOnChange = useCallback<TOnChange>((event, newValue) => setCurrentTab(newValue), [setCurrentTab]);

  const onSubmit:(values: TPrintItemForm, formikHelpers: FormikHelpers<TPrintItemForm>) => (void | Promise<TPrintItem>) = (values, formikHelpers) => {
    if (!create && _isEqual(values, initialValues)) {
      push({
        message: strings.errorPrintItemFormNoChanges,
        severity: 'error',
      });
      return;
    }
    formikHelpers.setSubmitting(true);
    const preparedValues: TPrintItemsItemNotSaved<number> = preparePrintItemDataForSave(values);
    const promise: Promise<TPrintItemsItem> = create
      ? createPrintItem(preparedValues)
      : updatePrintItem({
        ...data as TPrintItemsItem,
        ...preparedValues
      });
    promise
      .then(() => {
        push({
          message: create ? strings.successResponsePrintItemCreated : strings.successResponsePrintItemUpdated,
          severity: 'success',
        });
        navigate(`${ROUTES.BASE}${ROUTES.PORTFOLIO}`);
      })
      .catch((error: IResponseError) => {
        let errorMsg: string = create ? strings.errorResponseUnableToCreatePrintItem : strings.errorResponseUnableToUpdatePrintItem;
        const errorFromServer = error.response?.data?.message;
        if (errorFromServer) {
          errorMsg = errorFromServer;
        }
        push({
          message: errorMsg,
          severity: 'error',
        });

        const { title }: FormikErrors<TPrintItemsItem> = getBackendErrors<TPrintItemForm>(error);
        if (title) {
          const formErrors: FormikErrors<TPrintItemForm> = { itemInformation: { title } };
          formikHelpers.setErrors(formErrors);
          setTabWithError(formErrors, setCurrentTab);
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const formik = useFormik({
    validationSchema: PrintItemFormSchema(),
    initialValues,
    onSubmit,
  });
  const {
    values: {
      itemInformation: {
        papers,
        quantities,
        sizes,
      },
      options,
      pricings,
      optionalPricings,
    },
    errors,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    handleSubmit,
  } = formik;

  const onClickSave = () => {
    if (!isValid) {
      setTabWithError(errors, setCurrentTab);
      push({
        message: strings.errorPrintItemFormValidation,
        severity: 'error',
      });
    }
    handleSubmit();
  };

  const isSaveDisabled = useMemo<boolean>(() => !dirty || isSubmitting, [dirty, isSubmitting]);
  const saveBtnDisplayMode = useMemo<EFooterSaveButtonDisplayModes | boolean>(
    () => {
      if (create) {
        if (options?.length > 0) {
          return EFooterSaveButtonDisplayModes.ONLY_ON_LAST_TAB;
        }
        return !!PRINT_ITEM_FORM_TABS
          .filter((tab, idx) => [EPrintItemTabs.PRICINGS, EPrintItemTabs.OPTIONAL_PRICINGS].includes(tab.value) && currentTab === idx).length;
      }
      return EFooterSaveButtonDisplayModes.ALWAYS;
    },
    [create, currentTab, options]
  );
  const optionsValues = useMemo<TPrintItemNestedField[]>(() => options.map(({ values }) => values).flat(), [options]);

  const contentDiv = useRef<HTMLDivElement | null>(null);
  const prevSizesLen = usePrevious<number | undefined>(sizes.length);

  // add/remove pricings
  useEffect(() => {
    const newPricings = getNewPricings(sizes, papers, quantities, pricings);
    if (newPricings.length !== pricings.length) {
      setFieldValue(PRICINGS, newPricings);
    }
  }, [sizes.length, papers.length, quantities.length, pricings.length]);

  // add/remove optional pricings
  useEffect(() => {
    const newOptionalPricings = getNewOptionalPricings(quantities, options, optionalPricings);
    if (newOptionalPricings.length !== optionalPricings.length) {
      setFieldValue(OPTIONAL_PRICINGS, newOptionalPricings);
    }
  }, [quantities.length, optionsValues.length, optionalPricings.length]);

  useEffect(() => {
    const container = contentDiv?.current;
    if (prevSizesLen && (sizes.length > prevSizesLen) && container) {
      container.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [sizes.length, prevSizesLen]);

  return (
    <PageContent
      withBreadcrumbs
      pageTitle={create ? strings.addPrintItemPageTitle : strings.editPrintItemPageTitle}
      footerContent={(
        <FooterContent
          saveBtnDisplayMode={saveBtnDisplayMode}
          tabIndex={currentTab}
          tabsLen={PRINT_ITEM_FORM_TABS.length}
          setTabIndex={setCurrentTab}
          onClickSave={onClickSave}
          disableSave={isSaveDisabled}
        />
      )}
    >
      <div className={css.container}>
        <Tabs
          orientation="vertical"
          value={currentTab}
          tabs={PRINT_ITEM_FORM_TABS}
          onChange={onChangeTab}
        />
        <div ref={contentDiv} className={css.content}>
          <div className={cls(css.tabHeader, textCss.pLight1)}>
            <span>{PRINT_ITEM_FORM_TABS[currentTab].label()}</span>
          </div>
          {currentTab === 0 && <ItemInformation formik={formik} />}
          {currentTab === 1 && <Options formik={formik} />}
          {currentTab === 2 && <Pricing formik={formik} />}
          {currentTab === 3 && <OptionalPricing formik={formik} />}
          {/* place modal here, because formRef values updates only after rerender (tab changing) */}
          <UnsavedChangesModal areChangesExist={!isSubmitting && dirty} />
        </div>
      </div>
    </PageContent>
  );
};

export default React.memo(AddEditPrintItem);
