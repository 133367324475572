/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextCharSpacing(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M19.5 14.9998C19.2062 14.706 18.7296 14.7068 18.4369 15.0017C18.1456 15.295 18.1464 15.7687 18.4388 16.061L19.6275 17.2498H4.3725L5.56125 16.061C5.85357 15.7687 5.85441 15.295 5.56312 15.0017C5.27037 14.7068 4.79379 14.706 4.5 14.9998L2.20711 17.2927C1.81658 17.6832 1.81658 18.3164 2.20711 18.7069L4.5 20.9998C4.79379 21.2936 5.27037 21.2927 5.56312 20.9979C5.85441 20.7045 5.85357 20.2309 5.56125 19.9385L4.3725 18.7498H19.6275L18.4388 19.9385C18.1464 20.2309 18.1456 20.7045 18.4369 20.9979C18.7296 21.2927 19.2062 21.2936 19.5 20.9998L21.7929 18.7069C22.1834 18.3164 22.1834 17.6832 21.7929 17.2927L19.5 14.9998Z" fill="#A6CBDF" />
      <path d="M19.3402 12.2707C19.4356 12.5569 19.7035 12.75 20.0053 12.75C20.49 12.75 20.8285 12.2697 20.6654 11.8132L17.487 2.91366C17.3449 2.51567 16.9679 2.25 16.5453 2.25H16.4547C16.0321 2.25 15.6551 2.51567 15.513 2.91366L12.3346 11.8132C12.1715 12.2697 12.51 12.75 12.9947 12.75C13.2965 12.75 13.5644 12.5569 13.6598 12.2707L14.25 10.5H18.75L19.3402 12.2707ZM14.7525 9L16.5 3.75L18.2475 9H14.7525Z" fill="#A6CBDF" />
      <path d="M11.0053 2.25C10.7035 2.25 10.4356 2.44308 10.3402 2.72933L7.5 11.25L4.65978 2.72933C4.56436 2.44308 4.29648 2.25 3.99474 2.25C3.50997 2.25 3.17152 2.73026 3.33457 3.18679L6.51298 12.0863C6.65512 12.4843 7.03211 12.75 7.45472 12.75H7.54528C7.96789 12.75 8.34488 12.4843 8.48702 12.0863L11.6654 3.18679C11.8285 2.73026 11.49 2.25 11.0053 2.25Z" fill="#A6CBDF" />
    </svg>
  );
}

export default IconTextCharSpacing;
