import { useMutation } from 'react-query';

import instance from 'lib/apiClient';
import { TPrintItemsItem, TPrintItemsItemNotSaved } from 'types';
import { IResponseError } from 'constants/types';
import { useInvalidateGetPrintItems } from './useGetPrintItems';

const client = instance.getClient();

export const CREATE_PRINT_ITEM_QUERY = ['printItem', 'create'];

export const createPrintItem = (printItem: TPrintItemsItemNotSaved<number>, pspId: string | undefined): Promise<TPrintItemsItem> => client
  .post(`/print-items?pspId=${pspId}`, printItem)
  .then(res => res.data);

export const useCreatePrintItem = (pspId: string | undefined) => {
  const invalidatePrintItems = useInvalidateGetPrintItems();
  return useMutation<TPrintItemsItem, IResponseError, TPrintItemsItemNotSaved<number>>(
    CREATE_PRINT_ITEM_QUERY,
    data => createPrintItem(data, pspId),
    {
      retry: false,
      onSuccess: () => invalidatePrintItems(),
    }
  );
};
