import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import cls from 'classnames';

import {
  TOptionalPricingItem, TOptionValue, TQuantity,
} from 'types';
import strings from 'constants/localization';
import { MIN_PAGE } from 'constants/constants';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { addValuesAfterComma } from 'helpers';
import css from './OptionalPricingTable.module.css';
import { TFormikQuantity } from '../../../../constants';

const useColumns = (
  quantities: TQuantity[],
  formikQties: TFormikQuantity[],
  selectedPapers: string[],
  pricings: TOptionalPricingItem[],
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  pageCount: number,
):ColumnDef<TOptionValue>[] => useMemo<ColumnDef<TOptionValue>[]>(() => {
  const columns: ColumnDef<TOptionValue>[] = [
    {
      id: 'option',
      header: strings.options,
      meta: {
        className: css.optionColumn
      },
      cell: ({ row: { original } }) => original.value
    },
    {
      id: 'lineArrowLeft',
      header: () => page !== MIN_PAGE
        ? (
          <Icon
            className={cls(css.arrow, css.mirrorArrow)}
            type={ICON_TYPES.lineArrowRight}
            onClick={() => setPage(prevPage => prevPage - 1)}
          />
        )
        : null,
      meta: {
        className: css.arrowColumn,
      },
      cell: () => null
    }
  ];

  quantities.forEach(qty => {
    columns.push({
      id: `qty ${qty.id}`,
      header: strings.formatString(strings.columnHeadQtyWithValue, qty.value) as string,
      meta: {
        className: css.qtyColumn
      },
      cell: ({ row: { original } }) => {
        const selectedQty = formikQties.find((
          { qtyId, paperId, isSelected }
        ) => isSelected && qtyId === qty.id && selectedPapers.includes(paperId));
        if (selectedQty) {
          const pricing = pricings.find(({
            option: { id: optionId },
            quantity: { id: qtyId },
          }) => qtyId === qty.id && optionId === original.id);
          if (pricing) {
            return `${addValuesAfterComma(String(pricing.price))} ${pricing.currency}`;
          }
        }
        return '-';
      }
    });
  });

  columns.push({
    id: 'lineArrowRight',
    header: () => page !== pageCount
      ? (
        <Icon
          className={css.arrow}
          type={ICON_TYPES.lineArrowRight}
          onClick={() => setPage(prevPage => prevPage + 1)}
        />
      )
      : null,
    meta: {
      className: css.arrowColumn,
    },
    cell: () => null,
  });

  return columns;
}, [page]);

export default useColumns;
