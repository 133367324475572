/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFlagEu(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9999 0V24C18.6275 24 24.0001 18.6274 24.0001 12.0002C24.0001 5.37237 18.6275 0 11.9999 0Z" fill="#214391" />
      <path d="M11.9998 0C5.37257 0 0 5.37237 0 12.0002C0 18.6274 5.37257 24 11.9998 24C18.6274 24 18.6274 0 11.9998 0Z" fill="#2B479D" />
      <path d="M11.9793 2.08118L12.272 2.98194H13.2194L12.453 3.53863L12.7457 4.43939L11.9793 3.88236L11.213 4.43939L11.5058 3.53863L10.7391 2.98194H11.6865L11.9793 2.08118Z" fill="#F8D12E" />
      <path d="M11.9793 19.0858L12.272 19.987H13.2194L12.453 20.5437L12.7457 21.4444L11.9793 20.8878L11.213 21.4444L11.5058 20.5437L10.7391 19.987H11.6865L11.9793 19.0858Z" fill="#F8D12E" />
      <path d="M16.6462 3.58826L16.9387 4.48923H17.8861L17.1197 5.04626L17.4124 5.94703L16.6462 5.38999L15.8798 5.94703L16.1725 5.04626L15.4062 4.48923H16.3536L16.6462 3.58826Z" fill="#F8D12E" />
      <path d="M19.8302 6.72974L20.1228 7.6305H21.0702L20.3037 8.18739L20.5965 9.08815L19.8302 8.53147L19.0638 9.08815L19.3565 8.18739L18.5901 7.6305H19.5373L19.8302 6.72974Z" fill="#F8D12E" />
      <path d="M19.9062 14.6669L20.1987 15.568H21.146L20.3796 16.1247L20.6726 17.0252L19.9062 16.4688L19.1397 17.0252L19.4325 16.1247L18.666 15.568H19.6134L19.9062 14.6669Z" fill="#F8D12E" />
      <path d="M4.24348 6.72974L4.53603 7.6305H5.48321L4.71696 8.18739L5.00952 9.08815L4.24348 8.53147L3.47708 9.08815L3.76978 8.18739L3.00354 7.6305H3.95092L4.24348 6.72974Z" fill="#F8D12E" />
      <path d="M20.5683 10.5151L20.8609 11.4159H21.8083L21.0419 11.9726L21.3346 12.8736L20.5683 12.3171L19.8019 12.8736L20.0948 11.9726L19.3282 11.4159H20.2754L20.5683 10.5151Z" fill="#F8D12E" />
      <path d="M3.51975 10.5151L3.8123 11.4159H4.75948L3.99309 11.9726L4.286 12.8736L3.51975 12.3171L2.7533 12.8736L3.04606 11.9726L2.27966 11.4159H3.22699L3.51975 10.5151Z" fill="#F8D12E" />
      <path d="M4.14072 14.6669L4.43327 15.568H5.38045L4.61426 16.1247L4.90676 17.0252L4.14072 16.4688L3.37432 17.0252L3.66703 16.1247L2.90063 15.568H3.84801L4.14072 14.6669Z" fill="#F8D12E" />
      <path d="M16.7835 17.9423L17.0761 18.843H18.0235L17.2569 19.4001L17.5498 20.3008L16.7835 19.7441L16.0169 20.3008L16.3099 19.4001L15.5435 18.843H16.4908L16.7835 17.9423Z" fill="#F8D12E" />
      <path d="M7.46344 3.58826L7.75595 4.48923H8.70333L7.93693 5.04626L8.22984 5.94703L7.46344 5.38999L6.6972 5.94703L6.98991 5.04626L6.22351 4.48923H7.17069L7.46344 3.58826Z" fill="#F8D12E" />
      <path d="M7.11786 17.8848L7.41057 18.7859H8.35795L7.59155 19.3424L7.88426 20.2432L7.11786 19.6869L6.35162 20.2432L6.64433 19.3424L5.87793 18.7859H6.82511L7.11786 17.8848Z" fill="#F8D12E" />
    </svg>
  );
}

export default IconFlagEu;
