import { useMutation } from 'react-query';

import instance from 'lib/apiClient';
import { TPrintItemsItem } from 'types';
import { IResponseError } from 'constants/types';
import { useInvalidateGetPrintItems } from './useGetPrintItems';
import { useInvalidateGetPrintItem } from './useGetPrintItem';

const client = instance.getClient();

export const UPDATE_PRINT_ITEM_QUERY = ['printItem', 'patch'];

export const updatePrintItem = (printItem: TPrintItemsItem<number>, pspId: string | undefined): Promise<TPrintItemsItem> => client
  .put(`/print-items/${printItem.id}?pspId=${pspId}`, printItem)
  .then(res => res.data);

export const useUpdatePrintItem = (pspId: string | undefined) => {
  const invalidatePrintItems = useInvalidateGetPrintItems();
  const invalidatePrintItem = useInvalidateGetPrintItem();
  return useMutation<TPrintItemsItem, IResponseError, TPrintItemsItem<number>>(
    UPDATE_PRINT_ITEM_QUERY,
    data => updatePrintItem(data, pspId),
    {
      retry: false,
      onSuccess: (data, props) => {
        invalidatePrintItems();
        invalidatePrintItem(props.id!);
      },
    }
  );
};

export default useUpdatePrintItem;
