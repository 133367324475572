/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconHeight(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 41 122.9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 7.5A3.8 3.8 0 0 1 6 0h29a3.8 3.8 0 0 1 0 7.5H6zm0 115.4a3.8 3.8 0 0 1 0-7.5h29a3.8 3.8 0 0 1 0 7.5H6zm16-13.3c-.4.2-.8.3-1.3.3h-.4c-.4 0-.9-.1-1.3-.3h-.2l-.7-.5L1.4 95.6a3.7 3.7 0 0 1 4.7-5.9l10.7 8.7v-71L6 36a3.7 3.7 0 1 1-4.7-5.8l16.7-13.5.7-.5h.2l1.3-.4h.4c.5 0 1 .2 1.3.3h.1v.1h.1l.7.5 16.7 13.5A3.7 3.7 0 0 1 35 36l-10.7-8.6v71L35 89.7a3.7 3.7 0 1 1 4.6 5.8L23 109.1l-.7.4-.1.1z" strokeWidth="2" stroke="#2C3E66" fill="#2C3E66" />
    </svg>
  );
}

export default IconHeight;
