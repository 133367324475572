/* eslint-disable max-len */
import React from 'react';
import cls from 'classnames';
import { IIconProps } from './types';
import css from './Icon.module.css';

function IconCopy({ className, ...props }: IIconProps) {
  return (
    <svg className={cls(className, css.iconCopy)} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect style={{ fill: 'none !important' }} x="3" y="3" width="14" height="14" rx="3" stroke="#2C3E66" strokeWidth="1.8" />
      <rect x="8" y="8" width="13" height="13" rx="2" fill="#2C3E66" stroke="#2C3E66" strokeWidth="1.8" />
    </svg>
  );
}

export default IconCopy;
