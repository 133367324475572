import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Loader from 'components/library/Loader';
import Error from 'components/library/Error';

import useGetPrintItem from 'hooks/Portfolio/useGetPrintItem';
import UserContext from 'contexts/ContextUser';
import AddEditPrintItem from './AddEditPrintItem';
import css from './AddEditPrintItem.module.css';
import { generatePrintItemFormFields } from './helpers';

interface IAddEditPrintItemContainer {
  create?: boolean,
}

const AddEditPrintItemContainer: React.FC<IAddEditPrintItemContainer> = ({ create }) => {
  const { user } = useContext(UserContext);
  const { id: printItemId } = useParams();

  const { data = generatePrintItemFormFields(), isLoading, error } = useGetPrintItem({
    pspId: user?.pspId,
    printItemId,
    includeBase: true,
  });

  if (error) {
    return (
      <Error
        code={error.status}
        title={error.response?.data.className}
        description={error.response?.data.data?.reason || error.response?.data.message}
      />
    );
  }

  return isLoading
    ? <div className={css.loaderContainer}><Loader /></div>
    : (
      <AddEditPrintItem
        create={create}
        data={data}
      />
    );
};

export default React.memo(AddEditPrintItemContainer);
