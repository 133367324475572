import React, {
  useContext, useEffect, useMemo
} from 'react';
import AlertContext from 'contexts/ContextAlert';
import useQueryParams from 'hooks/useQueryParams';
import useGetUser from 'hooks/useUser';
import useGetArticleIntegrationTickets from 'hooks/Integration/useArticleIntegrationTickets';
import Error from 'components/library/Error';
import strings from 'constants/localization';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Loader from 'components/library/Loader';
import { EIntegrationServices } from 'types';
import TicketTypeSelect, { ITicketType } from './components/TicketTypeSelect';
import { getUseTicketsHook, transformTicketsData, transformTicketsError } from './helpers';
import { sortTicketTypesByName, sortTicketTypesByStatus } from '../helpers';
import useEditorData from '../../../../../hooks/useEditorData';

export interface ITicketTypeSelectContainer {
  service: EIntegrationServices,
  chosenTicket: ITicketType | null,
  matchingsTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
  integrationToken: string | null | undefined,
}

const TicketTypeSelectContainer: React.FC<ITicketTypeSelectContainer> = ({
  service, chosenTicket, matchingsTicket, setChosenTicket, integrationToken
}) => {
  const { push } = useContext(AlertContext);
  const { articleTitle } = useEditorData();

  const query = useQueryParams();
  const customerId = query.get('customerId') || undefined;

  const { data: customer, isFetching: isFetchingCustomer } = useGetUser({ userId: customerId });
  const { data: connectedTickets = [], isFetching: isFetchingConnectedTickets } = useGetArticleIntegrationTickets();

  const bindedEventId = useMemo<string | undefined>(
    () => customer?.eventBind?.eventId,
    [customer]
  );

  const useGetTickets = useMemo(() => getUseTicketsHook(service), [service]);
  const { data: ticketsData, isFetching: isFetchingTickets, error: ticketsError } = useGetTickets(integrationToken, bindedEventId);

  const tickets = useMemo<ITicketType[]>(() => {
    const transformedData = transformTicketsData({ service, data: ticketsData ?? [] });
    const sortedByName = sortTicketTypesByName(transformedData, articleTitle);
    return sortTicketTypesByStatus(sortedByName, connectedTickets);
  }, [service, ticketsData, connectedTickets, articleTitle]);

  const isFetching = useMemo<boolean>(
    () => isFetchingCustomer || isFetchingTickets || isFetchingConnectedTickets,
    [isFetchingCustomer, isFetchingTickets, isFetchingConnectedTickets]
  );

  useEffect(() => {
    if (!isFetchingTickets || matchingsTicket) {
      const chosenTicketFromMatchings = tickets.find(({ id }) => id === matchingsTicket?.id);
      if (chosenTicketFromMatchings) {
        setChosenTicket(chosenTicketFromMatchings);
      }
    }
  }, [isFetchingTickets, tickets, matchingsTicket]);

  if (ticketsError) {
    const errData = ticketsError?.response?.data;
    const { code, name, message } = transformTicketsError({ service, data: errData || {} });
    push({ severity: 'error', message: message || '' });
    return (
      <Error
        code={code}
        title={name}
        description={code === 401 ? strings.errorInvalidOrExpiredIntegrationToken : message}
      />
    );
  }
  if (!isFetchingCustomer && !bindedEventId) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoEventBind}
        description={strings.emptyStateDescriptionSeemsLikeNotBindedWithEvent}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }
  if (!isFetching && tickets.length === 0) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoTickets}
        description={strings.emptyStateDescriptionCreateTickets}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }

  if (isFetching) {
    return <Loader />;
  }

  return (
    <TicketTypeSelect
      title={customer?.eventBind?.eventName || ''}
      ticketTypes={tickets}
      connectedTickets={connectedTickets}
      matchingsTicket={matchingsTicket}
      chosenTicket={chosenTicket}
      setChosenTicket={setChosenTicket}
    />
  );
};

export default TicketTypeSelectContainer;
