import cls from 'classnames';
import React from 'react';
import strings from 'constants/localization';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import textCss from 'assets/styles/Text.module.css';
import Button from 'components/library/Button';
import css from './FileList.module.css';

interface IFileList {
  file: File,
  removeFile: () => void,
  fileRows: number
}
const FileList:React.FC<IFileList> = ({ file, removeFile, fileRows }) => (
  <div className={css.container}>
    {strings.orderPageModalUploadListDescription}
    <div className={css.row}>
      <div>
        <Icon type={E_ICON_TYPE.file} />
        <span className={cls(textCss.h4, css.text)}>{strings.orderPageModalUploadFileLabel}</span>
        <span className={cls(textCss.pMedium2, css.text)}>{file.name}</span>
        <span className={cls(textCss.pLight3, css.text)}>
          {strings.formatString(
            strings.orderPageModalUploadFileRowsRead,
            fileRows - 1
          ).toString()}
        </span>
      </div>
      <Button
        buttonType="tertiary"
        buttonStyle="circle"
        onClick={removeFile}
      >
        <Icon type={E_ICON_TYPE.delete} />
      </Button>
    </div>
  </div>
);

export default React.memo(FileList);
