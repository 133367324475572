import React, { useEffect } from 'react';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import css from './PageContent.module.css';
import Sidebar from '../Sidebar';
import Breadcrumbs from '../Breadcrumbs';

interface IPageContent extends React.HTMLProps<HTMLDivElement>{
  pageTitle?: string | JSX.Element,
  sidebarContent?: React.ReactNode,
  footerContent?: React.ReactNode,
  withBreadcrumbs?: boolean,
  titleClassName?: string,
  scrollableX?: boolean,
}

const PageContent:React.FC<IPageContent> = props => {
  const {
    className, pageTitle, titleClassName, withBreadcrumbs, sidebarContent, footerContent, children, scrollableX = true, ...rest
  } = props;

  useEffect(() => {
    if (footerContent) {
      const root = document.getElementById('root');
      root?.classList.add(css.withFooter);
    }
    return () => {
      const root = document.getElementById('root');
      root?.classList.remove(css.withFooter);
    };
  }, []);

  return (
    <div className={cls(css.container, {
      [css.scrollableX]: scrollableX,
      [css.scrollableXWithSidebar]: scrollableX && !!sidebarContent,
    })}
    >
      <div
        className={cls(css.innerContainer, {
          [css.withSidebar]: !!sidebarContent,
          [css.withFooter]: !!footerContent,
        }, className)}
        {...rest}
      >
        {pageTitle ? <span className={cls(css.title, textCss.h2, { [css.withBreadcrumbs]: !!withBreadcrumbs }, titleClassName)}>{pageTitle}</span> : null}
        {withBreadcrumbs ? <div className={css.breadcrumbs}><Breadcrumbs /></div> : null}
        {children}
      </div>
      {sidebarContent ? <Sidebar className={cls({ [css.sideBarWithFooter]: footerContent })}>{sidebarContent}</Sidebar> : null}
      {footerContent ? <div className={css.footer}>{footerContent}</div> : null}
    </div>
  );
};

export default React.memo(PageContent);
