import React from 'react';

import { IIconProps } from './types';

function IconSearch(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9234 13.4048C16.1704 11.7971 16.7581 9.77482 16.5669 7.74925C16.3758 5.72367 15.4201 3.84701 13.8944
        2.50104C12.3686 1.15507 10.3874 0.440903 8.35384 0.503832C6.32024 0.56676 4.387 1.40205 2.94741 2.83978C1.50657
        4.27851 0.66854 6.2125 0.604108 8.24765C0.539676 10.2828 1.25369 12.2659 2.60064 13.7929C3.94759 15.3199 5.82611
        16.2758 7.85338 16.4659C9.88066 16.6559 11.9041 16.0658 13.5114 14.8158L13.5544 14.8608L17.7964 19.1038C17.8893
        19.1967 17.9996 19.2704 18.121 19.3207C18.2424 19.371 18.3725 19.3968 18.5039 19.3968C18.6353 19.3968 18.7654
        19.371 18.8868 19.3207C19.0082 19.2704 19.1185 19.1967 19.2114 19.1038C19.3043 19.0109 19.378 18.9006 19.4283
        18.7792C19.4786 18.6578 19.5045 18.5277 19.5045 18.3963C19.5045 18.2649 19.4786 18.1348 19.4283 18.0134C19.378
        17.892 19.3043 17.7817 19.2114 17.6888L14.9684 13.4468C14.9538 13.4324 14.9388 13.4184 14.9234 13.4048V13.4048ZM12.8474
        4.25478C13.412 4.81026 13.861 5.47203 14.1686 6.20192C14.4762 6.93181 14.6362 7.71537 14.6394 8.5074C14.6426
        9.29944 14.489 10.0843 14.1874 10.8166C13.8858 11.549 13.4422 12.2144 12.8821 12.7745C12.322 13.3345 11.6566
        13.7782 10.9243 14.0798C10.1919 14.3814 9.40707 14.535 8.61503 14.5318C7.82299 14.5286 7.03943 14.3686 6.30955
        14.061C5.57966 13.7534 4.91789 13.3044 4.36241 12.7398C3.25233 11.6115 2.63307 10.0902 2.63951 8.5074C2.64596
        6.92458 3.27759 5.40843 4.39682 4.28919C5.51605 3.16996 7.03221 2.53833 8.61503 2.53188C10.1979 2.52544 11.7191
        3.1447 12.8474 4.25478Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconSearch;
