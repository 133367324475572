import React from 'react';
import cls from 'classnames';
import { FormikProps } from 'formik';

import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import strings from 'constants/localization';
import { TArticle } from 'types';
import {
  IArticleFormValues, TProductInfoDisabled, IFormProductInfoValues, PRODUCT_INFO_FORM,
} from '../../constants';
import css from './TabProductInfo.module.css';
import { getProductInfoDisabled } from '../../helpers';

interface ITabProductInfo {
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
}

const TabProductInfo:React.FC<ITabProductInfo> = ({
  formik, article
}) => {
  const {
    getFieldProps,
    touched: { productInfo: touched },
    errors: { productInfo: errors },
    values: { productInfo: values },
  } = formik;
  const getFieldName = (fieldName: keyof IFormProductInfoValues): string => `${PRODUCT_INFO_FORM}.${fieldName}`;
  const getFieldError = (fieldName: keyof IFormProductInfoValues): string | undefined => (
    touched?.[fieldName] && errors?.[fieldName]) || undefined;
  const disabled: TProductInfoDisabled | undefined = getProductInfoDisabled(article);
  const descriptionHelperText = strings.formatString(
    strings.inputDisplayCharactersLenHelper,
    values.description.length,
    120
  ) as string;

  return (
    <>
      <span className={cls(textCss.pLight1)}>{strings.articleFormTabProductInfo}</span>
      <form noValidate>
        <Input
          {...getFieldProps(getFieldName('skuIdTitle'))}
          disabled={disabled?.skuIdTitle}
          withError
          error={getFieldError('skuIdTitle')}
          className={css.input}
          label={strings.articleFormProductInfoTabSkuIdLabel}
          type="text"
          placeholder={strings.articleFormProductInfoTabSkuIdPlaceholder}
        />
        <Input
          {...getFieldProps(getFieldName('title'))}
          disabled={disabled?.title}
          withError
          error={getFieldError('title')}
          className={css.input}
          label={strings.articleFormProductInfoTabTitleLabel}
          type="text"
          placeholder={article.printItem.title}
        />
        <Input
          {...getFieldProps(getFieldName('description'))}
          withError
          disabled={disabled?.description}
          error={getFieldError('description')}
          className={css.input}
          label={strings.inputDescriptionLabel}
          type="email"
          placeholder={strings.inputDescriptionPlaceholder}
          labelProps={{ optional: true }}
          description={descriptionHelperText}
          isMultiple
          rows={3}
        />
      </form>
    </>
  );
};

export default TabProductInfo;
