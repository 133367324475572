import React, { useMemo } from 'react';
import Select from 'components/library/Select';
import { SingleValue } from 'react-select';
import { number, string, object } from 'yup';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import css from './NumberingPattern.module.css';

interface IOption {
  label: string,
  value: number,
}

const optionsList: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9
];

const NEW_OPTION_SCHEMA = object({
  label: string().required().min(1).max(100),
  value: number().required().min(1).max(20),
});

const NumberingPattern: React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const options: IOption[] = useMemo(() => {
    const values = optionsList.map(x => ({
      label: String(activeObject?.startValue).padStart(x, '0'),
      value: x,
    }));

    return values.filter(({ value }) => value >= String(activeObject?.startValue).length);
  }, [optionsList, activeObject?.startValue]);

  const option = useMemo(() => options.find(val => val.value === (activeObject?.pattern || options[0])), [options, activeObject?.pattern]);

  const handleChange = (newValue: SingleValue<IOption>) => {
    if (activeObject && canvas.current) {
      try {
        const { value } = NEW_OPTION_SCHEMA.validateSync(newValue);
        activeObject.set({ pattern: value });
        activeObject._changeValue();
        canvas.current.fire('object:modified', { target: activeObject });
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <Select<IOption>
      isCreatable
      className={css.select}
      menuShouldBlockScroll
      options={options}
      value={option}
      onChange={handleChange}
    />
  );
};

export default NumberingPattern;
