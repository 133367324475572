import React, {
  ChangeEvent, KeyboardEvent, useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import Input from 'components/library/Input';
import { string } from 'yup';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import { IObject } from '../../../../../../components/Editor/contexts/EditorContext';
import css from './NameInput.module.css';

const INPUT_SCHEMA = string()
  .required()
  .label('Object name')
  .min(1)
  .max(128);

interface INameInput {
  object: IObject['object'],
  onFocus?: () => void,
}

const NameInput:React.FC<INameInput> = ({
  object,
  onFocus,
}) => {
  const { push } = useContext(AlertContext);
  const {
    objects,
    setObjects,
    canvas,
    setIsChanged,
  } = useEditorData();

  const sendPush = useCallback((message: string) => push({ severity: 'error', message }), [push]);

  const input = useRef<HTMLInputElement>(null!);
  const [isValid, setIsValid] = useState<boolean>(true);

  const listOfNames = useMemo(() => objects.map(({ name }) => name), [objects]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    if (target) {
      const { value } = target;
      INPUT_SCHEMA.validate(value)
        .then(valid => {
          if (listOfNames.includes(valid) && valid !== object.name) {
            throw new Error(strings.errorEditorTheNameMustBeUniques);
          }
          setIsValid(true);
        })
        .catch(({ message }) => {
          sendPush(message);
          setIsValid(false);
        })
        .finally(() => {
          input.current.value = value;
        });
    }
  }, [listOfNames, object, setIsValid]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    if (target) {
      const { value } = target;

      INPUT_SCHEMA.validate(value)
        .then(valid => {
          if (listOfNames.includes(valid) && valid !== object.name) {
            throw new Error(strings.errorEditorTheNameMustBeUniques);
          }
          input.current.value = valid;
          object.set('name', valid);
          if (canvas.current) {
            canvas.current.fire('object:modified', { target: object });
          }
        })
        .catch(() => {
          input.current.value = object.name;
        })
        .finally(() => setIsValid(true));
    }
  }, [listOfNames, object, setIsValid, setObjects, setIsChanged]);

  const handleFocus = useCallback(() => {
    if (canvas.current && !object.locked) {
      canvas.current.setActiveObject(object);
      canvas.current.requestRenderAll();
      if (onFocus instanceof Function) {
        onFocus();
      }
    }
  }, [object, canvas]);

  const handleKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.target) {
      (e.target as HTMLInputElement).blur();
    }
  }, []);

  return (
    <Input
      ref={input}
      inputClassName={classNames(css.input, { [css.invalid]: !isValid })}
      reducedWidth
      reducedHeight
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyPress={handleKeyPress}
      defaultValue={object.name}
    />
  );
};

export default NameInput;
