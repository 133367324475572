import {
  EOrderDataIds,
  TExcelBindings,
  TBindingsDelivery,
  TBindingsOrder,
} from './types';

export const bindingsOrderInit: TBindingsOrder = {
  [EOrderDataIds.ORDER_ID]: null,
  [EOrderDataIds.TICKET_TYPE_ID]: null,
  [EOrderDataIds.TICKET_TYPE_NAME]: null,
};

export const bindingsDeliveryInit: TBindingsDelivery = {
  [EOrderDataIds.NAME]: null,
  [EOrderDataIds.SURNAME]: null,
  [EOrderDataIds.COUNTRY]: null,
  [EOrderDataIds.CITY]: null,
  [EOrderDataIds.ZIPCODE]: null,
  [EOrderDataIds.STREET]: null,
  [EOrderDataIds.HOUSE]: null,
  [EOrderDataIds.APARTMENT]: null,
  [EOrderDataIds.PHONE_NUMBER]: null,
};

export const bindingsInit: TExcelBindings = {
  ...bindingsOrderInit,
  ...bindingsDeliveryInit
};

export default { bindingsOrderInit, bindingsDeliveryInit, bindingsInit };
