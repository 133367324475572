/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconOrders(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.7998 3H3.45405C3.93244 3 4.17163 3 4.36609 3.08674C4.53751 3.16321 4.68395 3.28638 4.78866 3.44217C4.90744 3.61888 4.94842 3.85454 5.03039 4.32585L5.4085 6.5M5.4085 6.5L6.29153 11.5774C6.47071 12.6077 6.5603 13.1228 6.82563 13.4939C7.05926 13.8207 7.38586 14.0695 7.76291 14.208C8.19113 14.3654 8.71157 14.315 9.75245 14.2143L19.4138 13.2793C20.4296 13.181 20.9376 13.1318 21.3219 12.9033C21.6651 12.6993 21.9393 12.3972 22.1093 12.036C22.2998 11.6314 22.2998 11.1211 22.2998 10.1005V10.1005C22.2998 9.01582 22.2998 8.47348 22.092 8.05394C21.9066 7.67971 21.6088 7.3729 21.2402 7.17654C20.827 6.9564 20.2849 6.94036 19.2007 6.90826L5.4085 6.5Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="9.2998" cy="19.5" r="2" stroke="white" strokeWidth="1.8" />
      <circle cx="18.7998" cy="19.5" r="2" stroke="white" strokeWidth="1.8" />
    </svg>
  );
}

export default IconOrders;
