import React, { useMemo, useState } from 'react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import cls from 'classnames';
import { SingleValue } from 'react-select';

import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import Input from 'components/library/Input';
import Select from 'components/library/Select';
import Label from 'components/library/Label';
import Button from 'components/library/Button';
import { ERoles, TArticle, TSize } from 'types';
import Checkbox from 'components/library/Checkbox';
import { IArticleFormValues, IFormProductSetupValues, PRODUCT_SETUP_FORM } from '../../constants';
import css from './TabProductSetup.module.css';
import PaperAndQuantity from './components/PaperAndQuantity';
import PricingTable from './components/PricingTable';
import { getProductSetupDisabled, getSizeLabel, TProductSetupDisabled } from '../../helpers';

export const getFieldName = (fieldName: keyof IFormProductSetupValues): string => `${PRODUCT_SETUP_FORM}.${fieldName}`;
export const getFieldError = (
  fieldName: keyof IFormProductSetupValues,
  touched?: FormikTouched<IFormProductSetupValues>,
  errors?: FormikErrors<IFormProductSetupValues>
): string | undefined => {
  const error = touched?.[fieldName] && errors?.[fieldName] as string;
  return error || undefined;
};

export interface ITabProductSetup {
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
  userRole: ERoles,
}
const TabProductSetup:React.FC<ITabProductSetup> = ({
  formik, article, userRole,
}) => {
  const {
    getFieldProps, setFieldValue, setFieldTouched,
    touched: { productSetup: touched },
    errors: { productSetup: errors },
    values: {
      productSetup: values,
      template,
    }
  } = formik;
  const [isPricingOpen, setIsPricingOpen] = useState<boolean>(false);

  const onSizeChange = (value: SingleValue<TSize>) => setFieldValue(getFieldName('size'), value);
  const onSpecialChange = (event:React.ChangeEvent<HTMLInputElement>) => setFieldValue(getFieldName('special'), event.target.checked);
  const onSizeBlur = () => setFieldTouched(getFieldName('size'), true);
  const getSizeOptionLabel = (obj: TSize) => getSizeLabel(obj, article.printItem.units);
  const getSizeOptionValue = (obj: TSize) => obj.id;
  const onSeePricingClick = () => setIsPricingOpen(true);
  const isSeePricingDisabled: boolean = !values.selectedPapers.length || !values.size;
  const closePricingTable = () => setIsPricingOpen(false);
  const disabled: TProductSetupDisabled | undefined = getProductSetupDisabled(article);
  const isSizeDisabled = useMemo<boolean>(
    () => disabled?.size || Object.values(template).some(o => o instanceof Object),
    [disabled?.size, template]
  );

  return (
    <>
      <span className={cls(textCss.pLight1)}>{strings.articleFormTabProductSetup}</span>
      <form noValidate>
        { userRole === ERoles.PRINTER && (
          <div>
            <div className={css.input}>
              <Label text={strings.articleFormProductSetupTabPrintingLabel} />
              <Checkbox
                {...getFieldProps(getFieldName('special'))}
                wrapperClassName={css.checkbox}
                onChange={onSpecialChange}
                label={strings.profilePageInputChangeOrderSpecialLabel}
              />
            </div>
          </div>
        ) }
        <div className={css.commentContainer}>
          <div className={css.input}>
            <Label text={strings.articleFormProductSetupTabSizeLabel} />
            <Select<TSize>
              isDisabled={isSizeDisabled}
              {...getFieldProps(getFieldName('size'))}
              placeholder={strings.articleFormProductSetupTabSizePlaceholder}
              options={article.printItem.sizes}
              onChange={onSizeChange}
              onBlur={onSizeBlur}
              getOptionLabel={getSizeOptionLabel}
              getOptionValue={getSizeOptionValue}
              withError
              error={getFieldError('size', touched, errors)}
              isSearchable
            />
          </div>
          <div className={css.input}>
            <Input
              {...getFieldProps(getFieldName('sizeComment'))}
              disabled={disabled?.sizeComment}
              withError
              error={getFieldError('sizeComment', touched, errors)}
              label={strings.inputCommentLabel}
              type="text"
              placeholder={strings.inputCommentLabel}
              labelProps={{ optional: true }}
              className={css.fullWidth}
            /> {/* TODO make comment field closable */}
          </div>
        </div>
        <PaperAndQuantity formik={formik} article={article} disabled={disabled} />
        <Button
          buttonType="primary"
          onClick={onSeePricingClick}
          disabled={isSeePricingDisabled}
          className={css.seePricingButton}
        >
          {strings.articleFormProductSetupTabSeePricingButtonLabel}
        </Button>
        <PricingTable
          isOpen={isPricingOpen}
          closeModal={closePricingTable}
          article={article}
          formik={formik}
        />
      </form>
    </>
  );
};

export default TabProductSetup;
