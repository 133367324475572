import React from 'react';
import Button from '../../Button';
import strings from '../../../../constants/localization';

interface ITabNavigationButtons extends React.HTMLProps<HTMLDivElement> {
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>,
  maxTabIndex: number
}
const TabNavigationButtons: React.FC<ITabNavigationButtons> = ({
  tabIndex, setTabIndex, maxTabIndex
}) => {
  const isBackAvailable: boolean = tabIndex > 0;
  const isNextAvailable: boolean = tabIndex < maxTabIndex;

  const navigateBack = () => {
    if (isBackAvailable) {
      setTabIndex(tabIndex - 1);
    }
  };
  const navigateNext = () => {
    if (isNextAvailable) {
      setTabIndex(tabIndex + 1);
    }
  };

  return (
    <>
      {isBackAvailable && (
        <Button buttonType="quaternaryBack" onClick={navigateBack}>{strings.actionLabelBack}</Button>
      )}
      {isNextAvailable && (
        <Button buttonType="quaternaryNext" onClick={navigateNext}>{strings.actionLabelNext}</Button>
      )}
    </>
  );
};

export default TabNavigationButtons;
