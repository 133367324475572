import { EOrientation, EUnits } from 'types';
import * as pdfjs from 'pdfjs-dist';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import strings from 'constants/localization';
import { ORIENTATION_TO_STRINGS } from 'constants/constants';
import { IPdfBox } from '../contexts/EditorContext';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export const getOrientation = (width: number, height: number): EOrientation => {
  if (width > height) return EOrientation.LANDSCAPE;
  if (width < height) return EOrientation.PORTRAIT;
  return EOrientation.SQUARE;
};

export enum EConvertUnitDirection {
 PIXELS = 'pixels',
 UNIT = 'unit',
}

const UnitFactors = {
  [EUnits.MM]: 25.4,
  [EUnits.CM]: 2.54,
};

const DPI = 72;
export const convertUnit = (value: number, unit: EUnits, direction: EConvertUnitDirection, dpi: number = DPI): number => {
  const factor = UnitFactors?.[unit];
  if (!factor === undefined) {
    throw new Error(strings.formatString(strings.editorParseUnitHasNoImplementation, unit) as string);
  }

  return direction === EConvertUnitDirection.PIXELS
    ? (value * dpi) / factor
    : (value * factor) / dpi;
};

export interface ITrimBox {
  left: number,
  top: number,
  width: number,
  height: number,
}

export interface IGetCanvasTrimBox {
  canvas: HTMLCanvasElement,
  trimBox: ITrimBox,
}

const getCanvasTrimBox = (sourceCanvas: HTMLCanvasElement, width: number, height: number): IGetCanvasTrimBox => {
  const left = (sourceCanvas.width - width) / 2;
  const top = (sourceCanvas.height - height) / 2;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(sourceCanvas, left, top, width, height, 0, 0, width, height);
  }
  return {
    canvas,
    trimBox: {
      left, top, width, height
    },
  };
};

export const pdfDataUrlToPngDataUrl = async (dataUrl: any, pdfBox: IPdfBox, qualityFactor: number = 1) => {
  const doc = pdfjs.getDocument(dataUrl);
  const prom = await doc.promise;
  const page = await prom.getPage(1);

  const viewport = page.getViewport({ scale: qualityFactor });

  const {
    width,
    height,
    bleed,
    unit,
  } = pdfBox;

  const pdfBoxOrientation = getOrientation(width, height);
  const pdfOrientation = getOrientation(viewport.width, viewport.height);
  if (pdfBoxOrientation !== pdfOrientation) {
    throw new Error(strings.formatString(
      strings.editorPdfHasWrongOrientationErrorMessage,
      ORIENTATION_TO_STRINGS[pdfBoxOrientation]()
    ) as string);
  }

  // Real dimensions of the pdf in UNIT according to count of pixels on the pdf
  // Real size could be smaller, than showed in Pdf viewer/editor
  const realWidth = convertUnit(viewport.width / 2, unit, EConvertUnitDirection.UNIT);
  const realHeight = convertUnit(viewport.height / 2, unit, EConvertUnitDirection.UNIT);

  const minWidth = width + (bleed * 2);
  const minHeight = height + (bleed * 2);

  if (Math.ceil(realWidth) < minWidth || Math.ceil(realHeight) < minHeight) {
    throw new Error(strings.editorPdfSizeIsTooSmallErrorMessage);
  }

  const renderCanvas: HTMLCanvasElement = document.createElement('canvas');

  renderCanvas.width = Math.floor(viewport.width);
  renderCanvas.height = Math.floor(viewport.height);

  const renderContext = {
    intent: 'print',
    canvasContext: renderCanvas.getContext('2d'),
    viewport
  };

  // @ts-ignore
  const renderTask = await page.render(renderContext);
  await renderTask.promise;

  const widthInPixels = convertUnit(width * qualityFactor, unit, EConvertUnitDirection.PIXELS);
  const heightInPixels = convertUnit(height * qualityFactor, unit, EConvertUnitDirection.PIXELS);
  const { canvas, trimBox } = getCanvasTrimBox(renderCanvas, widthInPixels, heightInPixels);

  return {
    docWidth: canvas.width / qualityFactor,
    docHeight: canvas.height / qualityFactor,
    dataUrl: canvas.toDataURL('image/jpeg', 0.975),
    trimBox: Object.fromEntries(
      Object.entries(trimBox)
        .map(([key, val]) => [key, val / qualityFactor])
    ),
  };
};

export default pdfDataUrlToPngDataUrl;
