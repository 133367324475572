import React, { useCallback, useContext, useMemo } from 'react';
import {
  TOption, TOptionValue, TPaper, TQuantity
} from 'types';
import OrderContext from '../../ContextOrder';
import { calculateNewPrice } from '../../helper';
import OrderForm from './OrderForm';

const OrderFormContainer:React.FC = () => {
  const { state, setState } = useContext(OrderContext);
  const {
    optionsState,
    paper,
    papers: papersQuantity,
    size,
    skuIdTitle,
    productType,
    ticketType,
    options,
    orderComment,
    price,
    orderId,
    quantity,
    printItem,
    isOrderCreation,
    currency,
  } = state;

  const papers = useMemo<TPaper[]>(() => papersQuantity.map(e => e.paper), [papersQuantity]);
  const quantities = useMemo<TQuantity[]>(() => {
    const paperQuantity = papersQuantity.find((p => p.paper.id === paper?.id));
    return paperQuantity?.quantities.sort((a, b) => a.value > b.value ? 1 : -1) || [];
  }, [paper, papersQuantity]);
  const onChangeOption = useCallback((option: TOption, value:TOptionValue | null) => {
    const newOptionsState = {
      ...optionsState,
      [option.id]: value ? {
        ...option,
        value
      } : undefined
    };
    const totalPrice = calculateNewPrice(newOptionsState, paper, quantity, printItem, size);
    setState({
      optionsState: newOptionsState,
      price: totalPrice,
      changes: true
    });
  }, [optionsState, setState, paper, quantity, printItem, size]);
  const onChangeQuantity = useCallback((value: TQuantity | null) => {
    const totalPrice = calculateNewPrice(optionsState, paper, value, printItem, size);
    setState({ quantity: value, price: totalPrice, changes: true });
  }, [optionsState, paper, printItem, size]);
  const onChangePaper = useCallback((value: TPaper | null) => {
    const paperQuantity = papersQuantity.find((p => p.paper.id === value?.id));
    const existedQuantity = paperQuantity?.quantities.find(q => q.id === quantity?.id);
    const newQuantity = existedQuantity || paperQuantity?.quantities[0] || null;
    const totalPrice = calculateNewPrice(optionsState, value, newQuantity, printItem, size);
    setState({
      paper: value, quantity: newQuantity, price: totalPrice, changes: true
    });
  }, [papersQuantity, quantity, optionsState, printItem, size, setState]);

  const displayOptions = useMemo<boolean>(() => {
    if (options.length) {
      return !!Object.values(optionsState).filter(e => e?.value).length;
    }
    return false;
  }, [optionsState]);

  return useMemo(() => (
    <OrderForm
      currency={currency}
      isOrderCreation={isOrderCreation}
      orderId={orderId}
      skuIdTitle={skuIdTitle}
      productType={productType}
      ticketType={ticketType}
      quantity={quantity}
      quantities={quantities}
      paper={paper}
      papers={papers}
      size={size}
      displayOptions={displayOptions}
      options={options}
      optionsState={optionsState}
      orderComment={orderComment}
      price={price}
      onChangeOption={onChangeOption}
      onChangeQuantity={onChangeQuantity}
      onChangePaper={onChangePaper}
      units={printItem?.units}
      printItemTitle={printItem?.title}
    />
  ), [
    printItem?.title,
    printItem?.units,
    currency,
    isOrderCreation,
    orderId,
    skuIdTitle,
    productType,
    quantity,
    quantities,
    paper,
    papers,
    size,
    displayOptions,
    options,
    optionsState,
    orderComment,
    price,
    onChangeOption,
    onChangeQuantity,
    onChangePaper
  ]);
};

export default OrderFormContainer;
