import React, { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import cls from 'classnames';

import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import { Wrapper } from 'components/PageLogin';
import { useRequestResetPassword } from 'hooks/usePassword';
import ContextAlert from 'contexts/ContextAlert';
import { IResponseError } from 'constants/types';
import css from './UserForgotPassword.module.css';

const Schema = () => Yup.string().email(strings.inputValidateEmail).required(strings.inputValidateRequiredField);

interface IForm {
  setStatus: React.Dispatch<React.SetStateAction<boolean>>
}
const Form: React.FC<IForm> = ({ setStatus }) => {
  const { push } = useContext(ContextAlert);
  const [error, setError] = useState<string>('');
  const { mutateAsync } = useRequestResetPassword();
  const ref = useRef<HTMLInputElement>(null);

  const onClick = async () => {
    const email = ref.current?.value || '';
    const result = await Schema().validate(email).catch(err => {
      setError(err.message);
    });
    if (result) {
      setError('');
      mutateAsync({ email })
        .then(() => {
          setStatus(true);
        })
        .catch((err:IResponseError) => {
          const status = err.response?.status as number;
          if (status === 404) {
            push({ severity: 'error', message: strings.errorResponseUnknownEmail });
          } else if (status >= 400 && status < 500) {
            push({ severity: 'error', message: strings.errorResponse4 });
          } else {
            push({ severity: 'error', message: strings.errorResponse5 });
          }
        });
    }
  };
  return (
    <>
      <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageRequestResetTitle}</span>
      <span className={cls(textCss.pMedium2, css.description)}>{strings.loginPageRequestResetDescription}</span>
      <Input
        ref={ref}
        className={css.input}
        label={strings.inputEmailLabel}
        type="email"
        withError
        error={error}
        placeholder={strings.inputEmailPlaceholder}
      />
      <Button onClick={onClick} buttonType="primary" className={css.controls}>{strings.loginPageRequestResetButtonRequest}</Button>
    </>
  );
};

const Message = () => (
  <>
    <Icon type={ICON_TYPES.email} className={css.icon} />
    <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageRequestSentTitle}</span>
    <span className={cls(textCss.pMedium2, css.description)}>{strings.loginPageRequestSentDescription}</span>
  </>
);

function UserForgotPassword() {
  const [sendStatus, setStatus] = useState<boolean>(false);
  return (
    <Wrapper>
      {sendStatus ? <Message /> : <Form setStatus={setStatus} />}
    </Wrapper>
  );
}

export default UserForgotPassword;
