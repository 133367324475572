import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/library/Button';

import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';

const PortfolioAddNewButton: React.FC = () => (
  <NavLink to={ROUTES.ROUTE_ADD}>
    <Button
      type="button"
      buttonType="primary"
    >
      {strings.portfolioPageButtonAddPrintItem}
    </Button>
  </NavLink>
);

export default PortfolioAddNewButton;
