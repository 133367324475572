import React, { useMemo } from 'react';
import strings from 'constants/localization';
import { Grid } from '@mui/material';
import css from './ImageView.module.css';
import ObjectAlign from './components/ObjectAlign';
import ObjectRotate from './components/ObjectRotate';
import Opacity from './components/Opacity';
import useEditorData from '../../../../../components/Editor/hooks/useEditorData';
import Duplicate from './components/Duplicate';

const gridContainerProps = {
  container: true,
  columns: 4,
  spacing: '24px',
};

const gridItemProps = {
  item: true,
  xs: 1,
};

const ImageView:React.FC = () => {
  const {
    activeObject,
  } = useEditorData();

  const isBackground = useMemo<boolean>(() => !!activeObject?.isBackground, [activeObject]);

  return (
    <div className={css.container}>
      <div className={css.name}>
        { strings.editorObjectImageTitle }: { activeObject.name }
      </div>

      <ObjectAlign />
      <ObjectRotate />

      <div className={css.section}>
        <header>{strings.editorTabSettingsSectionImageEffectsTitle}</header>
        <Grid {...gridContainerProps}>
          <Grid {...gridItemProps}>
            <Opacity />
          </Grid>
          { isBackground
            ? <Grid {...gridItemProps}><Duplicate /></Grid>
            : null }
        </Grid>
      </div>
    </div>
  );
};

export default ImageView;
