import { useMutation } from 'react-query';
import { IResponseError } from 'constants/types';
import instance from 'lib/apiClient';
import { TUser } from 'types';

const client = instance.getClient();

export enum ERequestStrategy {
  LOCAL = 'local',
  EVENTBRITE_TOKEN = 'eventbriteToken',
}

export interface IResponseSuccess {
  accessToken: string,
  user: TUser
}

interface ILoginInput {
  email: string,
  password: string,
}

const login = (data: ILoginInput | ITokenInput, strategy: ERequestStrategy = ERequestStrategy.LOCAL): Promise<IResponseSuccess> => client
  .post('/authentication', { ...data, strategy })
  .then(res => res.data);

const QUERY_KEY = 'authentication';
export default function useAuthentication() {
  return useMutation<IResponseSuccess, IResponseError, ILoginInput>(
    [QUERY_KEY],
    data => login(data)
  );
}

interface ITokenInput {
  token: string,
}

// TODO: rewrite to 1 hook that supports types and strategy
export function useEventbriteAuthentication() {
  return useMutation<IResponseSuccess, IResponseError, ITokenInput>(
    [QUERY_KEY],
    data => login(data, ERequestStrategy.EVENTBRITE_TOKEN)
  );
}
