import React from 'react';
import cls from 'classnames';

import ArticlesControls from 'components/PageArticles/components/ArticlesControls';
import ArticlesStatus from 'components/PageArticles/components/ArticlesStatus';
import ArticlesTemplatesSlider from 'components/PageArticles/components/ArticlesTemplatesSlider';
import Tooltip from 'components/library/Tooltip';

import { ETablePageViews, TGetArticlesItem } from 'types';
import { IArticlesDisplayProps } from 'types/Articles';
import { displayArticleQuantities } from 'helpers';
import textCss from 'assets/styles/Text.module.css';
import css from './ArticlesGridView.module.css';
import ArticlesActionsPopover from '../ArticlesActionsPopover';
import ArticlesDescription from '../ArticlesDescription/ArticlesDescription';
import ArticlesTicketType from '../ArticlesTicketType';

interface IArticlesGridViewItem extends Omit<IArticlesDisplayProps, 'articles'> {
  article: TGetArticlesItem,
}

const ArticlesGridViewItem: React.FC<IArticlesGridViewItem> = ({
  article, isNotCustomer, handleArticleClick, ...deleteArticleActions
}) => {
  const {
    id,
    title,
    description,
    status,
    papers,
    templates,
    customerId: articleCustomerId,
    integrationTicketType,
  } = article;

  const handleContainerLinkClick = () => handleArticleClick(id);

  const qties: string = displayArticleQuantities(papers);

  return (
    <div className={cls(css.articleItem, { [css.itemForCustomer]: !isNotCustomer })} onClick={handleContainerLinkClick}>
      <div>
        {isNotCustomer && (
          <div className={css.headerControls}>
            <ArticlesStatus status={status} />
            <ArticlesActionsPopover articleId={id} customerId={articleCustomerId} />
          </div>
        )}
        <ArticlesTemplatesSlider templates={templates} />
        <div className={css.controls}>
          <div className={cls(css.articleTitle, textCss.h4)}>{title}</div>
          <ArticlesControls
            view={ETablePageViews.GRID}
            articleId={id}
            status={status}
            isNotCustomer={isNotCustomer}
            customerId={articleCustomerId}
            {...deleteArticleActions}
          />
        </div>
        <ArticlesDescription description={description} />
      </div>
      <div className={css.end}>
        <div>
          { integrationTicketType ? (<ArticlesTicketType ticketType={integrationTicketType} />) : (<div>&nbsp;</div>) }
          <Tooltip
            title={qties}
            className={cls(css.quantities, textCss.pMedium3)}
          >
            <span>{qties}</span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ArticlesGridViewItem;
