/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconDots({ passedRef, ...props }: IIconProps) {
  return (
    <svg ref={passedRef} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="5" r="2" fill="#2C3E66" />
      <circle cx="12" cy="12" r="2" fill="#2C3E66" />
      <circle cx="12" cy="19" r="2" fill="#2C3E66" />
    </svg>
  );
}

export default IconDots;
