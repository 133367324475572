import { nanoid } from 'nanoid';
import { ECanvasObjectTypes } from 'types';
import strings from 'constants/localization';
import fabric from '../../../helpers/fabric';
import { isDataUrl } from '../../../helpers/file';
import { extendCustomObject } from './extendCustomObject';

const defaultOptions = {
  lockScalingFlip: true,
  lockUniScaling: true,
  lockSkewingY: true,
  lockSkewingX: true,
  crossOrigin: 'anonymous',
};

fabric.ImageObject = fabric.util.createClass(fabric.Image, {
  type: ECanvasObjectTypes.IMAGE,
  title: strings.editorObjectImageTitle,
  id: '',
  isBackground: false,

  initialize(image: string, options?: any) {
    options = {
      ...defaultOptions,
      ...options,
      crossOrigin: 'anonymous',
    };

    this.callSuper('initialize', image, options);
    this.id = options?.id ?? nanoid();

    // update status when importing from json
    if (this.locked) {
      this.updateLock(true);
    }
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      title: this.title,
      locked: this.locked,
      name: this.name,
      crossOrigin: this.crossOrigin,
      isBackground: this.isBackground,
    });
  },
});

fabric.ImageObject.fromObject = (object: any, callback: Function) => {
  /* TODO: this string disables browser cache on images from s3.
       We have the problem with crossOrigin="anonymous" when cache has been expired
       and chrome tries to download an image again, but in cache it's without crossOrigin="anonymous"
       more details explained here:
       https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
  */
  const url = isDataUrl(object.src) ? object.src : `${object.src}?${Math.random()}`;

  // @ts-ignore
  fabric.util.loadImage(url, (img, isError: boolean) => {
    if (isError) {
      if (callback) callback(null, isError);
      return;
    }

    const oImg = new fabric.ImageObject(img, object);
    oImg._initConfig(object);
    oImg._initFilters(object);
    callback(oImg);
  }, null, 'anonymous');
};

fabric.util.object.extend(fabric.ImageObject.prototype, extendCustomObject);

export default fabric.ImageObject;
