import React from 'react';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import { Wrapper } from 'components/PageLogin';
import css from '../UserBlocked/UserBlocked.module.css';

const UserRestricted: React.FC = () => {
  localStorage.removeItem('token');
  return (
    <Wrapper>
      <Icon type={ICON_TYPES.blocked} className={css.icon} />
      <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageRestrictedTitle}</span>
      <span className={cls(textCss.pMedium2, css.description)}>
        {strings.loginPageRestrictedDescription}
      </span>
    </Wrapper>
  );
};

export default UserRestricted;
