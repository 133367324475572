import * as Yup from 'yup';
import strings from 'constants/localization';

export interface IChangePasswordValues {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
}

export const INITIAL_VALUES = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

export const VALIDATION_SCHEMA = () => Yup.object().shape({
  currentPassword: Yup.string()
    .required(strings.inputValidateRequiredField)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
    .min(6, strings.inputValidatePassLength),
  newPassword: Yup.string()
    .required(strings.inputValidateRequiredField)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
    .min(6, strings.inputValidatePassLength)
    .notOneOf([Yup.ref('currentPassword')], strings.inputValidateNotTheSameNewPass),
  confirmNewPassword: Yup.string()
    .required(strings.inputValidateRequiredField)
    .oneOf([Yup.ref('newPassword')], strings.inputValidatePassMatch)
});
