import React from 'react';
import Checkbox, { IInput } from './index';

export interface IArrayCheckbox extends Omit<IInput, 'checked'> {
  valueId: string,
  value: string[]
}
const ArrayCheckbox: React.FC<IArrayCheckbox> = ({ value, valueId, ...rest }) => {
  const isChecked: boolean = value.includes(valueId);

  return (
    <Checkbox
      checked={isChecked}
      value={valueId}
      {...rest}
    />
  );
};

export default ArrayCheckbox;
