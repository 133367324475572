import React, { useMemo } from 'react';
import { SingleValue } from 'react-select';
import { ColumnDef } from '@tanstack/react-table';

import Select from 'components/library/Select';

import textCss from 'assets/styles/Text.module.css';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import strings from 'constants/localization';
import css from './DeliveryData.module.css';
import { TExcelBindings, TExcelDataTableRow, TSelectMatchingFieldItem } from '../../types';
import { getCellValue } from '../../helpers';

interface IUseOrderDataTable {
  bindings: TExcelBindings,
  setBindings: React.Dispatch<React.SetStateAction<TExcelBindings>>,
  data: TRow[],
}

const useOrderDataTable = ({ data, bindings, setBindings }: IUseOrderDataTable): ColumnDef<TExcelDataTableRow>[] => {
  const selectValues = useMemo<TSelectMatchingFieldItem[]>(
    () => data[0].map((cell, index) => ({ label: getCellValue(cell), value: index })),
    [data]
  );

  const onSelectFieldChange = (newValue: SingleValue<TSelectMatchingFieldItem>, objectId: string) => {
    const value = newValue ? newValue.value : null;
    setBindings(prevState => ({ ...prevState, [objectId]: value }));
  };

  return useMemo<ColumnDef<TExcelDataTableRow>[]>(
    () => [
      {
        id: 'title',
        header: strings.editorExcelModalDeliveryDataValueColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { title, required } } }) => (
          <div className={textCss.pMedium2}>{title} { required && <span className={css.required}>*</span> }</div>
        )
      },
      {
        id: 'ticketField',
        header: strings.editorExcelModalDeliveryDataColumnColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { id } } }) => (
          <Select
            name="ticketField"
            className={css.select}
            options={selectValues}
            value={selectValues.find(value => value?.value === bindings[id])}
            onChange={v => onSelectFieldChange(v, id)}
            isClearable
          />
        ),
      },
      {
        id: 'value',
        header: strings.editorExcelModalDeliveryDataSampleColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { value } } }) => <div className={css.sample}>{value}</div>
      },
    ],
    [selectValues, bindings]
  );
};

export default useOrderDataTable;
