import { useMemo } from 'react';
import Fuse, { IFuseOptions } from 'fuse.js';
import { ITicketType } from './ChooseTicketModalContent/components/TicketTypeSelect';

const MIN_SEARCH_LENGTH = 2 as const;
const FUSE_OPTIONS: IFuseOptions<ITicketType> = {
  isCaseSensitive: false,
  minMatchCharLength: MIN_SEARCH_LENGTH,
  keys: ['id', 'name'],
  threshold: 0.2,
};

export const useFilteredTicketTypes = (list: ITicketType[], filter: string): ITicketType[] => useMemo<ITicketType[]>(
  () => {
    if (filter.length < MIN_SEARCH_LENGTH) {
      return list;
    }

    const fuse = new Fuse<ITicketType>(list, FUSE_OPTIONS);
    return fuse.search(filter).map(({ item }) => item);
  },
  [list, filter]
);

export default { useFilteredTicketTypes };
