import { TUser } from 'types';

export enum EAddCustomerModalSubmitModes {
  REDIRECT = 'redirect',
  SAVE = 'save',
  UNCHOSEN = '',
}

export type TCustomersFilters = {
  companies: string[],
  names: string[],
  surnames: string[],
  blocked: boolean | 'all',
};

export interface ICustomersTableCell {
  customerItem: TUser,
}
