import React, { useMemo } from 'react';
import useEditorData from '../../../components/Editor/hooks/useEditorData';
import css from './Sidebar.module.css';
import ToolsDescription from './ToolsDescription';
import GetStartedDescription from './GetStartedDescription';
import Settings from './Settings';

const Sidebar:React.FC = () => {
  const {
    pdfDocument,
    objects,
  } = useEditorData();

  const hasObjects = useMemo(() => objects.length > 0, [objects.length]);

  const component = useMemo(() => {
    if (!pdfDocument) return <GetStartedDescription />;
    if (!hasObjects) return <ToolsDescription />;
    return <Settings />;
  }, [pdfDocument, hasObjects]);

  return (
    <div className={css.container}>
      { component }
    </div>
  );
};

export default Sidebar;
