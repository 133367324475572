import React, { useMemo } from 'react';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import { ECanvasActiveSelection } from 'components/PageArticles/components/Editor/constants';
import Loader from 'components/library/Loader';
import { ECanvasObjectTypes } from 'types';
import TextView from './TextView';
import NumberingView from './NumberingView';
import ImageView from './ImageView';
import CodeView from './CodeView';
import ActiveSelectionView from './ActiveSelectionView';

const SettingsTab:React.FC = () => {
  const {
    activeObject,
  } = useEditorData();

  return useMemo(() => {
    switch (activeObject?.type) {
      case ECanvasObjectTypes.TEXT:
        return <TextView />;
      case ECanvasObjectTypes.NUMBERING:
        return <NumberingView />;
      case ECanvasObjectTypes.IMAGE:
        return <ImageView />;
      case ECanvasObjectTypes.CODE:
        return <CodeView />;
      case ECanvasActiveSelection.ACTIVE_SELECTION:
        return <ActiveSelectionView />;
      default:
        return <Loader />;
    }
  }, [activeObject?.type]);
};

export default SettingsTab;
