import React from 'react';
import { FormikErrors } from 'formik';

import { ITab } from 'components/library/Tabs';
import { ARTICLE_STATUS_OPTIONS, DEFAULT_ARTICLE_STATUS, ROUTES } from 'constants/constants';
import {
  EArticleStatuses,
  ECanvasObjectTypes,
  TArticle,
  TArticleCreateUpdate,
  TSize,
} from 'types';
import strings from 'constants/localization';
import { IArticleContext } from 'contexts/ContextArticle';
import {
  ETabs,
  IArticleFormValues,
  TFormFooterKeys,
  TFormikOptionComment,
  TFormikQuantity,
  TFormikSelectedOptionValue,
  TFormProductInfoKeys,
} from './constants';
import { IArticleForm } from './index';

export const ARTICLE_FORM_TABS: ITab<ETabs>[] = [
  { label: () => strings.articleFormTabProductInfo, value: ETabs.PRODUCT_INFO },
  { label: () => strings.articleFormTabProductSetup, value: ETabs.PRODUCT_SETUP },
  { label: () => strings.articleFormTabOptionsSetup, value: ETabs.OPTIONS_SETUP },
  { label: () => strings.articleFormTabTemplate, value: ETabs.TEMPLATE },
];

export const getTabIndexByKey = (key:ETabs, tabs: ITab<ETabs>[]):number => tabs.findIndex(tab => tab.value === key);

export const setFirstTabWithErrors = (
  errors: FormikErrors<IArticleFormValues>,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (errors.template) {
    setTabIndex(getTabIndexByKey(ETabs.TEMPLATE, ARTICLE_FORM_TABS));
  }
  if (errors.optionsSetup) {
    setTabIndex(getTabIndexByKey(ETabs.OPTIONS_SETUP, ARTICLE_FORM_TABS));
  }
  if (errors.productSetup) {
    setTabIndex(getTabIndexByKey(ETabs.PRODUCT_SETUP, ARTICLE_FORM_TABS));
  }
  if (errors.productInfo) {
    setTabIndex(getTabIndexByKey(ETabs.PRODUCT_INFO, ARTICLE_FORM_TABS));
  }
};

const getQuantities = (article: TArticle, create: IArticleForm['create']): TFormikQuantity[] => article
  .printItem.papers.flatMap(({ id: paperId }) => article
    .printItem.quantities.map(({ id, value }): TFormikQuantity => {
      const selectedQty = article
        .papers.find(({ paper: { id: articlePaperId } }) => articlePaperId === paperId)
        ?.quantities.find(({ id: articleQtyId }) => articleQtyId === id);
      const isSelected: boolean = create ? true : !!selectedQty;
      const isDisabled: boolean = article.status === EArticleStatuses.PUBLISHED ? isSelected : false;

      return {
        qtyId: id,
        paperId,
        value,
        isSelected,
        isDisabled,
      };
    }));

const getOptionComments = (article: TArticle): TFormikOptionComment[] => {
  const printItemOptions = article.printItem.options.map(({ id, comment }): TFormikOptionComment => ({
    optionId: id,
    comment: comment || '',
  }));

  const articleOptions = article.options.map(({ option: { id }, comment }): TFormikOptionComment => ({
    optionId: id,
    comment: comment || ''
  }));
  printItemOptions.forEach(printItemOption => {
    if (!articleOptions.find(({ optionId }) => optionId === printItemOption.optionId)) {
      articleOptions.push(printItemOption);
    }
  });
  return articleOptions;
};

const getOptionValues = (article: TArticle): TFormikSelectedOptionValue[] => article.options
  .flatMap(({ values, option: { id: optionId } }) => values?.map(({ id }) => ({
    optionId,
    optionValueId: id,
  })) || []);

export const getReadyOptionValuesStringArr = (selectedOptV: TFormikSelectedOptionValue[]): string[] => selectedOptV.map(item => item.optionValueId);

export const getInitialValues = (
  article: TArticle,
  create: IArticleForm['create'],
  contextValues: IArticleContext['articleFormInitialValues']
): IArticleFormValues => {
  if (contextValues) {
    return contextValues;
  }
  return {
    productInfo: {
      title: article.title,
      skuIdTitle: article.skuIdTitle,
      description: article.description,
    },
    productSetup: {
      special: Boolean(article.special),
      paperComment: article.paperComment,
      quantities: getQuantities(article, create),
      size: article.size,
      sizeComment: article.selectedSizeComment,
      selectedPapers: article.papers.map(({ paper: { id } }) => id),
    },
    optionsSetup: {
      optionComments: getOptionComments(article),
      selectedOptionValues: getOptionValues(article),
    },
    template: {
      frontSide: article.frontSide,
      backSide: article.backSide,
    },
    footer: {
      status: ARTICLE_STATUS_OPTIONS.find(({ value }) => value === article.status) || DEFAULT_ARTICLE_STATUS
    }
  };
};

const getNumberingStartValue = ({ values, article }: IPrepareFormData) => {
  const { template } = values;

  // Get numbering object from Article>camvas
  const findFunc = (object: any) => object.type === ECanvasObjectTypes.NUMBERING;
  const numberingObject = Object.values(template)
    .filter(n => !!n)
    .map(side => side.canvas.objects.find(findFunc))
    .filter(n => !!n)
    .find(findFunc);

  if (!numberingObject) {
    return null;
  }

  // If SKU is NEW/COPIED then use value from canvas
  if (article.status === EArticleStatuses.DRAFT) {
    return numberingObject?.startValue;
  }

  // If SKU is NEW or COPIED (article>numberingStartValue is null)
  if (numberingObject?.startValue && typeof article.numberingStartValue === 'undefined') {
    return numberingObject?.startValue;
  }

  // When editing published sku (comments etc.)
  return article.numberingStartValue;
};

export interface IPrepareFormData {
  values: IArticleFormValues,
  article: TArticle,
}
export const prepareFormDataToSave = ({ values, article }: IPrepareFormData): TArticleCreateUpdate => {
  const {
    productInfo, productSetup, optionsSetup, footer, template
  } = values;
  const { optionComments, selectedOptionValues } = optionsSetup;

  const getProductSetupValues = () => ({
    selectedSizeId: (productSetup.size as TSize).id,
    selectedPapers: productSetup.selectedPapers,
    selectedQuantities: productSetup.quantities
      .filter(({ isSelected, paperId }) => isSelected && productSetup.selectedPapers.includes(paperId))
      .map(({ qtyId, paperId }) => ({
        qtyId,
        paperId
      })),
    selectedSizeComment: productSetup.sizeComment,
    paperComment: productSetup.paperComment,
    special: productSetup.special,
  });

  return {
    ...productInfo,
    ...getProductSetupValues(),
    optionComments,
    // TODO: parse field below on server after validation? Case when we need to check max 1 option value chosen in 1 option
    selectedOptionValues: getReadyOptionValuesStringArr(selectedOptionValues),
    status: footer.status.value,
    printItemId: article.printItemId,
    customerId: article.customerId,
    currency: article.currency,
    copyIndex: article.copyIndex,
    originalId: article.originalId,
    integrationEventBindId: article.integrationEventBindId,
    canvas: Object.values(template).filter(n => !!n),
    numberingStartValue: getNumberingStartValue({ values, article }),
  };
};

export type TProductInfoDisabled = Record<TFormProductInfoKeys, boolean>;
export const getProductInfoDisabled = ({
  status, title, skuIdTitle, description
}: TArticle): TProductInfoDisabled | undefined => {
  if (status === EArticleStatuses.DRAFT) {
    return undefined;
  }
  return {
    title: !!title,
    skuIdTitle: !!skuIdTitle,
    description: !!description,
  };
};

export type TProductSetupDisabled = {
  size: boolean,
  sizeComment: boolean,
  paperComment: boolean,
  selectedPapers: Record<string, boolean>,
}
export const getProductSetupDisabled = ({
  status, size, selectedSizeComment, paperComment, papers
}: TArticle): TProductSetupDisabled | undefined => {
  if (status === EArticleStatuses.DRAFT) {
    return undefined;
  }

  const selectedPapers: TProductSetupDisabled['selectedPapers'] = {};
  papers.forEach(({ paper: { id } }) => {
    selectedPapers[id] = true;
  });

  return {
    size: !!size,
    sizeComment: !!selectedSizeComment,
    paperComment: !!paperComment,
    selectedPapers,
  };
};

export type TOptionsSetupDisabled = {
  optionComments: Record<string, boolean>,
  selectedOptionValues: Record<string, boolean>,
}
export const getOptionsSetupDisabled = ({
  status, options
}: TArticle): TOptionsSetupDisabled | undefined => {
  if (status === EArticleStatuses.DRAFT) {
    return undefined;
  }

  const optionComments: TOptionsSetupDisabled['optionComments'] = {};
  const selectedOptionValues: TOptionsSetupDisabled['selectedOptionValues'] = {};
  options.forEach(({ option: { id }, comment, values }) => {
    optionComments[id] = !!comment;
    values?.forEach(({ id: optionValueId }) => {
      selectedOptionValues[optionValueId] = true;
    });
  });

  return {
    optionComments,
    selectedOptionValues,
  };
};

export type TFooterDisabled = Record<TFormFooterKeys, boolean>;
export const getFooterDisabled = ({
  status
}: TArticle): TFooterDisabled | undefined => {
  if (status === EArticleStatuses.DRAFT) {
    return undefined;
  }
  return {
    status: true,
  };
};

export const getSizeLabel = (size: TSize, units: string) => `${size.name} (${size.width} x ${size.height} ${units})`;

export const getRoutesExceptions = (currentPath: string): string[] => [
  [currentPath, ROUTES.ARTICLES_SELECT_PRINT_ITEM].join('/'),
  [currentPath, ROUTES.TEMPLATE_EDITOR].join('/'),
];
