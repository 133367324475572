import FileSaver from 'file-saver';
import { TReportType } from 'types/Reports/Orders';
import moment from 'moment';

const getFileName = (type: TReportType): string => {
  const now = moment().utc().format('YYYY-MM-DD');
  return [
    'report-orders',
    '_',
    now,
    '.',
    type,
  ].join('');
};

const getFileMimeType = (type: TReportType): string => {
  switch (type) {
    case 'json':
      return 'application/json;charset=utf-8';
    case 'csv':
      return 'text/csv;charset=utf-8';
    default:
      return 'text/plain;charset=utf-8';
  }
};

const transformFileData = (data: any, type: TReportType): string => {
  switch (type) {
    case 'json':
      return JSON.stringify(data);
    case 'csv':
      return data;
    default:
      return data;
  }
};

export const saveAs = (data: any, type: TReportType) => {
  const blob = new Blob([transformFileData(data, type)], { type: getFileMimeType(type) });
  FileSaver.saveAs(blob, getFileName(type));
};

export default { saveAs };
