import React, { forwardRef } from 'react';
import classNames from 'classnames';
import Button from 'components/library/Button';
import css from './SidebarButton.module.css';

export interface ISidebarButton extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode,
  isActive?: boolean,
  onClick: () => void,
  disabled?: boolean
  buttonType?: 'transparent' | 'white'
}

const SidebarButton = forwardRef<HTMLButtonElement, ISidebarButton>((props, ref) => {
  const {
    children,
    isActive = false,
    className,
    buttonType = 'transparent',
    ...rest
  } = props;

  return (
    <Button
      ref={ref}
      buttonType="tertiary"
      buttonStyle="reducedHeight"
      type="button"
      className={classNames(css.button, {
        [css.active]: isActive,
        [css.transparent]: buttonType === 'transparent',
        [css.white]: buttonType === 'white',
      }, className)}
      {...rest}
    >
      { children }
    </Button>
  );
});

export default SidebarButton;
