import React, { useMemo, useState } from 'react';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Button from 'components/library/Button';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import { IObject } from '../../../../../../components/Editor/contexts/EditorContext';
import { EViewportModes } from '../../../../../../components/Editor/constants';
import css from './LockButton.module.css';
import Tooltip from '../../../../../../../library/Tooltip';
import strings from '../../../../../../../../constants/localization';
import { ECanvasObjectTypes } from '../../../../../../../../types';

interface ILockButton {
  object: IObject['object'],
  isUsedInMatchings: boolean,
}

const LockButton:React.FC<ILockButton> = ({ object, isUsedInMatchings }) => {
  const {
    mode,
    canvas,
  } = useEditorData();

  const disabled = useMemo(() => mode === EViewportModes.Navigate || isUsedInMatchings, [mode, isUsedInMatchings]);
  const icon = useMemo(() => object.locked ? ICON_TYPES.altLocked : ICON_TYPES.altUnLocked, [object, object.locked]);

  const handleClick = () => {
    if (object && canvas.current) {
      if (mode === EViewportModes.Navigate) {
        return;
      }

      const isObjectSelected: boolean = !!canvas.current.getActiveObjects().find(({ id }: any) => id === object.id);
      if (isObjectSelected) {
        canvas.current.discardActiveObject();
      }

      object.updateLock(!object.locked);
      canvas.current.fire('object:modified', { target: object });
    }
  };

  return (
    <Button
      className={css.button}
      disabled={disabled}
      buttonType="tertiary"
      type="button"
      onClick={handleClick}
    >
      <Icon type={icon} />
    </Button>
  );
};

const Container: React.FC<ILockButton> = props => {
  const { isUsedInMatchings, object } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const hasButton = useMemo(() => object.type !== ECanvasObjectTypes.NUMBERING, [object.type]);

  const onTooltipOpen = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  if (!hasButton) {
    return null;
  }

  if (isUsedInMatchings) {
    return (
      <Tooltip
        open={tooltipOpen}
        title={strings.editorLockedButtonDisabledDueToMatchingsTooltip}
        onOpen={onTooltipOpen}
      ><LockButton {...props} /></Tooltip>
    );
  }

  return <LockButton {...props} />;
};

export default Container;
