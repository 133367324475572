import { nanoid } from 'nanoid';
import { ECanvasObjectTypes } from 'types';

import fabric, { groupSVGToCodeObject } from '../../../helpers/fabric';
import createQrCode from '../../../helpers/qr';
import createBarCode from '../../../helpers/barcode';
import { extendCustomObject } from './extendCustomObject';

export enum ECodeObjectTitle {
  QR = 'QR',
  BARCODE = 'Barcode',
}

const defaultOptions = {
  lockScalingFlip: true,
  lockUniScaling: true,
  lockSkewingY: true,
  lockSkewingX: true,
};

fabric.CodeObject = fabric.util.createClass(fabric.Group, {
  type: ECanvasObjectTypes.CODE,
  title: ECodeObjectTitle.QR,
  id: '',
  locked: false,
  content: '', // will be inserted during object adding via corresponding button
  margin: 1,
  barcodeHeight: 100,

  initialize(elements: Object[], options?: any) {
    options = {
      ...defaultOptions,
      ...options,
    };

    this.callSuper('initialize', elements, options);
    this.id = options?.id ?? nanoid();

    // update status when importing from json
    if (this.locked) {
      this.updateLock(true);
    }

    this.setControlsVisibility({
      mt: false, mb: false, ml: false, mr: false
    });
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      title: this.title,
      locked: this.locked,
      name: this.name,
      margin: this.margin,
      barcodeHeight: this.barcodeHeight,
      content: this.content,
    });
  },

  async changeType(type: ECodeObjectTitle): Promise<void> {
    this.title = type;
    return this.generate();
  },

  async changeContent(content: string): Promise<void> {
    this.set('content', content);
    return this.generate();
  },

  async generate(): Promise<void> {
    let svg = '';

    const {
      title,
      content,
      width,
      margin,
      barcodeHeight
    } = this;

    switch (title) {
      case ECodeObjectTitle.QR:
        svg = await createQrCode(content, { width, margin });
        break;
      case ECodeObjectTitle.BARCODE:
        svg = await createBarCode(this.content, { margin, height: barcodeHeight });
        break;
      default:
    }

    return new Promise(resolve => {
      fabric.loadSVGFromString(svg, (objects, options) => {
        const group = groupSVGToCodeObject(objects, options);
        this.remove(...this._objects);
        this.add(...group._objects);
        this.set({ width: group.width, height: group.height }).setCoords();
        resolve();
      });
    });
  },
});

fabric.CodeObject.fromObject = (object: any, callback: Function) => {
  fabric.util.enlivenObjects(object.objects, (enlivenedObjects: any[]) => {
    const newObject = new fabric.CodeObject([], object);
    newObject.remove(...newObject._objects);
    newObject.add(...enlivenedObjects);
    callback(newObject);
  }, 'fabric');
};

fabric.util.object.extend(fabric.CodeObject.prototype, extendCustomObject);

export default fabric.CodeObject;
