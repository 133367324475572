import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import cls from 'classnames';

import { TUser } from 'types';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import ActionHeader from 'components/library/Table/components/ActionHeader';
import { IColumnsActions } from 'components/library/Table/types';
import css from './Printers.module.css';
import { getUserFullName } from '../../helpers';
import SimpleHeader from '../library/Table/components/SimpleHeader';
import Tooltip from '../library/Tooltip';

const useColumns = ({ onBlock, filterBlocked, setFilterBlocked }: IColumnsActions):ColumnDef<TUser>[] => useMemo<ColumnDef<TUser>[]>(() => [
  {
    id: 'name',
    header: () => <div className={css.displayFlex}><SimpleHeader title={strings.printersTableHeaderPrinter} /></div>,
    meta: {
      className: css.column
    },
    cell: ({ row: { original } }) => {
      const { companyName } = original;
      const fullName = getUserFullName(original);
      return (
        <div className={css.columnNames}>
          <div className={css.displayFlex}>
            <Tooltip title={companyName} placement="top">
              <span className={textCss.h4}>{companyName}</span>
            </Tooltip>
          </div>
          <div className={css.displayFlex}>
            <Tooltip title={fullName} placement="top">
              <span className={textCss.pMedium2}>{fullName}</span>
            </Tooltip>
          </div>
        </div>
      );
    }
  },
  {
    id: 'contacts',
    header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadContacts} /></div>,
    meta: {
      className: css.column
    },
    cell: props => {
      const { phoneNumber, email } = props.row.original;
      return (
        <div className={css.columnNames}>
          <div className={css.displayFlex}>
            <Tooltip title={email as string} placement="top">
              <span className={textCss.pMedium2}>{email}</span>
            </Tooltip>
          </div>
          {phoneNumber && (
            <div className={css.displayFlex}>
              <Tooltip title={phoneNumber} placement="top">
                <span className={textCss.pLight3}>{phoneNumber}</span>
              </Tooltip>
            </div>
          )}
        </div>
      );
    }
  },
  {
    id: 'actions',
    header: () => <ActionHeader className={css.columnAction} setFilterBlocked={setFilterBlocked} filterBlocked={filterBlocked} />,
    meta: {
      className: cls(css.column, css.cellCentered),
    },
    cell: props => {
      const { row: { original } } = props;
      return (
        <div
          className={cls(css.cellAction, { [css.locked]: original.blocked })}
          onClick={ev => {
            ev.stopPropagation();
            onBlock(original.id, original);
          }}
        >
          <Icon type={original.blocked ? E_ICON_TYPE.locked : E_ICON_TYPE.unlocked} />
        </div>
      );
    }
  },
], [filterBlocked]);

export default useColumns;
