import { RefObject, useState } from 'react';
import fabric from '../../helpers/fabric';
import { FIELDS_TO_EXPORT } from '../../components/Viewport/Canvas';
import { getObjectValidationSchema } from './validationSchema';
import getObjectModifier from './modifiers';

export interface FabricObject {
  object: typeof fabric.TextObject | typeof fabric.CodeObject | typeof fabric.ImageObject
}

export interface IUseFabricObjects {
  setObjects: (objects: Partial<FabricObject['object']>[], initialObjects?: Partial<FabricObject['object']>[]) => Promise<void>,
  setObject: (object: Partial<FabricObject['object']>, initialObject?: Partial<FabricObject['object']>) => Promise<void>,
  json: object,
  errors: Error[],
}

const useFabricObjects = (canvas: RefObject<fabric.Canvas | null>): IUseFabricObjects => {
  const [errors, setErrors] = useState<Error[]>([]);
  const setError = (error: Error) => setErrors([...errors, error]);

  const [json, setJson] = useState<any>({});

  const setObject = async (object: Partial<FabricObject['object']>, initialObject?: Partial<FabricObject['object']>): Promise<void> => {
    setErrors([]);
    const initialObjects: Partial<FabricObject['object']>[] = [];
    if (initialObject) initialObjects.push(initialObject);

    const fabricObject = _getClearFabricObject(object.id, initialObjects);
    await _setObject(fabricObject, object);
    if (canvas.current) {
      canvas.current.requestRenderAll();
      setJson(canvas.current.toJSON(FIELDS_TO_EXPORT));
    }
  };

  const setObjects = async (objects: Partial<FabricObject['object']>[], initialObjects: Partial<FabricObject['object']>[] = []): Promise<void> => {
    setErrors([]);
    const promises = objects.map(object => {
      const fabricObject = _getClearFabricObject(object.id, initialObjects);
      if (!fabricObject) return;
      return _setObject(fabricObject, object);
    });

    await Promise.all(promises);
    if (canvas.current) {
      canvas.current.requestRenderAll();
      setJson(canvas.current.toJSON(FIELDS_TO_EXPORT));
    }
  };

  const _getClearFabricObject = (objectId: string, initialObjects: Partial<FabricObject['object']>[] = []): FabricObject['object'] | undefined => {
    const currentObject: FabricObject['object'] = canvasObjects.find(o => o.id === objectId);

    if (!currentObject) {
      setError(new Error(`Object ${objectId} doesn't exists`));
      return;
    }

    // reset currentObject to initial state
    const initialObject = initialObjects.find(o => o?.id === currentObject?.id);
    if (initialObject) currentObject.set(initialObject);

    return currentObject;
  };

  const _setObject = async (fabricObject: FabricObject['object'], data: Partial<FabricObject['object']>): Promise<void> => {
    if (!data.id) data.id = fabricObject.id;

    try {
      const validator = getObjectValidationSchema(fabricObject.type);
      const validData = await validator.validate(data);

      const modifier = getObjectModifier(fabricObject.type);
      await modifier(fabricObject, validData);
    } catch (e: any) {
      setError(e);
    }
  };

  const canvasObjects: FabricObject['object'][] = canvas.current ? canvas.current.getObjects() : [];

  return {
    setObjects,
    setObject,
    json,
    errors,
  };
};

export default useFabricObjects;
