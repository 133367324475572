import React, { ChangeEvent, useState } from 'react';
import Label from 'components/library/Label';
import Input from 'components/library/Input';
import { ValidationError } from 'yup';
import get from 'lodash/get';

export interface ITicketTypeInput {
  label: string,
  value: string,
  validationSchema: any,
  onValueChange: React.Dispatch<React.SetStateAction<string>>,
  required?: boolean
}

const TicketTypeSelect: React.FC<ITicketTypeInput> = props => {
  const {
    label,
    value: initValue,
    validationSchema,
    onValueChange,
    required = true,
  } = props;

  const [value, setValue] = useState<string>(initValue);
  const [error, setError] = useState<string>('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = get(e, 'target.value', '');
    setValue(newValue);

    try {
      validationSchema.validateSync(e.target.value);
      onValueChange(newValue);
      setError('');
    } catch (err) {
      onValueChange('');
      if (err instanceof ValidationError) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <Label text={label} />
      <Input
        onChange={onChange}
        value={value}
        withError={!!error}
        error={error}
        required={required}
      />
    </>
  );
};

export default TicketTypeSelect;
