import React, {
  useMemo, useState
} from 'react';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';

import Table from 'components/library/Table';

import {
  IPrintItemFormik, TPricingTableItem, TPrintItemPricingsError, TPrintItemPricingsTouched
} from 'types/Portfolio';
import usePrevious from 'hooks/usePrevious';
import { groupPricingsBySizeName } from './helpers';
import { createTableSubRows, pricingsPagesHook } from '../../helpers';
import useColumns from './useColumns';

const Pricing: React.FC<IPrintItemFormik> = ({ formik }) => {
  const {
    errors: {
      pricings: pricingsErrors,
    },
    values: {
      itemInformation: { units },
      pricings,
    },
    touched: { pricings: pricingsTouched },
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const prevExpanded = usePrevious<ExpandedState | undefined>(expanded);

  const firstFieldWithError = useMemo<number>(
    () => Array.isArray(pricingsErrors) ? pricingsErrors.findIndex(err => err) : -1,
    [pricingsErrors]
  );

  const pricingsData = useMemo(
    (): TPricingTableItem[] => {
      const pricingsBySize = groupPricingsBySizeName(
        pricings,
        pricingsErrors as TPrintItemPricingsError[],
        pricingsTouched as TPrintItemPricingsTouched[],
        units
      );
      return createTableSubRows(pricingsBySize);
    },
    [pricings, pricingsErrors, pricingsTouched, units]
  );

  const fieldWithErrorRef = pricingsPagesHook({
    isSubmitting,
    errIdx: firstFieldWithError,
    pricings: pricingsData,
    prevExpanded,
    setExpanded,
  });

  const columns = useColumns({
    handleChange, handleBlur, setFieldValue, firstFieldWithError, fieldWithErrorRef
  });

  return (
    <Table<TPricingTableItem>
      data={pricingsData}
      columns={columns}
      state={{ expanded }}
      onExpandedChange={setExpanded}
      getSubRows={row => row.subRows}
      getExpandedRowModel={getExpandedRowModel()}
    />
  );
};

export default React.memo(Pricing);
