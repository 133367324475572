/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFile(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.21799 2.09202C4 2.51984 4 3.0799 4 4.2V19.8C4 20.9201 4 21.4802 4.21799 21.908C4.40973 22.2843 4.71569 22.5903 5.09202 22.782C5.51984 23 6.0799 23 7.2 23H16.8C17.9201 23 18.4802 23 18.908 22.782C19.2843 22.5903 19.5903 22.2843 19.782 21.908C20 21.4802 20 20.9201 20 19.8V8H16.2C15.0799 8 14.5198 8 14.092 7.78201C13.7157 7.59027 13.4097 7.28431 13.218 6.90798C13 6.48016 13 5.9201 13 4.8V1H7.2C6.0799 1 5.51984 1 5.09202 1.21799C4.71569 1.40973 4.40973 1.71569 4.21799 2.09202ZM8 11.1C7.50294 11.1 7.1 11.5029 7.1 12C7.1 12.4971 7.50294 12.9 8 12.9H16C16.4971 12.9 16.9 12.4971 16.9 12C16.9 11.5029 16.4971 11.1 16 11.1H8ZM7.1 15.5C7.1 15.0029 7.50294 14.6 8 14.6H16C16.4971 14.6 16.9 15.0029 16.9 15.5C16.9 15.9971 16.4971 16.4 16 16.4H8C7.50294 16.4 7.1 15.9971 7.1 15.5ZM8 18.1C7.50294 18.1 7.1 18.5029 7.1 19C7.1 19.4971 7.50294 19.9 8 19.9H12C12.4971 19.9 12.9 19.4971 12.9 19C12.9 18.5029 12.4971 18.1 12 18.1H8Z" fill="#BCDEF0" />
      <path opacity="0.5" d="M13 4.8V1L20 8H16.2C15.0799 8 14.5198 8 14.092 7.78201C13.7157 7.59027 13.4097 7.28431 13.218 6.90798C13 6.48016 13 5.9201 13 4.8Z" fill="#BCDEF0" />
    </svg>
  );
}

export default IconFile;
