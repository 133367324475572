/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextUnderline(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M12.79 16.95C15.82 16.56 18 13.84 18 10.79V4.25C18 3.91848 17.8683 3.60054 17.6339 3.36612C17.3995 3.1317 17.0815 3 16.75 3C16.4185 3 16.1005 3.1317 15.8661 3.36612C15.6317 3.60054 15.5 3.91848 15.5 4.25V10.9C15.5 12.57 14.37 14.09 12.73 14.42C12.22 14.5299 11.6919 14.5242 11.1844 14.4033C10.6769 14.2824 10.2029 14.0494 9.79724 13.7214C9.39156 13.3934 9.06448 12.9788 8.84 12.5078C8.61552 12.0369 8.49934 11.5217 8.5 11V4.25C8.5 3.91848 8.3683 3.60054 8.13388 3.36612C7.89946 3.1317 7.58152 3 7.25 3C6.91848 3 6.60054 3.1317 6.36612 3.36612C6.1317 3.60054 6 3.91848 6 4.25V11C6 14.57 9.13 17.42 12.79 16.95ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20C19 19.45 18.55 19 18 19H6C5.45 19 5 19.45 5 20Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextUnderline;
