import React, { useMemo } from 'react';
import Slider, { Settings } from 'react-slick';

import { TGetArticlesItemTemplate } from 'types';
import strings from 'constants/localization';
import css from './ArticlesTemplatesSlider.module.css';
import SliderArrow from './components/SliderArrow';

const settings: Settings = {
  dots: true,
  nextArrow: <SliderArrow type="next" />,
  prevArrow: <SliderArrow type="prev" />,
  appendDots: dots => {
    const onCLickHandler = (event: React.MouseEvent) => event.stopPropagation();
    return <div className={css.dots} onClick={onCLickHandler}> {dots} </div>;
  },
  className: css.sliderContainer,
};

interface IArticlesTemplatesSlider {
  templates: TGetArticlesItemTemplate[]
}

const ArticlesTemplatesSlider: React.FC<IArticlesTemplatesSlider> = ({ templates }) => {
  const templatePreviewLinks = useMemo(
    () => templates
      .filter(({ thumbnail }) => thumbnail && thumbnail !== '')
      .map(({ thumbnail }) => thumbnail),
    [templates]
  );

  return (
    <Slider {...settings}>
      {templatePreviewLinks.map(link => (
        <div key={link} className={css.sliderItem}>
          <img src={link} alt={strings.pdfPreview} />
        </div>
      ))}
    </Slider>
  );
};

export default ArticlesTemplatesSlider;
