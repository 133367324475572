import React, { useMemo, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import Popover from 'components/library/Popover';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';
import SidebarSlider from '../../../components/SidebarSlider';
import css from './Opacity.module.css';

const PERCENT_FACTOR = 100;
const ADORNMENT = '%';

const Opacity:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = (newValue: number) => {
    if (canvas.current) {
      activeObject.set({ opacity: newValue / PERCENT_FACTOR });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const value = useMemo(() => activeObject.opacity * PERCENT_FACTOR, [activeObject.opacity]);

  return (
    <>
      <Tooltip placement="top" title={strings.editorTabSettingsOpacityTooltip} arrow>
        <SidebarButton
          ref={ref}
          isActive={popoverState}
          onClick={() => setPopoverState(!popoverState)}
        >
          <Icon type={ICON_TYPES.opacity} />
        </SidebarButton>
      </Tooltip>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <SidebarSlider
          onChange={handleChange}
          value={value}
          withInput
          inputAdornment={ADORNMENT}
        />
      </Popover>
    </>
  );
};

export default Opacity;
