import React from 'react';

import { EFooterSaveButtonDisplayModes } from 'types';
import css from './FooterContent.module.css';
import { TAB_COUNT } from '../../constants';
import FooterActionButtons from '../../../library/FooterActionButtons';
import FooterCancelButton from '../../../library/FooterActionButtons/components/FooterCancelButton';

interface IFooter extends React.HTMLProps<HTMLDivElement> {
  create?: boolean,
  onClickSave: () => void,
  disableSave: boolean,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>,
}
const FooterContent:React.FC<IFooter> = ({
  create, onClickSave, disableSave, tabIndex, setTabIndex
}) => (
  <div className={css.footer}>
    <FooterCancelButton />
    <FooterActionButtons
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      tabCount={TAB_COUNT}
      onClickSave={onClickSave}
      disableSave={disableSave}
      saveBtnDisplayMode={create ? EFooterSaveButtonDisplayModes.ONLY_ON_LAST_TAB : EFooterSaveButtonDisplayModes.ALWAYS}
    />
  </div>
);

export default FooterContent;
