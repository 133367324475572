import { useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { TArticle } from 'types';
import { getArticleGetQuery } from 'helpers';
import { STALE_TIME } from 'constants/constants';
import { IGetArticleParams } from 'types/Articles';

const client = instance.getClient();

export const GET_ARTICLE_QUERY = ['articles', 'get'];

export const getArticle = (params: IGetArticleParams): Promise<TArticle> => {
  const query: string = getArticleGetQuery(params);
  return client.get(`/articles/${params.id}${query}`).then(res => res.data);
};

const useGetArticle = (data: IGetArticleParams) => useQuery<TArticle, IResponseError>(
  [...GET_ARTICLE_QUERY, data.customerId, data.pspId, data.id, data.copy],
  () => getArticle(data),
  {
    enabled: !!(data.id && data.customerId && data.pspId && (data.enabled ?? true)),
    staleTime: STALE_TIME,
    cacheTime: 0,
  }
);

export const useInvalidateGetArticle = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...GET_ARTICLE_QUERY, ...additionalParams]);
};

export default useGetArticle;
