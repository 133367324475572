import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from 'components/library/Icon';
import strings from 'constants/localization';
import { ROUTES } from 'constants/constants';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import { E_ICON_TYPE } from '../../../library/Icon/types';
import Popover from '../../../library/Popover';
import css from './ArticlesActionsPopover.module.css';
import Button from '../../../library/Button';

interface IArticlesControls {
  articleId: string,
  customerId: string,
}

const ArticlesActionsPopover: React.FC<IArticlesControls> = ({ customerId, articleId }) => {
  const location = useLocation();
  const ref = useRef(null);
  const [state, setState] = useState(false);
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, status: boolean) => {
    e.stopPropagation();
    setState(status);
  };
  return (
    <div onClick={e => e.stopPropagation()}>
      <Button
        buttonType="tertiary"
        buttonStyle="circle"
        ref={ref}
        onClick={e => onClick(e, true)}
      >
        <Icon type={E_ICON_TYPE.dots} />
      </Button>
      <Popover
        anchorEl={ref.current}
        open={state}
        onClose={() => setState(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <NavLink to={`/${ROUTES.ARTICLES}/${ROUTES.ROUTE_ADD}${location.search}`} state={{ customerId, articleId }}>
          <span className={cls(textCss.pMedium3, css.text)}>{strings.articlesDuplicateSKU}</span>
        </NavLink>
      </Popover>
    </div>
  );
};

export default ArticlesActionsPopover;
