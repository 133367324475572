import React, { useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import cls from 'classnames';
import AlertContext from 'contexts/ContextAlert';
import strings from 'constants/localization';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import textCss from 'assets/styles/Text.module.css';
import Tooltip from 'components/library/Tooltip';
import css from './UploadFile.module.css';

const FILE_TYPES = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.csv'];

interface IUploadFile {
  setFile: (file:File) => void,
  loading: boolean
}
const UploadFile:React.FC<IUploadFile> = ({ setFile, loading }) => {
  const alertContext = useContext(AlertContext);
  const onDrop = ([file]:File[]) => setFile(file);
  const {
    getRootProps, getInputProps, isDragActive, fileRejections
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      file: FILE_TYPES
    },
    useFsAccessApi: false
  });
  useEffect(() => {
    if (fileRejections.length) {
      alertContext.push({ message: strings.orderPageModalUploadListUploadAreaReject, severity: 'error' });
    }
  }, [fileRejections]);
  return (
    <>
      {strings.orderPageModalUploadListDescription}
      <div
        {...getRootProps()}
        className={cls(css.dropdownArea, {
          [css.dragActive]: isDragActive
        })}
      >
        <input {...getInputProps()} />
        {!isDragActive && !loading && <Icon type={E_ICON_TYPE.upload} strokeWidth={1.4} />}
        <span className={textCss.h4}>
          {isDragActive && strings.orderPageModalUploadListUploadAreaActive}
          {loading && strings.orderPageModalUploadListUploadAreaTextLoading}
          {!isDragActive && !loading && strings.orderPageModalUploadListUploadAreaText}
        </span>
      </div>
      <div className={css.bottomControls}>
        <Tooltip
          title={(
            // eslint-disable-next-line react/no-danger
            <div className={css.tooltip} dangerouslySetInnerHTML={{ __html: strings.orderPageModalUploadListFileRequirementsList }} />
          )}
        >
          <div className={css.controls}>
            <Icon type={E_ICON_TYPE.info} />
            <span className={textCss.pMedium3}>
              {strings.orderPageModalUploadListFileRequirements}
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default React.memo(UploadFile);
