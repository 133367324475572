import { nanoid } from 'nanoid';
import { ECanvasObjectTypes, ETextCase } from 'types';
import strings from 'constants/localization';
import { formatText } from 'helpers';
import fabric from '../../../helpers/fabric';
import { extendCustomObject } from './extendCustomObject';

const defaultOptions = {
  lockSkewingY: true,
  lockSkewingX: true,
  lockScalingFlip: true,
  lockUniScaling: true,
  fontSize: 40,
  fontWeight: 400,
  fontFamily: 'Helvetica',
  textCase: ETextCase.Default,
  editable: false,
};

fabric.NumberingObject = fabric.util.createClass(fabric.Textbox, {
  type: ECanvasObjectTypes.NUMBERING,
  title: strings.editorObjectTextTitle,
  id: '',
  prefix: '',
  pattern: 1,
  startValue: 1,
  _wordJoiners: /[\t\r]/,

  initialize(text: string, options?: any) {
    options = {
      ...defaultOptions,
      ...options,
    };

    this.on('scaling', this._handleScaling.bind(this));
    this.on('changed', this._changeValue.bind(this));

    this.callSuper('initialize', this.startValue.toString(), options);
    this.id = options?.id ?? nanoid();

    // update status when importing from json
    if (this.locked) {
      this.updateLock(true);
    }

    this.setControlsVisibility({
      mt: false, mb: false
    });
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      title: this.title,
      locked: this.locked,
      name: this.name,
      textCase: this.textCase,
      prefix: this.prefix,
      pattern: this.pattern,
      startValue: this.startValue,
    });
  },

  _handleScaling(e: any) {
    if (e.transform.target.canvas) {
      const scaleFactor = this.scaleX === this.scaleY ? this.scaleX : this.scaleY;

      if (this.scaleX !== this.scaleY) {
        this.set({
          width: this.getScaledWidth(),
          height: this.getScaledHeight(),
        });
      }

      // min = 2, max = 399
      let fontSize = Math.floor(this.fontSize * scaleFactor);
      if (fontSize < 2) fontSize = 2;
      if (fontSize > 399) fontSize = 399;

      this.set({
        scaleX: 1,
        scaleY: 1,
        width: this.getMinWidth(),
        fontSize,
      });
    }
  },
  _changeValue() {
    const startValueLength = String(this.startValue).length;
    if (startValueLength > this.pattern) {
      this.set({ pattern: startValueLength });
    }

    const value = String(this.startValue).padStart(this.pattern, '0');
    const text = `${this.prefix}${value}`;
    this.set({ text: formatText(text, this.textCase) });
  }
});

const extraParams = ['text', 'prefix', 'pattern', 'startValue'];
fabric.NumberingObject.fromObject = (object: fabric.Object, callback: Function) => fabric.Object._fromObject('NumberingObject', object, callback, extraParams);

fabric.util.object.extend(fabric.NumberingObject.prototype, extendCustomObject);

export default fabric.NumberingObject;
