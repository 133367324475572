/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconNumbering(props: IIconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z" fill="#2C3E66" />
      <path d="M1.62109 15.3027H3.02734V17.5039H1.62109V15.3027Z" fill="#2C3E66" />
      <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
      <path d="M1.62109 6.49609H3.02734V8.6973H1.62109V6.49609Z" fill="#2C3E66" />
      <path d="M8.5 15.5H13V14H10.2461C10.4258 13.8047 10.6094 13.6133 10.7969 13.4258C10.9844 13.2383 11.1836 13.0586 11.3945 12.8867C11.6055 12.7148 11.8047 12.5352 11.9922 12.3477C12.1797 12.1602 12.3516 11.9609 12.5078 11.75C12.6641 11.5391 12.7852 11.3125 12.8711 11.0703C12.957 10.8281 13 10.5547 13 10.25C13 9.9375 12.9414 9.64453 12.8242 9.37109C12.707 9.09766 12.5469 8.85938 12.3438 8.65625C12.1406 8.45312 11.9023 8.29297 11.6289 8.17578C11.3555 8.05859 11.0625 8 10.75 8C10.4844 8 10.2344 8.04688 10 8.14062C9.76562 8.23438 9.54297 8.35938 9.33203 8.51562C9.12109 8.67188 8.94922 8.85938 8.81641 9.07812C8.68359 9.29688 8.59375 9.53516 8.54688 9.79297L10.0117 10.0977C10.0586 9.91797 10.1484 9.77344 10.2812 9.66406C10.4141 9.55469 10.5703 9.5 10.75 9.5C10.9531 9.5 11.1289 9.57422 11.2773 9.72266C11.4258 9.87109 11.5 10.0469 11.5 10.25C11.4922 10.4531 11.4336 10.6367 11.3242 10.8008C11.2148 10.9648 11.0664 11.1484 10.8789 11.3516C10.6914 11.5547 10.4922 11.7461 10.2812 11.9258C10.0703 12.1055 9.85547 12.3008 9.63672 12.5117C9.41797 12.7227 9.22266 12.9297 9.05078 13.1328C8.87891 13.3359 8.75 13.5469 8.66406 13.7656C8.55469 14.0391 8.5 14.3242 8.5 14.6211V15.5Z" fill="#2C3E66" />
      <path d="M4.85547 10.0859C5.16016 9.97656 5.42578 9.85547 5.65234 9.72266V15.5H7V8H5.51172C5.46484 8.07812 5.37891 8.16406 5.25391 8.25781C5.12891 8.35156 4.98828 8.43359 4.83203 8.50391C4.67578 8.57422 4.51953 8.63281 4.36328 8.67969C4.20703 8.72656 4.08594 8.75 4 8.75V10.25C4.26562 10.25 4.55078 10.1953 4.85547 10.0859Z" fill="#2C3E66" />
      <path d="M15.5195 15.3359C15.8242 15.4453 16.1406 15.5 16.4688 15.5C16.7969 15.5 17.1289 15.4453 17.4648 15.3359C17.8008 15.2266 18.0938 15.0742 18.3438 14.8789C18.5938 14.6836 18.7969 14.4414 18.9531 14.1523C19.1094 13.8633 19.1875 13.5352 19.1875 13.168C19.1875 12.8008 19.0781 12.4688 18.8594 12.1719C18.6406 11.875 18.3594 11.668 18.0156 11.5508C18.3516 11.3633 18.5938 11.125 18.7422 10.8359C18.8906 10.5469 18.9648 10.2148 18.9648 9.83984C18.9727 9.53516 18.9102 9.26562 18.7773 9.03125C18.6445 8.79688 18.4766 8.60547 18.2734 8.45703C18.0703 8.30859 17.8359 8.19531 17.5703 8.11719C17.3047 8.03906 17.0312 8 16.75 8C16.4375 8 16.1172 8.03516 15.7891 8.10547C15.4609 8.17578 15.1641 8.30078 14.8984 8.48047V10.0039C15.125 9.80859 15.3516 9.64062 15.5781 9.5C15.8047 9.35938 16.0742 9.28906 16.3867 9.28906C16.6914 9.28906 16.9414 9.34375 17.1367 9.45312C17.332 9.5625 17.4297 9.78125 17.4297 10.1094C17.4297 10.4062 17.3594 10.6289 17.2188 10.7773C17.0781 10.9258 16.9023 11.0273 16.6914 11.082C16.4805 11.1367 16.2539 11.1641 16.0117 11.1641H15.332V12.3008C15.4883 12.3008 15.6953 12.293 15.9531 12.2773C16.2109 12.2617 16.4648 12.2734 16.7148 12.3125C16.9648 12.3516 17.1836 12.4219 17.3711 12.5234C17.5586 12.625 17.6523 12.7969 17.6523 13.0391C17.6602 13.2344 17.625 13.3984 17.5469 13.5312C17.4688 13.6641 17.3711 13.7734 17.2539 13.8594C17.1367 13.9453 17 14.0039 16.8438 14.0352C16.6875 14.0664 16.5234 14.082 16.3516 14.082C16.1328 14.082 15.957 14.0703 15.8242 14.0469C15.6914 14.0234 15.5586 13.9844 15.4258 13.9297C15.293 13.875 15.168 13.8047 15.0508 13.7188C14.9336 13.6328 14.7969 13.5312 14.6406 13.4141V14.9023C14.9219 15.082 15.2148 15.2266 15.5195 15.3359Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconNumbering;
