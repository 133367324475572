import _groupBy from 'lodash/groupBy';
import _uniqueId from 'lodash/uniqueId';

import {
  EUnits, TPrintItemNestedField, TPrintItemPricing, TPrintItemSize
} from 'types';
import { TPricingTableItem, TPrintItemPricingsError, TPrintItemPricingsTouched } from 'types/Portfolio';
import { PRICINGS } from '../../constants';
import { createInputData } from '../../helpers';

export const groupPricingsBySizeName = (
  pricings: TPrintItemPricing[],
  errors: TPrintItemPricingsError[],
  touched: TPrintItemPricingsTouched[],
  units: EUnits
): { [key: string]: TPricingTableItem[] } => _groupBy(pricings.map((pricing, originalItemIdx) => ({
  ...pricing,
  units,
  id: pricing.key, // only for table
  inputData: createInputData(originalItemIdx, errors, touched, pricings, pricing?.id, PRICINGS)
} as TPricingTableItem)), item => item.size.name);

export const pricingWithSizeExists = (oldSize: TPrintItemSize, newSize: TPrintItemSize) => {
  const {
    id: oldSizeId,
    key: oldSizeKey
  } = oldSize;
  const {
    id: newSizeId,
    key: newSizeKey
  } = newSize;

  return oldSizeId
    ? oldSizeId === newSizeId
    : oldSizeKey === newSizeKey;
};

export const getNewPricings = (
  sizes: TPrintItemSize[],
  papers: TPrintItemNestedField[],
  quantities: TPrintItemNestedField[],
  pricings: TPrintItemPricing[],
  currency = '€'
) => sizes
  .map((size, sizeIdx) => papers.map((paper, paperIdx) => quantities.map((quantity, quantIdx) => {
    const existedPricing = pricings.find(({
      size: pricingSize, quantity: pricingQuantity, paper: pricingPaper
    }) => pricingWithSizeExists(pricingSize, size) && pricingPaper.value === paper.value && pricingQuantity.value === quantity.value);
    const order: number = sizeIdx * papers.length * quantities.length + paperIdx * quantities.length + quantIdx;
    return existedPricing
      ? { ...existedPricing, size, order }
      : {
        key: _uniqueId(),
        size: { ...size },
        paper: { ...paper },
        quantity: { ...quantity },
        price: '',
        currency,
        order,
      } as TPrintItemPricing;
  })))
  .flat(2);
