import React from 'react';
import { TOrder } from 'types';
import strings from 'constants/localization';
import { STATUS_TO_STRINGS } from 'constants/constants';
import css from './PageTitle.module.css';
import { StatusItem } from '../../../StatusColumn/StatusColumn';

type TPageTitleContainer = {
  isOrderCreation: boolean,
  order?: TOrder | null
}

type TPageTitle = Pick<TPageTitleContainer, 'order'>

const PageTitle:React.FC<TPageTitle> = React.memo(({ order }) => {
  const title = order?.sourceData?.serviceOrderId || order?.title || strings.ordersPageTitle;

  return (
    <div className={css.pageTitle}>
      <span>{title}</span>
      {order && (
        <StatusItem
          className={css.status}
          item={{
            status: order.status,
            label: STATUS_TO_STRINGS[order.status]
          }}
        />
      )}
    </div>
  );
});

const PageTitleContainer:React.FC<TPageTitleContainer> = ({ isOrderCreation, order }) => {
  if (isOrderCreation) {
    return <span>{strings.orderPageTitleAdd}</span>;
  }

  return <PageTitle order={order} />;
};

export default PageTitleContainer;
