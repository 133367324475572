import React, { useCallback, useMemo, useState } from 'react';
import Button from 'components/library/Button';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import Tooltip from 'components/library/Tooltip';
import Modal from 'components/library/Modal';
import { Moment } from 'moment';
import useGetOrdersReport from 'hooks/Reports/useGetOrdersReport';
import { TReportType } from 'types/Reports/Orders';
import css from './ExportOrders.module.css';
import ExportOrdersModalControls from './components/ModalControls';
import Form from './components/Form';
import { saveAs } from './helpers';
import { FROM_INIT_VALUE, TO_INIT_VALUE } from './constants';

interface IExportOrders {
  isOpened: boolean,
  toggleIsOpened: () => void,
}

const ExportOrders: React.FC<IExportOrders> = React.memo(props => {
  const { isOpened, toggleIsOpened } = props;

  const [{ from, to }, setParams] = useState<{ from: Moment, to: Moment }>({ from: FROM_INIT_VALUE, to: TO_INIT_VALUE });

  const [reportType, setReportType] = useState<TReportType>('csv');
  const reportParams = useMemo<{ from: string, to: string, type: TReportType }>(() => ({
    from: from.toISOString(),
    to: to.toISOString(),
    type: reportType,
  }), [from, to, reportType]);

  const isValid = useMemo<boolean>(() => from.isValid() && to.isValid() && from.isSameOrBefore(to), [from, to]);

  const { isLoading, error, refetch } = useGetOrdersReport(reportParams, false);

  const isDownloadAvailable = useMemo<boolean>(() => isValid && !isLoading && !error, [isValid, isLoading, error]);

  const onNext = async (type: TReportType) => {
    setReportType(type);
    await Promise.resolve();
    const { data, isFetched, isSuccess } = await refetch();
    if (isFetched && isSuccess) saveAs(data, type);
  };

  return (
    <>
      <Modal
        className={css.container}
        isOpen={isOpened}
        title="Export report data"
        customControls={(
          <ExportOrdersModalControls
            onBack={toggleIsOpened}
            onNext={onNext}
            disableNext={!isDownloadAvailable}
          />
        )}
        onRequestClose={toggleIsOpened}
      >
        <Form
          params={{ from, to }}
          setParams={setParams}
        />
      </Modal>
      <Tooltip title="Export report">
        <Button
          className={css.button}
          buttonType="primary"
          buttonStyle="square"
          onClick={toggleIsOpened}
        >
          <Icon type={E_ICON_TYPE.export} />
        </Button>
      </Tooltip>
    </>
  );
});

const ExportOrdersContainer: React.FC = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const toggleIsOpened = useCallback(() => setIsOpened(prevState => !prevState), [setIsOpened]);

  return (
    <ExportOrders
      isOpened={isOpened}
      toggleIsOpened={toggleIsOpened}
    />
  );
};

export default ExportOrdersContainer;
