import React, {
  SetStateAction, useEffect, useMemo, useState
} from 'react';
import cls from 'classnames';

import Loader from 'components/library/Loader';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';

import useGetUser from 'hooks/useUser';
import useQueryParams from 'hooks/useQueryParams';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import useGetArticleIntegrationTickets from 'hooks/Integration/useArticleIntegrationTickets';
import * as yup from 'yup';
import css from './ChooseTicketModalContentInput.module.css';
import { ITicketType } from './components/TicketTypeSelect';
import TicketTypeInput from './components/TicketTypeInput';

interface IValidationSchema {
  id: yup.StringSchema,
  name: yup.StringSchema,
}

export interface IChooseTicketModalContentInput{
  matchingsTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
}

const ChooseTicketModalContentInput: React.FC<IChooseTicketModalContentInput> = ({
  matchingsTicket, setChosenTicket
}) => {
  const query = useQueryParams();
  const customerId = query.get('customerId') || undefined;
  const { data: connectedTickets = [], isFetching: isFetchingConnectedTickets } = useGetArticleIntegrationTickets();
  const { data: customer, isFetching: isFetchingCustomer } = useGetUser({ userId: customerId });
  const bindedEventId = useMemo<string | undefined>(
    () => customer?.eventBind?.eventId,
    [customer]
  );

  const [value, setValue] = useState<ITicketType>(matchingsTicket ?? { id: '', name: '' });

  useEffect(() => {
    if (matchingsTicket?.id) {
      setChosenTicket(matchingsTicket);
    }
  }, [matchingsTicket]);

  const isFetching = useMemo<boolean>(
    () => isFetchingCustomer || isFetchingConnectedTickets,
    [isFetchingCustomer, isFetchingConnectedTickets]
  );

  const onChange = (field: string, newValue: SetStateAction<string>) => setValue(prevState => ({ ...prevState, [field]: newValue }));

  const validationSchema = useMemo<IValidationSchema>(() => {
    const exceptOf = connectedTickets.filter(ticket => ticket !== matchingsTicket?.id);

    return {
      id: yup.string()
        .trim()
        .min(1)
        .notOneOf(exceptOf, strings.errorIsAlreadyBinded)
        .required(),
      name: yup.string()
        .trim()
        .min(1)
        .required()
    };
  }, [matchingsTicket, connectedTickets]);

  useEffect(() => {
    try {
      yup.object({ ...validationSchema }).validateSync(value);
      setChosenTicket(value);
    } catch (e) {
      setChosenTicket(null);
    }
  }, [value]);

  if (!isFetchingCustomer && !bindedEventId) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoEventBind}
        description={strings.emptyStateDescriptionSeemsLikeNotBindedWithEvent}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }
  return isFetching
    ? <Loader />
    : (
      <>
        <span className={cls(css.modalSubtitle, textCss.pMedium3)}>
          {strings.formatString(strings.availableTicketsForEvent, customer?.eventBind?.eventName || '')}
        </span>
        <div className={css.ticketContainer}>
          <TicketTypeInput
            label={strings.inputTicketTypeId}
            value={value.id}
            validationSchema={validationSchema.id}
            onValueChange={e => onChange('id', e)}
          />
          <TicketTypeInput
            label={strings.inputTicketTypeName}
            value={value.name}
            validationSchema={validationSchema.name}
            onValueChange={e => onChange('name', e)}
          />
        </div>
      </>
    );
};

export default ChooseTicketModalContentInput;
