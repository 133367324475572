import React, { forwardRef, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import css from './Checkbox.module.css';

// TODO: create HOC with error to be able to pass inputs, selects etc
const Error: React.FC<{ text?: string }> = ({ text }) => (
  <span className={cls(textCss.pMedium2, css.errorMessage)}>
    {text}
  </span>
);

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  wrapperClassName?: string
  labelClassName?: string,
  indeterminate?: boolean,
  hovered?: boolean,
  withError?: boolean,
  error?: string,
}

const Checkbox = forwardRef<HTMLInputElement, IInput>((props, ref) => {
  const id = useMemo(() => uniqueId('checkbox_'), []);
  const {
    wrapperClassName, labelClassName, label, className, value, checked, disabled, indeterminate,
    hovered, error, withError, ...rest
  } = props;

  return (
    <div className={cls(css.container, { [css.disabled]: disabled }, wrapperClassName)}>
      <input
        id={id}
        ref={ref}
        type="checkbox"
        className={cls(css.input, className, { [css.disabled]: disabled })}
        checked={checked ?? !!value}
        value={value}
        disabled={disabled}
        {...rest}
      />
      <label
        htmlFor={id}
        className={cls(css.label, labelClassName, textCss.pMedium2, {
          [css.active]: !!label,
          [css.indeterminate]: indeterminate,
          [css.hovered]: hovered,
          [css.errorForCheckbox]: error
        })}
      >
        {label}
      </label>
      {withError && <div><Error text={error} /></div>}
    </div>
  );
});

export default Checkbox;
