import { PDFDocument, PDFPage, rgb } from 'pdf-lib';
import { EUnits } from 'types';
import strings from 'constants/localization';
import { ORIENTATION_TO_STRINGS } from 'constants/constants';
import { convertUnit, EConvertUnitDirection, getOrientation } from './pdf';
import { IPdfBox } from '../contexts/EditorContext';
import { fileToDataUrl } from './file';

const CUT_MARKS_SIZE = 7; // mm
const CUT_MARKS_THICKNESS = 0.1; // mm
const CUT_MARKS_LENGTH = 4; // mm
const CUT_MARKS_BACKGROUND_COLOR = rgb(1, 1, 1);
const CUT_MARKS_BORDER = {
  borderWidth: 0,
  borderOpacity: 0,
  borderColor: CUT_MARKS_BACKGROUND_COLOR,
};

type MediaBoxParams = {
  x: number
  y: number
  width: number
  height: number
}

const getPdfDocument = async (file?: File): Promise<PDFDocument> => {
  if (file) {
    return fileToDataUrl(file)
      .then(str => PDFDocument.load(str));
  }
  return PDFDocument.create();
};

const getPdfPage = async (pdfDoc: PDFDocument, pdfBox: IPdfBox): Promise<PDFPage> => {
  const pageSizes = getPageSizes(pdfBox);
  const existingPagesIndexes = pdfDoc.getPageIndices();

  const page = existingPagesIndexes.length > 0 ? pdfDoc.getPage(0) : pdfDoc.addPage(pageSizes);

  const pdfBoxOrientation = getOrientation(pdfBox.width, pdfBox.height);
  const pdfOrientation = getOrientation(page.getWidth(), page.getHeight());
  if (pdfBoxOrientation !== pdfOrientation) {
    throw new Error(strings.formatString(
      strings.editorPdfHasWrongOrientationErrorMessage,
      ORIENTATION_TO_STRINGS[pdfBoxOrientation]()
    ) as string);
  }

  const { width, height } = getBleedBoxParams(pdfBox);

  if (Math.ceil(page.getWidth()) < Math.floor(width) || Math.ceil(page.getHeight()) < Math.floor(height)) {
    console.error('PDF size:', page.getSize(), '(px) is smaller than SKU size:', { width, height }, '(px)');
    throw new Error(strings.editorPdfSizeIsTooSmallErrorMessage);
  }

  const originalSizes = page.getSize();

  page.setSize(...pageSizes);

  page.translateContent(
    -((originalSizes.width - page.getWidth()) / 2),
    -((originalSizes.height - page.getHeight()) / 2)
  );

  // remove all pages from original pdf file
  existingPagesIndexes.slice(1).map(() => pdfDoc.removePage(1));

  return page;
};

const createPdfTemplate = async (pdfBox: IPdfBox, pdfFile?: File) => {
  const pdfDoc = await getPdfDocument(pdfFile);
  const page = await getPdfPage(pdfDoc, pdfBox);

  const mediaBox = page.getMediaBox();

  const bleedBox = getBleedBoxParams(pdfBox);
  page.setBleedBox(bleedBox.x + mediaBox.x, bleedBox.y + mediaBox.y, bleedBox.width, bleedBox.height);
  page.setArtBox(bleedBox.x + mediaBox.x, bleedBox.y + mediaBox.y, bleedBox.width, bleedBox.height);

  const trimBox = getTrimBoxParams(pdfBox);
  page.setTrimBox(trimBox.x + mediaBox.x, trimBox.y + mediaBox.y, trimBox.width, trimBox.height);

  drawCutMarks(page, bleedBox, trimBox, mediaBox, EUnits.MM);

  pdfDoc.setProducer('Orgafly.online');
  pdfDoc.setCreator('Orgafly.online');

  return pdfDoc.saveAsBase64({ dataUri: true });
};

const drawCutMarks = (page: PDFPage, bleedBox: MediaBoxParams, trimBox: MediaBoxParams, mediaBox: MediaBoxParams, unit: EUnits) => {
  const { width: bWidth, height: bHeight } = bleedBox;
  const bX = bleedBox.x + mediaBox.x;
  const bY = bleedBox.y + mediaBox.y;

  const { width: tWidth, height: tHeight } = trimBox;
  const tX = trimBox.x + mediaBox.x;
  const tY = trimBox.y + mediaBox.y;

  const cutMarkWidth = convertUnit(CUT_MARKS_LENGTH, unit, EConvertUnitDirection.PIXELS);
  const cutMarkSize = convertUnit(CUT_MARKS_SIZE, unit, EConvertUnitDirection.PIXELS);
  const thickness = convertUnit(CUT_MARKS_THICKNESS, unit, EConvertUnitDirection.PIXELS);

  page.resetPosition();

  // Background bottom
  page.drawRectangle({
    color: CUT_MARKS_BACKGROUND_COLOR,
    x: bX - cutMarkSize,
    y: bY - cutMarkSize,
    width: bWidth + (cutMarkSize * 2),
    height: cutMarkSize,
    ...CUT_MARKS_BORDER,
  });

  // Background top
  page.drawRectangle({
    color: CUT_MARKS_BACKGROUND_COLOR,
    x: bX - cutMarkSize,
    y: bY + bHeight,
    width: bWidth + (cutMarkSize * 2),
    height: cutMarkSize,
    ...CUT_MARKS_BORDER,
  });

  // Background left
  page.drawRectangle({
    color: CUT_MARKS_BACKGROUND_COLOR,
    x: bX - cutMarkSize,
    y: bY,
    width: cutMarkSize,
    height: bHeight,
    ...CUT_MARKS_BORDER,
  });

  // Background right
  page.drawRectangle({
    color: CUT_MARKS_BACKGROUND_COLOR,
    x: bX + bWidth,
    y: bY,
    width: cutMarkSize,
    height: bHeight,
    ...CUT_MARKS_BORDER,
  });

  // bottom left
  page.drawLine({
    start: { x: bX, y: tY },
    end: { x: bX - cutMarkWidth, y: tY },
    thickness,
  });
  page.drawLine({
    start: { x: tX, y: bY },
    end: { x: tX, y: bY - cutMarkWidth },
    thickness,
  });

  // top left
  page.drawLine({
    start: { x: bX, y: tY + tHeight },
    end: { x: bX - cutMarkWidth, y: tY + tHeight },
    thickness,
  });
  page.drawLine({
    start: { x: tX, y: bY + bHeight },
    end: { x: tX, y: bY + bHeight + cutMarkWidth },
    thickness,
  });

  // bottom right
  page.drawLine({
    start: { x: bX + bWidth, y: tY },
    end: { x: bX + bWidth + cutMarkWidth, y: tY },
    thickness,
  });
  page.drawLine({
    start: { x: tX + tWidth, y: bY },
    end: { x: tX + tWidth, y: bY - cutMarkWidth },
    thickness,
  });

  // top right
  page.drawLine({
    start: { x: bX + bWidth, y: tY + tHeight },
    end: { x: bX + bWidth + cutMarkWidth, y: tY + tHeight },
    thickness,
  });
  page.drawLine({
    start: { x: tX + tWidth, y: bY + bHeight },
    end: { x: tX + tWidth, y: bY + bHeight + cutMarkWidth },
    thickness,
  });
};

const getPageSizes = (pdfBox: IPdfBox): [number, number] => {
  const {
    width: rawWidth,
    height: rawHeight,
    bleed,
    unit,
  } = pdfBox;

  const bleedSize = bleed * 2;
  const cutMarksSize = convertUnit(CUT_MARKS_SIZE, EUnits.MM, EConvertUnitDirection.PIXELS) * 2;

  const width = convertUnit(rawWidth + bleedSize, unit, EConvertUnitDirection.PIXELS) + cutMarksSize;
  const height = convertUnit(rawHeight + bleedSize, unit, EConvertUnitDirection.PIXELS) + cutMarksSize;

  return [width, height];
};

const getBleedBoxParams = (pdfBox: IPdfBox): MediaBoxParams => {
  const {
    width: rawWidth,
    height: rawHeight,
    bleed,
    unit,
  } = pdfBox;

  const bleedSize = bleed * 2;

  const position = convertUnit(CUT_MARKS_SIZE, EUnits.MM, EConvertUnitDirection.PIXELS);
  const width = convertUnit(rawWidth + bleedSize, unit, EConvertUnitDirection.PIXELS);
  const height = convertUnit(rawHeight + bleedSize, unit, EConvertUnitDirection.PIXELS);

  return {
    x: position,
    y: position,
    width,
    height,
  };
};

const getTrimBoxParams = (pdfBox: IPdfBox): MediaBoxParams => {
  const {
    width: rawWidth,
    height: rawHeight,
    bleed,
    unit,
  } = pdfBox;

  const cutMarksSize = convertUnit(CUT_MARKS_SIZE, EUnits.MM, EConvertUnitDirection.PIXELS);

  const position = convertUnit(bleed, unit, EConvertUnitDirection.PIXELS) + cutMarksSize;
  const width = convertUnit(rawWidth, unit, EConvertUnitDirection.PIXELS);
  const height = convertUnit(rawHeight, unit, EConvertUnitDirection.PIXELS);

  return {
    x: position,
    y: position,
    width,
    height,
  };
};

export default createPdfTemplate;
