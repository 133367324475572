/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignTop(props: IIconProps) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 1C20 1.55 19.55 2 19 2H1C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0H19C19.55 0 20 0.45 20 1ZM6.5 20C7.33 20 8 19.33 8 18.5V5.5C8 4.67 7.33 4 6.5 4C5.67 4 5 4.67 5 5.5V18.5C5 19.33 5.67 20 6.5 20ZM13.5 14C14.33 14 15 13.33 15 12.5V5.5C15 4.67 14.33 4 13.5 4C12.67 4 12 4.67 12 5.5V12.5C12 13.33 12.67 14 13.5 14Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignTop;
