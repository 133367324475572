import React, { useMemo } from 'react';
import _ from 'lodash';

import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import strings from 'constants/localization';
import useGetArticleIntegrationTickets from 'hooks/Integration/useArticleIntegrationTickets';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Loader from 'components/library/Loader';
import useQueryParams from 'hooks/useQueryParams';
import useGetUser from 'hooks/useUser';
import { EOrderDataIds, TExcelBindings } from '../../types';
import TicketTypeSelect, { ITicketType } from '../../../TicketTypeSelect';
import { getCellValue } from '../../helpers';

export interface ITicketTypeContainer {
  data: TRow[],
  bindings: TExcelBindings,
  matchingsTicket: ITicketType | null,
  chosenTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
}

const TicketTypeContainer: React.FC<ITicketTypeContainer> = props => {
  const {
    bindings,
    data,
    matchingsTicket,
    chosenTicket,
    setChosenTicket,
  } = props;

  const query = useQueryParams();
  const customerId = query.get('customerId') || undefined;

  const { data: customer, isFetching: isFetchingCustomer } = useGetUser({ userId: customerId });
  const { data: connectedTickets = [], isFetching: isFetchingConnectedTickets } = useGetArticleIntegrationTickets();

  const ticketTypes = useMemo<ITicketType[]>(() => {
    const ticketTypeIdIndex = bindings[EOrderDataIds.TICKET_TYPE_ID];
    const ticketTypeNameIndex = bindings[EOrderDataIds.TICKET_TYPE_NAME] || ticketTypeIdIndex;

    if (ticketTypeIdIndex === null || ticketTypeNameIndex === null) {
      return [];
    }

    return _.uniqBy(data
      .slice(1)
      .map(row => ({
        id: getCellValue(row[ticketTypeIdIndex]),
        name: getCellValue(row[ticketTypeNameIndex]) || getCellValue(row[ticketTypeIdIndex]),
      })), 'id');
  }, [data, bindings]);

  const isFetching = useMemo<boolean>(
    () => isFetchingCustomer || isFetchingConnectedTickets,
    [isFetchingCustomer, isFetchingConnectedTickets]
  );

  if (!isFetching && ticketTypes.length === 0) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoTickets}
        description={strings.emptyStateDescriptionCreateTickets}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }

  if (isFetching) {
    return <Loader />;
  }

  return (
    <TicketTypeSelect
      title={customer?.eventBind?.eventName || ''}
      ticketTypes={ticketTypes}
      connectedTickets={connectedTickets}
      chosenTicket={chosenTicket}
      setChosenTicket={setChosenTicket}
      matchingsTicket={matchingsTicket}
    />
  );
};

export default TicketTypeContainer;
