import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import * as Yup from 'yup';

import Tabs, { ITab } from 'components/library/Tabs';
import strings from 'constants/localization';
import {
  ECanvasObjectTypes, ETemplateSides, ETextCase, TOrderItem, TParam
} from 'types';
import Input from 'components/library/Input';
import Label from 'components/library/Label';
import Button from 'components/library/Button';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { ECodeObjectTitle } from 'components/PageArticles/components/Editor/components/Viewport/fabric/codeObject';
import {
  createScaledImageObject,
  getImageDimensions,
  getObjectTitle,
  INDEX_TO_SIDE,
  OBJECT_TYPE_TO_FIELD,
  SIDES_TO_INDEX
} from '../../helper';
import OrderContext, { IOrderState } from '../../ContextOrder';
import css from './OrderItemParam.module.css';
import fabric from '../../../../../PageArticles/components/Editor/helpers/fabric';
import { formatText } from '../../../../../../helpers';
import AlertContext from '../../../../../../contexts/ContextAlert';

export enum EImageTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

const ACCEPTED_IMAGE_TYPES: EImageTypes[] = [
  EImageTypes.PNG,
  EImageTypes.JPEG,
  EImageTypes.JPG,
];

const ACCEPTED_IMAGE_TYPES_INPUT = ACCEPTED_IMAGE_TYPES.join(', ');

const tabs:ITab<number>[] = [{
  label: () => strings.orderPageViewFrontsideTitle,
  value: 0,
}, {
  label: () => strings.orderPageViewBacksideTitle,
  value: 1,
}];

const validateQRCode = () => Yup.string()
  .min(1, strings.editorTabSettingsCodeContentErrorQrFormat)
  .max(2048, strings.formatString(strings.inputMaxLen, 2048) as string);
const validateBarCode = () => Yup.string().length(12, strings.editorTabSettingsCodeContentErrorBarcodeFormat);

interface IItem extends TParam {
  onChange: (value: string, id: string | number, key: keyof TParam, isRemove?: boolean) => void,
  getImageDpi:(object: typeof fabric.ImageObject)=>number,
  width?:number,
  height?:number,
  imageDpiNotification:(dpi:number)=>void,
  canvasLoadingStatus: boolean
}
const Item:React.FC<IItem> = ({
  type, name, onChange, id, getImageDpi,
  width = 0, height = 0, imageDpiNotification, textCase = ETextCase.Default, canvasLoadingStatus, ...props
}) => {
  const { push } = useContext(AlertContext);
  const [error, setError] = useState<string | undefined>();
  const value = type === ECanvasObjectTypes.CODE ? props.content : props.text;
  const inputRef = useRef<HTMLInputElement>(null);
  const onUploadImage = () => inputRef.current?.click();

  const getNewImageObject = useCallback((src: string, imgWidth = 0, imgHeight = 0) => {
    const item = {
      src, width, height, id, name, type, ...props
    };
    return createScaledImageObject(item, imgWidth, imgHeight);
  }, [width, height, props, type, id, name]);

  const imageChangeHandler = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = (event.target as HTMLInputElement);

    if (!target.files || target.files.length === 0) {
      return;
    }

    const file = target.files[0];
    if (!ACCEPTED_IMAGE_TYPES.includes(file.type as EImageTypes)) {
      push({ severity: 'error', message: strings.editorImageObjectErrorMessage });
      return;
    }

    const reader = new FileReader();
    const sizeImg = await getImageDimensions(file);
    const img = getNewImageObject(reader.result as string, sizeImg?.width, sizeImg?.height);
    const dpi = getImageDpi(img);
    imageDpiNotification(dpi);
    reader.addEventListener('load', () => {
      const image = reader.result;
      if (image) {
        onChange(image.toString(), id, OBJECT_TYPE_TO_FIELD.ImageObject);
      }
    });
    reader.readAsDataURL(file);
    // react can't upload the same image twice
    // https://github.com/ngokevin/react-file-reader-input/issues/11
    target.value = '';
  }, [getNewImageObject, onChange, id, imageDpiNotification]);

  const textChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    onChange(formatText(target.value, textCase), id, OBJECT_TYPE_TO_FIELD[type]);
  }, [onChange, id, type, textCase]);

  const imageDeleteHandler = useCallback(() => {
    onChange('', id, OBJECT_TYPE_TO_FIELD.ImageObject, true);
  }, [onChange, id]);

  useEffect(() => {
    if (type === ECanvasObjectTypes.CODE && props.title === ECodeObjectTitle.BARCODE) {
      validateBarCode().validate(value)
        .then(() => setError(undefined))
        .catch(err => setError(err.message));
    }
    if (type === ECanvasObjectTypes.CODE && props.title === ECodeObjectTitle.QR) {
      validateQRCode().validate(value)
        .then(() => setError(undefined))
        .catch(err => setError(err.message));
    }
  }, [value, type]);
  const label = getObjectTitle(type, props.title);

  if (type === ECanvasObjectTypes.IMAGE) {
    return (
      <div className={css.inputImage}>
        <Label className={css.label} text={name} optional={label} />
        <div className={css.controls}>
          <input ref={inputRef} onChange={imageChangeHandler} type="file" accept={ACCEPTED_IMAGE_TYPES_INPUT} />
          <div className={css.imageContainer}>
            <img src={props.src} alt={name} />
          </div>
          <Button
            disabled={canvasLoadingStatus}
            onClick={onUploadImage}
            buttonType="primary"
          >
            {strings.editorChooseImageButton}
          </Button>
          <Button
            disabled={props.isDefaultValue || canvasLoadingStatus}
            onClick={imageDeleteHandler}
            buttonType="secondary"
            buttonStyle="square"
          >
            <Icon type={E_ICON_TYPE.delete} />
          </Button>
        </div>
      </div>
    );
  }
  return (
    <Input
      error={error}
      withError
      customLabel={<Label className={css.label} text={name} optional={label} />}
      value={value}
      className={css.item}
      onChange={textChangeHandler}
      disabled={canvasLoadingStatus}
    />
  );
};

interface IOrderItemParam {
  orderItemValue: TOrderItem,
  onChangeValue: (value:string, id: string | number, key: keyof TParam) => void,
  tab: IOrderState['currentTab'],
  setTab: (index: IOrderState['currentTab']) => void,
  sideCount: number,
  getImageDpi:(object: typeof fabric.ImageObject)=>number,
  imageDpiNotification:(dpi: number)=>void,
  canvasLoadingStatus: boolean
}
const OrderItemParams:React.FC<IOrderItemParam> = ({
  orderItemValue, onChangeValue, tab, setTab, sideCount, getImageDpi, imageDpiNotification, canvasLoadingStatus
}) => {
  const values = useMemo(() => [
    orderItemValue.items.filter(e => e.side === ETemplateSides.FRONT && !e.locked),
    orderItemValue.items.filter(e => e.side === ETemplateSides.BACK && !e.locked),
  ], [orderItemValue.items]);

  const isBackSideOnly = tab === ETemplateSides.BACK && sideCount === 1;
  const tabIndex = isBackSideOnly ? 0 : SIDES_TO_INDEX[tab];
  const originalTabIndex = SIDES_TO_INDEX[tab];

  const tabsProcessed = isBackSideOnly ? [tabs[originalTabIndex]] : tabs.slice(0, sideCount);

  const onChange = (e:React.SyntheticEvent, index: 0 | 1) => setTab(INDEX_TO_SIDE[index]);
  return (
    <>
      <Tabs
        variant="fullWidth"
        className={css.tabs}
        tabClass={css.tab}
        tabs={tabsProcessed}
        value={tabIndex}
        onChange={onChange}
        orientation="horizontal"
      />
      <div className={css.items}>
        {values[originalTabIndex]
          .map(e => (
            <Item
              key={`${orderItemValue.id}${e.id || e.type}`}
              {...e}
              onChange={onChangeValue}
              getImageDpi={getImageDpi}
              imageDpiNotification={imageDpiNotification}
              canvasLoadingStatus={canvasLoadingStatus}
            />
          ))}
      </div>
    </>
  );
};
interface IOrderItemParamContainer {
  getImageDpi:(object:typeof fabric.ImageObject)=>number,
  imageDpiNotification:(dpi:number)=>void
}

const OrderItemParamContainer: React.FC<IOrderItemParamContainer> = ({ getImageDpi, imageDpiNotification }) => {
  const {
    state, setStateRaw, canvasLoadingStatus
  } = useContext(OrderContext);

  const currentCanvasSideLoadingStatus = canvasLoadingStatus[state.currentTab];
  const orderItemValue = useMemo(() => state.selectedOrderItem, [state.selectedOrderItem]);
  const onChangeValue = useCallback((content:string, id: string | number, key: keyof TParam, isRemove?: boolean) => {
    setStateRaw(prev => {
      const newState = { ...prev, changes: true };
      newState.orderItems = prev.orderItems.map(e => {
        if (e.id !== orderItemValue?.id) return e;
        const newItem = {
          ...e,
          items: e.items.map(item => {
            if (item.id !== id) return item;
            const defaultItem = newState.params.find(p => p.id === id) as TParam;
            const value = isRemove ? defaultItem[key] : content;
            return {
              ...item,
              [key]: value,
              isDefaultValue: isRemove
            };
          })
        };
        newState.selectedOrderItem = newItem;
        return newItem;
      });
      return newState;
    });
  }, [orderItemValue]);
  const onChangeTab = useCallback((tab:IOrderState['currentTab']) => {
    setStateRaw(prev => ({ ...prev, currentTab: tab }));
  }, [setStateRaw]);

  return useMemo(() => orderItemValue && (
    <OrderItemParams
      setTab={onChangeTab}
      tab={state.currentTab}
      orderItemValue={orderItemValue}
      onChangeValue={onChangeValue}
      sideCount={state.sideCount}
      getImageDpi={getImageDpi}
      imageDpiNotification={imageDpiNotification}
      canvasLoadingStatus={currentCanvasSideLoadingStatus}
    />
  ), [orderItemValue, onChangeValue, state.currentTab, onChangeTab, state.sideCount, canvasLoadingStatus]);
};

export default React.memo(OrderItemParamContainer);
