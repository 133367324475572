import React, { useCallback } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Moment } from 'moment';
import css from './Form.module.css';
import DatePickerLocalizationProvider from './DatePickerLocalizationProvider';

interface IForm {
  from: Moment,
  setFrom: (newValue: Moment) => void,
  to: Moment,
  setTo: (newValue: Moment) => void,
}

const Form: React.FC<IForm> = React.memo(props => {
  const {
    from,
    setFrom,
    to,
    setTo,
  } = props;

  const handleChangeFrom = (newValue: Moment | null) => {
    if (newValue) setFrom(newValue.startOf('day'));
  };

  const handleChangeTo = (newValue: Moment | null) => {
    if (newValue) setTo(newValue.endOf('day'));
  };

  return (
    <div className={css.container}>
      <DesktopDatePicker
        timezone="UTC"
        label="From"
        value={from}
        onChange={newValue => handleChangeFrom(newValue)}
      />
      <DesktopDatePicker
        timezone="UTC"
        label="To"
        value={to}
        onChange={newValue => handleChangeTo(newValue)}
      />
    </div>
  );
});

type FormParams = {
  from: Moment,
  to: Moment,
};

interface IFormContainer {
  params: FormParams,
  setParams: (newParams: FormParams) => void,
}

const FormContainer: React.FC<IFormContainer> = props => {
  const { params: { from, to }, setParams } = props;
  const setFrom = useCallback((newValue: Moment) => setParams({ from: newValue, to }), [setParams, to]);
  const setTo = useCallback((newValue: Moment) => setParams({ from, to: newValue }), [setParams, from]);

  return (
    <DatePickerLocalizationProvider>
      <Form
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
      />
    </DatePickerLocalizationProvider>
  );
};

export default FormContainer;
