import React, { useMemo } from 'react';
import CustomersTableCellSlider from 'components/PageCustomers/components/CustomersTableCellSlider';
import { ICustomersTableCell } from 'types/Customers';
import { ROUTES } from 'constants/constants';
import { Link } from 'react-router-dom';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import css from '../PageCustomers.module.css';
import { EOrderStatus, TStat } from '../../../types';

const OrdersCell: React.FC<ICustomersTableCell> = ({ customerItem }) => {
  const { id } = customerItem;
  const viewAllArticlesRoute = `${ROUTES.BASE}${ROUTES.ORDERS}?customerId=${id}`;
  const ordersCount = useMemo<TStat<EOrderStatus>[]>(
    () => customerItem.orderStats || [],
    [customerItem]
  );

  const allOrdersCount = ordersCount.reduce((acc, el) => acc + el.count, 0);

  return (
    <CustomersTableCellSlider
      link={`/${ROUTES.ORDERS}?customerId=${customerItem.id}`}
      sliderItemClass={css.sliderItem}
      items={ordersCount}
    >
      <div className={css.sliderItem}>
        <Link to={viewAllArticlesRoute}>
          <div>{allOrdersCount}</div>
          <div className={cls(textCss.pLight3, css.status)}>{strings.viewAll}</div>
        </Link>
      </div>
    </CustomersTableCellSlider>
  );
};

export default OrdersCell;
