import React, { useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import cls from 'classnames';
import Select from 'components/library/Select';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import textCss from 'assets/styles/Text.module.css';
import {
  ECanvasObjectTypes, ETemplateSides,
  TOrderItem, TParam
} from 'types';
import strings from 'constants/localization';
import css from './ModalContentUploadList.module.css';
import { TCell, TRow, TRowGrouped } from '../../types';
import { getObjectTitle, OBJECT_TYPE_TO_FIELD, SIDE_TO_STRING } from '../../helper';

type TSampleDataMap = {
  [k:string]: TCell
}

type TKeyMap = { [k:string]: number }

type TOption = {
  label: string,
  value: string,
}

type TState = { [key: string]: TOption};

const getColumnLabel = (cell:string, index: number):string => `${cell}${cell ? ' ' : ''}${strings
  .formatString(
    strings.orderPageModalUploadFileGeneratedColumnName,
    index + 1
  )}`;

const getKey = (key:TCell):string => {
  if (typeof key === 'object' && key.type === 'image') return 'image';
  if (typeof key === 'object' && key.type === 'string') return key.value || '';
  return key?.toString() || '';
};

const getValidKeys = (keys:TCell[]):string[] => {
  const keysString = keys.map(getKey);
  return keysString.map((k, i, arr) => {
    if (arr.some((str, strIndex) => str === k && strIndex !== i)) {
      return getColumnLabel(k, i);
    }
    return k;
  });
};

type TUseColumns = (
  keys: TCell[],
  params: TParam[],
  rows: TRow[],
  setMatchedData: (items: TOrderItem[]) => void
) => ColumnDef<TRowGrouped>[]
const useColumnsMatchingTable:TUseColumns = (keys, params, rows, setMatchedData) => {
  const [validKeys, options] = useMemo<[string[], TOption[]]>(() => {
    const keysProcessed = getValidKeys(keys);
    const optionsByKeys = keysProcessed.map(e => ({ label: e, value: e }));
    return [keysProcessed, optionsByKeys];
  }, [keys]);
  const sample:TSampleDataMap = {};
  validKeys.forEach((k, i) => {
    sample[k] = rows[0][i];
  });
  const [state, setState] = useState<TState>(
    params.reduce((acc:TState, e) => {
      const option = options.find(o => o.label === e.name);
      if (option) {
        acc[e.id] = option;
      }
      return acc;
    }, {})
  );
  useEffect(() => {
    const keyMap:TKeyMap = {};
    validKeys.forEach((k, i) => {
      keyMap[k] = i;
    });
    const items:TOrderItem[] = rows.map((row, index) => {
      const id = `#${index}`;
      const itemsRaw = params
        .map(k => {
          const keyInTable = state[k.id]?.value;
          if (k.locked) return { ...k };
          if (keyInTable) {
            const value = row[keyMap[keyInTable]];
            if (!value) return null;
            const key = OBJECT_TYPE_TO_FIELD[k.type];
            const itemValue:TParam = {
              ...k,
              [key]: typeof value === 'object' ? value.value : value.toString(),
            };
            return itemValue;
          }
          return {
            ...k,
            content: '',
          };
        })
        .filter(e => e) as TParam[];
      const item: TOrderItem = {
        title: id,
        id,
        items: itemsRaw,
      };
      return item;
    });
    setMatchedData(items);
  }, [state, validKeys]);
  return useMemo(() => [
    {
      id: 'side',
      header: strings.orderPageModalUploadFileMatchTableColumnSideHeader,
      accessor: 'side',
      meta: {
        className: css.column
      },
      cell: ({ row: { original, ...row } }) => {
        const handler = row.getCanExpand() ? row.getToggleExpandedHandler() : undefined;
        const isExpanded = row.getIsExpanded();
        const side = original.groupedKey as ETemplateSides;
        return (
          <div onClick={handler} className={css.groupHeaderCell}>
            {handler && <Icon className={isExpanded ? css.expanded : ''} type={E_ICON_TYPE.arrowUp} />}
            {side && SIDE_TO_STRING[side]()}
          </div>
        );
      }
    }, {
      id: 'title',
      header: strings.orderPageModalUploadFileMatchTableColumnTitleHeader,
      accessor: 'title',
      meta: {
        className: css.column
      },
      cell: ({ row: { original } }) => (
        <div className={css.line}>
          <span>{original.name}</span>
          <span className={cls(css.description, textCss.pMedium3)}>{getObjectTitle(original.type, original.title)}</span>
        </div>
      )
    }, {
      id: 'column',
      header: strings.orderPageModalUploadFileMatchTableColumnColumnHeader,
      accessor: 'column',
      meta: {
        className: css.column
      },
      cell: ({ row }) => {
        const { id: rowId } = row.original;
        return (
          <Select
            isClearable
            onChange={value => setState(prev => {
              const res = { ...prev };
              if (!value) {
                delete res[rowId];
              } else {
                res[rowId] = value;
              }
              return res;
            })}
            options={options}
            value={state[rowId]}
            placeholder={strings.orderPageModalUploadFileMatchTableColumnColumnSelectPlaceholder}
          />
        );
      }
    }, {
      id: 'value',
      header: strings.orderPageModalUploadFileMatchTableColumnSampleDataHeader,
      accessor: 'title',
      meta: {
        className: css.column
      },
      cell: ({ row }) => {
        const { id, type: objectType } = row.original;
        const cell = sample[state[id]?.value];
        const type = typeof cell === 'object' ? cell.type : 'string';
        const value = typeof cell === 'object' ? cell.value : cell;
        return (
          <div className={css.columnText}>
            <Sample type={type} value={value} objectType={objectType} />
          </div>
        );
      }
    },
  ], [state]);
};

interface ISample {
  type: 'string' | 'image',
  value: string,
  objectType: ECanvasObjectTypes
}
const Sample:React.FC<ISample> = ({ type, value, objectType }) => {
  if (type === 'image') {
    return objectType === 'ImageObject'
      ? <img src={value} alt={type} className={css.sampleImage} />
      : <span className={css.sampleTextImage}>{value.slice(0, 22)}</span>;
  }
  return <span>{value}</span>;
};

export default useColumnsMatchingTable;
