import React, { useMemo } from 'react';

import Table from 'components/library/Table';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';

import strings from 'constants/localization';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import css from './OrderData.module.css';
import useOrderDataTable from './useOrderDataTable';
import { EOrderDataIds, TExcelBindings, TExcelDataTableRow } from '../../types';
import { getDataSample } from '../../helpers';

interface IOrderData {
  data: TRow[],
  bindings: TExcelBindings,
  setBindings: React.Dispatch<React.SetStateAction<TExcelBindings>>,
}

const OrderData: React.FC<IOrderData> = ({ data, bindings, setBindings }) => {
  const tableData = useMemo<TExcelDataTableRow[]>(
    () => [
      {
        id: EOrderDataIds.ORDER_ID,
        title: strings.editorExcelModalOrderDataOrderIdField,
        value: getDataSample(data, bindings[EOrderDataIds.ORDER_ID]),
        required: true,
      },
      {
        id: EOrderDataIds.TICKET_TYPE_ID,
        title: strings.editorExcelModalOrderDataTicketTypeIdField,
        value: getDataSample(data, bindings[EOrderDataIds.TICKET_TYPE_ID]),
        required: true,
      },
      {
        id: EOrderDataIds.TICKET_TYPE_NAME,
        title: strings.editorExcelModalOrderDataTicketTypeNameField,
        value: getDataSample(data, bindings[EOrderDataIds.TICKET_TYPE_NAME]),
      },
    ],
    [bindings]
  );

  const columns = useOrderDataTable({ data, bindings, setBindings });

  if (tableData?.length === 0) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoObjectsForMatching}
        description={strings.emptyStateDescriptionAddFewObjects}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }
  return (
    <div className={css.modalContainer}>
      <div className={css.description}>{strings.editorExcelModalOrderDataTabDescription}</div>
      <div className={css.content}>
        <Table<TExcelDataTableRow> data={tableData} columns={columns} />
      </div>
    </div>
  );
};

export default OrderData;
