import React, {
  RefObject, useMemo, useRef, useState
} from 'react';
import classNames from 'classnames';
import { Object } from 'fabric/fabric-impl';
import { ETemplateSides } from 'types';
import useDraggableScroll from '../../hooks/useDraggableScroll';
import { EViewportModes } from '../../constants';
import css from './Viewport.module.css';
import Canvas from './Canvas';
import { IPdfDocument, IViewportData } from '../../contexts/EditorContext';
import fabric from '../../helpers/fabric';

export interface IViewport {
  side?: ETemplateSides,
  mode: EViewportModes,
  viewport: IViewportData,
  json: any,
  setObjects: (objects: Object[]) => void,
  pdfDocument: IPdfDocument,
  canvas: RefObject<fabric.Canvas | null>,
  hideShadow?: boolean,
  setCanvasLoadingStatus: (value:boolean)=>void
}

const Viewport:React.FC<IViewport> = props => {
  const {
    mode: initMode,
    viewport,
  } = props;
  const areaRef = useRef<HTMLDivElement>(null);

  const [canvasSize, setCanvasSize] = useState<{width: number, height: number}>({ width: 0, height: 0 });

  const handleResized = (width: number, height: number) => {
    setCanvasSize({
      width: Math.round(width),
      height: Math.round(height),
    });
  };

  const isCanvasLargerThanViewport = useMemo<boolean>(
    () => canvasSize.width > viewport.width || canvasSize.height > viewport.height,
    [canvasSize, viewport]
  );

  const mode = useMemo<EViewportModes>(
    () => {
      if (initMode === EViewportModes.Navigate) {
        return isCanvasLargerThanViewport ? EViewportModes.Navigate : EViewportModes.Preview;
      }
      return initMode;
    },
    [isCanvasLargerThanViewport, initMode]
  );

  const { onMouseDown } = useDraggableScroll(areaRef, {
    direction: 'both',
    isDisabled: mode !== EViewportModes.Navigate,
  });

  const align = useMemo<{ x: boolean, y: boolean }>(
    () => ({
      x: (canvasSize.width >= canvasSize.height && canvasSize.width < viewport.width) || canvasSize.width < viewport.width,
      y: (canvasSize.height >= canvasSize.width && canvasSize.height < viewport.height) || canvasSize.height < viewport.height
    }),
    [canvasSize, viewport]
  );

  const areaClassName = useMemo(
    () => classNames(css.scrollArea, {
      [css.alignX]: align.x,
      [css.alignY]: align.y,
    }),
    [align]
  );

  return (
    <div className={css.container}>
      <div ref={areaRef} onMouseDown={onMouseDown} className={areaClassName}>
        <Canvas {...props} mode={mode} handleResized={handleResized} />
      </div>
    </div>
  );
};

export default Viewport;
