import { IObject } from 'components/PageArticles/components/Editor/contexts/EditorContext';
import fabric from '../../../helpers/fabric';
import { keyboardEventHandler } from './events';

export enum EBorderType {
  ACTIVE = 'active',
  ERROR = 'error',
  DEFAULT = '',
}

export enum EBorderTypeColor {
  ACTIVE = '#00C0FF',
  ERROR = '#FF2800',
  DEFAULT = 'transparent',
}

interface ISelectTriggers {
  e: MouseEvent | undefined,
  object?: IObject['object'] | undefined
}

const getBorderTypeColor = (type: EBorderType): EBorderTypeColor => {
  switch (type) {
    case EBorderType.ACTIVE:
      return EBorderTypeColor.ACTIVE;
    case EBorderType.ERROR:
      return EBorderTypeColor.ERROR;
    default:
      return EBorderTypeColor.DEFAULT;
  }
};
const BORDER_LINE_WIDTH: number = 3;
const BORDER_OFFSET: number = 1;

let keyboardEventContainer: (e: KeyboardEvent) => void = () => {};

export const extendCustomObject: any = {
  showBorder: EBorderType.DEFAULT,
  render(ctx: CanvasRenderingContext2D) {
    this.callSuper('render', ctx);

    // border drawing start
    const { canvas, showBorder } = this;
    if (canvas && showBorder) {
      const activeObject = canvas.getActiveObject();
      const isActiveObject: boolean = activeObject && activeObject.id === this.id;
      const isInActiveSelectionGroup: boolean = activeObject?._objects?.find((o: IObject['object']) => o?.id === this.id) !== undefined;

      if (!isActiveObject && !isInActiveSelectionGroup) {
        const zoomFactor: number = (1 / canvas.getZoom());
        const [tl, tr, br, bl]: fabric.Point[] = this.getCoords()
          .map((p: fabric.Point) => p.multiplyEquals(zoomFactor));
        const offset: number = BORDER_OFFSET * zoomFactor;

        ctx.strokeStyle = getBorderTypeColor(this.showBorder);
        ctx.lineWidth = BORDER_LINE_WIDTH * zoomFactor;
        ctx.beginPath();
        ctx.moveTo(tl.x - offset, tl.y - offset);
        ctx.lineTo(tr.x + offset, tr.y - offset);
        ctx.lineTo(br.x + offset, br.y + offset);
        ctx.lineTo(bl.x - offset, bl.y + offset);
        ctx.closePath();
        ctx.stroke();
      }
    }
    // border drawing end
  },

  locked: false,
  updateLock(isLocked: boolean) {
    this.updateEditable(!isLocked);
    this.set('locked', isLocked);
  },
  updateEditable(isEditable: boolean) {
    this.set({
      lockMovementX: !isEditable,
      lockMovementY: !isEditable,
      lockRotation: !isEditable,
      lockUniScaling: !isEditable,
      lockScalingY: !isEditable,
      lockScalingX: !isEditable,
      evented: isEditable,
      selectable: isEditable,
      hoverCursor: isEditable ? 'all-scroll' : 'default',
    });
  },

  onSelect({ e }: ISelectTriggers) {
    this.callSuper('onSelect', { e });

    const { canvas } = this;
    if (canvas) {
      keyboardEventContainer = (keyEvent: KeyboardEvent) => keyboardEventHandler(keyEvent, canvas, this);
      fabric.util.addListener(document.body, 'keydown', keyboardEventContainer);
    }
  },
  onDeselect(deselectData: ISelectTriggers) {
    this.callSuper('onDeselect', deselectData);

    const { canvas } = this;
    if (canvas) {
      fabric.util.removeListener(document.body, 'keydown', keyboardEventContainer);
    }
  }
};

export default { extendCustomObject };
