import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import 'assets/styles/index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import AppRoutes from './AppRoutes';
import reportWebVitals from './reportWebVitals';
import { AlertContextProvider } from './contexts/ContextAlert';
import { UserContextProvider } from './contexts/ContextUser';
import { checkUserBlocked } from './helpers';
import { IResponseError } from './constants/types';
import { ArticleListViewContextProvider } from './contexts/ContextArticleListView';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      checkUserBlocked(error as IResponseError);
    }
  }),
  mutationCache: new MutationCache({
    onError: error => {
      checkUserBlocked(error as IResponseError);
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <BrowserRouter>
          <AlertContextProvider>
            <ArticleListViewContextProvider>
              <StyledEngineProvider injectFirst>
                <AppRoutes />
              </StyledEngineProvider>
            </ArticleListViewContextProvider>
          </AlertContextProvider>
        </BrowserRouter>
      </UserContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
