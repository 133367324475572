import React, { useCallback, useState } from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Formik, FormikHelpers } from 'formik';
import cls from 'classnames';

import Button from 'components/library/Button';
import Modal from 'components/library/Modal';
import Input from 'components/library/Input';

import { TPrintItemNestedField } from 'types';
import { IPrintItemFormik } from 'types/Portfolio';
import strings from 'constants/localization';
import css from './Options.module.css';
import MultiSelectWithComment from '../MultiSelectWithComment';
import { generateOption, getOptionError } from './helpers';
import { getTouchedFieldError, handleMultiSelectChange } from '../../helpers';
import { OPTIONS, PrintItemFormAddOptionSchema } from '../../constants';

type TOptionsModal = {
  title: string,
}

const Options: React.FC<IPrintItemFormik> = ({ formik }) => {
  const {
    errors: { options: optionsErrors },
    values: { options },
    touched: { options: optionsTouched },
    handleChange,
    setFieldTouched,
    setFieldValue,
  } = formik;

  const [showModal, setShowModal] = useState<boolean>(false);

  const toggleShowModal = () => {
    setShowModal(prev => !prev);
  };

  const onAddOption = (data: TOptionsModal, formikHelpers: FormikHelpers<TOptionsModal>) => {
    const newOption: string = data.title.trim();
    const optionExists: boolean = !!options.find(opt => opt.name.toLowerCase() === newOption.toLowerCase());
    if (optionExists) {
      formikHelpers.setErrors({
        title: strings.formatString(strings.errorPrintItemFormSelectValueExists, 'option', newOption) as string,
      });
      return;
    }

    setFieldValue(OPTIONS, [...options, generateOption(newOption, options.length)]);
    toggleShowModal();
  };

  const onDeleteOption = useCallback(
    (key: string) => {
      setFieldValue(OPTIONS, options.filter(option => option.key !== key));
    },
    [options]
  );

  const onChangeOption = useCallback(
    (selectValues: OnChangeValue<TPrintItemNestedField, true>, actionMeta: ActionMeta<TPrintItemNestedField>, abstractOptionKey: string) => {
      const {
        name: fieldName
      } = actionMeta;
      if (fieldName) {
        const newValues = handleMultiSelectChange(selectValues, actionMeta, fieldName, abstractOptionKey);
        if (newValues) {
          setFieldValue(fieldName, newValues);
        }
      }
    },
    [setFieldValue]
  );

  return (
    <div>
      {options.map((option, idx) => {
        const onBlurHandler = () => setFieldTouched(`${OPTIONS}[${idx}].values`, true);
        const onDeleteOptionHandler = () => onDeleteOption(option.key);
        const onChangeHandler = (newValue: OnChangeValue<TPrintItemNestedField, true>, actionMeta: ActionMeta<TPrintItemNestedField>) => {
          onChangeOption(newValue, actionMeta, option.key);
        };
        return (
          <MultiSelectWithComment
            key={option.id || option.key}
            isDeletable
            deleteDisabled={!!option.id}
            selectFieldName={`${OPTIONS}[${idx}].values`}
            selectFieldLabel={option.name}
            selectFieldPlaceholder={strings.inputOptionPlaceholder}
            selectFieldValue={option.values}
            selectFieldError={getTouchedFieldError(!!optionsTouched?.[idx]?.values, getOptionError(optionsErrors, idx))}
            commentFieldName={`${OPTIONS}[${idx}].comment`}
            commentFieldValue={option.comment}
            commentFieldError={getOptionError(optionsErrors, idx, true)}
            handleChange={onChangeHandler}
            handleCommentChange={handleChange}
            handleDelete={onDeleteOptionHandler}
            handleBlur={onBlurHandler}
          />
        );
      })}
      <div className={cls({ [css.modalContainer]: options.length })}>
        <Button
          type="button"
          buttonType="primary"
          onClick={toggleShowModal}
        >
          {strings.addOption}
        </Button>
        <Modal
          isOpen={showModal}
          title={strings.addOption}
          customControls={null}
          onRequestClose={toggleShowModal}
        >
          <Formik
            validationSchema={PrintItemFormAddOptionSchema()}
            initialValues={{
              title: '',
            } as TOptionsModal}
            onSubmit={onAddOption}
          >
            {({
              values,
              errors: formikErrors,
              handleChange: formikHandleChange,
              submitForm,
              handleSubmit,
            }) => (
              <form noValidate className={css.form} onSubmit={handleSubmit}>
                <Input
                  name="title"
                  label={strings.inputTitleLabel}
                  placeholder={strings.inputTitlePlaceholder}
                  className={css.input}
                  value={values.title}
                  withError
                  error={formikErrors.title}
                  onChange={formikHandleChange}
                />
                <Button
                  type="button"
                  buttonType="primary"
                  className={css.addOptionSubmit}
                  disabled={!!formikErrors.title}
                  onClick={submitForm}
                >
                  {strings.printItemPageAddOptionModalAddButton}
                </Button>
              </form>
            )}
          </Formik>
        </Modal>
      </div>
    </div>
  );
};

export default Options;
