import React from 'react';
import cls from 'classnames';

import useTruncateText from 'hooks/useTruncateText';
import Tooltip from 'components/library/Tooltip';
import textCss from 'assets/styles/Text.module.css';
import css from './ArticlesDescription.module.css';

interface IArticlesDescription {
  description: string,
}
const ArticlesDescription: React.FC<IArticlesDescription> = ({ description }) => {
  const [elementTruncateProps, { isTruncated }] = useTruncateText<HTMLSpanElement>(40);

  const wrapperClassName: string = cls(css.description, textCss.pMedium3);
  const descriptionElement: JSX.Element = <span {...elementTruncateProps}>{description}</span>;
  return isTruncated
    ? (
      <Tooltip title={description} className={wrapperClassName}>
        {descriptionElement}
      </Tooltip>
    )
    : (
      <div className={wrapperClassName}>
        {descriptionElement}
      </div>
    );
};

export default ArticlesDescription;
