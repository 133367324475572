/* eslint-disable max-len */
import { ILanguages } from '../types';
import IManagersStrings from '../types/ManagersStrings';

const strings:ILanguages<IManagersStrings> = {
  en: {
    managersPageTitle: 'Managers',

    managersPageButtonAddManager: 'Add manager',
  },
  de: {
    managersPageTitle: 'Veranstalter',

    managersPageButtonAddManager: 'Veranstalter anlegen',
  },
  ru: {
    managersPageTitle: 'Менеджеры',

    managersPageButtonAddManager: 'Добавить менеджера',
  },
};

export default strings;
