import React from 'react';
import cls from 'classnames';
import { FormikProps } from 'formik';
import textCss from '../../../assets/styles/Text.module.css';
import strings from '../../../constants/localization';
import Checkbox from '../../library/Checkbox';
import css from './Tab.module.css';
import { IFormPermissionsValues, IFormsValues, PERMISSIONS_FORM } from '../constants';
import { EPermissions } from '../../../types';

interface ITabPermissions {
  formik: FormikProps<IFormsValues>,
  disabled?: boolean,
}
const getFieldName = (fieldName: keyof IFormPermissionsValues): string => `${PERMISSIONS_FORM}.${fieldName}`;

const TabPermissions:React.FC<ITabPermissions> = ({ formik, disabled }) => {
  const {
    getFieldProps,
    setFieldValue,
  } = formik;

  const onChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
    if (name === getFieldName(EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS) && checked) {
      setFieldValue(getFieldName(EPermissions.VIEW_MANAGER_CUSTOMERS), true);
      setFieldValue(getFieldName(EPermissions.EDIT_MANAGER_CUSTOMERS), true);
    }
    if ([
      getFieldName(EPermissions.EDIT_MANAGER_CUSTOMERS),
      getFieldName(EPermissions.VIEW_MANAGER_CUSTOMERS)
    ].includes(name)) {
      setFieldValue(getFieldName(EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS), false);
    }
  };

  return (
    <>
      <span className={cls(textCss.pLight1)}>{strings.profileTabPermissions}</span>
      <form noValidate>
        <Checkbox
          {...getFieldProps(getFieldName(EPermissions.CHANGE_ORDER_STATUS))}
          onChange={onChange}
          disabled={disabled}
          wrapperClassName={css.checkbox}
          label={strings.profilePageInputChangeOrderStatusLabel}
        />
        <Checkbox
          {...getFieldProps(getFieldName(EPermissions.ASSIGN_MANAGERS))}
          onChange={onChange}
          disabled={disabled}
          wrapperClassName={css.checkbox}
          label={strings.profilePageInputReassignManagersLabel}
        />
        <Checkbox
          {...getFieldProps(getFieldName(EPermissions.VIEW_MANAGER_CUSTOMERS))}
          onChange={onChange}
          disabled={disabled}
          wrapperClassName={css.checkbox}
          label={strings.profilePageInputViewOnlyOwnCustomersLabel}
        />
        <Checkbox
          {...getFieldProps(getFieldName(EPermissions.EDIT_MANAGER_CUSTOMERS))}
          onChange={onChange}
          disabled={disabled}
          wrapperClassName={css.checkbox}
          label={strings.profilePageInputEditOnlyOwnCustomersLabel}
        />
        <Checkbox
          {...getFieldProps(getFieldName(EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS))}
          onChange={onChange}
          disabled={disabled}
          wrapperClassName={css.checkbox}
          label={strings.profilePageInputEditAndViewCustomersLabel}
        />
      </form>
    </>
  );
};

export default TabPermissions;
