import React, { useEffect, useRef } from 'react';
import SliderSlick, { CustomArrowProps } from 'react-slick';
import get from 'lodash/get';
import { ETemplateSides, TArticleDocument, TOrderItem } from 'types';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import css from './Slider.module.css';
import { SIDE_TO_STRING } from '../../helper';
import Icon from '../../../../../library/Icon';
import { E_ICON_TYPE } from '../../../../../library/Icon/types';
import Side from './Side';

const getAppendDots = (dots: React.ReactNode, side:ETemplateSides) => (
  <div>
    <div className={css.dots}>
      <span className={cls(textCss.h4, css.side)}>{SIDE_TO_STRING[side]()}</span>
      <div>
        {dots}
      </div>
    </div>
  </div>
);

const Arrow:React.FC<React.HTMLProps<HTMLDivElement> & CustomArrowProps> = props => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className, currentSlide, slideCount, ...rest
  } = props;
  return <div className={cls(className, { [css.arrowDisabled]: !rest.onClick })} {...rest}>{props.children}</div>;
};

interface ISlider {
  activeSide: ETemplateSides,
  sides: TArticleDocument[],
  zoom: number,
  changeSlide: (prev:number, next:number) => void,
  orderItem?: TOrderItem | null,
  updateItemsToSave?: (canvas: TArticleDocument['canvas']) => void,
  setCanvasLoadingStatus:(value:boolean)=>void,
  canvasLoadingStatus?: { [key in ETemplateSides]: boolean }
}

const Slider:React.FC<ISlider> = props => {
  const {
    activeSide, sides, orderItem, zoom, updateItemsToSave, changeSlide, setCanvasLoadingStatus, canvasLoadingStatus
  } = props;

  const ref = useRef<SliderSlick>(null);

  useEffect(() => {
    const index = sides.findIndex(side => side.side === activeSide);
    if (ref.current && index >= 0) {
      ref.current.slickGoTo(index);
    }
  }, [ref, activeSide, sides]);

  return (
    <SliderSlick
      ref={ref}
      dots
      arrows
      lazyLoad="ondemand"
      infinite={false}
      draggable={false}
      className={css.sliderContainer}
      beforeChange={changeSlide}
      slidesToShow={1}
      slidesToScroll={1}
      appendDots={dots => getAppendDots(dots, activeSide)}
      nextArrow={<Arrow><Icon type={E_ICON_TYPE.next} className={css.arrow} /></Arrow>}
      prevArrow={<Arrow><Icon type={E_ICON_TYPE.back} className={css.arrow} /></Arrow>}
    >
      {sides.map(side => (
        <Side
          canvasLoadingStatus={get(canvasLoadingStatus, side.side)}
          updateItemsToSave={updateItemsToSave}
          key={side.side}
          side={side}
          orderItem={orderItem}
          zoom={zoom}
          setCanvasLoadingStatus={setCanvasLoadingStatus}
        />
      ))}
    </SliderSlick>
  );
};

export default Slider;
