import React, { ChangeEvent, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';

import Modal from 'components/library/Modal';
import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import strings from 'constants/localization';
import { DEFAULT_DEBOUNCE_DELAY } from 'constants/constants';
import css from './ModalReject.module.css';

interface IModalConfirm {
  isOpen: boolean,
  onRequestClose: () => void,
  onOk: (reason: string) => void,
}
const MAX_LENGTH = 255;
const ModalReject:React.FC<IModalConfirm> = ({
  isOpen, onRequestClose, onOk
}) => {
  const [state, setState] = useState('');
  const [error, setError] = useState<undefined | string>();
  const onChange = useDebounceCallback((e:ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > MAX_LENGTH) {
      setError(strings.formatString(strings.inputMaxLen, MAX_LENGTH) as string);
    } else {
      setError(undefined);
    }
    setState(e.target.value);
  }, DEFAULT_DEBOUNCE_DELAY);
  return (
    <Modal
      className={css.modal}
      isOpen={isOpen}
      title={strings.orderPageModalRejectOrderTitle}
      onRequestClose={onRequestClose}
      onOk={() => onOk(state)}
      okButtonProps={{ disabled: state.length === 0 || !!error }}
      onCancel={onRequestClose}
    >
      <div className={css.container}>
        <div className={textCss.pMedium3}>{strings.orderPageModalRejectOrderQuestion}</div>
        <Input
          error={error}
          withError
          label={strings.orderPageModalRejectOrderReasonFieldLabel}
          className={css.input}
          onChange={onChange}
        />
      </div>
    </Modal>
  );
};
export default ModalReject;
