import { FormikErrors } from 'formik';

import {
  EPrintItemSizeNumberFields,
  EPrintItemSizeStringFields,
  TPrintItemNestedField,
  TPrintItemPricing,
  TPrintItemSize
} from 'types';
import { TPrintItemSizeInputsMapper } from 'types/Portfolio';
import { replaceNumberSeparator } from 'helpers';
import strings from 'constants/localization';

export const SIZE_INPUTS: TPrintItemSizeInputsMapper[] = [
  {
    field: EPrintItemSizeStringFields.NAME,
    label: () => strings.inputSizeNameLabel,
    placeholder: () => strings.inputSizeNamePlaceholder,
  },
  {
    field: EPrintItemSizeNumberFields.WIDTH,
    label: () => strings.inputWidthLabel,
    placeholder: () => strings.inputSizeWidthPlaceholder,
  },
  {
    field: EPrintItemSizeNumberFields.HEIGHT,
    label: () => strings.inputHeightLabel,
    placeholder: () => strings.inputSizeHeightPlaceholder,
  },
  {
    field: EPrintItemSizeNumberFields.BLEED,
    label: () => strings.inputBleedLabel,
    placeholder: () => strings.inputSizeBleedPlaceholder,
  },
];

const convertToMM = (value: number) => value * 10;
const convertToCM = (value: number) => value / 10;

const converterHelper = (value: string, convert:(value: number)=>number) => {
  const input = replaceNumberSeparator(value);
  if (Number.isNaN(input)) {
    return '';
  }
  const output = convert(+input);
  const rounded = Math.round(output * 1000) / 1000;
  return rounded === 0 ? '' : rounded.toString();
};

export const mmConverter = (value: string) => replaceNumberSeparator(converterHelper(value, convertToMM), false);
export const cmConverter = (value: string) => replaceNumberSeparator(converterHelper(value, convertToCM), false);

export const sizeFieldEqualsName = (field: string) => field === EPrintItemSizeStringFields.NAME;

export const getQuantityAndPaperError = (errors: undefined | string | string[] | FormikErrors<TPrintItemNestedField>[] | undefined): string | undefined => {
  if (errors) {
    if (typeof errors === 'string') {
      return errors;
    }
    const formikErrors = errors as FormikErrors<TPrintItemNestedField>[];
    return formikErrors.find(nestedFieldValue => nestedFieldValue?.value)?.value;
  }
};

export const getPricingsAfterSizeChanged = (sizeKey: string, updatedSize: TPrintItemSize, oldPricings: TPrintItemPricing[]) => oldPricings
  .map(pricing => pricing.size.key === sizeKey
    ? {
      ...pricing,
      size: { ...updatedSize },
    }
    : pricing);
