import React from 'react';

import FooterCancelButton from 'components/library/FooterActionButtons/components/FooterCancelButton';
import FooterActionButtons from 'components/library/FooterActionButtons';

import { EFooterSaveButtonDisplayModes } from 'types';
import css from './FooterContent.module.css';

interface IFooter extends React.HTMLProps<HTMLDivElement> {
  saveBtnDisplayMode: EFooterSaveButtonDisplayModes | boolean,
  tabIndex: number,
  tabsLen: number,
  onClickSave: () => void,
  disableSave: boolean,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>,
}
const FooterContent: React.FC<IFooter> = ({
  saveBtnDisplayMode, tabIndex, tabsLen, setTabIndex, onClickSave, disableSave
}) => (
  <div className={css.footer}>
    <FooterCancelButton />
    <FooterActionButtons
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      tabCount={tabsLen}
      onClickSave={onClickSave}
      disableSave={disableSave}
      saveBtnDisplayMode={saveBtnDisplayMode}
    />
  </div>
);

export default FooterContent;
