import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import PageContent from 'components/library/PageContent';
import strings from 'constants/localization';
import {
  ECanvasObjectTypes,
  EOrderStatus,
  EPermissions,
  EProductTypes,
  ERoles,
  TArticle, TArticleDocument,
  TOrderItem,
  TUser
} from 'types';
import { useGetOrder, useOrderCreate, useOrderPatch } from 'hooks/useOrders';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/library/Loader';
import Button from 'components/library/Button';
import Select from 'components/library/Select';
import AlertContext from 'contexts/ContextAlert';
import useGetArticle from 'hooks/Articles/useGetArticle';
import { dataUrlToAwsLink } from 'components/PageArticles/components/Editor/helpers/aws';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { ROUTES } from 'constants/constants';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import css from './Order.module.css';
import Preview from './components/Preview';
import OrderForm from './components/OrderForm';
import OrderItems from './components/OrderItems';
import OrderContext from './ContextOrder';
import { getOptionsFromArticle, promiseMap } from './helper';
import Footer from './components/Footer';
import PageTitle from './components/PageTitle';
import ModalReject from '../ModalReject';
import ModalSummary from './components/ModalSummary';

export interface IOrder {
  isOrderCreation?: boolean,
  id?: string,
  articleId?: string,
  customerId?: string,
  user: TUser
}

const Order:React.FC<IOrder> = ({
  user, articleId, id, isOrderCreation = false, customerId
}) => {
  const { data: order, isLoading } = useGetOrder({ orderId: id });
  const { data: article, isLoading: isArticleLoading } = useGetArticle({
    customerId, pspId: user.pspId, id: articleId, includeBase: true, enabled: isOrderCreation
  });
  const { state, setState } = useContext(OrderContext);
  const alertContext = useContext(AlertContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalReject, setModalReject] = useState<boolean>(false);
  const [modalSummary, setModalSummary] = useState<boolean>(false);
  const orderCreate = useOrderCreate();
  const patchOrder = useOrderPatch();
  const navigate = useNavigate();

  const isVDP = state.productType === EProductTypes.VD_PRODUCT;
  const creatable = isOrderCreation && isVDP;
  const canRejectOrder = order?.id
    && order?.status === EOrderStatus.CREATED
    && (user.userPermissions.includes(EPermissions.MANAGER) || user.userPermissions.includes(EPermissions.PRINTER));

  const onRejectOrder = (reason: string) => {
    patchOrder
      .mutateAsync({
        customerId, pspId: user.pspId, id: order?.id, status: EOrderStatus.ISSUE, reason
      })
      .then(() => {
        alertContext.push({ severity: 'success', message: strings.orderPageNotificationUpdateStatus });
      })
      .finally(() => setModalReject(false));
  };
  const setSelectedItem = useCallback((value:TOrderItem | null) => {
    if (value && value.id !== state.selectedOrderItem?.id) {
      setState({ selectedOrderItem: value });
    }
  }, [setState, state.selectedOrderItem]);
  const onCancel = isOrderCreation ? () => navigate(-1) : undefined;
  const onClickBack = isOrderCreation ? undefined : () => navigate(-1);
  const onApprove = async () => {
    setLoading(true);
    const awsImages = {} as {[key: string]:string};
    const processedOrderItems = await promiseMap(state.orderItemsToSave, async (orderItem, itemIndex) => {
      const items = await promiseMap(orderItem.items, async (item, paramIndex) => {
        if (ECanvasObjectTypes.IMAGE === item.type && item.src?.startsWith('data:image')) {
          if (!Object.hasOwn(awsImages, item.src)) {
            const src = await dataUrlToAwsLink(item.src, `image-${item.id}-${itemIndex}-${paramIndex}`, user.pspId as string).catch(console.error) || '';
            awsImages[item.src] = src;
            return { ...item, src };
          }
          return { ...item, src: awsImages[item.src] };
        }
        return item;
      }, { concurrency: 3 });
      return {
        ...orderItem,
        items
      };
    }, { concurrency: 3 });
    orderCreate.mutateAsync({
      pspId: user.pspId,
      customerId,
      paperId: state.paper?.id,
      quantityId: state.quantity?.id,
      orderItems: processedOrderItems,
      orderComment: state.orderComment,
      price: state.price,
      status: EOrderStatus.CREATED,
      options: state.optionsState,
      currency: state.currency,
      article: state.article as TArticle,
    })
      .then(() => {
        setState({ changes: false, saved: true });
      })
      .catch(err => alertContext.push({ severity: 'error', message: err.message }))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!state.changes && state.saved) {
      switch (user.role) {
        case ERoles.PRINTER: navigate(`/${ROUTES.ORDERS}`); break;
        case ERoles.MANAGER: navigate(`/${ROUTES.CUSTOMERS}`); break;
        default: navigate(-1);
      }
    }
  }, [state.changes, state.saved, user.role]);

  useEffect(() => {
    if (order) {
      const options = getOptionsFromArticle(order.article, order);
      setState({
        ...options,
        orderItems: order.orderItems,
        selectedOrderItem: order.orderItems[0],
        orderId: order.sourceData?.serviceOrderId || order.title,
        ticketType: order.sourceData?.ticketTypeName,
        isOrderCreation,
        orderSheetReady: order.orderSheetStatus,
        linkToBack: order.linkToBack,
        linkToFront: order.linkToFront,
      });
    }
    if (isOrderCreation && article) {
      const options = getOptionsFromArticle(article);
      setState({
        ...options,
        article,
        isOrderCreation
      });
    }
  }, [isOrderCreation, order, article]);

  const openModalSummary = () => setModalSummary(true);

  const sides = [state.frontSide, state.backSide].filter(s => s) as TArticleDocument[];

  return (
    <PageContent
      pageTitle={<PageTitle order={order} isOrderCreation={!!isOrderCreation} />}
      withBreadcrumbs
      footerContent={(
        <Footer
          onApprove={isOrderCreation ? openModalSummary : undefined}
          onCancel={onCancel}
          onClickBack={onClickBack}
        />
      )}
    >
      {isArticleLoading || isLoading || loading ? <div className={css.loaderContainer}><Loader /></div> : null}
      {!isLoading && !isArticleLoading && (
        <>
          <div className={css.controls}>
            {!creatable && isVDP && (
              <div className={css.controlItems}>
                <Select
                  value={state.selectedOrderItem}
                  onChange={newValue => setSelectedItem(newValue)}
                  options={state.orderItems}
                  getOptionValue={e => e.title}
                  getOptionLabel={e => e.title}
                  isSearchable={false}
                />
                {state.orderItems.length > 1 && <span className={cls(textCss.pMedium2, css.itemsCount)}>{strings.orderPageOf} {state.orderItems.length}</span>}
              </div>
            )}
            <div />
            {canRejectOrder && (
              <Button
                buttonType="secondary"
                onClick={() => setModalReject(true)}
              >
                {strings.orderPageViewRejectOrder}
              </Button>
            )}
          </div>
          {creatable && (
            <div className={css.editor}>
              <OrderItems />
            </div>
          )}
          {!creatable && (
            <div className={css.preview}>
              <Preview />
            </div>
          )}
          <OrderForm />
        </>
      )}
      {isOrderCreation && <UnsavedChangesModal areChangesExist={state.changes} />}
      {!isOrderCreation && (
        <ModalReject
          isOpen={modalReject}
          onOk={onRejectOrder}
          onRequestClose={() => setModalReject(false)}
        />
      )}
      {sides.length && isOrderCreation && article ? (
        <ModalSummary
          sides={sides}
          items={state.orderItemsToSave}
          onSave={onApprove}
          article={article}
          onCancel={() => setModalSummary(false)}
          options={state.optionsState}
          price={state.price}
          isOpen={modalSummary}
        />
      ) : null}
    </PageContent>
  );
};

export default Order;
