import { ECanvasObjectTypes, EIntegrationServices } from 'types';
import { TIntegrationCanvasObjects, TIntegrationOrderFieldItem } from 'types/Integration/index';
import Fuse, { IFuseOptions } from 'fuse.js';
import { unionBy } from 'lodash';
import { TSideJsonObject } from '.';
import {
  ORDER_FIELDS, CODE_KARUSSEL_ORDER_FIELDS, EVENTBRITE_ORDER_FIELDS, PAYLOGIC_ORDER_FIELDS, API_ORDER_FIELDS,
} from './constants';
import { ITicketType } from './ChooseTicketModalContent/components/TicketTypeSelect';

export const filterObjectsByType = (objects: TIntegrationCanvasObjects | undefined): TIntegrationCanvasObjects => objects
  ? objects.filter(obj => obj.type !== ECanvasObjectTypes.IMAGE && obj.type !== ECanvasObjectTypes.NUMBERING && !obj.locked)
  : [];

export const sideReadyForMatching = (objects: TIntegrationCanvasObjects): boolean => objects.length > 0;

export const findOrderFieldSample = (service: EIntegrationServices | null, path: string): string => {
  let orderFields: TIntegrationOrderFieldItem[] = [];

  if (service) {
    orderFields = getOrderFields(service);
  }

  return orderFields.find(item => item.path === path)?.sample || '';
};

export const mergeObjectsInformation = (src: TSideJsonObject[], mergeWith: TSideJsonObject[]) => {
  if (src?.length !== mergeWith?.length) {
    return src;
  }
  return src.map((srcItem, idx) => {
    const { top: srcTop, left: srcLeft } = srcItem;
    if (srcTop === 0 || srcLeft === 0) {
      const { top, left } = mergeWith[idx];
      return { ...srcItem, top, left };
    }
    return srcItem;
  });
};

export const isAlreadyConnectedTicket = (ticket: string, connectedTickets: string[]) => connectedTickets.includes(ticket);

export const getOrderFields = (service: EIntegrationServices): TIntegrationOrderFieldItem[] => {
  let orderFields: string[];
  switch (service) {
    case EIntegrationServices.CODE_KARUSSEL:
      orderFields = CODE_KARUSSEL_ORDER_FIELDS;
      break;
    case EIntegrationServices.API:
      orderFields = API_ORDER_FIELDS;
      break;
    case EIntegrationServices.EVENTBRITE:
      orderFields = EVENTBRITE_ORDER_FIELDS;
      break;
    case EIntegrationServices.PAYLOGIC:
      orderFields = PAYLOGIC_ORDER_FIELDS;
      break;
    default:
      orderFields = [];
      break;
  }

  return ORDER_FIELDS.filter(({ path }) => orderFields.includes(path));
};

export const sortTicketTypesByStatus = (data: ITicketType[], connected: string[]): ITicketType[] => [...data].sort(
  (a: ITicketType, b: ITicketType): number => {
    const isAConnected = connected.some(id => a.id === id);
    const isBConnected = connected.some(id => b.id === id);
    if (isAConnected && !isBConnected) {
      return 1;
    }
    if (isBConnected && !isAConnected) {
      return -1;
    }
    return 0;
  }
);

export const sortTicketTypesByName = (data: ITicketType[], articleName: string): ITicketType[] => {
  const MIN_SEARCH_LENGTH = 2 as const;
  const FUSE_OPTIONS: IFuseOptions<ITicketType> = {
    isCaseSensitive: false,
    minMatchCharLength: MIN_SEARCH_LENGTH,
    keys: ['name'],
    threshold: 1,
  };

  if (articleName.length < MIN_SEARCH_LENGTH) {
    return data;
  }

  const fuse = new Fuse<ITicketType>(data, FUSE_OPTIONS);
  const results = fuse.search(articleName).map(({ item }) => item);

  return unionBy(results, data, 'id');
};
