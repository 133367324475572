/* eslint-disable max-len */
import IBaseStrings from '../types/BaseStrings';
import { ILanguages } from '../types';

const strings:ILanguages<IBaseStrings> = {
  en: {
    inputEmailLabel: 'Email',
    inputEmailPlaceholder: 'name@domain.com',
    inputPasswordLabel: 'Password',
    inputPasswordPlaceholder: 'at least 6 characters',
    inputCurrentPasswordLabel: 'Current Password',
    inputNewPasswordLabel: 'New Password',
    inputPasswordConfirmLabel: 'Confirm New Password',
    inputPasswordConfirmPlaceholder: 'at least 6 characters',
    inputLabelOptional: 'Optional',
    inputNoOptions: 'No options',
    inputMaxLen: 'Character limit exceeded ({0})',

    inputTitleLabel: 'Title',
    inputTitlePlaceholder: 'Enter title',
    inputDescriptionLabel: 'Description',
    inputDescriptionPlaceholder: 'Enter description',
    inputNameLabel: 'Name',
    inputNamePlaceholder: 'Enter name',
    inputLastNameLabel: 'Last Name',
    inputLastNamePlaceholder: 'Enter Last Name',
    inputCompanyNameLabel: 'Company Name',
    inputCompanyNamePlaceholder: 'Enter Company Name',
    inputSendOrderSheetsToLabel: 'Send order sheets to',
    inputSizeNameLabel: 'Name',
    inputSizeNamePlaceholder: 'Enter name',
    inputWidthLabel: 'Width',
    inputSizeWidthPlaceholder: 'Enter width',
    inputHeightLabel: 'Height',
    inputSizeHeightPlaceholder: 'Enter height',
    inputBleedLabel: 'Bleed',
    inputSizeBleedPlaceholder: 'Enter bleed',
    inputSizesLabel: 'Sizes',
    inputCommentLabel: 'Comment',
    inputQuantityLabel: 'Quantities',
    inputQuantityPlaceholder: 'e.g. 10',
    inputPaperLabel: 'Paper',
    inputPaperPlaceholder: 'e.g. Coated',
    inputOptionPlaceholder: 'Enter option values',
    inputProductNameLabel: 'Product name',
    inputProductNamePlaceholder: 'Enter Product name',
    inputSearchTextPlaceholder: 'Search item',

    inputDisplayCharactersLenHelper: 'Characters {0}/{1}',

    inputSelectCreateOptionLabel: 'Create "{0}"',

    inputValidateRequiredField: 'Required field',
    inputValidateEmail: 'Incorrect email',
    inputValidatePassLength: 'Password must be at least 6 characters',
    inputValidateStringLength: 'Field must be no more than {0} characters',
    inputValidateMultiSelectStringsLength: 'Selected values must be no more than {0} characters',
    inputValidatePassMatch: 'Passwords must match',
    inputValidateMinOne: 'At least one item',
    inputValidateNotTheSameNewPass: 'The new password must not match the current one.',
    inputValidatePositiveNumber: 'Field must be a positive number',

    actionLabelYes: 'Yes',
    actionLabelNo: 'No',
    actionLabelOk: 'Ok',
    actionLabelCopy: 'Copy',
    actionLabelBlock: 'Block',
    actionLabelUnblock: 'Unblock',
    actionLabelRemove: 'Remove',
    actionLabelReassign: 'Reassign',
    actionLabelSave: 'Save',
    actionLabelPlaceOrder: 'Place Order',
    actionLabelSaveForLater: 'Save for later',
    actionLabelBack: 'Back',
    actionLabelNext: 'Next',
    actionLabelCancel: 'Cancel',

    labelCompany: 'Company',
    labelLang: 'Language',
    labelBlockUser: '{block} {role}',
    labelRemoveUser: '{remove} {role}',

    filterAll: 'All',
    filterBlocked: 'Blocked',
    filterUnblocked: 'Unblocked',

    errorResponse401: 'Invalid email or password',
    errorResponse4: '4**: Client Error',
    errorResponse403Blocked: 'Your account has been suspended.',
    errorResponse403: '',
    errorResponse404: 'Requested entity is not available',
    errorResponse5: '5**: Internal Server Error',
    errorResponseUnknownEmail: 'Unknown email address',
    errorResponseInvalidResetToken: 'Invalid reset token',

    appName: 'Orgafly',

    routePortfolio: 'Portfolio',
    routeOrders: 'Orders',
    routeCustomers: 'Events',
    routeEvents: 'Events',
    routeManagers: 'Managers',
    routeProfile: 'Profile',
    routeChat: 'Support',
    routeLogout: 'Logout',
    routePrinters: 'Printers',
    routeSKUs: 'SKUs',

    columnHeadTitle: 'Title',
    columnHeadQuantities: 'Quantities',
    columnHeadDescription: 'Description',
    columnHeadDelete: 'Delete',
    columnHeadCustomer: 'Event',
    columnHeadEvent: 'Event',
    columnHeadCustomerCount: 'Events',
    columnHeadContacts: 'Contacts',
    columnHeadManager: 'Manager',
    columnHeadSKUs: 'SKUs',
    columnHeadOrders: 'Orders',
    columnHeadBlock: 'Block',
    columnHeadProduct: 'Product',
    columnHeadStatus: 'Status',
    columnHeadAvailableQtys: 'Available Qtys',
    columnHeadQty: 'Qty',
    columnHeadTicketType: 'Ticket Type',
    columnHeadActions: 'Actions',
    columnHeadPreview: 'Preview',
    columnHeadProductName: 'Product Name',
    columnHeadOptionLabel: 'Option label',
    columnHeadOptionValue: 'Option value',
    columnHeadSize: 'Size',
    columnHeadPaper: 'Paper',
    columnHeadNetPrice: 'Net Price',
    columnHeadType: 'Type',
    columnHeadQtyWithValue: 'Qty - {0}',

    modalBlockCustomerQuestion: 'Do you really want to block "{0}"?',
    modalBlockPrinterQuestion: 'Do you really want to block "{0}"?',
    modalUnblockCustomerQuestion: 'Do you really want to unblock "{0}"?',
    modalUnblockManagerQuestion: 'Do you really want to unblock "{0}"?',
    modalUnblockPrinterQuestion: 'Do you really want to unblock "{0}"?',
    modalBlockManagerHeader: 'Reassign events',
    modalBlockManagerWithoutCustomersMessage: 'User doesn\'t have events and can be blocked without any problems',
    modalBlockReassignCustomersHelperTextWithQty: 'Choose manager for selected {0} events',
    modalBlockReassignCustomersHelperTextAll: 'Choose manager for selected {0} events',
    modalBlockReassignCustomersLabelAll: 'all',

    modalUnsavedChangesTitle: 'Leaving the page',
    modalUnsavedChangesBody: 'Changes you made will not be saved. Are you sure you want to leave the page?',

    productTypeVDP: 'Variable',
    productTypeStatic: 'Static',

    orderStatusCreated: 'Created',
    orderStatusReceived: 'Received',
    orderStatusSendToPrinter: 'Send to Printer',
    orderStatusInPrinting: 'In Printing',
    orderStatusInDelivery: 'In Delivery',
    orderStatusAssumedDelivered: 'Assumed Delivered',
    orderStatusIssue: 'Issue',
    orderStatusArchived: 'Archived',

    pdfPreview: 'PDF preview',

    articleStatusAll: 'All',
    articleStatusDraft: 'Draft',
    articleStatusPublished: 'Published',

    sortByNameAsc: 'Sort sheet A > Z',
    sortByNameDesc: 'Sort sheet Z > A',
    sortByDateDesc: 'New first',
    sortByDateAsc: 'Old first',
    sortByIdDesc: 'Sort A > Z',
    sortByIdAsc: 'Sort Z > A',

    emptyStateTitleNoResults: 'No results found.',
    emptyStateTitleNoResultsDescription: 'We couldn’t find what you’re looking for.',
    emptyStateOnPageTitle: 'Looks like there are no {0} yet.',
    emptyStateOnPageDescription: 'To get started, click on {0}',

    langEN: 'English',
    langDE: 'Deutsch',
    langRU: 'Русский',

    roleCustomerLabel: 'Event',
    roleManagerLabel: 'Manager',
    rolePrinterLabel: 'Printer',
    roleAdminLabel: 'Admin',

    quantities: 'Quantities',
    paper: 'Paper',
    size: 'Size',
    options: 'Options',

    typeSomethingLabel: 'Type something...',
    viewAll: 'View all',

    canvasZoomOptionFitLabel: 'Fit',
  },
  de: {
    inputEmailLabel: 'E-Mail',
    inputEmailPlaceholder: 'Name@firma.de',
    inputPasswordLabel: 'Passwort',
    inputPasswordPlaceholder: 'mindestens 6 Zeichen',
    inputCurrentPasswordLabel: 'Aktuelles Passwort',
    inputNewPasswordLabel: 'Neues Passwort',
    inputPasswordConfirmLabel: 'Passwort wiederholen',
    inputPasswordConfirmPlaceholder: 'mindestens 6 Zeichen',
    inputLabelOptional: '(Optional)',
    inputNoOptions: 'Keine Auswahl vorhanden',
    inputMaxLen: 'Es sind nur ({0}) Zeichen möglich',

    inputTitleLabel: 'Titel',
    inputTitlePlaceholder: 'Ticketname',
    inputDescriptionLabel: 'Ticket-Beschreibung',
    inputDescriptionPlaceholder: 'Kurzbeschreibung eingeben',
    inputNameLabel: 'Vorname',
    inputNamePlaceholder: 'Vorname eingeben',
    inputLastNameLabel: 'Nachname',
    inputLastNamePlaceholder: 'Nachname eingeben',
    inputCompanyNameLabel: 'Firmenname',
    inputCompanyNamePlaceholder: 'Firmenname eingeben',
    inputSendOrderSheetsToLabel: 'Empfänger für Auftragsdaten',
    inputSizeNameLabel: 'Bezeichnung',
    inputSizeNamePlaceholder: 'z.B. DIN A4',
    inputWidthLabel: 'Breite',
    inputSizeWidthPlaceholder: 'Wert eingeben',
    inputHeightLabel: 'Höhe',
    inputSizeHeightPlaceholder: 'Wert eingeben',
    inputBleedLabel: 'Beschnittzugabe',
    inputSizeBleedPlaceholder: 'Wert eingeben',
    inputSizesLabel: 'Format',
    inputCommentLabel: 'Kurzbeschreibung',
    inputQuantityLabel: 'Auflagen',
    inputPaperLabel: 'Papier',
    inputProductNameLabel: 'Produktname',
    inputProductNamePlaceholder: 'Produktbezeichnung eingeben',
    inputSearchTextPlaceholder: 'Suchbegriff',

    inputDisplayCharactersLenHelper: 'Zeichen {0}/{1}',

    inputSelectCreateOptionLabel: '{0}',

    inputValidateRequiredField: 'erforderlich',
    inputValidateEmail: 'Ungültige E-Mail-Adresse',
    inputValidatePassLength: 'Das Passwort muss mindestens 6 Zeichen lang sein',
    inputValidateStringLength: 'Bitte Text kürzen (max. {0} Zeichen)',
    inputValidateMultiSelectStringsLength: 'Papierbezeichnung darf nicht mehr als {0} Zeichen haben',
    inputValidatePassMatch: 'Die beiden Werte stimmen nicht überein',
    inputValidateMinOne: 'Es muss mindestens eine Auflage konfiguriert werden',
    inputValidateNotTheSameNewPass: 'Das neue Passwort darf nicht mit dem Aktuellen identisch sein.',
    inputValidatePositiveNumber: 'Es muss ein Wert größer Null eingegeben werden',

    actionLabelYes: 'Ja',
    actionLabelNo: 'Nein',
    actionLabelOk: 'Ok',
    actionLabelCopy: 'In die Zwischenablage kopieren',
    actionLabelBlock: 'Zugang sperren',
    actionLabelUnblock: 'Zugang reaktivieren',
    actionLabelRemove: 'Löschen',
    actionLabelReassign: 'Neu zuordnen',
    actionLabelSave: 'Speichern',
    actionLabelSaveForLater: 'Speichern',
    actionLabelBack: 'Zurück',
    actionLabelNext: 'Nächste',
    actionLabelCancel: 'Abbrechen',

    labelCompany: 'Unternehmen',
    labelLang: 'Sprache',
    labelBlockUser: '{block} {role}',
    labelRemoveUser: '{remove} {role}',

    filterAll: 'Alle Einträge',
    filterBlocked: 'Gesperrt',
    filterUnblocked: 'Aktiv',

    errorResponse401: 'E-Mail oder Passwort ist falsch',
    errorResponse4: 'Anwenderfehler',
    errorResponse403Blocked: 'Ihr Zugang wurde gesperrt',
    errorResponse403: '',
    errorResponse404: 'Die Seite wurde nicht gefunden',
    errorResponse5: 'Interner Serverfehler',
    errorResponseUnknownEmail: 'Die E-Mail ist unbekannt',
    errorResponseInvalidResetToken: 'Das Passwort konnte nicht geändert werden',

    appName: 'Orgafly',

    routePortfolio: 'Portfolio',
    routeOrders: 'Bestellungen',
    routeCustomers: 'Veranstaltungen',
    routeManagers: 'Veranstalter',
    routeProfile: 'Profil',
    routeChat: 'Support',
    routeLogout: 'Logout',
    routePrinters: 'Drucker',
    routeSKUs: 'Ticketübersicht',

    columnHeadTitle: 'Druckprodukt',
    columnHeadQuantities: 'Auflagen',
    columnHeadDescription: 'Beschreibung',
    columnHeadDelete: 'Löschen',
    columnHeadCustomer: 'Veranstaltung',
    columnHeadCustomerCount: 'Veranstaltungsübersicht',
    columnHeadContacts: 'Ansprechpartner',
    columnHeadManager: 'Veranstalter',
    columnHeadSKUs: 'Tickets',
    columnHeadOrders: 'Bestellung',
    columnHeadBlock: 'Zugangsstatus',
    columnHeadProduct: 'Druckartikel',
    columnHeadStatus: 'Status',
    columnHeadAvailableQtys: 'Auflagen',
    columnHeadQty: 'Auflage',
    columnHeadActions: 'Aktion',
    columnHeadPreview: 'Vorschau',
    columnHeadProductName: 'Produktname',
    columnHeadOptionLabel: 'Zusatzoption',
    columnHeadOptionValue: 'Wert',
    columnHeadSize: 'Format',
    columnHeadPaper: 'Papier',
    columnHeadNetPrice: 'Nettopreis',
    columnHeadType: 'Artikelart',
    columnHeadQtyWithValue: 'Auflage - {0}',

    modalBlockCustomerQuestion: 'Wollen Sie wirklich den Zugang der Veranstaltung "{0}" sperren?',
    modalBlockPrinterQuestion: 'Wollen Sie wirklich den Zugang der Druckerei "{0}" sperren?',
    modalUnblockCustomerQuestion: 'Wollen Sie den Zugang zur Veranstaltung "{0}" reaktivieren?',
    modalUnblockManagerQuestion: 'Wollen Sie den Zugang des Veranstalters "{0}" reaktivieren?',
    modalUnblockPrinterQuestion: 'Wollen Sie den Zugang der Druckerei "{0}" reaktivieren?',
    modalBlockManagerHeader: 'Veranstaltung neu zuweisen',
    modalBlockManagerWithoutCustomersMessage: 'Der Veranstalter hat keine Veranstaltungen. Sein Konto kann sofort gesperrt werden.',
    modalBlockReassignCustomersHelperTextWithQty: 'Wählen Sie für die {0} angezeigten Veranstaltungen einen neuen Veranstalter aus',
    modalBlockReassignCustomersHelperTextAll: 'Wählen Sie für amgezeigten Veranstaltungen einen neuen Veranstalter aus',
    modalBlockReassignCustomersLabelAll: 'alle',

    modalUnsavedChangesTitle: 'Seite verlassen?',
    modalUnsavedChangesBody: 'Ihre Änderungen werden nicht gespeichert, wenn Sie den Dialog ohne zu Sichern verlassen. Wollen Sie das wirklich tun?',

    productTypeVDP: 'Variabler Artikel',
    productTypeStatic: 'Statischer Artikel',

    orderStatusCreated: 'Neu',
    orderStatusReceived: 'Eingegangen',
    orderStatusSendToPrinter: 'Beim Drucker',
    orderStatusInPrinting: 'In Produktion',
    orderStatusInDelivery: 'Im Versand',
    orderStatusAssumedDelivered: 'Zugestellt',
    orderStatusIssue: 'Problem',
    orderStatusArchived: 'Archiviert',

    pdfPreview: 'Vorschau',

    articleStatusAll: 'Alle Einträge',
    articleStatusDraft: 'Entwurf',
    articleStatusPublished: 'Veröffentlicht',

    sortByNameAsc: 'A...Z sortieren',
    sortByNameDesc: 'Z...A sortieren',
    sortByDateDesc: 'Neuester zuerst',
    sortByDateAsc: 'Ältester zuerst',
    sortByIdDesc: 'A...Z sortieren',
    sortByIdAsc: 'Z...A sortieren',

    emptyStateTitleNoResults: 'Keine Treffer',
    emptyStateTitleNoResultsDescription: 'Zu ihrer Suche gibt es keine Treffer',
    emptyStateOnPageTitle: 'Es gibt keine {0}.',
    emptyStateOnPageDescription: 'Klicke Sie auf {0}, um mit der Arbeit zu beginnen',

    langEN: 'English',
    langDE: 'Deutsch',
    langRU: 'Русский',

    roleCustomerLabel: 'Veranstaltung',
    roleManagerLabel: 'Veranstalter',
    rolePrinterLabel: 'Druckerei',
    roleAdminLabel: 'Admin',

    quantities: 'Auflagen',
    paper: 'Papier',
    size: 'Format',
    options: 'Zusatzoptionen',

    typeSomethingLabel: 'Etwas eingeben...',
    viewAll: 'Anzeigen',

    canvasZoomOptionFitLabel: 'Fit',
  },
  ru: {
    inputEmailLabel: 'Электронный адрес',
    inputEmailPlaceholder: 'name@domain.com',
    inputPasswordLabel: 'Пароль',
    inputPasswordPlaceholder: 'не менее 6 символов',
    inputCurrentPasswordLabel: 'Текущий пароль',
    inputNewPasswordLabel: 'Новый пароль',
    inputPasswordConfirmLabel: 'Повторите новый пароль',
    inputPasswordConfirmPlaceholder: 'не менее 6 символов',
    inputLabelOptional: 'необязательно',
    inputNoOptions: 'нет вариантов',
    inputMaxLen: 'Превышен лимит символов ({0})',

    inputTitleLabel: 'Название',
    inputTitlePlaceholder: 'Введите название',
    inputDescriptionLabel: 'Описание',
    inputDescriptionPlaceholder: 'Введите краткое описание',
    inputNameLabel: 'Имя',
    inputNamePlaceholder: 'Введите имя',
    inputLastNameLabel: 'Фамилия',
    inputLastNamePlaceholder: 'Введите фамилию',
    inputCompanyNameLabel: 'Название компании',
    inputCompanyNamePlaceholder: 'Введите название компании',
    inputSendOrderSheetsToLabel: 'Отправить задание на печать на электронную почту',
    inputSizeNameLabel: 'Название',
    inputSizeNamePlaceholder: 'Введите название ...',
    inputWidthLabel: 'Ширина',
    inputSizeWidthPlaceholder: 'Введите …',
    inputHeightLabel: 'Высота',
    inputSizeHeightPlaceholder: 'Введите …',
    inputBleedLabel: 'Обрез поля',
    inputSizeBleedPlaceholder: 'Введите …',
    inputSizesLabel: 'Форматы',
    inputCommentLabel: 'Комментарий',
    inputQuantityLabel: 'Тираж',
    inputQuantityPlaceholder: 'Введите тираж',
    inputPaperLabel: 'Бумага',
    inputPaperPlaceholder: 'Введите тип бумаги',
    inputOptionPlaceholder: 'Введите значения опции',
    inputProductNameLabel: 'Название продукта',
    inputProductNamePlaceholder: 'Введите название продукта',
    inputSearchTextPlaceholder: 'Поиск',

    inputDisplayCharactersLenHelper: 'Символы {0}/{1}',

    inputSelectCreateOptionLabel: 'Создать "{0}"',

    inputValidateRequiredField: 'обязательное поле',
    inputValidateEmail: 'Недействительный электронный адрес',
    inputValidatePassLength: 'Пароль должен содержать не менее 6 символов',
    inputValidateStringLength: 'Поле должно содержать не более {0} символов',
    inputValidateMultiSelectStringsLength: 'Выбранные значения должны содержать не более {0} символов',
    inputValidatePassMatch: 'Пароли не совпадают',
    inputValidateMinOne: 'Должно быть добавлено хотя бы одно значение',
    inputValidateNotTheSameNewPass: 'Новый пароль не должен совпадать с текущим',
    inputValidatePositiveNumber: 'Должно быть введено значение больше нуля',

    actionLabelYes: 'Да',
    actionLabelNo: 'Нет',
    actionLabelOk: 'Ок',
    actionLabelCopy: 'Копировать',
    actionLabelBlock: 'Заблокировать',
    actionLabelUnblock: 'Разблокировать',
    actionLabelRemove: 'Удалить',
    actionLabelReassign: 'Переназначить',
    actionLabelSave: 'Сохранить',
    actionLabelPlaceOrder: 'Разместить заказ',
    actionLabelSaveForLater: 'Сохранить',
    actionLabelBack: 'Назад',
    actionLabelNext: 'Далее',
    actionLabelCancel: 'Отмена',

    labelCompany: 'Компания',
    labelLang: 'Язык',
    labelBlockUser: '{block} {role}а',
    labelRemoveUser: '{remove} {role}а',

    filterAll: 'Все',
    filterBlocked: 'Заблокированный',
    filterUnblocked: 'Активный',

    errorResponse401: 'Неверный электронный адрес или пароль',
    errorResponse4: 'Ошибка пользователя',
    errorResponse403Blocked: 'Ваш аккаунт был заблокирован.',
    errorResponse403: 'Доступ запрещен',
    errorResponse404: 'Страница не найдена',
    errorResponse5: 'Внутренняя ошибка сервера',
    errorResponseUnknownEmail: 'Неизвестный электронный адрес',
    errorResponseInvalidResetToken: 'Не удалось изменить пароль',

    appName: 'Orgafly',

    routePortfolio: 'Портфолио',
    routeOrders: 'Заказы',
    routeCustomers: 'Мероприятия',
    routeEvents: 'Мероприятия',
    routeManagers: 'Менеджеры',
    routeProfile: 'Профиль',
    routeChat: 'Поддержка',
    routeLogout: 'Выход',
    routePrinters: 'Принтеры',
    routeSKUs: 'Артикулы',

    columnHeadTitle: 'Название продукта',
    columnHeadQuantities: 'Тираж',
    columnHeadDescription: 'Описание',
    columnHeadDelete: 'Удалить',
    columnHeadCustomer: 'Мероприятие',
    columnHeadEvent: 'Мероприятие',
    columnHeadCustomerCount: 'Мероприятия',
    columnHeadContacts: 'Контактные данные',
    columnHeadManager: 'Менеджер',
    columnHeadSKUs: 'Артикулы',
    columnHeadOrders: 'Заказы',
    columnHeadBlock: 'Статус',
    columnHeadProduct: 'Продукт',
    columnHeadStatus: 'Статус',
    columnHeadAvailableQtys: 'Доступное количество',
    columnHeadQty: 'Количество',
    columnHeadTicketType: 'Тип билета',
    columnHeadActions: 'Действия',
    columnHeadPreview: 'Предварительный просмотр',
    columnHeadProductName: 'Название продукта',
    columnHeadOptionLabel: 'Дополнительная опция',
    columnHeadOptionValue: 'Значение опции',
    columnHeadSize: 'Формат',
    columnHeadPaper: 'Бумага',
    columnHeadNetPrice: 'Цена нетто',
    columnHeadType: 'Тип',
    columnHeadQtyWithValue: 'Количество - {0}',

    modalBlockCustomerQuestion: 'Вы действительно хотите заблокировать мероприятие "{0}"?',
    modalBlockPrinterQuestion: 'Вы действительно хотите заблокировать принтера "{0}"?',
    modalUnblockCustomerQuestion: 'Вы действительно хотите разблокировать мероприятие "{0}"?',
    modalUnblockManagerQuestion: 'Вы действительно хотите разблокировать менеджера "{0}"?',
    modalUnblockPrinterQuestion: 'Вы действительно хотите разблокировать принтера "{0}"?',
    modalBlockManagerHeader: 'Переназначение клиентов',
    modalBlockManagerWithoutCustomersMessage: 'У менеджера нет закрепленных за ним мероприятий. Его учетная запись может быть немедленно заблокирована.',
    modalBlockReassignCustomersHelperTextWithQty: 'Выберите менеджера для выбранных {0} мероприятий',
    modalBlockReassignCustomersHelperTextAll: 'Выберите менеджера для выбранных {0} мероприятий',
    modalBlockReassignCustomersLabelAll: 'Все',

    modalUnsavedChangesTitle: 'Покинуть страницу',
    modalUnsavedChangesBody: 'Внесенные вами изменения не будут сохранены. Вы уверены, что хотите покинуть страницу?',

    productTypeVDP: 'Изменяемый продукт',
    productTypeStatic: 'Статический продукт',

    pdfPreview: 'Предварительный просмотр',

    articleStatusAll: 'Все',
    articleStatusDraft: 'Черновик',
    articleStatusPublished: 'Опубликовано',

    sortByNameAsc: 'Сортировка списка А > Я',
    sortByNameDesc: 'Сортировка списка Я > А',
    sortByDateDesc: 'Сначала новые',
    sortByDateAsc: 'Сначала старые',
    sortByIdDesc: 'Сортировка А > Я',
    sortByIdAsc: 'Сортировка Я > A',

    emptyStateTitleNoResults: 'Результатов не найдено',
    emptyStateTitleNoResultsDescription: 'Мы не смогли найти то, что вы ищете',
    emptyStateOnPageTitle: 'Похоже, что {0} пока не созданы',
    emptyStateOnPageDescription: 'Чтобы начать, нажмите на {0}',

    langEN: 'English',
    langDE: 'Deutsch',
    langRU: 'Русский',

    roleCustomerLabel: 'Мероприятие',
    roleManagerLabel: 'Менеджер',
    rolePrinterLabel: 'Принтер',
    roleAdminLabel: 'Администратор',

    quantities: 'Тираж',
    paper: 'Бумага',
    size: 'Формат',
    options: 'Дополнительные опции',

    typeSomethingLabel: 'Введите что-нибудь...',
    viewAll: 'Посмотреть все',

    canvasZoomOptionFitLabel: 'Заполнить',

    orderStatusCreated: 'Новый',
    orderStatusReceived: 'Ревью',
    orderStatusSendToPrinter: 'Отправлено PSP',
    orderStatusInPrinting: 'В печати',
    orderStatusInDelivery: 'В доставке',
    orderStatusAssumedDelivered: 'Доставлено',
    orderStatusIssue: 'Ошибка',
    orderStatusArchived: 'Архив',
  },
};

export default strings;
