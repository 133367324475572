/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextDefault(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M12.75 13H17.25C17.6625 13 18 12.55 18 12C18 11.45 17.6625 11 17.25 11H12.75H11.25H6.75C6.3375 11 6 11.45 6 12C6 12.55 6.3375 13 6.75 13H11.25H12.75Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextDefault;
