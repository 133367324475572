import strings from 'constants/localization';
import { ECanvasObjectTypes } from 'types';
import { IObject } from '../contexts/EditorContext';
import { ECodeObjectTitle } from '../components/Viewport/fabric/codeObject';
import { ECanvasActiveSelection } from '../constants';

export const getObjectTitle = (object: IObject['object'], fullTitle = false): string => {
  switch (object?.type) {
    case ECanvasObjectTypes.TEXT:
      return strings.editorObjectTextTitle;
    case ECanvasObjectTypes.NUMBERING:
      return strings.editorObjectNumberingTitle;
    case ECanvasObjectTypes.IMAGE:
      return strings.editorObjectImageTitle;
    case ECanvasObjectTypes.CODE:
      return fullTitle ? getCodeObjectTitle(object?.title) : strings.editorObjectCodeTitle;
    case ECanvasActiveSelection.ACTIVE_SELECTION:
      return strings.editorActiveSelectionTitle;
    default:
      return object?.title ?? '';
  }
};

export const getCodeObjectTitle = (type: ECodeObjectTitle): string => {
  switch (type) {
    case ECodeObjectTitle.QR:
      return strings.editorObjectQrCodeTitle;
    case ECodeObjectTitle.BARCODE:
      return strings.editorObjectBarCodeTitle;
    default:
      return type;
  }
};

export default getObjectTitle;
