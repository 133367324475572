import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Location } from 'history';
import get from 'lodash/get';
import { NavigateFunction } from 'react-router/lib/hooks';
import AlertContext, { IAlertContext } from 'contexts/ContextAlert';
import Loader from 'components/library/Loader';
import { IResponseSuccess, useEventbriteAuthentication } from 'hooks/useAuthentication';
import { IResponseError } from 'constants/types';
import strings from 'constants/localization';
import UserContext, { IUserContext } from 'contexts/ContextUser';
import { ELanguages, TUser } from 'types';
import { getDefaultRoute } from 'helpers';
import useEffectOnce from 'hooks/useEffectOnce';
import css from './Authorize.module.css';

export interface IEventbriteAuthorizeContainer {
  token: string
  language?: ELanguages
}

export const Container:React.FC<IEventbriteAuthorizeContainer> = props => {
  const {
    token,
    language = ELanguages.EN,
  } = props;

  const { push } = useContext(AlertContext);
  const { mutateAsync } = useEventbriteAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  return useMemo(() => (
    <EventbriteAuthorize
      token={token}
      language={language}
      push={push}
      onAuthorize={mutateAsync}
      location={location}
      navigate={navigate}
      userContext={userContext}
    />
  ), [token, language, push, mutateAsync, location, navigate, userContext]);
};

interface ILoginLocationState {
  from?: Location,
}

interface IEventbriteAuthorize {
  token: string
  language: ELanguages
  push: IAlertContext['push']
  onAuthorize: any
  location: Location
  navigate: NavigateFunction
  userContext: IUserContext
}

interface IAuthorizeRequestData {
  token: string
  language: ELanguages
}

const EventbriteAuthorize:React.FC<IEventbriteAuthorize> = props => {
  const {
    token,
    language,
    push,
    onAuthorize,
    location,
    navigate,
    userContext: {
      setUser, setToken
    },
  } = props;

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      push({ message: error, severity: 'error' });
    }
  }, [error]);

  const navigateUser = (user: TUser): void => {
    const locationState = location.state as ILoginLocationState;
    const path = locationState?.from?.pathname || getDefaultRoute(user.role, user.id);
    navigate(path, { replace: true });
  };

  const authorize = useCallback((data: IAuthorizeRequestData) => {
    onAuthorize(data).then((res: IResponseSuccess) => {
      localStorage.setItem('token', res.accessToken);
      setToken(res.accessToken);
      setUser(res.user);
      navigateUser(res.user);
    }).catch((err: IResponseError) => {
      const status = get(err, 'response.status', 500);
      const message = get(err, 'response.data.message') || get(err, 'response.data.data.reason');
      if (status === 401) {
        setError(strings.errorResponse401);
      } else if (status >= 400 && status <= 500) {
        setError(message || strings.errorResponse4);
      }
      if (status >= 500) {
        setError(message || strings.errorResponse5);
      }
    });
  }, [onAuthorize, push, localStorage, setToken, setUser, navigateUser, get]);

  useEffectOnce(() => {
    if (token) {
      authorize({ token, language });
    }
  }, [token, language]);

  return error ? (<b className={css.error}>{ error }</b>) : (<Loader />);
};

export default Container;
