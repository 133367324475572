import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import useSize from '@react-hook/size';
import fabric from 'components/PageArticles/components/Editor/helpers/fabric';
import Viewport from 'components/PageArticles/components/Editor/components/Viewport/Viewport';
import { EViewportModes } from 'components/PageArticles/components/Editor/constants';
import { TArticleDocument, TOrderItem, TParam } from 'types';
import css from './Side.module.css';
import useFabricObjects from '../../../../../PageArticles/components/Editor/hooks/useFabricObjects/useFabricObjects';
import { filterObjectPropertiesByType } from '../../helper';

const viewportDefault = {
  width: 400,
  height: 320,
  zoom: 0
};

interface ISide {
  side: TArticleDocument,
  orderItem?: TOrderItem | null,
  zoom: number,
  updateItemsToSave?: (objects:TArticleDocument['canvas']) => void,
  setCanvasLoadingStatus: (value:boolean)=>void,
  canvasLoadingStatus?: boolean
}
const Side:React.FC<ISide> = ({
  side, orderItem, zoom, updateItemsToSave, setCanvasLoadingStatus, canvasLoadingStatus
}) => {
  const [, setUpdate] = useState(false);
  const prevObjects = useRef<Partial<TParam>[]>([]);
  const ref = useRef(null);
  const [width, height] = useSize(ref);
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const { setObjects: setObjectsEffect, setObject, json } = useFabricObjects(canvasRef);

  const viewport = useMemo(() => ({
    ...viewportDefault,
    width: width || viewportDefault.width,
    height: height || viewportDefault.height,
    zoom
  }), [width, height, zoom]);

  useEffect(() => {
    if (orderItem?.items && !canvasLoadingStatus) {
      const objects = orderItem.items.map(filterObjectPropertiesByType);
      setUpdate(true);
      if (!prevObjects.current.length) {
        setObjectsEffect(objects, side.canvas.objects).then(() => setUpdate(false));
      } else {
        const promises = objects.map(obj => {
          const prevObj = prevObjects.current.find(prevObject => prevObject.id === obj.id);
          if (prevObj?.src !== obj.src || prevObj?.content !== obj.content || prevObj?.text !== obj.text) {
            const original = side.canvas.objects.find(o => o.id === obj.id);
            return setObject(obj, original);
          }
          return true;
        });
        Promise.all(promises).then(() => setUpdate(false));
      }
      prevObjects.current = [...objects];
    }
  }, [orderItem?.items, side.canvas.objects, canvasLoadingStatus]);

  useEffect(() => {
    const data = json as TArticleDocument['canvas'];
    if (data?.objects && updateItemsToSave) {
      updateItemsToSave(data);
    }
  }, [json]);

  return (
    <div className={css.container}>
      <div className={css.slide} ref={ref}>
        <Viewport
          key={side.document.src}
          mode={EViewportModes.Navigate}
          viewport={viewport}
          json={side.canvas}
          setObjects={() => {}}
          pdfDocument={side.document}
          canvas={canvasRef}
          setCanvasLoadingStatus={setCanvasLoadingStatus}
        />
      </div>
    </div>
  );
};

export default React.memo(Side);
