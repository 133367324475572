import React, {
  ChangeEvent,
  FC, useCallback, useContext, useMemo,
} from 'react';
import { FormikHelpers, useFormik } from 'formik';

import { get } from 'lodash';
import Button from 'components/library/Button';
import Label from 'components/library/Label';
import { TUser } from 'types';
import { IProfileIntegrationTabFormBind } from 'types/Integration';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import UserContext from 'contexts/ContextUser';
import { useCreateIntegrationEventBind, useDeleteIntegrationEventBind, usePatchIntegrationEventBind } from 'hooks/Integration/useIntegrationEventBind';
import containerCss from '../Tab.module.css';
import EventBindInformation from '../EventBindInformation';
import Input from '../../../library/Input';

const EVENT = 'event';

interface IIntegrationConnect {
    customerId: string,
    eventBind: TUser['eventBind']
}

const CodeKarusselIntegrationEventBind: FC<IIntegrationConnect> = ({ customerId, eventBind }) => {
  const {
    id: eventBindId,
    eventId: bindedEventId,
    eventName: bindedEventName,
    connection
  } = eventBind || {};

  const { user } = useContext(UserContext);
  const { push } = useContext(AlertContext);

  const connectionId = useMemo<string | null | undefined>(
    () => user?.integration?.id,
    [user]
  );

  const { mutateAsync: createEventBind } = useCreateIntegrationEventBind({ customerId, connectionId });
  const { mutateAsync: patchEventBind } = usePatchIntegrationEventBind({ customerId, connectionId });
  const { mutateAsync: deleteEventBind, isLoading: isDeleting } = useDeleteIntegrationEventBind({ customerId, connectionId });

  const onBindEvent = async (formData: IProfileIntegrationTabFormBind, options: FormikHelpers<IProfileIntegrationTabFormBind>) => {
    const { event } = formData;
    if (event) {
      options.setSubmitting(true);

      const { label: newEventName, value: newEventId } = event;
      const commonSaveData = { eventId: newEventId, eventName: newEventName, organizationId: null };
      const promise = eventBindId
        ? patchEventBind({ id: eventBindId, ...commonSaveData })
        : createEventBind(commonSaveData);

      await promise
        .then(() => {
          push({ severity: 'success', message: eventBindId ? strings.successEventBindUpdate : strings.successEventBind });
        })
        .catch(err => {
          const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
          push({ severity: 'error', message: errMsg });
        })
        .finally(() => {
          options.setSubmitting(false);
        });
    }
  };

  const {
    values: { event: chosenEvent },
    getFieldProps,
    isSubmitting,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      [EVENT]: null,
    } as IProfileIntegrationTabFormBind,
    onSubmit: onBindEvent,
  });

  const onDeleteEvent = useCallback(
    () => {
      const { id } = eventBind || {};
      if (id) {
        deleteEventBind(id)
          .then(() => {
            resetForm();
            push({ severity: 'success', message: strings.successEventBindDelete });
          })
          .catch(err => {
            const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
            push({ severity: 'error', message: errMsg });
          });
      }
    },
    [eventBind, deleteEventBind]
  );

  const isSavedEventChosen = useMemo<boolean>(
    () => bindedEventId === chosenEvent?.value,
    [bindedEventId, chosenEvent]
  );

  const submitBtnDisabled = useMemo<boolean>(
    () => isSubmitting || isDeleting || !chosenEvent?.value || !chosenEvent?.label || isSavedEventChosen,
    [isSubmitting, chosenEvent, isSavedEventChosen, isDeleting]
  );

  const inputDisabled = useMemo<boolean>(
    () => isDeleting,
    [isDeleting]
  );

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const label = chosenEvent?.label;
    setFieldValue(EVENT, { value, label });
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: label } = e.target;
    const value = chosenEvent?.value;
    setFieldValue(EVENT, { value, label });
  };

  const getIdProps = () => {
    const props = getFieldProps(EVENT);
    props.value = get(props, 'value.value', '');
    return props;
  };

  const getNameProps = () => {
    const props = getFieldProps(EVENT);
    props.value = get(props, 'value.label', '');
    return props;
  };

  return (
    <>
      {bindedEventName && (
        <EventBindInformation
          bindedEventName={bindedEventName}
          connectedService={connection?.service}
        />
      )}
      <form noValidate onSubmit={handleSubmit}>
        <div className={containerCss.selectContainer}>
          <Label text={strings.inputEventIdLabel} />
          <Input
            {...getIdProps()}
            onChange={onChangeId}
            disabled={inputDisabled}
          />
        </div>
        <div className={containerCss.selectContainer}>
          <Label text={strings.inputEventNameLabel} />
          <Input
            {...getNameProps()}
            onChange={onChangeName}
            disabled={inputDisabled}
          />
        </div>
        <div>
          <Button
            type="submit"
            buttonType="primary"
            className={containerCss.submitBtn}
            disabled={submitBtnDisabled}
          >{bindedEventId ? strings.rebind : strings.bindEvent}</Button>
          <Button
            type="button"
            buttonType="tertiary"
            className={containerCss.deleteBtn}
            disabled={!bindedEventId}
            onClick={onDeleteEvent}
          >{strings.actionLabelRemoveEventBinding}</Button>
        </div>
      </form>
    </>
  );
};

export default CodeKarusselIntegrationEventBind;
