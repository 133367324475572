import LocalizedStrings from 'react-localization';
import IStrings, { ILanguageStrings } from './types';
import Base from './modules/Base';
import Login from './modules/Login';
import Portfolio from './modules/Porfolio';
import Printers from './modules/Printers';
import Managers from './modules/Managers';
import Customers from './modules/Customers';
import Events from './modules/Events';
import Articles from './modules/Articles';
import Editor from './modules/Editor';
import Profile from './modules/Profile';
import Orders from './modules/Orders';
import Integration from './modules/Integration';

const strings:IStrings = new LocalizedStrings({
  en: {
    ...Base.en,
    ...Login.en,
    ...Portfolio.en,
    ...Printers.en,
    ...Managers.en,
    ...Customers.en,
    ...Events.en,
    ...Articles.en,
    ...Editor.en,
    ...Profile.en,
    ...Orders.en,
    ...Integration.en,
  },
  de: {
    ...Base.de,
    ...Login.de,
    ...Portfolio.de,
    ...Printers.de,
    ...Managers.de,
    ...Customers.de,
    ...Events.de,
    ...Articles.de,
    ...Editor.de,
    ...Profile.de,
    ...Orders.de,
    ...Integration.de,
  } as ILanguageStrings, // casting because German localization always lags behind English and has Partial in types
  ru: {
    ...Base.ru,
    ...Login.ru,
    ...Portfolio.ru,
    ...Printers.ru,
    ...Managers.ru,
    ...Customers.ru,
    ...Events.ru,
    ...Articles.ru,
    ...Editor.ru,
    ...Profile.ru,
    ...Orders.ru,
    ...Integration.ru,
  } as ILanguageStrings, // casting because Russian localization always lags behind English and has Partial in types
});

const setLanguage = () => {
  const lang = localStorage.getItem('lang');
  strings.setLanguage(lang || 'en');
};
setLanguage();

export default strings;
