import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import useQueryParams from '../../hooks/useQueryParams';
import EventbriteAuthorize from './components/authorize';
import { ROUTES } from '../../constants/constants';
import Loader from '../library/Loader';
import css from './EventbriteLogin.module.css';
import textCss from '../../assets/styles/Text.module.css';
import strings from '../../constants/localization';
import { getJwtToken } from './helpers/jwt';
import { ELanguages } from '../../types';

const Container:React.FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const data = useMemo(() => getJwtToken(queryParams, 'esr'), [queryParams]);
  const token = useMemo<string>(() => data?.auth_token || '', [data?.auth_token]);
  const language = useMemo<ELanguages>(
    () => data?.language as ELanguages || ELanguages.EN,
    [data?.language]
  );

  useEffect(() => {
    if (data === null) {
      navigate(`${ROUTES.USER_LOGIN}/${ROUTES.EVENTBRITE}`);
    }
  }, [data]);

  return data
    ? (
      <div className={css.container}>
        <div className={css.innerContainer}>
          <div className={cls(textCss.h2, css.formLabel)}>{strings.loginPageLoginTitle}</div>
          <EventbriteAuthorize token={token} language={language} />
        </div>
      </div>
    )
    : (<Loader />);
};

export default Container;
