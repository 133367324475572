/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconEmail(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="128" height="128" rx="64" fill="#EAF7FD" />
      <g clipPath="url(#clip0_1491_16392)" filter="url(#filter0_d_1491_16392)">
        <path d="M92.9239 59.0344L64 35.4482L35.0761 59.0344C34.5791 59.5309 34.2069 60.1516 34.2069 60.8965V97.5172C34.2069 98.8828 35.324 100 36.6897 100H91.3103C92.676 100 93.7931 98.8828 93.7931 97.5172V60.8965C93.7931 60.1516 93.4209 59.5309 92.9239 59.0344Z" fill="#EDD9BE" />
        <path d="M82.6207 28H45.3793C44.0137 28 42.8966 29.1171 42.8966 30.4828V82.6207H85.1035V30.4828C85.1035 29.1171 83.9863 28 82.6207 28Z" fill="white" />
        <path d="M46.6207 35.4484C46.6207 34.7628 47.1765 34.207 47.8621 34.207H80.1379C80.8235 34.207 81.3793 34.7628 81.3793 35.4484C81.3793 36.134 80.8235 36.6898 80.1379 36.6898H47.8621C47.1765 36.6898 46.6207 36.134 46.6207 35.4484Z" fill="#BCDEF0" />
        <path d="M46.6207 41.6554C46.6207 40.9698 47.1765 40.4141 47.8621 40.4141H80.1379C80.8235 40.4141 81.3793 40.9698 81.3793 41.6554C81.3793 42.341 80.8235 42.8968 80.1379 42.8968H47.8621C47.1765 42.8968 46.6207 42.341 46.6207 41.6554Z" fill="#BCDEF0" />
        <path d="M46.6207 47.8625C46.6207 47.1769 47.1765 46.6211 47.8621 46.6211H80.1379C80.8235 46.6211 81.3793 47.1769 81.3793 47.8625C81.3793 48.5481 80.8235 49.1038 80.1379 49.1038H47.8621C47.1765 49.1038 46.6207 48.5481 46.6207 47.8625Z" fill="#BCDEF0" />
        <path d="M46.6207 54.0695C46.6207 53.3839 47.1765 52.8281 47.8621 52.8281H51.5862C52.2718 52.8281 52.8276 53.3839 52.8276 54.0695C52.8276 54.7551 52.2718 55.3109 51.5862 55.3109H47.8621C47.1765 55.3109 46.6207 54.7551 46.6207 54.0695Z" fill="#BCDEF0" />
        <path d="M46.6207 60.2756C46.6207 59.59 47.1765 59.0342 47.8621 59.0342H50.3448C51.0304 59.0342 51.5862 59.59 51.5862 60.2756C51.5862 60.9612 51.0304 61.5169 50.3448 61.5169H47.8621C47.1765 61.5169 46.6207 60.9612 46.6207 60.2756Z" fill="#BCDEF0" />
        <path d="M75.1724 54.0695C75.1724 53.3839 75.7282 52.8281 76.4138 52.8281H80.1379C80.8235 52.8281 81.3793 53.3839 81.3793 54.0695C81.3793 54.7551 80.8235 55.3109 80.1379 55.3109H76.4138C75.7282 55.3109 75.1724 54.7551 75.1724 54.0695Z" fill="#BCDEF0" />
        <path d="M76.4138 60.2756C76.4138 59.59 76.9696 59.0342 77.6552 59.0342H80.1379C80.8235 59.0342 81.3793 59.59 81.3793 60.2756C81.3793 60.9612 80.8235 61.5169 80.1379 61.5169H77.6552C76.9696 61.5169 76.4138 60.9612 76.4138 60.2756Z" fill="#BCDEF0" />
        <path d="M36.6897 99.9995H91.3103C92.0553 99.9995 92.676 99.751 93.1724 99.1303L34.3312 60.2754C34.2069 60.5239 34.2069 60.7718 34.2069 60.8961V97.5168C34.2069 98.8824 35.324 99.9995 36.6897 99.9995Z" fill="#F4E0C5" />
        <path d="M93.1724 99.1303C93.5446 98.7582 93.7931 98.1375 93.7931 97.5168V60.8961C93.7931 60.6476 93.7931 60.5239 93.6688 60.2754L64.1243 80.0132L93.1724 99.1303Z" fill="#E2C8AC" />
        <path d="M67.4756 56.5518H64C61.2687 56.5518 59.0345 58.786 59.0345 61.5173C59.0345 64.2486 61.2687 66.4828 64 66.4828H67.1034L67.7241 69.3377C68.5933 68.9656 69.3377 68.4691 69.9584 67.8484L67.4756 56.5518ZM64 64C62.6344 64 61.5172 62.8829 61.5172 61.5173C61.5172 60.1516 62.6344 59.0345 64 59.0345H65.4899L66.607 64H64Z" fill="white" />
        <path d="M64 51.5859C58.5381 51.5859 54.069 56.055 54.069 61.517C54.069 66.9789 58.5381 71.448 64 71.448C69.462 71.448 73.931 66.9789 73.931 61.517C73.931 56.055 69.462 51.5859 64 51.5859ZM69.7105 66.3582L67.4756 56.5515H64C61.2687 56.5515 59.0345 58.7857 59.0345 61.517C59.0345 64.2482 61.2687 66.4825 64 66.4825H67.1035L67.4756 68.096C63.8757 70.0824 59.283 68.7167 57.2967 64.9926C55.3103 61.3927 56.676 56.8 60.4001 54.8136C64 52.8273 68.5934 54.193 70.5791 57.9171C72.069 60.6478 71.6968 63.9997 69.7105 66.3582ZM66.607 63.9997H64C62.6344 63.9997 61.5172 62.8826 61.5172 61.517C61.5172 60.1513 62.6344 59.0342 64 59.0342H65.4899L66.607 63.9997Z" fill="#BCDEF0" />
      </g>
      <defs>
        <filter id="filter0_d_1491_16392" x="28" y="28" width="92" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="10" dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.243137 0 0 0 0 0.4 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1491_16392" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1491_16392" result="shape" />
        </filter>
        <clipPath id="clip0_1491_16392">
          <rect width="72" height="72" fill="white" transform="translate(28 28)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconEmail;
