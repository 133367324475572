import React, {
  KeyboardEvent,
  FocusEvent,
  ChangeEvent,
  useState,
} from 'react';
import { Slider } from '@mui/material';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import Icon from 'components/library/Icon';
import Input from 'components/library/Input';
import css from './SidebarSlider.module.css';

const INPUT_REGEX = '^[-]?[0-9]+(,[0-9]+)?$';
const INPUT_FORBIDDEN_SYMBOLS = ['e', '+', '.'];

export interface ISidebarSlider {
  icon?: E_ICON_TYPE | null,
  withInput?: boolean,
  inputAdornment?: string,
  min?: number,
  max?: number,
  step?: number,
  value: number,
  onChange: (value: number) => void,
}

const SidebarSlider:React.FC<ISidebarSlider> = props => {
  const {
    icon = null,
    withInput = false,
    inputAdornment = '',
    min = 1,
    max = 100,
    step = 1,
    value,
    onChange,
  } = props;

  const [sliderValue, setSliderValue] = useState<number>(value);
  const [inputValue, setInputValue] = useState<string>(value.toString());

  const onSliderChange = (e: Event, newValue: number | number[]) => {
    newValue = Array.isArray(newValue) ? newValue[0] : newValue;
    setSliderValue(newValue);
    setInputValue(newValue.toString());
    onChange(newValue);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    setInputValue(target.value);
    if (target.checkValidity() && target.value !== '') {
      setSliderValue(target.valueAsNumber);
      onChange(target.valueAsNumber);
    }
  };

  // if invalid or empty - set initial value
  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    if (target.checkValidity() && target.value !== '') {
      return;
    }

    setInputValue(value.toString());
    setSliderValue(value);
  };

  const onInputKeyDown = (e: KeyboardEvent) => INPUT_FORBIDDEN_SYMBOLS.includes(e.key) && e.preventDefault();

  return (
    <div className={css.container}>
      { icon ? <Icon type={icon} /> : '' }
      <Slider
        size="small"
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={onSliderChange}
        className={css.slider}
      />
      { withInput ? (
        <Input
          customAdornment={<span className={css.inputAdornment}>{inputAdornment}</span>}
          min={min}
          max={max}
          step={step}
          type="number"
          pattern={INPUT_REGEX}
          reducedHeight
          reducedWidth
          inputClassName={css.input}
          value={inputValue}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onKeyDown={onInputKeyDown}
        />
      ) : '' }
    </div>
  );
};

export default SidebarSlider;
