import React, { ChangeEvent, useMemo } from 'react';
import { string } from 'yup';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import Input from '../../../../../../../library/Input';
import css from './NumberingStartFrom.module.css';

const VALUE_SCHEMA = string().min(0).max(20).nullable();

const NumberingPrefix: React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const value = useMemo(() => activeObject?.prefix || '', [activeObject?.prefix]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (activeObject && canvas.current && event.target) {
      try {
        activeObject.set({
          prefix: VALUE_SCHEMA.validateSync(event.target.value)
        });
        activeObject._changeValue();
        canvas.current.fire('object:modified', { target: activeObject });
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <Input
      inputClassName={css.input}
      onChange={handleChange}
      defaultValue={value}
    />
  );
};

export default NumberingPrefix;
