import React, { ChangeEventHandler, RefObject, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { FormikHelpers } from 'formik';

import cls from 'classnames';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Input from 'components/library/Input';

import { TPricingTableItem } from 'types/Portfolio';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import { TPrintItemForm } from 'types';
import css from './Pricing.module.css';
import { handlePriceFieldChange, preventEnterClick, twoCharactersAfterCommaOnBlur } from '../../helpers';

interface IPricingsTableColumns {
  firstFieldWithError: number,
  fieldWithErrorRef: RefObject<HTMLInputElement> | null,
  handleChange: ChangeEventHandler<HTMLInputElement>,
  handleBlur: (e: React.FocusEvent<any>)=> void,
  setFieldValue: FormikHelpers<TPrintItemForm>['setFieldValue']
}

const useColumns = ({
  handleChange, handleBlur, setFieldValue, firstFieldWithError, fieldWithErrorRef
}: IPricingsTableColumns): ColumnDef<TPricingTableItem>[] => useMemo<ColumnDef<TPricingTableItem>[]>(
  () => [
    {
      id: 'showNested',
      header: () => <span />,
      meta: {
        className: css.column
      },
      cell: ({ row }) => row.getCanExpand() && (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`
          }}
        >
          <div
            className={cls(css.expandContainer, css.row)}
            onClick={row.getToggleExpandedHandler()}
          >
            <Icon className={cls({ [css.expanded]: !row.getIsExpanded() })} type={ICON_TYPES.arrowUp} />
          </div>
        </div>
      ),
    },
    {
      id: 'size',
      header: strings.columnHeadSize,
      meta: {
        className: css.column,
      },
      cell: ({ row: { original: { size, units } } }) => {
        const { name, width, height } = size;
        return (<div className={cls(textCss.pMedium2, css.row)}>{name} ({String(width)} x {String(height)} {units})</div>);
      }
    },
    {
      id: 'paper',
      header: strings.columnHeadPaper,
      meta: {
        className: css.column,
      },
      cell: props => <div className={cls(textCss.pMedium2, css.row)}>{props.row.original.paper?.value}</div>
    },
    {
      id: 'Qty',
      header: strings.columnHeadQty,
      meta: {
        className: css.column,
      },
      cell: props => <div className={cls(textCss.pMedium2, css.row)}>{props.row.original.quantity?.value}</div>
    },
    {
      id: 'netPrice',
      header: strings.columnHeadNetPrice,
      meta: {
        className: css.column,
      },
      cell: ({ row: { original: { inputData } } }) => {
        const {
          inputName, inputError, inputValue, inputOrder, disabled
        } = inputData;
        const onBlur = (e :React.FocusEvent<HTMLInputElement>) => twoCharactersAfterCommaOnBlur({
          e, inputName, handleBlur, setFieldValue
        });
        const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => handlePriceFieldChange(e, handleChange);
        const obj: { ref?: RefObject<HTMLInputElement> | null } = {};
        if (firstFieldWithError === inputOrder) {
          obj.ref = fieldWithErrorRef;
        }
        return (
          <form noValidate>
            <Input
              {...obj}
              name={inputName}
              className={css.input}
              inputClassName={css.input}
              withError
              error={inputError}
              value={inputValue}
              disabled={disabled}
              onChange={changeHandler}
              onBlur={onBlur}
              onKeyDown={preventEnterClick}
              symbol={<span className={css.symbol}>€</span>}
            />
          </form>
        );
      },
    },
  ],
  [handleBlur, handleChange]
);

export default useColumns;
