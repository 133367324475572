import React, { CSSProperties, FC, useMemo } from 'react';
import ReactSelect, {
  components as rsComponents,
  ClearIndicatorProps,
  GroupBase,
  PlaceholderProps,
  Props,
  MultiValueRemoveProps,
  NoticeProps,
} from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import css from './Select.module.css';
import Icon, { ICON_TYPES } from '../Icon';

const Description:FC<{ text?: string }> = ({ text }) => <span className={cls(textCss.pMedium3, css.description)}>{text}</span>;

const customComponents = {
  IndicatorSeparator: () => null,
  ClearIndicator: (props:ClearIndicatorProps<any, boolean, any>) => {
    const {
      getStyles,
      innerProps,
    } = props;
    return (
      <div
        {...innerProps}
        className={cls(innerProps, css.closeIcon)}
        style={getStyles('clearIndicator', props) as CSSProperties}
      >
        <Icon type={ICON_TYPES.close} />
      </div>
    );
  },
  Placeholder: (props:PlaceholderProps<any, boolean, any>) => {
    const { children, className, ...rest } = props;
    return <rsComponents.Placeholder {...rest} className={cls(textCss.pLight2, css.placeholder, className)}>{children}</rsComponents.Placeholder>;
  },
  MultiValueRemove: (props:MultiValueRemoveProps<any, boolean, any>) => (
    <rsComponents.MultiValueRemove {...props}>
      <Icon type={ICON_TYPES.close} />
    </rsComponents.MultiValueRemove>
  ),
  NoOptionsMessage: (props:NoticeProps<any>) => (
    <rsComponents.NoOptionsMessage {...props}>
      <span className={cls(textCss.pMedium3, css.noOptions)}>{strings.inputNoOptions}</span>
    </rsComponents.NoOptionsMessage>
  )
};

// TODO: create HOC with error to be able to pass inputs, selects etc
const Error: React.FC<{ text?: string }> = ({ text }) => <span className={cls(textCss.pMedium2, css.error)}>{text}</span>;

interface ISelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
  > extends Props<Option, IsMulti, Group> {
  isCreatable?: boolean,
  withError?: boolean,
  error?: string,
  description?: string,
}
function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
  >(props: ISelect<Option, IsMulti, Group>) {
  const {
    menuPlacement = 'auto', isCreatable, components: passedComponents, className, description, withError, error, isSearchable, ...rest
  } = props;
  const formatCreateLabel = (inputValue: string) => strings.formatString(strings.inputSelectCreateOptionLabel, inputValue);
  const components = useMemo(() => {
    if (passedComponents) {
      return {
        ...customComponents,
        ...passedComponents
      };
    }
    return customComponents;
  }, [passedComponents]);
  const SelectComponent = isCreatable ? ReactSelectCreatable : ReactSelect;
  return (
    <>
      <SelectComponent<Option, IsMulti, Group>
        menuPlacement={menuPlacement}
        components={components}
        className={cls(css.select, className, {
          [css.inputError]: withError && error,
          [css.onlyChoosable]: !isCreatable && isSearchable === false
        })}
        classNamePrefix="react-select"
        isSearchable={isSearchable}
        formatCreateLabel={formatCreateLabel}
        {...rest}
      />
      {withError ? <Error text={error} /> : null}
      {description ? <Description text={description} /> : null}
    </>
  );
}

export default Select;
