import React from 'react';

import { IIconProps } from './types';

function IconCart(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 22C10.1046 22 11 21.1046 11 20C11 18.8954 10.1046 18 9 18C7.89543 18 7 18.8954 7 20C7
        21.1046 7.89543 22 9 22Z"
        stroke="#2C3E66"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22C18.1046 22 19 21.1046 19 20C19 18.8954 18.1046 18 17 18C15.8954 18 15 18.8954 15
        20C15 21.1046 15.8954 22 17 22Z"
        stroke="#2C3E66"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 3H4.63636L7.07273 13.7117C7.15586 14.08 7.38355 14.4109 7.71595 14.6464C8.04835 14.8818 8.46427
        15.0069 8.89091 14.9997H17.7273C18.1539 15.0069 18.5698 14.8818 18.9022 14.6464C19.2346 14.4109 19.4623
        14.08 19.5455 13.7117L21 6.9999H5.54545"
        stroke="#2C3E66"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconCart;
