/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconInfo(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0013" cy="9.99996" r="8.33333" stroke="#A6CBDF" strokeWidth="1.8" />
      <path d="M9.73013 7.14637C10.3195 7.14637 10.7275 7.32771 10.9541 7.69037C11.1921 8.04171 11.3111 8.51771 11.3111 9.11837C11.3111 9.39037 11.2828 9.75871 11.2261 10.2234C11.1695 10.688 11.1071 11.1754 11.0391 11.6854C10.9711 12.1954 10.9088 12.66 10.8521 13.0794C10.7955 13.4987 10.7671 13.8047 10.7671 13.9974C10.7671 14.1787 10.7955 14.2977 10.8521 14.3544C10.9088 14.411 10.9655 14.4394 11.0221 14.4394C11.1581 14.4394 11.2941 14.343 11.4301 14.1504C11.5661 13.9577 11.7078 13.6064 11.8551 13.0964L12.7731 13.3684C12.7278 13.6064 12.6371 13.8784 12.5011 14.1844C12.3765 14.4904 12.2065 14.785 11.9911 15.0684C11.7758 15.3404 11.5151 15.567 11.2091 15.7484C10.9145 15.9297 10.5745 16.0204 10.1891 16.0204C9.56579 16.0204 9.13513 15.8504 8.89713 15.5104C8.67046 15.1704 8.55713 14.7 8.55713 14.0994C8.55713 13.7707 8.58546 13.3797 8.64213 12.9264C8.69879 12.473 8.76113 12.0084 8.82913 11.5324C8.90846 11.0564 8.97646 10.6144 9.03313 10.2064C9.08979 9.78704 9.11813 9.44137 9.11813 9.16937C9.11813 8.97671 9.08979 8.85771 9.03313 8.81237C8.97646 8.75571 8.91413 8.72737 8.84613 8.72737C8.72146 8.72737 8.59113 8.82371 8.45513 9.01637C8.31913 9.20904 8.17746 9.56037 8.03013 10.0704L7.11213 9.79837C7.15746 9.56037 7.24813 9.28837 7.38413 8.98237C7.52013 8.67637 7.69579 8.38737 7.91113 8.11537C8.13779 7.83204 8.39846 7.59971 8.69313 7.41837C8.99913 7.23704 9.34479 7.14637 9.73013 7.14637ZM10.3251 2.86237C10.6991 2.86237 10.9995 2.99837 11.2261 3.27037C11.4641 3.54237 11.5718 3.87671 11.5491 4.27337C11.5378 4.65871 11.4075 4.98737 11.1581 5.25937C10.9088 5.52004 10.5971 5.65037 10.2231 5.65037C9.83779 5.65037 9.52613 5.52004 9.28813 5.25937C9.06146 4.98737 8.95379 4.65871 8.96513 4.27337C8.98779 3.87671 9.13513 3.54237 9.40713 3.27037C9.67913 2.99837 9.98513 2.86237 10.3251 2.86237Z" fill="#A6CBDF" />
    </svg>
  );
}

export default IconInfo;
