import React, { useEffect, useMemo, useState } from 'react';
import { useThrottleCallback } from '@react-hook/throttle';
import strings from 'constants/localization';
import Input from 'components/library/Input';
import { string } from 'yup';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import css from './CodeContent.module.css';
import {
  ECodeObjectTitle
} from '../../../../../../components/Editor/components/Viewport/fabric/codeObject';

const BARCODE_SCHEMA = string()
  .required()
  .matches(/^[0-9]+$/)
  .min(12)
  .max(12);

const QR_SCHEMA = string()
  .required()
  .min(1)
  .max(1000);

const CodeContent:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const [value, setValue] = useState<string>(activeObject.content);

  useEffect(() => handleChange(value), [value]);

  // update value, when activeObject changed
  useEffect(() => setValue(activeObject.content), [activeObject.content]);

  const handleChange = useThrottleCallback(async (newValue: string) => {
    if (!canvas.current || activeObject.content === newValue) {
      return;
    }

    await activeObject.changeContent(newValue);
    canvas.current.fire('object:modified', { target: activeObject });
  }, 4);

  const error = useMemo<string>(() => {
    switch (activeObject.title) {
      case ECodeObjectTitle.BARCODE:
        return !BARCODE_SCHEMA.isValidSync(activeObject.content) ? strings.editorTabSettingsCodeContentErrorBarcodeFormat : '';
      case ECodeObjectTitle.QR:
        return !QR_SCHEMA.isValidSync(activeObject.content) ? strings.editorTabSettingsCodeContentErrorQrFormat : '';
      default:
        return '';
    }
  }, [activeObject.title, activeObject.content]);

  return (
    <Input
      className={css.input}
      name="confirm"
      value={value}
      onChange={e => setValue(e.target.value)}
      label={strings.editorTabSettingsCodeContentInputLabel}
      type="text"
      error={error}
      withError
      placeholder={strings.editorTabSettingsCodeContentInputPlaceholder}
    />
  );
};

export default CodeContent;
