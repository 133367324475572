import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import { TUser } from 'types';
import strings from '../constants/localization';

export interface IUserContext {
  user: Partial<TUser> | null,
  setUser: React.Dispatch<React.SetStateAction<Partial<TUser> | null>>,
  token: string | null,
  setToken: React.Dispatch<React.SetStateAction<string | null>>
}
const initToken = localStorage.getItem('token');
const UserContext = createContext<IUserContext>({
  user: null,
  token: initToken,
  setUser: () => {},
  setToken: () => {},
});

function getUser():Partial<TUser> | null {
  const t = localStorage.getItem('token');
  if (t) {
    try {
      const decoded: { userId: string } = jwtDecode(t);
      return { id: decoded.userId };
    } catch (err) {
      return null;
    }
  }
  return null;
}
export const UserContextProvider:React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = useState<string | null>(initToken);
  const [user, setUser] = useState<Partial<TUser> | null>(getUser());

  const value = useMemo(() => ({
    token, user, setToken, setUser
  }), [token, user]);

  useEffect(() => {
    const savedLang = localStorage.getItem('lang');
    const currentDisplayLang = strings.getLanguage();
    if (savedLang && savedLang !== currentDisplayLang) {
      strings.setLanguage(savedLang);
    } else {
      localStorage.setItem('lang', currentDisplayLang);
    }
  }, []);

  useEffect(() => {
    const lang = user?.lang;
    if (lang && lang !== localStorage.getItem('lang')) {
      localStorage.setItem('lang', lang);
      strings.setLanguage(lang);
    }
  }, [user?.lang]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
