import { useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { TPrintItemsItem } from 'types';
import { IGetPrintItemsParams } from 'types/Portfolio/index';
import { getPrintItemsCommonQuery } from 'helpers';

const client = instance.getClient();

export const GET_PRINT_ITEM_QUERY = ['printItem', 'get'];

export const getPrintItem = (params: IGetPrintItemsParams): Promise<TPrintItemsItem> => {
  const query: string = getPrintItemsCommonQuery(params);
  return client.get(`/print-items/${params.printItemId}${query}`).then(res => res.data);
};

export const useGetPrintItem = (data: IGetPrintItemsParams) => useQuery<TPrintItemsItem, IResponseError>(
  [...GET_PRINT_ITEM_QUERY, data.printItemId],
  () => getPrintItem(data),
  {
    enabled: !!data.pspId && !!data.printItemId,
    retry: false,
  }
);

export const useInvalidateGetPrintItem = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...GET_PRINT_ITEM_QUERY, ...additionalParams]);
};

export default useGetPrintItem;
