/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconUserRegistered(props: IIconProps) {
  return (
    <svg {...props} width="463" height="297" viewBox="0 0 463 297" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M282.899 16.8306C330.759 30.7107 356.106 44.5924 392.689 70.3034C430.575 96.9299 448.855 134.579 457.801 160.153C467.868 188.927 460.329 228.685 433.552 245.102C397.371 267.283 358.256 271.116 307.137 280.585C259.147 289.475 217.432 294.928 163.689 296C108.403 297.103 43.8651 288.971 22.689 256C1.51279 223.029 16.1264 203.052 11.5025 169.053C7.48879 139.541 -7.48149 113.862 4.65463 70.3034C15.3038 32.0821 48.6045 13.348 90.2502 4.65767C140.212 -5.76798 233.921 2.62619 282.899 16.8306Z" fill="#EAF7FD" />
      <path d="M124.853 246.596L129.82 249.361C130.332 249.668 130.69 250.334 130.485 250.948C130.332 251.46 129.922 251.819 128.847 251.87C126.389 251.921 115.482 250.078 115.482 250.078V251.46H110.413C110.413 251.46 110.105 244.24 111.642 243.268L124.853 246.596Z" fill="#262722" />
      <path d="M118.145 242.09L124.801 246.545C124.801 246.545 123.931 249.003 120.142 247.364C116.353 245.726 111.59 243.268 111.59 243.268L112.307 241.373L118.145 242.09Z" fill="#E8AF94" />
      <path d="M112.307 241.425L111.59 220.636L118.247 221.097V244.241L112.307 241.425Z" fill="#E8AF94" />
      <path d="M68.1682 246.596L63.2013 249.361C62.6892 249.668 62.3308 250.334 62.5356 250.949C62.6892 251.461 63.0988 251.819 64.1742 251.87C66.632 251.921 77.5388 250.078 77.5388 250.078V251.563L82.5569 251.512C82.5569 251.512 82.9666 244.292 81.4304 243.37L68.1682 246.596Z" fill="#262722" />
      <path d="M74.8249 242.09L68.1682 246.545C68.1682 246.545 69.0387 249.003 72.8279 247.364C76.6171 245.726 81.3792 243.268 81.3792 243.268L80.6623 241.373L74.8249 242.09Z" fill="#E8AF94" />
      <path d="M80.7135 241.425L85.9365 220.636L79.331 221.097L74.2104 244.754L80.7135 241.425Z" fill="#E8AF94" />
      <path d="M114.612 126.725C114.612 126.725 123.624 139.68 123.419 153.812C123.163 167.945 120.244 235.075 120.244 235.075H109.491L106.111 147.36L104.678 147.258L85.2196 235.178L75.1833 234.103L86.8582 154.683C87.6775 149.562 89.0088 144.595 91.0058 139.833C92.6956 135.737 95.0511 131.128 97.9186 127.544L114.612 126.725Z" fill="#DE9FE7" />
      <path d="M101.452 148.078L108.313 146.849" stroke="black" strokeWidth="0.9147" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M95.9728 135.276L92.6444 144.801" stroke="#9B58A5" strokeWidth="0.9519" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.9546 72.2932C90.9546 72.2932 90.1865 79.8204 81.6352 85.9139C72.5206 92.3658 79.331 110.288 96.0752 109.468C114.253 108.547 121.832 85.197 109.952 67.4287L90.9546 72.2932Z" fill="#A74B22" />
      <path d="M103.705 94.5176L92.6956 97.5899C89.5209 98.4604 86.9606 101.84 87.9335 105.015L97.8674 127.494L114.663 126.726L120.551 102.403C121.063 99.0749 117.633 96.1049 114.304 95.6953L103.705 94.5176Z" fill="#7EB4D0" />
      <path d="M92.6444 97.6417L103.654 94.5693L114.253 95.7983L114.048 96.0031C108.006 100.97 99.4547 101.687 92.6444 97.6417Z" fill="#E0A78F" />
      <path d="M128.437 124.625L115.892 135.788L118.094 139.577L132.585 130.719C134.94 129.234 136.015 126.264 134.94 123.704C134.889 123.601 134.838 123.448 134.787 123.345L133.097 119.863L128.437 124.625Z" fill="#E0A78F" />
      <path d="M124.597 102.609L135.043 120.479C135.555 121.759 134.889 123.193 133.558 123.654L128.539 125.344C127.771 125.6 126.952 125.395 126.389 124.781C124.545 122.835 116.404 116.588 116.66 109.009L113.792 103.838L114.356 95.7471C119.681 96.3615 123.573 100.151 124.597 102.609Z" fill="#7EB4D0" />
      <path d="M117.735 114.334L120.091 106.653" stroke="black" strokeWidth="0.865" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M120.091 136.302C119.835 136.558 118.196 143.829 118.196 143.829L115.943 135.738L120.091 136.302Z" fill="#E0A78F" />
      <path d="M115.584 134.816L118.452 144.853" stroke="#D4688F" strokeWidth="0.9519" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M84.1443 104.911L75.7978 123.857C75.4394 125.189 76.2587 126.52 77.6412 126.827L81.584 127.698C82.3521 127.851 82.8642 129.746 83.325 129.08C84.9636 126.93 93.9246 117.815 92.798 110.339L95.1023 104.86L92.6956 97.5889C87.3702 98.869 84.8612 102.351 84.1443 104.911Z" fill="#7EB4D0" />
      <path d="M92.286 115.766L89.0601 108.443" stroke="black" strokeWidth="0.865" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.7886 103.53L60.897 105.118L76.6683 127.648C77.8972 130.157 81.1744 130.874 83.325 129.082C85.1172 127.597 85.4244 124.985 84.0419 123.091L64.7886 103.53Z" fill="#E0A78F" />
      <path d="M64.9934 104.657L65.8127 103.479C66.376 102.609 66.632 101.585 66.5296 100.509L66.1712 97.5907C66.1712 97.5907 66.0176 96.0545 63.0988 97.0786C60.2313 98.1027 60.0265 98.1539 60.2825 99.0244C60.2825 99.0244 61.1018 100.151 63.0476 99.9461L64.9934 104.657Z" fill="#E0A78F" />
      <path d="M61.3067 105.937C61.3067 105.937 58.0295 104.964 56.7494 99.8434C56.5958 99.2801 56.8006 98.6145 57.2102 98.2048C57.8759 97.5392 59.7705 95.7982 61.153 96.3614L63.7645 98.5633L64.9422 104.708L61.3067 105.937Z" fill="#E0A78F" />
      <path d="M64.0205 97.5383L63.8669 88.8846C63.8669 88.3725 63.4573 87.9629 62.9452 87.9629C62.4332 87.9629 62.0235 88.3725 62.0235 88.8846L61.9211 95.4389L61.2554 95.7461L57.4662 89.9087C57.2102 89.4991 56.647 89.3966 56.2373 89.6527C55.8789 89.9087 55.7765 90.3696 55.9813 90.728L60.2313 98.9721L64.0205 97.5383Z" fill="#E0A78F" />
      <path d="M64.0206 96.873C64.0206 96.873 59.9241 97.3851 60.2313 99.0749C60.2313 99.0749 60.4362 100.406 62.9964 99.9966C62.9964 99.9966 61.5115 100.765 61.8187 102.557" stroke="#B9635E" strokeWidth="0.6407" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M95.8663 82.9277C96.9172 81.6317 96.7185 79.7293 95.4225 78.6784C94.1266 77.6276 92.2242 77.8263 91.1733 79.1222C90.1225 80.4182 90.3212 82.3206 91.6171 83.3715C92.9131 84.4223 94.8155 84.2236 95.8663 82.9277Z" fill="#E0A78F" />
      <path d="M91.7227 80.7417C91.7227 80.7417 92.8492 80.076 94.1806 82.0218" stroke="#B9635E" strokeWidth="0.6612" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M107.545 79.8204C107.699 81.5102 109.133 82.7391 110.822 82.5855C112.512 82.4319 113.741 80.9981 113.587 79.3083C113.434 77.6186 112 76.3896 110.31 76.5432C108.621 76.6969 107.392 78.1818 107.545 79.8204Z" fill="#E0A78F" />
      <path d="M112.205 78.9493C112.205 78.9493 110.925 78.4885 110.054 80.6903" stroke="#B9635E" strokeWidth="0.6612" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M102.119 82.658C107.839 82.175 112.111 77.4429 111.658 72.0885C111.206 66.7341 106.202 62.785 100.482 63.268C94.7608 63.751 90.4897 68.4832 90.9418 73.8376C91.3938 79.192 96.3979 83.141 102.119 82.658Z" fill="#A74B22" />
      <path d="M105.446 86.0684C105.446 86.0684 104.217 87.3997 103.09 87.7581C101.913 88.1678 100.684 87.3485 100.684 87.3485L99.1987 86.4268L99.4547 96.4119L99.6084 96.5655C101.81 98.5625 104.729 98.3577 106.572 96.0534L106.828 95.695L105.446 86.0684Z" fill="#E0A78F" />
      <path d="M98.1234 85.1973C98.1234 85.1973 100.274 90.8811 103.295 90.5739C105.753 90.3179 105.958 84.8389 105.958 84.8389L98.1234 85.1973Z" fill="#1E0B02" />
      <path d="M109.696 73.9831C110.003 77.2602 109.747 80.1278 109.44 82.1248C109.133 84.1218 107.904 85.6067 106.111 86.8869L105.804 87.1429C103.91 88.4742 101.298 88.7303 99.1987 87.8086C97.0481 86.8357 95.4607 85.5043 94.8462 83.4561C93.7197 79.7181 93.2589 75.4681 93.2589 75.4681C93.2589 75.4681 91.8251 66.7119 100.735 66.0462C109.593 65.4318 109.696 73.9831 109.696 73.9831Z" fill="#E0A78F" />
      <path d="M101.093 79.2588L101.247 80.5389L102.117 80.4365" stroke="#B9635E" strokeWidth="0.6612" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M94.6926 75.1615L99.3523 74.3423C99.3523 74.3423 98.8403 73.1645 96.9457 73.523C94.9999 73.8814 94.6926 75.1615 94.6926 75.1615Z" fill="black" />
      <path d="M107.596 74.0868L102.885 74.0356C102.885 74.0356 103.193 72.8066 105.139 72.8066C107.084 72.8578 107.596 74.0868 107.596 74.0868Z" fill="black" />
      <path d="M98.5842 77.722C98.6354 78.1317 98.3282 78.4901 97.9186 78.4901C97.5089 78.5413 97.1505 78.2341 97.1505 77.8244C97.0993 77.4148 97.4065 77.0564 97.8162 77.0564C98.2258 77.0052 98.533 77.3124 98.5842 77.722Z" fill="#1E0B02" />
      <path d="M105.651 77.1081C105.702 77.5177 105.395 77.8761 104.985 77.8761C104.575 77.8761 104.217 77.6201 104.217 77.2105C104.166 76.8008 104.473 76.4424 104.883 76.4424C105.241 76.4424 105.599 76.7496 105.651 77.1081Z" fill="#1E0B02" />
      <path d="M99.1987 82.537C99.1987 82.537 101.298 83.4587 104.422 82.0762" stroke="black" strokeWidth="0.6612" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M109.542 71.9858C109.542 71.9858 96.4848 73.5731 96.3824 65.8411C96.3824 65.8411 107.494 61.6423 109.542 71.9858Z" fill="#A74B22" />
      <path d="M92.8492 73.3687C92.8492 73.3687 97.3041 71.0644 96.8945 66.3535C96.8945 66.3535 92.2348 67.3264 92.8492 73.3687Z" fill="#A74B22" />
      <path d="M149.636 230.469L148.663 244.141L142.775 244.346L142.365 227.499L149.636 230.469Z" fill="#E8B499" />
      <path d="M148.92 242.604C148.92 242.604 144.824 245.216 142.622 241.222L126.748 249.671L151.531 249.517L148.92 242.604Z" fill="#DA4412" />
      <path d="M151.526 249.497L126.743 249.648L126.756 251.799L151.539 251.647L151.526 249.497Z" fill="white" />
      <path d="M140.778 245.318C140.726 245.267 140.675 245.267 140.624 245.165C139.651 243.885 138.678 243.27 138.678 243.27C138.473 243.168 138.422 242.912 138.576 242.707C138.678 242.502 138.985 242.502 139.139 242.604C139.19 242.656 140.214 243.321 141.29 244.704C141.443 244.857 141.392 245.113 141.187 245.267C141.034 245.369 140.88 245.369 140.778 245.318Z" fill="white" />
      <path d="M138.729 246.343C138.678 246.291 138.627 246.291 138.576 246.189C137.603 244.909 136.63 244.294 136.63 244.294C136.425 244.192 136.374 243.936 136.527 243.731C136.63 243.526 136.937 243.526 137.091 243.629C137.142 243.68 138.166 244.346 139.241 245.728C139.395 245.882 139.344 246.138 139.139 246.291C139.037 246.394 138.883 246.394 138.729 246.343Z" fill="white" />
      <path d="M136.732 247.366C136.681 247.315 136.63 247.315 136.579 247.213C135.606 245.932 134.633 245.318 134.633 245.318C134.428 245.215 134.377 244.959 134.53 244.755C134.633 244.55 134.94 244.55 135.094 244.652C135.145 244.703 136.169 245.369 137.244 246.752C137.398 246.905 137.347 247.161 137.142 247.315C136.988 247.417 136.835 247.417 136.732 247.366Z" fill="white" />
      <path d="M165.868 230.469L166.892 244.141L172.781 244.346L173.139 227.499L165.868 230.469Z" fill="#E8B499" />
      <path d="M166.587 242.604C166.587 242.604 170.684 245.216 172.886 241.222L188.811 249.671L164.027 249.517L166.587 242.604Z" fill="#DA4412" />
      <path d="M164.006 249.491L163.992 251.642L188.776 251.793L188.789 249.643L164.006 249.491Z" fill="white" />
      <path d="M174.778 245.318C174.829 245.267 174.88 245.267 174.932 245.165C175.905 243.885 176.877 243.27 176.877 243.27C177.082 243.168 177.133 242.912 176.98 242.707C176.877 242.502 176.57 242.502 176.417 242.604C176.365 242.656 175.341 243.321 174.266 244.704C174.112 244.857 174.164 245.113 174.368 245.267C174.471 245.369 174.624 245.369 174.778 245.318Z" fill="white" />
      <path d="M176.775 246.343C176.826 246.291 176.877 246.291 176.929 246.189C177.902 244.909 178.874 244.294 178.874 244.294C179.079 244.192 179.13 243.936 178.977 243.731C178.874 243.526 178.567 243.526 178.414 243.629C178.362 243.68 177.338 244.346 176.263 245.728C176.109 245.882 176.161 246.138 176.365 246.291C176.519 246.394 176.673 246.394 176.775 246.343Z" fill="white" />
      <path d="M178.823 247.366C178.874 247.315 178.926 247.315 178.977 247.213C179.95 245.932 180.923 245.318 180.923 245.318C181.127 245.215 181.179 244.959 181.025 244.755C180.923 244.55 180.615 244.55 180.462 244.652C180.411 244.703 179.386 245.369 178.311 246.752C178.158 246.905 178.209 247.161 178.414 247.315C178.516 247.417 178.67 247.417 178.823 247.366Z" fill="white" />
      <path d="M173.139 141.472C173.139 141.472 175.034 153.351 174.983 194.777L176.314 237.636H163.359L161.362 195.186L158.648 157.294H153.528V141.472H173.139V141.472Z" fill="#262722" />
      <path d="M142.928 141.473C142.928 141.473 141.034 153.864 141.085 195.238L139.753 237.637H152.708L154.193 195.187L157.419 157.295H162.54V141.473H142.928V141.473Z" fill="#262722" />
      <path d="M152.094 157.346H162.028" stroke="#494949" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M140.88 134.916L135.247 160.98L131.561 160.775C131.561 160.775 132.738 144.645 134.121 133.841C134.326 132.151 136.527 130.717 138.217 131.076C139.856 131.486 141.238 133.329 140.88 134.916Z" fill="#E8B499" />
      <path d="M134.838 159.291C134.838 159.291 136.681 162.158 135.555 165.794L136.374 169.327C136.527 169.89 136.22 170.453 135.759 170.658C135.247 170.914 134.633 170.658 134.377 170.095L133.558 168.098L133.455 168.866C133.302 170.095 133.558 171.324 134.172 172.348L135.862 175.318C136.067 175.676 136.067 176.086 135.862 176.445C135.503 177.11 134.633 177.213 134.223 176.598L131.561 173.065C130.536 171.682 130.127 169.89 130.383 168.098L131.97 158.625L134.838 159.291Z" fill="#E8B499" />
      <path d="M134.121 133.892L137.449 111.566L145.079 114.075L140.829 135.223L134.121 133.892Z" fill="#E8B499" />
      <path d="M145.693 102.352C145.693 102.352 140.522 102.966 137.859 109.828C135.196 116.689 131.919 123.858 131.919 123.858L143.491 130.31L145.693 102.352Z" fill="#5AC9BB" />
      <path d="M179.796 138.654L205.04 124.931L203.504 119.555C203.504 119.555 185.89 127.031 176.365 132.254C174.829 133.073 174.368 136.145 175.29 137.579C176.161 138.962 178.414 139.525 179.796 138.654Z" fill="#E8B499" />
      <path d="M181.896 133.994L176.621 111.566L168.992 114.075L174.983 137.016L181.896 133.994Z" fill="#E8B499" />
      <path d="M169.401 102.146C169.401 102.146 174.983 102.812 177.85 109.776C180.615 116.586 183.381 122.936 183.381 122.936L172.218 129.849L169.401 102.146Z" fill="#5AC9BB" />
      <path d="M171.962 103.221C169.965 101.992 166.739 101.685 166.739 101.685L157.778 102.606L148.817 101.685C148.817 101.685 145.591 101.992 143.594 103.221L143.543 123.857L139.549 152.173L176.212 152.378L172.013 124.215L171.962 103.221Z" fill="#5AC9BB" />
      <path d="M145.693 102.35L156.242 99.585H159.877L169.401 102.196C164.537 106.754 152.401 110.492 146.205 102.606L145.693 102.35Z" fill="#E8B499" />
      <path d="M142.826 129.847L143.491 123.241L142.723 113.308" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M172.73 129.335L172.167 123.241L173.139 113.205" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M173.498 92.8261C173.395 91.9556 173.447 91.0851 173.651 90.2658C174.727 86.1182 173.498 82.585 171.296 79.9223C169.299 77.5157 168.838 73.9825 168.838 73.9825C167.404 69.1692 162.437 65.8408 157.983 65.8408C153.477 65.8408 148.561 68.9644 147.127 73.7777C147.127 73.7777 146.461 77.5669 144.618 79.9223C142.519 82.6362 141.187 86.1182 142.262 90.2658C142.467 91.0851 142.519 91.9556 142.416 92.8261C142.365 93.4406 142.16 94.1063 141.802 94.5671H174.01C173.754 94.1063 173.6 93.4918 173.498 92.8261Z" fill="#DA4412" />
      <path d="M152.401 83.7124C152.452 85.4534 151.07 86.8871 149.329 86.9383C147.588 86.9895 146.154 85.607 146.103 83.866C146.052 82.125 147.434 80.6913 149.175 80.64C150.916 80.5888 152.35 81.9714 152.401 83.7124Z" fill="#E8B499" />
      <path d="M147.485 83.3533C147.485 83.3533 148.714 82.7388 149.943 84.9406" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M167.097 86.9891C168.851 86.9891 170.272 85.5677 170.272 83.8144C170.272 82.061 168.851 80.6396 167.097 80.6396C165.344 80.6396 163.922 82.061 163.922 83.8144C163.922 85.5677 165.344 86.9891 167.097 86.9891Z" fill="#E8B499" />
      <path d="M168.838 83.3018C168.838 83.3018 167.558 82.7386 166.431 84.9404" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M167.763 80.1057C169.035 74.7686 165.738 69.4115 160.401 68.1404C155.064 66.8692 149.707 70.1653 148.436 75.5023C147.165 80.8394 150.461 86.1965 155.798 87.4677C161.135 88.7389 166.492 85.4428 167.763 80.1057Z" fill="#DA4412" />
      <path d="M161.209 90.113C161.209 90.113 159.826 91.342 158.597 91.6492C157.368 91.9564 156.139 91.0347 156.139 90.9835L154.705 89.9082L154.142 100.303L154.296 100.457C156.395 102.71 159.468 102.761 161.567 100.508L161.874 100.201L161.209 90.113Z" fill="#E8B499" />
      <path d="M153.681 88.5771C153.681 88.5771 155.422 94.6194 158.546 94.5682C161.106 94.517 161.823 88.8844 161.823 88.8844L153.681 88.5771Z" fill="#1E0B02" />
      <path d="M166.688 77.9261C166.688 81.3569 166.227 84.2756 165.715 86.375C165.203 88.4232 163.82 89.857 161.874 91.0347L161.516 91.2395C159.416 92.4685 156.702 92.5197 154.603 91.3419C152.452 90.1642 150.967 88.628 150.455 86.4262C149.585 82.4322 149.482 78.0285 149.482 78.0285C149.482 78.0285 148.766 68.8115 158.034 68.9139C167.302 69.0163 166.688 77.9261 166.688 77.9261Z" fill="#E8B499" />
      <path d="M157.317 82.9941V84.3255H158.239" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M151.019 77.823L155.934 77.3622C155.934 77.3622 155.474 76.082 153.528 76.2868C151.479 76.5429 151.019 77.823 151.019 77.823Z" fill="black" />
      <path d="M164.537 77.822L159.621 77.3612C159.621 77.3612 160.082 76.081 162.028 76.2859C164.076 76.5419 164.537 77.822 164.537 77.822Z" fill="black" />
      <path d="M155.115 85.5537H160.492C160.492 85.5537 160.287 86.9363 157.727 86.9363C155.576 86.9875 155.115 85.5537 155.115 85.5537Z" fill="white" />
      <path d="M155.371 81.1505C155.371 81.1505 154.091 80.1264 152.964 81.1505" stroke="#1E0B02" strokeWidth="0.9565" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M162.54 81.1508C162.54 81.1508 161.567 80.0755 160.236 81.1508" stroke="#1E0B02" strokeWidth="0.9565" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path opacity="0.23" d="M152.964 77.7707L156.549 82.6353C156.549 82.6353 155.474 84.7347 153.733 84.4787L150.097 79.7165C150.097 79.7165 151.121 77.6171 152.964 77.7707Z" fill="white" />
      <path opacity="0.23" d="M161.823 77.7707L165.407 82.6353C165.407 82.6353 164.332 84.7347 162.591 84.4787L158.956 79.7165C158.956 79.7165 159.928 77.6171 161.823 77.7707Z" fill="white" />
      <path d="M156.856 81.1523C156.856 83.0981 155.269 84.6855 153.323 84.6855C151.377 84.6855 149.79 83.0981 149.79 81.1523C149.79 79.2065 151.377 77.6191 153.323 77.6191C155.269 77.6191 156.856 79.2065 156.856 81.1523Z" stroke="white" strokeWidth="0.4927" strokeMiterlimit="10" />
      <path d="M165.715 81.1504C165.715 83.0962 164.127 84.6835 162.181 84.6835C160.236 84.6835 158.648 83.0962 158.648 81.1504C158.648 79.2046 160.236 77.6172 162.181 77.6172C164.127 77.6172 165.715 79.2046 165.715 81.1504Z" stroke="white" strokeWidth="0.4927" strokeMiterlimit="10" />
      <path d="M156.856 81.1505C156.856 81.1505 157.88 80.7921 158.699 81.1505" stroke="white" strokeWidth="0.4927" strokeMiterlimit="10" />
      <path d="M148.561 75.8264C148.561 75.8264 156.856 74.239 158.546 68.0432C158.546 68.0432 149.738 64.9196 148.561 75.8264Z" fill="#DA4412" />
      <path d="M166.688 75.8264C166.688 75.8264 158.392 74.239 156.702 68.0432C156.702 68.0432 165.51 64.9196 166.688 75.8264Z" fill="#DA4412" />
      <path d="M272.58 239.681H263.363L248.104 247.822L274.321 247.515L272.58 239.681Z" fill="#A74B22" />
      <path d="M267.87 249.412C268.74 249.412 269.457 250.129 269.457 251.05L274.424 250.999L274.321 247.568L248.104 247.876L248.207 251.306L266.333 251.102C266.282 250.129 266.999 249.412 267.87 249.412Z" fill="#1E0B02" />
      <path d="M256.963 242.651L259.984 243.471" stroke="#5AC9BB" strokeWidth="1.9384" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M259.933 241.167L262.954 241.986" stroke="#5AC9BB" strokeWidth="1.9384" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M272.58 226.675H263.159V239.681H272.58V226.675Z" fill="#E6A9A0" />
      <path d="M289.171 239.683H298.439L313.698 247.824L287.43 247.517L289.171 239.683Z" fill="#A74B22" />
      <path d="M293.882 249.412C293.011 249.412 292.295 250.129 292.295 251.05L287.328 250.999L287.43 247.568L313.647 247.876L313.545 251.306L295.418 251.102C295.469 250.129 294.752 249.412 293.882 249.412Z" fill="#1E0B02" />
      <path d="M304.789 242.652L302.228 243.984" stroke="#5AC9BB" strokeWidth="1.9384" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M301.819 241.169L299.771 241.988" stroke="#5AC9BB" strokeWidth="1.9384" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M297.569 226.675H288.198L289.171 239.681H298.593L297.569 226.675Z" fill="#E6A9A0" />
      <path d="M294.292 128.515C295.008 136.042 300.641 235.944 300.641 235.944H285.945L279.493 147.256L276.831 147.307L275.141 235.944H261.11C261.11 235.944 260.291 143.262 261.162 128.771L294.292 128.515Z" fill="#5AC9BB" />
      <path d="M273.144 147.256H283.897" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M272.017 46.7923C272.017 46.7923 276.062 41.3133 283.282 44.9489C291.168 48.9941 286.918 57.6478 286.918 57.6478L269.918 54.4219L272.017 46.7923Z" fill="#A74B22" />
      <path d="M268.638 57.6988C268.638 57.6988 264.183 48.9938 272.017 46.792L273.451 51.4517L268.638 57.6988Z" fill="#A74B22" />
      <path d="M272.376 61.7446C272.427 63.4856 271.044 64.9193 269.303 64.9706C267.562 65.0218 266.129 63.6392 266.077 61.8982C266.026 60.1572 267.409 58.7235 269.15 58.6723C270.891 58.6211 272.324 60.0036 272.376 61.7446Z" fill="#E8B499" />
      <path d="M267.46 61.3865C267.46 61.3865 268.689 60.772 269.918 62.9738" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M283.897 61.8456C283.897 63.5866 285.331 65.0204 287.072 65.0204C288.813 65.0204 290.246 63.5866 290.246 61.8456C290.246 60.1047 288.813 58.6709 287.072 58.6709C285.331 58.6709 283.897 60.1047 283.897 61.8456Z" fill="#E8B499" />
      <path d="M288.813 61.3331C288.813 61.3331 287.532 60.7698 286.406 62.9717" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M282.207 69.1175C282.207 69.1175 280.415 70.3976 278.828 70.7049C277.189 71.0121 275.653 70.0904 275.602 70.0392L273.707 68.9639L272.939 79.3586L273.144 79.5122C275.909 81.7652 279.852 81.8164 282.617 79.5634L283.026 79.2562L282.207 69.1175Z" fill="#E8B499" />
      <path d="M272.888 67.8369C272.888 67.8369 274.68 73.6231 278.725 73.5719C282.053 73.5207 282.617 67.8369 282.617 67.8369H272.888Z" fill="#1E0B02" />
      <path d="M286.662 56.3684C286.662 59.9527 286.201 63.0251 285.689 65.1757C285.177 67.3263 283.334 68.8113 281.337 70.0402L280.978 70.245C278.879 71.5252 277.138 71.5764 275.038 70.3475C272.888 69.1185 270.891 67.4799 270.43 65.2269C269.559 61.0793 269.457 56.4196 269.457 56.4196C269.457 56.4196 268.228 46.7929 277.547 46.8953C286.816 47.049 286.662 56.3684 286.662 56.3684Z" fill="#E8B499" />
      <path d="M274.782 59.1851C274.782 59.5947 274.424 59.9532 274.014 59.9532C273.605 59.9532 273.246 59.5947 273.246 59.1851C273.246 58.7754 273.605 58.417 274.014 58.417C274.475 58.417 274.782 58.7754 274.782 59.1851Z" fill="#1E0B02" />
      <path d="M282.156 59.1851C282.156 59.5947 281.797 59.9532 281.388 59.9532C280.978 59.9532 280.62 59.5947 280.62 59.1851C280.62 58.7754 280.978 58.417 281.388 58.417C281.849 58.417 282.156 58.7754 282.156 59.1851Z" fill="#1E0B02" />
      <path d="M277.035 61.1309V62.3598H278.008" stroke="#C47268" strokeWidth="0.6887" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M271.249 56.0095L276.165 55.5487C276.165 55.5487 275.704 54.2685 273.758 54.4734C271.71 54.6782 271.249 56.0095 271.249 56.0095Z" fill="black" />
      <path d="M283.897 56.0095L278.981 55.5487C278.981 55.5487 279.442 54.2685 281.388 54.4734C283.436 54.6782 283.897 56.0095 283.897 56.0095Z" fill="black" />
      <path d="M275.346 64.0488H280.21C280.21 64.0488 280.005 65.5338 277.701 65.5338C275.448 65.5338 275.346 64.0488 275.346 64.0488Z" fill="white" />
      <path d="M269.764 60.9755C269.764 60.9755 270.123 49.1471 269.969 48.8398C269.969 48.8398 265.77 52.7315 269.764 60.9755Z" fill="#A74B22" />
      <path d="M286.304 60.9239L285.689 48.0713C285.689 48.0713 289.734 52.0653 286.304 60.9239Z" fill="#A74B22" />
      <path d="M273.656 47.8164C273.656 47.8164 270.788 48.5845 270.891 51.452C270.993 54.2683 269.098 57.0846 269.098 57.0846L268.228 54.2683C268.177 54.2171 267.511 48.9429 273.656 47.8164Z" fill="#A74B22" />
      <path d="M272.888 48.021C272.888 48.021 275.038 46.7921 277.189 49.506C279.032 51.8102 283.846 54.1657 286.713 51.9126C286.713 51.9126 284.87 45.4095 277.803 45.5119C270.686 45.6656 272.888 48.021 272.888 48.021Z" fill="#A74B22" />
      <path d="M262.851 83.251C248.565 90.4197 236.737 103.733 236.737 103.733L244.417 113.001L266.026 99.2783L262.851 83.251Z" fill="#EEC86A" />
      <path d="M301.768 138.5L306.069 149.458L309.397 149.1L306.888 134.916L301.768 138.5Z" fill="#E8B499" />
      <path d="M305.915 147.717C305.915 147.717 304.225 150.789 305.608 154.476L304.994 158.214C304.891 158.777 305.198 159.391 305.71 159.596C306.274 159.801 306.888 159.494 307.093 158.931L307.81 156.78L307.963 157.548C308.168 158.828 308.015 160.108 307.451 161.184L305.915 164.358C305.762 164.717 305.762 165.178 305.966 165.485C306.376 166.151 307.298 166.202 307.707 165.536L310.217 161.696C311.189 160.211 311.497 158.316 311.087 156.524L308.834 146.795L305.915 147.717Z" fill="#E8B499" />
      <path d="M291.834 82.8398C291.834 82.8398 296.852 84.12 299.566 91.6984C304.942 106.855 310.524 147.461 310.524 147.461L300.897 149.253L287.379 97.2286L291.834 82.8398Z" fill="#EEC86A" />
      <path d="M288.71 81.458L293.011 83.3526C294.752 84.1207 295.879 85.8617 295.879 87.7563L296.033 134.609H259.83V87.7563C259.83 85.8105 261.008 84.0183 262.8 83.3014L267.204 81.458H288.71Z" fill="#EEC86A" />
      <path d="M278.264 76.9014L288.71 81.4587C288.71 81.4587 277.957 91.1877 267.255 81.4587L278.264 76.9014Z" fill="#E8B499" />
      <path d="M267.255 81.4072C267.255 81.4072 270.993 85.8109 277.957 85.8109C284.921 85.8109 288.71 81.4072 288.71 81.4072" stroke="white" strokeWidth="1.9384" strokeMiterlimit="10" />
      <path d="M296.033 129.284L295.93 98.1514" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M259.882 103.221V97.998" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M220.914 60.107C220.914 60.107 225.574 58.0588 227.212 61.9504C228.8 65.7396 222.502 69.324 222.502 69.324L220.914 60.107Z" fill="#1E0B02" />
      <path d="M212.209 75.7739C212.26 77.5149 210.878 78.9486 209.137 78.9998C207.396 79.0511 205.962 77.6685 205.911 75.9275C205.86 74.1865 207.242 72.7528 208.983 72.7016C210.724 72.6504 212.158 74.0329 212.209 75.7739Z" fill="#C67D69" />
      <path d="M207.242 75.4167C207.242 75.4167 208.471 74.8023 209.7 77.0041" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M226.905 79.0018C228.659 79.0018 230.08 77.5804 230.08 75.8271C230.08 74.0737 228.659 72.6523 226.905 72.6523C225.152 72.6523 223.73 74.0737 223.73 75.8271C223.73 77.5804 225.152 79.0018 226.905 79.0018Z" fill="#C67D69" />
      <path d="M228.646 75.3673C228.646 75.3673 227.366 74.804 226.24 77.0058" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M221.989 83.098C221.989 83.098 220.197 84.3781 218.61 84.6853C216.971 84.9926 215.435 84.0709 215.384 84.0197C215.333 83.9684 213.489 82.9443 213.489 82.9443L212.721 93.339L212.926 93.4927C215.691 95.7457 219.634 95.7969 222.399 93.5439L222.809 93.2366L221.989 83.098Z" fill="#C67D69" />
      <path d="M212.721 81.8682C212.721 81.8682 214.513 87.6544 218.559 87.6032C221.887 87.552 222.45 81.8682 222.45 81.8682H212.721Z" fill="#1E0B02" />
      <path d="M226.496 70.3478C226.496 73.9322 226.035 77.0046 225.523 79.1552C225.011 81.3058 223.628 82.7908 221.682 84.0197L221.324 84.2245C219.224 85.5047 216.51 85.5559 214.411 84.3269C212.26 83.098 210.724 81.4594 210.263 79.2064C209.393 75.0588 209.29 70.3991 209.29 70.3991C209.29 70.3991 208.062 60.7724 217.381 60.8748C226.649 61.0285 226.496 70.3478 226.496 70.3478Z" fill="#C67D69" />
      <path d="M213.848 73.9326C214.272 73.9326 214.616 73.5888 214.616 73.1646C214.616 72.7404 214.272 72.3965 213.848 72.3965C213.424 72.3965 213.08 72.7404 213.08 73.1646C213.08 73.5888 213.424 73.9326 213.848 73.9326Z" fill="#1E0B02" />
      <path d="M221.221 73.9326C221.646 73.9326 221.989 73.5888 221.989 73.1646C221.989 72.7404 221.646 72.3965 221.221 72.3965C220.797 72.3965 220.453 72.7404 220.453 73.1646C220.453 73.5888 220.797 73.9326 221.221 73.9326Z" fill="#1E0B02" />
      <path d="M216.869 74.29V76.3383H218.2" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M211.083 69.989L215.998 69.5282C215.998 69.5282 215.538 68.248 213.592 68.4529C211.544 68.7089 211.083 69.989 211.083 69.989Z" fill="black" />
      <path d="M223.73 69.989L218.815 69.5282C218.815 69.5282 219.276 68.248 221.221 68.4529C223.27 68.7089 223.73 69.989 223.73 69.989Z" fill="black" />
      <path d="M215.179 78.0801H220.044C220.044 78.0801 219.839 79.565 217.535 79.565C215.282 79.565 215.179 78.0801 215.179 78.0801Z" fill="white" />
      <path d="M209.598 75.0078C209.598 75.0078 210.98 64.5619 210.315 62.8721C210.263 62.8721 205.604 66.7125 209.598 75.0078Z" fill="#1E0B02" />
      <path d="M226.137 74.9053C226.137 74.9053 224.601 64.9202 225.011 62.0527C225.011 62.1039 229.517 66.098 226.137 74.9053Z" fill="#1E0B02" />
      <path d="M224.703 61.3865L220.402 60.7208L220.044 60.0039L217.791 60.3623L215.538 60.0039L215.179 60.7208L210.878 61.3865L210.315 63.6907L210.468 66.3534C210.468 66.3534 211.134 63.3323 213.489 63.9467C214.053 64.1003 214.718 64.3052 215.486 64.4588C216.203 64.6124 216.92 64.7148 217.586 64.7148C217.688 64.7148 217.739 64.7148 217.842 64.7148C217.944 64.7148 217.995 64.7148 218.098 64.7148C218.764 64.7148 219.429 64.6124 220.197 64.4588C220.965 64.3052 221.682 64.1003 222.194 63.9467C224.396 63.1786 225.164 66.1998 225.164 66.1998L225.369 63.6907L224.703 61.3865Z" fill="#1E0B02" />
      <path d="M224.601 63.9979C224.601 63.9979 221.836 66.507 218.251 64.5612C218.251 64.5612 209.188 70.1426 206.628 64.0491C204.477 58.9798 213.387 52.067 222.502 59.8503L224.601 63.9979Z" fill="#1E0B02" />
      <path d="M228.8 223.347L230.285 241.628L238.324 241.884L238.375 220.224L228.8 223.347Z" fill="#C67D69" />
      <path d="M230.131 239.681C230.131 239.681 235.712 242.958 238.528 237.838L259.01 248.693L227.109 248.489L230.131 239.681Z" fill="#101010" />
      <path d="M227.07 248.525L227.053 251.29L258.954 251.485L258.971 248.72L227.07 248.525Z" fill="white" />
      <path d="M240.935 243.163C241.038 243.112 241.089 243.061 241.14 243.009C242.369 241.371 243.649 240.603 243.649 240.552C243.905 240.398 243.957 240.091 243.803 239.835C243.649 239.579 243.291 239.528 243.086 239.681C243.035 239.732 241.652 240.603 240.321 242.344C240.167 242.549 240.219 242.907 240.423 243.061C240.577 243.214 240.782 243.214 240.935 243.163Z" fill="white" />
      <path d="M243.547 244.494C243.649 244.443 243.701 244.392 243.752 244.341C244.981 242.702 246.261 241.934 246.261 241.883C246.517 241.729 246.568 241.422 246.414 241.166C246.261 240.91 245.902 240.859 245.698 241.012C245.646 241.063 244.264 241.934 242.932 243.675C242.779 243.88 242.83 244.238 243.035 244.392C243.189 244.545 243.393 244.545 243.547 244.494Z" fill="white" />
      <path d="M246.158 245.774C246.261 245.723 246.312 245.672 246.363 245.621C247.592 243.982 248.872 243.214 248.872 243.163C249.128 243.009 249.18 242.702 249.026 242.446C248.872 242.19 248.514 242.139 248.309 242.292C248.258 242.344 246.875 243.214 245.544 244.955C245.39 245.16 245.442 245.518 245.646 245.672C245.8 245.826 245.954 245.826 246.158 245.774Z" fill="white" />
      <path d="M210.315 223.347L210.827 241.628L202.736 241.884L200.739 220.224L210.315 223.347Z" fill="#C67D69" />
      <path d="M210.827 239.681C210.827 239.681 205.245 242.958 202.429 237.838L181.947 248.693L213.848 248.489L210.827 239.681Z" fill="#101010" />
      <path d="M251.989 244.76C249.989 244.4 246.489 245.759 246.489 248.76H258.989C258.989 248.76 253.989 245.119 251.989 244.76Z" fill="white" />
      <path d="M188.689 244.76C190.689 244.4 194.189 245.759 194.189 248.76H181.689C181.689 248.76 186.689 245.119 188.689 244.76Z" fill="white" />
      <path d="M213.842 248.548L181.696 248.745L181.713 251.51L213.859 251.314L213.842 248.548Z" fill="white" />
      <path d="M200.022 243.163C199.92 243.112 199.869 243.061 199.817 243.009C198.589 241.371 197.308 240.603 197.308 240.552C197.052 240.398 197.001 240.091 197.155 239.835C197.308 239.579 197.667 239.528 197.872 239.681C197.923 239.732 199.305 240.603 200.637 242.344C200.79 242.549 200.739 242.907 200.534 243.061C200.381 243.214 200.176 243.214 200.022 243.163Z" fill="white" />
      <path d="M197.411 244.494C197.308 244.443 197.257 244.392 197.206 244.341C195.977 242.702 194.697 241.934 194.697 241.883C194.441 241.729 194.39 241.422 194.543 241.166C194.697 240.91 195.055 240.859 195.26 241.012C195.311 241.063 196.694 241.934 198.025 243.675C198.179 243.88 198.128 244.238 197.923 244.392C197.769 244.545 197.564 244.545 197.411 244.494Z" fill="white" />
      <path d="M194.799 245.775C194.697 245.724 194.646 245.673 194.595 245.622C193.366 243.983 192.085 243.215 192.085 243.164C191.829 243.01 191.778 242.703 191.932 242.447C192.085 242.191 192.444 242.14 192.649 242.293C192.7 242.345 194.082 243.215 195.414 244.956C195.567 245.161 195.516 245.519 195.311 245.673C195.158 245.827 194.953 245.827 194.799 245.775Z" fill="white" />
      <path d="M219.173 152.939L226.342 232.308H240.577L235.2 137.475L217.535 135.888L202.89 135.171L197.001 185.403C196.54 190.012 200.176 193.955 204.784 193.852C208.42 193.801 211.492 191.19 212.158 187.605L219.173 152.939Z" fill="#005683" />
      <path d="M212.772 152.172L221.989 153.35" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M243.445 233.69H224.652L223.782 225.139H242.523L243.445 233.69Z" fill="#7EB4D0" />
      <path d="M212.158 187.605L213.028 231.079H197.974L197.001 185.403L212.158 187.605Z" fill="#005683" />
      <path d="M214.872 225.139H196.079V233.639H214.872V225.139Z" fill="#7EB4D0" />
      <path d="M233.511 97.4342L222.86 93.2354L212.772 93.3378L199.459 99.5848C198.435 100.199 198.589 101.479 198.998 102.606L202.019 138.194L235.456 137.733L237.658 101.531C237.812 100.404 234.586 97.9975 233.511 97.4342Z" fill="#DE9FE7" />
      <path d="M212.721 93.3368L209.751 94.7193C209.751 94.7193 209.7 99.6863 217.791 99.4302C226.086 99.1742 226.086 94.4633 226.086 94.4633L222.86 93.2344L212.721 93.3368Z" fill="#C67D69" />
      <path d="M251.074 119.709L228.595 108.956L239.758 102.351L249.692 108.495L251.074 119.709Z" fill="#C67D69" />
      <path d="M246.875 84.7368L251.228 83.1494L258.909 113.463C259.984 116.689 257.782 120.12 254.351 120.427C251.535 120.683 248.975 118.686 248.565 115.87L246.875 84.7368Z" fill="#C67D69" />
      <path d="M246.875 84.7361L244.827 82.4318C243.752 81.2541 242.984 79.8204 242.523 78.233L241.755 75.5191C241.601 74.8534 242.011 74.239 242.676 74.1365C243.24 74.0853 243.803 74.3926 244.059 74.8534L245.442 77.5161L245.851 65.7388C245.851 65.2268 246.312 64.8683 246.824 64.9196L247.183 64.9708C247.592 65.022 247.899 65.3804 247.899 65.7388V72.7028L249.026 64.6635C249.077 64.1515 249.538 63.793 250.05 63.8954L250.306 63.9467C250.716 64.0491 251.023 64.4075 250.972 64.8171L250.05 73.2148L251.996 66.6605C252.149 66.1485 252.713 65.8925 253.225 66.1485L253.327 66.1997C253.634 66.3533 253.839 66.7117 253.788 67.1214L252.457 74.1878L253.788 70.0401C253.993 69.4256 254.71 69.272 255.171 69.6817C255.375 69.8865 255.478 70.1937 255.427 70.501L251.945 86.1186L246.875 84.7361Z" fill="#C67D69" />
      <path d="M245.493 77.5684C245.493 77.5684 247.746 78.0804 248.975 80.7431" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M248.104 107.983L248.565 113.77" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M232.026 96.9219C240.372 99.4309 246.107 106.241 246.107 106.241L239.86 118.684L225.471 112.027L232.026 96.9219Z" fill="#DE9FE7" />
      <path d="M236.583 117.199L232.538 115.305" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M192.854 111.106L187.784 129.336C187.221 132.254 190.652 135.531 193.417 136.248C196.182 136.965 195.567 134.815 196.284 131.998L203.197 114.742L192.854 111.106Z" fill="#C67D69" />
      <path d="M188.296 131.487C189.218 135.225 200.944 143.418 200.944 143.418L207.703 143.469L195.465 126.571L188.296 131.487Z" fill="#C67D69" />
      <path d="M209.803 145.261C209.803 145.261 211.544 146.131 213.233 145.773C214.155 145.568 214.309 144.339 213.489 143.878C211.288 142.752 206.628 140.089 204.836 139.423L201.661 138.553L198.64 141.523L200.995 143.417C200.995 143.417 201.558 146.387 202.736 147.411C202.685 147.411 207.652 148.179 209.803 145.261Z" fill="#C67D69" />
      <path d="M197.411 129.285L195.465 126.571" stroke="#A8523F" strokeWidth="0.7482" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M199.408 99.585C199.408 99.585 191.01 102.401 189.269 117.302L205.911 122.269L210.519 107.829L199.408 99.585Z" fill="#DE9FE7" />
      <path d="M200.637 120.989L199.92 112.693" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M205.86 96.5641C205.86 96.5641 204.17 97.4858 205.501 100.097C206.269 101.582 205.757 103.426 204.375 104.347L201.456 106.242C199.817 107.317 197.616 106.703 196.745 104.962C196.182 103.784 195.823 102.453 196.233 101.275C197.257 98.5099 203.453 95.3351 205.86 96.5641Z" fill="#E8B499" />
      <path d="M343.244 231.128V243.827L349.133 244.032L349.491 227.186L343.244 231.128Z" fill="#CF7C6C" />
      <path d="M342.937 242.291C342.937 242.291 347.033 244.902 349.235 240.908L365.16 249.357L340.377 249.203L342.937 242.291Z" fill="#101010" />
      <path d="M340.366 249.161L340.353 251.312L365.136 251.463L365.15 249.313L340.366 249.161Z" fill="white" />
      <path d="M351.13 245.004C351.181 244.953 351.232 244.953 351.283 244.85C352.256 243.57 353.229 242.956 353.229 242.956C353.434 242.853 353.485 242.597 353.332 242.392C353.229 242.188 352.922 242.188 352.768 242.29C352.717 242.341 351.693 243.007 350.618 244.389C350.464 244.543 350.515 244.799 350.72 244.953C350.822 245.055 350.976 245.055 351.13 245.004Z" fill="white" />
      <path d="M353.127 246.029C353.178 245.978 353.229 245.978 353.28 245.876C354.253 244.595 355.226 243.981 355.226 243.981C355.431 243.879 355.482 243.623 355.329 243.418C355.226 243.213 354.919 243.213 354.765 243.315C354.714 243.367 353.69 244.032 352.615 245.415C352.461 245.568 352.512 245.824 352.717 245.978C352.871 246.08 353.024 246.08 353.127 246.029Z" fill="white" />
      <path d="M355.175 247.053C355.226 247.001 355.277 247.001 355.329 246.899C356.301 245.619 357.274 245.004 357.274 245.004C357.479 244.902 357.53 244.646 357.377 244.441C357.274 244.236 356.967 244.236 356.814 244.339C356.762 244.39 355.738 245.056 354.663 246.438C354.509 246.592 354.56 246.848 354.765 247.001C354.868 247.104 355.021 247.104 355.175 247.053Z" fill="white" />
      <path d="M346.368 135.121C346.368 135.121 356.916 147.615 353.485 180.95L350.771 236.559H342.22L336.587 135.121H346.368V135.121Z" fill="#8E716C" />
      <path d="M331.364 231.131V243.83L325.476 244.035L325.117 227.188L331.364 231.131Z" fill="#CF7C6C" />
      <path d="M331.672 242.293C331.672 242.293 327.575 244.904 325.373 240.91L309.448 249.359L334.232 249.205L331.672 242.293Z" fill="#101010" />
      <path d="M314.189 246.703C314.189 246.703 317.689 248.203 317.689 250.703L309.189 249.703V249.203L314.189 246.703Z" fill="white" />
      <path d="M360.189 246.703C360.189 246.703 356.689 248.203 356.689 250.703L365.189 249.703V249.203L360.189 246.703Z" fill="white" />
      <path d="M334.231 249.185L309.186 249.338L309.199 251.489L334.244 251.335L334.231 249.185Z" fill="white" />
      <path d="M323.479 245.007C323.428 244.956 323.376 244.956 323.325 244.853C322.352 243.573 321.379 242.959 321.379 242.959C321.175 242.856 321.123 242.6 321.277 242.395C321.379 242.19 321.687 242.19 321.84 242.293C321.891 242.344 322.915 243.01 323.991 244.392C324.144 244.546 324.093 244.802 323.888 244.956C323.786 245.058 323.632 245.058 323.479 245.007Z" fill="white" />
      <path d="M321.482 246.031C321.431 245.98 321.379 245.98 321.328 245.878C320.355 244.597 319.382 243.983 319.382 243.983C319.177 243.881 319.126 243.624 319.28 243.42C319.382 243.215 319.69 243.215 319.843 243.317C319.894 243.368 320.918 244.034 321.994 245.417C322.147 245.57 322.096 245.826 321.891 245.98C321.738 246.082 321.584 246.082 321.482 246.031Z" fill="white" />
      <path d="M319.434 247.055C319.382 247.003 319.331 247.003 319.28 246.901C318.307 245.621 317.334 245.006 317.334 245.006C317.129 244.904 317.078 244.648 317.232 244.443C317.334 244.238 317.641 244.238 317.795 244.341C317.846 244.392 318.87 245.058 319.946 246.44C320.099 246.594 320.048 246.85 319.843 247.003C319.741 247.106 319.587 247.106 319.434 247.055Z" fill="white" />
      <path d="M327.678 135.121C327.678 135.121 317.129 147.615 320.56 180.95L323.274 236.559H332.747L337.407 135.121H327.678V135.121Z" fill="#8E716C" />
      <path d="M344.883 141.931H328.446V158.368H344.883V141.931Z" fill="#8E716C" />
      <path d="M339.045 158.418L331.467 157.906" stroke="black" strokeWidth="0.9528" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M377.603 97.79L381.341 98.9678L373.455 123.444C372.534 126.158 369.308 127.336 366.85 125.799C364.802 124.519 364.136 121.908 365.314 119.808L377.603 97.79Z" fill="#DE9A8C" />
      <path d="M377.757 96.9719L378.064 95.999C378.473 94.7189 379.139 93.49 380.112 92.5171L381.751 90.7761C382.16 90.3664 382.826 90.4176 383.133 90.8785C383.44 91.2881 383.44 91.8002 383.184 92.2098L381.853 94.3605L389.534 90.1616C389.892 89.9568 390.404 90.1104 390.558 90.5201L390.66 90.7761C390.814 91.0833 390.66 91.4418 390.404 91.6466L385.335 94.6677L391.633 92.005C391.992 91.8002 392.504 92.005 392.606 92.4147L392.657 92.6195C392.76 92.9779 392.606 93.3364 392.299 93.49L386.871 96.1527L391.428 95.0261C391.889 94.9237 392.299 95.231 392.35 95.6918V95.7942C392.35 96.1015 392.197 96.4087 391.889 96.5111L387.23 97.9961L389.79 97.7912C390.302 97.6888 390.763 98.1497 390.66 98.6617C390.609 98.9178 390.404 99.1226 390.199 99.1738L378.371 102.809L377.654 100.095C377.449 99.0202 377.449 97.9961 377.757 96.9719Z" fill="#DE9A8C" />
      <path d="M381.904 94.4111C381.904 94.4111 382.519 96.3057 381.085 98.3027" stroke="#C47268" strokeWidth="0.7519" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M350.669 99.0713C350.669 99.0713 357.018 100.71 366.696 115.559L371.202 102.707L381.495 107.315L374.531 125.954C372.738 130.819 366.543 132.304 362.702 128.77C359.63 125.903 349.901 115.15 347.904 111.463L350.669 99.0713Z" fill="#262722" />
      <path d="M319.126 132.97C319.126 132.97 323.991 134.352 321.431 139.626C319.075 144.389 315.03 145.361 311.701 144.593C311.138 144.44 310.831 143.825 311.087 143.262C311.241 142.904 311.599 142.699 311.958 142.75C313.186 142.75 315.337 142.596 315.952 140.958C316.72 138.858 315.644 136.503 316.873 134.301L319.126 132.97Z" fill="#DE9A8C" />
      <path d="M319.126 132.97L316.208 128.105L311.855 130.512L317.129 136.247L319.126 132.97Z" fill="#DE9A8C" />
      <path d="M319.075 101.223C319.075 101.223 310.831 105.319 300.59 115.714C299.566 116.789 299.156 118.325 299.566 119.759C300.334 122.371 302.997 126.723 311.343 132.407L318.614 128.72L312.265 120.271L324.4 115.253L319.075 101.223Z" fill="#262722" />
      <path d="M351.795 143.16L323.223 143.467L326.5 99.4814H348.569L351.795 143.16Z" fill="white" />
      <path d="M322.864 99.4824L319.075 101.223C319.075 101.223 324.708 121.706 316.566 153.248L323.632 154.887C323.632 154.887 331.262 132.049 330.545 101.223L322.864 99.4824Z" fill="#262722" />
      <path d="M351.488 99.5322L353.28 100.352C354.458 100.915 355.073 102.144 354.868 103.424C353.69 109.415 351.232 127.746 357.838 153.349L350.771 154.988C350.771 154.988 343.142 132.15 343.859 101.324L351.488 99.5322Z" fill="#262722" />
      <path d="M340.889 91.5452C340.889 91.5452 339.404 92.8254 338.021 93.1326C336.639 93.4398 335.307 92.5181 335.307 92.4669L333.72 91.3916L333.054 99.3285L333.208 99.4821C335.512 101.786 338.892 101.786 341.247 99.5333L341.605 99.226L340.889 91.5452Z" fill="#DE9A8C" />
      <path d="M346.112 79.256C346.112 82.6868 345.651 85.6567 345.139 87.8074C344.627 89.8556 343.244 91.3405 341.247 92.5183L340.889 92.7231C338.789 93.952 336.024 94.0032 333.873 92.8255C331.723 91.6478 330.187 90.0604 329.726 87.8586C328.855 83.8133 328.753 79.3585 328.753 79.3585C328.753 79.3585 328.036 70.0391 337.407 70.1415C346.726 70.2439 346.112 79.256 346.112 79.256Z" fill="#DE9A8C" />
      <path d="M333.361 82.7891C333.786 82.7891 334.129 82.4452 334.129 82.021C334.129 81.5968 333.786 81.2529 333.361 81.2529C332.937 81.2529 332.593 81.5968 332.593 82.021C332.593 82.4452 332.937 82.7891 333.361 82.7891Z" fill="#1E0B02" />
      <path d="M340.786 82.7891C341.21 82.7891 341.554 82.4452 341.554 82.021C341.554 81.5968 341.21 81.2529 340.786 81.2529C340.362 81.2529 340.018 81.5968 340.018 82.021C340.018 82.4452 340.362 82.7891 340.786 82.7891Z" fill="#1E0B02" />
      <path d="M336.383 83.8652V85.1966H337.304" stroke="#C47268" strokeWidth="0.6951" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M330.545 78.7966L335.512 78.3358C335.512 78.3358 335.051 77.0557 333.054 77.2605C331.006 77.5165 330.545 78.7966 330.545 78.7966Z" fill="black" />
      <path d="M343.295 78.7957L338.328 78.3348C338.328 78.3348 338.789 77.0547 340.786 77.2595C342.834 77.5155 343.295 78.7957 343.295 78.7957Z" fill="black" />
      <path d="M334.129 86.3223C334.129 86.3223 335.461 87.4488 337.407 87.0391" stroke="black" strokeWidth="0.6951" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M328.958 75.8762C328.958 75.8762 335.051 71.1653 346.112 75.8762C346.112 75.8762 345.139 69.0146 337.56 69.0146C329.982 69.0146 328.958 75.8762 328.958 75.8762Z" fill="white" />
      <path d="M349.337 78.5388C348.672 72.3429 344.371 69.0658 341.298 67.7857C338.789 66.7616 335.922 66.7616 333.413 67.7857C330.34 69.0146 325.937 72.2917 325.271 78.5388C324.4 86.8341 330.904 95.2318 330.904 95.2318L337.202 93.6956L343.705 95.2318C343.807 95.2318 350.259 86.8341 349.337 78.5388ZM338.994 92.4155C337.867 92.9788 336.536 92.9788 335.41 92.4155C331.108 90.2649 328.907 85.0419 329.163 79.3069C329.316 76.081 331.211 73.8279 333.157 72.3942C335.614 70.5508 338.994 70.5508 341.452 72.3942C343.398 73.8791 345.343 76.1322 345.446 79.3069C345.753 85.0419 343.346 90.2649 338.994 92.4155Z" fill="#7EB4D0" />
      <path d="M331.62 93.6455L322.864 99.4829C322.864 99.4829 334.078 108.854 352.359 99.995L343.193 93.6967H331.62V93.6455Z" fill="#7EB4D0" />
      <path d="M330.955 94.2598C330.955 94.2598 333.259 96.6664 337.304 96.2568" stroke="#61A2C4" strokeWidth="0.6735" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M330.545 99.2262C330.545 99.2262 339.301 102.759 348.569 97.7412" stroke="#61A2C4" strokeWidth="0.6735" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M333.617 93.9525C333.617 93.9525 336.69 94.7206 339.66 92.2627" stroke="#61A2C4" strokeWidth="0.6735" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M343.807 94.2598C343.807 94.2598 343.295 95.2839 341.605 95.5399" stroke="#61A2C4" strokeWidth="0.6735" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M45.689 251.664H389.534" stroke="#A5CADD" strokeWidth="0.9916" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconUserRegistered;
