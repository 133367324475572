import {
  ECanvasObjectTypes,
  EProductTypes,
  ETemplateSides,
  ETextCase,
  TArticle,
  TOption,
  TOrder,
  TOrderItem,
  TPaper,
  TParam,
  TPrintItem,
  TQuantity,
  TSize,
  TSizeDimensions
} from 'types';
import sumBy from 'lodash/sumBy';
import keyBy from 'lodash/keyBy';
import first from 'lodash/first';
import get from 'lodash/get';
import { IOrderState } from './ContextOrder';
import { TStateOptionalOptions } from './types';
import strings from '../../../../constants/localization';
import { ECodeObjectTitle } from '../../../PageArticles/components/Editor/components/Viewport/fabric/codeObject';
import fabric from '../../../PageArticles/components/Editor/helpers/fabric';
import getObjectModifier from '../../../PageArticles/components/Editor/hooks/useFabricObjects/modifiers';
import { formatText } from '../../../../helpers';

export const calculateNewPrice = (
  optionsState:TStateOptionalOptions,
  paper: TPaper | null,
  quantity: TQuantity | null,
  printItem: TPrintItem | null,
  size: TSize | null
) => {
  const selectedOptions = Object.values(optionsState).filter(e => e);
  const basePrice = printItem?.pricings.find(p => p.paper.id === paper?.id
    && p.size.id === size?.id
    && p.quantity.value === quantity?.value);
  const optionalPrices = selectedOptions.map(o => printItem?.optionalPricings.find(op => op.option.id === o?.value?.id
    && op.quantity.value === quantity?.value)).filter(e => e);
  return Number(basePrice?.price) + sumBy(optionalPrices, e => Number(e?.price));
};

export const getSizeDescription = (size: TSize | null, units?: string) => size
  ? `${size.width} ${units} x ${size.height} ${units}`
  : undefined;

const getDefaultOptionsState: (options:TOption[]) => IOrderState['optionsState'] = options => {
  const map = keyBy(options, 'id');
  options.forEach(e => {
    map[e.id].value = first(e.values) || undefined;
  });
  return map;
};

const getQuantity = (order: TOrder | undefined, quantityDefault: TQuantity): TQuantity => {
  // Quantity selected from dropdown
  const quantity = get(order, 'quantity');
  if (quantity) {
    return quantity;
  }

  // Quantity from integration service
  const ticketsQuantity = get(order, 'sourceData.ticketsQuantity');
  if (ticketsQuantity) {
    return { id: '', value: ticketsQuantity };
  }

  return quantityDefault;
};

export const getOptionsFromArticle = (article: TArticle, order?:TOrder):Partial<IOrderState> => {
  let params:TParam[] = [];
  const orderItems:TOrderItem[] = [];
  if (article.frontSide) {
    params = article.frontSide.canvas.objects.map(e => ({ ...e, side: ETemplateSides.FRONT, isDefaultValue: true }));
  }
  if (article.backSide) {
    params = [...params, ...article.backSide.canvas.objects.map(e => ({ ...e, side: ETemplateSides.BACK, isDefaultValue: true }))];
  }
  if (article.productType === EProductTypes.VD_PRODUCT && !order) {
    const id = '#0';
    orderItems.push({
      id,
      title: id,
      items: [...params]
    });
  }
  const optionsState = order?.options || getDefaultOptionsState(article.options);
  const paperQuantityDefault = article.papers[0];
  const quantityDefault = paperQuantityDefault.quantities.sort((a, b) => a.value > b.value ? 1 : -1)[0];
  const quantity = getQuantity(order, quantityDefault);
  const price = order?.price
    || calculateNewPrice(optionsState, paperQuantityDefault.paper, paperQuantityDefault.quantities[0], article.printItem, article.size);
  return {
    isOrderCreation: !order,
    optionsState,
    currentTab: article.frontSide ? ETemplateSides.FRONT : ETemplateSides.BACK,
    sideCount: article.frontSide && article.backSide ? 2 : 1,
    params,
    frontSide: article.frontSide,
    backSide: article.backSide,
    price,
    orderItems: order?.orderItems || orderItems,
    orderItemsToSave: order?.orderItems || orderItems,
    selectedOrderItem: order?.orderItems[0] || orderItems[0] || null,
    paper: order?.paper || paperQuantityDefault.paper,
    quantity,
    orderComment: order?.orderComment,
    orderId: order?.title,
    size: article.size,
    currency: article.currency,
    paperComment: article.paperComment,
    skuIdTitle: article.skuIdTitle,
    papers: article.papers,
    options: article.options,
    printItem: article.printItem,
    quantities: article.quantities,
    productType: article.productType,
  };
};

export const SIDES_TO_INDEX = {
  [ETemplateSides.FRONT]: 0,
  [ETemplateSides.BACK]: 1,
};

export const INDEX_TO_SIDE = {
  0: ETemplateSides.FRONT,
  1: ETemplateSides.BACK,
};

export const OBJECT_TYPE_TO_FIELD: { [key in ECanvasObjectTypes]: keyof TParam } = {
  [ECanvasObjectTypes.TEXT]: 'text',
  [ECanvasObjectTypes.NUMBERING]: 'startValue',
  [ECanvasObjectTypes.IMAGE]: 'src',
  [ECanvasObjectTypes.CODE]: 'content',
};

export const SIDE_TO_STRING = {
  [ETemplateSides.FRONT]: () => strings.orderPageViewFrontsideTitle,
  [ETemplateSides.BACK]: () => strings.orderPageViewBacksideTitle,
};

const TITLES_TO_STRING = {
  [ECodeObjectTitle.BARCODE]: () => strings.orderPageModalItemsBarCodeType,
  [ECodeObjectTitle.QR]: () => strings.orderPageModalItemsQRCodeType,
};

const TYPES_TO_STRING = {
  [ECanvasObjectTypes.IMAGE]: () => strings.orderPageModalItemsImageType,
  [ECanvasObjectTypes.TEXT]: () => strings.orderPageModalItemsTextType,
  [ECanvasObjectTypes.NUMBERING]: () => strings.orderPageModalItemsTextType,
};

export const getObjectTitle = (type: ECanvasObjectTypes, title: ECodeObjectTitle) => {
  if (type === ECanvasObjectTypes.CODE) {
    return TITLES_TO_STRING[title]();
  }
  return TYPES_TO_STRING[type]();
};

const imageDimensions = (file: File | string):Promise<TSizeDimensions> => new Promise((resolve => {
  const img = new Image();
  img.onload = () => {
    const { naturalWidth: width, naturalHeight: height } = img;
    resolve({ width, height });
  };
  if (file instanceof File) img.src = URL.createObjectURL(file);
  if (typeof file === 'string') img.src = file;
}));

export const getImageDimensions = async (file:File | string) => {
  try {
    return await imageDimensions(file);
  } catch (error) {
    console.log(error);
  }
};

export const createScaledImageObject = (item: TParam, imgWidth = 0, imgHeight = 0) => {
  const object = new fabric.ImageObject(item.src, { ...item });
  const scaleRatioWidth = object.getScaledWidth() / imgWidth;
  const scaleRatioHeight = object.getScaledHeight() / imgHeight;
  const scale = scaleRatioWidth > scaleRatioHeight ? scaleRatioHeight : scaleRatioWidth;
  return new fabric.ImageObject(item.src, { ...item, width: imgWidth, height: imgHeight }).scale(scale);
};

const getFabricObject = (item: TParam) => {
  switch (item.type) {
    case ECanvasObjectTypes.IMAGE:
      return new fabric.ImageObject(item.src, { ...item });
    case ECanvasObjectTypes.TEXT:
      return new fabric.TextObject(item.text, { ...item });
    case ECanvasObjectTypes.CODE:
      return new fabric.CodeObject(item.src, { ...item });
    default: throw new Error('Invalid Object type');
  }
};

const getModifiedFabricObject = async (initItem: TParam, item: TParam) => {
  const object = getFabricObject(initItem);
  const modifier = getObjectModifier(item.type);
  const data = filterObjectPropertiesByType(item);
  return modifier(object, data, {
    forceScale: true,
  });
};

export const processTableItems = async (
  orderItems:TOrderItem[],
  params: TParam[],
  getImgDpi:(object:typeof fabric.ImageObject)=>number
):Promise<TOrderItem[]> => promiseMap(orderItems, async orderItem => ({
  ...orderItem,
  items: await promiseMap(orderItem.items, async item => {
    if (item.type === ECanvasObjectTypes.IMAGE) {
      const initImageObject = params.find(initObj => initObj.id === item.id) as TParam;
      const initSrc = initImageObject?.src;
      if (item?.src === initSrc || !item?.src) {
        return { ...initImageObject, isDefaultValue: true };
      }
      const newImageSizes = await getImageDimensions(item.src as string);
      const newScaledImage = createScaledImageObject(item, newImageSizes?.width, newImageSizes?.height);
      const dpi = getImgDpi(newScaledImage);
      return getModifiedFabricObject(initImageObject, {
        ...item, isDefaultValue: false, dpi
      });
    }
    if (item.type === ECanvasObjectTypes.TEXT) {
      const initTextobject = params.find(initObj => initObj.id === item.id) as TParam;
      if (item?.text === initTextobject?.text) {
        return initTextobject;
      }
      const { text = '', textCase = ETextCase.Default, ...rest } = item;
      const formattedText = formatText(text || initTextobject?.text || '', textCase);
      return getModifiedFabricObject(initTextobject, { text: formattedText, textCase, ...rest });
    }
    const initCodeObject = params.find(initObj => initObj.id === item.id) as TParam;
    if (item?.content === initCodeObject?.content) {
      return initCodeObject;
    }
    const content = item.content || initCodeObject?.content;
    return getModifiedFabricObject(initCodeObject, { ...item, content });
  }, { concurrency: 3 })
}), { concurrency: 3 });

export const filterObjectPropertiesByType = (object: TParam) => {
  const {
    id, type, text, src, content, dpi, isDefaultValue
  } = object;
  switch (type) {
    case ECanvasObjectTypes.TEXT:
      return { id, text };
    case ECanvasObjectTypes.IMAGE:
      return {
        id, src, dpi, isDefaultValue
      };
    case ECanvasObjectTypes.CODE:
      return { id, content };
    default:
      return { id };
  }
};

// https://betterprogramming.pub/implement-your-own-bluebird-style-promise-map-in-js-7c081b7ad02c
// http://bluebirdjs.com/docs/api/promise.map.html
export const promiseMap = <T>(iterable: Iterable<T>, mapper:(item: T, index:number)=>Promise<any>, options = {} as {concurrency: number}) => {
  let concurrency = options.concurrency || Infinity;

  let index = 0;
  const results: any[] = [];
  const iterator = iterable[Symbol.iterator]();
  const promises: Promise<any>[] = [];

  // eslint-disable-next-line no-plusplus
  while (concurrency-- > 0) {
    const promise = wrappedMapper();
    if (promise) promises.push(promise);
    else break;
  }

  return Promise.all(promises).then(() => results);

  function wrappedMapper(): Promise<any> | null {
    const next = iterator.next();
    if (next.done) return null;
    // eslint-disable-next-line no-plusplus
    const i = index++;
    const mapped = mapper(next.value, i);
    return Promise.resolve(mapped).then(resolved => {
      results[i] = resolved;
      return wrappedMapper();
    });
  }
};
