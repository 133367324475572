import React from 'react';

import { IIconProps } from './types';

function IconGrid(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 6H4C4.55 6 5 5.55 5 5V1C5 0.45 4.55 0 4 0H1C0.45 0 0 0.45 0 1V5C0 5.55 0.45 6 1 6ZM1 13H4C4.55
        13 5 12.55 5 12V8C5 7.45 4.55 7 4 7H1C0.45 7 0 7.45 0 8V12C0 12.55 0.45 13 1 13ZM7 13H10C10.55
        13 11 12.55 11 12V8C11 7.45 10.55 7 10 7H7C6.45 7 6 7.45 6 8V12C6 12.55 6.45 13 7 13ZM13 13H16C16.55
        13 17 12.55 17 12V8C17 7.45 16.55 7 16 7H13C12.45 7 12 7.45 12 8V12C12 12.55 12.45 13 13 13ZM7 6H10C10.55
        6 11 5.55 11 5V1C11 0.45 10.55 0 10 0H7C6.45 0 6 0.45 6 1V5C6 5.55 6.45 6 7 6ZM12 1V5C12 5.55 12.45 6
        13 6H16C16.55 6 17 5.55 17 5V1C17 0.45 16.55 0 16 0H13C12.45 0 12 0.45 12 1Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconGrid;
