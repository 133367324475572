import { TRowSelection } from '../../../types';

// eslint-disable-next-line import/prefer-default-export
export const getAllRowSelected = (rowCount: number): TRowSelection => {
  const rowSelection: TRowSelection = {};
  for (let i = 0; i < rowCount; i += 1) {
    rowSelection[i] = true;
  }
  return rowSelection;
};
