import { PresignedPost as psPost } from 'aws-sdk/clients/s3';
import axios from 'axios';
import instance from 'lib/apiClient';
import { dataUrlToFile } from './file';

const client = instance.getClient();

interface IPreSignedPost extends psPost {
  key: string,
}

async function sendRequest(preSignedPost: IPreSignedPost, file: File) {
  const formData = new FormData();
  formData.append('Content-Type', file.type);
  formData.append('Content-Length', file.size.toString());
  formData.append('Content-Encoding', 'base64');
  formData.append('key', preSignedPost.key);
  Object.entries(preSignedPost.fields).forEach(([k, v]) => formData.append(k, v));
  formData.append('file', file);

  return axios.post(preSignedPost.url, formData);
}

interface IUploadToAws {
  preSignedPost: IPreSignedPost,
  url: string,
}

export async function uploadToAws(pspId: string, file: File):Promise<IUploadToAws> {
  const preSignedPost: IPreSignedPost = (await client.get(`aws-upload?pspId=${pspId}&fileName=${file.name}`)).data;
  await sendRequest(preSignedPost, file);
  return {
    preSignedPost,
    url: `${preSignedPost.url}/${preSignedPost.key}`,
  };
}

export async function dataUrlToAwsLink(dataUrl: string, fileName: string, pspId: string): Promise<string> {
  const file = await dataUrlToFile(dataUrl, fileName);
  const { url } = await uploadToAws(pspId, file);
  return url;
}

export default { dataUrlToAwsLink, uploadToAws };
