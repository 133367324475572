import { useLayoutEffect, useRef } from 'react';

const isDevelopmentRun = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Ignoring double call when Strict Mode is Enabled (in dev)
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode
// https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
const useLayoutEffectIgnoreStrictMode = (cb: Function, deps: any[]) => {
  const isMountedRef = useRef<boolean>(!isDevelopmentRun);

  useLayoutEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }

    return cb();
  }, deps);
};

export default useLayoutEffectIgnoreStrictMode;
