import React, { useCallback, useMemo } from 'react';
import cls from 'classnames';
import css from './EventbriteLogin.module.css';
import textCss from '../../assets/styles/Text.module.css';
import strings from '../../constants/localization';
import Button from '../library/Button';
import { ROUTES } from '../../constants/constants';

const AUTH_URL = 'https://www.eventbrite.com/oauth/authorize';
const RESPONSE_TYPE = 'token';

// Eventbrite Application Key
const CLIENT_ID = process.env.REACT_APP_EVENTBRITE_CLIENT_ID || '';

const getUrl = (redirect: string): string => {
  const url = new URL(AUTH_URL);

  url.searchParams.append('client_id', CLIENT_ID);
  url.searchParams.append('redirect_uri', redirect);
  url.searchParams.append('response_type', RESPONSE_TYPE);

  return url.href;
};

export const EventbriteManualAuthorize:React.FC = () => {
  const redirectUrl = useMemo(
    () => `${window.location.origin}${ROUTES.USER_LOGIN}/${ROUTES.EVENTBRITE}/${ROUTES.OAUTH}`,
    [window.location.origin]
  );

  const to = useMemo<string>(
    () => getUrl(redirectUrl),
    [redirectUrl]
  );

  const onClick = useCallback(() => {
    window.location.href = to;
  }, [to]);

  return (
    <div className={css.container}>
      <div className={css.innerContainer}>
        <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageLoginTitle}</span>
        <Button onClick={onClick} type="submit" buttonType="primary">{strings.loginPageLoginButtonLogin}</Button>
      </div>
    </div>
  );
};

export default React.memo(EventbriteManualAuthorize);
