import React, { useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cls from 'classnames';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';

import useAuthentication from 'hooks/useAuthentication';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import Checkbox from 'components/library/Checkbox';
import AlertContext from 'contexts/ContextAlert';
import instance from 'lib/apiClient';
import UserContext from 'contexts/ContextUser';
import { IResponseError } from 'constants/types';
import { getDefaultRoute } from 'helpers';
import { TUser } from 'types';
import css from './UserLogin.module.css';

const Schema = () => Yup.object().shape({
  password: Yup.string()
    .required(strings.inputValidateRequiredField),
  email: Yup.string()
    .email(strings.inputValidateEmail)
    .required(strings.inputValidateRequiredField),
});

interface ILoginLocationState {
  from?: Location,
}

interface IForm {
  email: string,
  password: string,
  checkbox: boolean
}

function UserLogin() {
  const { mutateAsync } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const { push } = useContext(AlertContext);
  const { setUser, setToken } = useContext(UserContext);
  const navigateUser = (user: TUser): void => {
    const locationState = location.state as ILoginLocationState;
    const path = !user.lastLoginAt
      ? ROUTES.PROFILE
      : locationState?.from?.pathname || getDefaultRoute(user.role, user.id);
    navigate(path, { replace: true });
  };
  const onSubmit = (data:IForm, options:FormikHelpers<IForm>) => {
    mutateAsync(data)
      .then(res => {
        if (data.checkbox) {
          localStorage.setItem('token', res.accessToken);
        } else {
          instance.setJwt(res.accessToken);
        }
        setToken(res.accessToken);
        setUser(res.user);
        navigateUser(res.user);
      })
      .catch(err => {
        const error = err as IResponseError;
        const status = error.response?.status as number;
        if (status === 401) {
          push({
            message: strings.errorResponse401,
            severity: 'error'
          });
        } else if (status >= 400 && status <= 500) {
          push({
            message: strings.errorResponse4,
            severity: 'error'
          });
        }
        if (status >= 500) {
          push({
            message: strings.errorResponse5,
            severity: 'error'
          });
        }
      })
      .finally(() => {
        options.setSubmitting(false);
      });
  };
  return (
    <div className={css.container}>
      <div className={css.innerContainer}>
        <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageLoginTitle}</span>
        <Formik
          validationSchema={Schema()}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            email: '',
            password: '',
            checkbox: true
          } as IForm}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Input
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className={css.input}
                label={strings.inputEmailLabel}
                type="email"
                withError
                error={errors.email}
                placeholder={strings.inputEmailPlaceholder}
              />
              <Input
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={css.input}
                label={strings.inputPasswordLabel}
                type="password"
                withError
                error={errors.password}
                placeholder={strings.inputPasswordPlaceholder}
              />
              <Checkbox
                name="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.checkbox}
                label={strings.loginPageLoginKeepLoggedLabel}
              />
              <div className={css.controls}>
                <Button disabled={isSubmitting} type="submit" buttonType="primary">{strings.loginPageLoginButtonLogin}</Button>
                <NavLink to={ROUTES.USER_FORGOT_PASSWORD} className={cls(css.link, textCss.pMedium2)}>{strings.loginPageLoginButtonForgot}</NavLink>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default UserLogin;
