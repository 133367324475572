/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconMark(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3L4 6L9 1" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconMark;
