/* eslint-disable max-len */
import { ILanguages } from '../types';
import IEventsStrings from '../types/EventsStrings';

const strings:ILanguages<IEventsStrings> = {
  en: {
    eventsPageTitle: 'Events',
    eventsPageButtonAddEvent: 'Add Event',

    eventsPageAddNewEventModalTitle: 'Add New Event',
    eventsPageAddNewEventModalButtonAdd: 'Add',

    eventsPageSKUCellAddNewLabel: 'Add New',

    eventsPageButtonViewAllEvent: 'View all Events',
  },
  de: {
    eventsPageTitle: 'Veranstaltungen',
    eventsPageButtonAddEvent: 'Veranstaltung hinzufügen',

    eventsPageAddNewEventModalTitle: 'Daten zur Veranstaltung',
    eventsPageAddNewEventModalButtonAdd: 'Weiter',

    eventsPageSKUCellAddNewLabel: 'Neu',

    eventsPageButtonViewAllEvent: 'Alle anzeigen',
  },
  ru: {
    eventsPageTitle: 'Мероприятия',
    eventsPageButtonAddEvent: 'Добавить мероприятие',

    eventsPageAddNewEventModalTitle: 'Добавить новое мероприятие',
    eventsPageAddNewEventModalButtonAdd: 'Добавить',

    eventsPageSKUCellAddNewLabel: 'Новое',

    eventsPageButtonViewAllEvent: 'Посмотреть все мероприятия',
  },
};

export default strings;
