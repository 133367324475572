import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EFooterSaveButtonDisplayModes } from 'types';
import { navigateBack } from 'helpers';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from '../Icon';
import Button from '../Button';
import TabNavigationButtons from './components/TabNavigationButtons';
import css from './FooterActionButtons.module.css';

interface IFooterActionButtons extends React.HTMLProps<HTMLDivElement> {
  tabIndex: number,
  setTabIndex: (React.Dispatch<React.SetStateAction<number>>),
  tabCount: number,
  onClickSave: () => void,
  disableSave?: boolean,
  saveBtnDisplayMode: EFooterSaveButtonDisplayModes | boolean,
  backToPreviousPage?: boolean,
  previousPageFallback?: string,
}
const FooterActionButtons: React.FC<IFooterActionButtons> = ({
  tabIndex,
  setTabIndex,
  tabCount,
  onClickSave,
  disableSave = false,
  saveBtnDisplayMode,
  backToPreviousPage,
  previousPageFallback,
}) => {
  const maxTabIndex = tabCount - 1;
  const showSaveButton: boolean = typeof saveBtnDisplayMode === 'boolean'
    ? saveBtnDisplayMode
    : saveBtnDisplayMode === EFooterSaveButtonDisplayModes.ALWAYS
      || (saveBtnDisplayMode === EFooterSaveButtonDisplayModes.ONLY_ON_LAST_TAB && tabIndex === maxTabIndex);
  const navigate = useNavigate();
  const onPreviousPageClick = () => navigateBack(navigate, previousPageFallback);

  return (
    <div className={css.actionButtons}>
      {(backToPreviousPage && tabIndex === 0) && (
        <Button buttonType="quaternaryBack" onClick={onPreviousPageClick}>{strings.actionLabelBack}</Button>
      )}
      <TabNavigationButtons tabIndex={tabIndex} setTabIndex={setTabIndex} maxTabIndex={maxTabIndex} />
      {showSaveButton && (
        <>
          <Icon type={ICON_TYPES.verticalDivider} />
          <Button buttonType="primary" disabled={disableSave} onClick={onClickSave}>{strings.actionLabelSave}</Button>
        </>
      )}
    </div>
  );
};

export default FooterActionButtons;
