import { convertUnit, EConvertUnitDirection } from './pdf';
import fabric from './fabric';
import { IPdfBox } from '../contexts/EditorContext';

const getImageBackgroundObject = async (dataUrl: string, pdfBox: IPdfBox) => {
  const {
    width: rawWidth,
    height: rawHeight,
    bleed: rawBleed,
    unit,
  } = pdfBox;

  const image = new fabric.ImageObject(dataUrl, {
    name: 'Background', // TODO: add localization
    crossOrigin: 'anonymous',
    isBackground: true,
  });

  const bleed = convertUnit(rawBleed, unit, EConvertUnitDirection.PIXELS);
  const width = convertUnit(rawWidth, unit, EConvertUnitDirection.PIXELS);
  const height = convertUnit(rawHeight, unit, EConvertUnitDirection.PIXELS);
  await new Promise(resolve => {
    image.setSrc(dataUrl, resolve);
  });

  const destination = {
    width: width + (bleed * 2),
    height: height + (bleed * 2)
  };

  image.scale(fabric.util.findScaleToCover(image, destination));
  const position = new fabric.Point(width / 2, height / 2);
  image.setPositionByOrigin(position, 'center', 'center');

  return image;
};

export default getImageBackgroundObject;
