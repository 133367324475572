import React, { BaseSyntheticEvent, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/library/Button';
import Icon, { ICON_TYPES } from 'components/library/Icon';

import { EArticleStatuses, ETablePageViews } from 'types';
import { IArticlesDisplayProps } from 'types/Articles';
import { ROUTES } from 'constants/constants';
import css from '../ArticlesTableView/ArticlesTableView.module.css';
import ArticlesActionsPopover from '../ArticlesActionsPopover';

interface IArticlesControls extends Omit<IArticlesDisplayProps, 'articles' | 'handleArticleClick'> {
  articleId: string,
  status: EArticleStatuses,
  view: ETablePageViews,
  customerId: string,
}

const ArticlesControls: React.FC<IArticlesControls> = ({
  view, isNotCustomer, isNotAppEventbriteManager, articleId, status, toggleModal, setDeleteArticleId, customerId,
}) => {
  const hideCartIcon = true;

  const cartButtonDisabled = useMemo(
    () => status === EArticleStatuses.DRAFT,
    [status]
  );

  const handleCartLinkClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    if (cartButtonDisabled) {
      e.preventDefault();
    }
  };

  const handleArticleDelete = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteArticleId(articleId);
    toggleModal();
  };

  return (
    <div className={css.buttons}>
      {isNotCustomer && (
        <Button
          type="button"
          buttonType="secondary"
          buttonStyle="circle"
          className={css.deleteBtn}
          onClick={handleArticleDelete}
        >
          <Icon type={ICON_TYPES.delete} />
        </Button>
      )}
      {!hideCartIcon && isNotAppEventbriteManager && (
        <NavLink to={`/${ROUTES.ORDERS}/${ROUTES.ROUTE_ADD}`} state={{ customerId, articleId }} onClick={handleCartLinkClick}>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="circle"
            disabled={cartButtonDisabled}
          >
            <Icon type={ICON_TYPES.cart} />
          </Button>
        </NavLink>
      )}
      {isNotCustomer && view === ETablePageViews.TABLE && (<ArticlesActionsPopover articleId={articleId} customerId={customerId} />)}
    </div>
  );
};

export default ArticlesControls;
