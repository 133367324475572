import React from 'react';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Tooltip from 'components/library/Tooltip';
import strings from 'constants/localization';
import css from './Label.module.css';

interface ILabel extends React.HTMLProps<HTMLDivElement>{
  text?: string,
  optional?: true | string,
  description?: string,
}
const Label:React.FC<ILabel> = props => {
  const {
    text, children, className, optional, description, ...rest
  } = props;
  return (
    <span className={cls(textCss.h4, css.label, className)} {...rest}>
      {text || children}
      {optional
        ? (
          <span className={cls(textCss.pMedium2, css.optional)}>
            {typeof optional === 'string'
              ? optional
              : strings.inputLabelOptional}
          </span>
        )
        : null}
      {description
        ? (
          <div className={css.description}>
            <Tooltip title={description}>
              <Icon type={ICON_TYPES.squaredChat} />
            </Tooltip>
          </div>
        )
        : null}
    </span>
  );
};

export type Props = ILabel;
export default Label;
