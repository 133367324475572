// This hook re-implement the now removed useBlocker hook in 'react-router-dom' (https://github.com/remix-run/react-router/issues/8139).
// eslint-disable-next-line max-len
// Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import type { History, Blocker, Transition } from 'history';
import { useContext, useEffect } from 'react';

export default function useBlocker(blocker: Blocker, when: boolean = true): void {
  const navigator = useContext(NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
