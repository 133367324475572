export enum EOrderDataIds {
  ORDER_ID = 'order_id',
  TICKET_TYPE_ID = 'ticket_type_id',
  TICKET_TYPE_NAME = 'ticket_type_name',
  NAME = 'name',
  SURNAME = 'surname',
  COUNTRY = 'country',
  CITY = 'city',
  ZIPCODE = 'zipcode',
  STREET = 'street',
  HOUSE = 'house',
  APARTMENT = 'apartment',
  PHONE_NUMBER = 'phone_number',
}

export type TExcelBindings = {
  [EOrderDataIds.ORDER_ID]: number | null,
  [EOrderDataIds.TICKET_TYPE_ID]: number | null,
  [EOrderDataIds.TICKET_TYPE_NAME]: number | null,
  [EOrderDataIds.NAME]: number | null,
  [EOrderDataIds.SURNAME]: number | null,
  [EOrderDataIds.COUNTRY]: number | null,
  [EOrderDataIds.CITY]: number | null,
  [EOrderDataIds.ZIPCODE]: number | null,
  [EOrderDataIds.STREET]: number | null,
  [EOrderDataIds.HOUSE]: number | null,
  [EOrderDataIds.APARTMENT]: number | null,
  [EOrderDataIds.PHONE_NUMBER]: number | null,
}

export type TBindingsOrder = Pick<TExcelBindings,
  EOrderDataIds.ORDER_ID
  | EOrderDataIds.TICKET_TYPE_ID
  | EOrderDataIds.TICKET_TYPE_NAME
  >;

export type TBindingsDelivery = Pick<TExcelBindings,
  EOrderDataIds.NAME
  | EOrderDataIds.SURNAME
  | EOrderDataIds.COUNTRY
  | EOrderDataIds.CITY
  | EOrderDataIds.ZIPCODE
  | EOrderDataIds.STREET
  | EOrderDataIds.HOUSE
  | EOrderDataIds.APARTMENT
  | EOrderDataIds.PHONE_NUMBER
  >;

export type TExcelDataTableRow = {
  id: EOrderDataIds,
  title: string,
  value: string,
  required?: boolean,
}

export type TSelectMatchingFieldItem = {
  label: string,
  value: number,
}

export enum ETabs {
  FILE_LIST,
  ORDER_DATA,
  DELIVERY_DATA,
  TICKET_TYPE,
}
