import React, { useContext, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormikErrors, FormikHelpers, useFormik,
} from 'formik';

import Modal from 'components/library/Modal';
import Input from 'components/library/Input';
import AddCustomerModalControls from 'components/PageCustomers/components/AddCustomerModal/AddCustomerModalControls';

import { ERoles, TAddCustomerModalForm, TUser } from 'types';
import { EAddCustomerModalSubmitModes } from 'types/Customers';
import UserContext from 'contexts/ContextUser';
import strings from 'constants/localization';
import { ROUTES } from 'constants/constants';
import { useUserCreate } from 'hooks/useUser';
import AlertContext from 'contexts/ContextAlert';
import { createTargetUser, getBackendErrors } from 'helpers';
import { IResponseError } from 'constants/types';
import css from './AddCustomerModal.module.css';
import { getDataToSend, getInitialValues, getValidationSchema } from './helpers';

interface IAddCustomerModal {
  isOpen: boolean,
  toggleOpen: () => void,
}

const AddCustomerModal: React.FC<IAddCustomerModal> = ({ isOpen, toggleOpen }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const submitModeRef = useRef<EAddCustomerModalSubmitModes>(EAddCustomerModalSubmitModes.UNCHOSEN);

  const alertContext = useContext(AlertContext);
  const user = useContext(UserContext).user as TUser;
  const userCreate = useUserCreate();

  const handleSubmit = (data: TAddCustomerModalForm, options: FormikHelpers<TAddCustomerModalForm>) => {
    const dataToSend: Partial<TUser> = getDataToSend(data, searchParams, user);

    if (submitModeRef.current === EAddCustomerModalSubmitModes.REDIRECT) {
      navigate(`${ROUTES.ROUTE_ADD}`, {
        state: {
          user: dataToSend
        }
      });
      return;
    }

    options.setSubmitting(true);
    const onMutateSuccess = (): void => {
      alertContext.push({ severity: 'success', message: strings.profileNotifySuccessCreate });
      closeModal();
    };
    const onMutateError = (err: IResponseError): void => {
      alertContext.push({
        message: err.response?.data.data?.reason || strings.errorResponse5,
        severity: 'error'
      });
      const backendErrors: FormikErrors<TAddCustomerModalForm> = getBackendErrors<TAddCustomerModalForm>(err);
      options.setErrors(backendErrors);
    };
    const onMutateEnd = (): void => {
      options.setSubmitting(false);
    };
    userCreate.mutate(createTargetUser(ERoles.CUSTOMER, dataToSend, user), {
      onSuccess: onMutateSuccess,
      onError: onMutateError,
      onSettled: onMutateEnd,
    });
  };

  const formik = useFormik<TAddCustomerModalForm>({
    initialValues: getInitialValues(user),
    validationSchema: getValidationSchema(),
    onSubmit: handleSubmit,
  });

  const getFieldError = (fieldName: keyof TAddCustomerModalForm): string | undefined => (
    formik.touched[fieldName] && formik.errors[fieldName]
  ) || undefined;

  const onSaveForLater = (): void => {
    submitModeRef.current = EAddCustomerModalSubmitModes.SAVE;
    formik.handleSubmit();
  };

  const onSaveRedirect = (): void => {
    submitModeRef.current = EAddCustomerModalSubmitModes.REDIRECT;
    formik.handleSubmit();
  };

  const closeModal = (): void => {
    formik.resetForm();
    toggleOpen();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={strings.customersPageAddNewCustomerModalTitle}
      customControls={(
        <AddCustomerModalControls
          closeModal={closeModal}
          onSaveForLater={onSaveForLater}
          onSaveRedirect={onSaveRedirect}
          isSubmitting={formik.isSubmitting}
        />
      )}
      onRequestClose={closeModal}
    >
      <form noValidate className={css.form}>
        <Input
          {...formik.getFieldProps('name')}
          withError
          error={getFieldError('name')}
          className={css.input}
          label={strings.inputNameLabel}
          placeholder={strings.inputNamePlaceholder}
        />
        <Input
          {...formik.getFieldProps('surname')}
          withError
          error={getFieldError('surname')}
          className={css.input}
          label={strings.inputLastNameLabel}
          placeholder={strings.inputLastNamePlaceholder}
        />
        <Input
          {...formik.getFieldProps('companyName')}
          withError
          error={getFieldError('companyName')}
          className={css.input}
          label={strings.inputCompanyNameLabel}
          placeholder={strings.inputCompanyNamePlaceholder}
        />
        <Input
          {...formik.getFieldProps('email')}
          withError
          error={getFieldError('email')}
          className={css.input}
          label={strings.inputEmailLabel}
          placeholder={strings.inputEmailPlaceholder}
        />
        <Input
          {...formik.getFieldProps('sendOrderSheetsTo')}
          withError
          error={getFieldError('sendOrderSheetsTo')}
          disabled
          className={css.input}
          label={strings.inputSendOrderSheetsToLabel}
          placeholder={strings.inputEmailPlaceholder}
        />
      </form>
    </Modal>
  );
};

export default AddCustomerModal;
