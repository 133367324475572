import React, { useContext, useEffect, useState } from 'react';
import IsEmpty from 'lodash/isEmpty';
import Modal from '../../library/Modal';
import strings from '../../../constants/localization';
import { TUser } from '../../../types';
import { IUserPatchInput, useUserPatch } from '../../../hooks/useUser';
import ContextAlert from '../../../contexts/ContextAlert';
import {
  getCancelLabel, getModalHeader, getOkLabel, getReassignedUsersToBackend
} from './helpers';
import ModalContent from './components/ModalContent';
import { TReassignedCustomers } from './types';

export interface IBlockUserModal {
  isOpen: boolean,
  closeModal: () => void,
  userToBlock?: TUser
}
const BlockUserModal: React.FC<IBlockUserModal> = ({
  isOpen, closeModal, userToBlock
}) => {
  const patchUser = useUserPatch();
  const { push: pushAlert } = useContext(ContextAlert);
  const [reassignedCustomers, setReassignedCustomers] = useState<TReassignedCustomers>({});
  const modalTitle = getModalHeader(userToBlock);
  const modalOkLabel = getOkLabel(userToBlock);
  const modalCancelLabel = getCancelLabel(userToBlock);

  useEffect(() => {
    if (!userToBlock) {
      setReassignedCustomers({});
    }
  }, [userToBlock]);

  const onBlockUser = (): void => {
    const data: IUserPatchInput = {
      id: (userToBlock as TUser).id,
      blocked: !(userToBlock as TUser).blocked
    };
    if (!IsEmpty(reassignedCustomers)) {
      data.reassignUsers = getReassignedUsersToBackend(reassignedCustomers);
    }
    patchUser.mutate(data, {
      onSuccess: () => {
        closeModal();
      },
      onError: err => {
        pushAlert({
          message: err.response?.data.data?.reason || strings.errorResponse5,
          severity: 'error'
        });
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onOk={onBlockUser}
      onCancel={closeModal}
      title={modalTitle}
      okLabel={modalOkLabel}
      cancelLabel={modalCancelLabel}
    >
      {userToBlock && (
        <ModalContent
          userToBlock={userToBlock}
          reassignedCustomers={reassignedCustomers}
          setReassignedCustomers={setReassignedCustomers}
        />
      )}
    </Modal>
  );
};

export default BlockUserModal;
