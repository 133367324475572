import axios, { AxiosInstance } from 'axios';

import { INTEGRATION_BASE_API_LINKS } from 'constants/constants';
import { EIntegrationServices } from 'types';

export class Client {
  private jwt:null|string = null;

  private client:AxiosInstance;

  private interceptor:number;

  constructor(baseURL: string = process.env.REACT_APP_ENV_API as string, integrationToken: string | null | undefined = null) {
    this.client = axios.create({
      baseURL,
    });
    this.interceptor = this.client.interceptors.request.use(config => {
      if (!config.headers) {
        config.headers = {};
      }
      if (integrationToken) {
        config.headers.Authorization = `Bearer ${integrationToken}`;
      } else {
        const localStorageValue = localStorage.getItem('token') as string;
        config.headers.Authorization = localStorageValue;
      }

      if (this.jwt) {
        config.headers.Authorization = this.jwt as string;
      }
      return config;
    });
  }

  public setJwt = (token:string) => {
    this.jwt = token;
  };

  public getClient = () => this.client;
}

export const eventbriteClient = (token: string | null | undefined, service = EIntegrationServices.EVENTBRITE) => new Client(
  `${INTEGRATION_BASE_API_LINKS[service]}`,
  token
);

export default new Client();
