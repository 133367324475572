import React, { useEffect, useMemo, useState } from 'react';
import { FormikErrors } from 'formik/dist/types';
import Input from 'components/library/Input';
import strings from 'constants/localization';
import containerCss from '../../Tab.module.css';
import { INTEGRATION_TOKEN } from '../index';

interface IPaylogicConnectionForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>,
  integrationToken: string,
  isSubmitting: boolean
}

const PaylogicConnectionForm:React.FC<IPaylogicConnectionForm> = props => {
  const {
    setFieldValue,
    integrationToken,
    isSubmitting,
  } = props;

  const credentials = useMemo<string[]>(
    () => {
      const chunks = integrationToken.split(':');
      while (chunks.length < 2) chunks.push('');
      return chunks;
    },
    [integrationToken]
  );

  const [login, setLogin] = useState<string>(credentials[0]);
  const [password, setPassword] = useState<string>(credentials[1]);

  useEffect(() => {
    let value = '';

    if (login && password) {
      value = `${login}:${password}`;
    }

    setFieldValue(INTEGRATION_TOKEN, value);
  }, [login, password]);

  const disabled = useMemo(() => isSubmitting, [isSubmitting]);

  return (
    <>
      <Input
        name="login"
        type="text"
        label={strings.inputLoginLabel}
        className={containerCss.input}
        value={login}
        onChange={e => setLogin(e.target.value)}
        disabled={disabled}
      />
      <Input
        name="password"
        type="password"
        label={strings.inputPasswordLabel}
        className={containerCss.input}
        value={password}
        onChange={e => setPassword(e.target.value)}
        disabled={disabled}
      />
    </>
  );
};

export default PaylogicConnectionForm;
