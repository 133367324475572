/* eslint-disable max-len */
import { ILanguages } from '../types';
import IEditorStrings from '../types/EditorStrings';

const strings:ILanguages<IEditorStrings> = {
  en: {
    editorPageTitle: 'Editor',

    editorLoaderStatus: 'Uploading...',
    editorLoaderLoadingStatus: 'Loading...',
    editorLoaderLoadingError: 'Loading error...',
    editorLoaderStatusPdf: 'Pdf file uploading...',
    editorLoaderStatusThumbnail: 'Thumbnail uploading...',
    editorLoaderStatusBackground: 'Background uploading...',
    editorLoaderStatusImage: '{0} uploading...',
    editorLoaderStatusSaving: 'Saving...',

    errorEditorNeedToOpenUsingForm: 'Please open Editor using SKU Parameters form',
    errorEditorQrInvalidContent: 'Invalid content',
    errorEditorBarcodeInvalidContent: 'Invalid content',
    errorEditorContentOutsidePdf: 'Content outside of the PDF will be cut off during printing',
    warningEditorContentOutsidePdf: 'Content outside of the PDF will be cut off during printing',
    errorEditorTheNameMustBeUniques: 'The object name must be unique for this side',

    editorFooterButtonCancel: 'Cancel',
    editorFooterButtonSave: 'Save',

    editorToolsDescriptionTitle: 'Select tool to get started',
    editorToolsDescriptionHand: 'The Hand tool allows you to move your PDF around its window by dragging',
    editorToolsDescriptionText: 'The Text field tool allows mapping the text field in the required place on the PDF',
    editorToolsDescriptionImage: 'The Image field tool allows mapping the image in the required place on the PDF',
    editorToolsDescriptionCode: 'The Code tool allows you to create QR and Barcodes',
    editorToolsDescriptionNumbering: 'The Pagination tool allows you to create numbering.',

    editorGetStartedTitle: 'Upload PDF to get started mapping',
    editorGetStartedContent: 'Drag and drop a file in the workspace or click on it to select a file from your device',

    editorTabSummary: 'Summary',
    editorTabField: '{0} field settings',
    editorShortTabField: '{0} settings',

    editorTabSettingsSectionCodeTypeTitle: 'Code type',
    editorTabSettingsSectionEffectsTitle: 'Effects',
    editorTabSettingsSectionImageEffectsTitle: 'Image Effects',
    editorTabSettingsSectionFontTitle: 'Font',
    editorTabSettingsSectionSizeTitle: 'Size',
    editorTabSettingsSectionNumberingPatternTitle: 'Pattern',
    editorTabSettingsSectionNumberingPrefixTitle: 'Prefix',
    editorTabSettingsSectionNumberingStartFromTitle: 'Start from',

    editorTabSettingsBarcodeHeightTooltip: 'Height',
    editorTabSettingsCodeContentInputLabel: 'Content',
    editorTabSettingsCodeContentInputPlaceholder: 'Content',
    editorTabSettingsCodeContentErrorQrFormat: 'Invalid QR code content format',
    editorTabSettingsCodeContentErrorBarcodeFormat: 'Invalid EAN13 content format',
    editorTabSettingsCodeMarginTooltip: 'Margin',
    editorTabSettingsColorPickerTooltip: 'Set Color',
    editorTabSettingsBackgroundColorPickerTooltip: 'Set Background Color',
    editorTabSettingsColorPickerDefaultCategoryTitle: 'Default colors',
    editorTabSettingsColorPickerDocumentCategoryTitle: 'Document colors',
    editorTabSettingsFontStyleTooltip: 'Italic',
    editorTabSettingsFontBoldTooltip: 'Bold',
    editorTabSettingsMorePopoverTooltip: 'More Settings',
    editorTabSettingsObjectAlignLeftTooltip: 'To the left edge',
    editorTabSettingsObjectAlignRightTooltip: 'To the right edge',
    editorTabSettingsObjectAlignCenterVTooltip: 'To the vertical center',
    editorTabSettingsObjectAlignCenterHTooltip: 'To the horizontal center',
    editorTabSettingsObjectAlignTopTooltip: 'To the top edge',
    editorTabSettingsObjectAlignBottomTooltip: 'To the bottom edge',
    editorTabSettingsObjectRotateLeftTooltip: 'Rotate left',
    editorTabSettingsObjectRotateRightTooltip: 'Rotate right',
    editorTabSettingsObjectAlignResetRotateButton: 'Reset',
    editorTabSettingsOpacityTooltip: 'Transparency',
    editorTabSettingsDuplicateTooltip: 'Duplicate image to other side',
    editorTabSettingsDuplicateDisabledTooltip: 'Background has already been copied',
    editorTabSettingsDuplicateSuccess: 'Image has been duplicated to the other side',
    editorTabSettingsTextAlignTooltip: 'Text align',
    editorTabSettingsTextAlignLeftTooltip: 'Align left',
    editorTabSettingsTextAlignCenterTooltip: 'Align center',
    editorTabSettingsTextAlignRightTooltip: 'Align right',
    editorTabSettingsTextJustifyTooltip: 'Justify',
    editorTabSettingsTextCaseTooltip: 'Text Case',
    editorTabSettingsTextCaseDefaultTooltip: 'Default',
    editorTabSettingsTextCaseCapitalizeTooltip: 'Capitalize',
    editorTabSettingsTextCaseUppercaseTooltip: 'Uppercase',
    editorTabSettingsTextCaseLowercaseTooltip: 'Lowercase',
    editorTabSettingsTextSpacingTooltip: 'Line Spacing',
    editorTabSettingsTextUnderlineTooltip: 'Underline',

    editorColorPickerColorTransparent: 'Transparent',
    editorColorPickerColorBlack: 'Black',
    editorColorPickerColorDimGrey: 'Dim Grey',
    editorColorPickerColorGrey: 'Grey',
    editorColorPickerColorDarkGrey: 'Dark Grey',
    editorColorPickerColorSilver: 'Silver',
    editorColorPickerColorLightGrey: 'Light Grey',
    editorColorPickerColorGainsboro: 'Gainsboro',
    editorColorPickerColorWhite: 'White',
    editorColorPickerColorCyan: 'Cyan',
    editorColorPickerColorMagenta: 'Magenta',
    editorColorPickerColorYellow: 'Yellow',
    editorColorPickerColorRed: 'Red',
    editorColorPickerColorGreen: 'Green',
    editorColorPickerColorBlue: 'Blue',

    editorObjectTextTitle: 'Text',
    editorObjectTextDefaultText: 'Text',
    editorObjectNumberingTitle: 'Pagination',
    editorObjectNumberingDefaultText: '1',
    editorObjectImageTitle: 'Image',
    editorObjectCodeTitle: 'Code',
    editorObjectQrCodeTitle: 'QR Code',
    editorObjectBarCodeTitle: 'Barcode',
    editorActiveSelectionTitle: 'Selection',
    editorObjectCodeDefaultContent: 'Code content',

    editorOrientationLandscapeLabel: 'landscape',
    editorOrientationPortraitLabel: 'portrait',
    editorOrientationSquareLabel: 'square',

    editorUploadAreaContent: 'Click to upload file',
    editorUploadAreaSupportedFormats: 'Supported Formats: PDF, PNG, JPG',
    editorUploadAreaDescription: 'Note: if the file size does not match the size of the print item, it will be automatically cropped',
    editorImageObjectSuccessMessage: 'The image was uploaded successfully',
    editorPdfSizeIsTooSmallErrorMessage: 'The PDF does not fit the selected size.',
    editorPdfHasWrongOrientationErrorMessage: 'The pdf file must have a {0} orientation',
    editorImageObjectErrorMessage: 'The image format is not supported. Please, choose PNG or JPEG file',
    editorParseUnitHasNoImplementation: 'Unit {0} has no implementation',
    editorErrorPdfFileSizeIsTooLarge: 'PDF File is larger than {0} MB and can not be processed',

    editorExcelModalFileListTabTitle: 'Upload spreadsheet',
    editorExcelModalFileListTabDescription: 'Upload the spreadsheet to be able to place order manually with this SKU.',

    editorExcelModalOrderDataTabTitle: 'Match order data',
    editorExcelModalOrderDataTabDescription: 'Match order data from the spreadsheet via the option drop down and check the sample data shown.',

    editorExcelModalOrderDataOrderIdField: 'Order ID',
    editorExcelModalOrderDataTicketTypeIdField: 'Ticket Type ID',
    editorExcelModalOrderDataTicketTypeNameField: 'Ticket Type Name',
    editorExcelModalOrderDataValueColumn: 'Value',
    editorExcelModalOrderDataColumnColumn: 'Column',
    editorExcelModalOrderDataSampleColumn: 'Sample data',

    editorExcelModalDeliveryDataTabTitle: 'Match delivery address',
    editorExcelModalDeliveryDataTabDescription: 'Match the delivery address from the spreadsheet via the option drop down and check the sample data shown.',

    editorExcelModalDeliveryDataNameField: 'Name',
    editorExcelModalDeliveryDataSurnameField: 'Surname',
    editorExcelModalDeliveryDataCountryField: 'Country',
    editorExcelModalDeliveryDataCityField: 'City',
    editorExcelModalDeliveryDataZipcodeField: 'Zipcode',
    editorExcelModalDeliveryDataStreetField: 'Street',
    editorExcelModalDeliveryDataHouseField: 'House',
    editorExcelModalDeliveryDataApartmentField: 'Apartment',
    editorExcelModalDeliveryDataPhoneField: 'Phone number',
    editorExcelModalDeliveryDataValueColumn: 'Value',
    editorExcelModalDeliveryDataColumnColumn: 'Column',
    editorExcelModalDeliveryDataSampleColumn: 'Sample data',

    editorExcelModalTicketTypeTabTitle: 'Choose ticket type',
  },
  de: {
    editorPageTitle: 'Editor',

    editorLoaderStatus: 'Datenübertragung läuft...',
    editorLoaderLoadingStatus: 'Datenübertragung läuft...',
    editorLoaderLoadingError: 'Ladefehler...',
    editorLoaderStatusPdf: 'PDF-Datei wird übertragen...',
    editorLoaderStatusThumbnail: 'Vorschau laden...',
    editorLoaderStatusBackground: 'Vorschau wird geladen...',
    editorLoaderStatusImage: '{0} wird übertragen...',
    editorLoaderStatusSaving: 'Speichern...',

    errorEditorNeedToOpenUsingForm: 'Bitte öffnen sie den Editor aus der Artikel-Parameter Seite',
    errorEditorQrInvalidContent: 'Die Eingabe ist kein gültiger QR-Code',
    errorEditorBarcodeInvalidContent: 'Die Eingabe ist kein gültiger Bar-Code',
    errorEditorContentOutsidePdf: 'Inhalte außerhalb der Vorlage. Variable Inhalte sollten nicht über die Grenzen der Vorlage hinausgehen, um Verluste im Druck zu vermeiden.',
    warningEditorContentOutsidePdf: 'Inhalte außerhalb der Vorlage. Variable Inhalte sollten nicht über die Grenzen der Vorlage hinausgehen, um Verluste im Druck zu vermeiden.',
    errorEditorTheNameMustBeUniques: 'Die Feldbezeichnung muss innerhalb der Seite eindeutig sein',

    editorFooterButtonCancel: 'Abbrechen',
    editorFooterButtonSave: 'Sichern',

    editorToolsDescriptionTitle: 'Wählen Sie ein Werkzeug aus, um mit der Gestaltung der Vorlage zu beginnen',
    editorToolsDescriptionHand: 'Mit dem ‘Hand-Werkzeug’ können Sie die Vorschau in der Arbeitsfläche verschieben. Zum Beenden des Verschiebe-Modus klicken Sie erneut auf das ‘Hand-Werkzeug’.',
    editorToolsDescriptionText: 'Mit dem ‘Text-Werkzeug’ fügen Sie der Vorlage einen Textrahmen hinzu. Ihnen stehen dann zahlreiche Einstellungen zur Textgestaltung zur Verfügung.',
    editorToolsDescriptionImage: 'Mit dem ‘Bild-Werkzeug’ fügen Sie der Vorlage ein Platzhalter-Bild hinzu, dass sie dann in Größe, Ausrichtung und Position anpassen können.',
    editorToolsDescriptionCode: 'Mit dem ‘Code-Werkzeug’ fügen Sie der Vorlage ein Feld für einen QR- oder Bar-Code hinzu, dass sie dann in Größe und Position anpassen können.',
    editorToolsDescriptionNumbering: 'Nutzen Sie das Nummern-Werkzeug zum Anlegen von Ticketnummern für Abendkassen-Tickets.',

    editorGetStartedTitle: 'PDF hochladen um mit der Gestaltung der Vorlage zu beginnen',
    editorGetStartedContent: 'Ein PDF in den Arbeitsbereichen ziehen oder per Klick der Auswahl-Dialog öffnen, um eine lokale Datei auszuwählen.',

    editorTabSummary: 'Übersicht',
    editorTabField: '{0}-Einstellungen',

    editorTabSettingsSectionCodeTypeTitle: 'Code wählen',
    editorTabSettingsSectionEffectsTitle: 'Weitere Einstellungen',
    editorTabSettingsSectionImageEffectsTitle: 'Bild-Einstellungen',
    editorTabSettingsSectionFontTitle: 'Schriftart',
    editorTabSettingsSectionSizeTitle: 'Schriftgröße',

    editorTabSettingsSectionNumberingPatternTitle: 'Schema',
    editorTabSettingsSectionNumberingPrefixTitle: 'Prefix',
    editorTabSettingsSectionNumberingStartFromTitle: 'Startwert',

    editorTabSettingsBarcodeHeightTooltip: 'Höhe variieren',
    editorTabSettingsCodeContentInputLabel: 'Inhalt',
    editorTabSettingsCodeContentInputPlaceholder: 'Beispiel-Code eingeben',
    editorTabSettingsCodeContentErrorQrFormat: 'Falsches Format ',
    editorTabSettingsCodeContentErrorBarcodeFormat: 'Kein gültiger EAN13 Code (exakt 12 Zahlen erforderlich)',
    editorTabSettingsCodeMarginTooltip: 'Rand (innen)',
    editorTabSettingsColorPickerTooltip: 'Textfarbe',
    editorTabSettingsBackgroundColorPickerTooltip: 'Hintergrundfarbe festlegen',
    editorTabSettingsColorPickerDefaultCategoryTitle: 'Basis-Farben',
    editorTabSettingsColorPickerDocumentCategoryTitle: 'Farben passend zu PDF-Datei',
    editorTabSettingsFontStyleTooltip: 'Kursiv',
    editorTabSettingsFontBoldTooltip: 'Fett',
    editorTabSettingsMorePopoverTooltip: 'Weitere Einstellungen',
    editorTabSettingsObjectAlignLeftTooltip: 'Linksbündig',
    editorTabSettingsObjectAlignRightTooltip: 'Rechtsbündig',
    editorTabSettingsObjectAlignCenterVTooltip: 'Vertikal zentriert',
    editorTabSettingsObjectAlignCenterHTooltip: 'Horizontal zentriert',
    editorTabSettingsObjectAlignTopTooltip: 'Oben ausrichten',
    editorTabSettingsObjectAlignBottomTooltip: 'Unten ausrichten',
    editorTabSettingsObjectRotateLeftTooltip: '90° nach links drehen',
    editorTabSettingsObjectRotateRightTooltip: '90° nach rechts drehen',
    editorTabSettingsObjectAlignResetRotateButton: 'Zurücksetzen',
    editorTabSettingsOpacityTooltip: 'Transparenz',
    editorTabSettingsDuplicateTooltip: 'Ticketdesign (Hintergrund) auf die zweite Seite kopieren.',
    editorTabSettingsDuplicateDisabledTooltip: 'Der Hintergrund wurde schon kopiert.',
    editorTabSettingsDuplicateSuccess: 'Das Ticketdesign (Hintergrund) ist jetzt auch auf der zweiten Seite vorhanden.',
    editorTabSettingsTextAlignTooltip: 'Textausrichtung',
    editorTabSettingsTextAlignLeftTooltip: 'Linksbündig',
    editorTabSettingsTextAlignCenterTooltip: 'Zentriert',
    editorTabSettingsTextAlignRightTooltip: 'Rechtsbündig',
    editorTabSettingsTextJustifyTooltip: 'Blocksatz',
    editorTabSettingsTextCaseTooltip: 'Groß-/Kleinschreibung',
    editorTabSettingsTextCaseCapitalizeTooltip: 'Großkleinschreibung',
    editorTabSettingsTextCaseUppercaseTooltip: 'GROSSBUCHSTABEN',
    editorTabSettingsTextCaseLowercaseTooltip: 'Kleinbuchstaben',
    editorTabSettingsTextSpacingTooltip: 'Zeilenabstand',
    editorTabSettingsTextUnderlineTooltip: 'Unterstrichen',

    editorColorPickerColorTransparent: 'Transparent',
    editorColorPickerColorBlack: 'Schwarz',
    editorColorPickerColorDimGrey: 'Dunkelgrau',
    editorColorPickerColorGrey: 'Grau',
    editorColorPickerColorDarkGrey: 'Dunkelgrau',
    editorColorPickerColorSilver: 'Silber',
    editorColorPickerColorLightGrey: 'Hellgrau',
    editorColorPickerColorGainsboro: 'Gainsboro',
    editorColorPickerColorWhite: 'Weiß',
    editorColorPickerColorCyan: 'Zyan',
    editorColorPickerColorMagenta: 'Magenta',
    editorColorPickerColorYellow: 'Gelb',
    editorColorPickerColorRed: 'Rot',
    editorColorPickerColorGreen: 'Grün',
    editorColorPickerColorBlue: 'Blau',

    editorObjectTextTitle: 'Text',
    editorObjectTextDefaultText: 'Text',
    editorObjectNumberingDefaultText: '1',
    editorObjectImageTitle: 'Bild',
    editorObjectCodeTitle: 'Code',
    editorObjectQrCodeTitle: 'QR-Code',
    editorObjectBarCodeTitle: 'Bar-Code',
    editorActiveSelectionTitle: 'Auswahl',
    editorObjectCodeDefaultContent: 'Beispiel-Code eingeben ',

    editorOrientationLandscapeLabel: 'Querformat',
    editorOrientationPortraitLabel: 'Hochformat',
    editorOrientationSquareLabel: 'Quadratformat',

    editorUploadAreaContent: 'Hier klicken um eine Datei auszuwählen',
    editorUploadAreaSupportedFormats: 'Unterstützte Formate: PDF, PNG, JPG',
    editorUploadAreaDescription: 'Hinweis: Das Bild wird so platziert, dass es die Fläche des Tickets komplett ausfüllt. Je nach Orientierung des Bildes können Inhalte außerhalb des sichtbaren Ticketbereichs liegen. ',
    editorImageObjectSuccessMessage: 'Das Bild wurde erfolgreich übertragen',
    editorPdfSizeIsTooSmallErrorMessage: 'Die PDF-Seitengröße passt nicht zum gewählten Seitenformat',
    editorPdfHasWrongOrientationErrorMessage: 'Das Ticket-Design muss im {0} sein',
    editorImageObjectErrorMessage: 'Es muss eine Bildatei (JPG, JPEG, PNG) verwendet werden',
    editorParseUnitHasNoImplementation: 'Seitengrößen in {0} werden nicht unterstützt',
    editorErrorPdfFileSizeIsTooLarge: 'Die PDF-Datei ist größer als {0} MB und kann nicht verarbeitet werden.',
  },
  ru: {
    editorPageTitle: 'Редактор',

    editorLoaderStatus: 'Загрузка...',
    editorLoaderLoadingStatus: 'Загрузка...',
    editorLoaderLoadingError: 'Ошибка загрузки...',
    editorLoaderStatusPdf: 'Загрузка PDF-файла...',
    editorLoaderStatusThumbnail: 'Загрузка предварительного просмотра...',
    editorLoaderStatusBackground: 'Загрузка фонового изображения...',
    editorLoaderStatusImage: '{0} загрузка...',
    editorLoaderStatusSaving: 'Сохранение...',

    errorEditorNeedToOpenUsingForm: 'Пожалуйста, откройте редактор, используя страницу параметров артикула',
    errorEditorQrInvalidContent: 'Введенный QR-код недействителен',
    errorEditorBarcodeInvalidContent: 'Введенный штрих-код недействителен',
    errorEditorContentOutsidePdf: 'Содержимое за пределами шаблона будет обрезано во время печати',
    warningEditorContentOutsidePdf: 'Предупреждение: Содержимое за пределами шаблона будет обрезано во время печати',
    errorEditorTheNameMustBeUniques: 'Имя объекта должно быть уникальным для выбранной страницы',

    editorFooterButtonCancel: 'Отмена',
    editorFooterButtonSave: 'Сохранить',

    editorToolsDescriptionTitle: 'Выберите инструмент, чтобы начать',
    editorToolsDescriptionHand: 'С помощью инструмента “Рука“ можно перемещать ваш PDF-файл по окну рабочей области. Чтобы выйти из режима перемещения PDF, снова нажмите на инструмент"Рука".',
    editorToolsDescriptionText: 'Инструмент “Текст” позволяет отобразить текстовое поле в нужном месте PDF-файла. Вам будут доступны многочисленные настройки оформления текста.',
    editorToolsDescriptionImage: 'Инструмент “Изображение” позволяет отобразить изображение в нужном месте PDF-файла. Изображение можно изменять по размеру, ориентации и положению.',
    editorToolsDescriptionCode: 'Инструмент “Код“позволяет создавать QR- и штрих-коды, которые можно изменять по размеру и расположению.',
    editorToolsDescriptionNumbering: 'Инструмент «Пагинация» позволяет создавать автоматическую нумерацию билетов.',

    editorGetStartedTitle: 'Загрузите PDF-файл, чтобы начать редактирование шаблона',
    editorGetStartedContent: 'Перетащите файл в рабочую область или кликните сюда, чтобы выбрать файл с вашего устройства',

    editorTabSummary: 'Обзор',
    editorTabField: 'Настройки',
    editorShortTabField: 'Настройки',

    editorTabSettingsSectionCodeTypeTitle: 'Тип кода',
    editorTabSettingsSectionEffectsTitle: 'Дополнительные настройки',
    editorTabSettingsSectionImageEffectsTitle: 'Настройки изображения',
    editorTabSettingsSectionFontTitle: 'Шрифт',
    editorTabSettingsSectionSizeTitle: 'Размер шрифта',
    editorTabSettingsSectionNumberingPatternTitle: 'Паттерн',
    editorTabSettingsSectionNumberingPrefixTitle: 'Префикс',
    editorTabSettingsSectionNumberingStartFromTitle: 'Начать с',

    editorTabSettingsBarcodeHeightTooltip: 'Высота',
    editorTabSettingsCodeContentInputLabel: 'Контент',
    editorTabSettingsCodeContentInputPlaceholder: 'Введите пример кода',
    editorTabSettingsCodeContentErrorQrFormat: 'Недопустимый формат QR-кода',
    editorTabSettingsCodeContentErrorBarcodeFormat: 'Недействительный код EAN13 (введите 12 цифр)',
    editorTabSettingsCodeMarginTooltip: 'Отступы',
    editorTabSettingsColorPickerTooltip: 'Цвет текста',
    editorTabSettingsBackgroundColorPickerTooltip: 'Цвет фона',
    editorTabSettingsColorPickerDefaultCategoryTitle: 'Цвета по умолчанию',
    editorTabSettingsColorPickerDocumentCategoryTitle: 'Цвета документа',
    editorTabSettingsFontStyleTooltip: 'Курсив',
    editorTabSettingsFontBoldTooltip: 'Жирный',
    editorTabSettingsMorePopoverTooltip: 'Дополнительные настройки',
    editorTabSettingsObjectAlignLeftTooltip: 'Выравнивание по левому краю',
    editorTabSettingsObjectAlignRightTooltip: 'Выравнивание по правому краю',
    editorTabSettingsObjectAlignCenterVTooltip: 'Центрировать по вертикали',
    editorTabSettingsObjectAlignCenterHTooltip: 'Центрировать по горизонтали',
    editorTabSettingsObjectAlignTopTooltip: 'Выровнять по верхнему краю',
    editorTabSettingsObjectAlignBottomTooltip: 'Выровнять по нижнему краю',
    editorTabSettingsObjectRotateLeftTooltip: 'Повернуть на 90° влево',
    editorTabSettingsObjectRotateRightTooltip: 'Повернуть на 90° вправо',
    editorTabSettingsObjectAlignResetRotateButton: 'Сброс поворота',
    editorTabSettingsOpacityTooltip: 'Прозрачность',
    editorTabSettingsDuplicateTooltip: 'Копировать изображение на другую сторону',
    editorTabSettingsDuplicateDisabledTooltip: 'Фон уже используется на другой стороне',
    editorTabSettingsDuplicateSuccess: 'Изображение было скопировано на другую сторону',
    editorTabSettingsTextAlignTooltip: 'Выравнивание текста',
    editorTabSettingsTextAlignLeftTooltip: 'Выровнять по левому краю',
    editorTabSettingsTextAlignCenterTooltip: 'Выровнять по центру',
    editorTabSettingsTextAlignRightTooltip: 'Выровнять по правому краю',
    editorTabSettingsTextJustifyTooltip: 'Выровнять по ширине',
    editorTabSettingsTextCaseTooltip: 'Текстовый регистр',
    editorTabSettingsTextCaseDefaultTooltip: 'Без изменений',
    editorTabSettingsTextCaseCapitalizeTooltip: 'Каждое Слово С Заглавной Буквы',
    editorTabSettingsTextCaseUppercaseTooltip: 'ВЕРХНИЙ РЕГИСТР',
    editorTabSettingsTextCaseLowercaseTooltip: 'нижний регистр',
    editorTabSettingsTextSpacingTooltip: 'Межстрочный интервал',
    editorTabSettingsTextUnderlineTooltip: 'Подчёркивание',

    editorColorPickerColorTransparent: 'Прозрачный',
    editorColorPickerColorBlack: 'Чёрный',
    editorColorPickerColorDimGrey: 'Тускло-серый',
    editorColorPickerColorGrey: 'Серый',
    editorColorPickerColorDarkGrey: 'Тёмно-серый',
    editorColorPickerColorSilver: 'Серебрянный',
    editorColorPickerColorLightGrey: 'Светло-серый',
    editorColorPickerColorGainsboro: 'Гейнсборо',
    editorColorPickerColorWhite: 'Белый',
    editorColorPickerColorCyan: 'Морская волна',
    editorColorPickerColorMagenta: 'Пурпурный',
    editorColorPickerColorYellow: 'Жёлтый',
    editorColorPickerColorRed: 'Красный',
    editorColorPickerColorGreen: 'Зелёный',
    editorColorPickerColorBlue: 'Синий',

    editorObjectTextTitle: 'Текст',
    editorObjectTextDefaultText: 'Текст',
    editorObjectNumberingDefaultText: '1',
    editorObjectImageTitle: 'Изображение',
    editorObjectCodeTitle: 'Код',
    editorObjectQrCodeTitle: 'QR-код',
    editorObjectBarCodeTitle: 'Штрих-код',
    editorActiveSelectionTitle: 'Выбранные объекты',
    editorObjectCodeDefaultContent: 'Текст кода',

    editorOrientationLandscapeLabel: 'альбомную',
    editorOrientationPortraitLabel: 'портретную',
    editorOrientationSquareLabel: 'квадратную',

    editorUploadAreaContent: 'Загрузить файл',
    editorUploadAreaSupportedFormats: 'Поддерживаемые форматы: PDF, PNG, JPG',
    editorUploadAreaDescription: 'Примечание: если размер файла не соответствует размеру продукта, он будет автоматически обрезан',
    editorImageObjectSuccessMessage: 'Изображение было успешно загружено',
    editorPdfSizeIsTooSmallErrorMessage: 'PDF-файл не соответствует выбранному размеру.',
    editorPdfHasWrongOrientationErrorMessage: 'PDF-файл должен иметь {0} ориентацию',
    editorImageObjectErrorMessage: 'Тип файла должен быть jpeg или png',
    editorParseUnitHasNoImplementation: 'Размеры страниц в {0} не поддерживается',
    editorErrorPdfFileSizeIsTooLarge: 'Размер PDF-файла превышает {0} МБ и не может быть обработан',
  },
};

export default strings;
