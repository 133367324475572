/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconDuplicate(props: IIconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="3" y="3" width="14" height="14" rx="3" stroke="#2C3E66" strokeWidth="1.8" />
      <rect x="8" y="8" width="13" height="13" rx="2" fill="#2C3E66" stroke="#2C3E66" strokeWidth="1.8" />
    </svg>
  );
}

export default IconDuplicate;
