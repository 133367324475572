import React from 'react';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import Tooltip from 'components/library/Tooltip';
import { TReportType } from 'types/Reports/Orders';
import css from './ModalControls.module.css';

interface IExportOrdersModalControls {
  onBack: () => void
  onNext: (type: TReportType) => void
  disableNext: boolean
}

const ExportOrdersModalControls: React.FC<IExportOrdersModalControls> = React.memo(({
  onBack, onNext, disableNext
}) => (
  <div className={css.controls}>
    <Button
      type="button"
      buttonType="secondary"
      onClick={onBack}
    >
      {strings.actionLabelCancel}
    </Button>
    <div className={css.rightButtons}>
      <Tooltip title="Download report as a JSON file">
        <Button
          className={css.button}
          type="button"
          buttonType="primary"
          onClick={() => onNext('json')}
          disabled={disableNext}
        >
          JSON <Icon type={E_ICON_TYPE.export} width={12} height={12} />
        </Button>
      </Tooltip>
      <Tooltip title="Download report as a CSV file">
        <Button
          className={css.button}
          type="button"
          buttonType="primary"
          onClick={() => onNext('csv')}
          disabled={disableNext}
        >
          CSV <Icon type={E_ICON_TYPE.export} width={12} height={12} />
        </Button>
      </Tooltip>
    </div>
  </div>
));

const ExportOrdersModalControlsContainer: React.FC<IExportOrdersModalControls> = props => <ExportOrdersModalControls {...props} />;

export default ExportOrdersModalControlsContainer;
