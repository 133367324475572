/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconColorPicker(props: IIconProps) {
  // filling only bottom line
  const exceptOfFill = { ...props };
  delete exceptOfFill.fill;

  return (
    <svg {...exceptOfFill} width="24" height="24" viewBox="0 0 24 24" className="">
      <path d="M2 20H22C23.1 20 24 20.9 24 22C24 23.1 23.1 24 22 24H2C0.9 24 0 23.1 0 22C0 20.9 0.9 20 2 20Z" stroke="black" strokeWidth={0.75} strokeOpacity={0.5} fill={props.fill} />
      <path fill="#2C3E66" d="M10.63 3.93L6.06 15.58C5.79 16.26 6.29 17 7.03 17C7.46 17 7.85 16.73 8.01 16.32L8.87 14H15.12L15.99 16.32C16.14 16.73 16.53 17 16.97 17C17.7 17 18.21 16.26 17.94 15.58L13.37 3.93C13.14 3.37 12.6 3 12 3C11.4 3 10.85 3.37 10.63 3.93ZM9.62 12L12 5.67L14.38 12H9.62Z" />
    </svg>
  );
}

export default IconColorPicker;
