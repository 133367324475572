/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconText(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z"
        fill="#2C3E66"
      />
      <path d="M1.62109 15.3025H3.02734V17.5037H1.62109V15.3025Z" fill="#2C3E66" />
      <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
      <path d="M1.62109 6.49634H3.02734V8.69754H1.62109V6.49634Z" fill="#2C3E66" />
      <path d="M13.4755 17.4844V9.46645H16.5291V6.51538H7.47095V9.46645H10.5245V17.4844H13.4755Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconText;
