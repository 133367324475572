/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconExport(props: IIconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 16v6m0 0l3-3m-3 3l-3-3M4 6v6s0 3 7 3 7-3 7-3V6" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11 3c7 0 7 3 7 3s0 3-7 3-7-3-7-3 0-3 7-3zM11 21c-7 0-7-3-7-3v-6" />
    </svg>
  );
}

export default IconExport;
