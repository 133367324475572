/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconLogout(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 20H4V4H7M20 20V4L10 1V23L20 20ZM13 11.8V12.2H13.4V11.8H13Z" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconLogout;
