/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconLogoWithTextBlue(props: IIconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 147.92 32">
      <defs>
        <style>{'.cls-1{fill:#6b5ce9;}.cls-2{fill:url(#Unbenannter_Verlauf_14);}.cls-3{fill:url(#Unbenannter_Verlauf_13);}.cls-4{fill:url(#Unbenannter_Verlauf_10);}.cls-5{fill:url(#Unbenannter_Verlauf_7);}'}</style>
        <linearGradient
          id="Unbenannter_Verlauf_13"
          x1="18.25"
          y1="3.8"
          x2="5.38"
          y2="22.18"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6b5ce9" />
          <stop offset=".84" stopColor="#18d7de" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_10"
          x1="16.29"
          y1="7.62"
          x2="7.46"
          y2="20.24"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6b5ce9" />
          <stop offset="1" stopColor="#18d7de" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_14"
          x1="29.16"
          y1="5.58"
          x2="14.61"
          y2="26.36"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#6b5ce9" />
          <stop offset="1" stopColor="#18d7de" />
        </linearGradient>
        <linearGradient
          id="Unbenannter_Verlauf_7"
          x1="29.49"
          y1="14.01"
          x2="15.77"
          y2="33.61"
          gradientTransform="translate(-1.28 -7.78) rotate(-.22)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".07" stopColor="#6b5ce9" />
          <stop offset=".76" stopColor="#18d7de" />
        </linearGradient>
      </defs>
      <g>
        <path
          className="cls-1"
          d="M40.85,16.2c0-4.81,3.71-8.22,8.79-8.22s8.76,3.41,8.76,8.22-3.68,8.22-8.76,8.22-8.79-3.41-8.79-8.22Zm12.86,0c0-2.76-1.75-4.42-4.07-4.42s-4.1,1.66-4.1,4.42,1.78,4.42,4.1,4.42,4.07-1.66,4.07-4.42Z"
        />
        <path
          className="cls-1"
          d="M71.1,7.97v4.28c-.39-.03-.68-.06-1.04-.06-2.55,0-4.25,1.4-4.25,4.45v7.54h-4.63V8.21h4.42v2.11c1.13-1.54,3.03-2.35,5.49-2.35Z"
        />
        <path
          className="cls-1"
          d="M89.9,8.21v13.3c0,5.94-3.21,8.67-8.97,8.67-3.03,0-5.97-.74-7.84-2.2l1.84-3.33c1.37,1.1,3.59,1.81,5.64,1.81,3.27,0,4.69-1.48,4.69-4.36v-.68c-1.22,1.34-2.97,1.99-5.05,1.99-4.42,0-7.99-3.06-7.99-7.72s3.56-7.72,7.99-7.72c2.23,0,4.07,.74,5.29,2.29v-2.05h4.39Zm-4.57,7.48c0-2.35-1.75-3.92-4.19-3.92s-4.22,1.57-4.22,3.92,1.78,3.92,4.22,3.92,4.19-1.57,4.19-3.92Z"
        />
        <path
          className="cls-1"
          d="M108.04,15.07v9.12h-4.33v-1.99c-.86,1.45-2.52,2.23-4.87,2.23-3.74,0-5.97-2.08-5.97-4.84s1.99-4.78,6.86-4.78h3.68c0-1.99-1.19-3.15-3.68-3.15-1.69,0-3.44,.56-4.6,1.48l-1.66-3.24c1.75-1.25,4.33-1.93,6.89-1.93,4.87,0,7.69,2.26,7.69,7.1Zm-4.63,4.07v-1.63h-3.18c-2.17,0-2.85,.8-2.85,1.87,0,1.16,.98,1.93,2.61,1.93,1.54,0,2.88-.71,3.41-2.17Z"
        />
        <path
          className="cls-1"
          d="M117.01,7.88v.68h4.1v3.56h-3.98v12.06h-4.63V12.13h-2.46v-3.56h2.46v-.71c0-3.59,2.2-5.94,6.18-5.94,1.31,0,2.67,.27,3.53,.86l-1.22,3.36c-.5-.36-1.16-.56-1.87-.56-1.37,0-2.11,.74-2.11,2.32Zm11.05-5.73V24.18h-4.63V2.15h4.63Z"
        />
        <path
          className="cls-1"
          d="M147.92,8.21l-7.22,16.95c-1.54,3.86-3.74,5.02-6.62,5.02-1.63,0-3.41-.53-4.45-1.45l1.69-3.3c.71,.62,1.69,1.01,2.61,1.01,1.28,0,1.99-.56,2.61-1.99l.06-.15-6.92-16.09h4.78l4.48,10.84,4.51-10.84h4.45Z"
        />
      </g>
      <g>
        <path
          className="cls-3"
          d="M9.46,21.04c-.42,0-.85-.16-1.17-.48-2.22-2.2-3.27-5.33-2.97-8.83,.26-3.07,1.58-6.07,3.52-8.02C12.07,.44,19.76-2.09,26.73,2.5c.77,.51,.98,1.54,.47,2.31s-1.54,.98-2.31,.47c-5.39-3.56-11.25-1.69-13.68,.77-1.39,1.4-2.37,3.69-2.57,5.96-.12,1.44-.03,4.16,2,6.17,.65,.65,.66,1.7,.01,2.36-.33,.33-.76,.49-1.19,.49Z"
        />
        <path
          className="cls-4"
          d="M12.9,26.54c-.22,0-.44,0-.67-.02-3.07-.15-6.12-1.35-8.15-3.22C.69,20.2-2.13,12.61,2.21,5.47c.48-.79,1.5-1.04,2.29-.56,.79,.48,1.04,1.5,.56,2.29-3.35,5.52-1.27,11.3,1.28,13.65,2.65,2.43,8.84,3.72,12.15,.12,.62-.68,1.68-.72,2.36-.1,.68,.62,.72,1.68,.1,2.36-1.98,2.15-4.81,3.32-8.04,3.32Z"
        />
        <path
          className="cls-2"
          d="M13.08,32c-2.65,0-5.51-.72-8.25-2.54-.77-.51-.97-1.55-.46-2.31,.51-.77,1.55-.97,2.31-.46,5.38,3.58,11.24,1.74,13.69-.71,1.39-1.39,2.39-3.68,2.59-5.95,.13-1.44,.05-4.16-1.97-6.18-.65-.65-.65-1.71,0-2.36,.65-.65,1.71-.65,2.36,0,2.21,2.21,3.25,5.35,2.93,8.84-.28,3.06-1.61,6.06-3.56,8.01-1.97,1.97-5.57,3.66-9.65,3.66Z"
        />
        <path
          className="cls-5"
          d="M28.23,27.29c-.3,0-.61-.08-.88-.25-.79-.48-1.03-1.51-.55-2.29,3.38-5.51,1.33-11.3-1.21-13.65-1.44-1.34-3.76-2.25-6.04-2.37-1.45-.08-4.16,.1-6.1,2.19-.63,.68-1.68,.71-2.36,.09-.68-.63-.71-1.68-.09-2.36,2.12-2.29,5.22-3.45,8.73-3.26,3.07,.17,6.11,1.38,8.13,3.26,3.37,3.13,6.15,10.73,1.78,17.84-.31,.51-.85,.79-1.41,.8Z"
        />
      </g>
    </svg>
  );
}

export default IconLogoWithTextBlue;
