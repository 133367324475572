import React, {
  BaseSyntheticEvent,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';

import PageContent from 'components/library/PageContent';
import Pagination from 'components/library/Pagination';
import Loader from 'components/library/Loader';
import Table from 'components/library/Table';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import {
  BLOCK_FILTER_OPTIONS,
  MIN_PAGE, NAME_ORDER_OPTIONS,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TITLE_ORDER_OPTIONS,
} from 'constants/constants';
import strings from 'constants/localization';
import { TFilterBlocked, TPageSizeOptionsItem, TUser } from 'types';
import { SingleValue } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUsersFind } from 'hooks/useUser';
import UserContext from 'contexts/ContextUser';
import Select from 'components/library/Select';
import { ICallback } from 'components/library/Table/components/ActionHeader';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import { TSort } from 'components/library/Table/types';
import get from 'lodash/get';
import { Location } from 'history';
import { NavigateFunction } from 'react-router/lib/hooks';
import useColumns from './useColumns';
import css from './PageManagers.module.css';
import BlockUserModal from '../Modals/BlockUserModal';
import { navigateWithState, showEmptyState } from '../../helpers';
import useQueryParams from '../../hooks/useQueryParams';

export interface IStateParams {
  page?: number
  search: string
  order?: TSort
  limit?: TPageSizeOptionsItem
}

interface IManagers {
  state: IStateParams
  location: Location
  navigate: NavigateFunction
  pspId: string | undefined
}

const Managers: React.FC<IManagers> = props => {
  const {
    state,
    location,
    navigate,
    pspId,
  } = props;

  const [filterBlocked, setFilterBlocked] = useState<TFilterBlocked | undefined>(BLOCK_FILTER_OPTIONS[1]);

  const [limit, setLimit] = useState<{ label: number, value: number }>(state.limit || PAGE_SIZE_OPTIONS[0]);
  useEffect(() => setLimit(state.limit || PAGE_SIZE_OPTIONS[0]), [state.limit]);

  const [searchActive, setSearchActive] = useState<boolean>(!!state.search);
  const [search, setSearchText] = useState<string>(state.search);
  useEffect(() => {
    setSearchText(state.search);
    if (state.search) {
      setSearchActive(true);
    }
  }, [state.search]);

  const [page, setPage] = useState<number>(state.page || MIN_PAGE);
  useEffect(() => setPage(state.page || MIN_PAGE), [state.page]);

  const [userToBlock, setUserToBlock] = useState<TUser | undefined>(undefined);
  const onChangeLimit = useCallback((value:SingleValue<{ label: number, value: number}>) => {
    if (limit !== value) {
      setLimit(value || PAGE_SIZE_OPTIONS[0]);
      setPage(MIN_PAGE);
      navigateWithState(navigate, location, { page: MIN_PAGE, limit: value });
    }
  }, [limit, setLimit, setPage, location]);

  const onChangePage = useCallback((event: any, value:number) => {
    if (page !== value) {
      setPage(value);
      navigateWithState(navigate, location, { page: value });
    }
  }, [page, setPage, location]);

  const onClickRow = useCallback<ICallback>(id => navigate(id), [navigate]) as (id: string, row: unknown) => void;
  const onBlock = useCallback<ICallback>((id, user) => setUserToBlock(user), []);
  const closeBlockModal = useCallback(() => setUserToBlock(undefined), [setUserToBlock]);

  // TODO: set filters blocked
  const [order, setOrder] = useState<TSort>(state.order || NAME_ORDER_OPTIONS[2].value);
  useEffect(() => setOrder(state.order || TITLE_ORDER_OPTIONS[2].value), [state.order]);

  const limitValue = limit?.value || PAGE_SIZE_OPTIONS[0].value;

  const {
    data = { data: [], total: 0 },
    isLoading,
    error
  } = useUsersFind({
    includeCustomers: true,
    limit: limitValue,
    blocked: filterBlocked?.value,
    skip: (page - 1) * limitValue,
    search,
    role: 'manager',
    sort: order,
    pspId,
  });

  const handleSetOrder = useCallback((value: TSort): void => {
    setOrder(value);
    navigateWithState(navigate, location, { order: value });
  }, [location, setOrder]);

  const columns = useColumns({
    order, setOrder: handleSetOrder, filterBlocked, setFilterBlocked, onBlock,
  });

  const handleSearchTextChange = useCallback((e: BaseSyntheticEvent): void => {
    const { value } = e.target;
    setPage(MIN_PAGE);
    setSearchText(value);
    navigateWithState(navigate, location, { page: MIN_PAGE, search: value }, { replace: true });
  }, [setPage, setSearchText, location]);

  const toggleSearchActive = () => {
    setSearchActive(prev => !prev);
  };

  const onAddManagerClick = () => {
    navigate(ROUTES.ROUTE_ADD);
  };

  const disabled: boolean = isLoading || !!error;

  return (
    <PageContent pageTitle={strings.managersPageTitle} withBreadcrumbs>
      <div className={css.container}>
        <div className={css.header}>
          <Select isDisabled={disabled} className={css.select} value={limit} onChange={onChangeLimit} options={PAGE_SIZE_OPTIONS} isSearchable={false} />
          <div className={css.right}>
            <Input
              className={css.search}
              searchActive={searchActive}
              toggleSearchActive={toggleSearchActive}
              onChange={handleSearchTextChange}
              value={search}
            />
            <Button buttonType="primary" onClick={onAddManagerClick}>{strings.managersPageButtonAddManager}</Button>
          </div>
        </div>
        <div className={css.table}>
          <Table<TUser>
            meta={{ onClickRow }}
            data={data.data}
            columns={columns}
          />
        </div>
        {showEmptyState(!isLoading, data.data.length, !search) && (
          <div className={css.noContent}>
            <EmptyState
              label={strings.formatString(strings.emptyStateOnPageTitle, strings.managersPageTitle) as string}
              description={strings.formatString(strings.emptyStateOnPageDescription, <b>{strings.managersPageButtonAddManager}</b>) as string}
              variant={EmptyStates.NO_MANAGERS}
            >
              <Button buttonType="primary" onClick={onAddManagerClick}>{strings.managersPageButtonAddManager}</Button>
            </EmptyState>
          </div>
        )}
        {showEmptyState(!isLoading, data.data.length, !!search) && (
          <div className={css.noContent}>
            <EmptyState label={strings.emptyStateTitleNoResults} description={strings.emptyStateTitleNoResultsDescription} variant={EmptyStates.NO_RESULTS} />
          </div>
        )}
        {isLoading && <div className={css.loader}><Loader /></div> }
        <Pagination
          className={css.pagination}
          page={page}
          count={Math.ceil(data.total / limitValue) || undefined}
          onChange={onChangePage}
        />
        <BlockUserModal
          isOpen={!!userToBlock}
          closeModal={closeBlockModal}
          userToBlock={userToBlock}
        />
      </div>
    </PageContent>
  );
};

const Container = () => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const contextUser = useContext(UserContext);
  const pspId = get(contextUser, 'user.pspId');

  const params = useMemo<IStateParams>(() => ({
    page: parseInt(get(location.state, 'page', query.get('page')) || '', 10) || undefined,
    limit: get(location.state, 'limit'),
    search: get(location.state, 'search', ''),
    order: get(location.state, 'order'),
  }), [location, query]);

  return useMemo(() => (
    <Managers
      state={params}
      location={location}
      navigate={navigate}
      pspId={pspId}
    />
  ), [params, location, navigate, pspId]);
};

export default Container;
