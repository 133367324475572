/* eslint-disable max-len */
import ProfileStrings from '../types/ProfileStrings';
import { ILanguages } from '../types';

const strings:ILanguages<ProfileStrings> = {
  en: {
    profilePageTitleAdmin: 'Admin profile',
    profilePageTitleCustomer: 'Event profile',
    profilePageTitlePrinter: 'Printer profile',
    profilePageTitleManager: 'Manager profile',
    profilePageTitle: 'Profile',
    profileSidebarStatsSKUButton: 'View all SKUs',
    profileSidebarStatsOrderButton: 'View all Orders',

    profileSidebarStatsSKUTitle: 'SKUs',
    profileSidebarStatsOrdersTitle: 'Orders',
    profileSidebarUserDeleteModalConfirmation: 'Do you really want to delete this user?',

    profileTabPersonalInfo: 'Personal information',
    profileTabCompanyInfo: 'Company information',
    profileTabPermissions: 'Permissions',

    profilePageInputNameLabel: 'Name',
    profilePageInputLastNameLabel: 'Last Name',
    profilePageInputEventDatesLabel: 'Event Dates',
    profilePageInputPhoneNumberLabel: 'Phone Number',
    profilePageInputLanguageLabel: 'Language',

    profilePageInputCompanyNameLabel: 'Company name',
    profilePageInputPositionLabel: 'Position',
    profilePageInputSendOrderSheetsToLabel: 'Send order sheets to',

    profilePageInputChangeOrderSpecialLabel: 'Special (do not send to automatic printing)',
    profilePageInputChangeOrderStatusLabel: 'Allowed to change order status',
    profilePageInputReassignManagersLabel: 'Allowed to re-assign managers',
    profilePageInputViewOnlyOwnCustomersLabel: 'Allowed to view only own events',
    profilePageInputEditOnlyOwnCustomersLabel: 'Allowed to edit only own events',
    profilePageInputEditAndViewCustomersLabel: 'Allowed to edit and view events',

    profileSidebarInputFileCannotRead: 'Cannot read your file',
    profileSidebarInputFileOnlyImagesAllowed: 'Only images allowed',
    profileSidebarCopyTitle: 'Copy',
    profileSidebarCopySuccessTitle: 'Copied!',
    profileSidebarCopyFailureTitle: 'Oops, unable to copy',

    profileNotifySuccessUpdate: 'Profile has been successfully updated',
    profileNotifySuccessCreate: 'Profile has been successfully created',

    profileUserRegisterModalHeader: 'Awesome!',
    profileUserRegisterModalCustomerRegisteredMessage: 'Event successfully registered!',
    profileUserRegisterModalManagerRegisteredMessage: 'Manager successfully registered!',
    profileUserRegisterModalPrinterRegisteredMessage: 'Printer successfully registered!',
    profileUserRegisterModalAddCustomerButton: 'Add event',
    profileUserRegisterModalAddManagerButton: 'Add manager',
    profileUserRegisterModalAddPrinterButton: 'Add printer',

    profileUserRegisterModalCancelProfileButton: 'Profile',
    profileUserRegisterModalCancelCustomerButton: 'Events',
    profileUserRegisterModalCancelManagerButton: 'Managers',
    profileUserRegisterModalCancelPrinterButton: 'Printers',

    profileOpenChangePasswordModal: 'Change password',
    profileChangePasswordModalTitle: 'Change password',
    profileChangePasswordModalSuccessNotificationText: 'Password has been successfully changed!',
  },
  de: {
    profilePageTitleAdmin: 'Administrator-Profil',
    profilePageTitleCustomer: 'Veranstaltung',
    profilePageTitlePrinter: 'Druckerei-Profil',
    profilePageTitleManager: 'Veranstalter-Profil',
    profilePageTitle: 'Profil',
    profileSidebarStatsSKUButton: 'Zur Ticketübersicht',
    profileSidebarStatsOrderButton: 'Zur Bestellübersicht',

    profileSidebarStatsSKUTitle: 'Tickets',
    profileSidebarStatsOrdersTitle: 'Bestellungen',
    profileSidebarUserDeleteModalConfirmation: 'Wollen Sie diese Veranstaltung wirklich löschen?',

    profileTabPersonalInfo: 'Angaben zur Person',
    profileTabCompanyInfo: 'Angaben zur Firma',
    profileTabPermissions: 'Zugriffsrechte',

    profilePageInputNameLabel: 'Vorname',
    profilePageInputLastNameLabel: 'Nachname',
    profilePageInputEventDatesLabel: 'Datum',
    profilePageInputPhoneNumberLabel: 'Telefon',
    profilePageInputLanguageLabel: 'Sprache',

    profilePageInputCompanyNameLabel: 'Firmenname',
    profilePageInputPositionLabel: 'Position',
    profilePageInputSendOrderSheetsToLabel: 'Empfänger für Bestellunterlagen',

    profilePageInputChangeOrderStatusLabel: 'Bestellstatus ändern',
    profilePageInputReassignManagersLabel: 'Kundenbetreuer zuweisen',
    profilePageInputViewOnlyOwnCustomersLabel: 'Kundenkonto sehen',
    profilePageInputEditOnlyOwnCustomersLabel: 'Kundenkonto bearbeiten',
    profilePageInputEditAndViewCustomersLabel: 'Kundenkonto sehen und bearbeiten',

    profileSidebarInputFileCannotRead: 'Die Datei konnte nicht gelesen werden',
    profileSidebarInputFileOnlyImagesAllowed: 'Es werden nur Pixelbilder (JPG, PNG) unterstützt',
    profileSidebarCopyTitle: 'Kopieren',
    profileSidebarCopySuccessTitle: 'Kopiert!',
    profileSidebarCopyFailureTitle: 'Kopieren fehlgeschlagen',

    profileNotifySuccessUpdate: 'Das Profil wurde erfolgreich aktualisiert',
    profileNotifySuccessCreate: 'Veranstaltung erfolgreich angelegt',

    profileUserRegisterModalHeader: 'Hervorragend!',
    profileUserRegisterModalCustomerRegisteredMessage: 'Die Veranstaltung wurde erfolgreich angelegt.',
    profileUserRegisterModalManagerRegisteredMessage: 'Das Veranstalter-Konto wurde erfolgreich angelegt.',
    profileUserRegisterModalPrinterRegisteredMessage: 'Das Druckerei-Konto wurde erfolgreich angelegt.',
    profileUserRegisterModalAddCustomerButton: 'Weitere anlegen…',
    profileUserRegisterModalAddManagerButton: 'Kundenbetreuer anlegen',
    profileUserRegisterModalAddPrinterButton: 'Druckerei hinzufügen',

    profileUserRegisterModalCancelProfileButton: 'Profil',
    profileUserRegisterModalCancelCustomerButton: 'zur Übersicht',
    profileUserRegisterModalCancelManagerButton: 'Veranstalter',
    profileUserRegisterModalCancelPrinterButton: 'Drucker',

    profileOpenChangePasswordModal: 'Passwort ändern',
    profileChangePasswordModalTitle: 'Passwort ändern',
    profileChangePasswordModalSuccessNotificationText: 'Das Passwort wurde geändert',
  },
  ru: {
    profilePageTitleAdmin: 'Профиль администратора',
    profilePageTitleCustomer: 'Профиль мероприятия',
    profilePageTitlePrinter: 'Профиль принтера',
    profilePageTitleManager: 'Профиль менеджера',
    profilePageTitle: 'Профиль',
    profileSidebarStatsSKUButton: 'Все артикулы',
    profileSidebarStatsOrderButton: 'Все заказы',

    profileSidebarStatsSKUTitle: 'Артикулы',
    profileSidebarStatsOrdersTitle: 'Заказы',
    profileSidebarUserDeleteModalConfirmation: 'Вы действительно хотите удалить пользователя?',

    profileTabPersonalInfo: 'Личная информация',
    profileTabCompanyInfo: 'Информация о компании',
    profileTabPermissions: 'Права доступа',

    profilePageInputNameLabel: 'Имя',
    profilePageInputLastNameLabel: 'Фамилия',
    profilePageInputEventDatesLabel: 'Даты мероприятия',
    profilePageInputPhoneNumberLabel: 'Телефон',
    profilePageInputLanguageLabel: 'Язык',

    profilePageInputCompanyNameLabel: 'Название компании',
    profilePageInputPositionLabel: 'Должность',
    profilePageInputSendOrderSheetsToLabel: 'Отправить задание на печать на электронную почту',

    profilePageInputChangeOrderSpecialLabel: 'Специальный (не отправлять в автоматическую печать)',
    profilePageInputChangeOrderStatusLabel: 'Разрешено изменять статус заказа',
    profilePageInputReassignManagersLabel: 'Разрешено переназначать менеджеров',
    profilePageInputViewOnlyOwnCustomersLabel: 'Разрешено просматривать только собственные мероприятия',
    profilePageInputEditOnlyOwnCustomersLabel: 'Разрешено редактировать только собственные мероприятия',
    profilePageInputEditAndViewCustomersLabel: 'Разрешено просматривать и редактировать мероприятия',

    profileSidebarInputFileCannotRead: 'Не удается прочитать ваш файл',
    profileSidebarInputFileOnlyImagesAllowed: 'Можно загрузить только формат изображений (JPG, PNG)',
    profileSidebarCopyTitle: 'Копировать',
    profileSidebarCopySuccessTitle: 'Скопировано!',
    profileSidebarCopyFailureTitle: 'Невозможно скопировать',

    profileNotifySuccessUpdate: 'Профиль успешно обновлён',
    profileNotifySuccessCreate: 'Пользователь успешно зарегистрирован',

    profileUserRegisterModalHeader: 'Отлично!',
    profileUserRegisterModalCustomerRegisteredMessage: 'Мероприяние успешно зарегистрировано!',
    profileUserRegisterModalManagerRegisteredMessage: 'Менеджер успешно зарегистрирован!',
    profileUserRegisterModalPrinterRegisteredMessage: 'Принтер успешно зарегистрирован!',
    profileUserRegisterModalAddCustomerButton: 'Добавить мероприятие',
    profileUserRegisterModalAddManagerButton: 'Добавить менеджера',
    profileUserRegisterModalAddPrinterButton: 'Добавить принтера',

    profileUserRegisterModalCancelProfileButton: 'Профиль',
    profileUserRegisterModalCancelCustomerButton: 'Мероприятия',
    profileUserRegisterModalCancelManagerButton: 'Менеджеры',
    profileUserRegisterModalCancelPrinterButton: 'Принтеры',

    profileOpenChangePasswordModal: 'Изменить пароль',
    profileChangePasswordModalTitle: 'Изменение пароля',
    profileChangePasswordModalSuccessNotificationText: 'Пароль успешно изменён!',
  },
};

export default strings;
