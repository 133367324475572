import React, { FC } from 'react';

import { capitalizePhrase } from 'helpers';
import { EIntegrationServices, ERoles, TUser } from 'types';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import IntegrationConnect from './IntegrationConnect';
import IntegrationEventBindContainer from './IntegrationEventBind';
import EventBindInformation from './EventBindInformation';
import { getIntegrationServiceName } from '../helpers';

interface ITabIntegration {
  isSelf: boolean,
  currentUserRole: ERoles,
  targetUserId: string,
  targetName: TUser['name'],
  targetRole: ERoles,
  integrationService: EIntegrationServices | undefined,
  eventBind: TUser['eventBind'],
}

const TabIntegration: FC<ITabIntegration> = ({
  isSelf, targetRole, targetUserId, targetName, eventBind, currentUserRole, integrationService
}) => (
  <>
    <span className={textCss.pLight1}>{capitalizePhrase(strings.integration)}</span>
    {isSelf && currentUserRole === ERoles.CUSTOMER && (
      <EventBindInformation
        bindedEventName={eventBind?.eventName}
        connectedService={eventBind?.connection?.service}
      />
    )}
    {isSelf && [ERoles.PRINTER, ERoles.MANAGER].includes(currentUserRole) && <IntegrationConnect />}
    {!isSelf && targetRole === ERoles.MANAGER && (
      <div className={textCss.pMedium2}>
        {integrationService
          ? strings.formatString(strings.connectedToIntegrationService, getIntegrationServiceName(integrationService))
          : strings.managerNotConnectedToIntegrationService}
      </div>
    )}
    {!isSelf && targetRole === ERoles.CUSTOMER && (
      <IntegrationEventBindContainer
        userName={targetName}
        customerId={targetUserId}
        eventBind={eventBind}
      />
    )}
  </>
);

export default TabIntegration;
