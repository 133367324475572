import React from 'react';

import textCss from 'assets/styles/Text.module.css';
import Modal from 'components/library/Modal';
import strings from 'constants/localization';

interface IUnsavedArticleFormValuesModal {
  isOpen: boolean,
  onCloseModal: () => void,
  onOk: () => void,
}
const UnsavedArticleFormValuesModal: React.FC<IUnsavedArticleFormValuesModal> = ({
  isOpen, onCloseModal, onOk,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onCloseModal}
    onOk={onOk}
    onCancel={onCloseModal}
    title={strings.articlesSelectPrintItemUnsavedArticleFormValuesModalTitle}
    okLabel={strings.actionLabelOk}
    cancelLabel={strings.actionLabelCancel}
  >
    <span className={textCss.pMedium3}>
      {strings.articlesSelectPrintItemUnsavedArticleFormValuesModalBody}
    </span>
  </Modal>
);

export default UnsavedArticleFormValuesModal;
