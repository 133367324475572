import * as Yup from 'yup';

import {
  ELanguages, EPermissions, ERoles, TLocalizedConstString
} from 'types';
import strings from 'constants/localization';
import { E_ICON_TYPE } from '../library/Icon/types';

export enum ETabs {
  PERSONAL_INFO,
  COMPANY_INFO,
  PERMISSIONS,
  INTEGRATION,
}

export const TAB_COUNT: number = 2;

export const PERSONAL_INFO_VALIDATION_SCHEMA = (role: ERoles, isSelf: boolean) => Yup.object().shape({
  name: Yup.string()
    .required(strings.inputValidateRequiredField)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  surname: Yup.string()
    .required(strings.inputValidateRequiredField)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  email: Yup.string()
    .when('$role', {
      is: () => role !== ERoles.CUSTOMER || isSelf,
      then: Yup.string().required(strings.inputValidateRequiredField),
    })
    .email(strings.inputValidateEmail)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  phoneNumber: Yup.string()
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  lang: Yup.object()
    .required(strings.inputValidateRequiredField)
    .nullable()
});
export const COMPANY_INFO_VALIDATION_SCHEMA = () => Yup.object().shape({
  companyName: Yup.string()
    .required(strings.inputValidateRequiredField)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  position: Yup.string()
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  sendOrderSheetsTo: Yup.string()
    .required(strings.inputValidateRequiredField)
    .email(strings.inputValidateEmail)
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
});
export const getValidationSchema = (role: ERoles, isSelf: boolean) => Yup.object({
  PERSONAL_INFO_FORM: PERSONAL_INFO_VALIDATION_SCHEMA(role, isSelf),
  COMPANY_INFO_FORM: COMPANY_INFO_VALIDATION_SCHEMA(),
});

export interface IFormPersonalInfoValues {
  name: string,
  surname: string,
  email: string,
  phoneNumber: string,
  lang: Option,
}
export interface IFormCompanyInfoValues {
  companyName: string,
  position: string,
  sendOrderSheetsTo: string,
}
export type TFormCompanyKeys = keyof IFormCompanyInfoValues;
export type TFormPersonalKeys = keyof Omit<IFormPersonalInfoValues, 'lang'>;

export type TCompanyInfoDisabled = Record<TFormCompanyKeys, boolean>;

export interface IFormPermissionsValues {
  [EPermissions.VIEW_MANAGER_CUSTOMERS]: boolean,
  [EPermissions.EDIT_MANAGER_CUSTOMERS]: boolean,
  [EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS]: boolean,
  [EPermissions.CHANGE_ORDER_STATUS]: boolean,
  [EPermissions.ASSIGN_MANAGERS]: boolean,
}

export interface IFormSidebarValues {
  avatar: string
}

export interface IFormsValues {
  PERSONAL_INFO_FORM: IFormPersonalInfoValues,
  COMPANY_INFO_FORM: IFormCompanyInfoValues,
  PERMISSIONS_FORM: IFormPermissionsValues,
  SIDEBAR: IFormSidebarValues,
}

export type Option = {
  label: TLocalizedConstString,
  value: ELanguages,
  icon?: E_ICON_TYPE
}
export const PROFILE_LANGUAGE_OPTIONS: Option[] = [
  {
    label: () => strings.langEN,
    value: ELanguages.EN,
    icon: E_ICON_TYPE.flagUK,
  },
  {
    label: () => strings.langDE,
    value: ELanguages.DE,
    icon: E_ICON_TYPE.flagGe,
  },
  {
    label: () => strings.langRU,
    value: ELanguages.RU,
    icon: E_ICON_TYPE.flagRu,
  },
];
export const DEFAULT_LANGUAGE_OPTION: Option = PROFILE_LANGUAGE_OPTIONS[0];

export const statsButtonActions: TLocalizedConstString[] = [
  () => strings.profileSidebarStatsSKUButton,
  () => strings.profileSidebarStatsOrderButton,
];

export const statsTabsLabels = [
  {
    value: 'skus',
    label: () => strings.profileSidebarStatsSKUTitle
  },
  {
    value: 'orders',
    label: () => strings.profileSidebarStatsOrdersTitle
  },
];

export type TProfileLocationState = {
  isUserCreated?: boolean
}

export const PERSONAL_INFO_FORM: keyof IFormsValues = 'PERSONAL_INFO_FORM';
export const COMPANY_INFO_FORM: keyof IFormsValues = 'COMPANY_INFO_FORM';
export const PERMISSIONS_FORM: keyof IFormsValues = 'PERMISSIONS_FORM';
export const SIDEBAR_FORM: keyof IFormsValues = 'SIDEBAR';
