import React from 'react';
import useEditorData from '../../../hooks/useEditorData';
import { EViewportModes } from '../../../constants';
import Icon, { ICON_TYPES } from '../../../../../../library/Icon';
import css from './ModeButton.module.css';
import SidebarButton from '../../../../../TemplateEditorArticle/components/Sidebar/components/SidebarButton';

interface IModeButton {
  isDisabled: boolean,
}

const ModeButton:React.FC<IModeButton> = ({ isDisabled = true }) => {
  const {
    mode,
    setMode,
  } = useEditorData();

  const handleClick = () => {
    const newMode = (mode === EViewportModes.Edit) ? EViewportModes.Navigate : EViewportModes.Edit;
    setMode(newMode);
  };

  return (
    <SidebarButton
      isActive={mode === EViewportModes.Navigate}
      buttonType="transparent"
      disabled={isDisabled}
      onClick={handleClick}
      className={css.button}
    >
      <Icon type={ICON_TYPES.hand} />
    </SidebarButton>
  );
};

export default ModeButton;
