import React, { useRef, useState } from 'react';

import strings from 'constants/localization';
import { BLOCK_FILTER_OPTIONS } from 'constants/constants';
import { TFilterBlocked, TUser } from 'types';
import Icon from '../../../Icon';
import { E_ICON_TYPE } from '../../../Icon/types';
import Popover from '../../../Popover';
import Radio from '../../../Radio';
import css from './ActionHeader.module.css';
import SimpleHeader from '../SimpleHeader';

export interface ICallback {
  (id: string, user?: TUser, object?: any):void,
}
// todo: make generic
export type TOrder = {
  label: string,
  value: [string, number]
}

export interface IColumnsActions {
  order: TOrder,
  setOrder?: (v: TOrder) => void,
  onBlock: ICallback,
  filterBlocked?: TFilterBlocked,
  setFilterBlocked: (param?: TFilterBlocked) => void,
}

export interface IActionHeader {
  filterBlocked?: TFilterBlocked,
  setFilterBlocked: IColumnsActions['setFilterBlocked'],
  className: string
}
const ActionHeader = ({ filterBlocked, setFilterBlocked, className }: IActionHeader) => {
  const [state, setState] = useState<boolean>(false);
  const ref = useRef<SVGSVGElement>(null);
  const handleFilterChange = (value: TFilterBlocked) => {
    setFilterBlocked(value);
    setState(false);
  };

  return (
    <div className={className}>
      <SimpleHeader title={strings.columnHeadBlock} />
      <Icon className={css.showIcon} passedRef={ref} type={E_ICON_TYPE.filter} onClick={() => setState(!state)} />
      <Popover
        open={state}
        anchorEl={ref.current}
        onClose={() => setState(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Radio
          options={BLOCK_FILTER_OPTIONS}
          onChange={handleFilterChange}
          defaultValue={filterBlocked}
        />
      </Popover>
    </div>
  );
};

export default ActionHeader;
