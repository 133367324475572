/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconNotReadyToPrint(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5506 2C23.3367 2 22.3438 2.993 22.3438 4.2069V6.96552H26.7575V4.2069C26.7575 2.993 25.7645 2 24.5506 2Z" fill="#A6CBDF" />
      <path d="M6.8944 2C5.6805 2 4.6875 2.993 4.6875 4.2069V27H22.3427V4.2069C22.3427 2.993 23.3357 2 24.5496 2H6.8944Z" fill="#EAF7FD" />
      <path d="M20 5H7V6H20V5Z" fill="#A6CBDF" />
      <path d="M20.1367 8.86206H11.3091V9.96551H20.1367V8.86206Z" fill="#A6CBDF" />
      <path d="M20.1367 11.6207H11.3091V12.7242H20.1367V11.6207Z" fill="#A6CBDF" />
      <path d="M20.1367 14.3855L11.8609 14.3734L11.8592 15.4769L20.1351 15.489L20.1367 14.3855Z" fill="#A6CBDF" />
      <path d="M9.65234 8.86206H6.89372V9.96551H9.65234V8.86206Z" fill="#A6CBDF" />
      <path d="M9.65234 11.6207H6.89372V12.7242H9.65234V11.6207Z" fill="#A6CBDF" />
      <path d="M9.65234 14.3793H6.89372V15.4827H9.65234V14.3793Z" fill="#A6CBDF" />
      <path d="M20.1367 17.1379H11.8609V18.2414H20.1367V17.1379Z" fill="#A6CBDF" />
      <path d="M20.1367 19.8966H11.8609V21.0001H20.1367V19.8966Z" fill="#A6CBDF" />
      <path d="M20.1367 22.6552H11.8609V23.7586H20.1367V22.6552Z" fill="#A6CBDF" />
      <path d="M27.3099 24.9655C27.3099 27.708 25.0869 29.931 22.3444 29.931C19.602 29.931 17.3789 27.708 17.3789 24.9655C17.3789 22.2231 19.602 20 22.3444 20C25.0869 20 27.3099 22.2231 27.3099 24.9655Z" fill="#F3D08A" />
      <path d="M21.5 22H22.5V25H21.5V22Z" fill="#B77800" />
      <path d="M21.5 26V25H24.5V26H21.5Z" fill="#B77800" />
    </svg>
  );
}

export default IconNotReadyToPrint;
