import React, {
  ChangeEvent, FocusEvent, useCallback, useMemo
} from 'react';
import { SingleValue } from 'react-select';
import { FormikErrors, FormikTouched } from 'formik';
import cls from 'classnames';

import Button from 'components/library/Button';
import Input from 'components/library/Input';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Select from 'components/library/Select';

import {
  EPrintItemSizeStringFields,
  TPrintItemInformation,
  TPrintItemPricing,
  TPrintItemSize,
  TSelectUnit,
  EUnits,
} from 'types';
import { UNIT_OPTIONS } from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import css from './Sizes.module.css';
import {
  cmConverter, getPricingsAfterSizeChanged, mmConverter, sizeFieldEqualsName, SIZE_INPUTS
} from '../helpers';
import { generateInitialSize, getTouchedFieldError, trimOnBlur } from '../../../helpers';
import { ITEM_INFORMATION, NUMBER_WITH_COMMA_REGEX, PRICINGS } from '../../../constants';

interface ISizes {
  values: TPrintItemInformation,
  pricings: TPrintItemPricing[],
  errors: FormikErrors<TPrintItemSize[]> | undefined,
  handleChange: (e: ChangeEvent<any>) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
  touched: FormikTouched<TPrintItemInformation> | undefined,
  handleBlur: (e: ChangeEvent<any>) => void,
}

const SIZES_FIELD = `${ITEM_INFORMATION}.sizes`;
const UNITS_FIELD = `${ITEM_INFORMATION}.units`;

const Sizes: React.FC<ISizes> = ({
  values,
  pricings,
  errors,
  setFieldValue,
  handleChange,
  touched,
  handleBlur,
}) => {
  const { sizes, units } = values;
  const currentUnit = useMemo(
    () => UNIT_OPTIONS.find(unit => unit.value === units),
    [units]
  );

  const unitsSelectorDisabled = useMemo(
    () => sizes.some(size => !!size.id),
    [sizes.length]
  );

  const handleSizePropChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, idx: number, fieldName: string): void => {
      // update corresponding size in pricings
      const { value } = e.target;
      if (!sizeFieldEqualsName(fieldName) && !NUMBER_WITH_COMMA_REGEX.test(value)) {
        return;
      }
      const updatedSize = { ...sizes[idx], [fieldName]: value };
      const updatedPricings = getPricingsAfterSizeChanged(updatedSize.key!, updatedSize, pricings);
      setFieldValue(PRICINGS, updatedPricings);
      // update size field
      handleChange(e);
    },
    [sizes, pricings, setFieldValue, handleChange]
  );

  const sizesConverter = useCallback((converter:(value:string)=>string) => sizes.map(({
    height, bleed, width, ...rest
  }) => ({
    ...rest, width: converter(width), height: converter(height), bleed: converter(bleed)
  })), [sizes]);

  const handleUnitsChange = useCallback(
    (newUnit: SingleValue<TSelectUnit>) => {
      setFieldValue(UNITS_FIELD, newUnit!.value);
      if (newUnit?.value === EUnits.MM) {
        setFieldValue(SIZES_FIELD, sizesConverter(mmConverter));
      } else {
        setFieldValue(SIZES_FIELD, sizesConverter(cmConverter));
      }
    },
    [setFieldValue, sizesConverter]
  );

  const handleAddSize = useCallback(
    () => {
      const prevOrder = sizes.slice(-1)[0]?.order;
      const newSizes = [...sizes, generateInitialSize(typeof prevOrder === 'number' ? prevOrder + 1 : 0)];
      setFieldValue(SIZES_FIELD, newSizes);
    },
    [sizes, setFieldValue]
  );

  const handleRemoveSize = useCallback(
    (idx: number) => {
      const newSizes = sizes
        .filter((size, index) => index !== idx)
        .map((size, order) => ({ ...size, order }));
      setFieldValue(SIZES_FIELD, newSizes);
    },
    [sizes, setFieldValue]
  );

  const sizeConverter = useCallback((size:TPrintItemSize, unit: EUnits, converter:(value:string)=>string) => currentUnit?.value === unit ? {
    ...size, width: converter(size.width), height: converter(size.height), bleed: converter(size.bleed)
  }
    : size, [currentUnit?.value]);

  return (
    <div className={css.sizesContainer}>
      <div className={css.header}>
        <div className={css.left}>
          <p className={textCss.pLight1}>{strings.inputSizesLabel}</p>
          <div className={css.selectContainer}>
            <Select
              name={UNITS_FIELD}
              options={UNIT_OPTIONS}
              value={currentUnit}
              onChange={handleUnitsChange}
              isDisabled={unitsSelectorDisabled}
              isSearchable={false}
            />
          </div>
        </div>
        <Button
          type="button"
          buttonType="secondary"
          onClick={handleAddSize}
        >{strings.addSize}</Button>
      </div>
      {sizes.map((size, idx) => {
        const sizeInMM = sizeConverter(size, EUnits.CM, mmConverter);
        const sizeInCm = sizeConverter(size, EUnits.MM, cmConverter);
        const sizeValues = currentUnit?.value === EUnits.MM ? sizeInMM : sizeInCm;
        const removeSizeClick = () => handleRemoveSize(idx);
        return (
          <div
            key={size[EPrintItemSizeStringFields.KEY] || size[EPrintItemSizeStringFields.ID]}
            className={css.sizeItemContainer}
          >
            {SIZE_INPUTS.map(({ field, label, placeholder }, index) => {
              const sizeLabelIsName = sizeFieldEqualsName(field);
              const inputName = `${SIZES_FIELD}[${idx}][${field}]`;
              const sizeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => handleSizePropChange(e, idx, field);
              const onBlur = sizeLabelIsName
                ? (e: FocusEvent<HTMLInputElement>) => trimOnBlur({
                  e, inputName, handleBlur, setFieldValue
                })
                : handleBlur;
              return (
                <Input
                  key={field}
                  name={inputName}
                  value={sizeValues[field]}
                  inputClassName={css.input}
                  label={label()}
                  className={sizeLabelIsName ? css.nameInput : css.numberInput}
                  withError
                  error={getTouchedFieldError(!!touched?.sizes?.[idx]?.[field], errors?.[idx]?.[field])}
                  placeholder={placeholder()}
                  disabled={!!size.id}
                  onChange={sizeChangeHandler}
                  onBlur={onBlur}
                  symbol={index !== 0 && <span className={cls(css.symbol, textCss.pLight2)}>{currentUnit?.value}</span>}
                />
              );
            })}
            <Button
              type="button"
              buttonType="secondary"
              className={css.deleteBtn}
              disabled={sizes.length === 1 || !!size.id}
              onClick={removeSizeClick}
            >
              <Icon type={ICON_TYPES.delete} />
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export default Sizes;
