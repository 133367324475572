import React, { useMemo } from 'react';
import { SingleValue } from 'react-select';
import { ColumnDef } from '@tanstack/react-table';

import Select from 'components/library/Select';

import textCss from 'assets/styles/Text.module.css';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import _ from 'lodash';
import cls from 'classnames';
import strings from 'constants/localization';
import css from './OrderData.module.css';
import {
  EOrderDataIds, TExcelBindings, TExcelDataTableRow, TSelectMatchingFieldItem
} from '../../types';
import { getCellValue } from '../../helpers';

interface IUseOrderDataTable {
  bindings: TExcelBindings,
  setBindings: React.Dispatch<React.SetStateAction<TExcelBindings>>,
  data: TRow[],
}

const useOrderDataTable = ({ data, bindings, setBindings }: IUseOrderDataTable): ColumnDef<TExcelDataTableRow>[] => {
  const selectValues = useMemo<TSelectMatchingFieldItem[]>(
    () => data[0].map((cell, index) => ({ label: getCellValue(cell), value: index })),
    [data]
  );

  const onSelectFieldChange = (newValue: SingleValue<TSelectMatchingFieldItem>, objectId: string) => {
    const value = newValue ? newValue.value : null;
    setBindings(prevState => ({ ...prevState, [objectId]: value }));
  };

  const ordersCount = useMemo<number | null>(() => {
    const columnPosition = bindings[EOrderDataIds.ORDER_ID];
    if (columnPosition === null) {
      return null;
    }

    return _.uniq(
      data.slice(1)
        .map(row => row[columnPosition])
        .map(row => typeof row === 'string' ? row : row.value)
    ).length;
  }, [bindings[EOrderDataIds.ORDER_ID], data]);

  return useMemo<ColumnDef<TExcelDataTableRow>[]>(
    () => [
      {
        id: 'title',
        header: strings.editorExcelModalOrderDataValueColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { title, required } } }) => (
          <div className={textCss.pMedium2}>{title} { required && <span className={css.required}>*</span> }</div>
        )
      },
      {
        id: 'ticketField',
        header: strings.editorExcelModalOrderDataColumnColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { id } } }) => (
          <>
            <Select
              name="ticketField"
              className={css.select}
              options={selectValues}
              value={selectValues.find(value => value?.value === bindings[id])}
              onChange={v => onSelectFieldChange(v, id)}
              isClearable
            />
            { id === EOrderDataIds.ORDER_ID && ordersCount !== null && <div className={cls(textCss.pMedium3, css.fieldTitleType)}>{ ordersCount } orders</div> }
          </>
        ),
      },
      {
        id: 'value',
        header: strings.editorExcelModalOrderDataSampleColumn,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { value } } }) => <div className={css.sample}>{value}</div>
      },
    ],
    [selectValues, bindings, ordersCount]
  );
};

export default useOrderDataTable;
