import React, { useMemo, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import Popover from 'components/library/Popover';
import { Tooltip } from '@mui/material';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import classNames from 'classnames';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';
import css from './ColorPicker.module.css';

export type TColor = {
  name?: () => string,
  hex: string,
}

const COLORS: TColor[] = [
  { name: () => strings.editorColorPickerColorBlack, hex: '#000000' },
  { name: () => strings.editorColorPickerColorDimGrey, hex: '#696969' },
  { name: () => strings.editorColorPickerColorGrey, hex: '#808080' },
  { name: () => strings.editorColorPickerColorDarkGrey, hex: '#a9a9a9' },
  { name: () => strings.editorColorPickerColorSilver, hex: '#c0c0c0' },
  { name: () => strings.editorColorPickerColorLightGrey, hex: '#d3d3d3' },
  { name: () => strings.editorColorPickerColorGainsboro, hex: '#dcdcdc' },
  { name: () => strings.editorColorPickerColorWhite, hex: '#FFFFFF' },
  { name: () => strings.editorColorPickerColorCyan, hex: '#00FFFF' },
  { name: () => strings.editorColorPickerColorMagenta, hex: '#FF00FF' },
  { name: () => strings.editorColorPickerColorYellow, hex: '#FFFF00' },
  { name: () => strings.editorColorPickerColorRed, hex: '#FF0000' },
  { name: () => strings.editorColorPickerColorGreen, hex: '#00FF00' },
  { name: () => strings.editorColorPickerColorBlue, hex: '#0000FF' },
];

const ColorPicker:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    pdfDocument,
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = (newValue: string) => {
    if (activeObject && canvas.current) {
      activeObject.set({ fill: newValue });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const value = useMemo(() => activeObject.fill.toString(), [activeObject.fill]);

  const documentColors = useMemo(() => pdfDocument?.colors ?? [], [pdfDocument?.colors]);

  return (
    <>
      <Tooltip placement="top" title={strings.editorTabSettingsColorPickerTooltip} arrow>
        <SidebarButton
          ref={ref}
          isActive={popoverState}
          onClick={() => setPopoverState(!popoverState)}
        >
          <Icon type={ICON_TYPES.colorPicker} fill={value} />
        </SidebarButton>
      </Tooltip>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <div className={css.colorContainer}>
          <ColorGroup
            title={strings.editorTabSettingsColorPickerDefaultCategoryTitle}
            colors={COLORS}
            currentColor={activeObject?.fill}
            onChange={handleChange}
          />
          { documentColors && (
            <ColorGroup
              title={strings.editorTabSettingsColorPickerDocumentCategoryTitle}
              colors={documentColors}
              currentColor={activeObject?.fill}
              onChange={handleChange}
            />
          ) }
          <HexColorPicker className={css.picker} color={value} onChange={handleChange} />
        </div>
      </Popover>
    </>
  );
};

interface IColorGroup {
  title: string,
  colors: TColor[],
  currentColor: string,
  onChange: (hex: string) => void,
}

const ColorGroup:React.FC<IColorGroup> = ({
  title,
  colors,
  currentColor,
  onChange
}) => (
  <div className={css.colorGroup}>
    <div className={css.colorTitle}>{title}</div>
    <div className={css.colorList}>
      {colors.map(color => (
        <Tooltip
          key={color.hex}
          title={color?.name ? color.name() : ''}
          disableHoverListener={!color.name}
          disableFocusListener={!color.name}
          placement="top"
          arrow
        >
          <Button
            type="button"
            buttonType="tertiary"
            buttonStyle="circle"
            className={classNames(css.button, { [css.active]: color.hex === currentColor })}
            style={{ backgroundColor: color.hex }}
            onClick={() => onChange(color.hex)}
          >&nbsp;</Button>
        </Tooltip>
      ))}
    </div>
  </div>
);

export default ColorPicker;
