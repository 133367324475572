import React, { BaseSyntheticEvent } from 'react';

import Button from 'components/library/Button';
import Icon, { ICON_TYPES } from 'components/library/Icon';

import { IPortfolioDeleteActions } from 'types/Portfolio';

interface IPortfolioControls {
  printItemId: string,
}

const PortfolioControls: React.FC<IPortfolioControls & IPortfolioDeleteActions> = ({
  printItemId, toggleModal, setIdToDelete
}) => {
  const handlePrintItemDelete = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIdToDelete(printItemId);
    toggleModal();
  };

  return (
    <Button
      type="button"
      buttonType="secondary"
      buttonStyle="circle"
      onClick={handlePrintItemDelete}
    >
      <Icon type={ICON_TYPES.delete} />
    </Button>
  );
};

export default PortfolioControls;
