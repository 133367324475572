import { NavLink } from 'react-router-dom';
import React, {
  BaseSyntheticEvent,
  useCallback, useEffect, useMemo, useState,
} from 'react';
import sumBy from 'lodash/sumBy';
import { NavigateFunction } from 'react-router/lib/hooks';
import { Location } from 'history';
import {
  EOrderStatus, ERoles, TOrder, TPageSizeOptionsItem, TUser,
} from 'types';
import strings from 'constants/localization';
import PageContent from 'components/library/PageContent';
import { ICallback } from 'components/library/Table/components/ActionHeader';
import { TSort } from 'components/library/Table/types';
import { IOrdersFindInput, useOrderPatch, useOrdersFind } from 'hooks/useOrders';
import {
  MIN_PAGE, PAGE_SIZE_OPTIONS, ROUTES, TITLE_ORDER_OPTIONS
} from 'constants/constants';
import Tabs, { TOnChange } from 'components/library/Tabs';
import { isCustomer, navigateWithState, showEmptyState } from 'helpers';
import { IAlertContext } from 'contexts/ContextAlert';
import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import Table from 'components/library/Table';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Button from 'components/library/Button';
import Loader from 'components/library/Loader';
import Pagination from 'components/library/Pagination';
import _groupBy from 'lodash/groupBy';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';
import { nanoid } from 'nanoid';
import { SingleValue } from 'react-select';
import cls from 'classnames';
import {
  DATE_ORDER_OPTIONS, getTabValue, TABS, createTableSubRows
} from './helper';
import useColumns from './useColumns';
import css from './Orders.module.css';
import ModalReject from './components/ModalReject';
import ExportOrders from './components/ExportOrders';
import Select from '../library/Select';

export type TOrderTableItem = {
  id: string,
  subRows?: TOrder[],
} & TOrder;

export interface IStateParams {
  customerId?: string,
  managerId?: string,
  status?: EOrderStatus,
  page?: number,
  search: string,
  order?: TSort
  limit?: TPageSizeOptionsItem
}

export interface IOrders {
  state: IStateParams,
  pspId: string,
  role: ERoles,
  navigate: NavigateFunction
  location: Location,
  alertContext: IAlertContext,
  user: Partial<TUser> | null
}

function Orders({
  state, pspId, role, navigate, location, alertContext, user
}:IOrders) {
  const {
    customerId,
    managerId,
  } = state;

  // const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  // const toggleIsModalOpened = useCallback(() => setIsModalOpened(prevState => !prevState), [setIsModalOpened]);

  const [searchActive, setSearchActive] = useState<boolean>(!!state.search);
  const [search, setSearch] = useState(state.search);
  useEffect(() => {
    setSearch(state.search);
    if (state.search) {
      setSearchActive(true);
    }
  }, [state.search]);

  const [modalReject, setRejectModal] = useState<{ id: string, value: EOrderStatus } | null>(null);

  const [limit, setLimit] = useState<TPageSizeOptionsItem>(state.limit || PAGE_SIZE_OPTIONS[0]);
  useEffect(() => setLimit(state.limit || PAGE_SIZE_OPTIONS[0]), [state.limit]);

  const [page, setPage] = useState<number>(state.page || MIN_PAGE);
  useEffect(() => setPage(state.page || MIN_PAGE), [state.page]);

  const [tabIndex, setTabIndex] = useState((state.status && TABS.findIndex(tab => tab.value === state.status)) || 0);
  useEffect(() => setTabIndex((state.status && TABS.findIndex(tab => tab.value === state.status)) || 0), [state.status]);

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const onChangeTab = useCallback<TOnChange>((e, index) => {
    setExpanded({});
    setTabIndex(index);
    setPage(MIN_PAGE);
    navigateWithState(navigate, location, { page: MIN_PAGE, status: getTabValue(index) });
  }, [setTabIndex, location, setExpanded]);
  const isNotCustomer = useMemo<boolean>(
    () => !isCustomer(user?.userPermissions || []),
    [user]
  );

  const onToggleSearchActive = useCallback(() => setSearchActive(prev => !prev), [setSearchActive]);
  const onChangePage = useCallback((event:any, value:number) => {
    if (page !== value) {
      setExpanded({});
      setPage(value);
      navigateWithState(navigate, location, { page: value });
    }
  }, [page, setPage, location, setExpanded]);

  const onSearch = useCallback((e: BaseSyntheticEvent): void => {
    const { value } = e.target;
    setPage(MIN_PAGE);
    setSearch(value);
    navigateWithState(navigate, location, { page: MIN_PAGE, search: value }, { replace: true });
  }, [setPage, setSearch, location]);

  const handleSetLimit = useCallback(
    (value: SingleValue<TPageSizeOptionsItem>) => {
      if (limit !== value) {
        setLimit(value || PAGE_SIZE_OPTIONS[0]);
        setPage(MIN_PAGE);
        navigateWithState(navigate, location, { page: MIN_PAGE, limit: value });
      }
    },
    [limit, setPage, setLimit, location]
  );

  const [order, setOrder] = useState<TSort>(state.order || DATE_ORDER_OPTIONS[0].value);
  useEffect(() => setOrder(state.order || TITLE_ORDER_OPTIONS[2].value), [state.order]);

  const patchOrder = useOrderPatch();

  const changeStatus = useCallback((value: EOrderStatus, id: string, reason?: string) => patchOrder
    .mutateAsync({ status: value, id, reason })
    .then(() => alertContext.push({ severity: 'success', message: strings.orderPageNotificationUpdateStatus }))
    .catch(err => alertContext.push({ severity: 'error', message: err.message })), [patchOrder]);

  const onChangeStatus = useCallback((value: EOrderStatus, id: string) => {
    if (value === EOrderStatus.ISSUE) {
      setRejectModal({ id, value });
    } else {
      changeStatus(value, id);
    }
  }, []);

  const onRejectFromModal = useCallback((reason: string) => {
    if (modalReject) {
      changeStatus(modalReject.value, modalReject.id, reason);
    }
    setRejectModal(null);
  }, [modalReject]);

  const handleSetOrder = useCallback((value: TSort): void => {
    setOrder(value);
    navigateWithState(navigate, location, { order: value });
  }, [location, setOrder]);

  const columns = useColumns({
    role, order, setOrder: handleSetOrder, changeStatus: onChangeStatus
  });
  const limitValue = useMemo<number>(
    () => limit?.value || PAGE_SIZE_OPTIONS[0].value,
    [limit]
  );

  const query = useMemo<IOrdersFindInput>(() => ({
    limit: limitValue,
    skip: (page - 1) * limitValue,
    search,
    pspId,
    status: getTabValue(tabIndex),
    sort: order,
    customerId,
    managerId,
    grouped: true,
  }), [page, limitValue, search, tabIndex, pspId, customerId, managerId, order]);
  const queryTabs = useMemo<IOrdersFindInput>(() => ({
    pspId,
    managerId,
    customerId,
    aggregate: true
  }), [pspId, customerId, managerId]);

  const { data = { data: [], total: 0 }, isLoading, error } = useOrdersFind(query);
  const { data: { aggregates } = { aggregates: [] } } = useOrdersFind(queryTabs);

  const ordersData = useMemo(
    (): TOrderTableItem[] => {
      const ordersByGroupId: { [key: string]: TOrderTableItem[] } = _groupBy(data.data, ({ groupId }) => groupId ?? nanoid());
      return createTableSubRows(ordersByGroupId);
    },
    [data.data]
  );

  const onClickRow = useCallback<ICallback>(
    (id, _, object) => object.getCanExpand() ? object.toggleExpanded() : navigate(id),
    [navigate]
  ) as (id: string, row: unknown, object: unknown) => void;

  const tabs = useMemo(() => {
    const counts = aggregates;
    if (Array.isArray(counts)) {
      const sum = sumBy(counts, 'count');
      return TABS.map(tab => {
        const { count } = counts.find(t => t.status === tab.value) || { count: tab.value === 'ALL' ? sum : 0 };
        return {
          ...tab,
          count
        };
      });
    }
    return TABS;
  }, [aggregates]);

  const disabled: boolean = isLoading || !!error;

  return (
    <PageContent pageTitle={strings.ordersPageTitle}>
      <div className={css.container}>
        <div>
          <Tabs
            orientation="vertical"
            tabs={tabs}
            value={tabIndex}
            onChange={onChangeTab}
          />
        </div>
        <div>
          <div className={css.header}>
            <span className={cls(textCss.pLight1, css.title)}>
              {TABS[tabIndex].label()}
              <Select
                className={css.select}
                value={limit}
                options={PAGE_SIZE_OPTIONS}
                isDisabled={disabled}
                onChange={handleSetLimit}
                isSearchable={false}
              />
            </span>
            <div className={css.right}>
              <Input
                className={css.search}
                searchActive={searchActive}
                placeholder={strings.inputSearchTextPlaceholder}
                toggleSearchActive={onToggleSearchActive}
                value={search}
                onChange={onSearch}
              />
              {/* <Button buttonType="primary" onClick={toggleIsModalOpened}>{strings.orderPlaceOrderButton}</Button> */}
              {/* <PlaceOrder isOpen={isModalOpened} toggleOpen={toggleIsModalOpened} /> */}
              { role === ERoles.PRINTER && <ExportOrders /> }
            </div>
          </div>
          <div className={css.table}>
            <Table<TOrderTableItem>
              columns={columns}
              data={ordersData}
              meta={{ onClickRow }}
              state={{ expanded }}
              onExpandedChange={setExpanded}
              getSubRows={row => row.subRows}
              getExpandedRowModel={getExpandedRowModel()}
            />
          </div>
          {showEmptyState(!isLoading, data.data.length, !search) && (
            <div className={css.noContent}>
              <EmptyState
                label={strings.formatString(strings.emptyStateOnPageTitle, strings.ordersPageTitle)as string}
                description={isNotCustomer
                  ? strings.formatString(strings.orderPageEmptyStateDescription, <b>{strings.articlesSKU}</b>)as string
                  : ''}
                variant={EmptyStates.NO_ORDERS}
              >
                {isNotCustomer && (
                  <NavLink to={`${ROUTES.BASE}${ROUTES.CUSTOMERS}`}>
                    <Button
                      type="button"
                      buttonType="primary"
                    >
                      {strings.customersPageButtonViewAllCustomer}
                    </Button>
                  </NavLink>
                )}
              </EmptyState>
            </div>
          )}
          {showEmptyState(!isLoading, data.data.length, !!search) && (
            <div className={css.noContent}>
              <EmptyState label={strings.emptyStateTitleNoResults} description={strings.emptyStateTitleNoResultsDescription} variant={EmptyStates.NO_RESULTS} />
            </div>
          )}
          {isLoading && <div className={css.loader}><Loader /></div> }
          <Pagination
            className={css.pagination}
            disabled={disabled}
            page={page}
            count={Math.ceil(data.total / limitValue) || undefined}
            onChange={onChangePage}
          />
        </div>
      </div>
      <ModalReject
        isOpen={!!modalReject}
        onRequestClose={() => setRejectModal(null)}
        onOk={onRejectFromModal}
      />
    </PageContent>
  );
}

export default Orders;
