import { useMutation, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { TArticle, TArticleCreateUpdate } from 'types';
import { useInvalidateFindArticles } from './useFindArticles';
import { GET_ARTICLE_QUERY, useInvalidateGetArticle } from './useGetArticle';
import { getArticleGetQuery } from '../../helpers';
import { useInvalidateUserGet, useInvalidateUsersFind } from '../useUser';

const client = instance.getClient();

export const UPDATE_ARTICLE_QUERY = ['articles', 'update'];

export const updateArticle = (
  articleId: string,
  article: TArticleCreateUpdate,
  pspId?: string,
  includeBase?: boolean
): Promise<TArticle> => {
  const query: string = getArticleGetQuery({ pspId, includeBase });
  return client.put(`/articles/${articleId}${query}`, article).then(res => res.data);
};

const useArticleUpdate = (articleId: string, customerId?: string, pspId?: string, includeBase?: boolean) => {
  const rqClient = useQueryClient();
  const invalidateArticles = useInvalidateFindArticles();
  const invalidateGetArticle = useInvalidateGetArticle();
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TArticle, IResponseError, TArticleCreateUpdate>(
    [...UPDATE_ARTICLE_QUERY],
    data => updateArticle(articleId, data, pspId, includeBase),
    {
      onSuccess: data => {
        if (includeBase) {
          rqClient.setQueryData([...GET_ARTICLE_QUERY, customerId, pspId, articleId], data);
        } else {
          invalidateGetArticle(customerId as string, pspId as string, articleId);
        }
        invalidateArticles();
        invalidateUsers();
        invalidateUserGet();
      },
    }
  );
};

export default useArticleUpdate;
