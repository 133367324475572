import React, {
  createContext, useMemo, useState,
} from 'react';
import { IArticleFormValues } from 'components/PageArticles/AddEditArticle/components/ArticleForm/constants';
import { TPrintItemsItem } from 'types';

export interface IArticleContext {
  selectedPrintItem: TPrintItemsItem | null,
  setSelectedPrintItem: React.Dispatch<React.SetStateAction<TPrintItemsItem | null>>,
  articleFormValues: IArticleFormValues | null,
  setArticleFormValues: React.Dispatch<React.SetStateAction<IArticleFormValues | null>>,
  articleFormInitialValues: IArticleFormValues | null,
  setArticleFormInitialValues: React.Dispatch<React.SetStateAction<IArticleFormValues | null>>,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>,
}
const ArticleContext = createContext<IArticleContext>({
  selectedPrintItem: null,
  setSelectedPrintItem: () => {},
  articleFormValues: null,
  setArticleFormValues: () => {},
  articleFormInitialValues: null,
  setArticleFormInitialValues: () => {},
  tabIndex: 0,
  setTabIndex: () => {},
});

export const ArticleContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedPrintItem, setSelectedPrintItem] = useState<TPrintItemsItem | null>(null);
  const [articleFormValues, setArticleFormValues] = useState<IArticleFormValues | null>(null);
  const [articleFormInitialValues, setArticleFormInitialValues] = useState<IArticleFormValues | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const value: IArticleContext = useMemo(() => ({
    selectedPrintItem,
    setSelectedPrintItem,
    articleFormValues,
    setArticleFormValues,
    articleFormInitialValues,
    setArticleFormInitialValues,
    tabIndex,
    setTabIndex,
  }), [selectedPrintItem, articleFormValues, tabIndex, articleFormInitialValues]);

  return (
    <ArticleContext.Provider value={value}>
      {children}
    </ArticleContext.Provider>
  );
};

export default ArticleContext;
