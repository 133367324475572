import React, {
  FC, forwardRef, useMemo, useState
} from 'react';
import cls from 'classnames';

import textCss from 'assets/styles/Text.module.css';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Label, { Props as LabelProps } from 'components/library/Label';
import css from './Input.module.css';
import Tooltip from '../Tooltip';

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement>, React.TextareaHTMLAttributes<HTMLInputElement> {
  withError?: boolean,
  error?: string,
  customLabel?: JSX.Element,
  label?: string,
  description?: string,
  reducedWidth?: boolean,
  reducedHeight?: boolean,
  inputClassName?: string,
  correct?: boolean,
  labelProps?: LabelProps,
  searchActive?: boolean,
  toggleSearchActive?: () => void,
  customAdornment?: React.ReactNode,
  symbol?:React.ReactNode,
  isMultiple?: boolean,
  withTooltip?: boolean,
}

const Error:FC<{ text?: string }> = ({ text }) => <span className={cls(textCss.pMedium2, css.error)}>{text}</span>;
const Description:FC<{ text?: string }> = ({ text }) => <span className={cls(textCss.pMedium3, css.description)}>{text}</span>;

const Input = forwardRef<HTMLInputElement, IInput>((props, ref) => {
  const {
    labelProps,
    customLabel,
    correct,
    type,
    description,
    withError,
    error,
    label,
    reducedHeight,
    reducedWidth,
    className,
    inputClassName,
    searchActive,
    toggleSearchActive,
    customAdornment,
    symbol,
    isMultiple = false,
    withTooltip = false,
    ...rest
  } = props;
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const isPassword = type === 'password';
  const isSearch = typeof searchActive === 'boolean';

  const inputType = useMemo<React.HTMLInputTypeAttribute | undefined>(() => {
    if (isPassword && passwordVisible) {
      return 'text';
    }
    return type;
  }, [type, isPassword, passwordVisible]);

  const showPassword = () => {
    setPasswordVisible(value => !value);
  };

  const inputClasses: string = cls(
    textCss.pMedium2,
    css.input,
    {
      [css.reducedHeight]: reducedHeight,
      [css.reducedWidth]: reducedWidth,
      [css.inputError]: withError && error,
      [css.inputAdornment]: isPassword,
      [css.inputCorrect]: correct,
      [css.inputSearchNotActive]: isSearch && !searchActive,
      [css.inputSearchActive]: isSearch && searchActive,
      [css.textAreaInput]: isMultiple
    },
    inputClassName,
  );

  const inputComponent: JSX.Element = isMultiple
    ? (
      <textarea
        className={inputClasses}
        {...rest as React.TextareaHTMLAttributes<HTMLTextAreaElement>}
      />
    )
    : (
      <input
        ref={ref}
        type={inputType}
        className={inputClasses}
        {...rest}
      />
    );

  return (
    <div className={cls(css.wrapper, className)}>
      {customLabel || null}
      {!customLabel && label ? <Label text={label as string} {...labelProps} /> : null}
      <div className={css.inputContainer}>
        {withTooltip
          ? (
            <Tooltip title={rest.value as string} placement="top" className={css.tooltip}>
              {inputComponent}
            </Tooltip>
          )
          : inputComponent}
        {customAdornment}
        {isPassword && (
          <div className={css.adornment} onClick={showPassword}>
            <Icon type={passwordVisible ? ICON_TYPES.openEye : ICON_TYPES.closedEye} />
          </div>
        )}
        {(correct && isPassword) && (
          <div className={css.correct}>
            <Icon type={ICON_TYPES.correct} />
          </div>
        )}
        {(isSearch && toggleSearchActive) && (
          <div
            className={cls(css.search, {
              [css.searchNotActive]: isSearch && !searchActive,
              [css.searchToggleDisabled]: isSearch && rest?.disabled,
            })}
            onClick={toggleSearchActive}
          >
            <Icon type={ICON_TYPES.search} />
          </div>
        )}
        {symbol && (
          <div className={cls(css.search, css.symbol)}>
            {symbol}
          </div>
        )}
      </div>
      <div className={css.helperTextsContainer}>
        {withError ? <Error text={error} /> : null}
        {description ? <Description text={description} /> : null}
      </div>
    </div>
  );
});

export default Input;
