import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { ActionMeta, MultiValue } from 'react-select';

import Label from 'components/library/Label';
import strings from 'constants/localization';
import Input from 'components/library/Input';
import ArrayCheckbox from 'components/library/Checkbox/ArrayCheckbox';
import cls from 'classnames';
import { getFieldError, getFieldName, ITabProductSetup } from '../../index';
import { matchingsExists, TFormikQuantity } from '../../../../constants';
import css from './PaperAndQuantity.module.css';
import { TProductSetupDisabled } from '../../../../helpers';
import QtiesSelect from './QtiesSelect';

export interface IPaperAndQuantity {
  formik: ITabProductSetup['formik'],
  article: ITabProductSetup['article'],
  disabled: TProductSetupDisabled | undefined,
}
const PaperAndQuantity: React.FC<IPaperAndQuantity> = ({
  formik, article, disabled
}) => {
  const {
    getFieldProps, setFieldValue,
    touched: { productSetup: touched },
    errors: { productSetup: errors },
    values: { productSetup: values, template }
  } = formik;

  const onChange = (
    remainedQties: MultiValue<TFormikQuantity>,
    actionMeta: ActionMeta<TFormikQuantity>
  ): void => {
    const newQuantities = cloneDeep(values.quantities);
    const qty = actionMeta.removedValue;

    const changedQty = newQuantities
      .find(({ qtyId, paperId }) => qtyId === qty?.qtyId && paperId === qty?.paperId);
    if (changedQty) {
      changedQty.isSelected = !changedQty.isSelected;
    }
    setFieldValue(getFieldName('quantities'), newQuantities);
  };

  return (
    <>
      <div className={cls(css.commentContainer, css.negativeMargin)}>
        <Label text={strings.paper} />
        <Input
          {...getFieldProps(getFieldName('paperComment'))}
          disabled={disabled?.paperComment}
          withError
          error={getFieldError('paperComment', touched, errors)}
          className={css.input}
          label={strings.inputCommentLabel}
          type="text"
          placeholder={strings.inputCommentLabel}
          labelProps={{ optional: true }}
        /> {/* TODO make comment field closable */}
      </div>
      {article.printItem.papers.map(({ id, value }) => (
        <div key={id}>
          <ArrayCheckbox
            {...getFieldProps(getFieldName('selectedPapers'))}
            valueId={id}
            disabled={disabled?.selectedPapers[id]}
            label={value}
            withError
            error={getFieldError('selectedPapers', touched, errors)}
            wrapperClassName={css.paperCheckbox}
          />
          {values.selectedPapers.includes(id) && !matchingsExists(null, template) && (
            <>
              <Label text={strings.inputQuantityLabel} />
              <div className={cls(css.commentContainer, css.qtiesSelectContainer)}>
                {[true, false].map(selected => (
                  <div key={id + selected} className={css.input}>
                    <QtiesSelect
                      formik={formik}
                      paperId={id}
                      selected={selected}
                      onChange={onChange}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default PaperAndQuantity;
