import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import FilterHeader, { IActionHeader } from 'components/library/Table/components/FilterHeader';
import PortfolioControls from 'components/PagePortfolio/components/PortfolioControls';

import { EPrintItemNestedFields, TPrintItemsItem } from 'types';
import { IPortfolioDeleteActions } from 'types/Portfolio';
import strings from 'constants/localization';
import { TITLE_ORDER_OPTIONS } from 'constants/constants';
import { displayQuantitiesAndPapers } from 'helpers';
import css from './PagePortfolio.module.css';
import SimpleHeader from '../library/Table/components/SimpleHeader';
import Tooltip from '../library/Tooltip';

const useColumns = ({
  order, setOrder, ...deleteActions
}: IPortfolioDeleteActions & Pick<IActionHeader, 'order' | 'setOrder'>): ColumnDef<TPrintItemsItem>[] => useMemo<ColumnDef<TPrintItemsItem>[]>(
  () => [
    {
      id: 'title',
      header: () => (
        <FilterHeader
          orderOptions={TITLE_ORDER_OPTIONS}
          order={order}
          className={css.columnHeadFilter}
          title={strings.columnHeadProductName}
          setOrder={setOrder}
        />
      ),
      meta: {
        className: css.column
      },
      cell: props => {
        const { title } = props.row.original;
        return (
          <div className={css.centeredCell}>
            <Tooltip title={title} placement="top">
              <span>{title}</span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      id: 'quantities',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadQuantities} /></div>,
      meta: {
        className: css.column
      },
      cell: props => {
        const qties = displayQuantitiesAndPapers(props.row.original, EPrintItemNestedFields.QUANTITIES);
        return (
          <div className={css.centeredCell}>
            <Tooltip title={qties} placement="top">
              <span>{qties}</span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      id: 'papers',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadPaper} /></div>,
      meta: {
        className: css.column
      },
      cell: props => {
        const papers = displayQuantitiesAndPapers(props.row.original, EPrintItemNestedFields.PAPERS);
        return (
          <div className={css.centeredCell}>
            <Tooltip title={papers} placement="top">
              <span>{papers}</span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      id: 'description',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadDescription} /></div>,
      meta: {
        className: css.column
      },
      cell: props => {
        const { description } = props.row.original;
        return (
          <div className={css.centeredCell}>
            <Tooltip title={description} placement="top">
              <span>{description}</span>
            </Tooltip>
          </div>
        );
      }
    },
    {
      id: 'delete',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadDelete} /></div>,
      meta: {
        className: css.column
      },
      cell: props => <PortfolioControls printItemId={props.row.original.id} {...deleteActions} />
    },
  ],
  [deleteActions.setIdToDelete, order]
);

export default useColumns;
