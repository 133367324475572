import React, {
  useContext, useEffect, useMemo, useState
} from 'react';

import Modal from 'components/library/Modal';
import { ERoles, TUser } from 'types';
import UserContext from 'contexts/ContextUser';
import strings from 'constants/localization';
import AlertContext, { IAlertContext } from 'contexts/ContextAlert';
import { FormikHelpers, useFormik } from 'formik';
import { useGetEvents, useGetOrganizations } from 'hooks/Integration/useEventbrite';
import Loader from 'components/library/Loader';
import { useCreateIntegrationEventBind, useGetIntegrationEventBinds } from 'hooks/Integration/useIntegrationEventBind';
import { useUserCreate } from 'hooks/useUser';
import { IResponseError } from 'constants/types';
import { createTargetUser } from 'helpers';
import { IEventbriteErrorRes } from 'types/Integration';
import AddEventModalControls from './components/AddEventModalControls';
import AddEventModalForm, { EVENT, ORGANIZATION } from './components/AddEventModalForm';
import { DisplayEmptyState, DisplayError } from './helpers';

interface IAddEventModalContainer {
  isOpen: boolean,
  toggleOpen: () => void,
}

const AddEventModalContainer: React.FC<IAddEventModalContainer> = props => {
  const { isOpen } = props;

  const { push } = useContext(AlertContext);
  const user = useContext(UserContext).user as TUser;

  return useMemo(
    () => isOpen ? <AddEventModal {...props} push={push} user={user} /> : null,
    [push, user, props]
  );
};

type TSelectValue = {
  label: string,
  value: string,
}

export interface IFormikFormData {
  organization: TSelectValue | null,
  event: TSelectValue| null,
}

interface IAddEventModal {
  isOpen: boolean
  toggleOpen: () => void
  push: IAlertContext['push']
  user: TUser
}

const AddEventModal: React.FC<IAddEventModal> = props => {
  const {
    isOpen,
    toggleOpen,
    push,
    user,
  } = props;

  const integrationToken = useMemo<string | null | undefined>(() => user?.integration?.token, [user]);
  const connectionId = useMemo<string | null | undefined>(() => user?.integration?.id, [user]);
  const [customerId, setCustomerId] = useState<string|null>(null);

  const {
    data: eventBindsData,
    isFetching: isFetchingEventBinds,
  } = useGetIntegrationEventBinds({ connectionId });

  const { mutateAsync: createEventBind } = useCreateIntegrationEventBind({ customerId, connectionId });

  const {
    data: organizationsData,
    isFetching: isFetchingOrganizations,
    error: organizationsError
  } = useGetOrganizations(integrationToken);

  const onBindEvent = async (data: IFormikFormData, options: FormikHelpers<IFormikFormData>) => {
    const { event, organization } = data;
    if (event && organization) {
      const { label: eventName, value: eventId } = event;
      const { value: organizationId } = organization;

      options.setSubmitting(true);

      await createEventBind({ eventId, eventName, organizationId })
        .then(() => {
          push({ severity: 'success', message: strings.successEventBind });
          setCustomerId(null);
          closeModal();
        })
        .catch(err => {
          const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
          push({ severity: 'error', message: errMsg });
        })
        .finally(() => {
          options.setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: { [ORGANIZATION]: null, [EVENT]: null } as IFormikFormData,
    onSubmit: onBindEvent,
  });

  useEffect(() => {
    if (customerId) {
      formik.submitForm();
    }
  }, [customerId, formik.submitForm]);

  const { organization, event } = useMemo(() => formik.values, [formik.values]);
  const organizationId = useMemo(() => organization?.value || null, [organization]);

  const {
    data: eventsData,
    isFetching: isFetchingEvents,
    error: eventsError
  } = useGetEvents(integrationToken, organizationId);

  const isSubmitDisabled = useMemo(
    () => formik.isSubmitting || isFetchingOrganizations || isFetchingEvents || isFetchingEventBinds || !event,
    [formik.isSubmitting || isFetchingOrganizations || isFetchingEvents, isFetchingEventBinds, event]
  );

  const isFormDisabled = useMemo(() => !!customerId || isFetchingEvents, [customerId, isFetchingEvents]);

  const userCreate = useUserCreate();
  const onSave = (): void => {
    if (organization && event && user.email) {
      const { label: name } = event;
      const { label: companyName } = organization;
      const { email: sendOrderSheetsTo, id: managerId } = user;

      const dataToSend: Partial<TUser> = {
        name, companyName, sendOrderSheetsTo, managerId
      };

      const targetUser = createTargetUser(ERoles.CUSTOMER, dataToSend, user);
      userCreate.mutate(targetUser, {
        onSuccess: (customer: TUser): void => {
          setCustomerId(customer.id);
          push({ severity: 'success', message: strings.profileNotifySuccessCreate });
        },
        onError: (err: IResponseError): void => {
          const message = err.response?.data.data?.reason || strings.errorResponse5;
          push({ severity: 'error', message });
        },
      });
    }
  };

  const closeModal = (): void => {
    toggleOpen();
    formik.resetForm();
  };

  if (!integrationToken) {
    return <DisplayEmptyState />;
  }

  const error = useMemo<IEventbriteErrorRes | null>(
    () => organizationsError || eventsError || null,
    [organizationsError, eventsError]
  );

  if (error) {
    return <DisplayError error={error} push={push} />;
  }

  return (
    <Modal
      isOpen={isOpen}
      title={strings.eventsPageAddNewEventModalTitle}
      customControls={(
        <AddEventModalControls
          closeModal={closeModal}
          onSave={onSave}
          disabled={isFormDisabled}
          disabledSubmit={isSubmitDisabled}
        />
      )}
      onRequestClose={closeModal}
    >
      {
        isFetchingEventBinds || isFetchingOrganizations
          ? (<Loader />)
          : (
            <AddEventModalForm
              formik={formik}
              disabled={isFormDisabled}
              organizations={organizationsData}
              events={eventsData}
              eventBinds={eventBindsData}
            />
          )
      }
    </Modal>
  );
};

export default AddEventModalContainer;
