/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFlagPt(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7474_44907)">
        <path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#C41C1C" />
        <path d="M9.60938 0.238159V23.7614C4.12734 22.6533 0 17.8083 0 12C0 6.19175 4.12734 1.34628 9.60938 0.238159Z" fill="#02934D" />
        <path d="M11.9998 0C5.37257 0 0 5.37237 0 12.0002C0 18.6274 5.37257 24 11.9998 24C18.6274 24 18.6274 0 11.9998 0Z" fill="#02934D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.1433 0.00378418H11.7656C11.0413 0.0170226 10.3196 0.0954645 9.60938 0.238159V23.7619C10.3819 23.918 11.1813 24 11.9998 24C18.5793 24 18.6271 0.347564 12.1433 0.00378418Z" fill="#DB1B1B" />
        <path d="M9.60937 4.14661C8.60355 4.14661 7.62031 4.44484 6.78397 5.00361C5.94764 5.56238 5.29576 6.35658 4.91076 7.28581C4.52577 8.21503 4.42494 9.23755 4.62103 10.2241C4.81712 11.2106 5.30132 12.1168 6.01241 12.8282C6.7235 13.5395 7.62956 14.0241 8.61601 14.2205C9.60246 14.417 10.625 14.3165 11.5544 13.9319C12.4837 13.5472 13.2782 12.8956 13.8373 12.0595C14.3963 11.2234 14.6949 10.2402 14.6953 9.23442C14.6942 7.88571 14.158 6.59254 13.2045 5.63869C12.251 4.68483 10.9581 4.14822 9.60937 4.14661ZM13.9781 8.88098H10.4583L10.5286 8.81067L11.8083 7.53051L12.9455 6.39333C13.5436 7.09275 13.9051 7.96355 13.9781 8.88098ZM9.96093 4.86379C10.8355 4.93363 11.6687 5.26577 12.3516 5.81676L12.4453 5.89551L9.96093 8.38317V4.86379ZM9.25781 4.86379V8.38317L9.13125 8.25707L7.725 6.84848L6.7725 5.89551L6.86625 5.81676C7.54932 5.26559 8.38286 4.93345 9.25781 4.86379ZM6.27328 6.39333L7.41094 7.53051L8.69062 8.81254L8.76093 8.88286H5.24063C5.31346 7.96482 5.67493 7.09338 6.27328 6.39333ZM5.24203 9.58411H8.76234L8.27484 10.0711L7.60312 10.7424L6.9 11.4455L6.27516 12.0708C5.67698 11.3716 5.31492 10.5013 5.24063 9.58411H5.24203ZM9.25921 13.6008C8.3422 13.5278 7.47171 13.1668 6.77203 12.5696L7.3064 12.0347L7.99781 11.3438L9.25781 10.0829L9.25921 13.6008ZM9.96234 13.6008V10.0805L12.45 12.5686C11.7499 13.1666 10.8787 13.5279 9.96093 13.6008H9.96234ZM12.9469 12.0713L11.6189 10.7429L10.9481 10.0735L10.4583 9.58411H13.9781C13.9046 10.5013 13.5432 11.3718 12.9455 12.0713H12.9469Z" fill="#F6C752" />
        <path d="M6.9375 5.8125C6.83187 5.81262 6.73059 5.85464 6.6559 5.92934C6.5812 6.00403 6.53919 6.1053 6.53906 6.21094V10.3331H6.55641C6.63682 11.0868 6.99311 11.7841 7.55672 12.2908C8.12033 12.7976 8.85144 13.078 9.60938 13.0781C10.3673 13.0781 11.0985 12.7977 11.6621 12.291C12.2258 11.7842 12.582 11.0868 12.6623 10.3331H12.6797V6.20859C12.6791 6.10333 12.6368 6.00259 12.5622 5.92837C12.4875 5.85415 12.3865 5.8125 12.2812 5.8125H6.9375Z" fill="#E83838" />
        <path d="M7.79539 6.8439C7.69344 6.8439 7.59568 6.88439 7.52359 6.95648C7.45151 7.02856 7.41101 7.12633 7.41101 7.22827V10.0783H7.4232C7.48006 10.6185 7.73489 11.1186 8.13857 11.4821C8.54225 11.8457 9.06621 12.0469 9.60945 12.047C10.1527 12.0469 10.6768 11.8457 11.0805 11.4822C11.4843 11.1187 11.7392 10.6186 11.7962 10.0783H11.8084V7.22593C11.8084 7.12399 11.7679 7.02622 11.6958 6.95413C11.6237 6.88205 11.5259 6.84155 11.424 6.84155L7.79539 6.8439Z" fill="white" />
        <path d="M9.16833 7.28687C9.14347 7.28687 9.11963 7.29674 9.10204 7.31432C9.08446 7.33191 9.07458 7.35575 9.07458 7.38062V8.07437H9.07787C9.09186 8.2056 9.15389 8.32703 9.25202 8.4153C9.35015 8.50356 9.47745 8.55242 9.60943 8.55249C9.74149 8.55254 9.86891 8.50373 9.96713 8.41545C10.0654 8.32718 10.1275 8.20568 10.1415 8.07437H10.1443V7.38062C10.1443 7.35575 10.1344 7.33191 10.1168 7.31432C10.0992 7.29674 10.0754 7.28687 10.0505 7.28687H9.16833Z" fill="#3E436D" />
        <path d="M9.16833 10.4237C9.14347 10.4237 9.11963 10.4336 9.10204 10.4511C9.08446 10.4687 9.07458 10.4926 9.07458 10.5174V11.2117H9.07787C9.09197 11.3428 9.15405 11.4641 9.25216 11.5523C9.35028 11.6405 9.47752 11.6893 9.60943 11.6893C9.74149 11.6894 9.86891 11.6405 9.96713 11.5523C10.0654 11.464 10.1275 11.3425 10.1415 11.2112H10.1443V10.517C10.1443 10.4921 10.1344 10.4683 10.1168 10.4507C10.0992 10.4331 10.0754 10.4232 10.0505 10.4232L9.16833 10.4237Z" fill="#3E436D" />
        <path d="M9.16833 8.81262C9.14347 8.81262 9.11963 8.8225 9.10204 8.84008C9.08446 8.85766 9.07458 8.88151 9.07458 8.90637V9.60012H9.07787C9.09186 9.73136 9.15389 9.85279 9.25202 9.94105C9.35015 10.0293 9.47745 10.0782 9.60943 10.0782C9.74149 10.0783 9.86891 10.0295 9.96713 9.94121C10.0654 9.85294 10.1275 9.73144 10.1415 9.60012H10.1443V8.90637C10.1443 8.88151 10.1344 8.85766 10.1168 8.84008C10.0992 8.8225 10.0754 8.81262 10.0505 8.81262H9.16833Z" fill="#3E436D" />
        <path d="M7.90271 8.81262C7.87785 8.81262 7.854 8.8225 7.83642 8.84008C7.81884 8.85766 7.80896 8.88151 7.80896 8.90637V9.60012H7.81224C7.82624 9.73136 7.88827 9.85279 7.98639 9.94105C8.08452 10.0293 8.21182 10.0782 8.3438 10.0782C8.47587 10.0783 8.60328 10.0295 8.70151 9.94121C8.79974 9.85294 8.86183 9.73144 8.87584 9.60012H8.87865V8.90637C8.87865 8.88151 8.86877 8.85766 8.85119 8.84008C8.83361 8.8225 8.80976 8.81262 8.7849 8.81262H7.90271Z" fill="#3E436D" />
        <path d="M10.4574 8.81262C10.4325 8.81262 10.4087 8.8225 10.3911 8.84008C10.3735 8.85766 10.3636 8.88151 10.3636 8.90637V9.60012H10.3669C10.3809 9.73136 10.443 9.85279 10.5411 9.94105C10.6392 10.0293 10.7665 10.0782 10.8985 10.0782C11.0306 10.0783 11.158 10.0295 11.2562 9.94121C11.3544 9.85294 11.4165 9.73144 11.4305 9.60012H11.4333V8.90637C11.4333 8.88151 11.4235 8.85766 11.4059 8.84008C11.3883 8.8225 11.3644 8.81262 11.3396 8.81262H10.4574Z" fill="#3E436D" />
      </g>
      <defs>
        <clipPath id="clip0_7474_44907">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFlagPt;
