import JsBarcode, { Ean13Options } from 'jsbarcode';
import { string } from 'yup';
import strings from 'constants/localization';
import { svgToString } from './svg';
import { createCodePlaceholder } from './codePlaceholder';

const TEXT_SCHEMA = string()
  .required()
  .matches(/^[0-9]+$/)
  .min(12)
  .max(12);

enum BarcodeFormats {
  EAN13 = 'EAN13',
}

const defaultOptions: Ean13Options = {
  margin: 1,
  width: 2,
  height: 100,
  format: BarcodeFormats.EAN13,
};

export default async function createBarCode(text: string, options?: Ean13Options): Promise<string> {
  const opt: Ean13Options = {
    ...defaultOptions,
    ...options
  };

  try {
    const validText = await TEXT_SCHEMA.validate(text) ?? '';

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    JsBarcode(svg, validText, opt);
    return svgToString(svg);
  } catch (e) {
    const margin = (opt.margin ?? 1) * 2;
    const width = (opt.width ?? 2) * 107 + margin;
    const height = (opt.height ?? 100) + 22 + margin;

    const svg = createCodePlaceholder(strings.errorEditorBarcodeInvalidContent, width, height);
    return svgToString(svg);
  }
}
