import { IFindResponse, IResponseError } from 'constants/types';
import { STALE_TIME } from 'constants/constants';
import instance from 'lib/apiClient';
import {
  EOrderStatus, IPaginationParams, TOrder
} from 'types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInvalidateUserGet, useInvalidateUsersFind } from './useUser';

const client = instance.getClient();
interface IOrderGetInput {
  orderId?: string,
  customerId?: string,
  pspId?: string,
  managerId?: string,
  status?: EOrderStatus,
}
export const getOrder = ({ orderId }:IOrderGetInput):Promise<TOrder> => client
  .get(`/orders-new/${orderId}?includeBase=1`)
  .then(res => res.data);

export const QUERY_KEY_GET = ['orders', 'get'];
export function useGetOrder(data:IOrderGetInput) {
  return useQuery<TOrder, IResponseError>(
    [...QUERY_KEY_GET, data?.orderId],
    () => getOrder(data),
    {
      enabled: !!data.orderId,
    }
  );
}
export function useInvalidateOrderGet() {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_GET, ...additionalParams]);
}

export interface IOrdersFindInput extends IPaginationParams, Omit<IOrderGetInput, 'orderId'> {
  search?: string,
  aggregate?: boolean,
  grouped?: boolean,
}
export const findOrders = ({
  pspId, limit, skip, search, sort, managerId, customerId, status, aggregate, grouped
}:IOrdersFindInput):Promise<IFindResponse<TOrder[]>> => {
  const query = ['?'];
  if (aggregate) {
    query.push('aggregate=1');
  }
  if (grouped) {
    query.push('grouped=1');
  }
  if (pspId) {
    query.push(`pspId=${pspId}`);
  }
  if (managerId) {
    query.push(`managerId=${managerId}`);
  }
  if (customerId) {
    query.push(`customerId=${customerId}`);
  }
  if (search) {
    query.push(`search=${search}`);
  }
  if (status) {
    query.push(`status=${status}`);
  }
  if (limit) {
    query.push(`%24limit=${limit}`);
  }
  if (skip) {
    query.push(`%24skip=${skip}`);
  }
  if (sort) {
    query.push(`%24sort[${sort[0]}]=${sort[1]}`);
  } else {
    query.push('%24sort[createdAt]]=-1');
  }
  return client.get(`/orders-new${query.join('&')}`).then(res => res.data);
};

export const QUERY_KEY_FIND = ['orders', 'find'];
export function useOrdersFind(data:IOrdersFindInput) {
  return useQuery<IFindResponse<TOrder[]>, IResponseError>(
    [...QUERY_KEY_FIND, data.limit, data.skip, data.sort, data.status,
      data.search, data.pspId, data.customerId, data.managerId, data.aggregate, data.grouped],
    () => findOrders(data),
    {
      refetchInterval: STALE_TIME,
      staleTime: !data.status ? 0 : STALE_TIME
    }
  );
}
export function useInvalidateOrdersFind() {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_FIND, ...additionalParams]);
}

type TOrderCreate = Pick<TOrder, 'paperId' | 'quantityId' | 'orderItems' | 'orderComment'
  | 'price' | 'status' | 'options' | 'currency' | 'article' | 'pspId' | 'customerId' >
export function createOrder(order: TOrderCreate):Promise<TOrder> {
  return client.post('/orders-new', order).then(res => res.data);
}
export const QUERY_KEY_CREATE = ['orders', 'create'];
export function useOrderCreate() {
  const invalidateOrders = useInvalidateOrdersFind();
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TOrder, IResponseError, TOrderCreate>(
    QUERY_KEY_CREATE,
    data => createOrder(data),
    {
      retry: false,
      onSuccess: () => {
        invalidateOrders();
        invalidateUsers();
        invalidateUserGet();
      }
    }
  );
}

export function patchOrder(order: Partial<TOrder>):Promise<TOrder> {
  return client.patch(`/orders-new/${order.id}`, order).then(res => res.data);
}
export const QUERY_KEY_PATCH = ['orders', 'patch'];
export function useOrderPatch() {
  const invalidateOrders = useInvalidateOrdersFind();
  const invalidateOrder = useInvalidateOrderGet();
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TOrder, IResponseError, Partial<TOrder>>(
    QUERY_KEY_PATCH,
    data => patchOrder(data),
    {
      retry: false,
      onSuccess: async data => {
        await Promise.all(
          [
            invalidateOrders(),
            invalidateOrder(data.id),
            invalidateUsers(),
            invalidateUserGet(),
          ]
        );
        return data;
      }
    }
  );
}
