/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconMail(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.1735 5H3.82647C2.81774 5 2 5.81774 2 6.82647C2 7.44185 2.30988 8.01586 2.82437 8.35349L4.01043 9.13184L10.354 13.2948C11.3534 13.9506 12.6466 13.9506 13.646 13.2948L19.9896 9.13184L21.1756 8.35349C21.6901 8.01586 22 7.44185 22 6.82647C22 5.81774 21.1823 5 20.1735 5ZM2.4762 11.75C2.4762 11.1834 2.63328 10.6535 2.90627 10.2014L9.53105 14.5489C11.03 15.5326 12.97 15.5326 14.469 14.5489L21.0937 10.2014C21.3667 10.6535 21.5238 11.1834 21.5238 11.75V18C21.5238 19.1046 20.6284 20 19.5238 20H4.4762C3.37163 20 2.4762 19.1046 2.4762 18V11.75Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconMail;
