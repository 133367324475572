import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import cls from 'classnames';
import useSize from '@react-hook/size';

import textCss from 'assets/styles/Text.module.css';
import fabric from 'components/PageArticles/components/Editor/helpers/fabric';
import {
  ERoles,
  TArticleDocument, TParam,
} from 'types';
import Viewport from 'components/PageArticles/components/Editor/components/Viewport/Viewport';
import { EViewportModes } from 'components/PageArticles/components/Editor/constants';
import strings from 'constants/localization';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import ScaleSelector from 'components/PageArticles/components/Editor/components/Toolbar/components/ScaleSelector/ScaleSelector';
import useFabricObjects from 'components/PageArticles/components/Editor/hooks/useFabricObjects/useFabricObjects';
import UserContext from 'contexts/ContextUser';
import css from './Preview.module.css';
import OrderContext from '../../ContextOrder';
import { filterObjectPropertiesByType } from '../../helper';

interface IPdfBox {
  label: string,
  pdf: boolean,
  link?: string
  doc?: TArticleDocument,
  items?: TParam[],
}

const viewportDefault = {
  width: 300,
  height: 280,
  zoom: 0, // fit
};

const PdfBox:React.FC<IPdfBox> = ({
  label, pdf, items = [], doc, link
}) => {
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const { setObjects } = useFabricObjects(canvasRef);
  const ref = useRef(null);
  const [width, height] = useSize(ref);
  const [zoom, setZoom] = useState<number>(viewportDefault.zoom);
  const viewport = useMemo(() => ({
    ...viewportDefault,
    width: width || viewportDefault.width,
    height: height || viewportDefault.height,
    zoom
  }), [width, height, zoom]);

  const canvas = useMemo<TArticleDocument['canvas'] | undefined>(() => {
    if (doc?.canvas && items?.length) {
      return {
        ...doc.canvas,
        objects: items
      };
    }
    return doc?.canvas;
  }, [doc?.canvas]);

  useEffect(() => {
    if (items.length && canvasRef.current) {
      const objects = items.map(filterObjectPropertiesByType);
      setObjects(objects, doc?.canvas.objects);
    }
  }, [items, canvasRef, doc?.canvas]);

  return (
    <div className={css.view}>
      { pdf && doc ? (
        <div className={css.viewActions}>
          <ScaleSelector
            zoom={viewport.zoom}
            setZoom={setZoom}
          />
          {link && (
            <a
              role="button"
              aria-label="pdf"
              href={link}
              className={css.pdfButton}
              target="_blank"
              rel="noreferrer"
            >
              <Icon type={E_ICON_TYPE.pdf} />
            </a>
          )}
        </div>
      ) : null }
      <div className={css.viewContent} ref={ref}>
        {pdf && doc && canvas
          ? (
            <Viewport
              canvas={canvasRef}
              mode={EViewportModes.Navigate}
              viewport={viewport}
              setObjects={() => {}}
              setCanvasLoadingStatus={() => {}}
              json={canvas}
              pdfDocument={doc.document}
            />
          )
          : (
            <div className={css.viewNoPdf}>
              <Icon type={E_ICON_TYPE.noPdf} />
              <span className={textCss.pMedium2}>{strings.orderPageViewNoBacksideTitle}</span>
            </div>
          )}
      </div>
      <div className={cls(textCss.h4, css.viewTitle)}>{label}</div>
    </div>
  );
};

interface IPreview {
  backSide?: TArticleDocument,
  frontSide?: TArticleDocument,
  itemsBackSide?: TParam[],
  itemsFrontSide?: TParam[],
  linkToFront?: string,
  linkToBack?: string,
}
const Preview:React.FC<IPreview> = ({
  itemsBackSide,
  itemsFrontSide,
  frontSide,
  backSide,
  linkToFront,
  linkToBack
}) => (
  <div className={css.container}>
    <div className={css.views}>
      <div className={css.left}>
        <PdfBox
          link={linkToFront}
          pdf={!!frontSide}
          doc={frontSide}
          items={itemsFrontSide}
          label={strings.orderPageViewFrontsideTitle}
        />
      </div>
      <div className={css.right}>
        <PdfBox
          link={linkToBack}
          pdf={!!backSide}
          doc={backSide}
          items={itemsBackSide}
          label={strings.orderPageViewBacksideTitle}
        />
      </div>
    </div>
  </div>
);

const PreviewContainer = () => {
  const { state } = useContext(OrderContext);
  const { user } = useContext(UserContext);
  const isCustomer = user?.role === ERoles.CUSTOMER;
  const {
    orderSheetReady, linkToFront, linkToBack, selectedOrderItem, backSide, frontSide
  } = state;
  const itemsBackSide = selectedOrderItem?.items.filter(item => backSide?.canvas.objects.some(obj => obj.id === item.id)) || [];
  const itemsFrontSide = selectedOrderItem?.items.filter(item => frontSide?.canvas.objects.some(obj => obj.id === item.id)) || [];
  const itemId = selectedOrderItem?.id;
  let frontLink: string | undefined;
  let backLink: string | undefined;
  if (orderSheetReady && !isCustomer) {
    frontLink = linkToFront;
    backLink = linkToBack;
  }
  return useMemo(() => (
    <Preview
      key={itemId}
      itemsBackSide={itemsBackSide}
      itemsFrontSide={itemsFrontSide}
      backSide={backSide}
      frontSide={frontSide}
      linkToFront={frontLink}
      linkToBack={backLink}
    />
  ), [itemId, itemsFrontSide, itemsBackSide, frontSide, backSide]);
};

export default PreviewContainer;
