import React, { useContext, useRef } from 'react';
import SidebarButton from 'components/PageArticles/TemplateEditorArticle/components/Sidebar/components/SidebarButton';
import strings from 'constants/localization';
import { ECanvasObjectTypes } from 'types';
import ContextAlert from 'contexts/ContextAlert';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import useEditorData from '../../../hooks/useEditorData';
import { EViewportModes } from '../../../constants';
import css from './AddImageObjectButton.module.css';
import { fileToDataUrl } from '../../../helpers/file';
import ImageObject from '../../Viewport/fabric/imageObject';
import getUniqueNameNumber from '../../../helpers/uniqueObjectName';

export enum EImageTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

const ACCEPTED_IMAGE_TYPES: EImageTypes[] = [
  EImageTypes.PNG,
  EImageTypes.JPEG,
  EImageTypes.JPG,
];

const ACCEPTED_IMAGE_TYPES_INPUT = ACCEPTED_IMAGE_TYPES.join(', ');

interface IAddImageObjectButton {
  isDisabled: boolean,
}

const AddImageObjectButton:React.FC<IAddImageObjectButton> = ({ isDisabled = true }) => {
  const {
    setMode,
    canvas,
    objects,
    setObjects,
    pdfDocument,
    setIsChanged,
    checkDpiCurrentObjects,
  } = useEditorData();

  const { push } = useContext(ContextAlert);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (canvas.current && pdfDocument && inputRef.current) {
      setMode(EViewportModes.Edit);
      canvas.current.discardActiveObject();
      inputRef.current.click();
    }
  };

  const handleChangeInput = async (e: { target: any; }) => {
    const { target } = e;

    if (target) {
      const file: File = target.files[0];

      if (!ACCEPTED_IMAGE_TYPES.includes(file.type as EImageTypes)) {
        push({ severity: 'error', message: strings.editorImageObjectErrorMessage });
        return;
      }

      const dataUrl: string = await fileToDataUrl(file) as string;
      target.value = '';

      const image = new Image();
      image.src = dataUrl;
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        const objectsWithType = objects.filter(({ type }) => type === ECanvasObjectTypes.IMAGE);
        const name = `${strings.editorObjectImageTitle} #`;
        const order = getUniqueNameNumber(objectsWithType, name.length, 1);
        const img = new ImageObject(image, { name: `${name}${order}` });

        if (canvas.current && pdfDocument) {
          if (img.getScaledWidth() > pdfDocument.width) img.scaleToWidth(pdfDocument.width - 30);
          if (img.getScaledHeight() > pdfDocument.height) img.scaleToHeight(pdfDocument.height - 30);

          canvas.current.add(img);
          canvas.current.viewportCenterObject(img);
          canvas.current.setActiveObject(img);
          setObjects(canvas.current.getObjects());
          canvas.current.requestRenderAll();
          setIsChanged(true);

          push({ severity: 'success', message: strings.editorImageObjectSuccessMessage });

          checkDpiCurrentObjects(push);
        }
      };
    }
  };

  return (
    <>
      <SidebarButton
        buttonType="transparent"
        disabled={isDisabled}
        onClick={handleClick}
        className={css.button}
      >
        <Icon type={ICON_TYPES.image} />
      </SidebarButton>
      <input
        accept={ACCEPTED_IMAGE_TYPES_INPUT}
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleChangeInput}
      />
    </>
  );
};

export default AddImageObjectButton;
