import React, { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import textCss from 'assets/styles/Text.module.css';
import { TIncludedCustomer, TRowSelection, TUser } from '../../../../types';
import useColumns from './useColumns';
import Select from '../../../library/Select';
import css from './ReassignTable.module.css';
import { getUserFullName } from '../../../../helpers';
import Table from '../../../library/Table';
import { IModalContent } from './ModalContent';
import { TReassignedCustomers } from '../types';
import { getCommonManagerLabel } from '../helpers';
import { getAllRowSelected } from '../../../library/Table/helpers';

export interface IReassignCustomersTable {
  customers: TIncludedCustomer[],
  managers: TUser[],
  printer: TUser,
  managerId: TUser['id'],
  reassignedCustomers: IModalContent['reassignedCustomers'],
  setReassignedCustomers: IModalContent['setReassignedCustomers']
}
const ReassignTable: React.FC<IReassignCustomersTable> = ({
  customers, managers, printer, managerId, reassignedCustomers, setReassignedCustomers
}) => {
  const selectableManagers = managers.filter(({ id }) => id !== managerId).concat([printer]);
  const [commonManager, setCommonManager] = useState<TUser>(printer);
  const [rowSelection, setRowSelection] = React.useState<TRowSelection>(getAllRowSelected(customers.length));
  const commonManagerLabel = getCommonManagerLabel(rowSelection, customers);

  useEffect(() => {
    const getInitialReassignedCustomers = (): TReassignedCustomers => {
      const initialCustomers: TReassignedCustomers = {};
      customers.forEach(({ id }) => {
        initialCustomers[id] = printer;
      });
      return initialCustomers;
    };
    setReassignedCustomers(getInitialReassignedCustomers());
  }, []);

  const onSelectManager = (manager: SingleValue<TUser>, customerId: TUser['id']): void => {
    if (manager) {
      const newSelected = { ...reassignedCustomers };
      newSelected[customerId] = manager;
      setReassignedCustomers(newSelected);
    }
  };
  const onCommonManagerSelect = (newManager: SingleValue<TUser>): void => {
    if (newManager) {
      const newReassignedCustomers = { ...reassignedCustomers };
      Object.keys(rowSelection).forEach(rowIndex => {
        const selectedCustomer = customers[(rowIndex as unknown) as number];
        newReassignedCustomers[selectedCustomer.id] = newManager;
      });
      setReassignedCustomers(newReassignedCustomers);
      setCommonManager(newManager);
    }
  };

  const columns = useColumns(selectableManagers, onSelectManager, reassignedCustomers);

  return (
    <div className={css.container}>
      <div className={css.commonManagerContainer}>
        <span className={textCss.pMedium3}>
          {commonManagerLabel}
        </span>
        <Select
          className={css.commonManagersSelect}
          onChange={onCommonManagerSelect}
          value={commonManager}
          options={selectableManagers}
          getOptionLabel={user => getUserFullName(user)}
          getOptionValue={user => user.id}
          isSearchable
        />
      </div>
      <div className={css.table}>
        <Table
          columns={columns}
          data={customers}
          state={{ rowSelection }}
          onRowSelectionChange={setRowSelection}
          zeroPaddingRight
        />
      </div>
    </div>
  );
};

export default ReassignTable;
