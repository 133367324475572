/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconLineArrowRight(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 1.5L17 6M17 6L12.5 10.5M17 6H1"
        stroke="#A6CBDF"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconLineArrowRight;
