import React, { useMemo } from 'react';
import ScaleSelector from './ScaleSelector';
import useEditorData from '../../../../hooks/useEditorData';

const ScaleSelectorContainer:React.FC = () => {
  const {
    viewport: { zoom },
    setZoom,
    side,
    uploadingStatus,
    canvasLoadingStatus
  } = useEditorData();

  const isDisabled = useMemo<boolean>(
    () => !!uploadingStatus || canvasLoadingStatus,
    [side, uploadingStatus, canvasLoadingStatus]
  );

  return (
    <ScaleSelector
      zoom={zoom}
      setZoom={setZoom}
      disabled={isDisabled}
    />
  );
};

export default ScaleSelectorContainer;
