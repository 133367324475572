import { nanoid } from 'nanoid';
import { ECanvasObjectTypes, ETextCase } from 'types';
import strings from 'constants/localization';
import { formatText } from 'helpers';
import fabric from '../../../helpers/fabric';
import { extendCustomObject } from './extendCustomObject';

const defaultOptions = {
  lockSkewingY: true,
  lockSkewingX: true,
  lockScalingFlip: true,
  lockUniScaling: true,
  fontSize: 40,
  fontWeight: 400,
  fontFamily: 'Helvetica',
  textCase: ETextCase.Default
};

fabric.TextObject = fabric.util.createClass(fabric.Textbox, {
  type: ECanvasObjectTypes.TEXT,
  title: strings.editorObjectTextTitle,
  id: '',

  initialize(text: string, options?: any) {
    options = {
      ...defaultOptions,
      ...options,
    };

    this.on('scaling', this._handleScaling.bind(this));
    this.on('changed', this._changeText.bind(this));

    this.callSuper('initialize', text, options);
    this.id = options?.id ?? nanoid();

    // update status when importing from json
    if (this.locked) {
      this.updateLock(true);
    }
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      id: this.id,
      title: this.title,
      locked: this.locked,
      name: this.name,
      textCase: this.textCase
    });
  },

  _handleScaling(e: any) {
    if (e.transform.target.canvas) {
      const scaleFactor = this.scaleX === this.scaleY ? this.scaleX : this.scaleY;

      if (this.scaleX !== this.scaleY) {
        this.set({
          width: this.getScaledWidth(),
          height: this.getScaledHeight(),
        });
      }

      // min = 2, max = 399
      let fontSize = Math.floor(this.fontSize * scaleFactor);
      if (fontSize < 2) fontSize = 2;
      if (fontSize > 399) fontSize = 399;

      this.set({
        scaleX: 1,
        scaleY: 1,
        width: this.getMinWidth(),
        fontSize,
      });
    }
  },
  _changeText() {
    this.set({ text: formatText(this.text, this.textCase) });
  }
});

fabric.TextObject.fromObject = (object: fabric.Object, callback: Function) => fabric.Object._fromObject('TextObject', object, callback, 'text');

fabric.util.object.extend(fabric.TextObject.prototype, extendCustomObject);

export default fabric.TextObject;
