import React from 'react';
import cls from 'classnames';

import Icon, { ICON_TYPES } from 'components/library/Icon';
import textCss from 'assets/styles/Text.module.css';
import css from './ArticlesTicketType.module.css';

interface IArticlesTicketType {
  ticketType: string | null,
}

const ArticlesProductType: React.FC<IArticlesTicketType> = ({ ticketType }) => (
  <div className={css.productType}>
    <div className={css.icon}><Icon type={ICON_TYPES.ticket} /></div>
    <div className={cls(css.productTypeLabel, textCss.h5)}>{ticketType}</div>
  </div>
);

export default ArticlesProductType;
