import React, { useState } from 'react';

import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import Input from 'components/library/Input';
import { TUser } from 'types';
import Tooltip from 'components/library/Tooltip';
import { copyTextToClipboard } from 'helpers';
import strings from 'constants/localization';
import css from './Inputs.module.css';

const InputAdornment:React.FC<{ type: E_ICON_TYPE, value: string }> = ({ type, value }) => {
  const [copyTooltipTitle, setCopyTooltipTitle] = useState<string>(strings.profileSidebarCopyTitle);
  const onCopyClick = async () => {
    try {
      await copyTextToClipboard(value);
    } catch {
      setCopyTooltipTitle(strings.profileSidebarCopyFailureTitle);
      return;
    }
    setCopyTooltipTitle(strings.profileSidebarCopySuccessTitle);
  };
  const onTooltipOpen = () => setCopyTooltipTitle(strings.profileSidebarCopyTitle);
  const onTooltipClose = () => setCopyTooltipTitle(strings.profileSidebarCopyTitle);
  const onIconClick = () => {
    if (type === E_ICON_TYPE.mail) {
      window.location.href = `mailto:${value}`;
    }
    if (type === E_ICON_TYPE.phone) {
      window.location.href = `tel:${value}`;
    }
  };
  return (
    <div className={css.inputsAdornment}>
      <Tooltip
        className={css.copyIconWrapper}
        onOpen={onTooltipOpen}
        onClose={onTooltipClose}
        title={copyTooltipTitle}
        placement="top"
      >
        <Icon type={E_ICON_TYPE.copy} onClick={onCopyClick} />
      </Tooltip>
      <Icon type={type} onClick={onIconClick} />
    </div>
  );
};

interface IInputs extends React.HTMLProps<HTMLDivElement> {
  email: TUser['email'],
  phoneNumber: TUser['phoneNumber']
}
const Inputs:React.FC<IInputs> = ({ email, phoneNumber }) => (
  <div className={css.inputs}>
    {email && (
      <Input
        className={css.inputsPadding}
        readOnly
        value={email}
        customAdornment={<InputAdornment value={email} type={E_ICON_TYPE.mail} />}
        withTooltip
      />
    )}
    {phoneNumber && (
      <Input
        className={css.inputsPadding}
        readOnly
        value={phoneNumber}
        customAdornment={<InputAdornment value={phoneNumber} type={E_ICON_TYPE.phone} />}
        withTooltip
      />
    )}
  </div>
);

export default Inputs;
