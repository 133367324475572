import React, {
  RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { IEvent, Object } from 'fabric/fabric-impl';
import classNames from 'classnames';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import { ECanvasObjectTypes } from 'types';
import fabric from '../../helpers/fabric';
import { canvasInit } from './fabric/canvasInit';
import useEditorData from '../../hooks/useEditorData';
import css from './Canvas.module.css';
import {
  changeCanvasMode, renderRotatedObjectArea, rotateAligner
} from './fabric/events';
import { beforeRenderActiveSelection } from './fabric/activeSelectionEvents';
import useLayoutEffectIgnoreStrictMode from '../../hooks/useLayoutEffectIgnoreStrictMode';
import { EViewportModes } from '../../constants';
import { IObject, IPdfDocument, IViewportData } from '../../contexts/EditorContext';
import { replaceObject } from '../../../../TemplateEditorArticle/components/Sidebar/Tabs/helpers/replaceObject';
import UploadingLoader from '../UploadingLoader';
import { fontsList, loadFonts } from '../../helpers/fonts';
import { EBorderType } from './fabric/extendCustomObject';
import getObjectBorder from '../../helpers/getObjectBorder';

export const FIELDS_TO_EXPORT = ['id', 'title', 'name', 'locked', 'content', 'margin', 'prefix', 'pattern', 'startValue', 'isBackground'];

export interface ICanvas {
  mode: EViewportModes,
  viewport: IViewportData,
  json: any,
  setObjects: (objects: Object[]) => void,
  pdfDocument: IPdfDocument,
  canvas: RefObject<fabric.Canvas | null>,
  hideShadow?: boolean,
  padding?: number,
  handleResized?: (width: number, height: number) => void,
  setCanvasLoadingStatus: (value:boolean)=>void
}

const Canvas:React.FC<ICanvas> = props => {
  const {
    mode,
    viewport,
    json,
    setObjects,
    pdfDocument,
    canvas,
    hideShadow = false,
    padding = 40,
    handleResized,
    setCanvasLoadingStatus
  } = props;

  const {
    setJson,
    setIsChanged,
    setActiveObject,
    setHoverObject,
    checkDpiCurrentObjects,
    removeCanvasObject
  } = useEditorData();

  const fabricRef = useRef<HTMLCanvasElement>(null);
  const [error, setError] = useState(false);
  const { push } = useContext(AlertContext);
  // const contentOutsidePdfNotification = useCallback(() => {
  //   push({
  //     message: strings.warningEditorContentOutsidePdf,
  //     severity: 'warning',
  //   });
  // }, [push]);
  const [loadingStatus, setLoadingStatus] = useState<boolean|string>(false);

  const viewportWithPadding = useMemo<IViewportData>(() => ({
    ...viewport,
    width: viewport.width - padding,
    height: viewport.height - padding,
  }), [viewport, padding]);

  // calculating actual scale including fit (viewport.zoom=0)
  const zoom = useMemo<number>(
    () => (viewport.zoom !== 0)
      ? viewport.zoom
      : fabric.util.findScaleToFit(pdfDocument, viewportWithPadding),
    [viewport, pdfDocument]
  );

  const { width, height } = useMemo(() => {
    if (pdfDocument) {
      return {
        width: pdfDocument.width * zoom,
        height: pdfDocument.height * zoom,
      };
    }
    return { width: 0, height: 0 };
  }, [pdfDocument, viewport, zoom]);

  const objectMouseEventHandler = useCallback((event: IEvent<Event>, border: EBorderType, mouseOver = true) => {
    const object = event.target as IObject['object'];
    if (mouseOver) {
      setHoverObject(object);
    } else {
      setHoverObject(null);
    }
    if (object) {
      object.set('showBorder', getObjectBorder(event.target, border));
      object.canvas.renderAll();
    }
  }, [setHoverObject]);

  // side/document was changed
  useLayoutEffectIgnoreStrictMode(() => {
    // deselecting active object
    setActiveObject(null);

    // clear error
    setError(false);

    // clearing current objects
    setObjects([]);

    // @ts-ignore
    canvas.current = canvasInit(fabricRef.current);

    setHoverObject(null);

    const canvasElement = canvas.current;
    canvasElement.on('before:render', e => beforeRenderActiveSelection(canvasElement, e));
    canvasElement.on('after:render', e => renderRotatedObjectArea(canvasElement, e));

    canvasElement.on('selection:created', () => {
      const activeObject = canvasElement?.getActiveObject();
      if (activeObject) setActiveObject(activeObject);
    });
    canvasElement.on('selection:updated', () => {
      const activeObject = canvasElement?.getActiveObject();
      if (activeObject) setActiveObject(activeObject);
    });
    canvasElement.on('selection:cleared', () => {
      setActiveObject(null);
      // objectsIntersect(canvasElement, setError, mode);
    });

    canvasElement.on('object:rotating', rotateAligner);
    canvasElement.on('object:added', () => setJson(canvasElement.toJSON(FIELDS_TO_EXPORT)));

    canvasElement.on('object:removed', e => {
      removeCanvasObject(e.target);
      // objectsIntersect(canvasElement, setError, mode);
    });

    // update info when object was modified in canvas
    canvasElement.on('object:modified', e => {
      if (e.target && e.target.canvas) {
        if (e.target.type === ECanvasObjectTypes.IMAGE) {
          checkDpiCurrentObjects(push);
        }
        // objectsIntersect(canvasElement, setError, mode, e.target, contentOutsidePdfNotification);
        const objList = canvasElement.getObjects();
        if (objList.length > 0) {
          setObjects(replaceObject(objList, e.target));
        }
        canvasElement.requestRenderAll();
        setJson(canvasElement.toJSON(FIELDS_TO_EXPORT));
        setIsChanged(true);
      }
    });

    canvasElement.on('custom:mode:change', e => changeCanvasMode(canvasElement, e));
    setLoadingStatus(strings.editorLoaderLoadingStatus);
    setCanvasLoadingStatus(true);

    loadFonts(fontsList)
      .then(() => {
        canvasElement.loadFromJSON(json, () => {
          canvasElement.fire('custom:mode:change', mode);
          // objectsIntersect(canvasElement, setError, mode);
          canvasElement.renderAll.bind(canvasElement);
          setObjects(canvasElement.getObjects());
          setLoadingStatus(false);
          setCanvasLoadingStatus(false);
          checkDpiCurrentObjects(push);
        });
      }).catch(
        () => setLoadingStatus(strings.editorLoaderLoadingError)
      );

    if (handleResized) {
      handleResized(width, height);
    }

    canvasElement.on('mouse:over', e => {
      objectMouseEventHandler(e, EBorderType.ACTIVE);
    });
    canvasElement.on('mouse:out', e => {
      objectMouseEventHandler(e, EBorderType.DEFAULT, false);
    });

    return () => {
      const exportData = canvasElement.toJSON(FIELDS_TO_EXPORT);
      setJson(exportData);

      // deselecting active object
      setActiveObject(null);

      // clearing current objects
      setObjects([]);

      // clearing prev side info
      canvasElement.dispose();
      // @ts-ignore
      canvas.current = null;

      setHoverObject(null);
    };
  }, [pdfDocument, objectMouseEventHandler, checkDpiCurrentObjects]);

  // updating sizes when the ZOOM was changed
  useEffect(() => {
    if (canvas.current) {
      const canvasElement = canvas.current;
      canvasElement.setDimensions({ width, height });
      canvasElement.setZoom(zoom);

      if (handleResized) {
        handleResized(width, height);
      }
    }
  }, [pdfDocument, zoom, viewport]);

  // updating objects and canvas, when the MODE was changed
  useEffect(() => {
    if (canvas.current) {
      canvas.current.fire('custom:mode:change', mode);
    }
  }, [mode]);

  const wrapperStyles = useMemo(() => ({ width, height }), [width, height]);

  // Canvas should not be removed from DOM, but "display: none;" is used, during loading
  const canvasWrapperClasses = useMemo(
    () => classNames({ [css.hide]: loadingStatus }),
    [loadingStatus]
  );

  return (
    <span style={wrapperStyles} className={classNames({ [css.error]: error })}>
      { loadingStatus && <UploadingLoader status={loadingStatus} /> }
      <span className={canvasWrapperClasses}>
        <canvas className={classNames({ [css.shadow]: !hideShadow })} ref={fabricRef} />
      </span>
    </span>
  );
};

export default Canvas;
