import * as Yup from 'yup';

import { ERoles, TAddCustomerModalForm, TUser } from 'types';
import strings from 'constants/localization';

export const getValidationSchema = () => Yup.object().shape({
  name: Yup.string()
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
    .required(strings.inputValidateRequiredField),
  surname: Yup.string()
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  companyName: Yup.string()
    .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string),
  email: Yup.string()
    .email(strings.inputValidateEmail),
  sendOrderSheetsTo: Yup.string()
    .email(strings.inputValidateEmail),
});

export const getInitialValues = (user: TUser): TAddCustomerModalForm => ({
  name: '',
  surname: '',
  companyName: '',
  email: '',
  sendOrderSheetsTo: user.sendOrderSheetsTo || '',
});

export const getDataToSend = (data: TAddCustomerModalForm, searchParams: URLSearchParams, user: TUser): Partial<TUser> => {
  const dataToSend: Partial<TUser> = data;
  const queryManagerId: string | null = searchParams.get('managerId');
  dataToSend.managerId = user.role === ERoles.PRINTER
    ? queryManagerId || user.id
    : user.id;
  return dataToSend;
};
