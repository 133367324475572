import React, { useContext } from 'react';

import { ICustomersTableCell } from 'types/Customers';
import { SingleValue } from 'react-select';
import { ERoles, TUser } from '../../../types';
import { useUserPatch, useUsersFind } from '../../../hooks/useUser';
import UserContext from '../../../contexts/ContextUser';
import Select from '../../library/Select';
import { getUserFullName } from '../../../helpers';

const MAX_LIMIT = 1000;
const ManagerCell: React.FC<ICustomersTableCell> = ({ customerItem }) => {
  const userContext = useContext(UserContext);
  const { manager } = customerItem;
  const {
    data = { data: [], total: 0 },
  } = useUsersFind({
    limit: MAX_LIMIT,
    role: 'manager',
    sort: ['createdAt', -1],
    pspId: userContext.user?.pspId,
    blocked: false,
  });
  const userPatch = useUserPatch();
  const onChange = (value: SingleValue<TUser>) => {
    if (value) {
      userPatch.mutate({
        id: customerItem.id,
        managerId: value.id,
      });
    }
  };
  return (
    <div onClick={e => e.stopPropagation()}>
      <Select<TUser>
        /* todo: create permission helper */
        isDisabled={userContext.user?.role !== ERoles.PRINTER}
        onChange={onChange}
        options={data.data}
        value={manager}
        getOptionLabel={obj => getUserFullName(obj)}
        getOptionValue={obj => obj.id}
        isSearchable
      />
    </div>
  );
};

export default ManagerCell;
