import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { TextOptions, Object as CanvasObjectOptions } from 'fabric/fabric-impl';

import Button from 'components/library/Button';
import Modal from 'components/library/Modal';
import strings from 'constants/localization';

import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import {
  ECanvasObjectTypes,
  ETemplateSides,
  ETextCase,
  TArticleDocument,
  EIntegrationServices,
  TIntegrationMatchingsFieldsItem,
  TIntegrationMatchings, TExcelMatchings,
} from 'types';
import { TIntegrationCanvasObjects } from 'types/Integration';
import UserContext from 'contexts/ContextUser';
import usePrevious from 'hooks/usePrevious';
import { formatText } from 'helpers';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import ChooseTicketModalContent from './ChooseTicketModalContent';
import FieldsMatchingModal from './FieldsMatchingModal';
import {
  filterObjectsByType, findOrderFieldSample, mergeObjectsInformation, sideReadyForMatching
} from './helpers';
import { ITicketType } from './ChooseTicketModalContent/components/TicketTypeSelect';

type TObjectAdditional = {
  id: string,
  content?: string,
  textCase?: ETextCase,
  changeContent?: (v: string) => {}
}
export type TSideJsonObject = TObjectAdditional & TextOptions;
export type TSideCanvasObject = Omit<TObjectAdditional, 'id'> & CanvasObjectOptions;

export type TMatchings = Record<ETemplateSides, TIntegrationMatchingsFieldsItem>

const IntegrationMatching: React.FC = () => {
  const [ticketModalOpen, setTicketModalOpen] = useState<boolean>(false);
  const [matchingModalOpen, setMatchingModalOpen] = useState<boolean>(false);
  const [chosenTicket, setChosenTicket] = useState<ITicketType | null>(null);
  const [excelMatchings, setExcelMatchings] = useState<TExcelMatchings | null>(null);

  const { user } = useContext(UserContext);
  const {
    side: currentSide, canvas, objects, frontJson, backJson, setObjects, setFrontJson, setBackJson, setIsChanged
  } = useEditorData();
  const savedFrontMappings: TIntegrationMatchings | undefined = frontJson?.integrationMatchings;
  const savedBackMappings: TIntegrationMatchings | undefined = backJson?.integrationMatchings;

  const [integrationMatchings, setIntegrationMatchings] = useState<TMatchings>({
    front: savedFrontMappings?.fields || {},
    back: savedBackMappings?.fields || {}
  });

  const matchingsTicket = useMemo<ITicketType | null>(
    () => {
      const mapping = [savedFrontMappings, savedBackMappings].find(side => side?.ticketId);

      if (mapping?.ticketId) {
        const { ticketId } = mapping;
        const name = mapping?.ticketName || ticketId;
        return { id: ticketId, name };
      }

      return null;
    },
    [savedFrontMappings, savedBackMappings]
  );

  const integrationService = useMemo<EIntegrationServices | null>(
    () => user?.integration?.service ?? null,
    [user?.integration?.service]
  );

  const frontObjects = useMemo<TIntegrationCanvasObjects>(
    () => filterObjectsByType(frontJson.objects),
    [frontJson.objects]
  );
  const backObjects = useMemo<TIntegrationCanvasObjects>(
    () => filterObjectsByType(backJson?.objects),
    [backJson.objects]
  );

  const matchingModalOkButtonDisabled = useMemo<boolean>(
    () => (!sideReadyForMatching(frontObjects) && !sideReadyForMatching(backObjects)) || !chosenTicket,
    [frontObjects, backObjects, chosenTicket]
  );

  const prevObjects = usePrevious(objects);

  const syncMatchings = useCallback(
    () => {
      setIntegrationMatchings({
        front: savedFrontMappings?.fields || {},
        back: savedBackMappings?.fields || {},
      });
    },
    [savedFrontMappings, savedBackMappings]
  );

  const toggleTicketModal = () => {
    setTicketModalOpen(prev => !prev);
  };
  const toggleMatchingModal = () => {
    setMatchingModalOpen(prev => !prev);
  };

  const onOk = () => {
    toggleTicketModal();
    toggleMatchingModal();
  };

  const onCancel = () => {
    setChosenTicket(null);
    toggleTicketModal();
  };

  const onCancelMatching = () => {
    syncMatchings();
    setChosenTicket(null);
    toggleMatchingModal();
    toggleTicketModal();
  };

  const getNewCanvasObjects = (sideObjects: TSideJsonObject[], side: ETemplateSides) => {
    const currentUpdateTargets: TSideJsonObject[] = [];
    const objectsToSave: TSideJsonObject[] = [];
    sideObjects.forEach(obj => {
      const { id, type, textCase } = obj;
      const orderFieldPath: string | undefined = integrationMatchings[side][id];

      if (orderFieldPath && chosenTicket) {
        let newObject;
        const newValue = findOrderFieldSample(integrationService, orderFieldPath);

        if (type === ECanvasObjectTypes.TEXT) {
          newObject = { ...obj, text: textCase === ETextCase.Default ? newValue : formatText(newValue, textCase as ETextCase) };
        } else {
          newObject = { ...obj, content: newValue };
        }

        objectsToSave.push(newObject);
        if (side === currentSide) {
          currentUpdateTargets.push(newObject);
        }
      } else {
        objectsToSave.push(obj);
      }
    });

    return { currentUpdateTargets, objectsToSave };
  };

  const setNewCanvasObjects = async (
    sideJson: TArticleDocument['canvas'],
    side: ETemplateSides,
    setSideJson: React.Dispatch<React.SetStateAction<TArticleDocument['canvas']>>,
  ) => {
    const matchingKeys = Object.keys(integrationMatchings[side]);
    if (matchingKeys.length > 0 && chosenTicket) {
      const { objects: sideJsonObjects } = sideJson;
      const { objectsToSave, currentUpdateTargets } = getNewCanvasObjects(
        side === currentSide ? mergeObjectsInformation(sideJsonObjects, objects) : sideJsonObjects,
        side
      );
      setSideJson({
        ...sideJson,
        objects: objectsToSave,
        integrationMatchings: {
          ticketId: chosenTicket.id,
          ticketName: chosenTicket.name,
          service: integrationService || EIntegrationServices.EVENTBRITE,
          fields: { ...integrationMatchings[side] },
          ...(excelMatchings !== null && { excel: excelMatchings })
        }
      });
      if (side === currentSide && canvas.current && currentUpdateTargets.length) {
        const canvasObjects = canvas.current.getObjects() as TSideCanvasObject[];
        canvasObjects.forEach(async canvasObj => {
          const { name } = canvasObj;
          const newTarget = currentUpdateTargets.find(item => item.name === name);
          if (newTarget) {
            if (newTarget.type === ECanvasObjectTypes.TEXT) {
              canvasObj.set(newTarget);
            } else {
              canvasObj.changeContent?.(newTarget.content!);
            }
          }
        });
        canvas.current.requestRenderAll();
        setObjects(canvas.current.getObjects());
      }
      return;
    }
    setSideJson({ ...sideJson, integrationMatchings: {} as TIntegrationMatchings });
  };

  const onMatchFields = async () => {
    await setNewCanvasObjects(frontJson, ETemplateSides.FRONT, setFrontJson);
    await setNewCanvasObjects(backJson, ETemplateSides.BACK, setBackJson);

    setIsChanged(true);
    toggleMatchingModal();
  };

  // TODO: probably temporary solution for syncing local matchings state after deleting an object
  useEffect(() => {
    if (prevObjects && objects.length < prevObjects.length) {
      syncMatchings();
    }
  }, [savedFrontMappings, savedBackMappings, objects.length, prevObjects]);

  return (
    <>
      <Button buttonType="primary" onClick={toggleTicketModal}>{strings.connectTicket}</Button>
      <Modal
        isOpen={ticketModalOpen}
        title={strings.chooseTicketType}
        onRequestClose={toggleTicketModal}
        onCancel={onCancel}
        onOk={onOk}
        okLabel={strings.actionLabelNext}
        cancelLabel={strings.actionLabelBack}
        okButtonProps={{ disabled: !chosenTicket }}
      >
        { integrationService ? (
          <ChooseTicketModalContent
            integrationService={integrationService}
            chosenTicket={chosenTicket}
            matchingsTicket={matchingsTicket}
            setChosenTicket={setChosenTicket}
            setExcelMatchings={setExcelMatchings}
            integrationToken={user?.integration?.token}
            onMatched={onOk}
          />
        ) : (
          <EmptyState
            label={strings.emptyStateLabelNoEventBind}
            description={strings.emptyStateDescriptionSeemsLikeNotBindedWithEvent}
            variant={EmptyStates.NO_RESULTS}
          />
        )}
      </Modal>
      <Modal
        isOpen={matchingModalOpen}
        title={strings.ticketFieldsMapping}
        cancelLabel={strings.actionLabelBack}
        okLabel={strings.actionLabelSave}
        okButtonProps={{ disabled: matchingModalOkButtonDisabled }}
        onRequestClose={toggleMatchingModal}
        onCancel={onCancelMatching}
        onOk={onMatchFields}
      >
        { integrationService ? (
          <FieldsMatchingModal
            integrationService={integrationService}
            frontObjects={frontObjects}
            backObjects={backObjects}
            integrationMatchings={integrationMatchings}
            setIntegrationMatchings={setIntegrationMatchings}
            excelMatchings={excelMatchings}
          />
        ) : (
          <EmptyState
            label={strings.emptyStateLabelNoEventBind}
            description={strings.emptyStateDescriptionSeemsLikeNotBindedWithEvent}
            variant={EmptyStates.NO_RESULTS}
          />
        )}
      </Modal>
    </>
  );
};

export default IntegrationMatching;
