import React, {
  useCallback, ChangeEvent, KeyboardEvent, useMemo, useState, useContext
} from 'react';
import { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import cls from 'classnames';

import Input from 'components/library/Input';
import Select from 'components/library/Select';
import Button from 'components/library/Button';
import Icon, { ICON_TYPES } from 'components/library/Icon';

import { TPrintItemNestedField } from 'types';
import AlertContext from 'contexts/ContextAlert';
import strings from 'constants/localization';
import css from './MultiSelectWithComment.module.css';
import CustomLabel from './CustomLabel';

interface IMultiSelectWithComment {
  isDeletable?: boolean,
  deleteDisabled?: boolean,
  selectFieldName: string,
  selectFieldLabel: string,
  selectFieldPlaceholder: string,
  selectFieldValue: TPrintItemNestedField[],
  selectFieldError?: string | undefined,
  commentFieldName: string,
  commentFieldValue: string,
  commentFieldError?: string | undefined,
  className?: string,
  handleDelete?: () => void,
  handleChange: (selectValues: OnChangeValue<TPrintItemNestedField, true>, actionMeta: ActionMeta<TPrintItemNestedField>) => void,
  handleCommentChange: (e: ChangeEvent<HTMLInputElement>) => void,
  handleBlur: (e: ChangeEvent<any>) => void,
}

const styles: StylesConfig<TPrintItemNestedField, true> = {
  multiValueRemove: (base, state) => state.data.id ? { ...base, display: 'none' } : base,
};

const MultiSelectWithComment: React.FC<IMultiSelectWithComment> = ({
  className,
  selectFieldName,
  selectFieldLabel,
  selectFieldPlaceholder,
  selectFieldValue,
  selectFieldError,
  commentFieldName,
  commentFieldValue,
  commentFieldError,
  isDeletable = false,
  deleteDisabled = false,
  handleDelete,
  handleChange,
  handleCommentChange,
  handleBlur,
}) => {
  const [showComment, setShowComment] = useState<boolean>(false);

  const values = useMemo<OnChangeValue<TPrintItemNestedField, true>>(
    () => selectFieldValue
      .map(item => ({
        ...item,
        label: String(item.value),
      })),
    [selectFieldValue],
  );

  const { push } = useContext(AlertContext);

  const onEnterValue = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      // @ts-ignore
      const { code, target: { value } } = e;
      if (code === 'Enter' && values.some(item => item.value === value)) {
        push({
          severity: 'error',
          message: strings.formatString(strings.errorPrintItemFormSelectValueExists, selectFieldLabel, value) as string,
        });
      }
    },
    [values]
  );

  return (
    <div className={cls(css.container, className, { [css.noDeletion]: !isDeletable })}>
      <div className={css.multiSelectContainer}>
        <CustomLabel shouldShowComment iconType={ICON_TYPES.squaredChat} label={selectFieldLabel} setShowComment={setShowComment} />
        <Select
          styles={styles}
          name={selectFieldName}
          value={values}
          isClearable={values.some(v => !v.id)}
          isMulti
          isCreatable
          withError
          error={selectFieldError}
          components={{ DropdownIndicator: () => null, Menu: () => null }}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onEnterValue}
          placeholder={selectFieldPlaceholder}
        />
      </div>
      {showComment && (
        <div className={css.commentContainer}>
          <Input
            name={commentFieldName}
            customLabel={(
              <CustomLabel
                shouldShowComment={false}
                iconType={ICON_TYPES.close}
                label={strings.inputCommentLabel}
                setShowComment={setShowComment}
              />
            )}
            className={css.input}
            value={commentFieldValue}
            withError
            error={commentFieldError}
            onChange={handleCommentChange}
          />
        </div>
      )}
      {(isDeletable && handleDelete) && (
        <div className={css.deleteBtnContainer}>
          <Button
            type="button"
            buttonType="secondary"
            buttonStyle="circle"
            className={css.deleteBtn}
            disabled={deleteDisabled}
            onClick={handleDelete}
          >
            <Icon className={css.icon} type={ICON_TYPES.delete} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default MultiSelectWithComment;
