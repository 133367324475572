import React from 'react';
import {
  useReactTable, TableOptions, flexRender, getCoreRowModel, RowData,
} from '@tanstack/react-table';
import cls from 'classnames';

import textCss from 'assets/styles/Text.module.css';
import css from './Table.module.css';

declare module '@tanstack/table-core' {
  interface ColumnMeta {
    className: string
  }
  interface TableMeta {
    onClickRow: (id: string, row: RowData, rowObject: RowData) => void
  }
}

interface ITable<D extends object & { id: string | number }> extends Omit<TableOptions<D>, 'getCoreRowModel'> {
  zeroPaddingRight?: boolean,
  rowClassName?: string,
  headClassName?: string,
}
function Table<D extends object & { id: string | number }>(props:ITable<D>) {
  const {
    columns, data, meta, zeroPaddingRight = false, rowClassName, headClassName, ...rest
  } = props;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    meta,
    ...rest
  });

  return (
    <div className={css.table}>
      <div className={cls(css.thead, headClassName)}>
        {table.getHeaderGroups().map(headerGroup => (
          <div className={cls(css.row, rowClassName, { [css.zeroPaddingRight]: zeroPaddingRight })} key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <div key={header.id} className={cls(textCss.h4, header.column.columnDef.meta?.className)}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className={css.tbody}>
        {table.getRowModel().rows.map(row => (
          <div
            key={row.id}
            className={cls(css.row, rowClassName, {
              [css.clickable]: meta?.onClickRow,
              [css.zeroPaddingRight]: zeroPaddingRight,
              [css.expandedRow]: row.getIsExpanded(),
              [css.expandedSubRow]: row.depth > 0,
            })}
            onClick={() => meta && meta?.onClickRow(row.original.id.toString(), row.original, row)}
          >
            {row.getVisibleCells().map(cell => (
              <div key={cell.id} className={cls(textCss.pMedium2, css.cell, cell.column.columnDef.meta?.className)}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Table;
