import React, { useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import UserContext from 'contexts/ContextUser';
import { TUser } from 'types';
import Order, { IOrder } from './Order';
import { OrderContextProvider } from './ContextOrder';
import { isCustomer } from '../../../../helpers';

const OrderContainer:React.FC<Omit<IOrder, 'id' | 'user'>> = ({ isOrderCreation }) => {
  const { id } = useParams();
  const location = useLocation();
  const params: { [key: string]: string | undefined } = {};
  const state = (location.state || {}) as { customerId?: string, articleId?: string };
  params.customerId = state.customerId;
  params.articleId = state.articleId;
  const { user } = useContext(UserContext);
  const isUserCustomer = isCustomer(user?.userPermissions || []);
  if (isUserCustomer) {
    params.customerId = user?.id;
  }
  return useMemo(() => (
    <OrderContextProvider>
      <Order
        customerId={params.customerId}
        user={user as TUser}
        isOrderCreation={isOrderCreation}
        id={id}
        articleId={params.articleId}
      />
    </OrderContextProvider>
  ), [isOrderCreation, id, user, params.articleId, params.customerId]);
};

export default OrderContainer;
