import React from 'react';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import strings from 'constants/localization';
import { Grid } from '@mui/material';
import css from './TextView.module.css';
import FontSizeSelector from './components/FontSizeSelector';
import FontFamilySelector from './components/FontFamilySelector';
import ColorPicker from './components/ColorPicker';
import TextAlign from './components/TextAlign';
import ObjectAlign from './components/ObjectAlign';
import ObjectRotate from './components/ObjectRotate';
import TextSpacing from './components/TextSpacing';
import FontWeight from './components/FontWeight';
import FontStyle from './components/FontStyle';
import TextUnderline from './components/TextUnderline';
import TextCase from './components/TextCase';
import BackgroundColorPicker from './components/BackgroundColorPicker';

const containerProps = {
  container: true,
  columns: 4,
  spacing: '24px',
};

const gridItemProps = {
  item: true,
  xs: 1,
};

const alignEnd = {
  display: 'flex',
  alignItems: 'flex-end',
};

const TextView:React.FC = () => {
  const {
    activeObject,
  } = useEditorData();

  return (
    <div className={css.container}>
      <div className={css.name}>
        { strings.editorObjectTextTitle }: { activeObject.name }
      </div>

      <ObjectAlign />
      <ObjectRotate />

      <div className={css.section}>
        <header>{strings.editorTabSettingsSectionFontTitle}</header>
        <FontFamilySelector />
      </div>

      <Grid {...containerProps}>
        <Grid item xs={2} className={css.section}>
          <header>{strings.editorTabSettingsSectionSizeTitle}</header>
          <FontSizeSelector />
        </Grid>

        <Grid {...gridItemProps} {...alignEnd}><FontStyle /></Grid>
        <Grid {...gridItemProps} {...alignEnd}><FontWeight /></Grid>
        <Grid {...gridItemProps}><ColorPicker /></Grid>
        <Grid {...gridItemProps}><BackgroundColorPicker /></Grid>
        <Grid {...gridItemProps}><TextAlign /></Grid>
        <Grid {...gridItemProps}><TextSpacing /></Grid>
        <Grid {...gridItemProps}><TextCase /></Grid>
        <Grid {...gridItemProps}><TextUnderline /></Grid>
      </Grid>
    </div>
  );
};

export default TextView;
