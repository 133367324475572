import React, {
  forwardRef
} from 'react';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import css from './Button.module.css';
import Icon, { ICON_TYPES } from '../Icon';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: 'primary' | 'secondary' | 'tertiary' | 'quaternaryNext' | 'quaternaryBack' | 'quaternary',
  buttonStyle?: 'reducedHeight' | 'square' | 'circle'
}

const Button = forwardRef<HTMLButtonElement, IButton>((props, ref) => {
  const {
    buttonType, buttonStyle, className, children, disabled, ...rest
  } = props;

  return (
    <button
      ref={ref}
      type="button"
      disabled={disabled}
      className={cls(css.button, {
        [css.notDisabled]: !disabled,
        [css.primary]: buttonType === 'primary',
        [css.secondary]: buttonType === 'secondary',
        [css.tertiary]: buttonType === 'tertiary',
        [css.quaternary]: ['quaternaryNext', 'quaternaryBack', 'quaternary'].includes(buttonType),
        [css.reducedHeight]: buttonStyle === 'reducedHeight',
        [css.square]: buttonStyle === 'square',
        [css.circle]: buttonStyle === 'circle',
        [textCss.h5]: buttonStyle === 'reducedHeight',
        [textCss.h4]: buttonStyle !== 'reducedHeight',
      }, className)}
      {...rest}
    >
      {buttonType === 'quaternaryBack' && (
        <Icon type={ICON_TYPES.lineArrowRight} className={cls(css.arrow, css.mirrorRightArrowToLeft, css.marginRight)} />
      )}
      {children}
      {buttonType === 'quaternaryNext' && (
        <Icon type={ICON_TYPES.lineArrowRight} className={cls(css.arrow, css.marginLeft)} />
      )}
    </button>
  );
});

export default Button;
