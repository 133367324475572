import React from 'react';
import { IIconProps } from './types';

function IconRotate(props: IIconProps) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99844 2.2C7.92975 2.2 5.94579 3.02179 4.483 4.48457C3.02022 5.94735 2.19844 7.93131 2.19844 10C2.19844 10.2387 2.10362 10.4676 1.93483
      10.6364C1.76605 10.8052 1.53713 10.9 1.29844 10.9C1.05974 10.9 0.830824 10.8052 0.662041 10.6364C0.493259 10.4676 0.398438 10.2387 0.398438 10C0.398631
      8.35516 0.821446 6.73795 1.62633 5.30349C2.43122 3.86903 3.59117 2.66543 4.99494 1.80814C6.39871 0.950855 7.99921 0.468629 9.64293 0.407717C11.2866
      0.346804 12.9184 0.709249 14.3818 1.46029C15.8452 2.21133 17.091 3.32577 17.9999 4.69672C18.9087 6.06768 19.4501 7.64915 19.572 9.28947C19.694
      10.9298 19.3924 12.5739 18.6962 14.0642C18.0001 15.5545 16.9327 16.8409 15.5964 17.8H16.8984C17.1371 17.8 17.3661 17.8948 17.5348 18.0636C17.7036
      18.2324 17.7984 18.4613 17.7984 18.7C17.7984 18.9387 17.7036 19.1676 17.5348 19.3364C17.3661 19.5052 17.1371 19.6 16.8984 19.6H13.2984C13.0597
      19.6 12.8308 19.5052 12.662 19.3364C12.4933 19.1676 12.3984 18.9387 12.3984 18.7V15.1C12.3984 14.8613 12.4933 14.6324 12.662 14.4636C12.8308 14.2948
      13.0597 14.2 13.2984 14.2C13.5371 14.2 13.7661 14.2948 13.9348 14.4636C14.1036 14.6324 14.1984 14.8613 14.1984 15.1V16.5736C15.6204 15.6654 16.7096
      14.3206 17.3025 12.7409C17.8954 11.1612 17.9601 9.43186 17.4868 7.81231C17.0136 6.19275 16.0279 4.77032 14.6778 3.75841C13.3276 2.7465 11.6857
      2.19969 9.99844 2.2ZM12.9984 10C12.9984 10.7957 12.6824 11.5587 12.1198 12.1213C11.5571 12.6839 10.7941 13 9.99844 13C9.20279 13 8.43973 12.6839
      7.87712 12.1213C7.31451 11.5587 6.99844 10.7957 6.99844 10C6.99844 9.20435 7.31451 8.44129 7.87712 7.87868C8.43973 7.31607 9.20279 7 9.99844 7C10.7941
      7 11.5571 7.31607 12.1198 7.87868C12.6824 8.44129 12.9984 9.20435 12.9984 10ZM11.1984 10C11.1984 9.68174 11.072 9.37652 10.847 9.15147C10.6219 8.92643
      10.3167 8.8 9.99844 8.8C9.68018 8.8 9.37495 8.92643 9.14991 9.15147C8.92487 9.37652 8.79844 9.68174 8.79844 10C8.79844 10.3183 8.92487 10.6235 9.14991
      10.8485C9.37495 11.0736 9.68018 11.2 9.99844 11.2C10.3167 11.2 10.6219 11.0736 10.847 10.8485C11.072 10.6235 11.1984 10.3183 11.1984 10Z"
        fill="#2C3E66"
      />
    </svg>

  );
}

export default IconRotate;
