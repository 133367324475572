import React from 'react';
import cls from 'classnames';
import css from './Loader.module.css';

interface ILoader extends React.HTMLProps<HTMLDivElement> {}
const Loader:React.FC<ILoader> = ({ className, ...rest }) => (
  <div className={cls(css.root, className)} {...rest}>
    <div className={css.wrapper}>
      <div className={cls(css.circle, css.firstLoaderCircle)} />
      <div className={cls(css.circle, css.secondLoaderCircle)} />
      <div className={cls(css.circle, css.thirdLoaderCircle)} />
      <div className={cls(css.circle, css.fourthLoaderCircle)} />
    </div>
  </div>
);

export default Loader;
