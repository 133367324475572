import { ECanvasObjectTypes } from 'types';
import * as Yup from 'yup';

export const TEXT_OBJECT_SCHEMA = Yup.object().shape({
  id: Yup.string().required(),
  text: Yup.string(),
});

export const IMAGE_OBJECT_SCHEMA = Yup.object().shape({
  id: Yup.string().required(),
  src: Yup.string(),
});

export const CODE_OBJECT_SCHEMA = Yup.object().shape({
  id: Yup.string().required(),
  content: Yup.string().min(1),
});

export const getObjectValidationSchema = (type: ECanvasObjectTypes): Yup.ObjectSchema<any> => {
  switch (type) {
    case ECanvasObjectTypes.TEXT:
      return TEXT_OBJECT_SCHEMA;
    case ECanvasObjectTypes.IMAGE:
      return IMAGE_OBJECT_SCHEMA;
    case ECanvasObjectTypes.CODE:
      return CODE_OBJECT_SCHEMA;
    default:
      throw new Error('Invalid Object type');
  }
};
