import { FormikErrors } from 'formik';
import _uniqueId from 'lodash/uniqueId';

import { TPrintItemNestedField, TPrintItemOptionPortfolio } from 'types';

export const generateOption = (name: string, order: number | null = null): TPrintItemOptionPortfolio => ({
  key: _uniqueId(),
  name,
  comment: '',
  order,
  values: [],
});

export const getOptionError = (
  optionsErrors: string | string[] | FormikErrors<TPrintItemOptionPortfolio>[] | undefined,
  index: number,
  isCommentError: boolean = false
): string | undefined => {
  if (typeof optionsErrors === 'string') {
    return optionsErrors;
  }
  const errorField = optionsErrors?.length && optionsErrors[index];
  if (errorField) {
    const { values, comment } = errorField as { values: FormikErrors<TPrintItemNestedField>[] | undefined, comment: string | undefined };
    if (isCommentError) {
      return comment;
    }
    return Array.isArray(values) ? values.find(optionValueErr => optionValueErr?.value)?.value : values;
  }
};
