import React from 'react';
import { IIconProps } from './types';

function IconUnblock(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.5C4.89543 8.5 4 9.39543 4 10.5V20.5C4 21.6046 4.89543 22.5 6 22.5H18C19.1046 22.5 20 21.6046
        20 20.5V10.5C20 9.39543 19.1046 8.5 18 8.5H6ZM18 10.5H6V20.5H18V10.5Z"
        fill="#B13E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6.5C7 3.73858 9.23858 1.5 12 1.5C14.7614 1.5 17 3.73858 17 6.5V8.5H15V6.5C15 4.84315 13.6569 3.5 12
        3.5C10.3431 3.5 9 4.84315 9 6.5V8.5H7V6.5Z"
        fill="#B13E3E"
      />
      <rect x="9" y="12.5" width="2" height="6" fill="#B13E3E" />
      <rect x="13" y="12.5" width="2" height="6" fill="#B13E3E" />
    </svg>
  );
}

export default IconUnblock;
