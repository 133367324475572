import jwtDecode from 'jwt-decode';

export interface IEventbriteJwt {
  event_id: number
  auth_token: string
  user_id: number
  active_organization_id: number
  language: string
}

export const getJwtToken = (queryParams: URLSearchParams, parameter: string): IEventbriteJwt | null => {
  const jwt = queryParams.get(parameter);
  if (!jwt) {
    return null;
  }

  try {
    return jwtDecode<IEventbriteJwt>(jwt);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default { getJwtToken };
