import { EOrientation } from 'types';
import fabric from '../../../helpers/fabric';
import { EDITOR_BITMAP } from '../../../constants';

const getOrientation = (width: number, height: number): EOrientation => {
  if (width > height) return EOrientation.LANDSCAPE;
  if (width < height) return EOrientation.PORTRAIT;
  return EOrientation.SQUARE;
};

const getBleedOffsetByOrientation = (orientation: EOrientation, bleed: number): fabric.Point => {
  switch (orientation) {
    case EOrientation.PORTRAIT:
      return new fabric.Point(-bleed, bleed);
    default:
      return new fabric.Point(bleed, bleed);
  }
};

export const getPositionByOrientation = (props: IGetBitmap & { orientation: EOrientation}): fabric.Point => {
  const {
    width, height, bleed, orientation
  } = props;

  const bleedOffset = getBleedOffsetByOrientation(orientation, bleed);

  switch (orientation) {
    case EOrientation.PORTRAIT:
      return new fabric.Point(0, height)
        .add(bleedOffset);
    default:
      return new fabric.Point(width, height)
        .add(bleedOffset);
  }
};

const getSizeByOrientation = (orientation: EOrientation): { width: number, height: number } => {
  const { width, height } = EDITOR_BITMAP;
  switch (orientation) {
    case EOrientation.PORTRAIT:
      return { width: height, height: width };
    default:
      return { width, height };
  }
};

const getOriginByOrientation = (orientation: EOrientation): { x: string, y: string } => {
  switch (orientation) {
    case EOrientation.PORTRAIT:
      return { x: 'left', y: 'bottom' };
    default:
      return { x: 'right', y: 'bottom' };
  }
};

interface IGetBitmap {
  width: number
  height: number
  bleed: number
}

export const getBitmap = (props: IGetBitmap): fabric.Rect => {
  const { width, height } = props;

  const orientation = getOrientation(width, height);
  const size = getSizeByOrientation(orientation);

  const object = new fabric.Rect({
    fill: EDITOR_BITMAP.color,
    ...size
  });

  const position = getPositionByOrientation({ ...props, orientation });
  const origin = getOriginByOrientation(orientation);

  object.setPositionByOrigin(position, origin.x, origin.y);

  return object;
};

export default { getBitmap };
