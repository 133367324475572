import React, { ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';

interface IDatePickerLocalizationProvider {
  children: ReactNode
}

const DatePickerLocalizationProvider: React.FC<IDatePickerLocalizationProvider> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
    { children }
  </LocalizationProvider>
);

export default DatePickerLocalizationProvider;
