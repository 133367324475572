/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFlagRu(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0C5.37258 0 0 5.37258 0 12H24C24 5.37258 18.6274 0 12 0Z" fill="#EFEFEF" />
      <path d="M16.0006 4.56836C16.647 6.70113 16.9702 9.35027 16.9702 11.9995L16.0006 4.56836ZM16.0006 4.56836C16.0006 4.56836 16.9999 7.5 16.9702 11.9995L16.0006 4.56836Z" fill="#EFECEC" />
      <path d="M16.0003 0.685669C20.6604 2.33355 23.9997 6.7758 23.9997 11.9995H16.9702C16.9999 7.5 16.0006 4.56836 16.0006 4.56836C15.184 1.87255 13.8513 0.00156808 12.0021 9.85171e-07C13.4045 0.000279037 14.7496 0.243349 16.0003 0.685669Z" fill="#EFECEC" />
      <path d="M12 24C5.37258 24 0 18.6274 0 12H24C24 18.6274 18.6274 24 12 24Z" fill="#E73B36" />
      <path d="M16.0006 19.4316C16.647 17.2989 16.9702 14.6497 16.9702 12.0005L16.0006 19.4316ZM16.0006 19.4316C16.0006 19.4316 16.9999 16.5 16.9702 12.0005L16.0006 19.4316Z" fill="#E63026" />
      <path d="M16.0003 23.3143C20.6604 21.6664 23.9997 17.2242 23.9997 12.0005H16.9702C16.9999 16.5 16.0006 19.4316 16.0006 19.4316C15.184 22.1274 13.8513 23.9984 12.0021 24C13.4045 23.9997 14.7496 23.7567 16.0003 23.3143Z" fill="#E63026" />
      <path d="M23.3172 16C23.7594 14.7489 24 13.4025 24 12C24 10.5975 23.7594 9.25113 23.3172 8H0.682831C0.240631 9.25113 0 10.5975 0 12C0 13.4025 0.240631 14.7489 0.682831 16H23.3172Z" fill="#3757A6" />
      <path d="M23.9996 11.9995C23.9996 10.597 23.7589 9.25086 23.3166 8H16.7223C16.8709 9.11515 16.9803 10.4663 16.9701 11.9995C16.9701 12.1265 16.9694 12.2536 16.9679 12.3805C16.9536 13.6094 16.8696 14.8329 16.716 15.9994H23.3166C23.7589 14.7485 23.9996 13.4022 23.9996 11.9995Z" fill="#2B479D" />
    </svg>
  );
}

export default IconFlagRu;
