import React from 'react';

import ArticlesGridViewItem from 'components/PageArticles/components/ArticlesGridView/ArticlesGridViewItem';

import { IArticlesDisplayProps } from 'types/Articles';
import css from './ArticlesGridView.module.css';

const ArticlesGridView: React.FC<IArticlesDisplayProps> = ({ articles, ...rest }) => (
  <div className={css.gridViewContainer}>
    {articles.map(article => <ArticlesGridViewItem key={article.id} article={article} {...rest} />)}
  </div>
);

export default ArticlesGridView;
