import React, {
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import Loader from 'components/library/Loader';
import css from './Thumbnail.module.css';

export interface IThumbnail {
  src: string,
  alt?: string,
  className: string,
}

const Thumbnail: React.FC<IThumbnail> = ({
  src,
  alt = 'thumbnail',
  className,
  ...rest
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const classes = useMemo(
    () => classNames(className, { [css.hide]: !loaded }),
    [loaded]
  );

  const handleLoad = () => setLoaded(true);

  return (
    <>
      {
        !loaded && <Loader />
      }
      <img
        src={src}
        alt={alt}
        onLoad={handleLoad}
        className={classes}
        {...rest}
      />
    </>
  );
};

export default Thumbnail;
