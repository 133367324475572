import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import cls from 'classnames';

import {
  TPaper, TPricingItem, TQuantity, TSize,
} from 'types';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { MIN_PAGE } from 'constants/constants';
import { addValuesAfterComma } from 'helpers';
import css from './PricingTable.module.css';
import { TFormikQuantity } from '../../../../constants';

const useColumns = (
  size: TSize | null,
  quantities: TQuantity[],
  formikQties: TFormikQuantity[],
  pricings: TPricingItem[],
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  pageCount: number,
):ColumnDef<TPaper>[] => useMemo<ColumnDef<TPaper>[]>(() => {
  const columns: ColumnDef<TPaper>[] = [
    {
      id: 'paper',
      header: strings.paper,
      meta: {
        className: css.paperColumn
      },
      cell: ({ row: { original } }) => original.value
    },
    {
      id: 'lineArrowLeft',
      header: () => page !== MIN_PAGE
        ? (
          <Icon
            className={cls(css.arrow, css.mirrorArrow)}
            type={ICON_TYPES.lineArrowRight}
            onClick={() => setPage(prevPage => prevPage - 1)}
          />
        )
        : null,
      meta: {
        className: css.arrowColumn,
      },
      cell: () => null
    },
  ];

  quantities.forEach(qty => {
    columns.push({
      id: `qty ${qty.id}`,
      header: strings.formatString(strings.columnHeadQtyWithValue, qty.value) as string,
      meta: {
        className: css.qtyColumn
      },
      cell: ({ row: { original } }) => {
        const selectedQty = formikQties.find((
          { qtyId, paperId, isSelected }
        ) => isSelected && qtyId === qty.id && paperId === original.id);
        if (selectedQty) {
          const pricing = pricings.find((
            {
              size: { id: sizeId },
              quantity: { id: qtyId },
              paper: { id: paperId },
            },
          ) => sizeId === size?.id && qtyId === qty.id && paperId === original.id);
          if (pricing) {
            return `${addValuesAfterComma(String(pricing.price))} ${pricing.currency}`;
          }
        }
        return '-';
      }
    });
  });

  columns.push({
    id: 'lineArrowRight',
    header: () => page !== pageCount
      ? (
        <Icon
          className={css.arrow}
          type={ICON_TYPES.lineArrowRight}
          onClick={() => setPage(prevPage => prevPage + 1)}
        />
      )
      : null,
    meta: {
      className: css.arrowColumn,
    },
    cell: () => null,
  });

  return columns;
}, [size, formikQties, page]);

export default useColumns;
