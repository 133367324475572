import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import cls from 'classnames';
import css from './EventbriteLogin.module.css';
import textCss from '../../assets/styles/Text.module.css';
import strings from '../../constants/localization';
import Loader from '../library/Loader';
import EventbriteAuthorize from './components/authorize';

const Container:React.FC = () => {
  const { hash } = useLocation();
  const params = useMemo(() => new URLSearchParams(hash.replace('#', '')), [hash]);
  const token = useMemo(() => params.get('access_token') || '', [params]);

  return token
    ? (
      <div className={css.container}>
        <div className={css.innerContainer}>
          <div className={cls(textCss.h2, css.formLabel)}>{strings.loginPageLoginTitle}</div>
          <EventbriteAuthorize token={token} />
        </div>
      </div>
    )
    : (<Loader />);
};

export default Container;
