import React, { useContext } from 'react';

import Button from 'components/library/Button';
import FooterCancelButton from 'components/library/FooterActionButtons/components/FooterCancelButton';

import strings from 'constants/localization';
import { TPrintItemsItem } from 'types';
import AlertContext from 'contexts/ContextAlert';
import ArticleContext from 'contexts/ContextArticle';
import css from './Footer.module.css';
import { areArticleFormValuesEqualInitial } from '../helpers';

interface IFooterContent {
  printItem: TPrintItemsItem | null,
  setIsUnsavedValuesModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  navigateToArticleForm: () => void,
}
const FooterContent: React.FC<IFooterContent> = ({
  printItem, setIsUnsavedValuesModalOpen, navigateToArticleForm,
}) => {
  const { push: pushAlert } = useContext(AlertContext);
  const articleContext = useContext(ArticleContext);

  const onNextClick = () => {
    if (!printItem) {
      pushAlert({ severity: 'error', message: strings.errorArticlesNeedToSelectPrintItem });
      return;
    }

    const areCurrentValuesEqualInitial: boolean = areArticleFormValuesEqualInitial(
      articleContext.articleFormValues,
      articleContext.articleFormInitialValues
    );
    if (articleContext.selectedPrintItem !== printItem && !areCurrentValuesEqualInitial) {
      setIsUnsavedValuesModalOpen(true);
      return;
    }
    navigateToArticleForm();
  };

  return (
    <div className={css.footer}>
      <FooterCancelButton />
      <Button buttonType="quaternaryNext" onClick={onNextClick}>{strings.actionLabelNext}</Button>
    </div>
  );
};

export default FooterContent;
