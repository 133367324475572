import React, { ChangeEvent, useMemo, useState } from 'react';
import { number, ValidationError } from 'yup';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import Input from '../../../../../../../library/Input';
import css from './NumberingStartFrom.module.css';

const MAX_VALUE = 999999999;
const MAX_LENGTH = String(MAX_VALUE).length;
const VALUE_SCHEMA = number().min(0).max(MAX_VALUE);

const NumberingStartFrom: React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const [error, setError] = useState<string>('');
  const value = useMemo(() => activeObject?.startValue || 0, [activeObject?.startValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (activeObject && canvas.current && event.target) {
      try {
        const newValue = parseInt(event.target.value, 10) || 0;

        activeObject.set({
          startValue: VALUE_SCHEMA.validateSync(newValue)
        });
        setError('');
      } catch (e) {
        if (e instanceof ValidationError) {
          setError(e?.message);
        }
        activeObject.set({ startValue: 1 });
      } finally {
        activeObject._changeValue();
        canvas.current.fire('object:modified', { target: activeObject });
      }
    }
  };

  return (
    <Input
      maxLength={MAX_LENGTH}
      withError={!!error}
      error={error}
      inputClassName={css.input}
      onChange={handleChange}
      defaultValue={value}
    />
  );
};

export default NumberingStartFrom;
