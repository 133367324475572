/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconSKUs(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9 2.89998C10.1879 2.89998 8.8 4.28789 8.8 5.99998V8.09998H15V5.99998C15 4.28789 13.6121 2.89998 11.9 2.89998ZM16.8 8.09998V5.99998C16.8 3.29378 14.6062 1.09998 11.9 1.09998C9.1938 1.09998 7 3.29378 7 5.99998V8.09998H5.4C4.35066 8.09998 3.5 8.95063 3.5 9.99998V21C3.5 22.0493 4.35066 22.9 5.4 22.9H18.4C19.4493 22.9 20.3 22.0493 20.3 21V9.99998C20.3 8.95063 19.4493 8.09998 18.4 8.09998H16.8ZM5.4 9.89998C5.34477 9.89998 5.3 9.94475 5.3 9.99998V21C5.3 21.0552 5.34477 21.1 5.4 21.1H18.4C18.4552 21.1 18.5 21.0552 18.5 21V9.99998C18.5 9.94475 18.4552 9.89998 18.4 9.89998H15.9H7.9H5.4ZM8.9 12C8.9 12.5523 8.45228 13 7.9 13C7.34772 13 6.9 12.5523 6.9 12C6.9 11.4477 7.34772 11 7.9 11C8.45228 11 8.9 11.4477 8.9 12ZM15.9 13C16.4523 13 16.9 12.5523 16.9 12C16.9 11.4477 16.4523 11 15.9 11C15.3477 11 14.9 11.4477 14.9 12C14.9 12.5523 15.3477 13 15.9 13Z" fill="white" />
    </svg>
  );
}

export default IconSKUs;
