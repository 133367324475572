import React from 'react';
import { ECanvasObjectTypes } from 'types';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import { Canvas } from 'fabric/fabric-impl';
import useEditorData from '../../../hooks/useEditorData';
import { EViewportModes } from '../../../constants';
import css from './AddTextObjectButton.module.css';
import TextObject from '../../Viewport/fabric/textObject';
import getUniqueNameNumber from '../../../helpers/uniqueObjectName';
import SidebarButton from '../../../../../TemplateEditorArticle/components/Sidebar/components/SidebarButton';

interface IAddTextObjectButton {
  isDisabled: boolean,
}

const calculateFontSize = (canvas: Canvas | null, object: typeof TextObject) => {
  if (!canvas) {
    return;
  }

  const multiplier = 0.1;
  const zoom = canvas.getZoom();
  const maxWidth = (canvas.getWidth() / zoom) * multiplier;
  const maxHeight = (canvas.getHeight() / zoom) * multiplier;

  let { fontSize } = object;
  while (object.width < maxWidth && object.height < maxHeight) {
    fontSize += 1;
    object.set({ fontSize });
  }
};

const AddTextObjectButton:React.FC<IAddTextObjectButton> = ({ isDisabled = true }) => {
  const {
    setMode,
    canvas,
    objects,
    setObjects,
    pdfDocument,
    setIsChanged,
  } = useEditorData();

  const handleClick = async () => {
    if (canvas.current && pdfDocument) {
      setMode(EViewportModes.Edit);
      canvas.current.discardActiveObject();

      const objectsWithType = objects.filter(({ type }) => type === ECanvasObjectTypes.TEXT);
      const name = `${strings.editorObjectTextTitle} #`;
      const order = getUniqueNameNumber(objectsWithType, name.length, 1);

      const textBox = new TextObject(strings.editorObjectTextDefaultText, {
        name: `${name}${order}`, fontSize: 1,
      });

      calculateFontSize(canvas.current, textBox);

      canvas.current.add(textBox);
      canvas.current.viewportCenterObject(textBox);
      canvas.current.setActiveObject(textBox);
      setObjects(canvas.current.getObjects());
      canvas.current.requestRenderAll();
      setIsChanged(true);
    }
  };

  return (
    <SidebarButton
      buttonType="transparent"
      disabled={isDisabled}
      onClick={handleClick}
      className={css.button}
    >
      <Icon type={ICON_TYPES.text} />
    </SidebarButton>
  );
};

export default AddTextObjectButton;
