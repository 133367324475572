/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconPdf(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.98723 12.2974C6.98723 11.6511 6.53847 11.2654 5.74653 11.2654C5.42348 11.2654 5.20461 11.2965 5.08984 11.3278V13.403C5.22553 13.4338 5.39233 13.4444 5.62108 13.4444C6.46601 13.4444 6.98723 13.017 6.98723 12.2974Z" fill="#2C3E66" />
      <path d="M11.8877 11.2861C11.5328 11.2861 11.304 11.3174 11.168 11.3487V15.9466C11.3037 15.9783 11.5227 15.9783 11.7209 15.9783C13.1595 15.9884 14.098 15.1964 14.098 13.5176C14.1085 12.0574 13.2533 11.2861 11.8877 11.2861Z" fill="#2C3E66" />
      <path d="M21.2805 8.58469H20.7042V5.80404C20.7042 5.7867 20.7015 5.76923 20.6992 5.75167C20.6983 5.64125 20.6632 5.5328 20.5876 5.44687L15.9602 0.160958C15.9588 0.159608 15.9575 0.159128 15.9565 0.157647C15.929 0.126841 15.8968 0.10122 15.8628 0.0790412C15.8527 0.0722874 15.8426 0.066623 15.832 0.0607842C15.8026 0.0447058 15.7713 0.0313725 15.7392 0.0217429C15.7305 0.0193028 15.7226 0.0157298 15.7139 0.013464C15.679 0.00518517 15.6427 0 15.6059 0H4.23366C3.71436 0 3.29249 0.422352 3.29249 0.941218V8.58447H2.71632C1.97345 8.58447 1.37109 9.18656 1.37109 9.9297V16.9251C1.37109 17.6677 1.97345 18.2702 2.71632 18.2702H3.29253V23.0588C3.29253 23.5776 3.7144 24 4.23371 24H19.7631C20.2819 24 20.7043 23.5776 20.7043 23.0588V18.2702H21.2806C22.0232 18.2702 22.6257 17.6677 22.6257 16.9251V9.92992C22.6257 9.18682 22.0232 8.58469 21.2805 8.58469ZM4.23366 0.941393H15.1353V5.75676C15.1353 6.01676 15.3462 6.22735 15.6059 6.22735H19.7631V8.58499H4.23366V0.941393ZM15.7874 13.465C15.7874 14.8104 15.2975 15.738 14.6188 16.3118C13.8793 16.9269 12.753 17.2187 11.3771 17.2187C10.5536 17.2187 9.96987 17.1665 9.57318 17.1146V10.212C10.157 10.1183 10.9186 10.0662 11.7215 10.0662C13.0554 10.0662 13.921 10.3062 14.599 10.8168C15.3286 11.359 15.7874 12.2242 15.7874 13.465ZM3.51471 17.1459V10.212C4.00451 10.129 4.69301 10.0662 5.66268 10.0662C6.64268 10.0662 7.34142 10.2539 7.81044 10.6295C8.25889 10.9838 8.56055 11.5678 8.56055 12.256C8.56055 12.9444 8.33179 13.5282 7.9144 13.9242C7.37227 14.4352 6.56952 14.6647 5.63087 14.6647C5.42268 14.6647 5.23436 14.6541 5.08904 14.6332V17.1461H3.51471V17.1459ZM19.7631 22.804H4.23366V18.2702H19.7631V22.804H19.7631ZM21.1268 11.4216H18.4258V13.0273H20.9492V14.3203H18.4258V17.1459H16.8313V10.1183H21.1268V11.4216Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconPdf;
