import React from 'react';
import { IIconProps } from './types';

function IconDelete(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.78564C6.89543 7.78564 6 8.68107 6 9.78564V19.4999C6 20.6045 6.89543 21.4999 8 21.4999H16C17.1046
        21.4999 18 20.6045 18 19.4999V9.78564C18 8.68108 17.1046 7.78564 16 7.78564H8ZM16.2857
        9.5H7.71431V19.7857H16.2857V9.5Z"
        fill="#2C3E66"
      />
      <rect x="6" y="4.35718" width="12" height="1.71429" rx="0.857143" fill="#2C3E66" />
      <path
        d="M8.57129 5.21429C8.57129 4.26751 9.3388 3.5 10.2856 3.5H13.7141C14.6609 3.5 15.4284 4.26751 15.4284
        5.21429H8.57129Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconDelete;
