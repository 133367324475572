import { FabricObject } from '../useFabricObjects';
import fabric from '../../../helpers/fabric';

export default async (object: FabricObject['object'], data: any, options?: any): Promise<FabricObject['object']> => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id, type, src, ...rest
  } = data;

  if (src) {
    await setSrc(object, src, options?.forceScale);
  }

  if (Object.values(rest).length) {
    applyForceRest(object, rest);
  }

  return new Promise(resolve => {
    resolve(object);
  });
};

const setSrc = async (object: FabricObject['object'], src: string, forceScale: boolean) => {
  const initialObject = {
    width: object.getScaledWidth(),
    height: object.getScaledHeight()
  };
  const initialPoint = object.getCenterPoint();
  const initialSrc = object.src;

  await new Promise(resolve => {
    object.setSrc(src, () => {
      resolve(true);
    });
  });

  if (initialSrc !== src || forceScale) {
    const { width, height } = object;
    object.scale(fabric.util.findScaleToFit({ width, height }, initialObject));
    object.setPositionByOrigin(initialPoint, 'center', 'center');
  }

  object.setCoords();
};

const applyForceRest = (object: FabricObject['object'], rest: any) => {
  object.set(rest);
  object.setCoords();
};
