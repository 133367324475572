import React, { useCallback, useMemo } from 'react';
import { FormikProps } from 'formik';
import { SingleValue } from 'react-select';
import Label from 'components/library/Label';
import strings from 'constants/localization';
import Select from 'components/library/Select';
import { IEventbriteGetEventsRes, IEventbriteGetOrgsRes, TIntegrationEventBind } from 'types/Integration';
import { IFindResponse } from 'constants/types';
import css from './AddEventModalForm.module.css';

export const EVENT = 'event';
export const ORGANIZATION = 'organization';

type TSelectValue = {
  label: string,
  value: string,
}

export interface IAddEventModalFormContainer {
  formik: FormikProps<any>
  disabled: boolean
  organizations: IEventbriteGetOrgsRes | undefined
  events: IEventbriteGetEventsRes | undefined
  eventBinds: IFindResponse<TIntegrationEventBind[]> | undefined
}

const AddEventModalFormContainer:React.FC<IAddEventModalFormContainer> = props => {
  const {
    formik,
    organizations,
    events,
    disabled,
    eventBinds,
  } = props;

  const organizationsList = useMemo<TSelectValue[]>(
    () => organizations?.organizations?.map(({ name, id }) => ({ label: name, value: id })) || [],
    [organizations]
  );

  const eventsList = useMemo<TSelectValue[]>(
    () => events?.events
      .filter(({ id }) => !eventBinds?.data.find(eventBind => eventBind.eventId === id))
      .map(({ name: { text }, id }) => ({ label: text, value: id })) || [],
    [events, eventBinds]
  );

  return (
    <AddEventModalForm
      formik={formik}
      disabled={disabled}
      organizations={organizationsList}
      events={eventsList}
    />
  );
};

export interface IAddEventModalForm {
  formik: FormikProps<any>
  disabled: boolean
  organizations: TSelectValue[]
  events: TSelectValue[]
}

const AddEventModalForm:React.FC<IAddEventModalForm> = props => {
  const {
    disabled,
    formik: {
      getFieldProps,
      handleSubmit,
      setFieldValue,
      values: {
        organization,
        event,
      },
    },
    organizations,
    events,
  } = props;

  const onEventChange = useCallback(
    (value: SingleValue<TSelectValue>) => setFieldValue(EVENT, value),
    [EVENT, setFieldValue]
  );

  const onOrganizationChange = useCallback(
    (value: SingleValue<TSelectValue>) => setFieldValue(ORGANIZATION, value),
    [ORGANIZATION, setFieldValue]
  );

  return (
    <form className={css.form} noValidate onSubmit={handleSubmit}>
      <div>
        <Label text={strings.inputOrganizationLabel} />
        <Select<TSelectValue>
          {...getFieldProps(ORGANIZATION)}
          value={organization}
          options={organizations}
          onChange={onOrganizationChange}
          isDisabled={disabled}
        />
      </div>
      <div>
        <Label text={strings.inputEventLabel} />
        <Select<TSelectValue>
          {...getFieldProps(EVENT)}
          value={event}
          options={events}
          onChange={onEventChange}
          isDisabled={disabled}
        />
      </div>
    </form>
  );
};

export default AddEventModalFormContainer;
