import React, { useMemo } from 'react';
import Select from 'components/library/Select';
import { SingleValue } from 'react-select';
import { ECodeObjectTitle } from 'components/PageArticles/components/Editor/components/Viewport/fabric/codeObject';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import strings from 'constants/localization';
import css from './CodeTypeSelector.module.css';

interface IOption {
  label: string,
  value: string,
}

const CodeTypeSelector: React.FC = () => {
  const options: IOption[] = [
    { value: ECodeObjectTitle.QR, label: strings.editorObjectQrCodeTitle },
    { value: ECodeObjectTitle.BARCODE, label: strings.editorObjectBarCodeTitle },
  ];

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const option = useMemo<IOption>(() => options.find(({ value }) => value === activeObject.title) ?? options[0], [activeObject.title]);

  const handleChange = async (newValue: SingleValue<IOption>) => {
    if (activeObject && canvas.current && newValue) {
      await activeObject.changeType(newValue.value);
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  return (
    <Select<IOption>
      className={css.select}
      isSearchable={false}
      menuPortalTarget={document.body}
      menuShouldBlockScroll
      options={options}
      value={option}
      onChange={handleChange}
    />
  );
};

export default CodeTypeSelector;
