import React, { useMemo } from 'react';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import Modal from 'components/library/Modal';
import { ETemplateSides } from 'types';
import strings from 'constants/localization';
import css from './SideDeleteModal.module.css';

export interface ISideDeleteModal {
  side: ETemplateSides | null,
  onClose: () => void,
}

// TODO: use common modal here and in 'Add/Edit Articles' page 'Template' tab
const SideDeleteModal:React.FC<ISideDeleteModal> = ({ side, onClose }) => {
  const {
    clearSide,
    setIsChanged,
  } = useEditorData();

  const localizedSide = useMemo(() => {
    switch (side) {
      case ETemplateSides.FRONT:
        return strings.templateAccusativeSideNameFront;
      case ETemplateSides.BACK:
        return strings.templateAccusativeSideNameBack;
      default:
        return strings.templateAccusativeSideNameUnknown;
    }
  }, [side]);

  const handleDeleteSide = () => {
    if (side) {
      clearSide(side);
      setIsChanged(true);
      onClose();
    }
  };

  const isOpen = useMemo<boolean>(() => !!side, [side]);

  return (
    <Modal
      title={strings.formatString(strings.templateDeleteModalTitle, localizedSide) as string}
      isOpen={isOpen}
      onRequestClose={onClose}
      onCancel={onClose}
      okLabel={strings.templateDeleteModalOkButton}
      onOk={handleDeleteSide}
      okButtonProps={{ className: css.confirm }}
    >
      <span>{strings.formatString(strings.templateDeleteModalContent, <b>{localizedSide}</b>)}</span>
    </Modal>
  );
};

export default SideDeleteModal;
