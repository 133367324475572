import React, { useState } from 'react';
import { FormikProps } from 'formik';

import Modal from 'components/library/Modal';
import Table from 'components/library/Table';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { TArticle, TPaper, TQuantity } from 'types';
import { MIN_PAGE } from 'constants/constants';
import useColumns from './useColumns';
import { IArticleFormValues, PRICING_TABLE_PAGE_SIZE } from '../../../../constants';
import css from './PricingTable.module.css';

interface IPricingTable {
  isOpen: boolean,
  closeModal: () => void,
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
}
const PricingTable: React.FC<IPricingTable> = ({
  isOpen, closeModal, formik, article,
}) => {
  const {
    values: { productSetup: { size, selectedPapers, quantities } }
  } = formik;
  const [page, setPage] = useState<number>(MIN_PAGE);

  const printItemQties = article.printItem.quantities;
  const pageCount = Math.ceil(printItemQties.length / PRICING_TABLE_PAGE_SIZE);
  const sliceStart: number = (page - 1) * PRICING_TABLE_PAGE_SIZE;
  const sliceEnd: number = page * PRICING_TABLE_PAGE_SIZE;
  const pageQties: TQuantity[] = printItemQties.slice(sliceStart, sliceEnd);
  const columns = useColumns(
    size,
    pageQties,
    quantities,
    article.printItem.pricings,
    page,
    setPage,
    pageCount,
  );
  const papers = article.printItem.papers.filter(({ id }) => selectedPapers.includes(id));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onOk={closeModal}
      title={strings.articleFormPricingTableHeader}
      okLabel={strings.actionLabelOk}
      headerIcon={<Icon type={ICON_TYPES.option} />}
    >
      <div className={css.tableContainer}>
        <Table<TPaper>
          columns={columns}
          data={papers}
        />
      </div>
    </Modal>
  );
};

export default PricingTable;
