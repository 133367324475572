import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';

const TextUnderline:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = () => {
    if (activeObject && canvas.current) {
      activeObject.set({ underline: !activeObject.underline });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const active = useMemo(() => activeObject.underline, [activeObject.underline]);

  return (
    <Tooltip placement="top" title={strings.editorTabSettingsTextUnderlineTooltip} arrow>
      <SidebarButton
        isActive={active}
        onClick={handleChange}
      >
        <Icon type={ICON_TYPES.textUnderline} />
      </SidebarButton>
    </Tooltip>
  );
};

export default TextUnderline;
