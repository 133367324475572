import { ECanvasObjectTypes } from 'types';
import { uniq, defaultsDeep } from 'lodash';
import fabric from '../../../helpers/fabric';
import { IObject } from '../../../contexts/EditorContext';
import { ECanvasActiveSelection } from '../../../constants';

const OBJECTS_CONFIG = {
  [ECanvasObjectTypes.TEXT]: {
    _controlsVisibility: {
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false,
      bl: false,
      br: false,
    }
  },
  [ECanvasObjectTypes.NUMBERING]: {
    _controlsVisibility: {
      mt: false,
      mb: false,
      ml: false,
      mr: false
    }
  },
  [ECanvasObjectTypes.CODE]: {
    _controlsVisibility: {
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    }
  },
  [ECanvasObjectTypes.IMAGE]: {
    _controlsVisibility: {
      mt: false,
      mb: false,
      ml: false,
      mr: false,
    }
  },
};

const mergeConfigs = (types: ECanvasObjectTypes[]) => {
  const configs = types.map(type => OBJECTS_CONFIG[type]);
  return defaultsDeep({}, ...configs);
};

const getUniqueTypesList = (objects: IObject['object'][]): ECanvasObjectTypes[] => {
  const types = objects.map((o: any) => o?.type);
  return uniq(types);
};

export function beforeRenderActiveSelection(canvas: fabric.Canvas, event: fabric.IEvent) {
  // @ts-ignore
  const { ctx } = event;
  if (ctx) {
    const object: IObject['object'] = canvas.getActiveObject();
    if (object && object.type === ECanvasActiveSelection.ACTIVE_SELECTION) {
      const types = getUniqueTypesList(object.getObjects());

      object.set({
        lockScalingFlip: true,
        lockUniScaling: true,
        lockSkewingY: true,
        lockSkewingX: true,
        ...mergeConfigs(types),
      });
    }
  }
}

export default { beforeRenderActiveSelection };
