import {
  useInfiniteQuery, UseInfiniteQueryOptions, useQuery, useQueryClient
} from 'react-query';

import instance from 'lib/apiClient';
import { PAGE_SIZE_10 } from 'constants/constants';
import { IFindResponse, IResponseError, IFindInfiniteResponse } from 'constants/types';
import { TPrintItemsItem } from 'types';
import { IGetPrintItemsParams } from 'types/Portfolio/index';
import { getPrintItemsCommonQuery } from 'helpers';

interface IFindPrintItemsResponse extends IFindResponse<TPrintItemsItem[]> {}
interface IFindInfinitePrintItemsResponse extends IFindInfiniteResponse<IFindPrintItemsResponse> {}

const client = instance.getClient();

export const GET_PRINT_ITEMS_QUERY = ['printItems', 'get'];
export const GET_INFINITE_PRINT_ITEMS_QUERY = ['infinitePrintItems', 'get'];

export const getPortfolioList = <Response = IFindPrintItemsResponse>(params: IGetPrintItemsParams):Promise<Response> => {
  const query: string = getPrintItemsCommonQuery(params);
  return client.get(`/print-items${query}`).then(res => res.data);
};

export const useGetPrintItems = (data: IGetPrintItemsParams) => useQuery<IFindPrintItemsResponse, IResponseError>(
  [...GET_PRINT_ITEMS_QUERY, data.pspId, data.limit, data.skip, data.search, data.sort],
  () => getPortfolioList(data),
);

export const useGetInfinitePrintItems = (data: IGetPrintItemsParams) => useInfiniteQuery<
  IFindInfinitePrintItemsResponse,
  IResponseError,
  IFindPrintItemsResponse
>(
  [...GET_INFINITE_PRINT_ITEMS_QUERY, data.search, data.sort],
  ({ pageParam = 1 }) => getPortfolioList<IFindInfinitePrintItemsResponse>({ ...data, skip: (pageParam - 1) * PAGE_SIZE_10, limit: PAGE_SIZE_10 }),
  {
    getNextPageParam: (lastPage: IFindPrintItemsResponse, allPages: IFindPrintItemsResponse[]): number | undefined => {
      const pagesAmount = Math.ceil(lastPage.total / PAGE_SIZE_10);
      const nextPage = allPages.length + 1;
      return nextPage <= pagesAmount ? nextPage : undefined;
    },
  } as unknown as UseInfiniteQueryOptions<IFindInfinitePrintItemsResponse, IResponseError, IFindPrintItemsResponse>
);

export const useInvalidateGetPrintItems = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...GET_PRINT_ITEMS_QUERY, ...additionalParams]);
};

export default useGetPrintItems;
