/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconVerticalDivider(props: IIconProps) {
  return (
    <svg {...props} width="2" height="40" viewBox="0 0 2 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="2" height="40" rx="1" fill="#BCDEF0" />
    </svg>

  );
}

export default IconVerticalDivider;
