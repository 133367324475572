/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconLogo(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50502 21.04C9.07502 21.04 8.65502 20.88 8.32502 20.56C6.09502 18.36 5.03502 15.23 5.34502 11.73C5.61502 8.66003 6.93502 5.66003 8.87502 3.71003C12.125 0.440028 19.835 -2.08997 26.835 2.50003C27.605 3.01003 27.825 4.04003 27.315 4.81003C26.805 5.58003 25.765 5.79003 24.995 5.28003C19.585 1.72003 13.705 3.59003 11.265 6.05003C9.87502 7.45003 8.88502 9.74003 8.68502 12.01C8.55502 13.45 8.65502 16.17 10.685 18.18C11.345 18.83 11.345 19.88 10.695 20.54C10.365 20.87 9.93502 21.03 9.50502 21.03V21.04Z" fill="url(#paint0_linear_9885_55695)" />
      <path d="M12.945 26.54C12.725 26.54 12.495 26.54 12.275 26.52C9.19503 26.37 6.13503 25.17 4.09503 23.3C0.695028 20.19 -2.13497 12.6 2.21503 5.46996C2.69503 4.67996 3.72503 4.42995 4.51503 4.90995C5.30503 5.38995 5.55503 6.40995 5.07503 7.19995C1.71503 12.72 3.80503 18.5 6.35503 20.85C9.01503 23.28 15.225 24.57 18.545 20.97C19.175 20.29 20.225 20.25 20.915 20.87C21.595 21.49 21.645 22.55 21.015 23.23C19.035 25.38 16.185 26.55 12.945 26.55V26.54Z" fill="url(#paint1_linear_9885_55695)" />
      <path d="M13.1251 31.9999C10.4651 31.9999 7.59506 31.2799 4.84506 29.4599C4.07506 28.9499 3.86506 27.9099 4.37506 27.1499C4.88506 26.3799 5.92506 26.1799 6.69506 26.6899C12.0951 30.2699 17.9751 28.4299 20.4351 25.9799C21.8351 24.5899 22.8351 22.2999 23.0351 20.0299C23.1651 18.5899 23.0851 15.8699 21.0651 13.8499C20.4151 13.1999 20.4151 12.1399 21.0651 11.4899C21.7151 10.8399 22.7751 10.8399 23.4351 11.4899C25.6551 13.6999 26.6951 16.8399 26.3751 20.3299C26.0951 23.3899 24.7651 26.3899 22.8051 28.3399C20.8251 30.3099 17.2151 31.9999 13.1251 31.9999Z" fill="url(#paint2_linear_9885_55695)" />
      <path d="M28.335 27.29C28.035 27.29 27.725 27.21 27.445 27.04C26.655 26.56 26.415 25.53 26.895 24.75C30.285 19.24 28.225 13.45 25.685 11.1C24.235 9.76001 21.915 8.85001 19.615 8.73001C18.165 8.65001 15.435 8.83 13.485 10.92C12.855 11.6 11.795 11.63 11.115 11.01C10.435 10.38 10.395 9.33001 11.025 8.65001C13.155 6.36001 16.265 5.20001 19.785 5.39001C22.865 5.56001 25.915 6.77001 27.945 8.65001C31.325 11.78 34.115 19.38 29.735 26.49C29.425 27 28.875 27.28 28.315 27.29H28.335Z" fill="url(#paint3_linear_9885_55695)" />
      <defs>
        <linearGradient id="paint0_linear_9885_55695" x1="18.325" y1="3.79003" x2="5.43502" y2="22.2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6B5CE9" />
          <stop offset="0.84" stopColor="#18D7DE" />
        </linearGradient>
        <linearGradient id="paint1_linear_9885_55695" x1="16.325" y1="7.63995" x2="7.49503" y2="20.25" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6B5CE9" />
          <stop offset="1" stopColor="#18D7DE" />
        </linearGradient>
        <linearGradient id="paint2_linear_9885_55695" x1="29.2351" y1="5.54994" x2="14.6551" y2="26.3699" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6B5CE9" />
          <stop offset="1" stopColor="#18D7DE" />
        </linearGradient>
        <linearGradient id="paint3_linear_9885_55695" x1="28.3516" y1="6.10433" x2="14.697" y2="25.7569" gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stopColor="#6B5CE9" />
          <stop offset="0.76" stopColor="#18D7DE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconLogo;
