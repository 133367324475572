/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconPhone(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.6169 15.1803L15.9983 16.1082C15.8748 16.2934 15.7115 16.4514 15.4963 16.5083C14.7914 16.6946 12.9199 16.7787 10.0706 13.9294C7.22133 11.0801 7.30542 9.20865 7.49169 8.50372C7.54855 8.28851 7.70659 8.12521 7.8918 8.00174L8.8197 7.38314C9.51054 6.92258 9.69722 5.98919 9.23666 5.29834L7.48417 2.66961C7.08217 2.06661 6.3072 1.83624 5.64108 2.12171L4.85053 2.46052C4.24997 2.7179 3.76009 3.17982 3.46789 3.76422C3.22376 4.25247 3.03455 4.76662 3.00891 5.3119C2.92812 7.03027 3.30492 11.2638 8.02055 15.9795C12.7362 20.6951 16.9697 21.0719 18.6881 20.9911C19.2334 20.9654 19.7475 20.7762 20.2358 20.5321C20.8202 20.2399 21.2821 19.75 21.5395 19.1495L21.8783 18.3589C22.1638 17.6928 21.9334 16.9178 21.3304 16.5158L18.7017 14.7633C18.0108 14.3028 17.0774 14.4895 16.6169 15.1803Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconPhone;
