import React, { useState } from 'react';
import cls from 'classnames';

import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Input from 'components/library/Input';
import css from './TicketTypeSelect.module.css';
import SelectItem from './components/SelectItem';
import { useFilteredTicketTypes } from '../../../hooks';

export interface ITicketType {
  id: string,
  name: string,
}

export interface ITicketTypeSelect {
  title: string,
  ticketTypes: ITicketType[],
  matchingsTicket: ITicketType | null,
  chosenTicket: ITicketType | null,
  connectedTickets: string[],
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
}

const TicketTypeSelect: React.FC<ITicketTypeSelect> = props => {
  const { title, ticketTypes: rawTicketTypes } = props;

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const ticketTypes = useFilteredTicketTypes(rawTicketTypes, search);

  return (
    (
      <div className={css.modalContainer}>
        <Input
          className={css.search}
          searchActive={isSearchActive}
          placeholder={strings.inputSearchTextPlaceholder}
          toggleSearchActive={() => setIsSearchActive(prevState => !prevState)}
          value={search}
          onChange={e => setSearch(e.target.value.trim())}
        />
        <span className={cls(css.title, textCss.pMedium3)}>
          {strings.formatString(strings.availableTicketsForEvent, title)}
        </span>
        <div className={css.content}>
          <div className={css.container}>
            { ticketTypes.map(ticketType => <SelectItem key={ticketType.id} {...props} ticketType={ticketType} />) }
          </div>
        </div>
      </div>
    )
  );
};

export default TicketTypeSelect;
