import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ROLE_TO_ADD_ROUTE, ROLE_TO_LIST_ROUTE, ROLE_TO_LIST_ROUTE_LABEL
} from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { ERoles, TLocalizedConstString } from 'types';
import Modal from 'components/library/Modal';
import css from './UserRegisteredModal.module.css';

export type TUserRegistered = ERoles.CUSTOMER | ERoles.MANAGER | ERoles.PRINTER;

const ADD_USER_LABELS: Record<TUserRegistered, TLocalizedConstString> = {
  [ERoles.PRINTER]: () => strings.profileUserRegisterModalAddPrinterButton,
  [ERoles.MANAGER]: () => strings.profileUserRegisterModalAddManagerButton,
  [ERoles.CUSTOMER]: () => strings.profileUserRegisterModalAddCustomerButton,
};

const USER_REGISTERED_MESSAGES: Record<TUserRegistered, TLocalizedConstString> = {
  [ERoles.PRINTER]: () => strings.profileUserRegisterModalPrinterRegisteredMessage,
  [ERoles.MANAGER]: () => strings.profileUserRegisterModalManagerRegisteredMessage,
  [ERoles.CUSTOMER]: () => strings.profileUserRegisterModalCustomerRegisteredMessage,
};

interface IUserRegisteredModal {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  targetRole: TUserRegistered,
}
const UserRegisteredModal: React.FC<IUserRegisteredModal> = ({
  isOpen, setIsOpen, targetRole
}) => {
  const navigate = useNavigate();
  const addUserLabel: string = ADD_USER_LABELS[targetRole]();
  const modalContent: string = USER_REGISTERED_MESSAGES[targetRole]();
  const cancelLabel: string = ROLE_TO_LIST_ROUTE_LABEL[targetRole]();
  const onRequestClose = (): void => setIsOpen(false);
  const onOk = (): void => {
    setIsOpen(false);
    navigate(ROLE_TO_ADD_ROUTE[targetRole]);
  };
  const onCancel = (): void => navigate(ROLE_TO_LIST_ROUTE[targetRole]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onOk={onOk}
      onCancel={onCancel}
      title={strings.profileUserRegisterModalHeader}
      okLabel={addUserLabel}
      cancelLabel={cancelLabel}
      className={css.registerModal}
    >
      <>
        <span className={textCss.pMedium3}>
          {modalContent}
        </span>
        <Icon type={ICON_TYPES.userRegistered} className={css.registerModalImage} />
      </>
    </Modal>
  );
};

export default UserRegisteredModal;
