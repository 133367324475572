import React, { useCallback, useContext, useMemo } from 'react';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import { FIELDS_TO_EXPORT } from 'components/PageArticles/components/Editor/components/Viewport/Canvas';
import { isDataUrl } from 'components/PageArticles/components/Editor/helpers/file';
import { useNavigate } from 'react-router-dom';
import Button from 'components/library/Button';
import UserContext from 'contexts/ContextUser';
import ArticleContext from 'contexts/ContextArticle';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import { navigateBack, dpiChecker } from 'helpers';
import { ECanvasObjectTypes, ETemplateSides } from 'types';
import { dataUrlToAwsLink } from '../../components/Editor/helpers/aws';
import { fabricJsonToThumbnail } from '../../components/Editor/helpers/thumbnail';
import css from './Footer.module.css';
import ContextAlert from '../../../../contexts/ContextAlert';
import fabric from '../../components/Editor/helpers/fabric';

const Footer:React.FC = () => {
  const {
    canvas,
    side: currentSide,
    frontJson,
    backJson,
    setFrontJson,
    setBackJson,
    pdfDocument,
    frontSidePdfDocument,
    backSidePdfDocument,
    uploadingStatus,
    canvasLoadingStatus,
    setUploadingStatus,
    isChanged,
    setIsChanged,
    calculateDpi,
    checkDpiCurrentObjects,
    setSide
  } = useEditorData();

  const { articleFormValues, setArticleFormValues } = useContext(ArticleContext);
  const { push } = useContext(ContextAlert);
  const contextUser = useContext(UserContext);
  const pspId = contextUser.user?.pspId;

  const checkImageDpi = useCallback((object: typeof fabric.ImageObject) => {
    const dpi = calculateDpi(object);
    return dpiChecker(dpi);
  }, [calculateDpi]);

  const checkImageObjects = useCallback((objects: Array<typeof fabric.ImageObject| typeof fabric.CodeObject | typeof fabric.TextObject> = []) => {
    const imageObjects = objects.filter((obj: any) => obj.type === 'ImageObject');
    return imageObjects.map((imgObj: typeof fabric.ImageObject) => new fabric.ImageObject(imgObj.src, { ...imgObj })).every(checkImageDpi);
  }, [checkImageDpi]);

  const handleClickSave = async () => {
    if (!pspId) return;

    // set current ObjectView and current ImageObjects red notification if dpi less 150
    checkDpiCurrentObjects(push);

    const checkedFrontImgObjects = checkImageObjects(frontJson.objects);
    const checkedBackImgObjects = checkImageObjects(backJson.objects);
    // change current side if another side has errors
    if (!checkedFrontImgObjects && !checkedBackImgObjects) return;
    if (checkedFrontImgObjects && !checkedBackImgObjects) {
      if (currentSide === ETemplateSides.FRONT) {
        setSide(ETemplateSides.BACK);
      }
      return;
    }
    if (!checkedFrontImgObjects && checkedBackImgObjects) {
      if (currentSide === ETemplateSides.BACK) {
        setSide(ETemplateSides.FRONT);
      }
      return;
    }

    setUploadingStatus(true);

    const sides = getSides();

    const result = await Promise.all(sides.map(async (side: any) => {
      setUploadingStatus(strings.editorLoaderStatusThumbnail);
      const thumbnail = await fabricJsonToThumbnail(side.canvas, side.document);
      side.thumbnail = await dataUrlToAwsLink(thumbnail, `${side.side}-thumbnail`, pspId);

      // saving PDF file
      if (isDataUrl(side.document.src)) {
        setUploadingStatus(strings.editorLoaderStatusPdf);
        side.document.src = await dataUrlToAwsLink(side.document.src, `${side.side}-pdf`, pspId);
      }

      // saving background image from pdf file
      if (isDataUrl(side.canvas.backgroundImage.src)) {
        setUploadingStatus(strings.editorLoaderStatusBackground);
        side.canvas.backgroundImage.src = await dataUrlToAwsLink(side.canvas.backgroundImage.src, `${side.side}-background`, pspId);
      }

      // objects>ImageObject
      if (side.canvas.objects) {
        const objects = side.canvas.objects
          .filter((obj: any) => obj.type === ECanvasObjectTypes.IMAGE && isDataUrl(obj.src));

        await Promise.all(
          objects.map(async (object: any) => {
            setUploadingStatus(strings.formatString(strings.editorLoaderStatusImage, object.name) as string);
            object.src = await dataUrlToAwsLink(object.src, `${side.side}-image`, pspId);
            return object;
          })
        );
      }

      if (side.side === ETemplateSides.FRONT) {
        setFrontJson(side.canvas);
      } else {
        setBackJson(side.canvas);
      }

      return side;
    }));
    // it's required to not render Canvas again and prevents errors because of aborted loadFromJSON()/canvas.dispose()
    setUploadingStatus(strings.editorLoaderStatusSaving);

    if (articleFormValues?.template) {
      articleFormValues.template = result.reduce(
        (prev, curr) => ({ ...prev, [`${curr.side}Side`]: curr }),
        {}
      );
      setArticleFormValues(articleFormValues);
      setIsChanged(false);
      await new Promise(resolve => { setTimeout(resolve, 50); });
      navigateBack(navigate, `${ROUTES.BASE}${ROUTES.ARTICLES}`);
    }
  };

  const getSides = () => {
    const sides = [];

    // current side info
    if (canvas.current && pdfDocument) {
      sides.push({
        side: currentSide,
        canvas: {
          // @ts-ignore (toJSON returns object in fact (and as doc says), but type check is incorrect saying that string should be returned)
          ...canvas.current.toJSON(FIELDS_TO_EXPORT),
          integrationMatchings: (currentSide === ETemplateSides.FRONT ? frontJson?.integrationMatchings : backJson?.integrationMatchings) || {},
        },
        document: pdfDocument,
      });
    }

    const secondDocument = currentSide === ETemplateSides.FRONT ? backSidePdfDocument : frontSidePdfDocument;
    if (secondDocument) {
      sides.push({
        side: currentSide === ETemplateSides.FRONT ? ETemplateSides.BACK : ETemplateSides.FRONT,
        canvas: currentSide === ETemplateSides.FRONT ? backJson : frontJson,
        document: secondDocument,
      });
    }

    sides.sort(({ side }) => side === ETemplateSides.FRONT ? -1 : 1);
    return sides;
  };

  const navigate = useNavigate();
  const handleClickCancel = () => navigateBack(navigate, `${ROUTES.BASE}${ROUTES.ARTICLES}`);

  const isSaveButtonDisabled = useMemo(() => !!uploadingStatus || canvasLoadingStatus || !isChanged, [uploadingStatus, canvasLoadingStatus, isChanged]);

  return (
    <div className={css.container}>
      <Button onClick={handleClickCancel} buttonType="quaternary">{strings.editorFooterButtonCancel}</Button>
      <Button disabled={isSaveButtonDisabled} onClick={handleClickSave} buttonType="primary">{strings.editorFooterButtonSave}</Button>
    </div>
  );
};

export default Footer;
