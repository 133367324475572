import React, { useMemo } from 'react';
import strings from 'constants/localization';
import { Grid } from '@mui/material';
import css from './CodeView.module.css';
import ObjectAlign from './components/ObjectAlign';
import ObjectRotate from './components/ObjectRotate';
import Opacity from './components/Opacity';
import useEditorData from '../../../../../components/Editor/hooks/useEditorData';
import CodeMargin from './components/CodeMargin';
import CodeTypeSelector from './components/CodeTypeSelector';
import CodeContent from './components/CodeContent';
import { ECodeObjectTitle } from '../../../../../components/Editor/components/Viewport/fabric/codeObject';
import { getObjectTitle } from '../../../../../components/Editor/helpers/getObjectTitle';
import BarcodeHeight from './components/BarcodeHeight';

const gridContainerProps = {
  container: true,
  columns: 4,
  spacing: '24px',
};

const gridItemProps = {
  item: true,
  xs: 1,
};

const CodeView:React.FC = () => {
  const {
    activeObject,
  } = useEditorData();

  const title = useMemo(
    () => getObjectTitle(activeObject),
    [activeObject?.title]
  );

  return (
    <div className={css.container}>
      <div className={css.name}>
        { title }: { activeObject.name }
      </div>

      <ObjectAlign />
      <ObjectRotate />

      <div className={css.section}>
        <header>{strings.editorTabSettingsSectionCodeTypeTitle}</header>
        <CodeTypeSelector />
      </div>

      <div>
        <CodeContent />

        <section className={css.section}>
          <header>{strings.editorTabSettingsSectionEffectsTitle}</header>

          <Grid {...gridContainerProps}>
            <Grid {...gridItemProps}><Opacity /></Grid>
            <Grid {...gridItemProps}><CodeMargin /></Grid>
            {
              activeObject.title === ECodeObjectTitle.BARCODE
              && <Grid {...gridItemProps}><BarcodeHeight /></Grid>
            }
          </Grid>
        </section>
      </div>

    </div>
  );
};

export default CodeView;
