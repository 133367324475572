import React, { useCallback, useContext, useMemo } from 'react';
import {
  ErrorCode, FileError, FileRejection, useDropzone
} from 'react-dropzone';

import ContextAlert, { IAlertContext } from 'contexts/ContextAlert';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import { ETemplateSides } from 'types';
import css from './UploadArea.module.css';

export enum EAcceptValue {
  PDF = 'application/pdf',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

export const isImage = (type: EAcceptValue): boolean => [EAcceptValue.JPEG, EAcceptValue.JPG, EAcceptValue.PNG].includes(type);

const MAX_FILE_SIZE: number = 50 * 1024 * 1024; // megabytes in bytes

const getRejectionErrorMessage = (errorCode: FileError['code'], maxSizeInBytes: number): string | undefined => {
  const errorMessages: Record<FileError['code'], string> = {
    [ErrorCode.FileTooLarge]: strings.formatString(strings.editorErrorPdfFileSizeIsTooLarge, maxSizeInBytes / 1024 / 1024) as string,
  };
  return errorMessages[errorCode];
};

interface IUploadAreaContainer {
  side: ETemplateSides,
  handleChange: (file: File) => void,
  maxSizeInBytes?: number,
  accept?: Array<string>
}

const UploadAreaContainer:React.FC<IUploadAreaContainer> = props => {
  const { push } = useContext(ContextAlert);

  return useMemo(() => <UploadArea {...props} push={push} />, [props]);
};

interface IUploadArea {
  side: ETemplateSides
  handleChange: (file: File) => void
  maxSizeInBytes?: number
  accept?: Array<string>
  push: IAlertContext['push']
}

const UploadArea:React.FC<IUploadArea> = props => {
  const {
    side,
    handleChange,
    accept = [EAcceptValue.PDF, EAcceptValue.PNG, EAcceptValue.JPEG, EAcceptValue.JPG],
    maxSizeInBytes = MAX_FILE_SIZE,
    push,
  } = props;

  const onDropAccepted = useCallback((files: File[]) => handleChange(files[0]), [side]);
  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach(({ errors }) => {
      errors.forEach(({ message, code }) => {
        const displayMessage: string = getRejectionErrorMessage(code, maxSizeInBytes) || message;
        push({ severity: 'error', message: displayMessage });
      });
    });
  }, [side]);
  const onError = useCallback(({ message }: Error) => push({ severity: 'error', message }), [side]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: accept.reduce((prev, next) => ({ ...prev, [next]: [] }), {}),
    maxSize: maxSizeInBytes,
    onDropAccepted,
    onDropRejected,
    onError,
    useFsAccessApi: false
  });

  return (
    <section className={css.container} {...getRootProps()}>
      <input {...getInputProps()} />
      <Icon type={ICON_TYPES.upload} />
      <h3>{strings.editorUploadAreaContent}</h3>
      <section className={css.description}>{strings.editorUploadAreaSupportedFormats}</section>
      <section className={css.description}>{strings.editorUploadAreaDescription}</section>
    </section>
  );
};

export default UploadAreaContainer;
