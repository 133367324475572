import React from 'react';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { SingleValue } from 'react-select';
import { useLocation } from 'react-router-dom';

import FooterActionButtons from 'components/library/FooterActionButtons';
import FooterCancelButton from 'components/library/FooterActionButtons/components/FooterCancelButton';
import Select from 'components/library/Select';
import { ARTICLE_STATUS_OPTIONS, ROUTES } from 'constants/constants';
import { EFooterSaveButtonDisplayModes, TArticle, TArticleStatusOption } from 'types';
import css from './FooterContent.module.css';
import {
  FOOTER_FORM, IArticleFormValues, IFormFooterValues, TAB_COUNT
} from '../../constants';
import {
  getFooterDisabled, TFooterDisabled
} from '../../helpers';

export const getFieldName = (fieldName: keyof IFormFooterValues): string => `${FOOTER_FORM}.${fieldName}`;
export const getFieldError = (
  fieldName: keyof IFormFooterValues,
  touched?: FormikTouched<IFormFooterValues>,
  errors?: FormikErrors<IFormFooterValues>
): string | undefined => {
  const error = touched?.[fieldName] && errors?.[fieldName] as string;
  return error || undefined;
};

interface IFooter extends React.HTMLProps<HTMLDivElement> {
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
  create?: boolean,
  onClickSave: () => void,
  disableSave: boolean,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>,
}
const FooterContent:React.FC<IFooter> = ({
  formik, article, create, onClickSave, disableSave, tabIndex, setTabIndex
}) => {
  const {
    getFieldProps, setFieldValue, setFieldTouched,
    touched: { footer: touched },
    errors: { footer: errors },
  } = formik;
  const disabled: TFooterDisabled | undefined = getFooterDisabled(article);
  const location = useLocation();
  const previousPageFallback = ROUTES.ARTICLES_SELECT_PRINT_ITEM + location.search;
  const cancelButtonRoute = `${ROUTES.BASE}${ROUTES.ARTICLES}${location.search}`;

  const onStatusChange = (value: SingleValue<TArticleStatusOption>) => setFieldValue(getFieldName('status'), value);
  const onStatusBlur = () => setFieldTouched(getFieldName('status'), true);

  return (
    <div className={css.footer}>
      <div className={css.cancelWithStatusSelect}>
        <FooterCancelButton navigateRoute={cancelButtonRoute} />
        <Select<TArticleStatusOption>
          {...getFieldProps(getFieldName('status'))}
          options={ARTICLE_STATUS_OPTIONS}
          onChange={onStatusChange}
          onBlur={onStatusBlur}
          getOptionLabel={option => option.label()}
          withError
          error={getFieldError('status', touched, errors)}
          isDisabled={disabled?.status}
        />
      </div>
      <FooterActionButtons
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabCount={TAB_COUNT}
        onClickSave={onClickSave}
        disableSave={disableSave}
        saveBtnDisplayMode={create ? EFooterSaveButtonDisplayModes.ONLY_ON_LAST_TAB : EFooterSaveButtonDisplayModes.ALWAYS}
        backToPreviousPage={create}
        previousPageFallback={previousPageFallback}
      />
    </div>
  );
};

export default FooterContent;
