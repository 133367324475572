import React, {
  createContext, Dispatch, SetStateAction, useMemo, useState
} from 'react';
import { ETablePageViews } from '../types';

export interface IArticleListViewContext {
  view: ETablePageViews,
  setView: Dispatch<SetStateAction<ETablePageViews>>,
}
const ArticleListViewContext = createContext<IArticleListViewContext>({
  view: ETablePageViews.TABLE,
  setView: () => {},
});

interface IArticleListViewContextProvider {
  children: React.ReactNode,
}
export const ArticleListViewContextProvider: React.FC<IArticleListViewContextProvider> = ({ children }) => {
  const [view, setView] = useState<ETablePageViews>(ETablePageViews.TABLE);

  const value: IArticleListViewContext = useMemo<IArticleListViewContext>(() => ({
    view,
    setView,
  }), [view]);

  return (
    <ArticleListViewContext.Provider value={value}>
      {children}
    </ArticleListViewContext.Provider>
  );
};

export default ArticleListViewContext;
