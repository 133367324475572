/* eslint-disable max-len */
import { ILanguages } from '../types';
import IArticlesStrings from '../types/ArticlesStrings';

const strings:ILanguages<IArticlesStrings> = {
  en: {
    articlesPageTitle: 'SKUs',
    articlesAddEditPageTitle: 'SKU Parameters',
    articlesSelectPrintItemPageTitle: 'Select Print Item',

    articlesSKU: 'SKU',

    articlesPageButtonAddNewSku: 'Add new SKU',

    articlesPageProductType: 'Product type',

    articlesDeleteSKU: 'Delete SKU',
    articlesDuplicateSKU: 'Duplicate SKU',
    articlesDeleteConfirm: 'Do you really want to delete this SKU?',

    emptyStateNoArticlesTitle: 'Looks like there are no SKUs yet',
    emptyStateNoArticlesDescription: 'To get started, click on Add new SKU',

    successResponseArticleDeleted: 'Article was successfully deleted',
    errorResponseUnableToDeleteArticle: 'Unable to delete Article',

    editorChooseImageButton: 'Choose Image',

    articlesSelectPrintedItemType: 'Please, select the type of printed item',
    errorArticlesNeedToSelectPrintItem: 'You need to select a print item to continue.',

    articlesSelectPrintItemUnsavedArticleFormValuesModalTitle: 'The entered SKU data will be lost',
    articlesSelectPrintItemUnsavedArticleFormValuesModalBody: 'You have changed the print item, so the entered SKU data will be lost.',

    articleFormTabProductInfo: 'Product information',
    articleFormTabProductSetup: 'Product setup',
    articleFormTabOptionsSetup: 'Options setup',
    articleFormTabTemplate: 'Template',

    articleFormProductInfoTabSkuIdLabel: 'SKU ID',
    articleFormProductInfoTabSkuIdPlaceholder: 'FLR 013',
    articleFormProductInfoTabTitleLabel: 'Title',

    articleFormProductSetupTabPrintingLabel: 'Printing',
    articleFormProductSetupTabSizeLabel: 'Size',
    articleFormProductSetupTabSizePlaceholder: 'Select size',
    articleFormProductSetupTabSeePricingButtonLabel: 'See Pricing',
    articleFormPricingTableHeader: 'Pricing',

    articleFormOptionsSetupTabSeeOptionalPricingButtonLabel: 'See Optional Pricing',
    articleFormOptionalPricingTableHeader: 'Optional Pricing',

    articleFormInputValidateOnePaper: 'Required at least one paper',
    articleFormInputValidateWithMatchingsOnePaperMax: 'Only one paper allowed for SKU with matchings',
    articleFormInputValidateWithMatchingsOneOptionValueInOption: 'You can only choose one option value per option with matchings',
    articleFormInputValidateOneQty: 'For each selected paper, at least one quantity must be selected',

    openEditorButton: 'Open Editor',
    opeImageUrlButton: 'Open URL',
    templateLoadingStatus: 'Uploading...',
    templateLoadingStatusThumbnail: 'Thumbnail uploading...',
    templateLoadingStatusPdf: 'Pdf file uploading...',
    templateLoadingStatusBackground: 'Background uploading...',
    templateLoadingStatusImage: '{0} uploading...',
    templateLoadingStatusDone: 'The file was uploaded successfully',

    templateTabNumberingPatternTitle: 'Pattern:',
    templateTabNumberingPrefixTitle: 'Prefix:',
    templateTabNumberingStartFromTitle: 'Start from:',
    templateTabNumberingResultValueTitle: 'Value:',

    templateTabSelectSizeAtTheProductSetup: 'Select the size at the product setup.',

    templateSideOpenCurrentPdf: 'Open current PDF',
    templateSideReprocessPdf: 'Re-process PDF',
    templateSideContentTitle: 'Content',
    templateSideThumbnailAlt: 'Preview',
    templateSideNameFront: 'Front Side',
    templateSideNameBack: 'Back Side',
    templateSideNameUnknown: 'Unknown Side',
    templateSideDisabledText: 'The side is absent',

    templateAccusativeSideNameFront: 'Front Side',
    templateAccusativeSideNameBack: 'Back Side',
    templateAccusativeSideNameUnknown: 'Unknown Side',

    templateAddSide: 'Add {0}',

    templateDeleteModalTitle: 'Delete {0}?',
    templateDeleteModalContent: 'Do you really want to delete {0}?',
    templateDeleteModalOkButton: 'Delete',
    templateDeleteButtonTooltip: 'Delete "{0}"',

    templateValidateAtLeastOneSideIsRequired: 'If the status is published, there must be a template on at least one side',

    articleTitleCopyMark: '(copy)'
  },
  de: {
    articlesPageTitle: 'Print Tickets',
    articlesAddEditPageTitle: 'Ticketkategorie-Parameter',
    articlesSelectPrintItemPageTitle: 'Druckprodukt auswählen',

    articlesSKU: 'Ticketkategorien',

    articlesPageButtonAddNewSku: 'Ticketdesign anlegen',

    articlesPageProductType: 'Produktart',

    articlesDeleteSKU: 'Ticketkategorie löschen',
    articlesDuplicateSKU: 'Artikel kopieren',
    articlesDeleteConfirm: 'Wollen Sie diese Ticketkategorie wirklich löschen?',

    emptyStateNoArticlesTitle: 'Bislang wurden keine Ticketdesigns angelegt',
    emptyStateNoArticlesDescription: 'Klicken Sie auf ‘Ticketdesign anlegen’ um mit dem Anlegen von Ticketkategorien zu beginnen',

    successResponseArticleDeleted: 'Das Ticketdesign wurde erfolgreich gelöscht',
    errorResponseUnableToDeleteArticle: 'Die Ticketkategorie konnte nicht gelöscht werden, da sie bereits in Bestellungen verwendet wird.',

    editorChooseImageButton: 'Bild auswählen',

    articlesSelectPrintedItemType: 'Auf der linken Seite sehen Sie alle zur Verfügung stehenden Druckprodukte. Wählen Sie das für ihren Zweck passende aus, um in den nächsten Schritten das gewünschte Ticketdesign zu konfigurieren.',
    errorArticlesNeedToSelectPrintItem: 'Achtung, sie müssen zunächst ein Druckprodukt auswählen, um eine Ticketdesign zu konfigurieren.',

    articlesSelectPrintItemUnsavedArticleFormValuesModalTitle: 'Achtung, die eingegebenen Daten gehen verloren!',
    articlesSelectPrintItemUnsavedArticleFormValuesModalBody: 'Mit der Auswahl eines anderen Druckprodukts werden alle zuvor schon eingegebenen Daten gelöscht. Die Konfiguration des Ticketdesigns wird neu gestartet.',

    articleFormTabProductInfo: 'Basisdaten zum Ticketdesign',
    articleFormTabProductSetup: 'Druckparameter',
    articleFormTabOptionsSetup: 'Zusatzoptionen',
    articleFormTabTemplate: 'Ticketdesign',

    articleFormProductInfoTabSkuIdLabel: 'Artikel-Nr.',
    articleFormProductInfoTabSkuIdPlaceholder: 'PP-2022-0001',
    articleFormProductInfoTabTitleLabel: 'Name des Ticketdesigns',

    articleFormProductSetupTabSizeLabel: 'Papierformat',
    articleFormProductSetupTabSizePlaceholder: 'Format auswählen',
    articleFormProductSetupTabSeePricingButtonLabel: 'Preise anzeigen',
    articleFormPricingTableHeader: 'Preisübersicht',

    articleFormOptionsSetupTabSeeOptionalPricingButtonLabel: 'Preise für Zusatzoptionen anzeigen',
    articleFormOptionalPricingTableHeader: 'Preisübersicht für Zusatzoptionen',

    articleFormInputValidateOnePaper: 'Es muss mindestens eine Papierqualität ausgewählt werden',
    articleFormInputValidateWithMatchingsOnePaperMax: 'Only one paper allowed for SKU with matchings',
    articleFormInputValidateWithMatchingsOneOptionValueInOption: 'You can only choose one option value per option with matchings',
    articleFormInputValidateOneQty: 'Es muss mindestens eine Auflage ausgewählt sein',

    openEditorButton: 'Editor öffnen',
    opeImageUrlButton: 'Bild anzeigen',
    templateLoadingStatus: 'Übertragen...',
    templateLoadingStatusThumbnail: 'Vorschau laden...',
    templateLoadingStatusPdf: 'PDF übertragen...',
    templateLoadingStatusBackground: 'Vorschau laden...',
    templateLoadingStatusImage: '{0} übertragen...',
    templateLoadingStatusDone: 'Die Datei wurde erfolgreich übertragen',

    templateTabNumberingPatternTitle: 'Schema:',
    templateTabNumberingPrefixTitle: 'Prefix:',
    templateTabNumberingStartFromTitle: 'Startwert:',
    templateTabNumberingResultValueTitle: 'Wert:',

    templateTabSelectSizeAtTheProductSetup: 'Zunächst ein Papierformat in den Basisdaten auswählen.',

    templateSideContentTitle: 'Variable Inhalte',
    templateSideThumbnailAlt: 'Vorschau',
    templateSideNameFront: 'Vorderseite',
    templateSideNameBack: 'Rückseite',
    templateSideNameUnknown: 'Unbekannte Seite',
    templateSideDisabledText: 'Keine Daten vorhanden',

    templateAccusativeSideNameFront: 'Vorderseite',
    templateAccusativeSideNameBack: 'Rückseite',
    templateAccusativeSideNameUnknown: 'Unbekannte Seite',

    templateAddSide: '{0} hinzufügen',

    templateDeleteModalTitle: '{0} löschen?',
    templateDeleteModalContent: 'Wollen Sie wirklich die {0} löschen?',
    templateDeleteModalOkButton: 'Löschen',
    templateDeleteButtonTooltip: '"{0}" löschen',

    templateValidateAtLeastOneSideIsRequired: 'Um eine Ticketkategorie zu veröffentlichen, muss mindestens für eine Seite ein Design angelegt sein',
  },
  ru: {
    articlesPageTitle: 'Артикулы',
    articlesAddEditPageTitle: 'Параметры артикула',
    articlesSelectPrintItemPageTitle: 'Выберите печатный продукт',

    articlesSKU: 'Артикул',

    articlesPageButtonAddNewSku: 'Добавить новый артикул',

    articlesPageProductType: 'Тип продукта',

    articlesDeleteSKU: 'Удалить артикул',
    articlesDuplicateSKU: 'Скопировать артикул',
    articlesDeleteConfirm: 'Вы действительно хотите удалить этот артикул?',

    emptyStateNoArticlesTitle: 'Похоже, что артикулы пока не созданы',
    emptyStateNoArticlesDescription: 'Чтобы начать, нажмите на Добавить новый артикул',

    successResponseArticleDeleted: 'Артикул был успешно удалён',
    errorResponseUnableToDeleteArticle: 'Не удается удалить артикул',

    editorChooseImageButton: 'Загрузить',

    articlesSelectPrintedItemType: 'Слева вы можете увидеть всю доступную печатную продукцию. Выберите подходящий для вашей цели товар, чтобы на следующих этапах настроить выбранный товар на основе имеющихся параметров (размер, бумага, количество и т.д.).',
    errorArticlesNeedToSelectPrintItem: 'Выберите печатный продукт, чтобы продолжить.',

    articlesSelectPrintItemUnsavedArticleFormValuesModalTitle: 'Введенные данные артикула будут потеряны',
    articlesSelectPrintItemUnsavedArticleFormValuesModalBody: 'При выборе другого печатного продукта все ранее введенные данные о товаре удалятся. Настройка элемента будет перезапущена.',

    articleFormTabProductInfo: 'Информация о продукте',
    articleFormTabProductSetup: 'Настройка продукта',
    articleFormTabOptionsSetup: 'Настройка параметров',
    articleFormTabTemplate: 'Шаблон',

    articleFormProductInfoTabSkuIdLabel: 'ID артикула',
    articleFormProductInfoTabSkuIdPlaceholder: 'АРТ-0123',
    articleFormProductInfoTabTitleLabel: 'Название',

    articleFormProductSetupTabPrintingLabel: 'Печать',
    articleFormProductSetupTabSizeLabel: 'Формат',
    articleFormProductSetupTabSizePlaceholder: 'Выберите формат',
    articleFormProductSetupTabSeePricingButtonLabel: 'Посмотреть цены',
    articleFormPricingTableHeader: 'Обзор цен',

    articleFormOptionsSetupTabSeeOptionalPricingButtonLabel: 'Посмотреть цены',
    articleFormOptionalPricingTableHeader: 'Обзор цен на дополнительные опции',

    articleFormInputValidateOnePaper: 'Должен быть выбран хотя бы один тип бумаги',
    articleFormInputValidateWithMatchingsOnePaperMax: 'Только один тип бумаги разрешен для артикула с внешними полями',
    articleFormInputValidateWithMatchingsOneOptionValueInOption: 'Вы можете выбрать только одну подопцию в каждой опции для артикула с внешними полями',
    articleFormInputValidateOneQty: 'Должен быть выбран хотя бы один тираж',

    openEditorButton: 'Открыть редактор',
    opeImageUrlButton: 'Открыть',
    templateLoadingStatus: 'Загрузка...',
    templateLoadingStatusThumbnail: 'Загрузка предварительного просмотра...',
    templateLoadingStatusPdf: 'Загрузка PDF-файла...',
    templateLoadingStatusBackground: 'Загрузка фонового изображения...',
    templateLoadingStatusImage: '{0} загрузка...',
    templateLoadingStatusDone: 'Файл был успешно загружен',

    templateTabNumberingPatternTitle: 'Паттерн:',
    templateTabNumberingPrefixTitle: 'Префикс:',
    templateTabNumberingStartFromTitle: 'Начать с:',
    templateTabNumberingResultValueTitle: 'Финальное значение:',

    templateTabSelectSizeAtTheProductSetup: 'Сначала выберите формат в настройках продукта.',

    templateSideOpenCurrentPdf: 'Открыть текущую PDF',
    templateSideReprocessPdf: 'Обработать PDF повторно',
    templateSideContentTitle: 'Контент',
    templateSideThumbnailAlt: 'Предварительный просмотр',
    templateSideNameFront: 'Передняя сторона',
    templateSideNameBack: 'Задняя сторона',
    templateSideNameUnknown: 'Неизвестная сторона',
    templateSideDisabledText: 'Сторона отсутствует',

    templateAddSide: 'Добавить {0}',

    templateDeleteModalTitle: 'Удалить {0}?',
    templateDeleteModalContent: 'Вы действительно хотите удалить {0}?',
    templateDeleteModalOkButton: 'Удалить',
    templateDeleteButtonTooltip: 'Удалить {0}',

    templateAccusativeSideNameFront: 'переднюю сторону',
    templateAccusativeSideNameBack: 'заднюю сторону',
    templateAccusativeSideNameUnknown: 'неизвестную сторону',

    templateValidateAtLeastOneSideIsRequired: 'Чтобы опубликовать артикул, должен быть создан шаблон как минимум для одной стороны',

    articleTitleCopyMark: '(копия)'
  },
};

export default strings;
