import { useMutation } from 'react-query';

import instance from 'lib/apiClient';
import { TPrintItemsItem } from 'types';
import { IResponseError } from 'constants/types';
import { useInvalidateGetPrintItems } from './useGetPrintItems';

const client = instance.getClient();

export const DELETE_PRINT_ITEM_QUERY = ['printItems', 'delete'];

export const deletePrintItem = (id: string, pspId: string): Promise<TPrintItemsItem> => client
  .delete(`/print-items/${id}?pspId=${pspId}`)
  .then(res => res.data);

export const useDeletePrintItem = () => {
  const invalidatePrintItems = useInvalidateGetPrintItems();

  return useMutation<TPrintItemsItem, IResponseError, { id: string, pspId: string }>(
    [...DELETE_PRINT_ITEM_QUERY],
    data => deletePrintItem(data.id, data.pspId),
    {
      onSuccess: () => invalidatePrintItems(),
    }
  );
};

export default useDeletePrintItem;
