import React, { useMemo, useState } from 'react';
import {
  components,
  GroupBase,
  OptionProps,
  SingleValue,
} from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import classNames from 'classnames';
import Icon from 'components/library/Icon';
import Select from 'components/library/Select';
import Tooltip from 'components/library/Tooltip';
import Button from 'components/library/Button';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { ETemplateSides } from 'types';
import strings from 'constants/localization';
import useEditorData from '../../../../hooks/useEditorData';
import { ETemplateSideOption, EViewportModes } from '../../../../constants';
import css from './SideSelector.module.css';
import SideDeleteModal from './SideDeleteModal';

const CustomOption = (props: OptionProps<ETemplateSideOption>, handleClickDelete: Function): JSX.Element => {
  const { data, label } = props;
  const title = useMemo<string>(() => data?.accusative ?? label, [label, data?.accusative]);
  const justify = {
    [css.justifySpaceBetween]: data.exists,
    [css.justifyFlexStart]: !data.exists,
  };
  return (
    <div className={classNames(css.optionWrapper, justify)}>
      <components.Option {...props}>
        <div className={classNames(css.optionContent, justify)}>
          {!data.exists
            ? (
              <>
                <Icon className={css.optionIcon} type={E_ICON_TYPE.add} />
                {strings.formatString(strings.templateAddSide, title)}
              </>
            )
            : label}
        </div>
      </components.Option>
      {
        data.exists && (
          <Tooltip
            title={strings.formatString(strings.templateDeleteButtonTooltip, title)}
            placement="top"
            arrow
          >
            <Button
              type="button"
              buttonType="tertiary"
              buttonStyle="circle"
              onClick={() => handleClickDelete(data.value)}
              className={css.deleteButton}
            >
              <Icon type={E_ICON_TYPE.delete} />
            </Button>
          </Tooltip>
        )
      }
    </div>
  );
};

const SideSelector:React.FC = () => {
  const {
    setMode,
    side,
    setSide,
    frontSidePdfDocument,
    backSidePdfDocument,
    uploadingStatus,
    canvasLoadingStatus,
  } = useEditorData();

  const [deleteSide, setDeleteSide] = useState<ETemplateSides | null>(null);
  const hasFrontSide = useMemo(() => !!frontSidePdfDocument, [frontSidePdfDocument]);
  const hasBackSide = useMemo(() => !!backSidePdfDocument, [backSidePdfDocument]);

  const isDisabled = useMemo<boolean>(
    () => !!uploadingStatus || canvasLoadingStatus,
    [side, uploadingStatus, canvasLoadingStatus]
  );

  const options:ETemplateSideOption[] = useMemo(() => [
    {
      label: strings.templateSideNameFront,
      accusative: strings.templateAccusativeSideNameFront,
      value: ETemplateSides.FRONT,
      exists: hasFrontSide,
    },
    {
      label: strings.templateSideNameBack,
      accusative: strings.templateAccusativeSideNameBack,
      value: ETemplateSides.BACK,
      exists: hasBackSide,
    },
  ], [side, hasFrontSide, hasBackSide]);

  const value = useMemo<ETemplateSideOption>(() => options.find(({ value: v }) => v === side) ?? options[0], [side]);

  const handleChange = (newValue: SingleValue<ETemplateSideOption>) => {
    if (newValue) {
      setSide(newValue.value);
      setMode(EViewportModes.Edit);
    }
  };

  const customStyles: StylesConfig<ETemplateSideOption, false, GroupBase<ETemplateSideOption>> = {
    option: styles => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: styles => ({
      ...styles,
      cursor: 'pointer',
    }),
  };

  return (
    <>
      <SideDeleteModal side={deleteSide} onClose={() => setDeleteSide(null)} />
      <Select<ETemplateSideOption>
        isSearchable={false}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        options={options}
        className={css.select}
        onChange={handleChange}
        value={value}
        components={{ Option: (option: OptionProps<ETemplateSideOption>) => CustomOption(option, setDeleteSide) }}
        styles={customStyles}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default SideSelector;
