import React, { useCallback, useMemo, useState } from 'react';
import {
  ETemplateSides, TArticle, TArticleDocument, TOrderItem
} from 'types';
import cls from 'classnames';
import Modal from 'components/library/Modal';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import css from './ModalSummary.module.css';
import { IOrderState } from '../../ContextOrder';
import { addValuesAfterComma } from '../../../../../../helpers';
import Slider from '../Slider/Slider';

interface IModalSummary {
  sides: TArticleDocument[],
  items: TOrderItem[],
  onSave: () => void,
  onCancel: () => void,
  options: IOrderState['optionsState'],
  price: number,
  isOpen: boolean,
  article: TArticle,
}

const ModalSummary:React.FC<IModalSummary> = ({
  sides, onSave, price, options, items, onCancel, article, isOpen = false
}) => {
  const [activeSide, setActiveSide] = useState<ETemplateSides>(sides[0].side);
  const description = useMemo(() => {
    const string = [];
    const count = items.length;
    if (count > 0) {
      if (count > 1) {
        string.push(strings.formatString(strings.orderModalSummaryItemsCountPlural, count));
      } else {
        string.push(strings.formatString(strings.orderModalSummaryItemsCountSingular, count));
      }
    }
    string.push(article.printItem.title);
    const sizeUnits = `${article?.size?.width} x ${article?.size?.height} ${article.printItem.units}`;
    string.push(sizeUnits);
    Object.values(options).forEach(option => {
      if (option?.value?.value) {
        string.push(option.value.value);
      }
    });

    return `${string.join(', ')}.`;
  }, [options, items, article]);
  const stringPrice = addValuesAfterComma(String(price));

  const changeSlide = useCallback((prev:number, next:number) => {
    const side = sides[next];
    if (side) {
      setActiveSide(side.side);
    }
  }, [setActiveSide]);
  const onClose = useCallback(() => {
    onCancel();
    setActiveSide(sides[0].side);
  }, [onCancel]);

  const updatedSides = useMemo(() => sides.map(side => ({
    ...side,
    canvas: {
      ...side.canvas,
      objects: side.canvas.objects.map(object => {
        const itemObject = items[0]?.items.find(i => i.id === object.id);
        return itemObject || object;
      })
    }
  })), [sides, items]);
  return (
    <Modal
      isOpen={isOpen}
      title={strings.orderModalSummaryTitle}
      onCancel={onClose}
      onOk={onSave}
      onRequestClose={onClose}
      cancelLabel={strings.actionLabelCancel}
      okLabel={strings.orderModalSummaryOkActionText}
    >
      <p>{strings.orderModalSummaryDescription}</p>
      <div className={css.row}>
        <div className={css.cell}>
          <div className={css.sliderContainer}>
            <Slider
              activeSide={activeSide}
              changeSlide={changeSlide}
              zoom={0}
              sides={updatedSides}
              setCanvasLoadingStatus={() => {}}
            />
          </div>
        </div>
        <div className={css.cell}>
          <span className={cls(textCss.h4, css.title)}>{article.title}, {article.size?.name}</span>
          <span className={css.description}>{description}</span>
          <span className={textCss.h2}>{stringPrice}{article.currency}</span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSummary;
