import React, { useMemo } from 'react';

import Button from 'components/library/Button';

import strings from 'constants/localization';
import css from './CustomControls.module.css';

interface IAddEventModalControls {
  closeModal: () => void
  onSave: () => void
  disabled: boolean
  disabledSubmit: boolean
}

const AddEventModalControls: React.FC<IAddEventModalControls> = ({
  closeModal, onSave, disabledSubmit, disabled
}) => {
  const isSubmitDisabled = useMemo(() => disabled || disabledSubmit, [disabled, disabledSubmit]);

  return (
    <div className={css.controls}>
      <Button
        type="button"
        buttonType="secondary"
        onClick={closeModal}
        disabled={disabled}
      >
        {strings.actionLabelCancel}
      </Button>
      <div>
        <Button
          type="button"
          buttonType="primary"
          onClick={onSave}
          disabled={isSubmitDisabled}
        >
          {strings.eventsPageAddNewEventModalButtonAdd}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(AddEventModalControls);
