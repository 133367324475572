import React, {
  BaseSyntheticEvent, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';

import Loader from 'components/library/Loader';
import PageContent from 'components/library/PageContent';
import Table from 'components/library/Table';
import Input from 'components/library/Input';
import Pagination from 'components/library/Pagination';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Select from 'components/library/Select';
import Modal from 'components/library/Modal';
import PortfolioAddNewButton from 'components/PagePortfolio/components/PortfolioAddNewButton';

import { TSort } from 'components/library/Table/types';
import { TPageSizeOptionsItem, TPrintItemsItem } from 'types';
import { IGetPrintItemsParams } from 'types/Portfolio';
import useGetPrintItems from 'hooks/Portfolio/useGetPrintItems';
import useDeletePrintItem from 'hooks/Portfolio/useDeletePrintItem';
import UserContext from 'contexts/ContextUser';
import AlertContext from 'contexts/ContextAlert';
import { IResponseError } from 'constants/types';
import { MIN_PAGE, PAGE_SIZE_OPTIONS, TITLE_ORDER_OPTIONS } from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import get from 'lodash/get';
import { Location } from 'history';
import { NavigateFunction } from 'react-router/lib/hooks';
import css from './PagePortfolio.module.css';
import useColumns from './useColumns';
import { navigateWithState, showEmptyState } from '../../helpers';
import useQueryParams from '../../hooks/useQueryParams';

export interface IStateParams {
  page?: number
  search: string
  order?: TSort
  limit?: TPageSizeOptionsItem
}

interface IPortfolio {
  state: IStateParams
  location: Location
  navigate: NavigateFunction
  pspId: string | undefined
}

const Portfolio: React.FC<IPortfolio> = props => {
  const {
    state,
    location,
    navigate,
    pspId,
  } = props;

  const [searchActive, setSearchActive] = useState<boolean>(!!state.search);
  const [searchText, setSearchText] = useState<string>(state.search);
  useEffect(() => {
    setSearchText(state.search);
    if (state.search) {
      setSearchActive(true);
    }
  }, [state.search]);

  const [limit, setLimit] = useState<TPageSizeOptionsItem>(state.limit || PAGE_SIZE_OPTIONS[0]);
  useEffect(() => setLimit(state.limit || PAGE_SIZE_OPTIONS[0]), [state.limit]);

  const [page, setPage] = useState<number>(state.page || MIN_PAGE);
  useEffect(() => setPage(state.page || MIN_PAGE), [state.page]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<string>('');

  const [order, setOrder] = useState<TSort>(state.order || TITLE_ORDER_OPTIONS[2].value);
  useEffect(() => setOrder(state.order || TITLE_ORDER_OPTIONS[2].value), [state.order]);

  const limitValue = useMemo<number>(
    () => limit?.value || PAGE_SIZE_OPTIONS[0].value,
    [limit]
  );

  const { push } = useContext(AlertContext);

  const {
    data = { data: [], total: 0 },
    isLoading,
    error
  } = useGetPrintItems({
    // TODO: search input debounce
    includeQuantities: true,
    includePapers: true,
    pspId,
    search: searchText,
    skip: (page - 1) * limitValue,
    limit: limitValue,
    sort: order,
  } as IGetPrintItemsParams);
  const {
    mutateAsync: deletePrintItemM,
    isLoading: isDeleting,
  } = useDeletePrintItem();

  const isFetching = useMemo(
    () => isLoading || isDeleting,
    [isLoading, isDeleting]
  );

  const isDisabled = useMemo(
    () => isFetching || !!error,
    [isFetching, error]
  );

  const toggleSearchActive = (): void => {
    setSearchActive(prev => !prev);
  };

  const toggleShowModal = (): void => {
    setShowModal(prev => !prev);
  };

  const handleSearchTextChange = useCallback((e: BaseSyntheticEvent): void => {
    const { value } = e.target;
    setPage(MIN_PAGE);
    setSearchText(value);
    navigateWithState(navigate, location, { page: MIN_PAGE, search: value }, { replace: true });
  }, [setPage, setSearchText, location]);

  const handleSetLimit = useCallback(
    (value: SingleValue<TPageSizeOptionsItem>) => {
      if (limit !== value) {
        setLimit(value || PAGE_SIZE_OPTIONS[0]);
        setPage(MIN_PAGE);
        navigateWithState(navigate, location, { page: MIN_PAGE, limit: value });
      }
    },
    [limit, setPage, setLimit, location]
  );

  const handlePrintItemDelete = useCallback(
    () => deletePrintItemM({ id: idToDelete, pspId: pspId as string })
      .then(() => {
        push({ severity: 'success', message: strings.successResponsePrintItemDeleted });
      })
      .catch((err: IResponseError) => {
        const errMsg = err?.response?.data?.message;
        push({ severity: 'error', message: errMsg || strings.errorResponseUnableToDeletePrintItem });
      })
      .finally(() => {
        setShowModal(false);
      }),
    [idToDelete]
  );

  const onClickRow = (id: string) => {
    navigate(id);
  };

  const handleSetOrder = useCallback((value: TSort): void => {
    setOrder(value);
    navigateWithState(navigate, location, { order: value });
  }, [location, setOrder]);

  const columns = useColumns({
    order, setOrder: handleSetOrder, setIdToDelete, toggleModal: toggleShowModal
  });

  const onChangePage = useCallback((event: any, value:number) => {
    if (page !== value) {
      setPage(value);
      navigateWithState(navigate, location, { page: value });
    }
  }, [page, setPage, location]);

  return (
    <PageContent pageTitle={strings.portfolioPageTitle} withBreadcrumbs>
      <div className={css.container}>
        <div className={css.header}>
          <div className={css.left}>
            <Select
              className={css.select}
              value={limit}
              options={PAGE_SIZE_OPTIONS}
              isDisabled={isDisabled}
              onChange={handleSetLimit}
              isSearchable={false}
            />
          </div>
          <div className={css.right}>
            <Input
              className={css.search}
              searchActive={searchActive}
              placeholder={strings.inputSearchTextPlaceholder}
              value={searchText}
              disabled={isDeleting}
              onChange={handleSearchTextChange}
              toggleSearchActive={toggleSearchActive}
            />
            <PortfolioAddNewButton />
          </div>
        </div>
        <Table<TPrintItemsItem>
          data={data.data}
          columns={columns}
          meta={{
            onClickRow,
          }}
        />
        {isFetching && <Loader /> }
        {showEmptyState(!isFetching, data.data.length, !searchText) && (
          <EmptyState
            label={strings.emptyStateNoPrintItemsTitle}
            description={strings.emptyStateNoPrintItemsDescription}
            variant={EmptyStates.NO_RESULTS}
          >
            <PortfolioAddNewButton />
          </EmptyState>
        )}
        {showEmptyState(!isFetching, data.data.length, !!searchText) && (
          <div className={css.noContent}>
            <EmptyState label={strings.emptyStateTitleNoResults} description={strings.emptyStateTitleNoResultsDescription} variant={EmptyStates.NO_RESULTS} />
          </div>
        )}
        {!isFetching && data.data.length > 0 && (
          <Pagination
            className={css.pagination}
            page={page}
            count={Math.ceil(data.total / limitValue) || undefined}
            disabled={isFetching}
            onChange={onChangePage}
          />
        )}
        <Modal
          isOpen={showModal}
          title={strings.printItemsDelete}
          onRequestClose={toggleShowModal}
          onOk={handlePrintItemDelete}
          onCancel={toggleShowModal}
        >
          <div className={textCss.pMedium3}>{strings.printItemsDeleteConfirm}</div>
        </Modal>
      </div>
    </PageContent>
  );
};

const Container = () => {
  const query = useQueryParams();
  const location = useLocation();
  const navigate = useNavigate();
  const contextUser = useContext(UserContext);
  const pspId = get(contextUser, 'user.pspId');

  const params = useMemo<IStateParams>(() => ({
    page: parseInt(get(location.state, 'page') || query.get('page') || '', 10),
    limit: get(location.state, 'limit'),
    search: get(location.state, 'search', ''),
    order: get(location.state, 'order'),
  }), [location, query]);

  return useMemo(() => (
    <Portfolio
      state={params}
      location={location}
      navigate={navigate}
      pspId={pspId}
    />
  ), [params, location, navigate, pspId]);
};

export default Container;
