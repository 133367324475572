/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconSquaredChat(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 12C21 8.22876 21 6.34315 19.8284 5.17157C18.6569 4 16.7712 4 13 4H10.5C7.21252 4 5.56878 4 4.46243 4.90796C4.25989 5.07418 4.07418 5.25989 3.90796 5.46243C3 6.56878 3 8.21252 3 11.5V11.5C3 14.7875 3 16.4312 3.90796 17.5376C4.07418 17.7401 4.25989 17.9258 4.46243 18.092C5.56878 19 7.21252 19 10.5 19H14.6978C15.3324 19 15.6497 19 15.9526 19.0467C16.5875 19.1446 17.1894 19.3939 17.7076 19.7736C17.9548 19.9548 18.1792 20.1792 18.6279 20.6279V20.6279C19.1813 21.1813 19.458 21.458 19.6793 21.533C20.1541 21.6937 20.6745 21.4781 20.8965 21.0287C21 20.8193 21 20.428 21 19.6454V12Z" stroke="#BCDEF0" strokeWidth="1.8" strokeLinejoin="round" />
      <circle cx="7.5" cy="11.5" r="0.75" stroke="#BCDEF0" strokeWidth="1.5" />
      <circle cx="12" cy="11.5" r="0.75" stroke="#BCDEF0" strokeWidth="1.5" />
      <circle cx="16.5" cy="11.5" r="0.75" stroke="#BCDEF0" strokeWidth="1.5" />
    </svg>
  );
}

export default IconSquaredChat;
