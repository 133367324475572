import React from 'react';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Button from 'components/library/Button';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import { IObject } from '../../../../../../components/Editor/contexts/EditorContext';
import css from './DeleteButton.module.css';

interface IDeleteButton {
  object: IObject['object'],
}

const DeleteButton:React.FC<IDeleteButton> = ({ object }) => {
  const {
    canvas,
  } = useEditorData();

  const handleClick = () => {
    if (canvas.current) {
      canvas.current.remove(object);
    }
  };

  return (
    <Button
      className={css.button}
      disabled={object.locked}
      buttonType="tertiary"
      type="button"
      onClick={handleClick}
    >
      <Icon type={ICON_TYPES.delete} />
    </Button>
  );
};

export default DeleteButton;
