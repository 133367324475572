/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconBack(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.70756 1.12799C6.80026 1.2205 6.87381 1.33039 6.92399 1.45137C6.97417 1.57234 7 1.70202 7 1.83299C7 1.96396 6.97417 2.09364 6.92399 2.21461C6.87381 2.33559 6.80026 2.44548 6.70756 2.53799L2.82756 6.41799L6.70756 10.298C6.80014 10.3906 6.87358 10.5005 6.92368 10.6214C6.97379 10.7424 6.99958 10.8721 6.99958 11.003C6.99958 11.1339 6.97379 11.2636 6.92368 11.3845C6.87358 11.5055 6.80014 11.6154 6.70756 11.708C6.61497 11.8006 6.50506 11.874 6.3841 11.9241C6.26314 11.9742 6.13349 12 6.00256 12C5.87163 12 5.74198 11.9742 5.62101 11.9241C5.50005 11.874 5.39014 11.8006 5.29756 11.708L0.707556 7.11799C0.614853 7.02548 0.541305 6.91559 0.491123 6.79462C0.440942 6.67364 0.415112 6.54396 0.415112 6.41299C0.415112 6.28202 0.440942 6.15234 0.491123 6.03137C0.541305 5.91039 0.614853 5.8005 0.707556 5.70799L5.29756 1.11799C5.67756 0.737991 6.31756 0.737991 6.70756 1.12799Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconBack;
