import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from 'history';

import textCss from 'assets/styles/Text.module.css';
import Modal from 'components/library/Modal';
import strings from 'constants/localization';
import useConfirmPageLeaving from 'hooks/useConfirmPageLeaving';
import useBlocker from './useBlocker';

interface IUnsavedChangesModal {
  areChangesExist: boolean,
  routesExceptions?: string[],
}
const UnsavedChangesModal: React.FC<IUnsavedChangesModal> = ({ areChangesExist, routesExceptions }) => {
  useConfirmPageLeaving(areChangesExist);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [transition, setTransition] = useState<Transition>();

  const handleBlockedNavigation = useCallback(
    (autoUnblockingTx: Transition) => {
      if (routesExceptions?.includes(autoUnblockingTx.location.pathname)) {
        autoUnblockingTx.retry();
        return;
      }
      if (location.pathname !== autoUnblockingTx.location.pathname) {
        setIsModalOpen(true);
        setTransition(autoUnblockingTx);
      }
    },
    [],
  );

  const confirmNavigation = () => {
    setIsModalOpen(false);
    transition?.retry();
  };

  const cancelNavigation = () => {
    setIsModalOpen(false);
  };

  useBlocker(handleBlockedNavigation, areChangesExist);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={cancelNavigation}
      onOk={confirmNavigation}
      onCancel={cancelNavigation}
      title={strings.modalUnsavedChangesTitle}
    >
      <span className={textCss.pMedium3}>{strings.modalUnsavedChangesBody}</span>
    </Modal>
  );
};

export default UnsavedChangesModal;
