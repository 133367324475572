/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignBottom(props: IIconProps) {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 20H1C0.45 20 0 19.55 0 19C0 18.45 0.45 18 1 18H19C19.55 18 20 18.45 20 19C20 19.55 19.55 20 19 20ZM6.5 0C5.67 0 5 0.67 5 1.5V14.5C5 15.33 5.67 16 6.5 16C7.33 16 8 15.33 8 14.5V1.5C8 0.67 7.33 0 6.5 0ZM13.5 6C12.67 6 12 6.67 12 7.5V14.5C12 15.33 12.67 16 13.5 16C14.33 16 15 15.33 15 14.5V7.5C15 6.67 14.33 6 13.5 6Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignBottom;
