import React, { useContext, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import { ETemplateSides } from 'types';
import { nanoid } from 'nanoid';
import SidebarButton from '../../../components/SidebarButton';
import { getBitmap } from '../../../../../../components/Editor/components/Viewport/fabric/bitmap';
import { IPdfDocument } from '../../../../../../components/Editor/contexts/EditorContext';

interface IDestination {
  side: ETemplateSides,
  document: IPdfDocument | null,
  setDocument: React.Dispatch<React.SetStateAction<IPdfDocument | null>>
  setData: React.Dispatch<any>
  data: any
}

const Duplicate:React.FC = () => {
  const { push } = useContext(AlertContext);
  const {
    activeObject,
    setSide,
    pdfDocument,
    json,
  } = useEditorData();

  const destination = useDestination();

  const isDisabled = useMemo<boolean>(
    () => !!destination.data?.objects?.find((obj: any) => !!obj?.isBackground),
    [destination.data]
  );

  const handleChange = () => {
    if (!pdfDocument) {
      return;
    }

    createBackgroundCopy(json, pdfDocument, activeObject, destination);
    setSide(destination.side);
    push({ severity: 'success', message: strings.editorTabSettingsDuplicateSuccess });
  };

  const tooltipTitle = useMemo(
    () => isDisabled
      ? strings.editorTabSettingsDuplicateDisabledTooltip
      : strings.editorTabSettingsDuplicateTooltip,
    [isDisabled]
  );

  return (
    <Tooltip placement="top" title={tooltipTitle} arrow>
      <span>
        <SidebarButton disabled={isDisabled} onClick={handleChange}>
          <Icon type={ICON_TYPES.duplicate} />
        </SidebarButton>
      </span>
    </Tooltip>
  );
};

const useDestination = (): IDestination => {
  const {
    side,
    frontSidePdfDocument,
    backSidePdfDocument,
    setFrontSidePdfDocument,
    setBackSidePdfDocument,
    frontJson,
    backJson,
    setFrontJson,
    setBackJson,
  } = useEditorData();

  return useMemo<IDestination>(() => side === ETemplateSides.FRONT
    ? {
      side: ETemplateSides.BACK,
      document: backSidePdfDocument,
      setDocument: setBackSidePdfDocument,
      setData: setBackJson,
      data: backJson,
    }
    : {
      side: ETemplateSides.FRONT,
      document: frontSidePdfDocument,
      setDocument: setFrontSidePdfDocument,
      setData: setFrontJson,
      data: frontJson
    }, [side, frontSidePdfDocument, backSidePdfDocument, frontJson, backJson]);
};

const createBackgroundCopy = (json: any, pdfDocument: IPdfDocument, object: any, destination: IDestination) => {
  // create copy of background object with new ID
  const background = object.toJSON();
  background.id = nanoid();

  // if side exists - copy only background object
  if (destination.document) {
    const data = { ...destination.data };
    // prepend background object
    data.objects = [background, ...data.objects];

    destination.setData(data);
  } else {
    const data = { ...json };
    // copy only active object (background)
    data.objects = [background];

    if (destination.side === ETemplateSides.BACK) {
      // create new bitmap for back side
      const { width, height, bleed = 0 } = pdfDocument;
      const bitmap = getBitmap({ width, height, bleed });
      data.overlayImage = bitmap.toJSON();
    } else {
      // remove bitmap
      data.overlayImage = null;
    }

    destination.setDocument({ ...pdfDocument });
    destination.setData(data);
  }
};

export default Duplicate;
