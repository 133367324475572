import { useQuery } from 'react-query';

import instance from 'lib/apiClient';
import { IPaylogicErrorRes } from 'types/Integration/index';
import { IPaylogicTicket } from '../../types';

const PAYLOGIC_INTEGRATION = 'paylogicIntegration';

export const getTickets = (token: string | null | undefined, eventId: string): Promise<IPaylogicTicket[]> => instance.getClient()
  .get(`/paylogic-api?event_id=${eventId}&type=ticket-types`)
  .then(res => res.data);

export const QUERY_KEY_PAYLOGIC_TICKETS = [PAYLOGIC_INTEGRATION, 'getTickets'];
export function useGetTickets(token: string | null | undefined, eventId: string | null | undefined) {
  return useQuery<IPaylogicTicket[] | undefined, IPaylogicErrorRes>(
    [...QUERY_KEY_PAYLOGIC_TICKETS, token, eventId],
    () => getTickets(token, eventId!),
    {
      enabled: ((eventId?.length || 0) > 0) && !!token,
      retry: 1,
    }
  );
}
