import React from 'react';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import { Wrapper } from 'components/PageLogin';
import useQueryParams from 'hooks/useQueryParams';
import css from './UserBlocked.module.css';

function UserBlocked() {
  const query = useQueryParams();
  const email = query.get('provider');
  localStorage.removeItem('token');
  return (
    <Wrapper>
      <Icon type={ICON_TYPES.blocked} className={css.icon} />
      <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageBlockedTitle}</span>
      <span className={cls(textCss.pMedium2, css.description)}>
        {
          strings.formatString(
            strings.loginPageBlockedDescription,
            <a className={cls(textCss.pMedium2, css.link)} href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
          )
        }
      </span>
    </Wrapper>
  );
}

export default UserBlocked;
