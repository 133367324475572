import React, {
  useCallback, useEffect, useState
} from 'react';
import { useParser } from 'hooks/useParser';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import { TExcelMatchings } from 'types';
import css from './ChooseTicketModalContentInput.module.css';
import { ITicketType } from './components/TicketTypeSelect';
import UploadFile from './components/ExcelModal/components/UploadFile';
import ExcelModal from './components/ExcelModal';

export interface IChooseTicketModalContentExcel{
  onMatched?: () => void,
  matchingsTicket: ITicketType | null,
  chosenTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
  setExcelMatchings: React.Dispatch<React.SetStateAction<TExcelMatchings | null>>
}

const ChooseTicketModalContentExcel: React.FC<IChooseTicketModalContentExcel> = props => {
  const { chosenTicket, onMatched } = props;
  const [file, setFile] = useState<File | null>(null);
  const parseFile = useParser();
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [data, setData] = useState<TRow[]>([]);

  const removeFile = () => {
    setFile(null);
    setData([]);
  };

  const onError = useCallback(() => {
    setIsFileLoading(false);
    setFile(null);
  }, [setIsFileLoading, setFile]);

  useEffect(() => {
    if (file) {
      const fileType = file.name.split('.').pop() as 'csv' | 'xlsx';
      setIsFileLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        parseFile
          .mutateAsync({ type: fileType, file: reader.result || '' })
          .then(setData).finally(() => setIsFileLoading(false));
      };
      reader.onerror = onError;
      reader.onabort = onError;
    }
  }, [file]);

  useEffect(() => {
    if (file && chosenTicket && onMatched) {
      onMatched();
    }
  }, [file, chosenTicket]);

  return (
    <>
      <div className={css.ticketContainer}>
        <UploadFile setFile={setFile} loading={isFileLoading} />
      </div>

      { file && <ExcelModal file={file} data={data} onClose={removeFile} {...props} /> }
    </>
  );
};

export default ChooseTicketModalContentExcel;
