import { useEffect } from 'react';

const useConfirmPageLeaving = (areChangesExist: boolean = true): void => {
  const alertUser = (e: BeforeUnloadEvent) => {
    if (areChangesExist) {
      // Show default browser confirmation modal
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);
};

export default useConfirmPageLeaving;
