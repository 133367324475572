import { EIntegrationServices, IPaylogicTicket } from 'types';
import { IEventbriteErrorData, IEventbriteGetTicketsRes, IPaylogicErrorData } from 'types/Integration';
import { useGetTickets as useGetTicketsEventbrite } from 'hooks/Integration/useEventbrite';
import { useGetTickets as useGetTicketsPaylogic } from 'hooks/Integration/usePaylogic';
import { ITicketType } from './components/TicketTypeSelect';

interface ITransformPaylogicData {
  service: EIntegrationServices.PAYLOGIC;
  data: IPaylogicTicket[];
}

interface ITransformEventbriteData {
  service: EIntegrationServices.EVENTBRITE;
  data: IEventbriteGetTicketsRes;
}

interface ITransformAnyData {
  service: EIntegrationServices;
  data: any;
}

type TTransformResponse = ITransformPaylogicData | ITransformEventbriteData | ITransformAnyData;

export const transformTicketsData = ({ service, data }: TTransformResponse): ITicketType[] => {
  switch (service) {
    case EIntegrationServices.PAYLOGIC:
      return transformPaylogicData(data);
    case EIntegrationServices.EVENTBRITE:
      return transformEventbriteData(data);
    default:
      return [];
  }
};

const transformPaylogicData = (data: ITransformPaylogicData['data']): ITicketType[] => data
  ?.map(({ product_id: id, product_name: name }) => ({ id: id.toString(), name })) ?? [];

const transformEventbriteData = (data: ITransformEventbriteData['data']): ITicketType[] => data?.ticket_classes
  ?.map(({ id, name }) => ({ id, name })) ?? [];

interface IIntegrationServiceError {
  code: number,
  name: string,
  message: string,
}

interface ITransformEventbriteError {
  service: EIntegrationServices.EVENTBRITE;
  data: IEventbriteErrorData,
}

interface ITransformPaylogicError {
  service: EIntegrationServices.PAYLOGIC;
  data: IPaylogicErrorData,
}

interface ITransformAnyError {
  service: EIntegrationServices;
  data: any;
}

type TTransformError = ITransformEventbriteError | ITransformPaylogicError | ITransformAnyError;

export const transformTicketsError = ({ service, data }: TTransformError): IIntegrationServiceError => {
  switch (service) {
    case EIntegrationServices.EVENTBRITE:
      return transformEventbriteError(data);
    default:
      return data;
  }
};

const transformEventbriteError = (data: ITransformEventbriteError['data']): IIntegrationServiceError => ({
  code: data.status_code,
  name: data.error,
  message: data.error_description,
});

export const getUseTicketsHook = (service: EIntegrationServices) => {
  switch (service) {
    case EIntegrationServices.EVENTBRITE:
      return useGetTicketsEventbrite;
    case EIntegrationServices.PAYLOGIC:
      return useGetTicketsPaylogic;
    default:
      return () => ({ data: [], isFetching: false, error: null });
  }
};

export default { transformTicketsData, transformTicketsError, getUseTicketsHook };
