import { useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';

const client = instance.getClient();

export const getArticleIntegrationTickets = ():Promise<string[]> => client
  .get('/articles-integration-tickets')
  .then(res => res.data);

export const QUERY_KEY_ARTICLE_INTEGRATION_TICKETS_GET = ['articleIntegrationTickets', 'get'];
export function useGetArticleIntegrationTickets() {
  return useQuery<string[], IResponseError>(
    QUERY_KEY_ARTICLE_INTEGRATION_TICKETS_GET,
    () => getArticleIntegrationTickets(),
  );
}

export const useInvalidateGetArticleIntegrationTickets = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_ARTICLE_INTEGRATION_TICKETS_GET, ...additionalParams]);
};

export default useGetArticleIntegrationTickets;
