import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';

enum EFontStyle {
  Normal = 'normal',
  Italic = 'italic',
}

const FontStyle:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = () => {
    if (activeObject && canvas.current) {
      const newValue: EFontStyle = activeObject.fontStyle !== EFontStyle.Normal ? EFontStyle.Normal : EFontStyle.Italic;

      activeObject.set({ fontStyle: newValue });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const active = useMemo(() => activeObject.fontStyle === EFontStyle.Italic, [activeObject.fontStyle]);

  return (
    <Tooltip placement="top" title={strings.editorTabSettingsFontStyleTooltip} arrow>
      <SidebarButton
        isActive={active}
        onClick={handleChange}
      >
        <Icon type={ICON_TYPES.textItalic} />
      </SidebarButton>
    </Tooltip>
  );
};

export default FontStyle;
