import React, { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';
import css from './FontWeight.module.css';

enum EWeight {
  Regular = 400,
  Bold = 600,
}

const FontWeight:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = () => {
    if (activeObject && canvas.current) {
      const newValue: EWeight = activeObject.fontWeight !== EWeight.Bold ? EWeight.Bold : EWeight.Regular;

      activeObject.set({ fontWeight: newValue });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const active = useMemo(() => activeObject.fontWeight === EWeight.Bold, [activeObject.fontWeight]);

  return (
    <Tooltip placement="top" title={strings.editorTabSettingsFontBoldTooltip} arrow>
      <SidebarButton
        className={css.button}
        isActive={active}
        onClick={handleChange}
      >
        <Icon type={ICON_TYPES.textBold} />
      </SidebarButton>
    </Tooltip>
  );
};

export default FontWeight;
