/* eslint-disable max-len */
import IntegrationStrings from '../types/IntegrationStrings';
import { ILanguages } from '../types';

const strings:ILanguages<IntegrationStrings> = {
  en: {
    integration: 'integration',
    connectToService: 'Connect to {0}',
    bindEvent: 'Bind event',
    reconnect: 'Reconnect',
    connected: 'connected',
    rebind: 'Rebind',
    chooseTicketType: 'Choose ticket type',
    inputTicketTypeId: 'Ticket type ID',
    inputTicketTypeName: 'Ticket type name',
    availableTicketsForEvent: 'Available tickets for "{0}"',
    ticketFieldsMapping: 'Ticket fields mapping',
    bindedWithEventOfService: 'Binded with an event "{0}" of "{1}" service',
    connectTicket: 'Connect Ticket',
    connectedToIntegrationService: 'Connected to the integration service "{0}"',
    managerNotConnectedToIntegrationService: 'Manager is not connected to any of integration services',
    yourAccountNotBindedYet: 'Your account is not binded with an integration event yet',

    orderFieldLabelAttendeeFullName: 'Attendee full name',
    orderFieldLabelAttendeeFirstName: 'Attendee first name',
    orderFieldLabelAttendeeLastName: 'Attendee last name',
    orderFieldLabelAttendeeEmail: 'Attendee email',
    orderFieldLabelAttendeeBarcode: 'Attendee barcode',
    orderFieldLabelPrice: 'Price',
    orderFieldLabelPriceValue: 'Price value',
    orderFieldLabelPriceCurrency: 'Price currency',
    orderFieldLabelTax: 'Tax',
    orderFieldLabelTaxValue: 'Tax value',
    orderFieldLabelTaxCurrency: 'Tax currency',
    orderFieldLabelTicketType: 'Ticket type',
    orderFieldLabelOrderStatus: 'Order status',
    orderFieldLabelSeatingSeat: 'Seat',
    orderFieldLabelSeatingRow: 'Row',
    orderFieldLabelSeatingSection: 'Tribune',
    orderFieldLabelSeatingEntrance: 'Entrance',

    successConnectTo: 'Successfully connected to {0}',
    successConnectUpdate: 'Successfully updated connection',
    successEventBind: 'Successfully binded an event',
    successEventBindUpdate: 'Successfully updated an event binding',
    successEventBindDelete: 'Successfully deleted an event binding',

    emptyStateLabelNoIntegrationService: 'No integration service',
    emptyStateDescriptionSetConnection: 'Please set connection to integration service in own profile',
    emptyStateLabelNoTickets: 'No tickets',
    emptyStateDescriptionCreateTickets: 'Please, create tickets on a corresponding platform',
    emptyStateLabelNoEventBind: 'No event binding',
    emptyStateDescriptionSeemsLikeNotBindedWithEvent: 'Seems like that SKU is not binded with any event',
    emptyStateLabelNoObjectsForMatching: 'No objects for matching',
    emptyStateDescriptionAddFewObjects: 'Please, add few objects into any of canvas sides',

    errorInvalidOrExpiredIntegrationToken: 'Seems like your integration token is invalid or expired',
    errorIsAlreadyBinded: 'Is already binded to another SKU',

    integrationMatchingFieldsModalTicketFieldTitle: 'Ticket field title',

    inputServiceModalTitle: 'Do you really want to change the integration service?',
    inputServiceModalBody: 'When you change the integration service, you will lose all data bindings for all events',

    inputServiceNameLabel: 'Service name',
    inputTokenLabel: 'Token',
    inputPlatformLabel: 'Platform',
    inputLoginLabel: 'Login',
    inputPasswordLabel: 'Password',
    inputOrganizationLabel: 'Organization',
    inputEventLabel: 'Event',
    inputEventIdLabel: 'Event ID',
    inputEventNameLabel: 'Event Name',

    actionLabelRemoveEventBinding: 'Remove event binding',

    editorLockedButtonDisabledDueToMatchingsTooltip: 'You can not lock an object until you remove it from integration matchings',
  },
  de: {
    integration: 'Ticket-System',
    connectToService: 'Anmelden',
    bindEvent: 'Veranstaltung verknüpfen',
    reconnect: 'Erneut verbinden',
    connected: 'Verbunden',
    rebind: 'Erneut verbinden',
    chooseTicketType: 'Ticketkategorie auswählen',
    availableTicketsForEvent: 'Hier sehen sie alle Ticketkategorien, die für die Veranstaltung "{0}" verfügbar sind',
    ticketFieldsMapping: 'Ticketdaten zuweisen',
    bindedWithEventOfService: 'Dieses Konto ist mit der Veranstaltung "{0}" des "{1}" Systems verknüpft',
    connectTicket: 'Ticketdaten zuweisen',
    connectedToIntegrationService: 'Mit dem “{0}” System verknüpft',
    managerNotConnectedToIntegrationService: 'Diese Veranstalterkonto ist mit keinem Ticketsystem verknüpft',
    yourAccountNotBindedYet: 'Ihr Konto ist bislang nicht mit einem Ticketsystem verknüpft',

    orderFieldLabelAttendeeFullName: 'Name des Besuchers',
    orderFieldLabelAttendeeFirstName: 'Vorname des Besuchers',
    orderFieldLabelAttendeeLastName: 'Nachname des Besuchers',
    orderFieldLabelAttendeeEmail: 'E-Mail des Besuchers',
    orderFieldLabelAttendeeBarcode: 'QR-Code',
    orderFieldLabelPrice: 'Ticketpreis',
    orderFieldLabelPriceValue: 'Ticketpreis (Wert)',
    orderFieldLabelPriceCurrency: 'Ticketpreis (Währung)',
    orderFieldLabelTax: 'Steuern',
    orderFieldLabelTaxValue: 'Steuern (Wert)',
    orderFieldLabelTaxCurrency: 'Steuern (Währung)',
    orderFieldLabelTicketType: 'Ticketkategorie',
    orderFieldLabelOrderStatus: 'Bestell-Status',
    orderFieldLabelSeatingSeat: 'Sitplatz',
    orderFieldLabelSeatingRow: 'Reihe',
    orderFieldLabelSeatingSection: 'Block',
    orderFieldLabelSeatingEntrance: 'Eingang',

    successConnectTo: 'Erfolgreich verknüpft mit “{0}”',
    successConnectUpdate: 'Die Verknüpfung wurde erfolgreich aktualisiert',
    successEventBind: 'Die Veranstaltung wurde erfolgreich verknüpft',
    successEventBindUpdate: 'Die Verknüpfung zur Veranstaltung wurde erfolgreich aktualisiert',
    successEventBindDelete: 'Die Verknüpfung zur Veranstaltung wurde erfolgreich gelöscht',

    emptyStateLabelNoIntegrationService: 'Keine Veranstaltungen zur Auswahl',
    emptyStateDescriptionSetConnection: 'Sie müssen zunächst in ihrem Profil ein Ticket-System auswählen und sich authentifizieren',
    emptyStateLabelNoTickets: 'Es gibt für die Veranstaltung keine Ticketkategorien',
    emptyStateDescriptionCreateTickets: 'Sie müssen zunächst im Ticketsystem Ticketkategorien anlegen, bevor sie hier ein passendes Ticket-Design anlegen können',
    emptyStateLabelNoEventBind: 'Keine Veranstaltung verknüpft',
    emptyStateDescriptionSeemsLikeNotBindedWithEvent: 'Die Ticketart scheint noch nicht mit einer Veranstaltung verknüpft zu sein',
    emptyStateLabelNoObjectsForMatching: 'Ihr Ticket-Design enthält keine variablen Datenfelder',
    emptyStateDescriptionAddFewObjects: 'Sie müssen zunächst variable Text-, Code-, oder Bildboxen in ihrem Design anlegen, um hier dann die Ticketdaten (z.B. Besuchername und QR-Code) zuweisen zu können.',

    errorInvalidOrExpiredIntegrationToken: 'Der Authentifizierungstoken ist ungültig',

    integrationMatchingFieldsModalTicketFieldTitle: 'Bezeichnung der Tickedaten',

    inputServiceNameLabel: 'Ticket-System',
    inputTokenLabel: 'Token',
    inputOrganizationLabel: 'Veranstalter',
    inputEventLabel: 'Veranstaltung',
    inputEventIdLabel: 'Veranstaltung ID',
    inputEventNameLabel: 'Veranstaltungsname',

    actionLabelRemoveEventBinding: 'Verknüpfung zur Veranstaltung löschen',

    editorLockedButtonDisabledDueToMatchingsTooltip: 'Das Textfeld ist mit dem Ticketsystem verbunden und kann nicht in statischen Text umgewandelt werden.',
  },
  ru: {
    integration: 'интеграция',
    connectToService: 'Подключиться к {0}',
    bindEvent: 'Привязать событие',
    reconnect: 'Переподключить',
    connected: 'подключен',
    rebind: 'Перепривязать',
    chooseTicketType: 'Выберите тип билета',
    inputTicketTypeId: 'ID типа билета',
    inputTicketTypeName: 'Название типа билета',
    availableTicketsForEvent: 'Доступные тикеты для "{0}"',
    ticketFieldsMapping: 'Отображение полей тикетов',
    bindedWithEventOfService: 'Привязано событие "{0}" от сервиса интеграции "{1}"',
    connectTicket: 'Подключить билет',
    connectedToIntegrationService: 'Подключен к сервису интеграции "{0}"',
    managerNotConnectedToIntegrationService: 'Менеджер не подключен ни к одному сервису интеграции',
    yourAccountNotBindedYet: 'Ваш аккаунт пока не привязан к внешнему событию',

    orderFieldLabelAttendeeFullName: 'Полное имя клиента',
    orderFieldLabelAttendeeFirstName: 'Имя клиента',
    orderFieldLabelAttendeeLastName: 'Фамилия клиента',
    orderFieldLabelAttendeeEmail: 'Email клиента',
    orderFieldLabelAttendeeBarcode: 'Код билета',
    orderFieldLabelPrice: 'Цена',
    orderFieldLabelPriceValue: 'Значение цены',
    orderFieldLabelPriceCurrency: 'Валюта цены',
    orderFieldLabelTax: 'Налог',
    orderFieldLabelTaxValue: 'Значение налога',
    orderFieldLabelTaxCurrency: 'Валюта налога',
    orderFieldLabelTicketType: 'Тип тикета',
    orderFieldLabelOrderStatus: 'Статус заказа',
    orderFieldLabelSeatingSeat: 'Место',
    orderFieldLabelSeatingRow: 'Ряд',
    orderFieldLabelSeatingSection: 'Трибуна',
    orderFieldLabelSeatingEntrance: 'Вход',

    successConnectTo: 'Успешное подключение к {0}',
    successConnectUpdate: 'Подключение успешно обновлено',
    successEventBind: 'Событие успешно привязано',
    successEventBindUpdate: 'Привязка события успешно обновлена',
    successEventBindDelete: 'Привязка события успешно удалена',

    emptyStateLabelNoIntegrationService: 'Интегрированный сервис не найден',
    emptyStateDescriptionSetConnection: 'Пожалуйста, подключитесь к интегрированному сервису в своем профиле',
    emptyStateLabelNoTickets: 'Билеты не найдены',
    emptyStateDescriptionCreateTickets: 'Пожалуйста, создайте билеты на соответственной платформе',
    emptyStateLabelNoEventBind: 'Нету привязки к событию',
    emptyStateDescriptionSeemsLikeNotBindedWithEvent: 'Похоже, что данная SKU не привязана к одному из событий',
    emptyStateLabelNoObjectsForMatching: 'Нету обьектов для установления связей',
    emptyStateDescriptionAddFewObjects: 'Пожалуйста, добавьте обьекты на любую из сторон полотна',

    errorInvalidOrExpiredIntegrationToken: 'Ваш токен для интеграции указан неправильно или просрочен',
    errorIsAlreadyBinded: 'Уже привязан к другому артикулу',

    integrationMatchingFieldsModalTicketFieldTitle: 'Поле тикета',

    inputServiceModalTitle: 'Вы действительно хотите изменить сервис интеграции?',
    inputServiceModalBody: 'Если вы измените сервис интеграции - все ваши привязки будут удалены',

    inputServiceNameLabel: 'Название сервиса',
    inputTokenLabel: 'Токен',
    inputPlatformLabel: 'Платформа',
    inputLoginLabel: 'Логин',
    inputPasswordLabel: 'Пароль',
    inputOrganizationLabel: 'Организация',
    inputEventLabel: 'Событие',
    inputEventIdLabel: 'ID События',
    inputEventNameLabel: 'Название события',

    actionLabelRemoveEventBinding: 'Удалить привязку',

    editorLockedButtonDisabledDueToMatchingsTooltip: 'Вы не можете изолировать обьект, пока он не будет удален с интеграции',
  },
};

export default strings;
