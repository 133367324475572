import cls from 'classnames';
import React from 'react';

import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import Icon from 'components/library/Icon';
import { getUserFullName } from 'helpers';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { DEFAULT_LANGUAGE_OPTION, PROFILE_LANGUAGE_OPTIONS } from '../../../../constants';
import css from './GeneralUserInfo.module.css';
import { ISidebar } from '../../index';

interface IGeneralUserInfo {
  user: ISidebar['user']
}
const GeneralUserInfo: React.FC<IGeneralUserInfo> = ({ user }) => {
  const { lang, companyName } = user;
  const fullName = getUserFullName(user);
  const userLangOption = PROFILE_LANGUAGE_OPTIONS.find(({ value }) => value === lang) || DEFAULT_LANGUAGE_OPTION;
  const userLangLabel = userLangOption.label();
  const userLangIcon = userLangOption.icon as E_ICON_TYPE;

  return (
    <>
      <div className={cls(textCss.h3, css.fullName)} title={fullName}>{fullName}</div>
      <div className={css.userInfoTable}>
        <div className={css.userInfoRow}>
          <span className={cls(textCss.pMedium3, css.tableHeaderCell)} title={strings.labelCompany}>{strings.labelCompany}</span>
          <span className={cls(textCss.pMedium3, css.textAlignRight)} title={companyName}>{companyName}</span>
        </div>
        <div className={css.userInfoRow}>
          <span className={cls(textCss.pMedium3, css.tableHeaderCell)} title={strings.labelLang}>{strings.labelLang}</span>
          <div>
            <Icon className={css.langIcon} type={userLangIcon} />
            <span className={cls(textCss.pMedium3, css.textAlignRight)} title={userLangLabel}>{userLangLabel}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralUserInfo;
