import React, { useState } from 'react';
import { FormikProps } from 'formik';

import Modal from 'components/library/Modal';
import strings from 'constants/localization';
import { TArticle, TOptionValue, TQuantity } from 'types';
import Table from 'components/library/Table';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { MIN_PAGE } from 'constants/constants';
import useColumns from './useColumns';
import { IArticleFormValues, OPTIONAL_PRICING_TABLE_PAGE_SIZE } from '../../../../constants';
import css from './OptionalPricingTable.module.css';
import { getReadyOptionValuesStringArr } from '../../../../helpers';

interface ISeePricingTable {
  isOpen: boolean,
  closeModal: () => void,
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
}
const OptionalPricingTable: React.FC<ISeePricingTable> = ({
  isOpen, closeModal, formik, article,
}) => {
  const {
    optionsSetup: { selectedOptionValues },
    productSetup: { quantities, selectedPapers }
  } = formik.values;
  const [page, setPage] = useState<number>(MIN_PAGE);

  const printItemQties = article.printItem.quantities;
  const pageCount = Math.ceil(printItemQties.length / OPTIONAL_PRICING_TABLE_PAGE_SIZE);
  const sliceStart: number = (page - 1) * OPTIONAL_PRICING_TABLE_PAGE_SIZE;
  const sliceEnd: number = page * OPTIONAL_PRICING_TABLE_PAGE_SIZE;
  const pageQties: TQuantity[] = printItemQties.slice(sliceStart, sliceEnd);
  const columns = useColumns(
    pageQties,
    quantities,
    selectedPapers,
    article.printItem.optionalPricings,
    page,
    setPage,
    pageCount,
  );
  const optionValues = article.printItem.options
    .flatMap(({ values }) => values)
    .filter(({ id }) => getReadyOptionValuesStringArr(selectedOptionValues).includes(id));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onOk={closeModal}
      title={strings.articleFormOptionalPricingTableHeader}
      okLabel={strings.actionLabelOk}
      headerIcon={<Icon type={ICON_TYPES.option} />}
    >
      <div className={css.tableContainer}>
        <Table<TOptionValue>
          columns={columns}
          data={optionValues}
        />
      </div>
    </Modal>
  );
};

export default OptionalPricingTable;
