import React from 'react';

import {
  ERoles, TRowSelection, TUser
} from 'types';
import { IUserPatchInput } from 'hooks/useUser';
import strings from 'constants/localization';
import { getBlockUserLabel } from 'components/PageProfile/helpers';
import { getUserFullName } from 'helpers';
import textCss from 'assets/styles/Text.module.css';
import { IBlockUserModal } from './index';
import { IModalContent } from './components/ModalContent';
import { TReassignedCustomers } from './types';
import { IReassignCustomersTable } from './components/ReassignTable';

export const isManagerGettingBlocked = (
  role: TUser['role'],
  blocked: TUser['blocked']
): boolean => role === ERoles.MANAGER && !blocked;

export const getReassignedUsersToBackend = (
  reassignedCustomers: TReassignedCustomers
): IUserPatchInput['reassignUsers'] => Object
  .keys(reassignedCustomers)
  .map(customerId => ({
    userId: customerId,
    managerId: reassignedCustomers[customerId].id
  }));

export const getModalHeader = (userToBlock: IBlockUserModal['userToBlock']): string => {
  if (!userToBlock) {
    return '';
  }
  if (isManagerGettingBlocked(userToBlock.role, userToBlock.blocked)) {
    return strings.modalBlockManagerHeader;
  }
  return getBlockUserLabel(userToBlock.blocked, userToBlock.role);
};

export const getOkLabel = (userToBlock: IBlockUserModal['userToBlock']): string => {
  if (!userToBlock) {
    return '';
  }
  if (isManagerGettingBlocked(userToBlock.role, userToBlock.blocked)) {
    return userToBlock.customers?.length ? strings.actionLabelReassign : strings.actionLabelBlock;
  }
  return strings.actionLabelYes;
};

export const getCancelLabel = (userToBlock: IBlockUserModal['userToBlock']): string => {
  if (!userToBlock) {
    return '';
  }
  if (isManagerGettingBlocked(userToBlock.role, userToBlock.blocked)) {
    return strings.actionLabelBack;
  }
  return strings.actionLabelNo;
};

const BLOCK_MESSAGES = {
  [ERoles.PRINTER]: () => strings.modalBlockPrinterQuestion,
  [ERoles.CUSTOMER]: () => strings.modalBlockCustomerQuestion,
};
const UNBLOCK_MESSAGES = {
  [ERoles.PRINTER]: () => strings.modalUnblockPrinterQuestion,
  [ERoles.MANAGER]: () => strings.modalUnblockManagerQuestion,
  [ERoles.CUSTOMER]: () => strings.modalUnblockCustomerQuestion,
};

export const getBlockUserMessage = (userToBlock: IModalContent['userToBlock']) => {
  const blockRole = userToBlock.role as ERoles.PRINTER | ERoles.CUSTOMER;
  const unblockRole = userToBlock.role as ERoles.PRINTER | ERoles.MANAGER | ERoles.CUSTOMER;
  const baseString: string = userToBlock.blocked
    ? UNBLOCK_MESSAGES[unblockRole]()
    : BLOCK_MESSAGES[blockRole]();

  return strings.formatString(baseString, getUserFullName(userToBlock));
};

export const getCommonManagerLabel = (rowSelection: TRowSelection, customers: IReassignCustomersTable['customers']) => {
  const selectedCustomersCount: number = Object.keys(rowSelection).length;
  let baseString: string;
  let wildcardValue: string | number;
  if (selectedCustomersCount === customers.length) {
    baseString = strings.modalBlockReassignCustomersHelperTextAll;
    wildcardValue = strings.modalBlockReassignCustomersLabelAll;
  } else {
    baseString = strings.modalBlockReassignCustomersHelperTextWithQty;
    wildcardValue = selectedCustomersCount;
  }
  return strings.formatString(
    baseString,
    <span className={textCss.h5}>
      {wildcardValue}
    </span>
  );
};
