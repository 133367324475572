import { useQuery } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { getOrdersReportHeaders, getGetOrdersReportQuery } from 'helpers';
import { STALE_TIME } from 'constants/constants';
import { AxiosRequestHeaders } from 'axios';
import { IGetOrdersReportParams } from '../../types/Reports/Orders';

const client = instance.getClient();

export const GET_ARTICLE_QUERY = ['reports', 'orders', 'get'];

export const getOrdersReport = (params: IGetOrdersReportParams): Promise<string> => {
  const query: string = getGetOrdersReportQuery(params);
  const headers: AxiosRequestHeaders = getOrdersReportHeaders(params);
  return client.get(`/export/orders${query}`, { headers }).then(res => res.data);
};

const useGetOrdersReport = (data: IGetOrdersReportParams, enabled: boolean) => useQuery<string, IResponseError>(
  [...GET_ARTICLE_QUERY, data.from, data.to, data.type],
  () => getOrdersReport(data),
  {
    staleTime: STALE_TIME,
    cacheTime: 0,
    enabled,
  }
);

export default useGetOrdersReport;
