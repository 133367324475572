import React, { useMemo, useRef, useState } from 'react';
import Popover from 'components/library/Popover';
import { Tooltip } from '@mui/material';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import { formatText } from 'helpers';
import { ETextCase } from 'types';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';
import css from './TextCase.module.css';

const TextCase:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = (action: ETextCase) => {
    if (activeObject && canvas.current) {
      activeObject.set('textCase', action);
      activeObject.set('text', formatText(activeObject.text, action));
      activeObject.initDimensions();
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const isUppercase = useMemo(() => activeObject.textCase === ETextCase.Uppercase, [activeObject.textCase]);
  const isLowercase = useMemo(() => activeObject.textCase === ETextCase.Lowercase, [activeObject.textCase]);
  const isCapitalize = useMemo(() => activeObject.textCase === ETextCase.Capitalize, [activeObject.textCase]);

  const icon = useMemo(() => {
    if (isUppercase) return ICON_TYPES.textUppercase;
    if (isLowercase) return ICON_TYPES.textLowercase;
    if (isCapitalize) return ICON_TYPES.textCapitalize;
    return ICON_TYPES.textDefault;
  }, [isUppercase, isCapitalize, isLowercase]);

  return (
    <>
      <Tooltip placement="top" title={strings.editorTabSettingsTextCaseTooltip} arrow>
        <SidebarButton
          ref={ref}
          isActive={popoverState}
          onClick={() => setPopoverState(!popoverState)}
        >
          <Icon type={icon} />
        </SidebarButton>
      </Tooltip>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <div className={css.row}>
          <Tooltip placement="top" title={strings.editorTabSettingsTextCaseDefaultTooltip} arrow>
            <SidebarButton
              isActive={!isUppercase && !isLowercase && !isCapitalize}
              onClick={() => handleChange(ETextCase.Default)}
            >
              <Icon type={ICON_TYPES.textDefault} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextCaseCapitalizeTooltip} arrow>
            <SidebarButton
              isActive={isCapitalize}
              onClick={() => handleChange(ETextCase.Capitalize)}
            >
              <Icon type={ICON_TYPES.textCapitalize} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextCaseUppercaseTooltip} arrow>
            <SidebarButton
              isActive={isUppercase}
              onClick={() => handleChange(ETextCase.Uppercase)}
            >
              <Icon type={ICON_TYPES.textUppercase} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextCaseLowercaseTooltip} arrow>
            <SidebarButton
              isActive={isLowercase}
              onClick={() => handleChange(ETextCase.Lowercase)}
            >
              <Icon type={ICON_TYPES.textLowercase} />
            </SidebarButton>
          </Tooltip>
        </div>
      </Popover>
    </>
  );
};

export default TextCase;
