import React from 'react';
import { IEditorContext } from 'components/PageArticles/components/Editor/contexts/EditorContext';
import css from './SummaryTab.module.css';
import ObjectView from './components/ObjectView';

interface ISummaryTab {
  objects: IEditorContext['objects'],
  onObjectSelected?: () => void,
}

const SummaryTab:React.FC<ISummaryTab> = ({
  objects,
  onObjectSelected,
}) => (
  <div className={css.container}>
    {objects.map(object => <ObjectView key={object.id} object={object} onObjectSelected={onObjectSelected} />)}
  </div>
);

export default SummaryTab;
