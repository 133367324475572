/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextUppercase(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M3.93039 13.71H8.00039L5.97039 8.29L3.93039 13.71ZM5.11039 6H6.83039L11.5404 18H9.61039L8.64039 15.43H3.29039L2.33039 18H0.400391L5.11039 6Z" fill="#2C3E66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.1104 6H18.8304L23.5404 18H21.6104L20.6404 15.43H15.2904L14.3304 18H12.4004L17.1104 6ZM20.0004 13.71H15.9304L17.9704 8.29L20.0004 13.71Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextUppercase;
