import React, { useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';
import Table from 'components/library/Table';
import { TOrderItem, TParam } from 'types';
import strings from 'constants/localization';
import useColumnsMatchingTable from './useColumnsMatchingTable';
import css from './MatchingTable.module.css';
import { TRow, TRowGrouped } from '../../types';

interface IMatchingTable {
  data: TRow[],
  params: TParam[],
  setMatchedData: (items: TOrderItem[]) => void,
}
const MatchingTable:React.FC<IMatchingTable> = ({ data, params, setMatchedData }) => {
  const [keys, ...rows] = data;
  const filteredParams = useMemo(() => params.filter(e => e.locked !== true), [params]);
  const tableRows = useMemo<TRowGrouped[]>(() => {
    const grouped = groupBy(filteredParams, e => e.side);
    return Object.values(grouped).map(values => ({
      ...values[0],
      groupedKey: values[0].side,
      subRows: values.slice(1)
    }));
  }, [filteredParams]);
  const [expanded, setExpanded] = React.useState<ExpandedState>({ 0: true, 1: true });
  const columns = useColumnsMatchingTable(keys, params, rows, setMatchedData);
  return (
    <div className={css.container}>
      <span className={css.title}>{strings.orderPageModalUploadFileMatchTableDescription}</span>
      <Table<TRowGrouped>
        columns={columns}
        data={tableRows}
        state={{ expanded }}
        onExpandedChange={setExpanded}
        getSubRows={row => row.subRows}
        getExpandedRowModel={getExpandedRowModel()}
      />
    </div>
  );
};

export default React.memo(MatchingTable);
