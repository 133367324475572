/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconChat(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23 11.5C23 16.7467 18.0751 21 12 21C10.96 21 10.3347 20.9684 9 20.6424C8.17908 20.4419 5.34168 21.7582 3.27882 22.8013C2.60916 23.1399 1.78496 22.3521 2.04424 21.648C2.708 19.8452 3.27738 17.4311 2.30993 16C1.42576 14.6921 1 13.1281 1 11.5C1 6.25329 5.92487 2 12 2C18.0751 2 23 6.25329 23 11.5ZM7 13C7.82843 13 8.5 12.3284 8.5 11.5C8.5 10.6716 7.82843 10 7 10C6.17157 10 5.5 10.6716 5.5 11.5C5.5 12.3284 6.17157 13 7 13ZM13.5 11.5C13.5 12.3284 12.8284 13 12 13C11.1716 13 10.5 12.3284 10.5 11.5C10.5 10.6716 11.1716 10 12 10C12.8284 10 13.5 10.6716 13.5 11.5ZM17 13C17.8284 13 18.5 12.3284 18.5 11.5C18.5 10.6716 17.8284 10 17 10C16.1716 10 15.5 10.6716 15.5 11.5C15.5 12.3284 16.1716 13 17 13Z" fill="white" />
    </svg>
  );
}

export default IconChat;
