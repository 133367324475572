import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { fabric } from 'fabric';

import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import { IEditorContext, IObject } from 'components/PageArticles/components/Editor/contexts/EditorContext';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { ECanvasObjectTypes } from 'types';
import Loader from 'components/library/Loader';
import { getObjectIsUsedInMatchings } from 'components/PageArticles/helpers';
import css from './ObjectView.module.css';
import NameInput from './NameInput';
import LockButton from './LockButton';
import DeleteButton from './DeleteButton';
import { EBorderType } from '../../../../../../components/Editor/components/Viewport/fabric/extendCustomObject';
import getObjectBorder from '../../../../../../components/Editor/helpers/getObjectBorder';
import { ECanvasActiveSelection } from '../../../../../../components/Editor/constants';

interface IObjectView {
  object: IObjectViewContainer['object'],
  canvas: fabric.Canvas,
  isActive: boolean,
  isHovered: boolean,
  isUsedInMatchings: boolean,
  onObjectSelected?: () => void,
  setHoverObject: IEditorContext['setHoverObject']
}

const ObjectView:React.FC<IObjectView> = ({
  object,
  canvas,
  isActive,
  isHovered,
  isUsedInMatchings,
  onObjectSelected,
  setHoverObject
}) => {
  const isErrorObject = useMemo<boolean>(() => object.showBorder === EBorderType.ERROR, [object.showBorder]);

  const icon = useMemo<E_ICON_TYPE>(() => {
    switch (object.type) {
      case ECanvasObjectTypes.TEXT:
        return ICON_TYPES.text;
      case ECanvasObjectTypes.NUMBERING:
        return ICON_TYPES.numbering;
      case ECanvasObjectTypes.IMAGE:
        return ICON_TYPES.image;
      case ECanvasObjectTypes.CODE:
        return ICON_TYPES.code;
      default:
        return ICON_TYPES.text;
    }
  }, [object.type]);

  const handleClickIcon = useCallback(() => {
    if (!object.locked) {
      canvas.setActiveObject(object);
      canvas.requestRenderAll();
    }
  }, [object, canvas]);

  const handleFocusInput = useCallback(() => {
    if (onObjectSelected instanceof Function) {
      onObjectSelected();
    }
  }, [onObjectSelected]);

  const iconClasses = useMemo<string>(
    () => classNames(
      css.icon,
      { [css.iconDisabled]: object.locked }
    ),
    [object.locked]
  );

  const containerClasses = useMemo<string>(
    () => classNames(
      css.container,
      {
        [css.active]: ((isActive || isHovered) && !isErrorObject),
        [css.error]: isErrorObject
      }
    ),
    [isActive, isHovered, isErrorObject]
  );

  const lockBtnProps = useMemo(() => ({ object, isUsedInMatchings }), [object, isUsedInMatchings]);

  const handleObjectViewMouseEnter = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (object.id === event.currentTarget.id) {
      setHoverObject(object);
      object.set('showBorder', getObjectBorder(object, EBorderType.ACTIVE));
      object.canvas.renderAll();
    }
  }, [object, setHoverObject]);
  const handleObjectViewMouseLeave = useCallback(() => {
    setHoverObject(null);
    object.set('showBorder', getObjectBorder(object, EBorderType.DEFAULT));
    object.canvas.renderAll();
  }, [setHoverObject, object]);

  return (
    <div
      id={object?.id}
      onMouseEnter={handleObjectViewMouseEnter}
      onMouseLeave={handleObjectViewMouseLeave}
      className={containerClasses}
    >
      <Icon
        className={iconClasses}
        onClick={handleClickIcon}
        width={24}
        height={24}
        type={icon}
      />
      <NameInput
        object={object}
        onFocus={handleFocusInput}
      />
      <LockButton {...lockBtnProps} />
      <DeleteButton object={object} />
    </div>
  );
};

interface IObjectViewContainer {
  object: IObject['object'],
  onObjectSelected?: () => void,
}

// TODO: need to refactor NameInput, LockButton, DeleteButton for using container
const ObjectViewContainer:React.FC<IObjectViewContainer> = ({
  object,
  onObjectSelected
}) => {
  const {
    canvas,
    activeObject,
    hoverObject,
    setHoverObject,
    frontJson,
    backJson,
  } = useEditorData();

  const activeObjects = useMemo<IObjectViewContainer['object'][]>(() => {
    if (!activeObject) {
      return [];
    }

    return activeObject.type === ECanvasActiveSelection.ACTIVE_SELECTION
      ? activeObject.getObjects()
      : [activeObject];
  }, [activeObject, activeObject?._objects]);

  const isActive = useMemo<boolean>(() => {
    if (activeObjects.length === 0) {
      return false;
    }

    return activeObjects.some((o: IObjectViewContainer['object']) => o?.id === object.id);
  }, [activeObjects, object.id]);

  const isHovered = useMemo<boolean>(() => hoverObject?.id === object?.id, [hoverObject, object]);

  const isUsedInMatchings = useMemo<boolean>(
    () => getObjectIsUsedInMatchings(object?.id, frontJson?.integrationMatchings?.fields || {}, backJson?.integrationMatchings?.fields || {}),
    [object.id, frontJson?.integrationMatchings, backJson?.integrationMatchings]
  );

  if (canvas.current === null) {
    return <Loader />;
  }

  return (
    <ObjectView
      object={object}
      canvas={canvas.current}
      isActive={isActive}
      isHovered={isHovered}
      isUsedInMatchings={isUsedInMatchings}
      onObjectSelected={onObjectSelected}
      setHoverObject={setHoverObject}
    />
  );
};

export default ObjectViewContainer;
