import fabric from '../../../helpers/fabric';

export function canvasInit(element: HTMLCanvasElement | string | null) {
  return new fabric.Canvas(element, {
    stateful: false,
    preserveObjectStacking: true,
    uniScaleKey: 'none',
    selectionKey: 'none',
    altSelectionKey: 'none',
    altActionKey: 'none',
    centeredKey: 'none',
    controlsAboveOverlay: true,
  });
}

export default { canvasInit };
