import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import strings from 'constants/localization';
import Tabs, { ITab, TOnChange } from 'components/library/Tabs';
import Loader from 'components/library/Loader';
import { ECanvasActiveSelection } from '../../../components/Editor/constants';
import useEditorData from '../../../components/Editor/hooks/useEditorData';
import css from './Settings.module.css';
import SummaryTab from './Tabs/SummaryTab/SummaryTab';
import SettingsTab from './Tabs/SettingsTab/SettingsTab';
import getObjectTitle from '../../../components/Editor/helpers/getObjectTitle';

const Settings: React.FC = () => {
  const {
    objects,
    activeObject,
  } = useEditorData();

  const showSummaryTab = useRef<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const onChangeTab: TOnChange = useCallback<TOnChange>((event, newValue) => setCurrentTab(newValue), [setCurrentTab]);

  const tabs = useMemo<ITab<string>[]>(() => {
    const list = [{ value: 'summary', label: () => strings.editorTabSummary }];

    if (activeObject) {
      const objectTitle = getObjectTitle(activeObject);
      let title: string = '';
      switch (activeObject?.type) {
        case ECanvasActiveSelection.ACTIVE_SELECTION:
          title = strings.formatString(strings.editorShortTabField, strings.editorActiveSelectionTitle) as string;
          break;
        default:
          title = strings.formatString(strings.editorTabField, objectTitle) as string;
      }

      list.push({ value: 'settings', label: () => title });
    } else {
      // reset to SummaryTab if there's any active object
      setCurrentTab(0);
    }

    return list;
  }, [activeObject]);

  useEffect(() => {
    setCurrentTab(showSummaryTab.current ? 0 : tabs.length - 1);
    showSummaryTab.current = false;
  }, [setCurrentTab, tabs, showSummaryTab]);

  const handleObjectSelected = useCallback(
    () => {
      showSummaryTab.current = true;
    },
    [showSummaryTab]
  );

  const currentContent = useMemo(
    () => {
      switch (currentTab) {
        case 0:
          return <SummaryTab objects={objects} onObjectSelected={handleObjectSelected} />;
        case 1:
          return <SettingsTab />;
        default:
          return <Loader />;
      }
    },
    [currentTab, objects, handleObjectSelected]
  );

  return (
    <div className={css.container}>
      <Tabs
        orientation="horizontal"
        value={currentTab}
        tabs={tabs}
        tabClass={css.tab}
        className={css.tabs}
        onChange={onChangeTab}
      />
      { currentContent }
    </div>
  );
};

export default Settings;
