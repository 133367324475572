/* eslint-disable max-len */
import IPrintersString from '../types/PrintersStrings';
import { ILanguages } from '../types';

const strings:ILanguages<IPrintersString> = {
  en: {
    printersPageTitle: 'Printers',
    printersTableHeaderPrinter: 'Printer',
    printersButtonAddPrinter: 'Add Printer',
    printersPageProfileTitle: 'Printer Profile',
    printersModalBlockTitle: 'Block Printer',
  },
  de: {
    printersPageTitle: 'Druckerei-Übersicht',
    printersTableHeaderPrinter: 'Druckerei',
    printersButtonAddPrinter: 'Druckerei hinzufügen',
    printersPageProfileTitle: 'Druckerei-Profil',
    printersModalBlockTitle: 'Druckerei-Konto sperren',
  },
  ru: {
    printersPageTitle: 'Принтеры',
    printersTableHeaderPrinter: 'Принтер',
    printersButtonAddPrinter: 'Создать принтера',
    printersPageProfileTitle: 'Профиль принтера',
    printersModalBlockTitle: 'Заблокировать принтера',
  },
};

export default strings;
