/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconProfile(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12" cy="8" rx="4" ry="4.5" stroke="white" strokeWidth="1.8" />
      <path d="M19 20C18 17.5 16 15.5 12 15.5C8 15.5 6 17.5 5 20" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconProfile;
