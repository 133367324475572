import {
  Dispatch, RefObject, SetStateAction, useLayoutEffect, useRef, useState,
} from 'react';
import shave from 'shave';

type TElementTruncateProps<T> = {
  key: string,
  ref: RefObject<T>,
}
type TTruncateFlags = {
  isTruncated: boolean,
  canTruncate: boolean,
}
type TTruncateHelpers = {
  setEnabled: Dispatch<SetStateAction<boolean>>,
}
type TUseTruncateReturn<T> = [
  TElementTruncateProps<T>,
  TTruncateFlags,
  TTruncateHelpers,
]
const useTruncateText = <T extends HTMLElement>(maxHeight: number, isEnabled: boolean = true): TUseTruncateReturn<T> => {
  const elementRef = useRef<T>(null);
  const [enabled, setEnabled] = useState<boolean>(isEnabled);
  const [canTruncate, setCanTruncate] = useState<boolean>(isEnabled);
  const [isTruncated, setIsTruncated] = useState<boolean>(isEnabled);
  const classname: string = 'js-shave';

  useLayoutEffect(() => {
    const tryToTruncate = () => {
      if (elementRef.current && enabled) {
        shave(elementRef.current as never, maxHeight, {
          classname,
          spaces: false,
        });
      }
      setCanTruncate(elementRef.current?.offsetHeight as number >= maxHeight);
      setIsTruncated(!!elementRef.current?.querySelector(`.${classname}`));
    };

    tryToTruncate();
    window.addEventListener('resize', tryToTruncate);

    return () => window.removeEventListener('resize', tryToTruncate);
  }, [maxHeight, enabled]);

  // By using enabled as our 'key', we force react to create a completely new DOM node if enabled changes.
  // Effectively blowing away a previously shaved version of the component
  // if we want to "unshave" it by setting enabled to false.
  const elementProps: TElementTruncateProps<T> = {
    key: enabled.toString(),
    ref: elementRef,
  };
  const flags: TTruncateFlags = { isTruncated, canTruncate };
  const helpers: TTruncateHelpers = { setEnabled };
  return [elementProps, flags, helpers];
};

export default useTruncateText;
