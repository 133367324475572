import { ECanvasObjectTypes } from 'types';
import { FabricObject } from '../useFabricObjects';
import textObjectModifier from './textObjectModifier';
import imageObjectModifier from './imageObjectModifier';
import codeObjectModifier from './codeObjectModifier';

interface IModifier {
  modifier: (object: FabricObject['object'], data: any, options?: any) => Promise<FabricObject['object']>
}

export default (type: ECanvasObjectTypes): IModifier['modifier'] => {
  switch (type) {
    case ECanvasObjectTypes.TEXT:
      return textObjectModifier;
    case ECanvasObjectTypes.IMAGE:
      return imageObjectModifier;
    case ECanvasObjectTypes.CODE:
      return codeObjectModifier;
    default:
      throw new Error('Invalid Object type');
  }
};
