/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextBold(props: IIconProps) {
  return (
    <svg {...props} width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.65C0 0.75 0.75 0 1.65 0H6.15C9.3 0 11.25 2.1 11.25 5.1C11.25 6.45 10.8 8.1 10.2 9C11.4 9.9 12.3 10.8 12.3 12.75C12.3 16.8 9.15 18 6.9 18H1.65C0.75 18 0 17.25 0 16.35V1.65ZM3 10.5V15H6.6C7.65 15 8.85 14.25 8.85 12.75C8.85 11.25 7.65 10.5 6.6 10.5H3ZM3 7.5H6.3C7.65 7.5 8.55 6.45 8.55 5.25C8.55 4.05 7.65 3 6.45 3H3V7.5Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextBold;
