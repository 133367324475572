/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconBorderSize(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M 15.917969 6.121094 L 8.082031 6.121094 C 7.492188 6.121094 7.101562 5.730469 7.101562 5.140625 C 7.101562 4.554688 7.492188 4.160156 8.082031 4.160156 L 15.921875 4.160156 C 16.507812 4.160156 16.902344 4.554688 16.902344 5.140625 C 16.902344 5.730469 16.507812 6.121094 15.917969 6.121094 Z M 15.917969 6.121094" fill="#2C3E66" />
      <path d="M 15.917969 19.839844 L 8.082031 19.839844 C 7.492188 19.839844 7.101562 19.449219 7.101562 18.863281 C 7.101562 18.273438 7.492188 17.882812 8.082031 17.882812 L 15.921875 17.882812 C 16.507812 17.882812 16.902344 18.273438 16.902344 18.863281 C 16.902344 19.445312 16.507812 19.839844 15.917969 19.839844 Z M 15.917969 19.839844" fill="#2C3E66" />
      <path d="M 18.859375 16.902344 C 18.269531 16.902344 17.878906 16.507812 17.878906 15.921875 L 17.878906 8.082031 C 17.878906 7.492188 18.269531 7.101562 18.859375 7.101562 C 19.445312 7.101562 19.839844 7.492188 19.839844 8.082031 L 19.839844 15.921875 C 19.839844 16.507812 19.445312 16.902344 18.859375 16.902344 Z M 18.859375 16.902344" fill="#2C3E66" />
      <path d="M 5.140625 16.902344 C 4.554688 16.902344 4.160156 16.507812 4.160156 15.921875 L 4.160156 8.082031 C 4.160156 7.492188 4.554688 7.101562 5.140625 7.101562 C 5.730469 7.101562 6.121094 7.492188 6.121094 8.082031 L 6.121094 15.921875 C 6.121094 16.507812 5.730469 16.902344 5.140625 16.902344 Z M 5.140625 16.902344" fill="#2C3E66" />
      <path d="M 22.78125 23.761719 L 1.21875 23.761719 C 0.632812 23.761719 0.238281 23.367188 0.238281 22.78125 L 0.238281 1.21875 C 0.238281 0.632812 0.632812 0.238281 1.21875 0.238281 L 22.777344 0.238281 C 23.367188 0.238281 23.757812 0.632812 23.757812 1.21875 L 23.757812 22.777344 C 23.761719 23.367188 23.367188 23.761719 22.78125 23.761719 Z M 2.199219 21.800781 L 21.800781 21.800781 L 21.800781 2.199219 L 2.199219 2.199219 Z M 2.199219 21.800781" fill="#2C3E66" />
    </svg>
  );
}

export default IconBorderSize;
