import React, { useCallback, useEffect, useState } from 'react';
import strings from 'constants/localization';
import Button from 'components/library/Button';
import { TOrderItem, TParam } from 'types';
import { useParser } from 'hooks/useParser';
import Loader from 'components/library/Loader';
import textCss from 'assets/styles/Text.module.css';
import css from './ModalContentUploadList.module.css';
import UploadFile from './UploadFile';
import FileList from './FileList';
import MatchingTable from './MatchingTable';
import { TRow } from '../../types';

const SECOND_STEP = 2;
const FIRST_STEP = 1;

interface IModalContentUploadList {
  params: TParam[],
  onSave: (items: TOrderItem[]) => void,
}
const ModalContentUploadList:React.FC<IModalContentUploadList> = ({ params, onSave }) => {
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const parseFile = useParser();
  const [fileRows, setFileRows] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [data, setData] = useState<TRow[]>([]);
  const [matchedData, setMatchedData] = useState<TOrderItem[]>([]);
  const removeFile = () => {
    setFile(null);
    setStep(0);
    setData([]);
  };

  const onClickSave = () => onSave(matchedData);
  const onError = useCallback(() => {
    setLoading(false);
    setFile(null);
    // todo: send error
  }, []);

  useEffect(() => {
    if (file) {
      const fileType = file.name.split('.').pop() as 'csv' | 'xlsx';
      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        parseFile
          .mutateAsync({ type: fileType, file: reader.result || '' })
          .then(d => {
            setData(d);
            setFileRows(d.length);
            setStep(FIRST_STEP);
          }).finally(() => {
            setLoading(false);
          });
      };
      reader.onerror = onError;
      reader.onabort = onError;
    }
  }, [file]);

  return (
    <div className={css.modalUploadList}>
      {step === 0 && <UploadFile setFile={setFile} loading={loading} />}
      {step === 0 && loading && <Loader className={css.loader} />}
      {step === FIRST_STEP && file && <FileList removeFile={removeFile} file={file} fileRows={fileRows} />}
      {step === SECOND_STEP && data && <MatchingTable params={params} data={data} setMatchedData={setMatchedData} />}
      {step > 0 && (
        <div className={css.footer}>
          {step >= FIRST_STEP && (
            <Button
              onClick={() => {
                setStep(step - 1);
                if (step - 1 < FIRST_STEP) {
                  setFile(null);
                  setData([]);
                }
              }}
              buttonType="secondary"
            >
              {strings.actionLabelBack}
            </Button>
          )}
          <span className={textCss.pLight2}>{strings.formatString(strings.orderPageModalUploadListStepsTitle, step, SECOND_STEP)}</span>
          {step < SECOND_STEP && (
            <Button
              disabled={fileRows < 2}
              onClick={() => setStep(step + 1)}
              buttonType="primary"
            >
              {strings.actionLabelNext}
            </Button>
          )}
          {step === SECOND_STEP && (
            <Button
              buttonType="primary"
              onClick={onClickSave}
            >
              {strings.actionLabelSave}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ModalContentUploadList;
