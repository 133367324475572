import React, { useMemo } from 'react';
import cls from 'classnames';

import Icon, { ICON_TYPES } from 'components/library/Icon';

import { TPrintItemsItem } from 'types';
import { TSelectPrintItemInfoMapper } from 'types/Articles';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import css from './SideBarContent.module.css';
import { generateSelectPrintItemSideBarInfo } from '../../../../helpers';

interface ISideBarContent {
  printItem: TPrintItemsItem | null,
}

const SideBarContent: React.FC<ISideBarContent> = ({ printItem }) => {
  const content = useMemo<TSelectPrintItemInfoMapper[] | null>(
    () => printItem && generateSelectPrintItemSideBarInfo(printItem, css.infoItemNested),
    [printItem]
  );

  return (
    <div className={cls(css.sideBar, { [css.empty]: !printItem })}>
      <div className={css.head}>
        <Icon type={ICON_TYPES.description} />
      </div>
      {printItem && content
        ? (
          <div className={css.content}>
            <div className={cls(textCss.h4, css.title)}>{printItem.title}</div>
            {content.map(({ title, value }) => (
              <div key={title} className={css.infoItem}>
                <div className={textCss.pMedium2}>{title !== strings.options && '•'} {title}</div>
                <div className={textCss.pLight2}>{value}</div>
              </div>
            ))}
          </div>
        )
        : (
          <div className={cls(css.noSelected, textCss.pMedium3)}>{strings.articlesSelectPrintedItemType}</div>
        )}
    </div>
  );
};

export default SideBarContent;
