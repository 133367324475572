import React from 'react';
import ReactModal, { Props } from 'react-modal';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Button, { IButton } from 'components/library/Button';
import strings from 'constants/localization';
import css from './Modal.module.css';

interface IModal extends Props {
  title?: string,
  withoutHeader?: boolean,
  withoutCloseIcon?: boolean,
  customControls?: React.ReactNode,
  headerIcon?: React.ReactNode,
  onOk?: () => void,
  onCancel?: () => void,
  okLabel?: string,
  cancelLabel?: string,
  okButtonProps?: Partial<IButton>,
  cancelButtonProps?: Partial<IButton>,
}

const Modal:React.FC<IModal> = props => {
  const {
    cancelLabel = strings.actionLabelNo, okLabel = strings.actionLabelYes,
    onCancel, onOk, onRequestClose,
    withoutHeader, withoutCloseIcon, customControls,
    headerIcon, title, children, className, okButtonProps, cancelButtonProps,
    ...rest
  } = props;
  return (
    <ReactModal
      overlayClassName={cls(css.overlay)}
      className={cls(textCss.pMedium3, css.modal, className)}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      {...rest}
    >
      <div className={css.wrapper}>
        {!withoutHeader
          ? (
            <div className={css.header}>
              {!!headerIcon && <div>{headerIcon}</div> }
              <span className={textCss.pLight1}>{title}</span>
            </div>
          ) : null}
        {!withoutCloseIcon
          ? <div className={css.iconClose} onClick={onRequestClose}><Icon type={ICON_TYPES.close} /></div>
          : null}
      </div>
      {children}
      {customControls}
      {!customControls && (onOk || onCancel) && (
        <div className={css.controls}>
          {onOk ? <Button {...okButtonProps} type="button" buttonType="primary" onClick={onOk}>{okLabel}</Button> : null}
          {onCancel ? <Button {...cancelButtonProps} type="button" buttonType="secondary" onClick={onCancel}>{cancelLabel}</Button> : null}
        </div>
      )}
    </ReactModal>
  );
};

export default React.memo(Modal);
