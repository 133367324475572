import { IFindResponse, IResponseError } from 'constants/types';
import { STALE_TIME } from 'constants/constants';
import instance from 'lib/apiClient';
import {
  ERoles, IPaginationParams, PartialExcludingOne, TUser
} from 'types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInvalidateOrdersFind } from './useOrders';

const client = instance.getClient();
interface IUserGetInput {
  userId?: string,
  role?: ERoles
}
export const getUser = ({ userId, role }:IUserGetInput):Promise<TUser> => {
  // TODO: set args to include only needed fields
  const query = ['?includeOrdersStats=true&includeArticleStats=true&includeCustomers=1&includeIntegrationConnection=1&includeIntegrationEventBind=1'];
  if (role) {
    query.push(`role=${role}`);
  }
  return client
    .get(`/users/${userId}${query.join('&')}`)
    .then(res => res.data);
};

export const QUERY_KEY = ['users', 'get'];
export function useGetUser(data:IUserGetInput) {
  return useQuery<TUser, IResponseError>(
    [...QUERY_KEY, data?.userId],
    () => getUser(data),
    {
      enabled: !!data.userId,
    }
  );
}
export function useGetUserPrinter(pspId: IUsersFindInput['pspId']) {
  return useUsersFind({
    limit: 1,
    role: 'printer',
    pspId
  });
}
export function useInvalidateUserGet() {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY, ...additionalParams]);
}

export interface IUsersFindInput extends IPaginationParams {
  pspId?: string,
  search?: string,
  blocked?: boolean,
  managerId?: string,
  includeOrdersStats?: boolean,
  includeIntegrationEventBind?: boolean,
  includeArticleStats?: boolean,
  includeManager?: boolean,
  includeCustomers?: boolean,
  role?: 'admin' | 'printer' | 'manager' | 'customer'
}
export const findUsers = ({
  pspId, limit, skip, search, sort, blocked, role, managerId,
  includeArticleStats, includeManager, includeOrdersStats, includeIntegrationEventBind, includeCustomers
}:IUsersFindInput):Promise<IFindResponse<TUser[]>> => {
  const query = ['?'];
  if (pspId) {
    query.push(`pspId=${pspId}`);
  }
  if (managerId) {
    query.push(`managerId=${managerId}`);
  }
  if (includeManager) {
    query.push('includeManager=1');
  }
  if (includeCustomers) {
    query.push('includeCustomers=1');
  }
  if (includeArticleStats) {
    query.push('includeArticleStats=1');
  }
  if (includeOrdersStats) {
    query.push('includeOrdersStats=1');
  }
  if (includeIntegrationEventBind) {
    query.push('includeIntegrationEventBind=1');
  }
  if (limit) {
    query.push(`%24limit=${limit}`);
  }
  if (skip) {
    query.push(`%24skip=${skip}`);
  }
  if (blocked !== undefined) {
    query.push(`blocked=${blocked}`);
  }
  if (role) {
    query.push(`role=${role}`);
  }
  if (search) {
    query.push(`%24or[0][name][%24iLike]=%25${search}%25`);
    query.push(`%24or[1][surname][%24iLike]=%25${search}%25`);
    query.push(`%24or[2][companyName][%24iLike]=%25${search}%25`);
  }
  if (sort) {
    query.push(`%24sort[${sort[0]}]=${sort[1]}`);
  }
  query.push('%24sort[createdAt]]=-1');

  return client.get(`/users${query.join('&')}`).then(res => res.data);
};

export const QUERY_KEY_FIND = ['users', 'find'];
export function useUsersFind(data:IUsersFindInput) {
  return useQuery<IFindResponse<TUser[]>, IResponseError>(
    [...QUERY_KEY_FIND, data.limit, data.skip, data.sort, data.search, data.blocked, data.pspId, data.role, data.managerId],
    () => findUsers(data),
    {
      staleTime: STALE_TIME
    }
  );
}
export function useInvalidateUsersFind() {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_FIND, ...additionalParams]);
}

export interface IUserPatchInput extends PartialExcludingOne<TUser, 'id'> {
  reassignUsers?: { userId: TUser['id'], managerId: TUser['id'] }[],
  currentPassword?: string,
  newPassword?: string,
}
export const QUERY_KEY_PATCH = ['users', 'patch'];
export const patchUser = ({ id, ...rest }:IUserPatchInput):Promise<TUser> => client
  .patch(`/users/${id}`, { ...rest }).then(res => res.data);
export function useUserPatch() {
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUser = useInvalidateUserGet();
  const invalidateOrders = useInvalidateOrdersFind();
  return useMutation<TUser, IResponseError, IUserPatchInput>(
    QUERY_KEY_PATCH,
    data => patchUser(data),
    {
      onSuccess: (data, variables) => {
        invalidateUsers();
        invalidateUser(variables.id);
        invalidateOrders();
      }
    }
  );
}
// todo: invalidate get user after update/patch
export const QUERY_KEY_UPDATE = ['users', 'update'];
export const updateUser = (user:TUser):Promise<TUser> => {
  // todo: remove it when will implemented function for updating user permission
  const data:Partial<TUser> = {
    ...user
  };
  delete data.userPermissions;
  return client.put(`/users/${user.id}`, data).then(res => res.data);
};
export function useUserUpdate() {
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();
  const invalidateOrders = useInvalidateOrdersFind();
  return useMutation<TUser, IResponseError, TUser>(
    QUERY_KEY_UPDATE,
    data => updateUser(data),
    {
      onSuccess: () => Promise.all([
        invalidateUsers(),
        invalidateUserGet(),
        invalidateOrders()
      ])
    }
  );
}

export const QUERY_KEY_CREATE = ['users', 'create'];
export const createUser = (user:TUser):Promise<TUser> => {
  const data:Partial<TUser> = {
    ...user
  };
  delete data.id;
  // todo: remove it when will implemented function for updating user permission
  delete data.userPermissions;
  return client.post('/users', data).then(res => res.data);
};
export function useUserCreate() {
  const invalidateUsers = useInvalidateUsersFind();
  return useMutation<TUser, IResponseError, TUser>(
    QUERY_KEY_CREATE,
    data => createUser(data),
    {
      retry: false,
      onSuccess: () => invalidateUsers()
    }
  );
}

export const QUERY_KEY_DELETE = ['users', 'delete'];
export const deleteUser = (id:TUser['id']):Promise<null> => client.delete(`/users/${id}`).then(res => res.data);
export function useUserDelete() {
  const invalidateUsers = useInvalidateUsersFind();
  return useMutation<null, IResponseError, TUser['id']>(
    QUERY_KEY_DELETE,
    id => deleteUser(id),
    {
      onSuccess: () => invalidateUsers()
    }
  );
}

export default useGetUser;
