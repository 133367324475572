import * as Yup from 'yup';

import strings from 'constants/localization';
import {
  EArticleStatuses, TQuantity, TSize, TArticleStatusOption, TArticleDocument, ETemplateSides, EIntegrationServices,
} from 'types';
import { ResolveOptions } from 'yup/lib/Condition';

export enum ETabs {
  PRODUCT_INFO,
  PRODUCT_SETUP,
  OPTIONS_SETUP,
  TEMPLATE,
}

export const TAB_COUNT: number = 4;

export const PRODUCT_INFO_VALIDATION_SCHEMA = () => Yup.object().shape({
  skuIdTitle: Yup.string()
    .max(50, strings.formatString(strings.inputValidateStringLength, 50) as string)
    .required(strings.inputValidateRequiredField),
  title: Yup.string()
    .max(50, strings.formatString(strings.inputValidateStringLength, 50) as string)
    .required(strings.inputValidateRequiredField),
  description: Yup.string()
    .max(120, strings.formatString(strings.inputValidateStringLength, 120) as string)
});

const QUANTITIES_SCHEMA = Yup.array()
  .of(Yup.object().shape({
    qtyId: Yup.string().uuid().required(strings.inputValidateRequiredField),
    paperId: Yup.string().uuid().required(strings.inputValidateRequiredField),
    value: Yup.number().required(strings.inputValidateRequiredField),
    isSelected: Yup.boolean().required(strings.inputValidateRequiredField)
  }));
const SELECTED_PAPERS_SCHEMA = Yup.array()
  .of(Yup.string().uuid())
  .min(1, strings.articleFormInputValidateOnePaper)
  .required(strings.inputValidateRequiredField);
export const PRODUCT_SETUP_VALIDATION_SCHEMA = () => Yup.object().shape({
  size: Yup.object()
    .shape({
      id: Yup.string().uuid().required(strings.inputValidateRequiredField),
      height: Yup.number().required(strings.inputValidateRequiredField),
      width: Yup.number().required(strings.inputValidateRequiredField),
      bleed: Yup.number().required(strings.inputValidateRequiredField),
      name: Yup.string().required(strings.inputValidateRequiredField)
    })
    .nullable()
    .required(strings.inputValidateRequiredField),
  selectedPapers: SELECTED_PAPERS_SCHEMA,
  quantities: QUANTITIES_SCHEMA.test({
    name: 'at-least-one-selected-quantity-for-selected-paper',
    message: strings.articleFormInputValidateOneQty,
    test: (value, context) => {
      const productSetupValues = context.parent as IFormProductSetupValues;
      return productSetupValues.selectedPapers?.every(paper => productSetupValues.quantities?.find(({
        paperId,
        isSelected,
      }) => paperId === paper && isSelected)) || false;
    }
  }).required(strings.inputValidateRequiredField),
  sizeComment: Yup.string()
    .max(50, strings.formatString(strings.inputValidateStringLength, 50) as string),
  paperComment: Yup.string()
    .max(50, strings.formatString(strings.inputValidateStringLength, 50) as string),
});
export const PRODUCT_SETUP_WITH_MATCHINGS = () => PRODUCT_SETUP_VALIDATION_SCHEMA().concat(Yup.object().shape({
  quantities: QUANTITIES_SCHEMA,
  selectedPapers: SELECTED_PAPERS_SCHEMA.max(1, strings.articleFormInputValidateWithMatchingsOnePaperMax),
}));

const SELECTED_OPTION_VALUES_SCHEMA = Yup.array().of(Yup.object().shape({
  optionId: Yup.string().uuid(),
  optionValueId: Yup.string().uuid(),
}));
export const OPTIONS_SETUP_VALIDATION_SCHEMA = () => Yup.object().shape({
  optionComments: Yup.array().of(Yup.object().shape({
    optionId: Yup.string().uuid().required(strings.inputValidateRequiredField),
    comment: Yup.string().max(50, strings.formatString(strings.inputValidateStringLength, 50) as string)
  })),
  selectedOptionValues: SELECTED_OPTION_VALUES_SCHEMA,
});
export const OPTIONS_SETUP_WITH_MATCHINGS = () => OPTIONS_SETUP_VALIDATION_SCHEMA().concat(Yup.object().shape({
  selectedOptionValues: SELECTED_OPTION_VALUES_SCHEMA
    .test({
      name: 'only-one-option-value-for-every-option',
      message: strings.articleFormInputValidateWithMatchingsOneOptionValueInOption,
      test: v => {
        if (v) {
          const optionDuplicateIdx = v.map(item => item.optionId).findIndex((name, idx, arr) => arr.indexOf(name) !== idx);
          if (optionDuplicateIdx >= 0) {
            return false;
          }
        }
        return true;
      }
    }),
}));

const TEMPLATE_SIDE_SCHEMA = () => Yup.object().shape({
  canvas: Yup.object().shape({
    version: Yup.string().required(),
    objects: Yup.array().of(Yup.object().shape({
      id: Yup.lazy(value => typeof value === 'string'
        ? Yup.string().required()
        : Yup.number().required()),
      name: Yup.string().required(),
      type: Yup.string().required(),
      subtype: Yup.string(),
      src: Yup.string(),
      content: Yup.string(),
      side: Yup.string().oneOf(Object.values(ETemplateSides)),
    })).required(),
    backgroundImage: Yup.mixed().required(),
    integrationMatchings: Yup.lazy(v => Object.keys(v || {})?.length === 0
      ? Yup.object().shape({}).default(undefined)
      : Yup.object().shape({
        ticketId: Yup.string().required(),
        ticketName: Yup.string().required(),
        service: Yup.mixed().oneOf(Object.values(EIntegrationServices)),
        fields: Yup.lazy(fields => Yup.object(Object.keys(fields).reduce((acc, next) => ({
          ...acc,
          [next]: Yup.string().required(),
        }), {}))),
      })),
  }).required(),
  side: Yup.string().oneOf(Object.values(ETemplateSides)).required(),
  document: Yup.object().required(),
  thumbnail: Yup.string().required(),
});

export const TEMPLATE_VALIDATION_SCHEMA = () => Yup.object().shape({
  frontSide: TEMPLATE_SIDE_SCHEMA().nullable(true).default(null),
  backSide: TEMPLATE_SIDE_SCHEMA().nullable(true).default(null),
}).test(
  'at-least-one-template-in-published-status',
  strings.templateValidateAtLeastOneSideIsRequired,
  (sides, context) => {
    const formValues = context.parent as IArticleFormValues;
    return !(
      formValues.footer.status.value === EArticleStatuses.PUBLISHED
      && Object.values(sides).every(side => side === null)
    );
  }
);

export const FOOTER_VALIDATION_SCHEMA = () => Yup.object().shape({
  status: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().oneOf(Object.values(EArticleStatuses))
  })
    .required(strings.inputValidateRequiredField),
});

// 2 args for using in component or inside yup lazy
export const matchingsExists = (yupCtx: null | ResolveOptions<any> = null, templateValues: null | IFormTemplateValues = null): boolean => {
  const template: IFormTemplateValues | null = yupCtx?.parent?.template || templateValues;
  if (template) {
    const sides = Object.keys(template) as Array<keyof IFormTemplateValues>;
    return sides.length ? sides.some(side => template[side]?.canvas?.integrationMatchings?.ticketId) : false;
  }
  return false;
};
export const ARTICLE_SCHEMES = () => Yup.object({
  productInfo: PRODUCT_INFO_VALIDATION_SCHEMA(),
  productSetup: Yup.lazy((_, ctx) => matchingsExists(ctx) ? PRODUCT_SETUP_WITH_MATCHINGS() : PRODUCT_SETUP_VALIDATION_SCHEMA()),
  optionsSetup: Yup.lazy((_, ctx) => matchingsExists(ctx) ? OPTIONS_SETUP_WITH_MATCHINGS() : OPTIONS_SETUP_VALIDATION_SCHEMA()),
  template: TEMPLATE_VALIDATION_SCHEMA(),
  footer: FOOTER_VALIDATION_SCHEMA(),
});

export interface IFormProductInfoValues {
  skuIdTitle: string,
  title: string,
  description: string
}
export type TFormikQuantity = {
  qtyId: string,
  paperId: string,
  value: TQuantity['value'],
  isSelected: boolean,
  isDisabled: boolean,
}
export interface IFormProductSetupValues {
  special: boolean,
  size: TSize | null,
  selectedPapers: string[],
  quantities: TFormikQuantity[],
  sizeComment: string,
  paperComment: string
}
export type TFormikOptionComment = {
  optionId: string,
  comment: string
}
export type TFormikSelectedOptionValue = {
  optionId: string,
  optionValueId: string,
}
export interface IFormOptionsSetupValues {
  optionComments: TFormikOptionComment[],
  selectedOptionValues: TFormikSelectedOptionValue[],
}
export interface IFormTemplateValues {
  frontSide: TArticleDocument,
  backSide: TArticleDocument,
}
export interface IFormFooterValues {
  status: TArticleStatusOption,
}
export type TFormProductInfoKeys = keyof IFormProductInfoValues;
export type TFormProductSetupKeys = keyof IFormProductSetupValues;
export type TFormOptionsSetupKeys = keyof IFormOptionsSetupValues;
export type TFormTemplateKeys = keyof IFormTemplateValues;
export type TFormFooterKeys = keyof IFormFooterValues;

export type TProductInfoDisabled = Record<TFormProductInfoKeys, boolean>;

export interface IArticleFormValues {
  productInfo: IFormProductInfoValues,
  productSetup: IFormProductSetupValues,
  optionsSetup: IFormOptionsSetupValues,
  template: IFormTemplateValues,
  footer: IFormFooterValues,
}

export const PRODUCT_INFO_FORM: keyof IArticleFormValues = 'productInfo';
export const PRODUCT_SETUP_FORM: keyof IArticleFormValues = 'productSetup';
export const OPTIONS_SETUP_FORM: keyof IArticleFormValues = 'optionsSetup';
export const TEMPLATE_FORM: keyof IArticleFormValues = 'template';
export const FOOTER_FORM: keyof IArticleFormValues = 'footer';

export const PRICING_TABLE_PAGE_SIZE = 5;
export const OPTIONAL_PRICING_TABLE_PAGE_SIZE = 5;
