/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAltUnLocked(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 16 16">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 5.7207C3.44772 5.7207 3 6.16842 3 6.7207V13.5628C3 14.1151 3.44772 14.5628 4 14.5628H12.1053C12.6575 14.5628 13.1053 14.1151 13.1053 13.5628V6.7207C13.1053 6.16842 12.6575 5.7207 12.1053 5.7207H4ZM11.8422 6.98391H4.26326V13.2997H11.8422V6.98391Z" fill="#2C3E66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.89453 4.4577C4.89453 2.71364 6.30837 1.2998 8.05243 1.2998C9.79648 1.2998 11.2103 2.71364 11.2103 4.4577V5.72346C11.1756 5.72166 11.1406 5.72075 11.1054 5.72075H9.94747V4.4576C9.94747 3.41116 9.09917 2.56286 8.05273 2.56286C7.0063 2.56286 6.158 3.41116 6.158 4.4576V5.72075H5.0001C4.96469 5.72075 4.92949 5.72167 4.89453 5.72349V4.4577ZM8.02665 14.5629C8.03523 14.5629 8.04383 14.563 8.05243 14.563C8.06103 14.563 8.06962 14.5629 8.0782 14.5629H8.05273H8.02665Z" fill="#2C3E66" />
      <rect x="4.7998" y="4.21973" width="1.5" height="1.5" fill="#E7F6FF" />
    </svg>
  );
}

export default IconAltUnLocked;
