/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextLowercase(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M19.0596 18.0004C18.9591 17.7123 18.892 17.4137 18.8596 17.1104C18.1896 17.8104 17.3796 18.1604 16.4496 18.1604C15.6196 18.1604 14.9296 17.9204 14.3996 17.4504C13.8696 17.0004 13.5996 16.3904 13.5996 15.6604C13.5996 14.7804 13.9296 14.1004 14.5996 13.6104C15.2696 13.1204 16.2096 12.8804 17.4296 12.8804H18.8296V12.2404C18.8296 11.7504 18.6796 11.3604 18.3796 11.0704C18.0796 10.7804 17.6296 10.6404 17.0496 10.6404C16.5296 10.6404 16.0996 10.7604 15.7496 11.0004C15.3996 11.2504 15.2296 11.5404 15.2296 11.8904H13.7696C13.7696 11.4604 13.9196 11.0504 14.2196 10.6504C14.4996 10.2504 14.9296 9.94035 15.4396 9.71035C15.9496 9.50035 16.4996 9.36035 17.1296 9.36035C18.1096 9.36035 18.8696 9.60035 19.4196 10.0904C19.9696 10.5804 20.2596 11.2504 20.2796 12.1104V16.0004C20.2796 16.8004 20.3796 17.4204 20.5796 17.8804V18.0004H19.0596ZM16.6596 16.8804C17.1096 16.8804 17.5396 16.7704 17.9496 16.5604C18.3496 16.3504 18.6496 16.0704 18.8296 15.7304V14.1604H17.6996C15.9296 14.1604 15.0396 14.6304 15.0396 15.5704C15.0396 16.0004 15.1896 16.3004 15.4996 16.5304C15.7996 16.7604 16.1796 16.8804 16.6596 16.8804Z" fill="#2C3E66" />
      <path d="M9.05961 18.0004C8.95914 17.7123 8.89203 17.4137 8.85961 17.1104C8.18961 17.8104 7.37961 18.1604 6.44961 18.1604C5.61961 18.1604 4.92961 17.9204 4.39961 17.4504C3.86961 17.0004 3.59961 16.3904 3.59961 15.6604C3.59961 14.7804 3.92961 14.1004 4.59961 13.6104C5.26961 13.1204 6.20961 12.8804 7.42961 12.8804H8.82961V12.2404C8.82961 11.7504 8.67961 11.3604 8.37961 11.0704C8.07961 10.7804 7.62961 10.6404 7.04961 10.6404C6.52961 10.6404 6.09961 10.7604 5.74961 11.0004C5.39961 11.2504 5.22961 11.5404 5.22961 11.8904H3.76961C3.76961 11.4604 3.91961 11.0504 4.21961 10.6504C4.49961 10.2504 4.92961 9.94035 5.43961 9.71035C5.94961 9.50035 6.49961 9.36035 7.12961 9.36035C8.10961 9.36035 8.86961 9.60035 9.41961 10.0904C9.96961 10.5804 10.2596 11.2504 10.2796 12.1104V16.0004C10.2796 16.8004 10.3796 17.4204 10.5796 17.8804V18.0004H9.05961ZM6.65961 16.8804C7.10961 16.8804 7.53961 16.7704 7.94961 16.5604C8.34961 16.3504 8.64961 16.0704 8.82961 15.7304V14.1604H7.69961C5.92961 14.1604 5.03961 14.6304 5.03961 15.5704C5.03961 16.0004 5.18961 16.3004 5.49961 16.5304C5.79961 16.7604 6.17961 16.8804 6.65961 16.8804Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextLowercase;
