import React, { useState } from 'react';
import MuiRadio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import cls from 'classnames';

import textCss from 'assets/styles/Text.module.css';
import { TLocalizedConstString } from 'types';
import Label, { Props as LabelProps } from '../Label';
import css from './Radio.module.css';

export interface IRadioOption<T> {
  label: TLocalizedConstString,
  domValue: string,
  value: T
}

export interface IRadio<T> {
  options: IRadioOption<T>[],
  onChange: (value: IRadioOption<T>) => void,
  defaultValue?: IRadioOption<T>,
  label?: string,
  labelProps?: LabelProps,
  className?: string
}

function Radio<T>({
  options, onChange, defaultValue, label, labelProps, className, ...rest
}: IRadio<T>) {
  const [selectedValue, setSelectedValue] = useState<string | null>(defaultValue?.domValue || null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedValue(value);
    const selectedOption = options.find(({ domValue: optionValue }) => optionValue === value);
    onChange(selectedOption ?? options[0]);
  };

  return (
    <div className={className}>
      {label && <Label id={label} text={label} {...labelProps} />}
      <RadioGroup
        aria-labelledby={label}
        name="radio-buttons-group"
        onChange={handleChange}
        value={selectedValue}
        className={css.radioGroup}
        {...rest}
      >
        {options.map(({ domValue, label: optionLabel }) => (
          <FormControlLabel
            key={domValue}
            value={domValue}
            classes={{
              label: cls({
                [textCss.h5]: selectedValue === domValue,
                [textCss.pMedium3]: selectedValue !== domValue
              })
            }}
            control={(
              <MuiRadio
                icon={<span className={css.icon} />}
                checkedIcon={<span className={cls(css.icon, css.checkedIcon)} />}
              />
            )}
            label={optionLabel()}
          />
        ))}
      </RadioGroup>
    </div>
  );
}

export default Radio;
