import React from 'react';
import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import css from './Error.module.css';

interface IError {
  code?: number | string,
  title?: string,
  description?: string
}

const Error:React.FC<IError> = props => {
  const { description = '', code = 404, title = strings.errorResponse404 } = props;

  return (
    <div className={css.container}>
      <div className={css.content}>
        <span className={textCss.h3}>{code}: {title.replace('-', ' ')}</span>
        {description && <span className={textCss.pMedium2}>{description}</span>}
      </div>
    </div>
  );
};

export default Error;
