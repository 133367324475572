/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconNext(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.292444 1.12799C0.199741 1.2205 0.126193 1.33039 0.0760116 1.45137C0.0258302 1.57234 -3.79325e-08 1.70202 -4.36573e-08 1.83299C-4.93821e-08 1.96396 0.0258301 2.09364 0.0760116 2.21461C0.126193 2.33559 0.199741 2.44548 0.292444 2.53799L4.17244 6.41799L0.292444 10.298C0.199862 10.3906 0.126422 10.5005 0.0763174 10.6214C0.0262124 10.7424 0.000422994 10.8721 0.000422987 11.003C0.00042298 11.1339 0.0262124 11.2636 0.0763173 11.3845C0.126422 11.5055 0.199862 11.6154 0.292444 11.708C0.385026 11.8006 0.494936 11.874 0.615901 11.9241C0.736865 11.9742 0.866513 12 0.997444 12C1.12837 12 1.25802 11.9742 1.37899 11.9241C1.49995 11.874 1.60986 11.8006 1.70244 11.708L6.29244 7.11799C6.38515 7.02548 6.4587 6.91559 6.50888 6.79462C6.55906 6.67364 6.58489 6.54396 6.58489 6.41299C6.58489 6.28202 6.55906 6.15234 6.50888 6.03137C6.4587 5.91039 6.38515 5.8005 6.29244 5.70799L1.70244 1.11799C1.32244 0.737991 0.682444 0.737991 0.292444 1.12799Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconNext;
