const TEXT_COLOR = 'red';
const TEXT_FONT = 'Arial';
const TEXT_FONTSIZE_FACTOR = 10;
const BACKGROUND_COLOR = 'white';

interface ICreateSvg {
  namespace?: string,
  width: number,
  height: number,
  backgroundFill: string,
  fontSize: number,
  fontFamily: string,
  textColor: string,
  textLines: ITextLine[],
}
export const createSvg = (props: ICreateSvg): SVGElement => {
  const {
    namespace = 'http://www.w3.org/2000/svg',
    width,
    height,
    backgroundFill,
    fontSize,
    fontFamily,
    textColor,
    textLines,
  } = props;

  const svg = document.createElementNS(namespace, 'svg') as SVGElement;
  svg.setAttribute('width', width.toString());
  svg.setAttribute('height', height.toString());
  svg.setAttribute('viewbox', `0 0 ${width} ${height}`);

  const bg = document.createElementNS(namespace, 'rect');
  bg.setAttribute('x', '0');
  bg.setAttribute('y', '0');
  bg.setAttribute('width', width.toString());
  bg.setAttribute('height', height.toString());
  bg.setAttribute('fill', backgroundFill);

  const textWrapper = document.createElementNS(namespace, 'g');
  textWrapper.setAttribute('dy', '0.5em');
  textWrapper.setAttribute('dominant-baseline', 'middle');
  textWrapper.setAttribute('text-anchor', 'middle');
  textWrapper.setAttribute('font-size', fontSize.toString());
  textWrapper.setAttribute('font-family', fontFamily);
  textWrapper.setAttribute('fill', textColor);

  textLines.forEach(({ text, x, y }) => {
    const textLine = document.createElementNS(namespace, 'text');
    textLine.setAttribute('x', x.toString());
    textLine.setAttribute('y', y.toString());
    textLine.textContent = text;

    textWrapper.appendChild(textLine);
  });

  svg.appendChild(bg);
  svg.appendChild(textWrapper);

  return svg;
};

export const createCodePlaceholder = (text: string, width: number, height: number): SVGElement => {
  const fontSize = width / TEXT_FONTSIZE_FACTOR;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  const textLines: ITextLine[] = [];
  if (ctx) {
    ctx.fillStyle = BACKGROUND_COLOR;
    ctx.fillRect(0, 0, width, height);

    ctx.font = `${fontSize}px ${TEXT_FONT}`;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';

    textLines.push(...splitToLines(ctx, text, width / 2, height / 2, (width - (width / 10))));
  }

  return createSvg({
    width,
    height,
    backgroundFill: BACKGROUND_COLOR,
    fontSize,
    fontFamily: TEXT_FONT,
    textColor: TEXT_COLOR,
    textLines,
  });
};

interface EReduceLines {
  lines: string[],
  line: string,
}

interface ITextLine {
  text: string,
  x: number,
  y: number,
}

const splitToLines = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number, maxWidth: number): ITextLine[] => {
  const paragraphs = text.split('\n');

  const textLines = paragraphs.map(paragraph => {
    const words: string[] = paragraph.split(' ');

    const lines: EReduceLines = words.reduce((prev: EReduceLines, curr) => {
      const newLine = `${prev.line} ${curr}`.trim();
      const metrics = ctx.measureText(newLine);
      if (metrics.width > maxWidth) {
        prev.lines.push(prev.line);
        prev.line = curr;
      } else {
        prev.line = newLine;
      }
      return prev;
    }, {
      lines: [],
      line: '',
    });

    return Object.values(lines).flat();
  }).flat();

  const { width: lineHeight } = ctx.measureText('O ');

  // Move text up if centered vertically
  if (ctx.textBaseline === 'middle') {
    y -= ((textLines.length - 1) * lineHeight) / 2;
  }

  // Render text on canvas
  return textLines.map(line => {
    const textLine: ITextLine = { text: line, x, y };
    y += lineHeight;
    return textLine;
  });
};

export default { createCodePlaceholder };
