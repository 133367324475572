import { useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import { IGetArticlesParams } from 'types/Articles';
import { STALE_TIME } from 'constants/constants';
import { IResponseError, IFindResponse } from 'constants/types';
import { TGetArticlesItem, EArticleStatuses } from 'types';

const client = instance.getClient();

export const FIND_ARTICLES_QUERY = ['articles', 'find'];
export const findArticles = (params: IGetArticlesParams): Promise<IFindResponse<TGetArticlesItem[]>> => {
  const {
    pspId, customerId, search, sort, status, skip, limit
  } = params;
  const query = [];
  if (pspId) {
    query.push(`pspId=${params.pspId}`);
  }
  if (customerId) {
    query.push(`customerId=${params.customerId}`);
  }
  if (search) {
    query.push(`title[%24iLike]=%25${params.search}%25`);
  }
  if (sort) {
    query.push(`%24sort[${sort[0]}]=${sort[1]}`);
  }
  if (limit) {
    query.push(`%24limit=${limit}`);
  }
  if (skip) {
    query.push(`%24skip=${skip}`);
  }
  // TODO: remake after TSort generic done
  if (status[1] >= 0) {
    query.push(`status=${status[1] === 0 ? EArticleStatuses.DRAFT : EArticleStatuses.PUBLISHED}`);
  }
  return client
    .get(`/articles?${query.join('&')}`)
    .then(res => res.data);
};

export const useFindArticles = (data: IGetArticlesParams) => useQuery<IFindResponse<TGetArticlesItem[]>, IResponseError>(
  [...FIND_ARTICLES_QUERY, data.customerId, data.search, data.skip, data.limit, data.sort, data.status],
  () => findArticles(data),
  {
    staleTime: STALE_TIME,
  }
);

export const useInvalidateFindArticles = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...FIND_ARTICLES_QUERY, ...additionalParams]);
};

export default useFindArticles;
