/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAltLocked(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 16 16">
      <path d="M3 6.7207C3 6.16842 3.44772 5.7207 4 5.7207H12.1053C12.6575 5.7207 13.1053 6.16842 13.1053 6.7207V13.5628C13.1053 14.1151 12.6575 14.5628 12.1053 14.5628H4C3.44772 14.5628 3 14.1151 3 13.5628V6.7207Z" fill="#2C3E66" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.89478 4.4577C4.89478 2.71364 6.30861 1.2998 8.05267 1.2998C9.79673 1.2998 11.2106 2.71364 11.2106 4.4577V5.72346C11.1758 5.72166 11.1408 5.72075 11.1056 5.72075H9.94772V4.4576C9.94772 3.41116 9.09941 2.56286 8.05298 2.56286C7.00654 2.56286 6.15824 3.41116 6.15824 4.4576V5.72075H5.00035C4.96493 5.72075 4.92974 5.72167 4.89478 5.72349V4.4577ZM8.0269 14.5629C8.03548 14.5629 8.04407 14.563 8.05267 14.563C8.06127 14.563 8.06986 14.5629 8.07845 14.5629H8.05298H8.0269Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAltLocked;
