import { Settings } from 'react-slick';
import { NavigateFunction } from 'react-router/lib/hooks';
import { FormikErrors, FormikValues } from 'formik';

import {
  EArticleStatuses,
  ELanguages,
  EPermissions,
  EPrintItemNestedFields,
  ERoles,
  ETablePageViews,
  ETextCase,
  EUnits,
  TGetArticlesItemSelectedPaper,
  TGetArticlesItemTemplate,
  TPrintItemNestedField,
  TPrintItemsItem,
  TSizeDimensions,
  TUser,
} from 'types';
import { IGetPrintItemsParams } from 'types/Portfolio';
import { TSort } from 'components/library/Table/types';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import strings from 'constants/localization';
import {
  DPI_IMAGE_ERROR_THRESHOLD,
  DPI_IMAGE_WARNING_THRESHOLD,
  ROLE_TO_LIST_ROUTE,
  ROUTES,
  STATUS_ORDER_OPTIONS,
  SUPPORT_LINK,
} from 'constants/constants';
import { IRadioOption } from 'components/library/Radio';
import { IGetArticleParams } from 'types/Articles';
import { IResponseError } from 'constants/types';
import fabric from 'components/PageArticles/components/Editor/helpers/fabric';
import { IAlertAction } from 'contexts/ContextAlert';
import { IMenuItem } from 'components/Layout/components/MenuItem';
import { Location } from 'history';
import { AxiosRequestHeaders } from 'axios';
import { IGetOrdersReportParams } from '../types/Reports/Orders';

export const isAdmin = (permissions: EPermissions[]) => permissions?.includes(EPermissions.ADMIN);
export const isPrinter = (permissions: EPermissions[]) => permissions?.includes(EPermissions.PRINTER);
export const isManager = (permissions: EPermissions[]) => permissions?.includes(EPermissions.MANAGER);
export const isAppEventbriteManager = (permissions: EPermissions[]) => permissions?.includes(EPermissions.MANAGER)
  && permissions?.includes(EPermissions.APP_EVENTBRITE);
export const isCustomer = (permissions: EPermissions[]) => permissions?.includes(EPermissions.CUSTOMER);

const articlePreviewImagePlaceholder = `${process.env.PUBLIC_URL}/previewPlaceholder.png`;

const commonTopMenuItems:IMenuItem[] = [
  {
    to: ROUTES.PROFILE,
    type: E_ICON_TYPE.profile,
    title: () => strings.routeProfile
  }
];
export const getTopMenuItems = (userPermission: EPermissions[], userId?: string):IMenuItem[] => {
  if (isAdmin(userPermission)) {
    return [
      {
        to: ROUTES.PRINTERS,
        type: E_ICON_TYPE.managers,
        title: () => strings.routePrinters
      },
      ...commonTopMenuItems
    ];
  }
  if (isPrinter(userPermission)) {
    return [
      {
        to: ROUTES.PORTFOLIO,
        type: E_ICON_TYPE.home,
        title: () => strings.routePortfolio
      },
      {
        to: ROUTES.ORDERS,
        type: E_ICON_TYPE.orders,
        title: () => strings.routeOrders
      },
      {
        to: ROUTES.CUSTOMERS,
        type: E_ICON_TYPE.customers,
        title: () => strings.routeCustomers
      },
      {
        to: ROUTES.MANAGERS,
        type: E_ICON_TYPE.managers,
        title: () => strings.routeManagers
      },
      ...commonTopMenuItems
    ];
  }
  if (isAppEventbriteManager(userPermission)) {
    return [
      {
        to: ROUTES.ORDERS,
        type: E_ICON_TYPE.orders,
        title: () => strings.routeOrders
      },
      {
        to: ROUTES.CUSTOMERS,
        type: E_ICON_TYPE.customers,
        title: () => strings.routeEvents
      }
    ];
  }
  if (isManager(userPermission)) {
    return [
      {
        to: ROUTES.ORDERS,
        type: E_ICON_TYPE.orders,
        title: () => strings.routeOrders
      },
      {
        to: ROUTES.CUSTOMERS,
        type: E_ICON_TYPE.customers,
        title: () => strings.routeCustomers
      },
      ...commonTopMenuItems
    ];
  }
  if (isCustomer(userPermission)) {
    return [
      {
        to: ROUTES.ORDERS,
        type: E_ICON_TYPE.orders,
        title: () => strings.routeOrders
      },
      {
        to: `${ROUTES.ARTICLES}?customerId=${userId}`,
        type: E_ICON_TYPE.SKUs,
        title: () => strings.routeSKUs
      },
      ...commonTopMenuItems
    ];
  }
  return [];
};

export const getBottomMenuItems = (): IMenuItem[] => [
  {
    to: '',
    external: SUPPORT_LINK,
    type: E_ICON_TYPE.chat,
    title: () => strings.routeChat,
  },
  {
    to: ROUTES.LOGOUT,
    type: E_ICON_TYPE.logout,
    title: () => strings.routeLogout,
  }
];

export const getDefaultRoute = (role: ERoles, userId?: string) => {
  const defaultRoutes: {[key in ERoles]: string} = {
    [ERoles.ADMIN]: ROUTES.BASE + ROUTES.PRINTERS,
    [ERoles.PRINTER]: ROUTES.BASE + ROUTES.ORDERS,
    [ERoles.MANAGER]: ROUTES.BASE + ROUTES.CUSTOMERS,
    [ERoles.CUSTOMER]: `${ROUTES.BASE + ROUTES.ARTICLES}?customerId=${userId}&view=${ETablePageViews.GRID}`,
  };
  return defaultRoutes[role];
};

export const isPreviousPageExist = () => window.history.state && window.history.state.idx > 0;
export const navigateBack = (navigate: NavigateFunction, fallbackRoute: string = ROUTES.PROFILE) => {
  if (isPreviousPageExist()) {
    navigate(-1);
  } else {
    navigate(fallbackRoute);
  }
};

export const navigateWithState = (navigate: NavigateFunction, location: Location, appendState: any, options?: any): void => {
  const { pathname, search, state: existingState } = location;
  const state = { ...(existingState as object || {}), ...appendState };
  navigate(pathname + search, { state, ...options });
};

export const getCustomersTableSliderParams = (additionalSettings: Settings = {}): Settings => {
  const DEFAULT_SLIDER_SETTINGS: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return {
    ...DEFAULT_SLIDER_SETTINGS,
    ...additionalSettings,
  };
};

/* PORTFOLIO */
export const displayQuantitiesAndPapers = (row: TPrintItemsItem, field: EPrintItemNestedFields): string => {
  const items: Array<TPrintItemNestedField> = row[field] || [];
  if (!items || items.length === 0) {
    return '';
  }
  if (items.length === 1) {
    return String(items[0].value);
  }
  return items.map(({ value }) => value).join(' / ');
};

export const getPrintItemsCommonQuery = ({
  includePapers,
  includeQuantities,
  includeSizes,
  includeOptions,
  includePricing,
  includeOptionalPricing,
  includeBase,
  pspId,
  search,
  limit,
  skip,
  sort
}: IGetPrintItemsParams): string => {
  const query = ['?'];
  if (pspId) {
    query.push(`pspId=${pspId}`);
  }
  if (includeBase) {
    query.push('includeBase=1');
  } else {
    if (includePapers) {
      query.push('includePapers=1');
    }
    if (includeQuantities) {
      query.push('includeQuantities=1');
    }
    if (includeSizes) {
      query.push('includeSizes=1');
    }
    if (includeOptions) {
      query.push('includeOptions=1');
    }
    if (includePricing) {
      query.push('includePricing=1');
    }
    if (includeOptionalPricing) {
      query.push('includeOptionalPricing=1');
    }
  }
  if (search) {
    query.push(`%24or[0][title][%24iLike]=%25${search}%25`);
    query.push(`%24or[1][description][%24iLike]=%25${search}%25`);
  }
  if (limit) {
    query.push(`%24limit=${limit}`);
  }
  if (skip) {
    query.push(`%24skip=${skip}`);
  }
  if (sort) {
    query.push(`%24sort[${sort[0]}]=${sort[1]}`);
  }
  return query.join('&');
};
/* PORTFOLIO */

/* ARTICLES */
export const getPreviewImageSrc = (articleTemplates: TGetArticlesItemTemplate[]): string | undefined => articleTemplates
  .find(template => template.thumbnail)?.thumbnail ?? articlePreviewImagePlaceholder;

export const displayArticleQuantities = (selectedPapers: TGetArticlesItemSelectedPaper[]) => selectedPapers
  .flatMap(({ quantities: qties }) => qties)
  .map(({ value }) => Number(value))
  .filter((quantity, idx, arr) => arr.indexOf(quantity) === idx)
  .sort((a, b) => a - b)
  .join(' / ');

export const getArticlesStatusOnMount = (isNotCustomer: boolean, outerStatus: EArticleStatuses | undefined): IRadioOption<TSort> => {
  if (isNotCustomer) {
    const initialStatus = STATUS_ORDER_OPTIONS.find(statusOption => statusOption.domValue === outerStatus);
    return initialStatus || STATUS_ORDER_OPTIONS[2];
  }
  return STATUS_ORDER_OPTIONS[0];
};

export const getArticleGetQuery = ({
  pspId, customerId, includeBase, copy
}: IGetArticleParams): string => {
  const query = ['?'];
  if (includeBase) {
    query.push('includeBase=1');
  }
  if (pspId) {
    query.push(`pspId=${pspId}`);
  }
  if (customerId) {
    query.push(`customerId=${customerId}`);
  }
  if (copy) {
    query.push('copy=true');
  }
  return query.join('&');
};
/* ARTICLES */

/* REPORTS */
export const getGetOrdersReportQuery = ({ from, to }: IGetOrdersReportParams): string => {
  const query = ['?'];
  if (from) {
    query.push(`from=${from}`);
  }
  if (to) {
    query.push(`to=${to}`);
  }
  return query.join('&');
};

export const getOrdersReportHeaders = ({ type }: IGetOrdersReportParams): AxiosRequestHeaders => {
  const headers = { accept: 'text/plain' };
  if (type) {
    switch (type) {
      case 'csv':
        headers.accept = 'text/csv';
        break;
      case 'json':
        headers.accept = 'application/json';
        break;
      default:
        headers.accept = 'text/plain';
    }
  }

  return headers;
};
/* REPORTS */

export const getPermissionsForRole = (role: ERoles):EPermissions[] => {
  const checker = {
    [ERoles.ADMIN]: [EPermissions.ADMIN],
    [ERoles.PRINTER]: [EPermissions.PRINTER],
    [ERoles.MANAGER]: [EPermissions.MANAGER],
    [ERoles.CUSTOMER]: [EPermissions.CUSTOMER],
  };
  return checker[role];
};

const getManagerIdForUser = (
  roleToCreate: ERoles,
  userFromLocation: Partial<TUser> | undefined,
  user: TUser
): TUser['managerId'] => roleToCreate === ERoles.CUSTOMER
  ? userFromLocation?.managerId || user.id
  : null;

export const createTargetUser = (role: ERoles, userFromLocation: Partial<TUser> | undefined, user: TUser):TUser => ({
  id: '',
  role,
  userPermissions: getPermissionsForRole(role),
  position: '',
  name: userFromLocation?.name || '',
  surname: userFromLocation?.surname || '',
  avatar: '',
  phoneNumber: '',
  email: userFromLocation?.email || '',
  lang: ELanguages.EN,
  companyName: userFromLocation?.companyName || '',
  articleStats: [],
  orderStats: [],
  sendOrderSheetsTo: userFromLocation?.sendOrderSheetsTo || '',
  currency: '€',
  blocked: false,
  confirmed: false,
  fullProfile: false,
  deleted: false,
  printerBlocked: false,
  skuPreview: false,
  lastLoginAt: null,
  managerId: getManagerIdForUser(role, userFromLocation, user),
  pspId: user.pspId,
});

export const getUserProfileLink = (userRole: ERoles, userId: TUser['id']) => [ROLE_TO_LIST_ROUTE[userRole], userId].join('/');

export const getUserFullName = ({ name, surname }: { name: string, surname: string, }) => `${name} ${surname}`;

export const getSearchParamsObject = (searchParams: URLSearchParams) => Object.fromEntries(Array.from(searchParams));

export const isUserBlocked = (error: IResponseError): boolean => {
  const statusCode = error.response?.status;
  const reason = error.response?.data.data?.reason;
  return statusCode === 403 && reason === 'blocked';
};

export const checkUserBlocked = (error: IResponseError): void => {
  if (isUserBlocked(error as IResponseError)) {
    window.location.assign([ROUTES.USER_LOGIN, ROUTES.USER_BLOCKED].join('/'));
  }
};

export const showEmptyState = (isLoading: boolean, length: number, searchValue: boolean) => isLoading && length === 0 && searchValue;

export const replaceNumberSeparator = (v: string, toNumberFormat = true): string => toNumberFormat
  ? v.replaceAll(',', '.')
  : String(v).replaceAll('.', ','); // saved items comes as numbers, so cast to string first

export const addValuesAfterComma = (value: string) => {
  if (value.match(/^[0-9]*[.,][0-9]{2}$/)) {
    return replaceNumberSeparator(value, false);
  }
  return replaceNumberSeparator(Number(replaceNumberSeparator(value)).toFixed(2), false);
};

const convertSizeToInch = (imgSize: number, units: EUnits) => {
  const inch = 2.54;
  return units === EUnits.MM ? (imgSize / 10) / inch : imgSize / inch;
};
const convertSizeToPx = (imgSize: number, units: EUnits) => {
  const ration = 0.26458333;
  return units === EUnits.MM ? imgSize / ration : (imgSize * 10) / ration;
};

const getRealImgSize = (printItemSize:number, scaledImgSize:number, printItemPxSize:number) => (printItemSize * scaledImgSize) / printItemPxSize;

export const calculateImgDPI = (imgObject: typeof fabric.ImageObject, size:TSizeDimensions, units = EUnits.MM): number => {
  // print item sizes
  const widthPrintItem = size.width; // mm or cm
  const heightPrintItem = size.height; // mm or cm
  const widthPrintItemPx = convertSizeToPx(widthPrintItem, units);
  const heightPrintItemPx = convertSizeToPx(heightPrintItem, units);

  // uploaded img sizes (px)
  const heightScaledImg = imgObject.getScaledHeight();
  const widthScaledImg = imgObject.getScaledWidth();
  const diagonalImgPx = Math.hypot(imgObject.width, imgObject.height);

  // uploaded img real sizes
  const widthImg = getRealImgSize(widthPrintItem, widthScaledImg, widthPrintItemPx);
  const heightImg = getRealImgSize(heightPrintItem, heightScaledImg, heightPrintItemPx);
  const diagonalImgInch = Math.hypot(convertSizeToInch(widthImg, units), convertSizeToInch(heightImg, units));
  // dpi img
  return diagonalImgPx / diagonalImgInch;
};

export const dpiNotification = (notify:(action: Omit<IAlertAction, 'onClose'>) => void, dpi:number, withWarning = true) => {
  if (dpi < DPI_IMAGE_ERROR_THRESHOLD) {
    notify({ severity: 'error', message: strings.orderPageDpiNotificationError });
  }
  if ((dpi >= DPI_IMAGE_ERROR_THRESHOLD && dpi < DPI_IMAGE_WARNING_THRESHOLD) && withWarning) {
    notify({ severity: 'warning', message: strings.orderPageDpiNotificationWarning });
  }
};
export const dpiChecker = (dpi:number) => {
  if (dpi < DPI_IMAGE_ERROR_THRESHOLD) {
    return false;
  }
  if (dpi >= DPI_IMAGE_ERROR_THRESHOLD && dpi < DPI_IMAGE_WARNING_THRESHOLD) {
    return true;
  }
  return true;
};

export const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const isSuccess = document.execCommand('copy');
    if (!isSuccess) {
      throw new Error('Oops, unable to copy');
    }
  } finally {
    document.body.removeChild(textArea);
  }
};

export const copyTextToClipboard = (text: string): void | Promise<void> => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
};

export const formatText = (text: string, action: ETextCase): string => {
  switch (action) {
    case ETextCase.Lowercase:
      return text.toLowerCase();
    case ETextCase.Uppercase:
      return text.toUpperCase();
    case ETextCase.Capitalize: {
      const firstLetterOfEachWord = /(^\w{1})|(\s+\w{1})/g;
      return text.replace(firstLetterOfEachWord, letter => letter.toUpperCase());
    }
    default:
      return text;
  }
};

export const getBackendErrors = <Values extends FormikValues>(err: IResponseError): FormikErrors<Values> => {
  const backendErrors: FormikErrors<Values> = {};
  err.response?.data.data?.fields?.forEach(({ field, message }) => {
    backendErrors[field as keyof Values] = message as FormikErrors<Values>[keyof Values];
  });
  return backendErrors;
};

export const capitalizePhrase = (phrase: string): string => phrase.charAt(0).toUpperCase() + phrase.slice(1);
