import { useMutation } from 'react-query';
import { IResponseError } from '../constants/types';
import instance from '../lib/apiClient';

const client = instance.getClient();

type TCell = string | { type: 'image' | 'string', value: string }
type TResult = TCell[][]

interface IParsedQuery {
  type: 'csv' | 'xlsx',
  file: string | ArrayBuffer,
}
export function getParsedData({ type, file }:IParsedQuery):Promise<TResult> {
  return client.post('/parser', { type, file }).then(res => res.data);
}
export const QUERY_KEY_POST = ['parser', 'post'];
export function useParser() {
  return useMutation<TResult, IResponseError, IParsedQuery>(
    QUERY_KEY_POST,
    data => getParsedData(data),
    {
      retry: false,
    }
  );
}
