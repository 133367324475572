import React from 'react';
import { ActionMeta, MultiValue, StylesConfig } from 'react-select';

import Select from 'components/library/Select';
import cls from 'classnames';
import { getFieldError, getFieldName } from '../../index';
import { TFormikQuantity } from '../../../../constants';
import { IPaperAndQuantity } from './index';
import css from './PaperAndQuantity.module.css';

const styles: StylesConfig<TFormikQuantity, true> = {
  multiValueRemove: (base, state) => state.data.isDisabled ? { ...base, display: 'none' } : base,
};

interface IQtiesSelect extends Pick<IPaperAndQuantity, 'formik'>{
  selected: boolean,
  paperId: string,
  onChange: (remainedQties: MultiValue<TFormikQuantity>, actionMeta: ActionMeta<TFormikQuantity>) => void,
}
const QtiesSelect: React.FC<IQtiesSelect> = ({
  formik,
  paperId,
  selected,
  onChange,
}) => {
  const {
    setFieldTouched,
    touched: { productSetup: touched },
    errors: { productSetup: errors },
    values: { productSetup: values }
  } = formik;
  const isAllQtiesUnselected = values.quantities
    .filter(({ paperId: id }) => id === paperId)
    .every(({ isSelected }) => !isSelected);
  const error: string | undefined = isAllQtiesUnselected
    ? getFieldError('quantities', touched, errors)
    : undefined;

  const selectValues = values.quantities.filter(({ paperId: id, isSelected }) => id === paperId && isSelected === selected);
  const onBlur = () => setFieldTouched(getFieldName('quantities'), true);

  return (
    <Select
      name={getFieldName('quantities')}
      isMulti
      styles={styles}
      isClearable={false}
      isCreatable={false}
      isSearchable={false}
      menuIsOpen={false}
      placeholder=""
      components={{
        DropdownIndicator: () => null
      }}
      options={selectValues}
      onChange={onChange}
      onBlur={onBlur}
      value={selectValues}
      getOptionLabel={obj => obj.value as string}
      getOptionValue={obj => obj.qtyId}
      withError={selected}
      error={error}
      className={cls(css.qtySelect, { [css.unselectedQties]: !selected })}
    />
  );
};

export default QtiesSelect;
