import React from 'react';
import { IIconProps } from './types';

function IconStaticProduct(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5 18.5V5.5C18.5 4.39543 17.6046 3.5 16.5 3.5H5.5C4.39543 3.5 3.5 4.39543 3.5 5.5V18.5C3.5
        19.6046 4.39543 20.5 5.5 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5Z"
        stroke="#2C3E66"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 7.5H14.5" stroke="#2C3E66" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11H14.5" stroke="#2C3E66" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 14.6L11.5 14.6" stroke="#2C3E66" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconStaticProduct;
