import React from 'react';
import cls from 'classnames';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import textCss from 'assets/styles/Text.module.css';
import css from './EmptyState.module.css';

export enum EmptyStates {
  NO_RESULTS = 'NO_RESULTS',
  NO_STATISTICS = 'NO_STATISTICS',
  NO_SKU = 'NO_SKU',
  NO_ORDERS = 'NO_ORDERS',
  NO_CUSTOMERS = 'NO_CUSTOMERS',
  NO_MANAGERS = 'NO_MANAGERS',
  SOME_ERROR = 'SOME_ERROR',
  NO_INVOICES = 'NO_INVOICES',
}

interface IEmptyState extends React.HTMLProps<HTMLDivElement> {
  label: string,
  variant: EmptyStates,
  description?: string,
}

const getIconType = (variant: EmptyStates): E_ICON_TYPE => {
  const iconTypes: { [key: string]: E_ICON_TYPE} = {
    [EmptyStates.SOME_ERROR]: E_ICON_TYPE.someError,
    [EmptyStates.NO_RESULTS]: E_ICON_TYPE.noResults,
    [EmptyStates.NO_STATISTICS]: E_ICON_TYPE.noStatistics,
    [EmptyStates.NO_SKU]: E_ICON_TYPE.noSKU,
    [EmptyStates.NO_ORDERS]: E_ICON_TYPE.noOrders,
    [EmptyStates.NO_CUSTOMERS]: E_ICON_TYPE.noCustomers,
    [EmptyStates.NO_MANAGERS]: E_ICON_TYPE.noManagers,
    [EmptyStates.NO_INVOICES]: E_ICON_TYPE.noInvoices,
  };

  return iconTypes[variant];
};

const EmptyState:React.FC<IEmptyState> = ({
  className, label, description, variant, children, ...rest
}) => (
  <div className={cls(css.root, className)} {...rest}>
    <Icon className={css.icon} type={getIconType(variant)} />
    <div className={css.info}>
      <p className={cls(css.label, textCss.h3)}>{label}</p>
      <p className={cls(css.description, textCss.pMedium2)}>{description}</p>
      <div className={css.children}>
        {children}
      </div>
    </div>
  </div>
);

export default EmptyState;
