import React, { useMemo, useRef, useState } from 'react';
import Popover from 'components/library/Popover';
import { Tooltip } from '@mui/material';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';
import css from './TextAlign.module.css';

export enum ETextAlign {
  Left= 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}

const TextAlign:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const icon = useMemo(() => {
    switch (activeObject.textAlign) {
      case ETextAlign.Left:
        return ICON_TYPES.textAlignLeft;
      case ETextAlign.Center:
        return ICON_TYPES.textAlignCenter;
      case ETextAlign.Right:
        return ICON_TYPES.textAlignRight;
      case ETextAlign.Justify:
        return ICON_TYPES.textAlignJustify;
      default:
        return ICON_TYPES.textAlignLeft;
    }
  }, [activeObject.textAlign]);

  const handleChange = (newValue: ETextAlign) => {
    if (activeObject && canvas.current) {
      activeObject.set({ textAlign: newValue });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const value = useMemo(() => activeObject.textAlign, [activeObject.textAlign]);

  return (
    <>
      <div onClick={() => setPopoverState(!popoverState)}>
        <Tooltip placement="top" title={strings.editorTabSettingsTextAlignTooltip} arrow>
          <SidebarButton
            ref={ref}
            isActive={popoverState}
            onClick={() => setPopoverState(!popoverState)}
          >
            <Icon type={icon} />
          </SidebarButton>
        </Tooltip>
      </div>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <div className={css.row}>
          <Tooltip placement="top" title={strings.editorTabSettingsTextAlignLeftTooltip} arrow>
            <SidebarButton
              isActive={value === ETextAlign.Left}
              onClick={() => handleChange(ETextAlign.Left)}
            >
              <Icon type={ICON_TYPES.textAlignLeft} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextAlignCenterTooltip} arrow>
            <SidebarButton
              isActive={value === ETextAlign.Center}
              onClick={() => handleChange(ETextAlign.Center)}
            >
              <Icon type={ICON_TYPES.textAlignCenter} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextAlignRightTooltip} arrow>
            <SidebarButton
              isActive={value === ETextAlign.Right}
              onClick={() => handleChange(ETextAlign.Right)}
            >
              <Icon type={ICON_TYPES.textAlignRight} />
            </SidebarButton>
          </Tooltip>
          <Tooltip placement="top" title={strings.editorTabSettingsTextJustifyTooltip} arrow>
            <SidebarButton
              isActive={value === ETextAlign.Justify}
              onClick={() => handleChange(ETextAlign.Justify)}
            >
              <Icon type={ICON_TYPES.textAlignJustify} />
            </SidebarButton>
          </Tooltip>
        </div>
      </Popover>
    </>
  );
};

export default TextAlign;
