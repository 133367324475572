import React from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/library/Button';

import strings from 'constants/localization';
import { ROUTES } from 'constants/constants';

interface IArticlesAddNewSkuBtn {
  customerId: string,
}
const ArticlesAddNewSkuBtn: React.FC<IArticlesAddNewSkuBtn> = ({ customerId }) => {
  const createArticleRoute = `${ROUTES.ROUTE_ADD}/${ROUTES.ARTICLES_SELECT_PRINT_ITEM}?customerId=${customerId}`;

  return (
    <NavLink to={createArticleRoute}>
      <Button
        type="button"
        buttonType="primary"
      >
        {strings.articlesPageButtonAddNewSku}
      </Button>
    </NavLink>
  );
};

export default ArticlesAddNewSkuBtn;
