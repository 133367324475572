import React, {
  FC, useCallback, useContext, useEffect, useMemo
} from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { SingleValue } from 'react-select';

import Button from 'components/library/Button';
import Label from 'components/library/Label';
import Select from 'components/library/Select';
import Loader from 'components/library/Loader';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Error from 'components/library/Error';

import { useGetEvents, useGetOrganizations } from 'hooks/Integration/useEventbrite';
import { TUser } from 'types';
import { IProfileIntegrationTabFormBind } from 'types/Integration';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import UserContext from 'contexts/ContextUser';
import { useCreateIntegrationEventBind, useDeleteIntegrationEventBind, usePatchIntegrationEventBind } from 'hooks/Integration/useIntegrationEventBind';
import containerCss from '../Tab.module.css';
import EventBindInformation from '../EventBindInformation';

const EVENT = 'event';
const ORGANIZATION = 'organization';

type TSelectValue = {
    label: string,
    value: string,
}

interface IIntegrationConnect {
    customerId: string,
    eventBind: TUser['eventBind']
}

const IntegrationEventBind: FC<IIntegrationConnect> = ({ customerId, eventBind }) => {
  const {
    id: eventBindId,
    eventId: bindedEventId,
    eventName: bindedEventName,
    connection
  } = eventBind || {};

  const { user } = useContext(UserContext);
  const { push } = useContext(AlertContext);

  const integrationToken = useMemo<string | null | undefined>(
    () => user?.integration?.token,
    [user]
  );

  const connectionId = useMemo<string | null | undefined>(
    () => user?.integration?.id,
    [user]
  );

  const { mutateAsync: createEventBind } = useCreateIntegrationEventBind({ customerId, connectionId });
  const { mutateAsync: patchEventBind } = usePatchIntegrationEventBind({ customerId, connectionId });
  const { mutateAsync: deleteEventBind, isLoading: isDeleting } = useDeleteIntegrationEventBind({ customerId, connectionId });

  const {
    data: orgsData,
    isFetching: isFetchingOrgs,
    error: orgsReqError
  } = useGetOrganizations(integrationToken);

  const onBindEvent = async (formData: IProfileIntegrationTabFormBind, options: FormikHelpers<IProfileIntegrationTabFormBind>) => {
    const { event, organization: organizationId } = formData;
    if (event) {
      options.setSubmitting(true);

      const { label: newEventName, value: newEventId } = event;
      const commonSaveData = { eventId: newEventId, eventName: newEventName, organizationId };
      const promise = eventBindId
        ? patchEventBind({ id: eventBindId, ...commonSaveData })
        : createEventBind(commonSaveData);

      await promise
        .then(() => {
          push({ severity: 'success', message: eventBindId ? strings.successEventBindUpdate : strings.successEventBind });
        })
        .catch(err => {
          const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
          push({ severity: 'error', message: errMsg });
        })
        .finally(() => {
          options.setSubmitting(false);
        });
    }
  };

  const {
    values: { organization: chosenOrg, event: chosenEvent },
    getFieldProps,
    isSubmitting,
    setFieldValue,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      [ORGANIZATION]: null,
      [EVENT]: null,
    } as IProfileIntegrationTabFormBind,
    onSubmit: onBindEvent,
  });

  const {
    data: eventsData,
    isFetching: isFetchingEvents,
    error: eventsReqError
  } = useGetEvents(integrationToken, chosenOrg);

  const onDeleteEvent = useCallback(
    () => {
      const { id } = eventBind || {};
      if (id) {
        deleteEventBind(id)
          .then(() => {
            resetForm();
            push({ severity: 'success', message: strings.successEventBindDelete });
          })
          .catch(err => {
            const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
            push({ severity: 'error', message: errMsg });
          });
      }
    },
    [eventBind, deleteEventBind]
  );

  const selectOrgsList = useMemo<TSelectValue[]>(
    () => orgsData?.organizations?.map(({ name, id }) => ({ label: name, value: id })) || [],
    [orgsData]
  );

  const orgSelectValue = useMemo<TSelectValue | null>(
    () => selectOrgsList?.find(({ value: v }) => v === chosenOrg) || null,
    [chosenOrg, selectOrgsList]
  );

  const selectEventsList = useMemo<TSelectValue[]>(
    () => eventsData?.events.map(({ name: { text }, id }) => ({ label: text, value: id })) || [],
    [eventsData]
  );

  const eventSelectValue = useMemo<TSelectValue | null>(
    () => selectEventsList?.find(v => v === chosenEvent) || null,
    [chosenEvent, selectEventsList]
  );

  const isSavedEventChosen = useMemo<boolean>(
    () => bindedEventId === chosenEvent?.value,
    [bindedEventId, chosenEvent]
  );

  const submitBtnDisabled = useMemo<boolean>(
    () => isSubmitting || isFetchingEvents || isDeleting || !chosenEvent || isSavedEventChosen,
    [isSubmitting, isFetchingEvents, chosenEvent, isSavedEventChosen, isDeleting]
  );

  const selectorDisabled = useMemo<boolean>(
    () => isFetchingEvents || isDeleting,
    [isFetchingEvents, isDeleting]
  );

  const onOrgsSelectChange = (value: SingleValue<TSelectValue>) => setFieldValue(ORGANIZATION, value?.value);

  const onEventsSelectChange = (value: SingleValue<TSelectValue>) => setFieldValue(EVENT, value);

  useEffect(() => {
    setFieldValue(EVENT, null);
  }, [chosenOrg]);

  if (!integrationToken) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoIntegrationService}
        description={strings.emptyStateDescriptionSetConnection}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }

  if (orgsReqError || eventsReqError) {
    const errData = orgsReqError?.response?.data || eventsReqError?.response?.data;
    const { status_code: statusCode, error: errorTitle, error_description: errorDescription } = errData || {};
    push({ severity: 'error', message: errorDescription || '' });
    return (
      <Error
        code={statusCode}
        title={errorTitle}
        description={statusCode === 401 ? strings.errorInvalidOrExpiredIntegrationToken : errorDescription}
      />
    );
  }

  return isFetchingOrgs
    ? <Loader />
    : (
      <>
        {bindedEventName && (
          <EventBindInformation
            bindedEventName={bindedEventName}
            connectedService={connection?.service}
          />
        )}
        <form noValidate onSubmit={handleSubmit}>
          <div className={containerCss.selectContainer}>
            <Label text={strings.inputOrganizationLabel} />
            <Select<TSelectValue>
              {...getFieldProps(ORGANIZATION)}
              value={orgSelectValue}
              options={selectOrgsList}
              onChange={onOrgsSelectChange}
              isDisabled={selectorDisabled}
            />
          </div>
          <div className={containerCss.selectContainer}>
            <Label text={strings.inputEventLabel} />
            <Select<TSelectValue>
              {...getFieldProps(EVENT)}
              value={eventSelectValue}
              options={selectEventsList}
              onChange={onEventsSelectChange}
              isDisabled={selectorDisabled}
            />
          </div>
          <div>
            <Button
              type="submit"
              buttonType="primary"
              className={containerCss.submitBtn}
              disabled={submitBtnDisabled}
            >{bindedEventId ? strings.rebind : strings.bindEvent}</Button>
            <Button
              type="button"
              buttonType="tertiary"
              className={containerCss.deleteBtn}
              disabled={!bindedEventId}
              onClick={onDeleteEvent}
            >{strings.actionLabelRemoveEventBinding}</Button>
          </div>
        </form>
      </>
    );
};

export default IntegrationEventBind;
