import React, { FC, useContext, useMemo } from 'react';
import { EIntegrationServices, TUser } from 'types';
import strings from '../../../../constants/localization';
import UserContext from '../../../../contexts/ContextUser';
import EmptyState, { EmptyStates } from '../../../library/EmptyState';
import IntegrationEventBind from './IntegrationEventBind';
import CodeKarusselIntegrationEventBind from './CodeKarusselIntegrationEventBind';
import ManualIntegrationEventBind from './ManualIntegrationEventBind';

interface IIntegrationConnect {
  userName: string,
  customerId: string,
  eventBind: TUser['eventBind']
}

const IntegrationEventBindContainer: FC<IIntegrationConnect> = props => {
  const { user } = useContext(UserContext);

  const integrationService = useMemo<string | null | undefined>(
    () => user?.integration?.service,
    [user]
  );

  if (!integrationService) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoIntegrationService}
        description={strings.emptyStateDescriptionSetConnection}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }

  switch (integrationService) {
    case EIntegrationServices.MANUAL:
      return <ManualIntegrationEventBind {...props} />;
    case EIntegrationServices.API:
    case EIntegrationServices.PAYLOGIC:
    case EIntegrationServices.CODE_KARUSSEL:
      return <CodeKarusselIntegrationEventBind {...props} />;
    default:
      return <IntegrationEventBind {...props} />;
  }
};

export default IntegrationEventBindContainer;
