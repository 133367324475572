import React, { useMemo } from 'react';

import Table from 'components/library/Table';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import strings from 'constants/localization';
import css from './DeliveryData.module.css';
import useDeliveryDataTable from './useDeliveryDataTable';
import { EOrderDataIds, TExcelBindings, TExcelDataTableRow } from '../../types';

const getDataSample = (data: TRow[], columnIndex: number | null, rowIndex: number = 1): string => {
  if (columnIndex === null) {
    return '';
  }

  const cell = data[rowIndex][columnIndex];
  return typeof cell === 'string' ? cell : cell.value;
};

interface IDeliveryData {
  data: TRow[],
  bindings: TExcelBindings,
  setBindings: React.Dispatch<React.SetStateAction<TExcelBindings>>,
}

const DeliveryData: React.FC<IDeliveryData> = ({ data, bindings, setBindings }) => {
  const tableData = useMemo<TExcelDataTableRow[]>(
    () => [
      {
        id: EOrderDataIds.NAME,
        title: strings.editorExcelModalDeliveryDataNameField,
        value: getDataSample(data, bindings[EOrderDataIds.NAME]),
        required: true,
      },
      {
        id: EOrderDataIds.SURNAME,
        title: strings.editorExcelModalDeliveryDataSurnameField,
        value: getDataSample(data, bindings[EOrderDataIds.SURNAME]),
        required: true,
      },
      {
        id: EOrderDataIds.COUNTRY,
        title: strings.editorExcelModalDeliveryDataCountryField,
        value: getDataSample(data, bindings[EOrderDataIds.COUNTRY]),
        required: true,
      },
      {
        id: EOrderDataIds.CITY,
        title: strings.editorExcelModalDeliveryDataCityField,
        value: getDataSample(data, bindings[EOrderDataIds.CITY]),
        required: true,
      },
      {
        id: EOrderDataIds.ZIPCODE,
        title: strings.editorExcelModalDeliveryDataZipcodeField,
        value: getDataSample(data, bindings[EOrderDataIds.ZIPCODE]),
        required: true,
      },
      {
        id: EOrderDataIds.STREET,
        title: strings.editorExcelModalDeliveryDataStreetField,
        value: getDataSample(data, bindings[EOrderDataIds.STREET]),
        required: true,
      },
      {
        id: EOrderDataIds.HOUSE,
        title: strings.editorExcelModalDeliveryDataHouseField,
        value: getDataSample(data, bindings[EOrderDataIds.HOUSE]),
        required: true,
      },
      {
        id: EOrderDataIds.APARTMENT,
        title: strings.editorExcelModalDeliveryDataApartmentField,
        value: getDataSample(data, bindings[EOrderDataIds.APARTMENT]),
      },
      {
        id: EOrderDataIds.PHONE_NUMBER,
        title: strings.editorExcelModalDeliveryDataPhoneField,
        value: getDataSample(data, bindings[EOrderDataIds.PHONE_NUMBER]),
      },
    ],
    [bindings, data, getDataSample]
  );

  const columns = useDeliveryDataTable({ data, bindings, setBindings });

  return (
    <div className={css.modalContainer}>
      <div className={css.description}>{strings.editorExcelModalDeliveryDataTabDescription}</div>
      <div className={css.content}>
        <Table<TExcelDataTableRow> data={tableData} columns={columns} />
      </div>
    </div>
  );
};

export default DeliveryData;
