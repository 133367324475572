import React from 'react';
import MuiPopover, { PopoverProps } from '@mui/material/Popover';
import { PopoverClasses } from '@mui/material/Popover/popoverClasses';
import css from './Popover.module.css';

const classes:Partial<PopoverClasses> = {
  paper: css.paper
};

interface IPopover extends PopoverProps {}
const Popover:React.FC<IPopover> = ({ children, ...rest }) => (
  <MuiPopover
    {...rest}
    classes={classes}
  >
    {children}
  </MuiPopover>
);

export default Popover;
