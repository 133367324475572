import React, {
  createContext, Dispatch, SetStateAction, useCallback, useMemo, useState
} from 'react';
import {
  EProductTypes,
  ETemplateSides,
  TArticle,
  TArticleDocument,
  TOption,
  TOrderItem, TPaper,
  TPaperQuantity,
  TParam,
  TPrintItem,
  TQuantity,
  TSize
} from 'types';
import { TStateOptionalOptions } from './types';

export interface IOrderState {
  frontSide?: TArticleDocument,
  backSide?: TArticleDocument,
  optionsState: TStateOptionalOptions,
  sideCount: 1 | 2,
  currentTab: ETemplateSides,
  isOrderCreation: boolean,
  orderItems: TOrderItem[],
  orderItemsToSave: TOrderItem[],
  params: TParam[],
  quantities: TQuantity[],
  quantity: TQuantity | null,
  paper: TPaper | null,
  papers: TPaperQuantity[],
  size: TSize | null,
  price: number,
  orderComment: string,
  paperComment: string,
  currency: string,
  productType: EProductTypes,
  ticketType: string | null,
  orderId: string,
  skuIdTitle: string,
  options: TOption[],
  printItem: TPrintItem | null,
  selectedOrderItem: TOrderItem | null,
  article?: TArticle,
  orderSheetReady?: boolean,
  linkToFront?: string,
  linkToBack?: string,
  changes: boolean,
  saved: boolean,
  zoom: number,
}

type TSetState = (state:Partial<IOrderState>) => void;

interface IOrderContext {
  setState: TSetState
  setStateRaw: Dispatch<SetStateAction<IOrderState>>
  state: IOrderState
  canvasLoadingStatus: {[key in ETemplateSides]: boolean},
  setCanvasLoadingStatus: Dispatch<SetStateAction<{[key in ETemplateSides]: boolean}>>,
}

export const getInitialState = (defaults?:Partial<IOrderState>):IOrderState => ({
  currency: '€',
  changes: false,
  saved: false,
  optionsState: {},
  sideCount: 1,
  currentTab: ETemplateSides.FRONT,
  isOrderCreation: defaults?.isOrderCreation || false,
  selectedOrderItem: defaults?.orderItems ? defaults?.orderItems[0] : null,
  orderItems: defaults?.orderItems || [],
  orderItemsToSave: defaults?.orderItems || [],
  params: defaults?.params || [],
  quantities: defaults?.quantities || [],
  quantity: defaults?.quantity || null,
  paper: defaults?.paper || null,
  papers: defaults?.papers || [],
  size: defaults?.size || null,
  price: defaults?.price || 0,
  orderComment: defaults?.orderComment || '',
  paperComment: defaults?.paperComment || '',
  productType: defaults?.productType || EProductTypes.STATIC_PRODUCT,
  ticketType: defaults?.ticketType || null,
  orderId: defaults?.orderId || '',
  skuIdTitle: defaults?.skuIdTitle || '',
  options: defaults?.options || [],
  printItem: defaults?.printItem || null,
  article: defaults?.article,
  zoom: 0,
});

const getInitialContextValue = ():IOrderContext => ({
  state: getInitialState(),
  setState: () => {},
  setStateRaw: () => {},
  canvasLoadingStatus: { [ETemplateSides.FRONT]: true, [ETemplateSides.BACK]: true },
  setCanvasLoadingStatus: () => {},
});

const OrderContext = createContext<IOrderContext>(getInitialContextValue());

export default OrderContext;

interface IContextProvider {
  children: React.ReactNode
}
export const OrderContextProvider:React.FC<IContextProvider> = ({ children }) => {
  const [state, setStateRaw] = useState<IOrderState>(getInitialState());
  const [canvasLoadingStatus, setCanvasLoadingStatus] = useState({ [ETemplateSides.FRONT]: true, [ETemplateSides.BACK]: true });
  const setState = useCallback<TSetState>(newState => setStateRaw(prev => ({ ...prev, ...newState })), [setStateRaw]);
  const value = useMemo<IOrderContext>(() => ({
    state, setState, setStateRaw, canvasLoadingStatus, setCanvasLoadingStatus
  }), [state, setState, canvasLoadingStatus]);
  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};
