/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAdd(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 13H13V19C13 19.55 12.55 20 12 20C11.45 20 11 19.55 11 19V13H5C4.45 13 4 12.55 4 12C4 11.45 4.45 11 5 11H11V5C11 4.45 11.45 4 12 4C12.55 4 13 4.45 13 5V11H19C19.55 11 20 11.45 20 12C20 12.55 19.55 13 19 13Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAdd;
