import React, { useContext, useEffect, useMemo } from 'react';
import {
  URLSearchParamsInit, useLocation, useNavigate, useSearchParams
} from 'react-router-dom';

import { ETablePageViews } from 'types';
import { getSearchParamsObject, isCustomer } from 'helpers';
import UserContext from 'contexts/ContextUser';
import ArticleListViewContext from 'contexts/ContextArticleListView';
import get from 'lodash/get';
import PageArticles, { IStateParams } from './PageArticles';
import { getArticleListView, TGetListView } from './helpers';
import AlertContext from '../../contexts/ContextAlert';

const PageArticlesContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const alertContext = useContext(AlertContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const userContext = useContext(UserContext);
  const user = get(userContext, 'user');
  const isUserCustomer = isCustomer(get(user, 'userPermissions', []));

  const articleListViewContext = useContext(ArticleListViewContext);
  const { view: contextView, setView: setContextView } = articleListViewContext;

  const setListViewQuery = (newView: ETablePageViews): void => {
    const newSearchParams: URLSearchParamsInit = { ...getSearchParamsObject(searchParams), view: newView };
    setSearchParams(newSearchParams, { replace: true });
  };

  useEffect(() => {
    const { view, isFromUrl }: TGetListView = getArticleListView(searchParams, contextView);
    if (view !== contextView) {
      setContextView(view);
    }
    if (!isFromUrl) {
      setListViewQuery(view);
    }
  }, [searchParams.get('view')]);

  const params = useMemo<IStateParams>(() => ({
    customerId: (isUserCustomer ? get(user, 'id') : searchParams.get('customerId')) || '',
    status: isUserCustomer ? ['status', 1] : get(location.state, 'status'),
    page: parseInt(get(location.state, 'page') || searchParams.get('page') || '', 10),
    search: get(location.state, 'search', ''),
    order: get(location.state, 'order'),
  }), [location, searchParams, isUserCustomer]);

  return useMemo(
    () => (
      <PageArticles
        state={params}
        navigate={navigate}
        location={location}
        user={user}
        setListViewQuery={setListViewQuery}
        alertContext={alertContext}
        articleListViewContext={articleListViewContext}
      />
    ),
    [params, navigate, location, user, contextView, alertContext, articleListViewContext]
  );
};

export default PageArticlesContainer;
