/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconOpenEye(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0002 12.7031C10.0617 12.7031 8.48462 14.2802 8.48462 16.2187C8.48462 18.1573 10.0617 19.7344 12.0002 19.7344C13.9386 19.7344 15.5157 18.1573 15.5157 16.2187C15.5157 14.2802 13.9386 12.7031 12.0002 12.7031ZM12.0002 18.1719C10.9232 18.1719 10.0471 17.2957 10.0471 16.2187C10.0471 15.1418 10.9232 14.2656 12.0002 14.2656C13.0771 14.2656 13.9533 15.1418 13.9533 16.2187C13.9533 17.2957 13.0771 18.1719 12.0002 18.1719Z" fill="#2C3E66" />
      <path d="M21.9159 14.3682C21.0543 12.6616 19.6888 11.2158 17.9662 10.1853L20.081 8.07058C20.386 7.7655 20.386 7.27085 20.081 6.96574C19.7759 6.66066 19.2813 6.66066 18.9761 6.96574L16.4952 9.44671C15.3259 8.96277 14.0709 8.67167 12.7812 8.58788V5.04687C12.7812 4.61539 12.4315 4.26562 12 4.26562C11.5685 4.26562 11.2187 4.61539 11.2187 5.04687V8.58784C9.92907 8.67163 8.674 8.96273 7.5047 9.44667L5.02381 6.9657C4.71869 6.66058 4.224 6.66058 3.91892 6.9657C3.61389 7.27078 3.61389 7.76542 3.91896 8.07054L6.03369 10.1853C4.31111 11.2158 2.94564 12.6615 2.08401 14.3681C1.88955 14.7533 2.04416 15.2232 2.42932 15.4177C2.81436 15.6122 3.28439 15.4576 3.47881 15.0724C4.92795 12.2022 8.15739 10.125 12 10.125C15.8455 10.125 19.0733 12.2047 20.5212 15.0724C20.716 15.4583 21.1861 15.6119 21.5707 15.4177C21.9559 15.2232 22.1105 14.7533 21.9159 14.3682Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconOpenEye;
