import React, { useMemo } from 'react';
import { EOrderStatus } from 'types';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import { Row } from '@tanstack/react-table';
import { chain } from 'lodash';
import css from './GroupStatusColumn.module.css';
import { TOrderTableItem } from '../../Orders';
import { STATUS_TO_STRINGS } from '../../../../constants/constants';

export interface IStatusColumn {
  rows: Row<TOrderTableItem>[],
}
const StatusColumn = ({ rows }: IStatusColumn) => {
  const status = useMemo<EOrderStatus>(() => {
    const position = Object.values(EOrderStatus);
    const value = chain(rows)
      .map('original.status')
      .uniq()
      .map(val => position.indexOf(val))
      .min()
      .value();
    return position[value];
  }, [rows]);

  const classes = useMemo(() => cls(
    textCss.pMedium3,
    css.container,
    css.pill,
    {
      [css.created]: status === EOrderStatus.CREATED,
      [css.received]: status === EOrderStatus.RECEIVED,
      [css.sendToPrinter]: status === EOrderStatus.SEND_TO_PRINTER,
      [css.inPrinting]: status === EOrderStatus.IN_PRINTING,
      [css.inDelivery]: status === EOrderStatus.IN_DELIVERY,
      [css.delivered]: status === EOrderStatus.ASSUMED_DELIVERED,
      [css.issue]: status === EOrderStatus.ISSUE,
      [css.archived]: status === EOrderStatus.ARCHIVED,
    }
  ), [status]);

  return (<div className={classes}>{STATUS_TO_STRINGS[status]()}</div>);
};

export default StatusColumn;
