import { FabricObject } from '../useFabricObjects';
import fabric from '../../../helpers/fabric';

export default async (object: FabricObject['object'], data: any): Promise<FabricObject['object']> => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id, type, text, ...rest
  } = data;

  if (typeof text !== 'undefined' || text !== null) {
    setText(object, text);
  }
  if (Object.values(rest).length) {
    applyForceRest(object, rest);
  }

  return new Promise(resolve => {
    resolve(object);
  });
};

const setText = (object: FabricObject['object'], text: string) => {
  const newText = lineBreak(object, text);
  object.set({ text: newText });
};

const applyForceRest = (object: FabricObject['object'], rest: any) => {
  object.set(rest);
  object.setCoords();
};

const lineBreak = (object: FabricObject['object'], text: string) => {
  const initCountLines = object._splitText().lines.length;
  const newTextbox = new fabric.TextObject(text, { ...object, splitByGrapheme: true });
  newTextbox.set({ text });
  const { textLines } = newTextbox;
  let newText = textLines;
  if (textLines.length > initCountLines) {
    newText = textLines.slice(0, initCountLines);
  }
  return newText.join('\n');
};
