import React from 'react';
import MuiTooltip, { TooltipProps, TooltipClasses } from '@mui/material/Tooltip';

import { PartialBy } from 'types';
import css from './Tooltip.module.css';

const classes:Partial<TooltipClasses> = {
  popper: css.popover,
  tooltip: css.tooltip,
  arrow: css.arrow,
};

interface ITooltip extends PartialBy<TooltipProps, 'title'> {

}
const Tooltip:React.FC<ITooltip> = props => {
  const {
    className, children, arrow = true, title, ...rest
  } = props;
  const internalContent: JSX.Element = <div className={className}>{children}</div>;
  return title
    ? (
      <MuiTooltip title={title} arrow={arrow} {...rest} classes={classes}>
        {internalContent}
      </MuiTooltip>
    )
    : internalContent;
};

export default Tooltip;
