/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextSpacing(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M7.28997 7.0002C7.73997 7.0002 7.95997 6.4602 7.63997 6.1502L5.34997 3.8502C5.14997 3.6502 4.83997 3.6502 4.63997 3.8502L2.34997 6.1502C2.28087 6.22069 2.23418 6.31006 2.21579 6.40705C2.19739 6.50404 2.20812 6.6043 2.24662 6.6952C2.28512 6.7861 2.34967 6.86357 2.43212 6.91785C2.51458 6.97212 2.61125 7.00078 2.70997 7.0002H3.99997V17.0002H2.70997C2.25997 17.0002 2.03997 17.5402 2.35997 17.8502L4.64997 20.1402C4.84997 20.3402 5.15997 20.3402 5.35997 20.1402L7.64997 17.8502C7.71907 17.7797 7.76576 17.6903 7.78415 17.5933C7.80254 17.4964 7.79181 17.3961 7.75331 17.3052C7.71482 17.2143 7.65027 17.1368 7.56782 17.0825C7.48536 17.0283 7.38868 16.9996 7.28997 17.0002H5.99997V7.0002H7.28997ZM11 7.0002H21C21.55 7.0002 22 6.5502 22 6.0002C22 5.4502 21.55 5.0002 21 5.0002H11C10.45 5.0002 9.99997 5.4502 9.99997 6.0002C9.99997 6.5502 10.45 7.0002 11 7.0002ZM21 17.0002H11C10.45 17.0002 9.99997 17.4502 9.99997 18.0002C9.99997 18.5502 10.45 19.0002 11 19.0002H21C21.55 19.0002 22 18.5502 22 18.0002C22 17.4502 21.55 17.0002 21 17.0002ZM21 11.0002H11C10.45 11.0002 9.99997 11.4502 9.99997 12.0002C9.99997 12.5502 10.45 13.0002 11 13.0002H21C21.55 13.0002 22 12.5502 22 12.0002C22 11.4502 21.55 11.0002 21 11.0002Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextSpacing;
