/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignCenterV(props: IIconProps) {
  return (
    <svg {...props} width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0037 8H16.0037V4.5C16.0037 3.67 15.3337 3 14.5037 3C13.6737 3 13.0037 3.67 13.0037 4.5V8H9.00375V1.5C9.00375 0.67 8.33375 0 7.50375 0C6.67375 0 6.00375 0.67 6.00375 1.5V8H1.84375C1.29375 8 0.84375 8.45 0.84375 9C0.84375 9.55 1.29375 10 1.84375 10H6.00375V16.5C6.00375 17.33 6.67375 18 7.50375 18C8.33375 18 9.00375 17.33 9.00375 16.5V10H13.0037V13.5C13.0037 14.33 13.6737 15 14.5037 15C15.3337 15 16.0037 14.33 16.0037 13.5V10H20.0037C20.5537 10 21.0037 9.55 21.0037 9C21.0037 8.45 20.5537 8 20.0037 8Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignCenterV;
