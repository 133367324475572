import React, { useMemo } from 'react';
import { IEventbriteErrorRes } from 'types/Integration';
import strings from 'constants/localization';
import { IAlertContext } from 'contexts/ContextAlert';
import Error from 'components/library/Error';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';

interface IError {
  error: IEventbriteErrorRes
  push: IAlertContext['push']
}

export const DisplayError:React.FC<IError> = React.memo(props => {
  const { error, push } = props;

  const {
    status_code: code,
    error: title,
    error_description: message,
  } = error.response.data;

  const description = useMemo(() => code === 401 ? strings.errorInvalidOrExpiredIntegrationToken : message, [code, message]);

  push({ severity: 'error', message });
  return (<Error code={code} title={title} description={description} />);
});

export const DisplayEmptyState:React.FC = React.memo(() => (
  <EmptyState
    label={strings.emptyStateLabelNoIntegrationService}
    description={strings.emptyStateDescriptionSetConnection}
    variant={EmptyStates.NO_RESULTS}
  />
));

export default { DisplayError, DisplayEmptyState };
