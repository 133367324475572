/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconCorrect(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3 6.3C17.2075 6.2073 17.0976 6.13375 16.9766 6.08357C16.8557 6.03339 16.726 6.00756 16.595 6.00756C16.464 6.00756 16.3344 6.03339 16.2134 6.08357C16.0924 6.13375 15.9825 6.2073 15.89 6.3L10.25 11.94L11.66 13.35L17.3 7.7C17.68 7.32 17.68 6.68 17.3 6.3ZM21.54 6.29L11.66 16.17L8.18002 12.7C7.99304 12.513 7.73944 12.408 7.47501 12.408C7.21059 12.408 6.95699 12.513 6.77001 12.7C6.58304 12.887 6.47799 13.1406 6.47799 13.405C6.47799 13.6694 6.58304 13.923 6.77001 14.11L10.95 18.29C11.34 18.68 11.97 18.68 12.36 18.29L22.95 7.71C23.0427 7.61749 23.1163 7.5076 23.1664 7.38662C23.2166 7.26565 23.2425 7.13597 23.2425 7.005C23.2425 6.87403 23.2166 6.74435 23.1664 6.62337C23.1163 6.5024 23.0427 6.39251 22.95 6.3H22.94C22.8498 6.2056 22.7415 6.13029 22.6216 6.07853C22.5018 6.02677 22.3727 5.99961 22.2421 5.99868C22.1116 5.99775 21.9821 6.02305 21.8615 6.0731C21.7409 6.12314 21.6316 6.1969 21.54 6.29ZM1.12001 14.12L5.30001 18.3C5.69001 18.69 6.32001 18.69 6.71001 18.3L7.41001 17.6L2.53001 12.7C2.4375 12.6073 2.32761 12.5337 2.20664 12.4836C2.08567 12.4334 1.95598 12.4076 1.82501 12.4076C1.69405 12.4076 1.56436 12.4334 1.44339 12.4836C1.32242 12.5337 1.21253 12.6073 1.12001 12.7C0.730015 13.09 0.730015 13.73 1.12001 14.12Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconCorrect;
