import React, {
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import set from 'lodash/set';
import { FormikHelpers, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import PageContent from 'components/library/PageContent';
import strings from 'constants/localization';
import { useUserCreate, useUserUpdate } from 'hooks/useUser';
import Tabs, { TOnChange } from 'components/library/Tabs';
import { ERoles, TUser } from 'types';
import AlertContext from 'contexts/ContextAlert';
import SidebarContent from './components/SidebarContent';
import TabPersonalInfo from './components/TabPersonalInfo';
import TabCompanyInfo from './components/TabCompanyInfo';
import TabPermissions from './components/TabPermissions';
import css from './Profile.module.css';
import {
  getActionMetaForRole, getCompanyInfoDisabled, getField, getInitialValues, getPageTitle, getTabIndexByKey, getTabs, prepareFormDataToSave,
} from './helpers';
import {
  ETabs, getValidationSchema, IFormsValues, TProfileLocationState,
} from './constants';
import { checkPermission, EActions } from './permission';
import { IResponseError } from '../../constants/types';
import { getUserProfileLink } from '../../helpers';
import FooterContent from './components/FooterContent/FooterContent';
import UserRegisteredModal, { TUserRegistered } from './components/UserRegisteredModal';
import UnsavedChangesModal from '../Modals/UnsavedChangesModal';
import TabIntegration from './components/TabIntegration';

export interface IProfile {
  create?: boolean,
  tabKey?: ETabs,
  currentUser: TUser,
  targetUser: TUser,
  targetRole: ERoles,
  isSelf: boolean,
}
const Profile:React.FC<IProfile> = ({
  create, tabKey, currentUser, targetUser, isSelf, targetRole
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const alertContext = useContext(AlertContext);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [userCreatedModal, setUserCreatedModal] = useState<boolean>(false);
  const pageTitle = getPageTitle(targetRole, isSelf);
  const tabs = getTabs(currentUser.userPermissions, targetRole, isSelf, !!targetUser.id);
  const onChangeTab = useCallback<TOnChange>((e, index) => setTabIndex(index), [setTabIndex]);
  useEffect(() => {
    if (tabs.length && tabKey) {
      setTabIndex(getTabIndexByKey(tabKey, tabs));
    }
  }, [tabs, tabKey]);
  useEffect(() => {
    if (location.state) {
      const state = location.state as TProfileLocationState;
      if (state.isUserCreated) {
        setUserCreatedModal(true);
      }
    }
  }, [location.state]);
  const updateUser = useUserUpdate();
  const createUser = useUserCreate();
  const handleSubmit:(values: IFormsValues, formikHelpers: FormikHelpers<IFormsValues>) => (void | Promise<any>) = (forms, formikHelpers) => {
    formikHelpers.setSubmitting(true);
    const user = prepareFormDataToSave({ forms, targetUser });
    let promise: Promise<TUser>;
    if (user.id) {
      promise = updateUser.mutateAsync(user);
    } else {
      promise = createUser.mutateAsync(user);
    }
    promise
      .then(data => {
        if (isSelf && strings.getLanguage() !== data.lang) {
          strings.setLanguage(data.lang);
        }
        if (user.id) {
          alertContext.push({
            message: create ? strings.profileNotifySuccessCreate : strings.profileNotifySuccessUpdate,
            severity: 'success',
          });
        } else {
          const state: TProfileLocationState = { isUserCreated: true };
          navigate(getUserProfileLink(targetRole, data.id), { state });
        }
      })
      .catch((err:IResponseError) => {
        console.log(err);
        alertContext.push({
          message: err.response?.data.data?.reason || strings.errorResponse5,
          severity: 'error'
        });
        const errorsInFields = {};
        err.response?.data.data?.fields?.forEach(field => {
          const key = getField(field.field);
          if (key) {
            set(errorsInFields, key, field.message);
          }
        });
        formikHelpers.setErrors(errorsInFields);
        if (formik.errors.COMPANY_INFO_FORM) {
          setTabIndex(getTabIndexByKey(ETabs.COMPANY_INFO, tabs));
        }
        if (formik.errors.PERSONAL_INFO_FORM) {
          setTabIndex(getTabIndexByKey(ETabs.PERSONAL_INFO, tabs));
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const allowEditPermissions = useMemo(
    () => checkPermission(EActions.EDIT_MANAGER_PERMISSION, currentUser.userPermissions, targetRole, isSelf),
    [currentUser, targetRole, isSelf]
  );
  const allowEditMeta = useMemo(() => checkPermission(
    getActionMetaForRole(targetRole),
    currentUser.userPermissions,
    targetRole,
    isSelf
  ), [currentUser, targetRole]);
  const companyInfoDisabled = getCompanyInfoDisabled(targetUser.userPermissions);

  const formik = useFormik<IFormsValues>({
    validationSchema: getValidationSchema(targetRole, isSelf),
    initialValues: getInitialValues(targetUser),
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const onClickSave = () => {
    if (formik.errors.COMPANY_INFO_FORM) {
      setTabIndex(getTabIndexByKey(ETabs.COMPANY_INFO, tabs));
    }
    if (formik.errors.PERSONAL_INFO_FORM) {
      setTabIndex(getTabIndexByKey(ETabs.PERSONAL_INFO, tabs));
    }
    formik.handleSubmit();
  };

  const isSaveDisabled = useMemo(() => !formik.dirty && !create, [formik.dirty, create]);

  const permissionsTabAdded = useMemo<boolean>(
    () => !!tabs.find(tab => tab.value === ETabs.PERMISSIONS),
    [tabs, tabIndex]
  );
  const drawIntegrationTab = useMemo<boolean>(
    () => {
      let tabIdxToCheck = 2;
      if (permissionsTabAdded) {
        tabIdxToCheck = 3;
      }
      return !!tabs.find(tab => tab.value === ETabs.INTEGRATION) && tabIdxToCheck === tabIndex;
    },
    [tabs, permissionsTabAdded, tabIndex]
  );

  return (
    <PageContent
      pageTitle={pageTitle}
      withBreadcrumbs
      sidebarContent={(
        <SidebarContent
          formik={formik}
          allowEditMeta={allowEditMeta && !create}
          editable
          isSelf={isSelf}
          user={targetUser}
        />
      )}
      footerContent={(
        <FooterContent
          onClickSave={onClickSave}
          disableSave={isSaveDisabled}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          create={create}
        />
      )}
    >
      <div className={css.container}>
        <Tabs
          orientation="vertical"
          tabs={tabs}
          value={tabIndex}
          onChange={onChangeTab}
        />
        <div className={css.tab}>
          {tabIndex === 0 ? (
            <TabPersonalInfo
              currentUser={currentUser}
              formik={formik}
              readOnly={false}
              isSelf={isSelf}
            />
          ) : null}
          {tabIndex === 1 ? (
            <TabCompanyInfo
              formik={formik}
              readOnly={false}
              disabled={companyInfoDisabled}
            />
          ) : null}
          {permissionsTabAdded && tabIndex === 2 ? <TabPermissions formik={formik} disabled={!allowEditPermissions} /> : null}
          {drawIntegrationTab
            ? (
              <TabIntegration
                isSelf={isSelf}
                currentUserRole={currentUser.role}
                targetUserId={targetUser.id}
                targetName={targetUser.name}
                targetRole={targetRole}
                integrationService={targetUser.integration?.service}
                eventBind={targetUser.eventBind}
              />
            )
            : null}
        </div>
      </div>
      {targetRole !== ERoles.ADMIN && (
        <UserRegisteredModal
          isOpen={userCreatedModal}
          setIsOpen={setUserCreatedModal}
          targetRole={targetRole as TUserRegistered}
        />
      )}
      <UnsavedChangesModal areChangesExist={!formik.isSubmitting && formik.dirty} />
    </PageContent>
  );
};

export default Profile;
