import React from 'react';
import cls from 'classnames';
import Label from 'components/library/Label';
import textCss from 'assets/styles/Text.module.css';
import { IFormItem } from '../../types';
import css from './FormItem.module.css';

const FormItem:React.FC<IFormItem> = ({
  currency, label, value, description, children,
}) => (
  <div className={css.formItem}>
    <div className={css.formRow}>
      <Label text={label} />
      {children || <span className={cls(currency ? textCss.h2 : textCss.pMedium2)}>{value}{currency || ''}</span>}
    </div>
    {description && (
      <div className={css.formRow}>
        <div />
        <span className={cls(textCss.pMedium3, css.formItemDesc)}>{description}</span>
      </div>
    )}
  </div>
);

export default FormItem;
