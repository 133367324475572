import React from 'react';
import strings from 'constants/localization';
import css from './ActiveSelectionView.module.css';
import ObjectAlign from './components/ObjectAlign';
import ObjectRotate from './components/ObjectRotate';

const ActiveSelectionView:React.FC = () => (
  <div className={css.container}>
    <div className={css.name}>
      { strings.editorActiveSelectionTitle }
    </div>
    <ObjectAlign />
    <ObjectRotate />
  </div>
);

export default ActiveSelectionView;
