/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFilter({ passedRef, ...props }: IIconProps) {
  return (
    <svg ref={passedRef} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.175 7.84998H1.92505C1.62668 7.84998 1.34053 7.73145 1.12955 7.52047C0.918575 7.30949 0.800049 7.02334 0.800049 6.72498C0.800049 6.42661 0.918575 6.14046 1.12955 5.92948C1.34053 5.7185 1.62668 5.59998 1.92505 5.59998H22.175C22.4734 5.59998 22.7596 5.7185 22.9705 5.92948C23.1815 6.14046 23.3 6.42661 23.3 6.72498C23.3 7.02334 23.1815 7.30949 22.9705 7.52047C22.7596 7.73145 22.4734 7.84998 22.175 7.84998Z" fill="#BCDEF0" />
      <path d="M18.425 13.1H5.67505C5.37668 13.1 5.09053 12.9814 4.87955 12.7705C4.66858 12.5595 4.55005 12.2733 4.55005 11.975C4.55005 11.6766 4.66858 11.3905 4.87955 11.1795C5.09053 10.9685 5.37668 10.85 5.67505 10.85H18.425C18.7234 10.85 19.0096 10.9685 19.2205 11.1795C19.4315 11.3905 19.55 11.6766 19.55 11.975C19.55 12.2733 19.4315 12.5595 19.2205 12.7705C19.0096 12.9814 18.7234 13.1 18.425 13.1Z" fill="#BCDEF0" />
      <path d="M13.925 18.35H10.175C9.87668 18.35 9.59053 18.2314 9.37955 18.0205C9.16858 17.8095 9.05005 17.5233 9.05005 17.225C9.05005 16.9266 9.16858 16.6405 9.37955 16.4295C9.59053 16.2185 9.87668 16.1 10.175 16.1H13.925C14.2234 16.1 14.5096 16.2185 14.7205 16.4295C14.9315 16.6405 15.05 16.9266 15.05 17.225C15.05 17.5233 14.9315 17.8095 14.7205 18.0205C14.5096 18.2314 14.2234 18.35 13.925 18.35Z" fill="#BCDEF0" />
    </svg>
  );
}

export default IconFilter;
