import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Icon, { ICON_TYPES } from 'components/library/Icon';
import CustomersTableCellSlider from 'components/PageCustomers/components/CustomersTableCellSlider';

import { ICustomersTableCell } from 'types/Customers';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import css from '../PageCustomers.module.css';
import { EArticleStatuses, TStat } from '../../../types';

const SkusCell: React.FC<ICustomersTableCell> = ({ customerItem }) => {
  const { id } = customerItem;
  const createArticleRoute = `/${ROUTES.ARTICLES}/${ROUTES.ROUTE_ADD}/${ROUTES.ARTICLES_SELECT_PRINT_ITEM}?customerId=${id}`;
  const viewAllArticlesRoute = `${ROUTES.BASE}${ROUTES.ARTICLES}?customerId=${id}`;
  const articlesCount = useMemo<TStat<EArticleStatuses>[]>(
    () => customerItem.articleStats || [],
    [id, customerItem.articleStats]
  );
  const allArticlesCount = articlesCount.reduce((acc, el) => acc + el.count, 0);
  return (
    <CustomersTableCellSlider
      link={`/${ROUTES.ARTICLES}?customerId=${id}`}
      sliderItemClass={css.sliderItem}
      items={articlesCount}
    >
      <div className={css.sliderItem}>
        <Link to={viewAllArticlesRoute}>
          <div>{allArticlesCount}</div>
          <div className={cls(textCss.pLight3, css.status)}>{strings.viewAll}</div>
        </Link>
      </div>
      <div className={css.sliderItem}>
        <Link to={createArticleRoute}>
          <Icon type={ICON_TYPES.add} />
          <div className={cls(textCss.pLight3, css.status)}>{strings.customersPageSKUCellAddNewLabel}</div>
        </Link>
      </div>
    </CustomersTableCellSlider>
  );
};

export default SkusCell;
