import React, {
  useEffect, useMemo, useState, useCallback
} from 'react';
import strings from 'constants/localization';
import Modal from 'components/library/Modal';
import { TRow } from 'components/PageOrders/components/OrderEditor/types';
import Loader from 'components/library/Loader';
import * as Yup from 'yup';
import { TExcelMatchings } from 'types';
import FileList from './components/FileList';
import OrderData from './components/OrderData';
import css from './ExcelModal.module.css';
import DeliveryData from './components/DeliveryData';
import { EOrderDataIds, ETabs, TExcelBindings } from './types';
import TicketType from './components/TicketType';
import { ITicketType } from '../TicketTypeSelect';
import { bindingsDeliveryInit, bindingsInit, bindingsOrderInit } from './constants';
import { getCellValue, getModalTitle } from './helpers';
import ModalControls from './components/ModalControls';

interface IExcelModal {
  file: File,
  data: TRow[],
  onClose: () => void,
  matchingsTicket: ITicketType | null,
  chosenTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
  setExcelMatchings: React.Dispatch<React.SetStateAction<TExcelMatchings | null>>
}

const ExcelModal: React.FC<IExcelModal> = props => {
  const {
    file,
    data,
    onClose,
    chosenTicket,
    setChosenTicket,
    setExcelMatchings,
  } = props;

  const [tab, setTab] = useState<ETabs>(ETabs.FILE_LIST);
  const [isOpen, setIsOpen] = useState<boolean>(data.length > 0);

  const [bindings, setBindings] = useState<TExcelBindings>(bindingsInit);

  const [ticketType, setTicketType] = useState<ITicketType | null>(chosenTicket);

  useEffect(() => setIsOpen(data.length > 0), [data.length]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const content = useMemo(() => {
    switch (tab) {
      case ETabs.FILE_LIST:
        return <FileList removeFile={handleClose} file={file} fileRows={data.length} />;
      case ETabs.ORDER_DATA:
        return <OrderData data={data} bindings={bindings} setBindings={setBindings} />;
      case ETabs.DELIVERY_DATA:
        return <DeliveryData data={data} bindings={bindings} setBindings={setBindings} />;
      case ETabs.TICKET_TYPE:
        return <TicketType bindings={bindings} {...props} chosenTicket={ticketType} setChosenTicket={setTicketType} />;
      default:
        return <Loader />;
    }
  }, [tab, handleClose, file, data]);

  const isNextDisabled = useMemo<boolean>(() => {
    if (!isOpen) {
      return false;
    }

    if (tab === ETabs.FILE_LIST) {
      return false;
    }

    if (tab === ETabs.ORDER_DATA) {
      return !Yup.object({
        [EOrderDataIds.ORDER_ID]: Yup.string().min(1).required(),
        [EOrderDataIds.TICKET_TYPE_ID]: Yup.string().min(1).required(),
      }).isValidSync(bindings);
    }

    if (tab === ETabs.DELIVERY_DATA) {
      return !Yup.object({
        [EOrderDataIds.NAME]: Yup.string().min(1).required(),
        [EOrderDataIds.SURNAME]: Yup.string().min(1).required(),
        [EOrderDataIds.COUNTRY]: Yup.string().min(1).required(),
        [EOrderDataIds.ZIPCODE]: Yup.string().min(1).required(),
        [EOrderDataIds.STREET]: Yup.string().min(1).required(),
        [EOrderDataIds.HOUSE]: Yup.string().min(1).required(),
      }).isValidSync(bindings);
    }

    if (tab === ETabs.TICKET_TYPE) {
      return ticketType === null;
    }

    return true;
  }, [isOpen, tab, bindings, ticketType]);

  const handleBack = () => {
    if (tab === ETabs.FILE_LIST) {
      return handleClose();
    }

    if (tab === ETabs.ORDER_DATA) {
      setBindings(prevState => ({ ...prevState, ...bindingsOrderInit }));
    }

    if (tab === ETabs.DELIVERY_DATA) {
      setBindings(prevState => ({ ...prevState, ...bindingsDeliveryInit }));
    }

    if (tab === ETabs.TICKET_TYPE) {
      setTicketType(null);
    }

    setTab(tab - 1);
  };

  const handleNext = () => {
    if (tab === ETabs.FILE_LIST) {
      return setTab(ETabs.ORDER_DATA);
    }

    if (tab === ETabs.ORDER_DATA) {
      return setTab(ETabs.DELIVERY_DATA);
    }

    if (tab === ETabs.DELIVERY_DATA) {
      return setTab(ETabs.TICKET_TYPE);
    }

    if (tab === ETabs.TICKET_TYPE) {
      setIsOpen(false);
      setChosenTicket(ticketType);
      setExcelMatchings({
        bindings,
        columns: data[0].map((cell, index) => ({ index, label: getCellValue(cell) })),
      });
    }
  };

  const title = useCallback(getModalTitle, [tab]);

  return (
    <Modal
      isOpen={isOpen}
      title={title(tab)}
      cancelLabel={strings.actionLabelBack}
      okLabel={strings.actionLabelNext}
      okButtonProps={{ disabled: isNextDisabled }}
      onOk={handleNext}
      onCancel={handleBack}
      onRequestClose={handleClose}
      customControls={(
        <ModalControls
          onBack={handleBack}
          disableBack={false}
          onNext={handleNext}
          disableNext={isNextDisabled}
          tab={tab}
        />
      )}
    >
      <div className={css.container}>
        { content }
      </div>
    </Modal>
  );
};

export default ExcelModal;
