/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconUpload(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83451 15C4.79475 15 3.79756 14.6049 3.06234 13.9017C2.3271 13.1984 1.91406 12.2446 1.91406 11.25C1.91406 10.2554 2.3271 9.30159 3.06234 8.59834C3.79756 7.89509 4.79475 7.5 5.83451 7.5C6.08009 6.40599 6.79849 5.44457 7.83167 4.82726C8.34323 4.5216 8.91673 4.30962 9.51931 4.20343C10.1219 4.09723 10.7419 4.0989 11.3438 4.20834C11.9456 4.31777 12.5177 4.53283 13.0272 4.84124C13.5368 5.14964 13.9739 5.54535 14.3135 6.00578C14.6531 6.46619 14.8886 6.98232 15.0066 7.52466C15.1246 8.06701 15.1228 8.625 15.0011 9.16667H15.8345C16.6081 9.16667 17.3499 9.47392 17.8969 10.0209C18.4439 10.5679 18.7511 11.3098 18.7511 12.0833C18.7511 12.8569 18.4439 13.5988 17.8969 14.1458C17.3499 14.6927 16.6081 15 15.8345 15H15.0011" stroke="#BCDEF0" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 12.5L10 10L12.5 12.5" stroke="#BCDEF0" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10V17.5" stroke="#BCDEF0" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconUpload;
