import { IDataURLOptions } from 'fabric/fabric-impl';
import fabric from './fabric';

const THUMBNAIL_OPTIONS: IDataURLOptions = {
  format: 'jpeg',
  quality: 1,
};

const THUMBNAIL_DIMENSIONS = {
  width: 450,
  height: 300,
};

export const fabricJsonToThumbnail = async (canvasJson: any, pdfDocument: any): Promise<string> => {
  const { width, height } = pdfDocument;
  const canvas = document.createElement('canvas');
  const fabricCanvas = new fabric.Canvas(canvas);
  await new Promise(resolve => {
    fabricCanvas.loadFromJSON(canvasJson, resolve);
  });
  fabricCanvas.setDimensions({ ...pdfDocument });
  fabricCanvas.renderAll.bind(fabricCanvas);

  const scale = fabric.util.findScaleToFit(pdfDocument, THUMBNAIL_DIMENSIONS);
  fabricCanvas.setZoom(scale);

  return fabricCanvas.toDataURL({
    width: width * scale,
    height: height * scale,
    left: 0,
    top: 0,
    ...THUMBNAIL_OPTIONS
  });
};

export default { fabricJsonToThumbnail };
