import { Dispatch, FocusEvent, SetStateAction } from 'react';
import { FormikHandlers, FormikHelpers, FormikProps } from 'formik';

import {
  IPaginationParams,
  EPrintItemSizeStringFields,
  EPrintItemSizeNumberFields,
  TPrintItemForm,
  EPrintItemNestedFields,
  EPrintItemCommentFields,
  TPrintItemPricing,
  TLocalizedConstString,
  TPrintItemsItem,
} from 'types';

export enum EPrintItemTabs {
  ITEM_INFORMATION = 'itemInformation',
  OPTIONS = 'options',
  PRICINGS = 'pricings',
  OPTIONAL_PRICINGS = 'optionalPricings',
}

export type TPrintItemFormTablesInput = {
  inputName: string,
  inputValue: string,
  inputError: string | undefined,
  inputOrder?: number,
  disabled: boolean,
}

export type TOptionalPricingTableRow = {
  id: string,
  optionLabel: string,
  optionValue: string,
  subRows?: TOptionalPricingTableRow[],
  inputData: TPrintItemFormTablesInput[],
}

export type TPrintItemNestedFieldsMapper = {
  field: EPrintItemNestedFields,
  commentField: EPrintItemCommentFields,
  label: TLocalizedConstString,
  placeholder: TLocalizedConstString,
}

export type TPrintItemSizeInputsMapper = {
  field: EPrintItemSizeStringFields | EPrintItemSizeNumberFields,
  label: TLocalizedConstString,
  placeholder: TLocalizedConstString,
};

export type TPricingTableItem = {
  id: string,
  subRows?: TPricingTableItem[],
  inputData: TPrintItemFormTablesInput,
} & TPrintItemPricing;

// simplify Pricings and OptionalPricings errors&touched which would only have one in 'price' field
export type TPrintItemPricingsTouched = {
  price: boolean,
}
export type TPrintItemPricingsError = {
  price: string | undefined
}

export interface IPortfolioDeleteActions {
  setIdToDelete: Dispatch<SetStateAction<string>>,
  toggleModal: () => void,
}

export interface IGetPrintItemsParams extends IPaginationParams {
  includePapers?: boolean,
  includeQuantities?: boolean,
  includeSizes?: boolean,
  includeOptions?: boolean,
  includePricing?: boolean,
  includeOptionalPricing?: boolean,
  includeBase?: boolean,
  pspId: string | undefined,
  search?: string,
  printItemId?: string,
}

export interface IPrintItemFormik {
 formik: FormikProps<TPrintItemForm>
}

export interface IOnBlurHelpers {
  e: FocusEvent<HTMLInputElement>
  inputName: string,
  handleBlur: FormikHandlers['handleBlur'],
  setFieldValue: FormikHelpers<TPrintItemsItem>['setFieldValue']
}
