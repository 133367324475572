import React, { useMemo } from 'react';
import { GroupBase, SingleValue } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import Select from 'components/library/Select';
import css from './ScaleSelector.module.css';
import { ECanvasZoomOption, CANVAS_ZOOM_SELECTOR_OPTIONS } from '../../../../constants';

interface IScaleSelector {
  zoom: number,
  setZoom: (zoom: number) => void,
  disabled?: boolean,
}

const ScaleSelector:React.FC<IScaleSelector> = ({
  zoom,
  setZoom,
  disabled = false,
}) => {
  const onChange = (newValue: SingleValue<ECanvasZoomOption>) => {
    if (newValue) {
      setZoom(newValue.value);
    }
  };

  const value = useMemo(() => CANVAS_ZOOM_SELECTOR_OPTIONS.find(({ value: v }) => v === zoom) ?? CANVAS_ZOOM_SELECTOR_OPTIONS[0], [zoom]);

  const customStyles: StylesConfig<ECanvasZoomOption, false, GroupBase<ECanvasZoomOption>> = {
    option: styles => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: styles => ({
      ...styles,
      cursor: 'pointer',
    }),
  };

  return (
    <Select<ECanvasZoomOption>
      isSearchable={false}
      menuPortalTarget={document.body}
      menuShouldBlockScroll
      options={CANVAS_ZOOM_SELECTOR_OPTIONS}
      className={css.select}
      value={value}
      onChange={onChange}
      styles={customStyles}
      isDisabled={disabled}
      getOptionLabel={option => option.label()}
    />
  );
};

export default ScaleSelector;
