/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconBackgroundColorPicker(props: IIconProps) {
  // filling only bottom line
  const exceptOfFill = { ...props };
  delete exceptOfFill.fill;

  const { fill } = props;

  return (
    <svg {...exceptOfFill} width="24" height="24" viewBox="0 0 24 24" className="">
      {
        fill
          ? (<path d="M2 20H22C23.1 20 24 20.9 24 22C24 23.1 23.1 24 22 24H2C0.9 24 0 23.1 0 22C0 20.9 0.9 20 2 20Z" fill={fill} />)
          : (
            <>
              <path d="M2 20.5H22C22.8239 20.5 23.5 21.1761 23.5 22C23.5 22.8239 22.8239 23.5 22 23.5H2C1.17614 23.5 0.5 22.8239 0.5 22C0.5 21.1761 1.17614 20.5 2 20.5Z" fill="transparent" stroke="#A6CBDF" />
              <line x1="5.41603" y1="20.2774" x2="3.41603" y2="23.2773" stroke="#A6CBDF" />
              <line x1="9.41603" y1="20.2774" x2="7.41603" y2="23.2773" stroke="#A6CBDF" />
              <line x1="13.416" y1="20.2774" x2="11.416" y2="23.2773" stroke="#A6CBDF" />
              <line x1="17.416" y1="20.2774" x2="15.416" y2="23.2773" stroke="#A6CBDF" />
              <line x1="21.416" y1="20.2774" x2="19.416" y2="23.2773" stroke="#A6CBDF" />
            </>
          )
      }
      <path d="M8.93993 16.56C9.22993 16.85 9.61993 17 9.99993 17C10.3799 17 10.7699 16.85 11.0599 16.56L16.5599 11.06C17.1499 10.48 17.1499 9.52998 16.5599 8.93998L8.31993 0.69998C8.22735 0.607398 8.11744 0.533958 7.99647 0.483853C7.87551 0.433748 7.74586 0.407959 7.61493 0.407959C7.3505 0.407959 7.09691 0.513002 6.90993 0.69998C6.72295 0.886957 6.61791 1.14055 6.61791 1.40498C6.61791 1.66941 6.72295 1.923 6.90993 2.10998L8.58993 3.78998L3.43993 8.93998C3.29964 9.07857 3.18826 9.24362 3.11224 9.42558C3.03622 9.60754 2.99707 9.80278 2.99707 9.99998C2.99707 10.1972 3.03622 10.3924 3.11224 10.5744C3.18826 10.7563 3.29964 10.9214 3.43993 11.06L8.93993 16.56ZM9.99993 5.20998L14.7899 9.99998H5.20993L9.99993 5.20998ZM18.9999 17C20.0999 17 20.9999 16.1 20.9999 15C20.9999 13.67 18.9999 11.5 18.9999 11.5C18.9999 11.5 16.9999 13.67 16.9999 15C16.9999 16.1 17.8999 17 18.9999 17Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconBackgroundColorPicker;
