/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFlagUK(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="white" />
      <path d="M15.242 20.8629V23.5529C16.528 23.1929 17.727 22.6254 18.7994 21.8861L15.8847 18.9714C15.6518 19.7897 15.5666 20.2128 15.242 20.8629Z" fill="#252F6C" />
      <path d="M15.9 5.49591L19.0818 2.31406C17.9387 1.47694 16.642 0.838992 15.2419 0.446777V3.21006C15.6162 3.95943 15.6476 4.52882 15.9 5.49591Z" fill="#252F6C" />
      <path d="M21.6364 19.1514C22.4377 18.0734 23.0623 16.8566 23.4679 15.5432H18.0283L21.6364 19.1514Z" fill="#252F6C" />
      <path d="M23.6051 8.94054C23.2409 7.55642 22.6352 6.2716 21.8369 5.13078L18.0268 8.94054H23.6051Z" fill="#252F6C" />
      <path d="M15.7752 6.09762L19.3527 2.51976C19.2631 2.45025 19.1733 2.38108 19.0817 2.314L15.8999 5.49585C15.9329 5.62165 15.7445 5.96877 15.7752 6.09762Z" fill="#E7E7E7" />
      <path d="M16.6005 14.7188L21.3722 19.4905C21.4618 19.3783 21.5508 19.2665 21.6365 19.1515L18.0284 15.5434H23.468C23.5717 15.207 23.6597 14.8639 23.734 14.5156H16.6776C16.666 14.6613 16.6143 14.574 16.6005 14.7188Z" fill="#E7E7E7" />
      <path d="M15.2418 2.98891V0.446535C14.9052 0.352193 14.5624 0.27224 14.2141 0.207031V1.44716C14.5965 1.86172 14.9393 2.38242 15.2418 2.98891Z" fill="#E7E7E7" />
      <path d="M16.5195 9.96803H23.8254C23.7661 9.62015 23.6937 9.27718 23.6053 8.9403H18.0269L21.8371 5.13054C21.4625 4.59544 21.0462 4.09222 20.5915 3.62593L16.2999 7.91713C16.4154 8.69553 16.4672 9.14758 16.5195 9.96803Z" fill="#E7E7E7" />
      <path d="M15.7111 18.7976L18.7993 21.8862C19.313 21.5324 19.7967 21.1387 20.2481 20.7113L16.1859 16.6489C16.0563 17.4002 15.9025 18.1259 15.7111 18.7976Z" fill="#E7E7E7" />
      <path d="M14.2144 22.4101V23.7923C14.5627 23.7275 14.9053 23.6471 15.2422 23.5528V20.8628C14.9394 21.4696 14.5966 21.9961 14.2144 22.4101Z" fill="#E7E7E7" />
      <path d="M11.9999 23.9999C12.757 23.9999 13.4963 23.9266 14.2144 23.7923V22.4101C13.5841 23.0933 12.8467 23.9999 11.9999 23.9999Z" fill="#D32030" />
      <path d="M16.2999 7.91714L20.5911 3.62594C20.2048 3.22917 19.7906 2.8599 19.3525 2.51978L15.9806 5.89223C16.1305 6.51863 16.1986 7.23562 16.2999 7.91714Z" fill="#D71F28" />
      <path d="M23.8253 9.96802H16.6143C16.7098 11.473 16.6983 13.0207 16.5798 14.5154H23.734C23.9072 13.7039 24 12.863 24 12.0001C24 11.307 23.9382 10.6291 23.8253 9.96802Z" fill="#D71F28" />
      <path d="M16.6004 14.7188C16.5337 15.4278 16.3014 15.9803 16.1858 16.6491L20.248 20.7113C20.6499 20.3304 21.0256 19.923 21.3719 19.4905L16.6004 14.7188Z" fill="#D71F28" />
      <path d="M14.2145 1.53643V0.207279C13.4966 0.0733154 12.7573 0 11.9999 0C12.8467 0 13.5841 0.853387 14.2145 1.53643Z" fill="#D32030" />
      <path d="M5.13069 21.8367C6.18831 22.5768 7.36992 23.1497 8.63939 23.5194V18.3286L5.13069 21.8367Z" fill="#29337A" />
      <path d="M2.31137 4.92191C2.24428 5.01362 2.17816 5.10665 2.11336 5.20063L5.8534 8.94048H0.394831C0.306366 9.27716 0.234166 9.62033 0.174683 9.96821H7.35806L2.31137 4.92191Z" fill="white" />
      <path d="M0.265983 14.5155C0.340464 14.8637 0.428371 15.2067 0.532391 15.5432H5.85249L2.31425 19.0814C2.69907 19.6072 3.12579 20.1006 3.58944 20.5569L9.63079 14.5155H0.265983Z" fill="white" />
      <path d="M8.6393 0.48029V6.15409L4.84864 2.36369C4.32606 2.7523 3.83757 3.18374 3.38497 3.65078L9.66683 9.93264V0.228271C9.31874 0.297077 8.97639 0.38235 8.6393 0.48029Z" fill="white" />
      <path d="M16.8334 14.9514C16.847 14.8066 16.8593 14.6613 16.8711 14.5154H16.3974L16.8334 14.9514Z" fill="white" />
      <path d="M15.2418 6.15356V2.61817C14.9391 2.01168 14.5965 1.49062 14.2141 1.07642V7.65838L16.278 5.59445C16.247 5.46561 16.2143 5.3394 16.1818 5.21359L15.2418 6.15356Z" fill="white" />
      <path d="M14.249 9.96809H16.9054C16.8533 9.14769 16.7703 8.34051 16.6549 7.56231L14.249 9.96809Z" fill="white" />
      <path d="M15.2419 21.3819C15.5665 20.7316 15.8448 19.983 16.0779 19.1648L15.2419 18.3289V21.3819Z" fill="#29337A" />
      <path d="M2.11336 5.20063C1.33952 6.32382 0.751426 7.58498 0.394831 8.94048H5.8534L2.11336 5.20063Z" fill="#29337A" />
      <path d="M8.6393 0.48029C7.25669 0.882941 5.97619 1.52565 4.84864 2.36369L8.6393 6.15409V0.48029Z" fill="#29337A" />
      <path d="M0.532391 15.5432C0.929165 16.8284 1.53722 18.0208 2.31425 19.0814L5.85249 15.5432H0.532391Z" fill="#29337A" />
      <path d="M4.85225 21.6386C4.94355 21.7066 5.03751 21.7715 5.13069 21.8367L8.63939 18.3286V23.5194C8.97647 23.6179 9.31871 23.7028 9.66699 23.7712V16.824L4.85225 21.6386Z" fill="white" />
      <path d="M16.1818 5.21359C15.929 4.2465 15.6161 3.36753 15.2418 2.61817V6.15356L16.1818 5.21359Z" fill="#29337A" />
      <path d="M14.2144 22.9234C14.5968 22.5088 14.9392 21.9884 15.2419 21.3819V18.3289L16.0779 19.1648C16.2696 18.4928 16.4307 17.7746 16.5607 17.0234L14.2145 14.6773L14.2144 22.9234Z" fill="white" />
      <path d="M14.2144 22.9234V14.6773L16.5605 17.0235C16.6759 16.3545 16.7667 15.6606 16.8331 14.9515L16.3971 14.5155H16.8708C16.9892 13.0207 17.0008 11.4731 16.9053 9.9681H14.2489L16.6547 7.56232C16.5533 6.8806 16.4279 6.22121 16.278 5.59461L14.2145 7.65854V1.07657C13.584 0.393329 12.8466 0 11.9998 0C11.8003 0 11.6009 0.00511738 11.4011 0.0147948C10.8108 0.0443337 10.232 0.11694 9.66698 0.228357V9.93268L3.38497 3.65082C2.99899 4.04911 2.63996 4.4735 2.31138 4.92201L6.32994 8.94057L7.35803 9.9683H0.1747C0.0621685 10.6292 0 11.3071 0 12.0002C0 12.8633 0.0932275 13.7039 0.266002 14.5155H9.63101L3.58961 20.5569C3.98522 20.9455 4.40642 21.3074 4.85229 21.6386L9.66683 16.824V23.7713C10.2318 23.8827 10.811 23.9553 11.4013 23.9849C11.6008 23.9945 11.8001 24 11.9996 24C12.8466 24 13.584 23.6067 14.2144 22.9234Z" fill="#E51D35" />
    </svg>

  );
}

export default IconFlagUK;
