/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignCenterH(props: IIconProps) {
  return (
    <svg {...props} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C9.55 0 10 0.45 10 1V5H16.5C17.33 5 18 5.67 18 6.5C18 7.33 17.33 8 16.5 8H10V12H13.5C14.33 12 15 12.67 15 13.5C15 14.33 14.33 15 13.5 15H10V19C10 19.55 9.55 20 9 20C8.45 20 8 19.55 8 19V15H4.5C3.67 15 3 14.33 3 13.5C3 12.67 3.67 12 4.5 12H8V8H1.5C0.67 8 0 7.33 0 6.5C0 5.67 0.67 5 1.5 5H8V1C8 0.45 8.45 0 9 0Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignCenterH;
