import React, { useCallback } from 'react';

import Table from 'components/library/Table';

import { TGetArticlesItem } from 'types';
import { IArticlesDisplayProps, IArticlesTableFilters } from 'types/Articles';
import useColumns from './useColumns';
import css from './ArticlesTableView.module.css';

interface IArticlesRowClick {
  (id: string, row: TGetArticlesItem): void
}

const ArticlesTableView: React.FC<IArticlesDisplayProps & IArticlesTableFilters> = ({
  articles, isNotCustomer, order, setOrder, status, setStatus, handleArticleClick, ...deleteArticleActions
}) => {
  const columns = useColumns({
    isNotCustomer, order, setOrder, status, setStatus, ...deleteArticleActions
  });

  const onClickRow = useCallback<IArticlesRowClick>(
    id => handleArticleClick(id),
    []
  ) as (id: string, row: unknown) => void;

  return (
    <Table<TGetArticlesItem>
      data={articles}
      columns={columns}
      meta={{ onClickRow }}
      state={{
        columnVisibility: {
          status: isNotCustomer,
        }
      }}
      rowClassName={css.row}
    />
  );
};

export default ArticlesTableView;
