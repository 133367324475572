import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';
import UserContext from 'contexts/ContextUser';
import Loader from 'components/library/Loader';
import { useLocation, useParams } from 'react-router-dom';
import Error from 'components/library/Error';
import { ERoles, TUser } from 'types';
import useUser from 'hooks/useUser';
import { createTargetUser, isPrinter } from 'helpers';
import Profile, { IProfile } from './Profile';
import css from './Profile.module.css';

const ProfileContainer:React.FC<{ targetRole?: ERoles, create?: boolean }> = ({ targetRole, create }) => {
  const context = useContext(UserContext);
  const { id: userId } = useParams();
  const location = useLocation();
  const userFromLocation:Partial<TUser> | undefined = get(location, 'state.user');
  const targetId = create ? undefined : userId || context.user?.id;
  const isSelf = targetId === context.user?.id;
  const { data: targetUser, error } = useUser({ userId: targetId, role: targetRole });
  const currentUser = context.user as TUser;

  const role = (targetRole || targetUser?.role) as ERoles;
  const props = useMemo<IProfile | null>(() => {
    if (targetUser || create) {
      const user = targetUser || createTargetUser(role, userFromLocation, currentUser);
      if (create && !isPrinter(user.userPermissions)) {
        user.sendOrderSheetsTo = currentUser.sendOrderSheetsTo;
      }
      const res:IProfile = {
        targetUser: user,
        currentUser,
        isSelf,
        targetRole: role,
        create
      };
      return res;
    }
    return null;
  }, [targetUser, currentUser, isSelf, create, userFromLocation]);

  if (error) {
    return (
      <Error
        code={error.status}
        title={error.response?.data.className}
        description={error.response?.data.data?.reason || error.response?.data.message}
      />
    );
  }
  if (!props) return <div className={css.loaderContainer}><Loader /></div>;
  return <Profile {...props} />;
};

export default ProfileContainer;
