/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconReprocess(props: IIconProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path fill="#2C3E66" fillRule="evenodd" d="M15.061 9.68l1.415 1.414 1.403-1.403-1.415-1.414-1.403 1.403zm1.404-3.525L20 9.691 8.686 21.004 5.151 17.47 16.465 6.155zM11.364 5.06L9.596 6.829l-1.06-1.06L10.303 4l1.061 1.06zM6.768 6.829L5 5.061 6.06 4l1.768 1.768-1.06 1.06zm3.535 3.536L8.536 8.596l1.06-1.06 1.768 1.767-1.06 1.061zM7.828 8.596l-1.767 1.768L5 9.304l1.768-1.768 1.06 1.06z" clipRule="evenodd" />
    </svg>
  );
}

export default IconReprocess;
