/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconClosedEye(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9159 9.63181C21.0543 11.3384 19.6888 12.7842 17.9662 13.8147L20.081 15.9294C20.386 16.2345 20.386 16.7291 20.081 17.0343C19.7759 17.3393 19.2813 17.3393 18.9761 17.0343L16.4952 14.5533C15.3259 15.0372 14.0709 15.3283 12.7812 15.4121V18.9531C12.7812 19.3846 12.4315 19.7344 12 19.7344C11.5685 19.7344 11.2187 19.3846 11.2187 18.9531V15.4122C9.92907 15.3284 8.674 15.0373 7.5047 14.5533L5.02381 17.0343C4.71869 17.3394 4.224 17.3394 3.91892 17.0343C3.61389 16.7292 3.61389 16.2346 3.91896 15.9295L6.03369 13.8147C4.31111 12.7842 2.94564 11.3385 2.08401 9.63185C1.88955 9.24666 2.04416 8.77681 2.42932 8.58232C2.81436 8.38783 3.28439 8.5424 3.47881 8.9276C4.92795 11.7978 8.15739 13.875 12 13.875C15.8455 13.875 19.0733 11.7953 20.5212 8.92756C20.716 8.54174 21.1861 8.3881 21.5707 8.58228C21.9559 8.77678 22.1105 9.24666 21.9159 9.63181Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconClosedEye;
