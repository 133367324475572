/* eslint-disable import/prefer-default-export */
import strings from 'constants/localization';
import { TIntegrationOrderFieldItem } from 'types/Integration';

const TICKET = 'ticket';
const ORDER = 'order';
const SAMPLE_USD = 'USD';

export const ORDER_FIELDS: TIntegrationOrderFieldItem[] = [
  // profile info
  {
    label: () => strings.orderFieldLabelAttendeeFullName,
    path: `${TICKET}.full_name`,
    sample: 'John Doe',
  },
  {
    label: () => strings.orderFieldLabelAttendeeFirstName,
    path: `${TICKET}.first_name`,
    sample: 'John',
  },
  {
    label: () => strings.orderFieldLabelAttendeeLastName,
    path: `${TICKET}.last_name`,
    sample: 'Doe',
  },
  {
    label: () => strings.orderFieldLabelAttendeeEmail,
    path: `${TICKET}.email`,
    sample: 'john.doe@gmail.com',
  },
  {
    label: () => strings.orderFieldLabelAttendeeBarcode,
    path: `${TICKET}.barcode`,
    sample: '56153166299148040639001',
  },
  {
    label: () => strings.orderFieldLabelTicketType,
    path: `${TICKET}.type_name`,
    sample: 'For children',
  },
  {
    label: () => strings.orderFieldLabelSeatingSeat,
    path: `${TICKET}.seating.seat`,
    sample: '1',
  },
  {
    label: () => strings.orderFieldLabelSeatingRow,
    path: `${TICKET}.seating.row`,
    sample: '1',
  },
  {
    label: () => strings.orderFieldLabelSeatingSection,
    path: `${TICKET}.seating.section`,
    sample: 'A',
  },
  {
    label: () => strings.orderFieldLabelSeatingEntrance,
    path: `${TICKET}.seating.entrance`,
    sample: '1',
  },
  {
    label: () => strings.orderFieldLabelOrderStatus,
    path: `${TICKET}.status`,
    sample: 'Attending',
  },
  {
    label: () => strings.orderFieldLabelPrice,
    path: `${ORDER}.price.label`,
    sample: '$1.50',
  },
  {
    label: () => strings.orderFieldLabelPriceValue,
    path: `${ORDER}.price.value`,
    sample: '1.50',
  },
  {
    label: () => strings.orderFieldLabelPriceCurrency,
    path: `${ORDER}.price.currency`,
    sample: SAMPLE_USD,
  },
  // tax
  {
    label: () => strings.orderFieldLabelTax,
    path: `${ORDER}.tax.label`,
    sample: '$0.15',
  },
  {
    label: () => strings.orderFieldLabelTaxValue,
    path: `${ORDER}.tax.value`,
    sample: '0.15',
  },
  {
    label: () => strings.orderFieldLabelTaxCurrency,
    path: `${ORDER}.tax.currency`,
    sample: SAMPLE_USD,
  },
];

export const EVENTBRITE_ORDER_FIELDS: string[] = [
  `${TICKET}.full_name`,
  `${TICKET}.first_name`,
  `${TICKET}.last_name`,
  `${TICKET}.email`,
  `${TICKET}.barcode`,
  `${ORDER}.price.label`,
  `${ORDER}.price.value`,
  `${ORDER}.price.currency`,
  `${ORDER}.tax.label`,
  `${ORDER}.tax.value`,
  `${ORDER}.tax.currency`,
  `${TICKET}.type_name`,
  `${TICKET}.status`,
];

export const CODE_KARUSSEL_ORDER_FIELDS: string[] = [
  `${TICKET}.full_name`,
  `${TICKET}.email`,
  `${TICKET}.barcode`,
  `${ORDER}.price.label`,
  `${TICKET}.type_name`,
];

export const API_ORDER_FIELDS: string[] = [
  `${TICKET}.full_name`,
  `${TICKET}.first_name`,
  `${TICKET}.last_name`,
  `${TICKET}.email`,
  `${TICKET}.barcode`,
  `${TICKET}.type_name`,
  `${TICKET}.seating.seat`,
  `${TICKET}.seating.row`,
  `${TICKET}.seating.section`,
  `${TICKET}.seating.entrance`,
];

export const PAYLOGIC_ORDER_FIELDS: string[] = [
  `${TICKET}.full_name`,
  `${TICKET}.first_name`,
  `${TICKET}.last_name`,
  `${TICKET}.email`,
  `${TICKET}.barcode`,
  `${TICKET}.type_name`,
  `${TICKET}.status`,
  `${TICKET}.seating.seat`,
  `${TICKET}.seating.row`,
  `${TICKET}.seating.section`,
  `${TICKET}.seating.entrance`,
];
