import React from 'react';

import Tooltip from '../../../Tooltip';
import css from './SimpleHeader.module.css';

interface ISimpleHeader {
  title: string,
}
const SimpleHeader: React.FC<ISimpleHeader> = ({ title }) => (
  <Tooltip title={title} placement="top" className={css.textOverflow}>
    <span>{title}</span>
  </Tooltip>
);

export default SimpleHeader;
