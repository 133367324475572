import React, { useCallback, useMemo, useState } from 'react';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';

import Table from 'components/library/Table';
import { SIDE_TO_STRING } from 'components/PageOrders/components/OrderEditor/helper';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';

import { EIntegrationServices, ETemplateSides, TExcelMatchings } from 'types';
import { TIntegrationCanvasObjects, TMatchingTableData, TMatchingTableDataItem } from 'types/Integration';
import strings from 'constants/localization';
import css from './FieldsMatchingModal.module.css';
import useColumns from './useIntegrationMatchingTable';
import { TMatchings } from '..';
import { findOrderFieldSample, sideReadyForMatching } from '../helpers';

interface IFieldsMatchingModal {
  integrationService: EIntegrationServices,
  frontObjects: TIntegrationCanvasObjects,
  backObjects: TIntegrationCanvasObjects,
  integrationMatchings: TMatchings,
  setIntegrationMatchings: React.Dispatch<React.SetStateAction<TMatchings>>,
  excelMatchings: TExcelMatchings | null
}

const FieldsMatchingModal: React.FC<IFieldsMatchingModal> = ({
  integrationService, frontObjects, backObjects, integrationMatchings, setIntegrationMatchings, excelMatchings
}) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const generateMatchingTableSideDataItem = (
    {
      id, title, name, type
    }: TIntegrationCanvasObjects[0],
    side: ETemplateSides,
    showSideLabel: boolean = false
  ): TMatchingTableDataItem => {
    const path = integrationMatchings[side][id] || null;
    const sample = path ? findOrderFieldSample(integrationService, path) : '';
    return {
      id,
      side: {
        label: showSideLabel ? SIDE_TO_STRING[side]() : '',
        value: side
      },
      objectTitle: {
        title,
        name,
        type,
      },
      chosenOrderFieldPath: path,
      sample,
    };
  };

  const generateMatchingTableSideData = (sideObjects: TIntegrationCanvasObjects, side: ETemplateSides): TMatchingTableData => {
    const [firstObject, ...subRowObjects] = sideObjects;
    return {
      ...generateMatchingTableSideDataItem(firstObject, side, true),
      subRows: subRowObjects?.length ? subRowObjects.map(subRow => generateMatchingTableSideDataItem(subRow, side)) : []
    };
  };

  const generateMatchingTableData = useCallback(
    (): TMatchingTableData[] => {
      const result: TMatchingTableData[] = [];
      if (sideReadyForMatching(frontObjects)) {
        result.push(generateMatchingTableSideData(frontObjects, ETemplateSides.FRONT));
      }
      if (sideReadyForMatching(backObjects)) {
        result.push(generateMatchingTableSideData(backObjects, ETemplateSides.BACK));
      }
      return result;
    },
    [integrationMatchings, frontObjects, backObjects]
  );

  const tableData = useMemo<TMatchingTableData[]>(
    () => generateMatchingTableData(),
    [generateMatchingTableData]
  );

  const columns = useColumns({ integrationService, setIntegrationMatchings, excelMatchings });

  if (tableData?.length === 0) {
    return (
      <EmptyState
        label={strings.emptyStateLabelNoObjectsForMatching}
        description={strings.emptyStateDescriptionAddFewObjects}
        variant={EmptyStates.NO_RESULTS}
      />
    );
  }
  return (
    <div className={css.modalContainer}>
      {tableData && (
        <Table<TMatchingTableData>
          data={tableData}
          columns={columns}
          state={{ expanded }}
          onExpandedChange={setExpanded}
          getSubRows={row => row.subRows}
          getExpandedRowModel={getExpandedRowModel()}
        />
      )}
    </div>
  );
};

export default FieldsMatchingModal;
