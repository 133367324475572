import React from 'react';

import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import css from './GetStartedDescription.module.css';

interface IFooter extends React.HTMLProps<HTMLDivElement> {}
const GetStartedDescription:React.FC<IFooter> = () => (
  <article className={css.container}>
    <header>
      <h2>{strings.editorGetStartedTitle}</h2>
    </header>

    <p className={textCss.pMedium3}>{strings.editorGetStartedContent}</p>

  </article>
);

export default GetStartedDescription;
