import React, {
  FC, useContext, useEffect, useMemo, useState
} from 'react';
import { SingleValue } from 'react-select';
import { FormikHelpers, useFormik } from 'formik';

import Select from 'components/library/Select';
import Button from 'components/library/Button';
import Label from 'components/library/Label';

import { EIntegrationServices } from 'types';
import { IProfileIntegrationTabForm, TIntegrationSelectServiceValue } from 'types/Integration';
import { useCreateIntegrationConnection, usePatchIntegrationConnection } from 'hooks/Integration/useIntegrationConnection';
import UserContext from 'contexts/ContextUser';
import AlertContext from 'contexts/ContextAlert';
import strings from 'constants/localization';
import containerCss from '../Tab.module.css';
import Modal from '../../../library/Modal';
import css from '../../../PageArticles/AddEditArticle/components/ArticleForm/components/TabTemplate/components/DeleteButton.module.css';
import EventbriteConnectionForm from './components/EventbriteConnectionForm';
import PaylogicConnectionForm from './components/PaylogicConnectionForm';
import { getIntegrationServiceName } from '../../helpers';
import ApiConnectionForm from './components/ApiConnectionForm';

const SERVICE = 'service';
export const INTEGRATION_TOKEN = 'integrationToken';

const isTokenRequired = (service: EIntegrationServices | null | undefined): boolean => {
  switch (service) {
    case EIntegrationServices.API:
    case EIntegrationServices.EVENTBRITE:
    case EIntegrationServices.PAYLOGIC:
      return true;
    default:
      return false;
  }
};

const IntegrationConnect: FC = () => {
  const { user } = useContext(UserContext);
  const { push } = useContext(AlertContext);

  const { mutateAsync: createConnection } = useCreateIntegrationConnection(user?.id);
  const { mutateAsync: updateConnection } = usePatchIntegrationConnection(user?.id);

  const connectedService = useMemo<EIntegrationServices | null>(
    () => user?.integration?.service || null,
    [user]
  );

  const integrationConnectionId = useMemo<string | null>(
    () => user?.integration?.id || null,
    [user]
  );

  const selectValues = useMemo<TIntegrationSelectServiceValue[]>(
    () => Object
      .values(EIntegrationServices)
      .filter(value => value !== EIntegrationServices.MANUAL) // TODO: remove when the development of manual orders continues
      .map(value => {
        let label = getIntegrationServiceName(value);
        if (value === connectedService) {
          label += ` [${strings.connected}]`;
        }
        return { label, value } as TIntegrationSelectServiceValue;
      }),
    [connectedService]
  );

  const onFormSubmit = async () => {
    if (connectedService && connectedService !== chosenService) {
      return setIsModalOpen(true);
    }

    await submitForm();
  };

  const onModalOk = async () => {
    setIsModalOpen(false);
    await submitForm();
  };

  const onConnect = async (connectData: IProfileIntegrationTabForm, options: FormikHelpers<IProfileIntegrationTabForm>) => {
    const { service, integrationToken } = connectData;
    if (service) {
      options.setSubmitting(true);

      const token = isTokenRequired(service) ? integrationToken : null;
      const preparedData = { service, token };
      const promise = integrationConnectionId
        ? updateConnection({ id: integrationConnectionId, ...preparedData })
        : createConnection(preparedData);

      await promise
        .then(() => {
          push({
            severity: 'success',
            message: integrationConnectionId ? strings.successConnectUpdate : strings.formatString(strings.successConnectTo, service) as string
          });
        })
        .catch(err => {
          const errMsg = err?.response?.data?.data?.reason || err?.response?.data?.message;
          push({ severity: 'error', message: errMsg });
        })
        .finally(() => {
          options.setSubmitting(false);
        });
    }
  };

  const {
    values: { service: chosenService, integrationToken },
    handleSubmit,
    getFieldProps,
    setFieldValue,
    submitForm,
    isSubmitting
  } = useFormik<IProfileIntegrationTabForm>({
    initialValues: {
      [SERVICE]: null,
      [INTEGRATION_TOKEN]: '',
    },
    onSubmit: onConnect,
  });

  const connectionForm = (): JSX.Element | null => {
    switch (chosenService) {
      case EIntegrationServices.API:
        return <ApiConnectionForm isSubmitting={isSubmitting} getFieldProps={getFieldProps} />;
      case EIntegrationServices.EVENTBRITE:
        return <EventbriteConnectionForm isSubmitting={isSubmitting} getFieldProps={getFieldProps} />;
      case EIntegrationServices.PAYLOGIC:
        return <PaylogicConnectionForm isSubmitting={isSubmitting} integrationToken={integrationToken} setFieldValue={setFieldValue} />;
      default:
        return null;
    }
  };

  const orgSelectValue = useMemo<TIntegrationSelectServiceValue | null>(
    () => selectValues?.find(({ value: v }) => v === chosenService) || null,
    [chosenService, selectValues]
  );

  const onSelectChange = (value: SingleValue<TIntegrationSelectServiceValue>) => setFieldValue(SERVICE, value?.value);

  const tokenRequired = useMemo<boolean>(() => isTokenRequired(orgSelectValue?.value), [orgSelectValue?.value]);

  useEffect(() => {
    if (connectedService) {
      setFieldValue(SERVICE, connectedService);
    }
  }, [connectedService]);

  const isSubmitBtnDisabled = useMemo<boolean>(
    () => !chosenService || (tokenRequired && !integrationToken?.length) || isSubmitting,
    [chosenService, tokenRequired, integrationToken, isSubmitting]
  );

  const chosenServiceName = useMemo<string>(
    () => getIntegrationServiceName(chosenService as EIntegrationServices),
    [chosenService]
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <div className={containerCss.selectContainer}>
          <Label text={strings.inputServiceNameLabel} />
          <Select<TIntegrationSelectServiceValue>
            {...getFieldProps(SERVICE)}
            options={selectValues}
            value={orgSelectValue}
            onChange={onSelectChange}
            isDisabled={isSubmitting}
          />
        </div>
        {/* TODO: Inputs could be different depending on chosen service, so make a comfortable mapper */}
        { connectionForm() }
        <Button
          onClick={onFormSubmit}
          type="button"
          buttonType="primary"
          className={containerCss.submitBtn}
          disabled={isSubmitBtnDisabled}
        >{connectedService && connectedService === chosenService
            ? strings.reconnect
            : strings.formatString(strings.connectToService, chosenServiceName || '')}</Button>
      </form>

      <Modal
        title={strings.inputServiceModalTitle}
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        onOk={onModalOk}
        okButtonProps={{ className: css.modalConfirm }}
      >
        <span>{strings.inputServiceModalBody}</span>
      </Modal>
    </>
  );
};

export default IntegrationConnect;
