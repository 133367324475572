/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconImage(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 6C6.89543 6 6 6.89543 6 8V16C6 17.1046 6.89543 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89543 17.1046
        6 16 6H8ZM12.8415 8.10989C13.3148 8.10989 13.6986 8.49365 13.6986 8.96703C13.6986 9.44041 13.3148 9.82418
        12.8415 9.82418C12.3681 9.82418 11.9843 9.44041 11.9843 8.96703C11.9843 8.49365 12.3681 8.10989 12.8415
        8.10989ZM15.8901 14.4599C15.8901 15.2498 15.2498 15.8901 14.4599 15.8901H9.3461C8.66336 15.8901 8.10989
        15.3366 8.10989 14.6539C8.10989 14.3375 8.2312 14.0332 8.44884 13.8035L9.10486 13.1113C9.7915 12.3869
        10.9072 12.2796 11.7193 12.8599L12.069 13.1098C12.5392 13.4458 13.1626 13.475 13.6621 13.1845C14.2512
        12.8418 14.9986 12.9492 15.4674 13.4438L15.498 13.4761C15.7498 13.7418 15.8901 14.0939 15.8901 14.4599Z"
        fill="#2C3E66"
      />
      <path
        d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z"
        fill="#2C3E66"
      />
      <path d="M1.62109 15.3025H3.02734V17.5037H1.62109V15.3025Z" fill="#2C3E66" />
      <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
      <path d="M1.62109 6.49634H3.02734V8.69754H1.62109V6.49634Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconImage;
