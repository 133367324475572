import React from 'react';
import fabric from '../../../../../../../../components/Editor/helpers/fabric';
import strings from '../../../../../../../../../../constants/localization';

export interface INumberingContent {
  object: typeof fabric.TextObject,
}

const NumberingContent: React.FC<INumberingContent> = ({ object }) => (
  <div>
    <div><b>{strings.templateTabNumberingPatternTitle}</b> {String(0).padStart(object.pattern, '0')} ({object.pattern})</div>
    <div><b>{strings.templateTabNumberingPrefixTitle}</b> {object.prefix}</div>
    <div><b>{strings.templateTabNumberingStartFromTitle}</b> {object.startValue}</div>
    <div><b>{strings.templateTabNumberingResultValueTitle}</b> {object.text}</div>
  </div>
);

export default NumberingContent;
