import {
  ChangeEvent, KeyboardEvent, Dispatch, SetStateAction, useState, useEffect, useRef
} from 'react';
import { ActionMeta, OnChangeValue } from 'react-select';
import { ExpandedState } from '@tanstack/react-table';
import { FormikErrors } from 'formik';
import _uniqueId from 'lodash/uniqueId';
import _first from 'lodash/first';

import {
  EUnits,
  EPrintItemNestedFields,
  TPrintItemsItemNotSaved,
  TPrintItemsItem,
  TPrintItemForm,
  TPrintItemNestedField,
  TPrintItemOptionalPricing,
  TPrintItemOptionPortfolio,
  TPrintItemPricing,
  TPrintItemSize
} from 'types';
import {
  TOptionalPricingTableRow, TPricingTableItem, TPrintItemFormTablesInput, EPrintItemTabs, TPrintItemPricingsTouched, TPrintItemPricingsError, IOnBlurHelpers
} from 'types/Portfolio';
import { addValuesAfterComma, replaceNumberSeparator } from 'helpers';
import {
  NUMBER_REGEX, NUMBER_TYPING_REGEX, PRINT_ITEM_FORM_TABS, ITEM_INFORMATION
} from './constants';

export const setTabWithError = (
  errors: FormikErrors<TPrintItemForm>,
  setTabIndex: Dispatch<SetStateAction<number>>
): void => {
  const firstErrorKey: EPrintItemTabs | undefined = _first(Object.keys(errors)) as EPrintItemTabs | undefined;
  if (firstErrorKey) {
    const tabIdxWithError: number = PRINT_ITEM_FORM_TABS.findIndex(tab => tab.value === firstErrorKey);
    setTabIndex(tabIdxWithError);
  }
};

export const handlePriceFieldChange = (e: ChangeEvent<HTMLInputElement>, handleChange: (e: ChangeEvent<any>) => void) => {
  const { value } = e.target;
  if (!NUMBER_TYPING_REGEX.test(value)) {
    return;
  }
  handleChange(e);
};

export const generateInitialSize = (order: number | null = null): TPrintItemSize => ({
  name: '',
  width: '',
  height: '',
  bleed: '',
  key: _uniqueId(),
  order,
});

export const generatePrintItemFormFields = (): TPrintItemForm => ({
  itemInformation: {
    title: '',
    description: '',
    paperComment: '',
    quantityComment: '',
    units: EUnits.MM,
    quantities: [],
    papers: [],
    sizes: [generateInitialSize(0)],
  },
  options: [],
  pricings: [],
  optionalPricings: [],
});

export const generatePrintItemNestedFieldValue = (
  value: string,
  order: number | null = null,
  key: null | string = null
): TPrintItemNestedField => ({
  key: key || _uniqueId(),
  value,
  order,
});

export const getTouchedFieldError = (touched: boolean, error: string | undefined): string => touched && error ? error : '';

export const createInputData = <E extends TPrintItemPricingsError, D extends { price: string }, T extends TPrintItemPricingsTouched>(
  index: number,
  errors: E[],
  touched: T[],
  values: D[],
  id: string | undefined,
  name: string
): TPrintItemFormTablesInput => {
  const fieldTouched = !!touched?.[index]?.price;
  const error = errors?.[index]?.price;

  return ({
    inputName: `${name}[${index}].price`,
    inputValue: values[index]!.price,
    inputError: getTouchedFieldError(fieldTouched, error),
    inputOrder: index,
    disabled: !!id
  });
};

export const parsePrintItemStrings = (v: string): number => Number(replaceNumberSeparator(v));

export const handleSavedNestedFields = ({
  value, id, optionId, printItemId, ...rest
}: TPrintItemNestedField): TPrintItemNestedField => {
  // Option Value has an optionId and a key that depends of that field, but Quantities and Papers not
  const nestedField = {
    ...rest,
    id,
    key: optionId || id,
    value: String(value),
  };
  return optionId ? { ...nestedField, optionId } : { ...nestedField, printItemId };
};

export const handleSavedOptions = ({ id, values, ...rest }: TPrintItemOptionPortfolio): TPrintItemOptionPortfolio => ({
  ...rest,
  id,
  key: id!,
  values: values.map(value => handleSavedNestedFields(value)),
});

export const handleSavedSizes = ({
  id, width, height, bleed, key, ...rest
}: TPrintItemSize): TPrintItemSize => ({
  ...rest,
  id,
  key: id || key,
  width: replaceNumberSeparator(width, false),
  height: replaceNumberSeparator(height, false),
  bleed: replaceNumberSeparator(bleed, false),
});

export const handleSavedPricings = ({
  id, price, quantity, paper, size, ...rest
}: TPrintItemPricing): TPrintItemPricing => ({
  ...rest,
  id,
  key: id!,
  price: addValuesAfterComma(price),
  quantity: handleSavedNestedFields(quantity),
  paper: handleSavedNestedFields(paper),
  size: handleSavedSizes(size),
});

export const handleSavedOptionalPricings = ({
  id, price, option, quantity, ...rest
}: TPrintItemOptionalPricing, options: TPrintItemOptionPortfolio[]): TPrintItemOptionalPricing => ({
  ...rest,
  id,
  key: id!,
  price: addValuesAfterComma(price),
  option: {
    ...option,
    key: options.find(({ values }) => values.find(({ id: optValueId }) => optValueId === option.id))?.id,
  },
  quantity: handleSavedNestedFields(quantity),
});

export const preparePrintItemData = ({
  quantities, papers, sizes, options, pricings, optionalPricings, ...rest
}: TPrintItemsItemNotSaved | TPrintItemsItem): TPrintItemForm => ({
  itemInformation: {
    ...rest,
    quantities: quantities.map(quantity => handleSavedNestedFields(quantity)),
    papers: papers.map(paper => handleSavedNestedFields(paper)),
    sizes: sizes.map(size => handleSavedSizes(size)),
  },
  options: options.map(opt => handleSavedOptions(opt)) || [],
  pricings: pricings.map(pricing => handleSavedPricings(pricing)),
  optionalPricings: optionalPricings.map(optPricing => handleSavedOptionalPricings(optPricing, options!)) || [],
});

// TODO: quantities and sizes separate parsing method
export const preparePrintItemDataForSave = ({
  itemInformation, options, pricings, optionalPricings,
}: TPrintItemForm): TPrintItemsItemNotSaved<number> => {
  const {
    quantities,
    sizes,
    ...rest
  } = itemInformation;
  return ({
    ...rest,
    quantities: quantities.map(({ value, ...quantityRest }) => ({ ...quantityRest, value: parsePrintItemStrings(value) })),
    sizes: sizes.map(({
      width, height, bleed, ...sizeRest
    }) => ({
      ...sizeRest,
      width: parsePrintItemStrings(width),
      height: parsePrintItemStrings(height),
      bleed: parsePrintItemStrings(bleed)
    })),
    options,
    pricings: pricings.map(({
      price, quantity, size, ...pricingRest
    }) => ({
      ...pricingRest,
      price: parsePrintItemStrings(price),
      quantity: { ...quantity, value: parsePrintItemStrings(quantity.value) },
      size: {
        ...size,
        width: parsePrintItemStrings(size.width),
        height: parsePrintItemStrings(size.height),
        bleed: parsePrintItemStrings(size.bleed),
      }
    })),
    optionalPricings: optionalPricings.map(({ price, quantity, ...optPricingRest }) => ({
      ...optPricingRest,
      price: parsePrintItemStrings(price),
      quantity: { ...quantity, value: parsePrintItemStrings(quantity.value) }
    }))
  });
};

// eslint-disable-next-line
export const removeMultiSelectLabelField = (values: OnChangeValue<TPrintItemNestedField, true>) => values.map(({ label, ...rest }) => rest);

export const handleMultiSelectChange = (
  selectValues: OnChangeValue<TPrintItemNestedField, true>,
  actionMeta: ActionMeta<TPrintItemNestedField>,
  fieldName: string,
  key: string | null = null
) => {
  const {
    action, removedValue, removedValues, option
  } = actionMeta;
  switch (action) {
    case 'remove-value':
    case 'pop-value':
      if (removedValue?.id) {
        return;
      }
      return removeMultiSelectLabelField(selectValues);
    case 'clear':
      return removedValues.filter(v => v.id);
    case 'create-option':
      if (fieldName === `${ITEM_INFORMATION}.${EPrintItemNestedFields.QUANTITIES}` && option) {
        if (!NUMBER_REGEX.test(option.value)) {
          return;
        }
      }
      if (option) {
        if (shouldTrimValue(option.value)) {
          return;
        }
        if (selectValues.length === 1) {
          return [generatePrintItemNestedFieldValue(option.value, 0, key)];
        }
        // eslint-disable-next-line
        const valuesBeforeChange = removeMultiSelectLabelField(selectValues.slice(0, -1));
        const { order } = valuesBeforeChange[valuesBeforeChange.length - 1];
        return [...valuesBeforeChange, generatePrintItemNestedFieldValue(option.value, typeof order === 'number' ? order + 1 : null, key)];
      }
      break;
    default:
      break;
  }
};

export const createTableSubRows = <T extends { subRows?: T[] }> (data: {[key:string]: T[]}): T[] => Object.keys(data).map(key => {
  const dataToDisplay = data[key];
  const tableItem = { ...dataToDisplay[0] };
  if (dataToDisplay.length > 1) {
    tableItem.subRows = [...dataToDisplay.slice(1)];
  }
  return tableItem;
});

export const preventEnterClick = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.code === 'Enter') {
    e.preventDefault();
  }
};

export const isRowExpandCondition = (inputData: TPrintItemFormTablesInput) => !inputData.inputValue || inputData.inputError;
export const rowShouldBeExpanded = (inputData: TPrintItemFormTablesInput | TPrintItemFormTablesInput[]) => Array.isArray(inputData)
  ? inputData.some(item => isRowExpandCondition(item))
  : isRowExpandCondition(inputData);
export const generateExpandObject = (indexes: number []): ExpandedState => {
  const expandObject: ExpandedState = {};
  indexes.forEach(idx => {
    expandObject[idx] = true;
  });
  return expandObject;
};
export const getExpandObject = (items: TPricingTableItem[] | TOptionalPricingTableRow[]): ExpandedState => {
  if (!items?.length) {
    return {};
  }
  const rowIndexesToExpand: number[] = items
    .map((item, idx) => {
      const { inputData, subRows } = item;
      if (!subRows?.length) { // if no subRows, the row is not expandable
        return -1;
      }
      return (rowShouldBeExpanded(inputData) || subRows.some(subRow => rowShouldBeExpanded(subRow.inputData)))
        ? idx
        : -1;
    })
    .filter(idx => idx >= 0);

  return generateExpandObject(rowIndexesToExpand);
};

interface IPricingsPagesHook {
  isSubmitting: boolean,
  errIdx: number,
  pricings: TPricingTableItem[] | TOptionalPricingTableRow[],
  prevExpanded: undefined | ExpandedState,
  setExpanded: Dispatch<SetStateAction<ExpandedState>>,
}
export const pricingsPagesHook = ({
  isSubmitting, errIdx, pricings, prevExpanded, setExpanded
}: IPricingsPagesHook) => {
  const [scrollToggler, setScrollToggler] = useState<boolean>(false);

  const fieldWithErrorRef = useRef<HTMLInputElement>(null);

  // auto-expand first row if empty fields or errors detected
  useEffect(() => {
    if (pricings?.length && (!prevExpanded || isSubmitting)) {
      const expandObject: ExpandedState = getExpandObject(pricings);
      if (Object.keys(expandObject).length) {
        setExpanded(expandObject);
        setScrollToggler(true);
      }
    }
  }, [pricings, prevExpanded, isSubmitting]);
  // scroll down into corresponding element after rows had been expanded
  useEffect(() => {
    const element = fieldWithErrorRef?.current;
    if (element && errIdx >= 0) {
      if (element && errIdx >= 0 && scrollToggler) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollToggler(false);
      }
    }
  }, [scrollToggler, errIdx]);

  return fieldWithErrorRef;
};

export const shouldTrimValue = (value: string) => value.startsWith(' ') || value.endsWith(' ');

export const trimOnBlur = ({
  e, inputName, handleBlur, setFieldValue
}: IOnBlurHelpers) => {
  handleBlur(e);
  const { value } = e.target;
  if (shouldTrimValue(value)) {
    setFieldValue(inputName, value.trim());
  }
};

export const twoCharactersAfterCommaOnBlur = ({
  e, inputName, handleBlur, setFieldValue
}: IOnBlurHelpers) => {
  handleBlur(e);
  const value = addValuesAfterComma(e.target.value);
  setFieldValue(inputName, replaceNumberSeparator(value, false));
};
