import React from 'react';
import MuiPagination, { PaginationProps } from '@mui/material/Pagination';
import MuiPaginationItem from '@mui/material/PaginationItem';
import css from './Pagination.module.css';

const classes = {
  root: css.root,
  ul: css.ul,
  outlined: css.outlined,
  text: css.text
};

const paginationItemClasses = {
  root: css.itemRoot,
  page: css.page,
  selected: css.selected,
  ellipsis: css.ellipsis,
  icon: css.icon,
};

const FIRST_PAGE = 1;
const PAGE_COUNT_ONE = 1;
interface IPagination extends PaginationProps {}
const Pagination:React.FC<IPagination> = props => {
  const { page = FIRST_PAGE, count = PAGE_COUNT_ONE, ...rest } = props;
  const hideNextButton = page === count;
  const hidePrevButton = page === FIRST_PAGE;

  if (count === PAGE_COUNT_ONE) {
    return null;
  }
  return (
    <MuiPagination
      page={page}
      count={count}
      hideNextButton={hideNextButton}
      hidePrevButton={hidePrevButton}
      {...rest}
      classes={classes}
      renderItem={item => <MuiPaginationItem {...item} classes={paginationItemClasses} />}
    />
  );
};

export default Pagination;
