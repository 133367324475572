import React from 'react';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import css from './ToolsDescription.module.css';

const ToolsDescription:React.FC = () => (
  <article className={css.container}>
    <header>
      <h2>{strings.editorToolsDescriptionTitle}</h2>
    </header>

    <ul>
      <li>
        <Icon type={ICON_TYPES.hand} />
        {strings.editorToolsDescriptionHand}
      </li>
      <li>
        <Icon type={ICON_TYPES.text} />
        {strings.editorToolsDescriptionText}
      </li>
      <li>
        <Icon type={ICON_TYPES.image} />
        {strings.editorToolsDescriptionImage}
      </li>
      <li>
        <Icon type={ICON_TYPES.code} />
        {strings.editorToolsDescriptionCode}
      </li>
      <li>
        <Icon type={ICON_TYPES.numbering} />
        {strings.editorToolsDescriptionNumbering}
      </li>
    </ul>

  </article>
);

export default ToolsDescription;
