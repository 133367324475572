import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import moment from 'moment';
import cls from 'classnames';
import { Link } from 'react-router-dom';

import get from 'lodash/get';
import textCss from 'assets/styles/Text.module.css';
import { EOrderStatus, ERoles } from 'types';
import { getUserFullName } from 'helpers';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import css from './Orders.module.css';
import FilterHeader from '../library/Table/components/FilterHeader';
import { TSort } from '../library/Table/types';
import StatusColumn from './components/StatusColumn';
import Tooltip from '../library/Tooltip';
import SimpleHeader from '../library/Table/components/SimpleHeader';
import {
  DATE_ORDER_OPTIONS,
  EColumnId, ID_ORDER_OPTIONS,
  processOrder,
  ROLE_TO_COLUMN_STYLE,
  ROLE_TO_COLUMNS,
  USER_ORDER_OPTIONS,
} from './helper';
import ReadyToPrint from './components/ReadyToPrint';
import Icon, { ICON_TYPES } from '../library/Icon';
import { TOrderTableItem } from './Orders';
import GroupStatusColumn from './components/GroupStatusColumn';

interface IUseColumns {
  role: ERoles,
  order: TSort,
  setOrder: (order: TSort) => void,
  changeStatus: (status: EOrderStatus, id: string) => void
}
const useColumns = ({
  role, order, setOrder, changeStatus
}:IUseColumns):ColumnDef<TOrderTableItem>[] => useMemo<ColumnDef<TOrderTableItem>[]>(
  () => {
    const {
      idOrder,
      dateOrder,
      managerOrder,
      customerOrder
    } = processOrder(order);

    const columns:ColumnDef<TOrderTableItem>[] = [
      {
        id: EColumnId.SHOW_NESTED,
        header: () => <span />,
        meta: {
          className: css.column
        },
        cell: ({ row }) => row.getCanExpand() && (
          <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
            <div className={cls(css.expandContainer, css.row)}>
              { row?.subRows?.length ?? '' }
              <Icon className={cls({ [css.expanded]: !row.getIsExpanded() })} type={ICON_TYPES.arrowUp} />
            </div>
          </div>
        ),
      },
      {
        id: EColumnId.TITLE,
        header: () => (
          <FilterHeader
            orderOptions={ID_ORDER_OPTIONS}
            order={idOrder}
            className={css.columnName}
            title={strings.ordersPageColumnId}
            setOrder={setOrder}
          />
        ),
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: ({ row }) => {
          const { title, source, sourceData } = row.original;

          let value = title;
          if (source !== 'web') {
            value = row.depth === 0 ? get(sourceData, 'serviceOrderId', title) : get(sourceData, 'ticketTypeName', title);
          }

          return (
            <Tooltip title={value} placement="top">
              <span className={textCss.h4}>{value}</span>
            </Tooltip>
          );
        }
      },
      {
        id: EColumnId.STATUS,
        header: () => <SimpleHeader title={strings.ordersPageColumnStatus} />,
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: ({ row }) => !row.getCanExpand() ? (
          <StatusColumn
            role={role}
            readyToPrint={row.original.readyToPrint}
            id={row.original.id}
            status={row.original.status}
            onChangeStatus={changeStatus}
          />
        ) : (<GroupStatusColumn rows={row.subRows} />)
      },
      {
        id: EColumnId.CREATED_AT,
        header: () => (
          <FilterHeader
            orderOptions={DATE_ORDER_OPTIONS}
            order={dateOrder}
            className={css.columnName}
            title={strings.ordersPageColumnCreatedAt}
            setOrder={setOrder}
          />
        ),
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => {
          const createdAtTitle: string = moment(props.row.original.createdAt)
            .format('DD.MM.YYYY HH:mm');

          return (
            <Tooltip title={createdAtTitle} placement="top">
              <span className={textCss.pLight2}>
                {createdAtTitle}
              </span>
            </Tooltip>
          );
        }
      },
      {
        id: EColumnId.CUSTOMER,
        header: () => (
          <FilterHeader
            orderOptions={USER_ORDER_OPTIONS('customer')}
            order={customerOrder}
            className={css.columnName}
            title={strings.ordersPageColumnCustomer}
            setOrder={setOrder}
          />
        ),
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => {
          const { id: customerId, name, surname } = props.row.original.customer;
          const value = [name, surname].join(' ');
          return (
            <Tooltip placement="top" title={value}>
              <div onClick={e => e.stopPropagation()}>
                <Link to={['', ROUTES.CUSTOMERS, customerId].join('/')}>
                  {value}
                </Link>
              </div>
            </Tooltip>
          );
        }
      },
      {
        id: EColumnId.MANAGER,
        header: () => (
          <FilterHeader
            orderOptions={USER_ORDER_OPTIONS('manager')}
            order={managerOrder}
            className={css.columnName}
            title={strings.ordersPageColumnManager}
            setOrder={setOrder}
          />
        ),
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => {
          const { manager } = props.row.original;
          const fullName = getUserFullName(manager);
          return (
            <Tooltip placement="top" title={fullName}>
              <div onClick={e => e.stopPropagation()}>
                <Link to={['', ROUTES.MANAGERS, manager.id].join('/')}>
                  {fullName}
                </Link>
              </div>
            </Tooltip>
          );
        }
      },
      {
        id: EColumnId.READY_TO_PRINT,
        header: '',
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role]),
        },
        cell: ({ row }) => !row.getCanExpand() && (<ReadyToPrint order={row.original} role={role} />)
      },
    ];
    return columns.filter(({ id }) => ROLE_TO_COLUMNS[role].includes(id as EColumnId));
  },
  [order]
);

export default useColumns;
