import React, { useContext, useEffect, useState } from 'react';
import cls from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import { IResponseError } from 'constants/types';
import { ROUTES } from 'constants/constants';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import { Wrapper } from 'components/PageLogin';
import useQueryParams from 'hooks/useQueryParams';
import ContextAlert from 'contexts/ContextAlert';
import { useResetPassword } from 'hooks/usePassword';
import css from './UserResetPassword.module.css';

const Schema = () => Yup.object().shape({
  password: Yup.string().min(6, strings.inputValidatePassLength).required(strings.inputValidateRequiredField),
  confirm: Yup.string().min(6, strings.inputValidatePassLength).when('password', {
    is: (val:string) => (!!(val && val.length > 0)),
    then: Yup.string().oneOf([Yup.ref('password')], strings.inputValidatePassMatch)
  })
});

interface IFormValues {
  password: string,
  confirm: string
}

interface IForm {
  setStatus?: React.Dispatch<React.SetStateAction<boolean>>
}

const Form: React.FC<IForm> = () => {
  const query = useQueryParams();
  const { push } = useContext(ContextAlert);
  const { mutateAsync } = useResetPassword();
  const [, setState] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (data:IFormValues, options:FormikHelpers<IFormValues>) => {
    const token = query.get('token') || '';
    mutateAsync({ password: data.password, token })
      .then(() => {
        push({ severity: 'success', message: strings.loginNotifyPassHasBeenReset });
        navigate(ROUTES.USER_LOGIN, { replace: true });
      })
      .catch((err:IResponseError) => {
        const status = err.response?.status as number;
        if (status === 400) {
          return push({ severity: 'error', message: strings.errorResponseInvalidResetToken });
        }
        if (status >= 400 && status < 500) {
          push({ severity: 'error', message: strings.errorResponse4 });
        } else {
          push({ severity: 'error', message: strings.errorResponse5 });
        }
        options.setSubmitting(false);
      })
      .finally(() => {
      });
  };
  useEffect(() => {
    const lang = query.get('lang');
    if (lang) {
      localStorage.setItem('lang', lang);
      strings.setLanguage(lang);
      setState(true);
    }
  }, [query]);
  return (
    <>
      <span className={cls(textCss.h2, css.formLabel)}>{strings.loginPageNewPassTitle}</span>
      <Formik
        validationSchema={Schema()}
        validateOnChange
        initialValues={{
          password: '',
          confirm: ''
        } as IFormValues}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Input
              name="password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              className={css.input}
              label={strings.inputPasswordLabel}
              type="password"
              withError
              correct={!!values.password && !errors.password}
              error={errors.password}
              placeholder={strings.inputPasswordPlaceholder}
            />
            <Input
              name="confirm"
              value={values.confirm}
              onBlur={handleBlur}
              onChange={handleChange}
              className={css.input}
              label={strings.inputPasswordConfirmLabel}
              type="password"
              correct={!!values.confirm && !errors.confirm}
              withError
              error={errors.confirm}
              placeholder={strings.inputPasswordConfirmPlaceholder}
            />
            <Button disabled={isSubmitting} type="submit" buttonType="primary" className={css.controls}>{strings.loginPageNewPassButtonReset}</Button>
          </form>
        )}
      </Formik>
    </>
  );
};

function UserResetPassword() {
  return (
    <Wrapper>
      <Form />
    </Wrapper>
  );
}

export default UserResetPassword;
