import React, { Dispatch, SetStateAction, useCallback } from 'react';
import cls from 'classnames';

import { TPrintItemsItem } from 'types';
import textCss from 'assets/styles/Text.module.css';
import css from './PrintItemCard.module.css';

interface IPrintItemCard {
  printItem: TPrintItemsItem,
  selectedPrintItemId: string | undefined,
  handleSelectPrintItem: Dispatch<SetStateAction<TPrintItemsItem | null>>,
}

const PrintItemCard: React.FC<IPrintItemCard> = ({
  printItem, selectedPrintItemId, handleSelectPrintItem
}) => {
  const selectPrintItem = useCallback(
    () => handleSelectPrintItem(printItem),
    [printItem]
  );

  return (
    <div
      className={cls(css.item, { [css.active]: printItem.id === selectedPrintItemId })}
      onClick={selectPrintItem}
    >
      <div className={css.titleContainer}>
        <div className={cls(css.circle, { [css.circleActive]: printItem.id === selectedPrintItemId })} />
        <span className={textCss.h4}>{printItem.title}</span>
      </div>
      <div className={cls(css.description, textCss.pMedium3)}>{printItem.description}</div>
    </div>
  );
};

export default PrintItemCard;
