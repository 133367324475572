import isEqual from 'lodash/isEqual';

import { IArticleContext } from 'contexts/ContextArticle';

// eslint-disable-next-line import/prefer-default-export
export const areArticleFormValuesEqualInitial = (
  currentValues: IArticleContext['articleFormValues'],
  initialValues: IArticleContext['articleFormInitialValues']
): boolean => isEqual(
  currentValues,
  initialValues
);
