import React, { useMemo } from 'react';

import Button from 'components/library/Button';

import strings from 'constants/localization';
import css from './ModalControls.module.css';
import { ETabs } from '../../types';

interface IPlaceOrderModalControls {
  onBack: () => void
  disableBack: boolean
  onNext: () => void
  disableNext: boolean
  tab: ETabs
}

const PlaceOrderModalControls: React.FC<IPlaceOrderModalControls> = React.memo(({
  onBack, onNext, disableBack, disableNext, tab
}) => (
  <div className={css.controls}>
    <Button
      type="button"
      buttonType="secondary"
      onClick={onBack}
      disabled={disableBack}
    >
      {strings.actionLabelBack}
    </Button>
    <ControlsStep tab={tab} />
    <div>
      <Button
        type="button"
        buttonType="primary"
        onClick={onNext}
        disabled={disableNext}
      >
        {strings.actionLabelNext}
      </Button>
    </div>
  </div>
));

const ControlsStep: React.FC<{ tab: ETabs }> = React.memo(({ tab }) => {
  const currentTab = useMemo(() => tab + 1, [tab]);

  return (
    <div className={css.counter}>
      {strings.formatString(strings.orderPageModalUploadListStepsTitle, currentTab, ETabs.TICKET_TYPE + 1)}
    </div>
  );
});

const PlaceOrderModalControlsContainer: React.FC<IPlaceOrderModalControls> = props => <PlaceOrderModalControls {...props} />;

export default PlaceOrderModalControlsContainer;
