import {
  EPermissions, ERoles, ROLE_SELF, TRoleSelf
} from '../../types';

export enum EActions {
  VIEW_STATS,
  EDIT_ADMIN_META,
  EDIT_PRINTER_META,
  EDIT_MANAGER_META,
  EDIT_CUSTOMER_META,
  VIEW_TAB_MANAGER_PERMISSIONS,
  EDIT_MANAGER_PERMISSION,
  VIEW_PROFILE,
}

type TPermissionMap<T extends number> = {
  [role in ERoles | TRoleSelf]: {
    [key in T]?: EPermissions[]
  }
}

const map:TPermissionMap<EActions> = {
  [ROLE_SELF]: {
    [EActions.VIEW_STATS]: [EPermissions.CUSTOMER],
    [EActions.VIEW_PROFILE]: [EPermissions.CUSTOMER, EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER],
    [EActions.EDIT_MANAGER_PERMISSION]: [/* EPermissions.ADMIN, EPermissions.PRINTER */],
    [EActions.EDIT_MANAGER_META]: [],
    [EActions.EDIT_CUSTOMER_META]: [],
    [EActions.EDIT_PRINTER_META]: [],
    [EActions.EDIT_ADMIN_META]: [],
    [EActions.VIEW_TAB_MANAGER_PERMISSIONS]: [/* EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER */],
  },
  [ERoles.ADMIN]: {
    [EActions.VIEW_PROFILE]: [EPermissions.ADMIN],
    [EActions.VIEW_STATS]: [],
    [EActions.EDIT_MANAGER_META]: [],
    [EActions.EDIT_CUSTOMER_META]: [],
    [EActions.EDIT_PRINTER_META]: [],
    [EActions.EDIT_ADMIN_META]: [/* EPermissions.ADMIN, EPermissions.EDIT_ADMIN_META */],
    [EActions.EDIT_MANAGER_PERMISSION]: [],
  },
  [ERoles.PRINTER]: {
    [EActions.VIEW_PROFILE]: [EPermissions.ADMIN, EPermissions.PRINTER],
    [EActions.VIEW_STATS]: [],
    [EActions.EDIT_MANAGER_PERMISSION]: [],
    [EActions.EDIT_MANAGER_META]: [],
    [EActions.EDIT_PRINTER_META]: [EPermissions.ADMIN, EPermissions.EDIT_PRINTER_META],
  },
  [ERoles.MANAGER]: {
    [EActions.VIEW_STATS]: [],
    [EActions.VIEW_PROFILE]: [EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER],
    [EActions.EDIT_MANAGER_META]: [EPermissions.ADMIN, EPermissions.PRINTER],
    [EActions.VIEW_TAB_MANAGER_PERMISSIONS]: [/* EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER */],
    [EActions.EDIT_MANAGER_PERMISSION]: [/* EPermissions.ADMIN, EPermissions.PRINTER */],
  },
  [ERoles.CUSTOMER]: {
    [EActions.VIEW_PROFILE]: [EPermissions.CUSTOMER, EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER],
    [EActions.VIEW_STATS]: [EPermissions.PRINTER, EPermissions.MANAGER],
    [EActions.EDIT_MANAGER_META]: [],
    [EActions.EDIT_MANAGER_PERMISSION]: [],
    [EActions.EDIT_CUSTOMER_META]: [EPermissions.ADMIN, EPermissions.PRINTER, EPermissions.MANAGER, EPermissions.EDIT_CUSTOMER_META],
  },
};

type TCheckPermission = (action:EActions, userPermissions:EPermissions[], role: ERoles, isSelf: boolean) => boolean;

export const checkPermission:TCheckPermission = (action, userPermissions, targetRole, isSelf) => {
  const role = isSelf ? ROLE_SELF : targetRole;
  const mappedItem = map[role][action];
  if (mappedItem) {
    return mappedItem.some(p => userPermissions.includes(p));
  }
  throw new Error(`Missing rules for: [profile, ${role}, ${action}, ]`);
};
