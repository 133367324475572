/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconHand(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2169 3.89099C16.9492 3.57358 17.2856 2.72261 16.9682 1.99028C16.6508 1.25796 15.7998 0.921592
        15.0675 1.23899C14.3351 1.5564 13.9988 2.40737 14.3162 3.1397C14.6336 3.87202 15.4845 4.20839 16.2169 3.89099Z"
        fill="#2C3E66"
      />
      <path
        d="M11.5315 2.77155C12.2638 2.45414 12.6002 1.60317 12.2828 0.870844C11.9654 0.138516 11.1144 -0.197847
        10.3821 0.119554C9.64973 0.436956 9.31337 1.28793 9.63077 2.02026C9.94817 2.75258 10.7991 3.08895
        11.5315 2.77155Z"
        fill="#2C3E66"
      />
      <path
        d="M7.61235 3.4201C8.34467 3.1027 8.68104 2.25172 8.36364 1.5194C8.04623 0.78707 7.19526 0.450707
        6.46293 0.768108C5.73061 1.08551 5.39424 1.93648 5.71164 2.66881C6.02905 3.40114 6.88002 3.7375
        7.61235 3.4201Z"
        fill="#2C3E66"
      />
      <path
        d="M4.84263 6.72065C5.57496 6.40324 5.91132 5.55227 5.59392 4.81994C5.27652 4.08762 4.42555 3.75125
        3.69322 4.06865C2.96089 4.38606 2.62453 5.23703 2.94193 5.96936C3.25933 6.70168 4.11031 7.03805
        4.84263 6.72065Z"
        fill="#2C3E66"
      />
      <path
        d="M14.3162 3.1397L16.8066 8.88567C16.9123 9.12966 16.8001 9.4135 16.5561 9.51924C16.3122 9.62499
        16.0283 9.5128 15.9226 9.26881L12.2828 0.870844L9.63077 2.02026L12.8875 9.53423C12.9932 9.77821 12.881
        10.0621 12.637 10.1678C12.393 10.2735 12.1092 10.1613 12.0035 9.91737L8.36364 1.5194L5.71164
        2.66881L9.35146 11.0668C9.45721 11.3108 9.34502 11.5946 9.10103 11.7003C8.85705 11.8061 8.57321
        11.6939 8.46747 11.4499L5.59392 4.81994L2.94193 5.96936L7.73116 17.0193L4.87939 15.3456C3.99459
        14.7578 2.84066 14.9324 2.28525 15.7297C1.7371 16.5364 2.00256 17.6625 2.87914 18.2507C2.87914
        18.2507 7.66012 21.369 9.70137 22.7062C11.7426 24.0434 14.3701 24.6424 18.0979 23.0268C24.27 20.3517
        22.7566 15.3457 22.1405 13.9242C21.5244 12.5028 16.9682 1.99028 16.9682 1.99028L14.3162 3.1397Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconHand;
