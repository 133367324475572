import React, { useEffect, useMemo, useState } from 'react';
import Select from 'components/library/Select';
import { SingleValue } from 'react-select';
import { number, object } from 'yup';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import css from './FontSizeSelector.module.css';

interface IOption {
  label: number,
  value: number,
}

const optionsList: number[] = [
  6, 8, 10, 12, 14,
  18, 24, 30, 36, 48,
  60, 72, 96,
];

const options: IOption[] = optionsList.map(x => ({ label: x, value: x }));

const NEW_OPTION_SCHEMA = object({
  label: number().required().min(1).max(1500),
  value: number().required().min(1).max(1500),
});

const FontSizeSelector: React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const zoom = useMemo(() => canvas.current?.getZoom() ?? 1, [canvas.current?.getZoom()]);

  const [sortedOptions, setSortedOptions] = useState<IOption[]>(options);
  const option = useMemo<IOption>(
    () => sortedOptions.find(({ value }) => value === activeObject.fontSize) ?? sortedOptions[0],
    [activeObject.fontSize, zoom, sortedOptions]
  );

  useEffect(() => {
    if (!optionsList.includes(activeObject.fontSize)) {
      setSortedOptions(
        [...options, { label: activeObject.fontSize, value: activeObject.fontSize }]
          .sort(({ value: a }, { value: b }) => a - b)
      );
    }
  }, [activeObject.fontSize]);

  const handleChange = (newValue: SingleValue<IOption>) => {
    if (activeObject && canvas.current) {
      try {
        const { value } = NEW_OPTION_SCHEMA.validateSync(newValue);

        activeObject.set({ fontSize: value });
        canvas.current.fire('object:modified', { target: activeObject });
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <Select<IOption>
      isCreatable
      className={css.select}
      menuShouldBlockScroll
      options={sortedOptions}
      value={option}
      onChange={handleChange}
    />
  );
};

export default FontSizeSelector;
