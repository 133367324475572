import { fabric as libFabric } from 'fabric';
// @ts-ignore
import extractColors from 'extract-colors';
import { ETemplateSides } from 'types';
import { calculateImgDPI, dpiNotification } from 'helpers';
import { IAlertAction } from 'contexts/ContextAlert';
import { DPI_IMAGE_ERROR_THRESHOLD } from 'constants/constants';
import strings from 'constants/localization';
import { fileToDataUrl } from './file';
import pdfDataUrlToPngDataUrl, { convertUnit, EConvertUnitDirection } from './pdf';
import { fabricJsonToThumbnail } from './thumbnail';
import { IPdfBox } from '../contexts/EditorContext';
import { getBitmap } from '../components/Viewport/fabric/bitmap';
import { EAcceptValue, isImage } from '../components/UploadArea';
import createPdfTemplate from './pdfTemplate';
import getImageBackgroundObject from './getImageBackgroundObject';

type TFabric = typeof libFabric & {
  CodeObject: any,
  ImageObject: any,
  TextObject: any,
  NumberingObject: any,
  util: {
    findScaleToFit: any
    findScaleToCover: any
  }
}

const fabric:TFabric = libFabric as TFabric;

// Prevents position shifts
// http://fabricjs.com/fabric-gotchas
fabric.Object.NUM_FRACTION_DIGITS = 32;

// The size of the background that will be generated depends on this parameter.
// This value should correspond to the maximum possible Zoom (2 = 200%) to not be blurred background when scale
const BG_QUALITY_FACTOR = 2;

export async function fileToFabricSide(file: File, pdfBox: IPdfBox, side: ETemplateSides, notify?: (action: Omit<IAlertAction, 'onClose'>) => void) {
  const fileDataUrl = isImage(file.type as EAcceptValue)
    ? await createPdfTemplate(pdfBox)
    : await createPdfTemplate(pdfBox, file);

  const { docWidth, docHeight, dataUrl } = await pdfDataUrlToPngDataUrl(fileDataUrl, pdfBox, BG_QUALITY_FACTOR);

  const bg = new fabric.ImageObject(dataUrl, {
    crossOrigin: 'anonymous',
  });
  bg.scale(1 / BG_QUALITY_FACTOR);
  await new Promise(resolve => {
    bg.setSrc(dataUrl, resolve);
  });

  const canvas: any = {
    version: fabric.version,
    backgroundImage: bg.toJSON(),
    objects: [],
  };

  // bleed in pixels
  const bleed = convertUnit(pdfBox.bleed, pdfBox.unit, EConvertUnitDirection.PIXELS, 72);

  if (side === ETemplateSides.BACK) {
    const bitmap = getBitmap({
      width: docWidth,
      height: docHeight,
      bleed,
    });
    canvas.overlayImage = bitmap.toJSON();
  }

  if (isImage(file.type as EAcceptValue)) {
    const imageDataUrl = await fileToDataUrl(file);
    const image = await getImageBackgroundObject(imageDataUrl, pdfBox);

    if (notify) {
      const dpi = calculateImgDPI(image, pdfBox, pdfBox.unit);
      dpiNotification(notify, dpi, false);
      if (dpi < DPI_IMAGE_ERROR_THRESHOLD) {
        throw Error(strings.orderPageDpiNotificationError);
      }
    }

    canvas.objects.push(image.toJSON());
  }

  // TODO: check cors option
  const extractedColors: Array<{ hex: string, saturation: number }> = await extractColors(dataUrl).catch(console.log);
  const sortedColors = extractedColors.sort((a, b) => a.saturation - b.saturation);
  const colors = sortedColors.map(({ hex }) => ({ hex }));

  const pdfDocument = {
    width: docWidth,
    height: docHeight,
    bleed,
    src: fileDataUrl,
    colors
  };

  const thumbnail = await fabricJsonToThumbnail(canvas, pdfDocument);

  return {
    canvas,
    thumbnail,
    document: pdfDocument,
  };
}

export function groupSVGToCodeObject(elements: any[], options?: any, path?: string): typeof fabric.CodeObject {
  if (options) {
    if (options.width && options.height) {
      options.centerPoint = {
        x: options.width / 2,
        y: options.height / 2
      };
    } else {
      delete options.width;
      delete options.height;
    }
  }
  const object = new fabric.CodeObject(elements, options);
  if (typeof path !== 'undefined') {
    object.sourcePath = path;
  }
  return object;
}

export default fabric;
