import strings from 'constants/localization';
import { ETemplateSides, TLocalizedConstString } from 'types';

export enum ECanvasActiveSelection {
  ACTIVE_SELECTION = 'activeSelection',
}

export type ETemplateSideOption = {
  label: string,
  accusative?: string,
  value: ETemplateSides,
  exists: boolean,
}

export type ECanvasZoomOption = {
  label: TLocalizedConstString,
  value: number,
}

export const CANVAS_ZOOM_SELECTOR_OPTIONS:ECanvasZoomOption[] = [
  {
    label: () => strings.canvasZoomOptionFitLabel,
    value: 0,
  },
  {
    label: () => '12.5%',
    value: 0.125,
  },
  {
    label: () => '25%',
    value: 0.25,
  },
  {
    label: () => '50%',
    value: 0.5,
  },
  {
    label: () => '75%',
    value: 0.75,
  },
  {
    label: () => '100%',
    value: 1,
  },
  {
    label: () => '125%',
    value: 1.25,
  },
  {
    label: () => '150%',
    value: 1.5,
  },
  {
    label: () => '175%',
    value: 1.75,
  },
  {
    label: () => '200%',
    value: 2,
  },
  {
    label: () => '300%',
    value: 3,
  },
];

export enum EViewportModes {
  Preview = 'preview',
  Navigate = 'navigate',
  Edit = 'edit',
}

export const EDITOR_BITMAP = {
  width: 150.236, // 53 mm (dpi 96)
  height: 42.519, // 15 mm  (dpi 96)
  color: 'white'
};
