import { useMutation, useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import {
  TIntegrationEventBind,
  TIntegrationEventBindQueryWithId,
  TCreateIntegrationEventBind,
  TPatchIntegrationEventBind,
  TIntegrationEventBindQuery
} from 'types/Integration';
import { IResponseError, IFindResponse } from 'constants/types';
import { useInvalidateUserGet } from 'hooks/useUser';

const getURL = (path: string, params: any): string => {
  const searchParams = new URLSearchParams(params);
  return `${path}?${searchParams.toString()}`;
};

const client = instance.getClient();

export const getIntegrationEventBinds = (params: TIntegrationEventBindQuery):Promise<IFindResponse<TIntegrationEventBind[]>> => client
  .get(getURL('/event-bind', params))
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_EVENT_BINDS_GET = ['integrationEventBinds', 'get'];
export function useGetIntegrationEventBinds(data: TIntegrationEventBindQuery) {
  return useQuery<IFindResponse<TIntegrationEventBind[]>, IResponseError>(
    [...QUERY_KEY_INTEGRATION_EVENT_BINDS_GET, data.customerId],
    () => getIntegrationEventBinds(data),
  );
}

export const useInvalidateGetIntegrationEventBinds = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_INTEGRATION_EVENT_BINDS_GET, ...additionalParams]);
};

export const getIntegrationEventBind = ({ id, connectionId, customerId }: TIntegrationEventBindQueryWithId):Promise<TIntegrationEventBind> => client
  .get(`/event-bind/${id}?customerId=${customerId}&connectionId=${connectionId}`)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_EVENT_BIND_GET = ['integrationEventBind', 'get'];
export function useGetIntegrationEventBind(data: TIntegrationEventBindQueryWithId) {
  return useQuery<TIntegrationEventBind, IResponseError>(
    QUERY_KEY_INTEGRATION_EVENT_BIND_GET,
    () => getIntegrationEventBind(data),
  );
}

export const createIntegrationEventBind = (
  data: TCreateIntegrationEventBind,
  { customerId, connectionId }: TIntegrationEventBindQuery
): Promise<TIntegrationEventBind> => client
  .post(`/event-bind?customerId=${customerId}&connectionId=${connectionId}`, data)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_EVENT_BIND_CREATE = ['integrationEventBind', 'create'];
export function useCreateIntegrationEventBind(query: TIntegrationEventBindQuery) {
  const invalidateIntegrationEventBinds = useInvalidateGetIntegrationEventBinds();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TIntegrationEventBind, IResponseError, TCreateIntegrationEventBind>(
    [...QUERY_KEY_INTEGRATION_EVENT_BIND_CREATE, query.customerId, query.connectionId],
    data => createIntegrationEventBind(data, query),
    {
      retry: false,
      onSuccess: () => Promise.all([invalidateIntegrationEventBinds(), invalidateUserGet(query.customerId!)]),
    }
  );
}

export const patchIntegrationEventBind = (
  { id, ...data }: TPatchIntegrationEventBind,
  { customerId, connectionId }: TIntegrationEventBindQuery
): Promise<TIntegrationEventBind> => client
  .patch(`/event-bind/${id}?customerId=${customerId}&connectionId=${connectionId}`, data)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_EVENT_BIND_PATCH = ['integrationEventBind', 'patch'];
export function usePatchIntegrationEventBind(query: TIntegrationEventBindQuery) {
  const invalidateIntegrationEventBinds = useInvalidateGetIntegrationEventBinds();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TIntegrationEventBind, IResponseError, TPatchIntegrationEventBind>(
    [...QUERY_KEY_INTEGRATION_EVENT_BIND_PATCH, query.customerId, query.connectionId],
    data => patchIntegrationEventBind(data, query),
    {
      retry: false,
      onSuccess: () => Promise.all([invalidateIntegrationEventBinds(), invalidateUserGet(query.customerId!)]),
    }
  );
}

export const deleteIntegrationEventBind = (id: string, { customerId, connectionId }: TIntegrationEventBindQuery): Promise<TIntegrationEventBind> => client
  .delete(`/event-bind/${id}?customerId=${customerId}&connectionId=${connectionId}`)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_EVENT_BIND_DELETE = ['integrationEventBind', 'delete'];
export function useDeleteIntegrationEventBind(query: TIntegrationEventBindQuery) {
  const invalidateIntegrationEventBinds = useInvalidateGetIntegrationEventBinds();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TIntegrationEventBind, IResponseError, string>(
    [...QUERY_KEY_INTEGRATION_EVENT_BIND_DELETE, query.customerId, query.connectionId],
    eventBindId => deleteIntegrationEventBind(eventBindId, query),
    {
      retry: false,
      onSuccess: () => Promise.all([invalidateIntegrationEventBinds(), invalidateUserGet(query.customerId!)]),
    }
  );
}

export default useGetIntegrationEventBinds;
