import QRCode, { QRCodeToStringOptions } from 'qrcode';
import { string } from 'yup';
import strings from 'constants/localization';
import { createCodePlaceholder } from './codePlaceholder';
import { svgToString } from './svg';

const TEXT_SCHEMA = string()
  .required()
  .min(1)
  .max(1000);

const defaultOptions: QRCodeToStringOptions = {
  width: 500,
  margin: 1,
  color: {
    dark: '#000000ff',
    light: '#ffffffff',
  }
};

export default async function createQrCode(text: string, options?: QRCodeToStringOptions): Promise<string> {
  const opt: QRCodeToStringOptions = {
    ...defaultOptions,
    ...options,
  };

  try {
    const validText = await TEXT_SCHEMA.validate(text);

    return await QRCode.toString(validText as string, opt);
  } catch (e) {
    const width = opt?.width ?? 100;
    const svg = createCodePlaceholder(strings.errorEditorQrInvalidContent, width, width);

    return svgToString(svg);
  }
}
