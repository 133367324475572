import React, { useMemo } from 'react';
import { SingleValue, StylesConfig } from 'react-select';
import Select from 'components/library/Select';
import FontFaceObserver from 'fontfaceobserver';
import { fabric } from 'fabric';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import { fontsList } from 'components/PageArticles/components/Editor/helpers/fonts';

interface IOption {
  label: string,
  value: string,
}

const options: IOption[] = fontsList.map(x => ({ label: x, value: x }));

const customStyles: StylesConfig<IOption> = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
  option: (provided: any, state: any) => ({ ...provided, fontFamily: state.value }),
  singleValue: (provided, state) => ({ ...provided, fontFamily: state.data.value }),
};
const FontFamilySelector:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const option = useMemo<IOption>(() => options.find(({ value }) => value === activeObject.fontFamily) ?? options[0], [activeObject.fontFamily]);

  const handleChange = async (newValue: SingleValue<IOption>) => {
    if (activeObject && canvas.current && newValue) {
      // waiting for loading the font
      await new FontFaceObserver(newValue.value).load();

      activeObject.set({ fontFamily: newValue.value });
      activeObject.initDimensions();
      fabric.util.clearFabricFontCache(newValue.value);
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  return (
    <Select<IOption>
      isSearchable={false}
      menuPortalTarget={document.body}
      menuShouldBlockScroll
      options={options}
      value={option}
      onChange={handleChange}
      styles={customStyles}
    />
  );
};

export default FontFamilySelector;
