/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextCapitalize(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M20.0597 18C19.9592 17.712 19.8921 17.4133 19.8597 17.11C19.1897 17.81 18.3797 18.16 17.4497 18.16C16.6197 18.16 15.9297 17.92 15.3997 17.45C14.8697 17 14.5997 16.39 14.5997 15.66C14.5997 14.78 14.9297 14.1 15.5997 13.61C16.2697 13.12 17.2097 12.88 18.4297 12.88H19.8297V12.24C19.8297 11.75 19.6797 11.36 19.3797 11.07C19.0797 10.78 18.6297 10.64 18.0497 10.64C17.5297 10.64 17.0997 10.76 16.7497 11C16.3997 11.25 16.2297 11.54 16.2297 11.89H14.7697C14.7697 11.46 14.9197 11.05 15.2197 10.65C15.4997 10.25 15.9297 9.94 16.4397 9.71C16.9497 9.5 17.4997 9.36 18.1297 9.36C19.1097 9.36 19.8697 9.6 20.4197 10.09C20.9697 10.58 21.2597 11.25 21.2797 12.11V16C21.2797 16.8 21.3797 17.42 21.5797 17.88V18H20.0597ZM17.6597 16.88C18.1097 16.88 18.5397 16.77 18.9497 16.56C19.3497 16.35 19.6497 16.07 19.8297 15.73V14.16H18.6997C16.9297 14.16 16.0397 14.63 16.0397 15.57C16.0397 16 16.1897 16.3 16.4997 16.53C16.7997 16.76 17.1797 16.88 17.6597 16.88ZM5.45969 13.71H9.52969L7.49969 8.29L5.45969 13.71ZM6.63969 6H8.35969L13.0697 18H11.1397L10.1697 15.43H4.81969L3.85969 18H1.92969L6.63969 6Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextCapitalize;
