import {
  EProductTypes, TArticle, TArticleDocument, TPrintItemsItem
} from 'types';
import { DEFAULT_ARTICLE_STATUS } from 'constants/constants';
import strings from '../../../constants/localization';

const processCopy = (title:string, copy:boolean):string => {
  if (!copy) return title;
  return `${title} ${strings.articleTitleCopyMark}`;
};

// eslint-disable-next-line import/prefer-default-export
export const createArticleFromPrintItem = (printItem: TPrintItemsItem, customerId?: string, defaultData?: TArticle): TArticle => ({
  title: defaultData ? processCopy(defaultData.title, !!defaultData?.originalId) : '',
  skuIdTitle: defaultData ? processCopy(defaultData.skuIdTitle, !!defaultData?.originalId) : '',
  description: defaultData?.description || '',
  status: DEFAULT_ARTICLE_STATUS.value,
  currency: '€',
  customerId: customerId || '',
  size: defaultData?.size || null,
  frontSide: (defaultData?.frontSide || undefined) as TArticleDocument,
  backSide: (defaultData?.backSide || undefined) as TArticleDocument,
  selectedSizeComment: defaultData?.selectedSizeComment || '',
  paperComment: defaultData ? defaultData.paperComment : printItem.paperComment,
  printItemId: defaultData ? defaultData.printItem?.id : printItem.id,
  papers: defaultData?.papers || [],
  options: defaultData?.options || [],
  // @ts-ignore TODO remove ts-ignore
  printItem: defaultData?.printItem || printItem,
  id: '',
  productType: EProductTypes.STATIC_PRODUCT,
  pspId: defaultData?.pspId || '',
  quantities: defaultData?.quantities || [],
  selectedSizeId: defaultData?.selectedSizeId || '',
  createdAt: '',
  updatedAt: '',
  deletedAt: null,
  copyIndex: defaultData?.originalId ? defaultData.copyIndex || 0 : null,
  originalId: defaultData?.originalId || null,
});
