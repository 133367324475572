import strings from 'constants/localization';
import { EDITABLE_PERMISSIONS, ROLE_TO_STRINGS } from 'constants/constants';
import { isAdmin, isPrinter, capitalizePhrase } from 'helpers';
import {
  EIntegrationServices,
  EPermissions, ERoles, TUser,
} from 'types';
import { IAlertAction } from 'contexts/ContextAlert';
import { ITab } from '../library/Tabs';
import { checkPermission, EActions } from './permission';
import {
  COMPANY_INFO_FORM, DEFAULT_LANGUAGE_OPTION, ETabs, IFormsValues, PERSONAL_INFO_FORM,
  PROFILE_LANGUAGE_OPTIONS, TCompanyInfoDisabled, TFormCompanyKeys, TFormPersonalKeys,
} from './constants';

type TGetTabs = (userPermissions: EPermissions[], userRole: ERoles, isSelf: boolean, targetUserExists: boolean) => ITab<ETabs>[]
export const getTabs:TGetTabs = (userPermissions, userRole, isSelf, targetUserExists) => {
  if (!userPermissions) {
    return [];
  }
  const tabs:ITab<ETabs>[] = [
    { label: () => strings.profileTabPersonalInfo, value: ETabs.PERSONAL_INFO },
    { label: () => strings.profileTabCompanyInfo, value: ETabs.COMPANY_INFO },
  ];
  if (userRole === ERoles.MANAGER && checkPermission(EActions.VIEW_TAB_MANAGER_PERMISSIONS, userPermissions, userRole, isSelf)) {
    tabs.push({ label: () => strings.profileTabPermissions, value: ETabs.PERMISSIONS });
  }
  const [selfRole] = userPermissions;
  if (selfRole !== EPermissions.ADMIN) {
    tabs.push({ label: () => capitalizePhrase(strings.integration), value: ETabs.INTEGRATION, disabled: !targetUserExists });
  }
  return tabs;
};

export const getPageTitle = (targetRole:ERoles, isSelf:boolean):string => {
  if (isSelf) return strings.profilePageTitle;
  switch (targetRole) {
    case ERoles.ADMIN: return strings.profilePageTitleAdmin;
    case ERoles.PRINTER: return strings.profilePageTitlePrinter;
    case ERoles.MANAGER: return strings.profilePageTitleManager;
    case ERoles.CUSTOMER: return strings.profilePageTitleCustomer;
    default: return strings.profilePageTitle;
  }
};

export const getTabIndexByKey = (key:ETabs, tabs: ITab<ETabs>[]):number => tabs.findIndex(tab => tab.value === key);

export const getField = (key: string):string | null => {
  const personalFormKeys: TFormPersonalKeys[] = ['email', 'name', 'phoneNumber', 'surname'];
  const companyFormKeys: TFormCompanyKeys[] = ['companyName', 'position', 'sendOrderSheetsTo'];
  if (personalFormKeys.some(k => k === key)) {
    return `${PERSONAL_INFO_FORM}.${key}`;
  }
  if (companyFormKeys.some(k => k === key)) {
    return `${COMPANY_INFO_FORM}.${key}`;
  }
  return null;
};

export const getInitialValues = (user: TUser):IFormsValues => ({
  COMPANY_INFO_FORM: {
    companyName: user.companyName || '',
    position: user.position || '',
    sendOrderSheetsTo: user.sendOrderSheetsTo || ''
  },
  PERSONAL_INFO_FORM: {
    email: user.email || '',
    lang: PROFILE_LANGUAGE_OPTIONS.find(e => e.value === user.lang) || DEFAULT_LANGUAGE_OPTION,
    name: user.name || '',
    surname: user.surname || '',
    phoneNumber: user.phoneNumber || '',
  },
  PERMISSIONS_FORM: {
    [EPermissions.ASSIGN_MANAGERS]: user.userPermissions.includes(EPermissions.ASSIGN_MANAGERS),
    [EPermissions.CHANGE_ORDER_STATUS]: user.userPermissions.includes(EPermissions.CHANGE_ORDER_STATUS),
    [EPermissions.EDIT_MANAGER_CUSTOMERS]: user.userPermissions.includes(EPermissions.EDIT_MANAGER_CUSTOMERS),
    [EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS]: user.userPermissions.includes(EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS),
    [EPermissions.VIEW_MANAGER_CUSTOMERS]: user.userPermissions.includes(EPermissions.VIEW_MANAGER_CUSTOMERS),
  },
  SIDEBAR: {
    avatar: user.avatar || ''
  }
});

export const getActionMetaForRole = (role: ERoles):EActions => {
  const checker = {
    [ERoles.ADMIN]: EActions.EDIT_ADMIN_META,
    [ERoles.PRINTER]: EActions.EDIT_PRINTER_META,
    [ERoles.MANAGER]: EActions.EDIT_MANAGER_META,
    [ERoles.CUSTOMER]: EActions.EDIT_CUSTOMER_META,
  };
  return checker[role];
};

const VALID_USER_AVATAR_FILE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];
type TFileHandler = (
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void,
  fieldName: string,
  pushAlert: (action: Omit<IAlertAction, 'onClose'>) => void,
  ) => void
export const onFileInputHandler:TFileHandler = (setFieldValue, fieldName, pushAlert) => {
  const input = document.createElement('input');
  const eventListener = (e:Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const { type } = file;
      if (VALID_USER_AVATAR_FILE_TYPES.includes(type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result;
          setFieldValue(fieldName, base64String);
        };
        reader.onerror = () => {
          pushAlert({ severity: 'error', message: strings.profileSidebarInputFileCannotRead });
        };
      } else {
        pushAlert({ severity: 'error', message: strings.profileSidebarInputFileOnlyImagesAllowed });
      }
    }
    input.removeEventListener('change', eventListener);
  };
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.addEventListener('change', eventListener);
  input.click();
};

export interface IPrepareFormData {
  forms: IFormsValues,
  targetUser: TUser,
}
export const prepareFormDataToSave = (params: IPrepareFormData):TUser => {
  const { forms, targetUser } = params;
  const permissions = targetUser.userPermissions.filter(permission => EDITABLE_PERMISSIONS.includes(permission));
  Object.entries(forms.PERMISSIONS_FORM).forEach(([key, value]) => {
    if (value) permissions.push(key as EPermissions);
  });
  const user:TUser = {
    ...targetUser,
    userPermissions: permissions,
    ...forms.COMPANY_INFO_FORM,
    ...forms.PERSONAL_INFO_FORM,
    ...forms.SIDEBAR,
    lang: forms.PERSONAL_INFO_FORM.lang.value,
  };
  delete user.orderStats;
  delete user.articleStats;
  return user;
};

export const getBlockUserLabel = (
  blocked: TUser['blocked'],
  role: TUser['role']
): string => strings.formatString(strings.labelBlockUser, {
  block: blocked ? strings.actionLabelUnblock : strings.actionLabelBlock,
  role: ROLE_TO_STRINGS[role]().toLowerCase()
}) as string;

export const getRemoveUserLabel = (role: TUser['role']): string => strings.formatString(strings.labelRemoveUser, {
  remove: strings.actionLabelRemove,
  role: ROLE_TO_STRINGS[role]().toLowerCase()
}) as string;

export const getCompanyInfoDisabled = (permissions: EPermissions[]): TCompanyInfoDisabled => ({
  companyName: false,
  position: false,
  sendOrderSheetsTo: !isPrinter(permissions) && !isAdmin(permissions)
});

export const getIntegrationServiceName = (integrationService: EIntegrationServices): string => {
  switch (integrationService) {
    case EIntegrationServices.PAYLOGIC:
      return 'Paylogic';
    case EIntegrationServices.EVENTBRITE:
      return 'Eventbrite';
    case EIntegrationServices.CODE_KARUSSEL:
      return 'Rausgegangen';
    case EIntegrationServices.MANUAL:
      return 'Manual connection';
    case EIntegrationServices.API:
      return 'API';
    default:
      return integrationService;
  }
};
