import React, {
  useContext, useEffect, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikHelpers, useFormik } from 'formik';

import PageContent from 'components/library/PageContent';
import strings from 'constants/localization';
import Tabs from 'components/library/Tabs';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import AlertContext from 'contexts/ContextAlert';
import { TArticle, TUser } from 'types';
import { ROUTES } from 'constants/constants';
import useCreateArticle from 'hooks/Articles/useCreateArticle';
import { IResponseError } from 'constants/types';
import UserContext from 'contexts/ContextUser';
import useUpdateArticle from 'hooks/Articles/useUpdateArticle';
import Loader from 'components/library/Loader';
import ArticleContext from 'contexts/ContextArticle';
import FooterContent from './components/FooterContent';
import { ARTICLE_SCHEMES, IArticleFormValues } from './constants';
import css from './ArticleForm.module.css';
import {
  ARTICLE_FORM_TABS, getInitialValues, getRoutesExceptions, prepareFormDataToSave, setFirstTabWithErrors,
} from './helpers';
import TabProductInfo from './components/TabProductInfo';
import TabProductSetup from './components/TabProductSetup';
import TabOptionsSetup from './components/TabOptionsSetup';
import TabTemplate from './components/TabTemplate';

export interface IArticleForm {
  article: TArticle,
  create?: boolean,
  id?: string,
  customerId: string | undefined,
}
const ArticleForm: React.FC<IArticleForm> = ({
  id, article, create, customerId
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { push: pushAlert } = useContext(AlertContext);
  const user = useContext(UserContext).user as TUser;
  const articleContext = useContext(ArticleContext);
  const { tabIndex, setTabIndex } = articleContext;
  const onChangeTab = (e: React.SyntheticEvent, index: number) => setTabIndex(index);

  const createArticle = useCreateArticle(user.pspId);
  const updateArticle = useUpdateArticle(article.id, customerId, user.pspId, true);
  const handleSubmit = (values: IArticleFormValues, formikHelpers: FormikHelpers<IArticleFormValues>): void => {
    const articleToSave = prepareFormDataToSave({ values, article });
    const onMutationSuccess = () => {
      navigate(`${ROUTES.BASE}${ROUTES.ARTICLES}?customerId=${customerId}`);
    };
    const onMutationError = (err: IResponseError) => {
      pushAlert({
        message: err.response?.data.data?.reason || strings.errorResponse5,
        severity: 'error'
      });
    };
    const onMutationEnd = () => {
      formikHelpers.setSubmitting(false);
    };
    const mutateOptions = {
      onSuccess: onMutationSuccess,
      onError: onMutationError,
      onSettled: onMutationEnd
    };

    if (create) {
      createArticle.mutate(articleToSave, mutateOptions);
    } else {
      updateArticle.mutate(articleToSave, mutateOptions);
    }
  };

  const formik = useFormik<IArticleFormValues>({
    validationSchema: ARTICLE_SCHEMES(),
    initialValues: getInitialValues(
      article,
      create,
      articleContext.articleFormInitialValues
    ),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (create && id) {
      formik.setFieldValue('dirty', true);
    }
    if (!articleContext.articleFormInitialValues) {
      articleContext.setArticleFormInitialValues({ ...formik.initialValues });
    } else if (articleContext.articleFormValues) {
      formik.setValues(articleContext.articleFormValues);
    }
  }, []);

  const onClickSave = () => {
    setFirstTabWithErrors(formik.errors, setTabIndex);
    formik.handleSubmit();
  };

  useEffect(() => {
    articleContext.setArticleFormValues({ ...formik.values });
  }, [formik.values]);

  const routesExceptions: string[] = getRoutesExceptions(location.pathname);

  const isSaveDisabled = useMemo(() => !formik.dirty, [formik.dirty]);

  if (formik.isSubmitting) {
    return (
      <div className={css.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <PageContent
      pageTitle={strings.articlesAddEditPageTitle}
      withBreadcrumbs
      footerContent={(
        <FooterContent
          formik={formik}
          article={article}
          onClickSave={onClickSave}
          disableSave={isSaveDisabled}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          create={create}
        />
      )}
    >
      <div className={css.container}>
        <Tabs
          orientation="vertical"
          tabs={ARTICLE_FORM_TABS}
          value={tabIndex}
          onChange={onChangeTab}
        />
        <div className={css.tab}>
          {tabIndex === 0 && (
            <TabProductInfo
              formik={formik}
              article={article}
            />
          )}
          {tabIndex === 1 && (
            <TabProductSetup
              formik={formik}
              article={article}
              userRole={user.role}
            />
          )}
          {tabIndex === 2 && (
            <TabOptionsSetup
              formik={formik}
              article={article}
            />
          )}
          {tabIndex === 3 && (
            <TabTemplate
              customerId={customerId}
              formik={formik}
              article={article}
            />
          )}
        </div>
      </div>
      <UnsavedChangesModal
        areChangesExist={!formik.isSubmitting && formik.dirty}
        routesExceptions={routesExceptions}
      />
    </PageContent>
  );
};

export default ArticleForm;
