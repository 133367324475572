import React, {
  useContext, useEffect, useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import AlertContext from 'contexts/ContextAlert';
import PageContent from 'components/library/PageContent';
import { navigateBack } from 'helpers';
import {
  EditorContextProvider, IEditorContextData,
} from '../components/Editor/contexts/EditorContext';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer';
import Editor from '../components/Editor/Editor';
import ArticleContext from '../../../contexts/ContextArticle';

const TemplateEditorArticle: React.FC = () => {
  const { push } = useContext(AlertContext);
  const articleContext = useContext(ArticleContext);
  const {
    articleFormValues
  } = articleContext;
  const navigate = useNavigate();
  const location = useLocation();
  const state = useMemo<IEditorContextData>(() => location.state as IEditorContextData, [location.state]);

  // TODO: pass articleFormValues to ArticleContext using react-router state for applying on page refresh
  useEffect(() => {
    if (!state.pdfBox || !articleFormValues) {
      push({ severity: 'error', message: strings.errorEditorNeedToOpenUsingForm });
      navigateBack(navigate, `${ROUTES.BASE}${ROUTES.ARTICLES}`);
    }
  }, []);

  return (
    <EditorContextProvider data={state}>
      <PageContent pageTitle={strings.editorPageTitle} sidebarContent={<Sidebar />} footerContent={<Footer />}>
        <Editor />
      </PageContent>
    </EditorContextProvider>
  );
};

export default TemplateEditorArticle;
