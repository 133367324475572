import React from 'react';
import fabric from '../../../../../../../../components/Editor/helpers/fabric';

export interface ITextContent {
  object: typeof fabric.TextObject,
}

const TextContent: React.FC<ITextContent> = ({ object }) => object.text;

export default TextContent;
