import React from 'react';
import MuiTabs, { TabsProps, TabsClasses } from '@mui/material/Tabs';
import MuiTab, { TabClasses } from '@mui/material/Tab';
import cls from 'classnames';

import textCss from 'assets/styles/Text.module.css';
import { TLocalizedConstString } from 'types';
import css from './Tabs.module.css';

const tabsClasses:Partial<TabsClasses> = {
  root: css.root,
  indicator: css.indicator,
};
const tabClasses = (tabClass?:string):Partial<TabClasses> => ({
  root: cls(css.tabRoot, tabClass),
  selected: css.tabSelected,
  disabled: css.tabDisabled,
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface ITab<T> {
  // eslint-disable-next-line react/no-unused-prop-types
  value: T,
  label: TLocalizedConstString,
  count?: number,
  disabled?: boolean,
}

interface ITabs<T> extends TabsProps {
  tabs?: ITab<T>[],
  tabClass?: string,
}

export type TOnChange = (event: React.SyntheticEvent, newValue: number) => void;

function Label<T>({ label, count }:Omit<ITab<T>, 'disabled'>) {
  return Number.isInteger(count)
    ? (
      <span className={css.label}>
        <span>{label()}</span>
        <div className={cls(textCss.h5, css.count)}>{count}</div>
      </span>
    )
    : <span>{label()}</span>;
}

function Tabs<T>(props:ITabs<T>) {
  const {
    tabs, orientation, defaultValue = 0, tabClass, ...rest
  } = props;
  const [value, setValue] = React.useState(defaultValue);
  const handleChange:TOnChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MuiTabs value={value} onChange={handleChange} orientation={orientation} {...rest} classes={tabsClasses}>
      {tabs
        ? tabs.map(({ disabled = false, ...tab }, index) => (
          <MuiTab
            key={tab.label()}
            label={<Label<T> {...tab} />}
            classes={tabClasses(tabClass)}
            disabled={disabled}
            {...a11yProps(index)}
          />
        ))
        : null}
    </MuiTabs>
  );
}

export default React.memo(Tabs);
