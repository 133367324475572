import React, { useCallback, useMemo, useState } from 'react';
import cls from 'classnames';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';

import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import { TArticle } from 'types';
import Label from 'components/library/Label';
import Input from 'components/library/Input';
import ArrayCheckbox from 'components/library/Checkbox/ArrayCheckbox';
import Button from 'components/library/Button';
import {
  IFormOptionsSetupValues, IArticleFormValues, OPTIONS_SETUP_FORM, TFormikOptionComment, TFormikSelectedOptionValue,
} from '../../constants';
import css from './TabOptionsSetup.module.css';
import OptionalPricingTable from './components/OptionalPricingTable';
import { getOptionsSetupDisabled, getReadyOptionValuesStringArr, TOptionsSetupDisabled } from '../../helpers';

const getFieldName = (fieldName: keyof IFormOptionsSetupValues): string => `${OPTIONS_SETUP_FORM}.${fieldName}`;
export const getFieldError = (
  fieldName: keyof IFormOptionsSetupValues,
  touched?: FormikTouched<IFormOptionsSetupValues>,
  errors?: FormikErrors<IFormOptionsSetupValues>
): string | undefined => {
  const error = touched && touched[fieldName] && errors && errors[fieldName] as string;
  return error || undefined;
};

interface ITabOptionsSetup {
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
}
const TabOptionsSetup:React.FC<ITabOptionsSetup> = ({
  formik, article,
}) => {
  const {
    getFieldProps,
    setFieldValue,
    touched: { optionsSetup: touched },
    errors: { optionsSetup: errors },
    values: { optionsSetup: values }
  } = formik;
  const [isOptionalPricingOpen, setIsOptionalPricingOpen] = useState<boolean>(false);

  const checkboxValue = useMemo<string[]>(
    () => getReadyOptionValuesStringArr(values.selectedOptionValues),
    [values.selectedOptionValues]
  );

  const onSeeOptionalPricingClick = () => setIsOptionalPricingOpen(true);
  const closeOptionalPricingTable = () => setIsOptionalPricingOpen(false);
  const isSeeOptionalPricingDisabled: boolean = !values.selectedOptionValues.length;

  const getOptionCommentIndex = (optionId: string): number => values.optionComments
    .findIndex(({ optionId: id }) => id === optionId);

  const getOptionCommentName = (optionId: string): string => {
    const index = getOptionCommentIndex(optionId);
    return `${getFieldName('optionComments')}[${index}].comment`;
  };

  const getOptionCommentError = (optionId: string): string | undefined => {
    const index = getOptionCommentIndex(optionId);
    const error = touched?.optionComments?.[index]?.comment
      && (errors?.optionComments as FormikErrors<TFormikOptionComment>[] | undefined)?.[index]?.comment;
    return error || undefined;
  };

  const onChangeSelectedOptionValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, optionId: string) => {
      const { checked, value } = e.target;
      const { selectedOptionValues } = values;
      const fieldPath = 'optionsSetup.selectedOptionValues';
      if (checked) {
        setFieldValue(fieldPath, [...selectedOptionValues, { optionId, optionValueId: value } as TFormikSelectedOptionValue]);
      } else {
        setFieldValue(fieldPath, selectedOptionValues.filter(item => item.optionValueId !== value));
      }
    },
    [values.selectedOptionValues, article.printItem.options]
  );

  const disabled: TOptionsSetupDisabled | undefined = getOptionsSetupDisabled(article);

  return (
    <>
      <span className={cls(textCss.pLight1)}>{strings.articleFormTabOptionsSetup}</span>
      <form noValidate>
        {article.printItem.options.map(({ id, name, values: optionValues }) => (
          <div key={id} className={css.optionContainer}>
            <div className={cls(css.commentContainer, css.negativeMargin)}>
              <Label text={name} optional className={css.input} />
              <div className={css.input}>
                <Input
                  {...getFieldProps(getOptionCommentName(id))}
                  withError
                  error={getOptionCommentError(id)}
                  disabled={disabled?.optionComments[id]}
                  className={css.fullWidth}
                  label={strings.inputCommentLabel}
                  type="text"
                  placeholder={strings.inputCommentLabel}
                  labelProps={{ optional: true }}
                /> {/* TODO make comment field closable */}
              </div>
            </div>
            {optionValues.map(({ id: valueId, value, optionId }) => (
              <ArrayCheckbox
                key={valueId}
                {...getFieldProps(getFieldName('selectedOptionValues'))}
                valueId={valueId}
                disabled={disabled?.selectedOptionValues[valueId]}
                label={value}
                wrapperClassName={css.optionValueCheckbox}
                value={checkboxValue}
                withError
                error={getFieldError('selectedOptionValues', touched, errors)}
                onChange={e => onChangeSelectedOptionValue(e, optionId as string)}
              />
            ))}
          </div>
        ))}
        <Button
          buttonType="primary"
          onClick={onSeeOptionalPricingClick}
          disabled={isSeeOptionalPricingDisabled}
          className={css.seeOptionalPricingButton}
        >
          {strings.articleFormOptionsSetupTabSeeOptionalPricingButtonLabel}
        </Button>
        <OptionalPricingTable
          isOpen={isOptionalPricingOpen}
          closeModal={closeOptionalPricingTable}
          article={article}
          formik={formik}
        />
      </form>
    </>
  );
};

export default TabOptionsSetup;
