import React, { useMemo } from 'react';
import Loader from 'components/library/Loader';
import strings from 'constants/localization';
import css from './UploadingLoader.module.css';

export interface IUploadingLoader {
  status: boolean | string,
}

const UploadingLoader:React.FC<IUploadingLoader> = props => {
  const {
    status
  } = props;

  const text = useMemo<string>(() => (typeof status === 'string') ? status.toString() : strings.editorLoaderStatus, [status]);

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <div className={css.description}>{ text }</div>
        <Loader className={css.loader} />
      </div>
    </div>
  );
};

export default UploadingLoader;
