import React, {
  ChangeEvent, useCallback, useContext, useEffect, useRef, useState
} from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Error from 'components/library/Error';
import Input from 'components/library/Input';
import Loader from 'components/library/Loader';
import Button from 'components/library/Button';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import PageContent from 'components/library/PageContent';
import FilterHeader from 'components/library/Table/components/FilterHeader';

import { TPrintItemsItem } from 'types';
import { IGetPrintItemsParams } from 'types/Portfolio';
import { TSort } from 'components/library/Table/types';
import strings from 'constants/localization';
import { MIN_PAGE, ROUTES, TITLE_ORDER_OPTIONS } from 'constants/constants';
import { useGetInfinitePrintItems } from 'hooks/Portfolio/useGetPrintItems';
import UserContext from 'contexts/ContextUser';
import ArticleContext from 'contexts/ContextArticle';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import css from './SelectPrintItem.module.css';
import SideBarContent from './SideBarContent';
import PrintItemCard from './PrintItemCard';
import FooterContent from './FooterContent';
import UnsavedArticleFormValuesModal from './UnsavedArticleFormValuesModal';
import { areArticleFormValuesEqualInitial } from './helpers';
import { ROUTES_EXCEPTIONS } from './constants';

const SelectPrintItem: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useContext(UserContext);
  const articleContext = useContext(ArticleContext);

  const [printItem, setPrintItem] = useState<TPrintItemsItem | null>(articleContext.selectedPrintItem);
  const [search, setSearch] = useState<string>('');
  const [searchActive, setSearchActive] = useState<boolean>(true);
  const [page, setPage] = useState<number>(MIN_PAGE);
  const [sort, setSort] = useState<TSort>(TITLE_ORDER_OPTIONS[2].value);
  const [isUnsavedValuesModalOpen, setIsUnsavedValuesModalOpen] = useState<boolean>(false);

  const anchorElement = useRef<null | HTMLDivElement>(null);

  const {
    data = { pages: [], pageParams: [] },
    isLoading,
    error,
    fetchNextPage,
    hasNextPage
  } = useGetInfinitePrintItems({
    pspId: user?.pspId,
    includeBase: true,
    search,
    sort,
  } as IGetPrintItemsParams);

  const handleObserver = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const first = entries[0];
      if (first.isIntersecting && hasNextPage) {
        const nextPage = page + 1;
        await fetchNextPage({ pageParam: nextPage });
        setPage(nextPage);
      }
    },
    [hasNextPage, isLoading, page]
  );

  const toggleSearchActive = () => {
    setSearchActive(prev => !prev);
  };

  const handleSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    setPage(MIN_PAGE);
  };

  const handleSortChange = (value: TSort) => {
    setSort(value);
    setPage(MIN_PAGE);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 1 });
    const currentElement = anchorElement.current;
    if (currentElement) {
      observer.observe(currentElement);
      return () => {
        observer.unobserve(currentElement);
      };
    }
  }, [handleObserver, page]);

  const onCloseUnsavedValuesModal = (): void => {
    setIsUnsavedValuesModalOpen(false);
    setPrintItem(articleContext.selectedPrintItem);
  };

  const navigateToArticleForm = (): void => {
    if (articleContext.selectedPrintItem !== printItem) {
      articleContext.setArticleFormInitialValues(null);
      articleContext.setArticleFormValues(null);
      articleContext.setTabIndex(0);
    }
    articleContext.setSelectedPrintItem(printItem);
    navigate(`${ROUTES.BASE}${ROUTES.ARTICLES}/${ROUTES.ROUTE_ADD}${location.search}`);
  };

  const areChangesExist: boolean = !areArticleFormValuesEqualInitial(
    articleContext.articleFormValues,
    articleContext.articleFormInitialValues
  );

  if (error) {
    return (
      <Error
        code={error.status}
        title={error.response?.data.className}
        description={error.response?.data.data?.reason || error.response?.data.message}
      />
    );
  }
  return (
    <PageContent
      pageTitle={strings.articlesSelectPrintItemPageTitle}
      sidebarContent={<SideBarContent printItem={printItem} />}
      footerContent={(
        <FooterContent
          printItem={printItem}
          setIsUnsavedValuesModalOpen={setIsUnsavedValuesModalOpen}
          navigateToArticleForm={navigateToArticleForm}
        />
      )}
    >
      <div className={css.container}>
        <div className={css.filtersBlock}>
          <Input
            searchActive={searchActive}
            value={search}
            placeholder={strings.inputSearchTextPlaceholder}
            onChange={handleSearchTextChange}
            toggleSearchActive={toggleSearchActive}
          />
          <FilterHeader
            title=""
            className={css.sort}
            orderOptions={TITLE_ORDER_OPTIONS}
            order={sort}
            setOrder={handleSortChange}
          />
        </div>
        {isLoading
          ? <div className={css.loaderContainer}><Loader /></div>
          : (
            <div className={css.items}>
              {!!data.pages.length && data.pages.map(mappedPage => mappedPage.data.map(item => (
                <PrintItemCard
                  key={item.id}
                  printItem={item}
                  selectedPrintItemId={printItem?.id}
                  handleSelectPrintItem={setPrintItem}
                />
              )))}
              <div ref={anchorElement} />
            </div>
          )}
        {!isLoading && data?.pages?.every(mappedPage => mappedPage.data?.length === 0) && (
          <EmptyState
            label={search ? strings.emptyStateTitleNoResults : strings.emptyStateNoPrintItemsTitle}
            description={search ? strings.emptyStateTitleNoResultsDescription : strings.emptyStateNoPrintItemsDescription}
            variant={EmptyStates.NO_RESULTS}
          >
            {!search && (
              <NavLink to={`${ROUTES.BASE}${ROUTES.PORTFOLIO}/${ROUTES.ROUTE_ADD}`}>
                <Button
                  type="button"
                  buttonType="secondary"
                >{strings.portfolioPageButtonAddPrintItem}</Button>
              </NavLink>
            )}
          </EmptyState>
        )}
      </div>
      <UnsavedArticleFormValuesModal
        isOpen={isUnsavedValuesModalOpen}
        onCloseModal={onCloseUnsavedValuesModal}
        onOk={navigateToArticleForm}
      />
      <UnsavedChangesModal
        areChangesExist={areChangesExist}
        routesExceptions={ROUTES_EXCEPTIONS}
      />
    </PageContent>
  );
};

export default SelectPrintItem;
