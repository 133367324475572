import React from 'react';
import cls from 'classnames';

import { EArticleStatuses } from 'types';
import { ARTICLE_STATUSES_LOCALIZATION } from 'constants/constants';
import textCss from 'assets/styles/Text.module.css';
import css from './ArticlesStatus.module.css';

interface IArticlesStatus {
  status: EArticleStatuses,
  isTableView?: boolean,
}

const ArticlesStatus: React.FC<IArticlesStatus> = ({ status, isTableView }) => (
  <div
    className={cls(
      css.statusLabel,
      textCss.pMedium3,
      status === EArticleStatuses.PUBLISHED ? css.published : css.draft,
      {
        [css.centerContent]: isTableView,
      }
    )}
  >
    {ARTICLE_STATUSES_LOCALIZATION[status]()}
  </div>
);

export default ArticlesStatus;
