import {
  DependencyList,
  EffectCallback,
  useRef,
  useEffect,
} from 'react';

const useEffectOnce = (callback: EffectCallback, when: DependencyList) => {
  const hasRunOnce = useRef(false);
  useEffect(() => {
    if (when && !hasRunOnce.current) {
      callback();
      hasRunOnce.current = true;
    }
  }, [when]);
};

export default useEffectOnce;
