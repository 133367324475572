import React, { useMemo } from 'react';
import cls from 'classnames';
import * as yup from 'yup';

import textCss from 'assets/styles/Text.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES_LOCALIZATION, ROUTES } from 'constants/constants';
import { EPathKeyType } from 'types';
import strings from 'constants/localization';
import css from './Breadcrumbs.module.css';
import Icon, { ICON_TYPES } from '../Icon';

const schema = yup.object().shape({
  uuid: yup.string().uuid()
});

interface IPath {
  path: string,
  titleKey: string,
  active: boolean,
}
const Path:React.FC<IPath> = ({ active, path, titleKey }) => (
  <NavLink to={path} className={cls(css.path, active ? textCss.h5 : textCss.pMedium3)}>{titleKey}</NavLink>
);

const Delimiter:React.FC<{}> = () => (
  <div className={css.delimiter}>
    <Icon type={ICON_TYPES.arrowUp} className={css.delimiterIcon} />
  </div>
);

const getPathTitle = (pathKey: string, index: number, pathArr: string[]): string => {
  let pathTitle: string;
  let pathKeyType: EPathKeyType;
  if (schema.isValidSync({ uuid: pathKey })) {
    pathKeyType = EPathKeyType.EDIT;
    pathTitle = pathArr[index - 1];
  } else if (pathKey === ROUTES.ROUTE_ADD) {
    pathKeyType = EPathKeyType.ADD;
    pathTitle = pathArr[index - 1];
  } else {
    pathKeyType = EPathKeyType.LIST;
    pathTitle = pathKey;
  }
  return ROUTES_LOCALIZATION[pathTitle]?.[pathKeyType]?.() || pathTitle;
};

interface IBreadcrumbs extends React.HTMLProps<HTMLDivElement> {}
const Breadcrumbs:React.FC<IBreadcrumbs> = props => {
  const { className, ...rest } = props;
  const location = useLocation();
  const paths = useMemo<IPath[]>(() => {
    const values:IPath[] = location.pathname
      .split('/')
      .slice(1)
      .map((pathKey, index, arr) => {
        const pathTitle: string = getPathTitle(pathKey, index, arr);
        return {
          titleKey: pathTitle,
          path: `/${arr.slice(0, index + 1).join('/')}${location.search}`,
          active: index === arr.length - 1
        };
      });
    return values;
  }, [location.pathname, location.search]);

  return (
    <div className={cls(css.breadcrumbs, className)} {...rest}>
      {paths.map((path, index, arr) => (
        <React.Fragment key={path.path}>
          <Path {...path} />
          {index < arr.length - 1 && <Delimiter />}
        </React.Fragment>
      ))}
    </div>
  );
};

const BreadcrumbsContainer:React.FC<IBreadcrumbs> = props => {
  const currentLanguage = strings.getLanguage();
  return useMemo(
    () => <Breadcrumbs key={currentLanguage} {...props} />,
    [props, currentLanguage]
  );
};

export default BreadcrumbsContainer;
