/* eslint-disable max-len */
import { ILanguages } from '../types';
import IOrdersString from '../types/OrdersStrings';

const strings:ILanguages<IOrdersString> = {
  en: {
    ordersPageTitle: 'Orders',

    ordersPageColumnId: 'Order ID',
    ordersPageColumnStatus: 'Status',
    ordersPageColumnCost: 'Cost',
    ordersPageColumnCreatedAt: 'Created at',
    ordersPageColumnCustomer: 'Event',
    ordersPageColumnManager: 'Manager',

    ordersPageStatusAll: 'All',

    orderPageTitleOrder: 'Order',
    orderPageTitleAdd: 'Add to Order',

    orderPageFormItemOptionsTitle: 'Item Options',
    orderPageFormPricingTitle: 'Pricing',
    orderPageFormPricingQuantity: 'Quantity',
    orderPageFormPricingTotalPrice: 'Total price',
    orderPageFormProductDescriptionTitle: 'Product Description',
    orderPageFormProductDescOrderId: 'Order ID',
    orderPageFormProductDescSkuId: 'SKU ID',
    orderPageFormProductDescPrintType: 'Print type',
    orderPageFormProductDescCorners: 'Corners',
    orderPageFormProductDescPaper: 'Paper',
    orderPageFormProductDescSize: 'Size',
    orderPageFormProductDescShippingMethod: 'Free Local Delivery',
    orderPageFormProductDescProductType: 'Product Type',
    orderPageFormProductDescTicketTypeName: 'Ticket Type',
    orderPageFormProductDescProductName: 'Product Name',
    orderPageFormItemComment: 'Item Comment',
    orderPageViewBacksideTitle: 'Back side',
    orderPageViewFrontsideTitle: 'Front side',
    orderPageViewNoBacksideTitle: 'This product does not contain this side',
    orderPageViewRejectOrder: 'Reject Order',
    orderPageUploadMultipleItems: 'Multiple Items',

    orderPageModalTitleUploadItemsExists: 'Change the data',
    orderPageModalContentUploadItemsExists: 'You already have uploaded data if you upload new the previous will be lost. Continue?',
    orderPageModalUploadListTitle: 'Multiple items',
    orderPageModalUploadListDescription: 'To add multiple items, you need to upload a spreadsheet.',
    orderPageModalUploadListUploadAreaText: 'Choose a file or drag it here.',
    orderPageModalUploadListUploadAreaActive: 'Drop the file here...',
    orderPageModalUploadListUploadAreaReject: 'Invalid file type',
    orderPageModalUploadListFileRequirements: 'File requirements',
    orderPageModalUploadListFileRequirementsList: `Supported file formats: <b>.CSV, .XLSX</b>
      Spreadsheet cell content: <b>text, image</b>
      Preferable: All the images in the imported .xslx file should have the same width and height in pixels as the initial image from the SKU  
    `,
    orderPageModalUploadListStepsTitle: 'Step {0} of {1}',
    orderPageModalUploadFileLabel: 'File:',
    orderPageModalUploadFileMatchTableDescription: 'Match your data via the Option drop down to the Title on the product and check the sample data shown.',
    orderPageModalUploadFileMatchTableColumnSideHeader: 'Side',
    orderPageModalUploadFileMatchTableColumnTitleHeader: 'Title',
    orderPageModalUploadFileMatchTableColumnColumnHeader: 'Column',
    orderPageModalUploadFileMatchTableColumnColumnSelectPlaceholder: 'Select column',
    orderPageModalUploadFileMatchTableColumnSampleDataHeader: 'Sample data',
    orderPageModalUploadFileGeneratedColumnName: '#{0}', // column name
    orderPageModalUploadFileRowsRead: 'Expected number of items: {0}. (Rows in file)', // rows in file
    orderPageModalUploadListUploadAreaTextLoading: 'File is processing...',
    orderPageNotificationUpdateStatus: 'Status updated successfully',

    orderPageModalRejectOrderTitle: 'Reject Order',
    orderPageModalRejectOrderQuestion: 'Do you really want to reject this Order?',
    orderPageModalRejectOrderReasonFieldLabel: 'Reason',
    orderPageReasonOfRejection: 'Reason of rejection:\n"{0}"',

    orderPageFooterButtonGoToCheckout: 'Go to Checkout',
    orderPageNotReadyTooltip: 'You cannot change the status of the order until the order sheet has been processed.',
    orderPageModalItemsImageType: 'Image field',
    orderPageModalItemsTextType: 'Text field',
    orderPageModalItemsNumberingType: 'Pagination',
    orderPageModalItemsBarCodeType: 'Barcode field',
    orderPageModalItemsQRCodeType: 'QR code field',
    orderPageEmptyStateDescription: 'To get started, add some {0} to event orders.',

    orderPageDpiNotificationWarning: 'DPI is less than 300, it\'s better to use a different image.',
    orderPageDpiNotificationError: 'DPI is less than 150, please upload another image.',
    orderPageDpiExelNotificationError: 'Some images had poor quality less than 150 and were removed from pdf. Please upload another file.',
    orderPageDpiExelNotificationWarning: 'Some pictures have unsatisfactory quality, which may affect the output PDF. Please upload another file.',
    orderPageDpiExelNotificationAttention: 'Some images had poor quality less than 150 and were removed from pdf. Some pictures have unsatisfactory quality, which may affect the output PDF. Please upload another file.',
    orderPageDpiExelNotificationSuccess: 'File was successfully uploaded!',

    orderPageOf: 'of',

    orderModalSummaryTitle: 'Order summary',
    orderModalSummaryDescription: 'If you press “Place order” you have to pay for this product.',
    orderModalSummaryItemsCountSingular: '{0} variant',
    orderModalSummaryItemsCountPlural: '{0} variants',
    orderModalSummaryOkActionText: 'Place Order',

    orderPlaceOrderButton: 'Place Order',

    orderPlaceOrderModalEventTabTitle: 'Place Order',

    orderPlaceOrderModalEventTabEventName: 'Event name',
    orderPlaceOrderModalEventTabSelectEvent: 'Select event',

    orderPlaceOrderModalEventTabSelectEventSkuAvailable: 'This event has {0} SKU',
    orderPlaceOrderModalEventTabSelectEventNoSku: 'There is no bound SKU',

    orderPlaceOrderModalFileTabTitle: 'Upload spreadsheet',
    orderPlaceOrderModalFileTabDescription: 'Upload the spreadsheet to be able to place order manually with this SKU',

    orderPlaceOrderModalSummaryTabTitle: 'Order summary',
    orderPlaceOrderModalSummaryTabDescription: 'If you press “Place order” you have to pay for this product.',

    orderPlaceOrderModalSummaryTabOrders: 'Number of Orders',
    orderPlaceOrderModalSummaryTabTicketTypes: 'Number of Ticket Types',
    orderPlaceOrderModalSummaryTabTickets: 'Number of Tickets',

    orderPlaceOrderModalPlaceTabTitle: 'Order processing',
    orderPlaceOrderModalPlaceTabDescription: 'Please wait, we are processing your orders',

    orderPlaceOrderModalSuccess: 'The order was successfully placed.\nYour order will be displayed in the list within a few minutes.',
    orderPlaceOrderModalError: 'Something went wrong with your order',
  },
  de: {
    ordersPageTitle: 'Auftragsübersicht',

    ordersPageColumnId: 'Auftragsnummer',
    ordersPageColumnStatus: 'Status',
    ordersPageColumnCost: 'Preis',
    ordersPageColumnCreatedAt: 'Bestellt am',
    // TODO: required translation
    // ordersPageColumnCustomer: '',
    ordersPageColumnManager: 'Veranstalter',

    ordersPageStatusAll: 'Alle',

    orderPageTitleOrder: 'Auftragsdetails',
    orderPageTitleAdd: 'Auftrag anlegen',

    orderPageFormItemOptionsTitle: 'Zusatzoptionen',
    orderPageFormPricingTitle: 'Kosten',
    orderPageFormPricingQuantity: 'Auflage',
    orderPageFormPricingTotalPrice: 'Gesamtpreis',
    orderPageFormProductDescriptionTitle: 'Produktparameter',
    orderPageFormProductDescOrderId: 'Auftragsnummer',
    orderPageFormProductDescSkuId: 'Artikelnummer',
    orderPageFormProductDescPrintType: 'Artikelart',
    orderPageFormProductDescCorners: 'Gerundete Ecken',
    orderPageFormProductDescPaper: 'Papier',
    orderPageFormProductDescSize: 'Format',
    orderPageFormProductDescShippingMethod: 'Kostenfreie Zustellung',
    orderPageFormProductDescProductType: 'Produktart',
    orderPageFormProductDescProductName: 'Produktname',
    orderPageFormItemComment: 'Beschreibung',
    orderPageViewBacksideTitle: 'Rückseite',
    orderPageViewFrontsideTitle: 'Vorderseite',
    orderPageViewNoBacksideTitle: 'Dieses Produkt hat keine Rückseite',
    orderPageViewRejectOrder: 'Auftrag ablehnen',
    orderPageUploadMultipleItems: 'Datensätze hochladen',

    orderPageModalTitleUploadItemsExists: 'Vorhandene Daten überschreiben',
    orderPageModalContentUploadItemsExists: 'Die zuvor hochgeladenen Datensätze werden durch die neuen Daten ersetzt. Wollen Sie fortfahren?',
    orderPageModalUploadListTitle: 'Massenhafte Individualisierung',
    orderPageModalUploadListDescription: 'Laden Sie zunächst eine Daten-Datei hoch. Im nächsten Schritt können Sie die Daten-Spalten passend zur Vorlage zuordnen.',
    orderPageModalUploadListUploadAreaText: 'Ziehen Sie eine Daten-Datei hier her oder klicken Sie hier um eine Datei auf ihrer Festplatte zu suchen.',
    orderPageModalUploadListUploadAreaActive: 'Lassen Sie die Datei hier fallen...',
    orderPageModalUploadListUploadAreaReject: 'Ungültiges Dateiformat',
    orderPageModalUploadListFileRequirements: 'Zulässige Dateiformate',
    orderPageModalUploadListFileRequirementsList: 'Excel (.xlsx) oder CSV (.csv, .txt)',
    orderPageModalUploadListStepsTitle: 'Schritt {0} von {1}',
    orderPageModalUploadFileLabel: 'Datei:',
    orderPageModalUploadFileMatchTableDescription: 'In dieser Übersicht können Sie die Inhalte der Datei-Datei den verfügbaren Textrahmen in der Vorlage zuweisen. Auf der linken Seite der Tabelle sehen Sie die Textfelder auf der Vorder- und Rückseite (falls vorhanden) der Vorlage. Auf der rechten Seite können Sie mit der jeweiligen Auswahlliste die zu dem Textfeld passende Spalte aus ihrer Tabelle auswählen. Ganz rechts sehen Sie einen Beispielswert aus ihrer Datei und können die korrekte Zuordnung überprüfen.\n'
      + 'Hinweis: Es ist möglich eine Spalte mehr als einem Textfeld zuzuweisen.',
    orderPageModalUploadFileMatchTableColumnSideHeader: 'Seite',
    orderPageModalUploadFileMatchTableColumnTitleHeader: 'Feldbezeichnung',
    orderPageModalUploadFileMatchTableColumnColumnHeader: 'Spaltentitel',
    orderPageModalUploadFileMatchTableColumnColumnSelectPlaceholder: 'Spalte auswählen',
    orderPageModalUploadFileMatchTableColumnSampleDataHeader: 'Beispieldaten',
    orderPageModalUploadFileGeneratedColumnName: 'Spalte {0}', // column name
    orderPageModalUploadFileRowsRead: 'Er wurden {0} Zeilen in der Datei gefunden', // rows in file
    orderPageModalUploadListUploadAreaTextLoading: 'Die Datensätze werden verarbeitet...',
    orderPageNotificationUpdateStatus: 'Die Datensätze wurden erfolgreich verarbeitet.',

    orderPageModalRejectOrderTitle: 'Auftrag ablehnen',
    orderPageModalRejectOrderQuestion: 'Möchten Sie den Auftrag wirklich ablehnen?',
    orderPageModalRejectOrderReasonFieldLabel: 'Begründung',
    orderPageReasonOfRejection: 'Grund für Auftragsablehnung:\n"{0}"',

    orderPageFooterButtonGoToCheckout: 'Zur Kasse',
    orderPageNotReadyTooltip: 'Sie können den Status des Auftrags nicht ändern, da die PDF-Druckdaten noch nicht fertiggestellt sind. Versuchen Sie es in ein paar Minuten noch einmal.',
    orderPageModalItemsImageType: 'Bild-Rahmen',
    orderPageModalItemsTextType: 'Text-Rahmen',
    orderPageModalItemsBarCodeType: 'Barcode-Rahmen',
    orderPageModalItemsQRCodeType: 'QR-Code-Rahmen',
    orderPageEmptyStateDescription: 'Prüfen Sie in der {0}, ob alle Artikel richtig und vollständig angelegt wurden und veröffentlicht sind.',

    orderPageDpiNotificationWarning: 'Das Bild hat weniger als 300 dpi, dies kann zu einer schlechten Druckqualität führen. Verwenden Sei ein Bild mit einer größeren Anzahl von Pixeln bzw. einer höheren Auflösung.',
    orderPageDpiNotificationError: 'Das Bild hat weniger als 150 dpi. Sie müssen ein größeres Bild (höhere Auflösung) hochladen.',

    orderPageOf: 'von',

    orderModalSummaryTitle: 'Bestell-Übersicht',
    orderModalSummaryDescription: 'Mit dem Klick auf ‘Kaufen’ erstellen Sie eine verbindliche Bestellung des angezeigten Artikels',
    orderModalSummaryItemsCountSingular: '{0} Variante',
    orderModalSummaryItemsCountPlural: '{0} Varianten',
    orderModalSummaryOkActionText: 'Kaufen',
  },
  ru: {
    ordersPageTitle: 'Заказы',

    ordersPageColumnId: 'ID Заказа',
    ordersPageColumnStatus: 'Статус',
    ordersPageColumnCost: 'Цена',
    ordersPageColumnCreatedAt: 'Дата создания',
    ordersPageColumnCustomer: 'Мероприятие',
    ordersPageColumnManager: 'Менеджер',

    ordersPageStatusAll: 'Все',

    orderPageTitleOrder: 'Заказ',
    orderPageTitleAdd: 'Создание заказа',

    orderPageFormItemOptionsTitle: 'Опции заказа',
    orderPageFormPricingTitle: 'Цена',
    orderPageFormPricingQuantity: 'Тираж',
    orderPageFormPricingTotalPrice: 'Итоговая цена',
    orderPageFormProductDescriptionTitle: 'Описание товара',
    orderPageFormProductDescOrderId: 'ID заказа',
    orderPageFormProductDescSkuId: 'ID артикула',
    orderPageFormProductDescPrintType: 'Тип печати',
    orderPageFormProductDescCorners: 'Углы',
    orderPageFormProductDescPaper: 'Бумага',
    orderPageFormProductDescSize: 'Формат',
    orderPageFormProductDescShippingMethod: 'Бесплатная доставка',
    orderPageFormProductDescProductType: 'Тип продукта',
    orderPageFormProductDescTicketTypeName: 'Тип билета',
    orderPageFormProductDescProductName: 'Название продукта',
    orderPageFormItemComment: 'Примечание к заказу',
    orderPageViewBacksideTitle: 'Задняя сторона',
    orderPageViewFrontsideTitle: 'Передняя сторона',
    orderPageViewNoBacksideTitle: 'У этого продукта нет задней стороны',
    orderPageViewRejectOrder: 'Отклонить заказ',
    orderPageUploadMultipleItems: 'Загрузить данные',

    orderPageModalTitleUploadItemsExists: 'Изменение данных',
    orderPageModalContentUploadItemsExists: 'Ранее добавленные данные будут заменены новыми. Вы хотите продолжить? ',
    orderPageModalUploadListTitle: 'Загрузить данные',
    orderPageModalUploadListDescription: 'Чтобы добавить новые элементы, вам необходимо загрузить электронную таблицу.',
    orderPageModalUploadListUploadAreaText: 'Выберите файл или перетащите его сюда.',
    orderPageModalUploadListUploadAreaActive: 'Перетащите файл сюда...',
    orderPageModalUploadListUploadAreaReject: 'Недопустимый тип файла',
    orderPageModalUploadListFileRequirements: 'Требования к файлу',
    orderPageModalUploadListFileRequirementsList: 'Допустимые типы файлов: .XLSX или .CSV',
    orderPageModalUploadListStepsTitle: 'Шаг {0} из {1}',
    orderPageModalUploadFileLabel: 'Файл:',
    orderPageModalUploadFileMatchTableDescription: 'Сопоставьте свои данные с помощью выпадающего списка с названием элементов и проверьте приведенные данные в образце.',
    orderPageModalUploadFileMatchTableColumnSideHeader: 'Сторона',
    orderPageModalUploadFileMatchTableColumnTitleHeader: 'Название поля',
    orderPageModalUploadFileMatchTableColumnColumnHeader: 'Заголовок столбца',
    orderPageModalUploadFileMatchTableColumnColumnSelectPlaceholder: 'Выберите столбец',
    orderPageModalUploadFileMatchTableColumnSampleDataHeader: 'Образец',
    orderPageModalUploadFileGeneratedColumnName: 'Столбец {0}', // column name
    orderPageModalUploadFileRowsRead: 'Ожидаемое количество элементов: {0}. (Строки в файле)', // rows in file
    orderPageModalUploadListUploadAreaTextLoading: 'Файл обрабатывается...',
    orderPageNotificationUpdateStatus: 'Статус обновлен успешно',

    orderPageModalRejectOrderTitle: 'Отклонить заказ',
    orderPageModalRejectOrderQuestion: 'Вы действительно хотите отклонить этот заказ?',
    orderPageModalRejectOrderReasonFieldLabel: 'Причина',
    orderPageReasonOfRejection: 'Причина отклонения заказа:\n"{0}"',

    orderPageFooterButtonGoToCheckout: 'Оформить заказ',
    orderPageNotReadyTooltip: 'Вы не можете изменить статус заказа до тех пор, пока задание на печать не будет обработано.',
    orderPageModalItemsImageType: 'Поле изображения',
    orderPageModalItemsTextType: 'Поле текста',
    orderPageModalItemsNumberingType: 'Нумерация',
    orderPageModalItemsBarCodeType: 'Поле штрих-кода',
    orderPageModalItemsQRCodeType: 'Поле QR-кода',
    orderPageEmptyStateDescription: 'Чтобы начать, добавьте {0}ы в заказ',

    orderPageDpiNotificationWarning: 'Разрешение изображения составляет менее 300 точек на дюйм. Для хорошего качества печати следует использовать изображение большего размера (более высокое значение dpi).',
    orderPageDpiNotificationError: 'Разрешение изображения составляет менее 150 точек на дюйм. Вы должны загрузить изображение большего размера.',
    orderPageDpiExelNotificationError: 'Некоторые изображения имеют низкое качество менее 150 и были удалены из pdf. Пожалуйста, загрузите другой файл.',
    orderPageDpiExelNotificationWarning: 'Некоторые из изображений не соответствуют качеству, что может повлиять на качество итогового PDF. Пожалуйста, загрузите другой файл.',
    orderPageDpiExelNotificationAttention: 'Некоторые изображения имеют низкое качество менее 150 и были удалены из pdf. Некоторые изображения имеют неудовлетворительное качество, что может повлиять на итоговый PDF-файл. Пожалуйста, загрузите другой файл.',
    orderPageDpiExelNotificationSuccess: 'Файл был успешно загружен!',

    orderPageOf: 'из',

    orderModalSummaryTitle: 'Описание заказа',
    orderModalSummaryDescription: 'Если вы нажмете “Оформить заказ”, вам придется оплатить этот товар.',
    orderModalSummaryItemsCountSingular: '{0} вариант',
    orderModalSummaryItemsCountPlural: '{0} варианты',
    orderModalSummaryOkActionText: 'Сделать заказ',

    orderPlaceOrderButton: 'Разместить заказ',

    orderPlaceOrderModalEventTabTitle: 'Разместить заказ',
    orderPlaceOrderModalEventTabEventName: 'Название мероприятия',
    orderPlaceOrderModalEventTabSelectEvent: 'Выбрать мероприятие',

    orderPlaceOrderModalEventTabSelectEventSkuAvailable: 'У этого ивента есть {0} привязанных SKU',
    orderPlaceOrderModalEventTabSelectEventNoSku: 'У этого ивента нет привязанных SKU',

    orderPlaceOrderModalFileTabTitle: 'Загрузить таблицу',
    orderPlaceOrderModalFileTabDescription: 'Загрузите таблицу, чтобы иметь возможность вручную разместить заказ с этим артикулом',

    orderPlaceOrderModalSummaryTabTitle: 'Описание заказа',
    orderPlaceOrderModalSummaryTabDescription: 'Нажимая «Оформить заказ», вы соглашаетесь с условиями оплаты.',

    orderPlaceOrderModalSummaryTabOrders: 'Количество заказов',
    orderPlaceOrderModalSummaryTabTicketTypes: 'Количество типов билетов',
    orderPlaceOrderModalSummaryTabTickets: 'Количество билетов',

    orderPlaceOrderModalPlaceTabTitle: 'Обработка заказа',
    orderPlaceOrderModalPlaceTabDescription: 'Пожалуйста подождите, мы обрабатываем ваш заказ',

    orderPlaceOrderModalSuccess: 'Заказ был успешно расмещён.\nВаш заказ отобразится в списке заказов через несколько минут.',
    orderPlaceOrderModalError: 'Что-то не так с данными вашего заказа',
  },
};

export default strings;
