import React, {
  createContext,
  RefObject, useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { fabric } from 'fabric';
import {
  ETemplateSides, EUnits, TArticleDocument, TIntegrationMatchings
} from 'types';
import { IAlertAction } from 'contexts/ContextAlert';
import { EViewportModes } from '../constants';
import { getObjectWithBorder } from '../helpers/getObjectWithBorder';
import { calculateImgDPI, dpiNotification } from '../../../../../helpers';
import { TColor } from '../../../TemplateEditorArticle/components/Sidebar/Tabs/SettingsTab/components/ColorPicker';
import filterObjectsById from '../helpers/objectsFilter';

export interface IPdfBox {
  width: number,
  height: number,
  bleed: number,
  unit: EUnits,
}

export interface IPdfDocument {
  width: number,
  height: number,
  src?: string,
  url?: string,
  bleed?: number,
  colors?: TColor[]
}

export interface IViewportData {
  width: number,
  height: number,
  zoom: number,
}

const initialViewportData: IViewportData = {
  width: 720,
  height: 550,
  zoom: 0,
};

export const initialPdfBoxData: IPdfBox = {
  width: 0,
  height: 0,
  bleed: 0,
  unit: EUnits.MM
};

export interface IObject {
  object: any
}

export interface IEditorContext {
  articleTitle: string,
  mode: EViewportModes,
  setMode: React.Dispatch<React.SetStateAction<IEditorContext['mode']>>,
  viewport: IViewportData,
  setViewport: React.Dispatch<React.SetStateAction<IEditorContext['viewport']>>,
  setZoom: (value: IViewportData['zoom']) => void,
  canvas: RefObject<fabric.Canvas | null>,

  side: ETemplateSides,
  setSide: React.Dispatch<React.SetStateAction<IEditorContext['side']>>,

  objects: Array<IObject['object']>,
  setObjects: React.Dispatch<React.SetStateAction<IEditorContext['objects']>>,
  activeObject: IObject['object'] | null,
  setActiveObject: React.Dispatch<React.SetStateAction<IEditorContext['activeObject']>>,

  json: any,
  setJson: React.Dispatch<React.SetStateAction<any>>,
  frontJson: any,
  setFrontJson: React.Dispatch<React.SetStateAction<any>>,
  backJson: any,
  setBackJson: React.Dispatch<React.SetStateAction<any>>,

  pdfBox: IPdfBox,
  pdfDocument: IPdfDocument | null,
  setPdfDocument: React.Dispatch<React.SetStateAction<IEditorContext['pdfDocument']>>,
  frontSidePdfDocument: IPdfDocument | null,
  setFrontSidePdfDocument: React.Dispatch<React.SetStateAction<IEditorContext['frontSidePdfDocument']>>,
  backSidePdfDocument: IPdfDocument | null,
  setBackSidePdfDocument: React.Dispatch<React.SetStateAction<IEditorContext['backSidePdfDocument']>>,

  isChanged : boolean,
  setIsChanged: React.Dispatch<React.SetStateAction<IEditorContext['isChanged']>>,

  uploadingStatus: boolean | string,
  setUploadingStatus: React.Dispatch<React.SetStateAction<IEditorContext['uploadingStatus']>>,

  canvasLoadingStatus: boolean,
  setCanvasLoadingStatus: React.Dispatch<React.SetStateAction<IEditorContext['canvasLoadingStatus']>>,

  clearSide: (value: ETemplateSides) => void,

  hoverObject:IObject['object'] | null,
  setHoverObject: React.Dispatch<React.SetStateAction<IObject['object']>>,
  calculateDpi: (object:IObject['object'])=>number,
  checkDpiCurrentObjects: (notify:(action: Omit<IAlertAction, 'onClose'>)=> void) => void,

  deleteObjectFromJson: (objectId: string) => void,
  removeCanvasObject: (object: IObject['object']) => void
}

const EditorContext = createContext<IEditorContext>({
  articleTitle: '',
  mode: EViewportModes.Edit,
  setMode: () => {},
  viewport: initialViewportData,
  setViewport: () => {},
  setZoom: () => {},
  canvas: React.createRef<fabric.Canvas | null>(),

  side: ETemplateSides.FRONT,
  setSide: () => {},

  objects: [],
  setObjects: () => {},
  activeObject: null,
  setActiveObject: () => {},

  json: {},
  setJson: () => {},
  frontJson: {},
  setFrontJson: () => {},
  backJson: {},
  setBackJson: () => {},

  pdfBox: initialPdfBoxData,
  pdfDocument: null,
  setPdfDocument: () => {},
  setFrontSidePdfDocument: () => {},
  setBackSidePdfDocument: () => {},
  frontSidePdfDocument: null,
  backSidePdfDocument: null,

  isChanged: false,
  setIsChanged: () => {},

  uploadingStatus: false,
  setUploadingStatus: () => {},

  canvasLoadingStatus: false,
  setCanvasLoadingStatus: () => {},

  clearSide: () => {},

  hoverObject: null,
  setHoverObject: () => {},
  calculateDpi: () => 96,
  checkDpiCurrentObjects: () => {},

  deleteObjectFromJson: () => {},
  removeCanvasObject: () => {},
});

export interface IEditorContextData {
  frontSide?: TArticleDocument,
  backSide?: TArticleDocument,
  pdfBox: IPdfBox,
  articleTitle: string
}

export const EditorContextProvider:React.FC<{ children: React.ReactNode, data: IEditorContextData }> = ({ children, data }) => {
  const { articleTitle } = data;
  const [mode, setMode] = useState<IEditorContext['mode']>(EViewportModes.Edit);
  const [viewport, setViewport] = useState<IEditorContext['viewport']>(initialViewportData);
  const setZoom = (value: IViewportData['zoom']) => setViewport({ ...viewport, zoom: value });
  const canvas = useRef<fabric.Canvas | null>(null);

  const currentSide = Object.values(data)
    .sort(({ side }) => side === ETemplateSides.FRONT ? -1 : 1)
    .find(o => typeof o?.side === 'string')?.side;
  const [side, setSide] = useState<IEditorContext['side']>(currentSide ?? ETemplateSides.FRONT);

  const [objects, setObjects] = useState<IEditorContext['objects']>([]);
  const [activeObject, setActiveObject] = useState<IEditorContext['activeObject']>(null);

  const pdfBox = useMemo<IEditorContext['pdfBox']>(() => data.pdfBox, [data.pdfBox]);
  const [frontSidePdfDocument, setFrontSidePdfDocument] = useState<IEditorContext['pdfDocument']>(data?.frontSide?.document ?? null);
  const [backSidePdfDocument, setBackSidePdfDocument] = useState<IEditorContext['pdfDocument']>(data?.backSide?.document ?? null);
  const pdfDocument = useMemo(
    () => side === ETemplateSides.FRONT ? frontSidePdfDocument : backSidePdfDocument,
    [side, frontSidePdfDocument, backSidePdfDocument]
  );
  const setPdfDocument = useMemo(
    () => side === ETemplateSides.FRONT ? setFrontSidePdfDocument : setBackSidePdfDocument,
    [side, frontSidePdfDocument, backSidePdfDocument]
  );

  const [frontJson, setFrontJson] = useState<any>(data?.frontSide?.canvas ?? {});
  const [backJson, setBackJson] = useState<any>(data?.backSide?.canvas ?? {});

  const json = useMemo(() => side === ETemplateSides.FRONT ? frontJson : backJson, [side, pdfDocument, frontJson, backJson]);
  const setJson = useMemo(
    () => (canvasData: TArticleDocument['canvas']) => side === ETemplateSides.FRONT
      ? setFrontJson((prev: TArticleDocument['canvas']) => ({ ...prev, ...canvasData }))
      : setBackJson((prev: TArticleDocument['canvas']) => ({ ...prev, ...canvasData })),
    [side, pdfDocument, frontJson, backJson]
  );

  const [isChanged, setIsChanged] = useState<boolean>(false);

  const [uploadingStatus, setUploadingStatus] = useState<boolean|string>(false);
  const [canvasLoadingStatus, setCanvasLoadingStatus] = useState<boolean>(false);

  const clearSide = (clearingSide: ETemplateSides) => {
    // if clearingSide === side (current selected side)
    if (clearingSide === side && canvas.current) {
      canvas.current.clear();
      setActiveObject(null);
      setObjects([]);
    }

    if (clearingSide === ETemplateSides.FRONT) {
      setFrontSidePdfDocument(null);
      setFrontJson({});
    } else {
      setBackSidePdfDocument(null);
      setBackJson({});
    }
  };

  const [hoverObject, setHoverObject] = useState<IObject['object'] | null>(null);

  const calculateDpi = useCallback((object: IObject['object']) => {
    const { unit, width, height } = pdfBox;
    const fileSize = { width, height };
    return calculateImgDPI(object, fileSize, unit);
  }, [pdfBox]);

  const checkDpiCurrentObjects = useCallback((notify:(action: Omit<IAlertAction, 'onClose'>)=> void) => {
    const setDpiNotification = (object: IObject['object']) => {
      const dpi = calculateDpi(object);
      dpiNotification(notify, dpi, false);
      return getObjectWithBorder(object, dpi);
    };
    if (canvas.current) {
      const currentObjects = canvas.current?.getObjects();
      const newObjects = currentObjects?.map(obj => obj.type === 'ImageObject' ? setDpiNotification(obj) : obj) || [];
      setObjects(newObjects);
    }
  }, [calculateDpi]);

  const removeObjectFromMatchings = (objectId: string, matchings: TIntegrationMatchings | undefined) => {
    const matchingsAmount = Object.keys(matchings?.fields || {});
    if (matchingsAmount?.length > 0) {
      const result: TIntegrationMatchings = { ...matchings! };
      const keyToDelete: string | undefined = matchingsAmount.find(key => key === objectId);
      if (keyToDelete) {
        // if there is one element which will be deleted, clear whole mappings object
        if (matchingsAmount?.length === 1) {
          return {};
        }
        delete result.fields[keyToDelete];
      }
      return result;
    }
    return {};
  };

  const deleteObjectFromJson = useCallback(
    (objectId: string) => side === ETemplateSides.FRONT
      ? setFrontJson((prevState: any) => ({
        ...prevState,
        objects: filterObjectsById(objectId, prevState.objects),
        integrationMatchings: removeObjectFromMatchings(objectId, prevState?.integrationMatchings),
      }))
      : setBackJson((prevState: any) => ({
        ...prevState,
        objects: filterObjectsById(objectId, prevState.objects),
        integrationMatchings: removeObjectFromMatchings(objectId, prevState?.integrationMatchings),
      })),
    [side]
  );

  const removeCanvasObject = (object: IObject['object']) => {
    if (canvas.current) {
      const canvasObjects: IObject['object'][] = canvas.current.getObjects();
      const objectId = object.id;

      if (object.group) {
        object.group.removeWithUpdate(object);
      }

      setObjects(filterObjectsById(objectId, canvasObjects));
      deleteObjectFromJson(objectId);
      setIsChanged(true);
    }
  };

  const value = useMemo(() => ({
    articleTitle,
    mode,
    setMode,
    viewport,
    setViewport,
    setZoom,
    canvas,

    side,
    setSide,

    objects,
    setObjects,
    activeObject,
    setActiveObject,

    json,
    setJson,
    frontJson,
    setFrontJson,
    backJson,
    setBackJson,

    pdfDocument,
    setPdfDocument,

    isChanged,
    setIsChanged,

    uploadingStatus,
    setUploadingStatus,

    canvasLoadingStatus,
    setCanvasLoadingStatus,

    pdfBox,
    frontSidePdfDocument,
    setFrontSidePdfDocument,
    backSidePdfDocument,
    setBackSidePdfDocument,

    clearSide,

    hoverObject,
    setHoverObject,
    calculateDpi,
    checkDpiCurrentObjects,

    deleteObjectFromJson,
    removeCanvasObject
  }), [
    mode, viewport, canvas, side, objects, activeObject, articleTitle,
    pdfDocument, json, frontJson, backJson, uploadingStatus,
    canvasLoadingStatus, pdfBox, frontSidePdfDocument,
    backSidePdfDocument, isChanged, hoverObject, setHoverObject, calculateDpi, checkDpiCurrentObjects,
    deleteObjectFromJson, removeCanvasObject
  ]);

  return (
    <EditorContext.Provider value={value}>
      {children}
    </EditorContext.Provider>
  );
};

export default EditorContext;
