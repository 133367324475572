import { IObject } from 'components/PageArticles/components/Editor/contexts/EditorContext';
import { ECanvasObjectTypes } from 'types';
import fabric from '../../../helpers/fabric';
import { EViewportModes } from '../../../constants';

// render object area lines & central point
export function renderRotatedObjectArea(canvas: fabric.Canvas, event: fabric.IEvent) {
  // @ts-ignore
  const { ctx } = event;
  if (ctx) {
    const object = canvas.getActiveObject();
    if (object && object.angle && object.angle % 90) {
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.33)';
      const {
        left,
        top,
        width,
        height
      } = object.getBoundingRect();
      ctx.setLineDash([6]);
      ctx.strokeRect(left + 0.5, top + 0.5, width, height);
      ctx.setLineDash([]);
    }
  }
}

// Rounds rotate angle to 0/90/180/270, when current Angle less than THRESHOLD
export function rotateAligner(event: fabric.IEvent) {
  const ROTATE_ANGLE_THRESHOLD = 5;
  const { target } = event;
  if (target && target.angle) {
    const angle = target.angle % 90;
    if (angle <= ROTATE_ANGLE_THRESHOLD) target.rotate(target.angle - angle);
  }
}

export function changeCanvasMode(canvas: fabric.Canvas, mode: fabric.IEvent | EViewportModes) {
  const isEditable = mode === EViewportModes.Edit;
  canvas.getObjects() // @ts-ignore
    .filter(object => !object.locked) // @ts-ignore
    .forEach(object => object.updateEditable(isEditable));
  canvas.selection = isEditable;

  canvas.defaultCursor = mode === EViewportModes.Navigate ? 'grab' : 'default';
  canvas.discardActiveObject().renderAll();
}

export function keyboardEventHandler(e: KeyboardEvent, canvas: fabric.Canvas | null, activeObject: IObject['object'] | undefined = canvas?.getActiveObject()) {
  if (canvas) {
    const { code } = e;
    if (document.activeElement?.tagName !== 'INPUT' && activeObject) {
      const { type, isEditing } = activeObject as IObject['object'];
      if (!(type === ECanvasObjectTypes.TEXT && isEditing)) {
        switch (code) {
          case 'Delete':
          case 'Backspace':
            canvas.remove(activeObject);
            break;
          default:
            break;
        }
      }
    }
  }
}
