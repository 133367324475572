import React from 'react';
import cls from 'classnames';

import { EIntegrationServices } from 'types';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import css from './EventBindInformation.module.css';
import { getIntegrationServiceName } from '../../helpers';

interface IEventBindInformation {
  bindedEventName: string | undefined,
  connectedService: EIntegrationServices | undefined,
}

const EventBindInformation: React.FC<IEventBindInformation> = ({ bindedEventName, connectedService }) => (
  <span className={cls(textCss.pMedium2, css.eventBindInfo)}>
    {(bindedEventName && connectedService)
      ? strings.formatString(strings.bindedWithEventOfService, bindedEventName, getIntegrationServiceName(connectedService))
      : strings.yourAccountNotBindedYet}
  </span>
);

export default EventBindInformation;
