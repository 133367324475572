const FontFaceObserver = require('fontfaceobserver');

export const fontsList: string[] = [
  'Arial',
  'Verdana',
  'Helvetica',
  'Trebuchet MS',
  'Gill Sans',
  'Noto Sans',
  'Courier New',
  'Arial Narrow'
];

export const loadFonts = async (fonts: string[]): Promise<void> => {
  const observers: Array<FontFaceSetLoadEvent> = [];
  fonts.forEach(family => {
    const obs = new FontFaceObserver(family);
    observers.push(obs.load());
  });
  await Promise.all(observers);
};

export default { fontsList, loadFonts };
