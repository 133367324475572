import React from 'react';
import useEditorData from 'components/PageArticles/components/Editor/hooks/useEditorData';
import strings from 'constants/localization';
import { Grid } from '@mui/material';
import css from './NumberingView.module.css';
import FontSizeSelector from './components/FontSizeSelector';
import FontFamilySelector from './components/FontFamilySelector';
import ColorPicker from './components/ColorPicker';
import BackgroundColorPicker from './components/BackgroundColorPicker';
import TextAlign from './components/TextAlign';
import ObjectAlign from './components/ObjectAlign';
import ObjectRotate from './components/ObjectRotate';
import NumberingPattern from './components/NumberingPattern';
import NumberingPrefix from './components/NumberingPrefix';
import NumberingStartFrom from './components/NumberingStartFrom';

const containerProps = {
  container: true,
  columns: 4,
  spacing: '24px',
};

const gridItemProps = {
  item: true,
  xs: 1,
};

const alignEnd = {
  display: 'flex',
  alignItems: 'flex-end',
};

const NumberingView:React.FC = () => {
  const {
    activeObject,
  } = useEditorData();

  return (
    <div className={css.container}>
      <div className={css.name}>
        { strings.editorObjectNumberingTitle }: { activeObject.name }
      </div>

      <ObjectAlign />
      <ObjectRotate />

      <div className={css.section}>
        <header>{strings.editorTabSettingsSectionNumberingPatternTitle}</header>
        <NumberingPattern />
      </div>

      <Grid {...containerProps}>
        <Grid item xs={2} className={css.section}>
          <header>{strings.editorTabSettingsSectionNumberingPrefixTitle}</header>
          <NumberingPrefix />
        </Grid>
        <Grid item xs={2} className={css.section}>
          <header>{strings.editorTabSettingsSectionNumberingStartFromTitle}</header>
          <NumberingStartFrom />
        </Grid>
      </Grid>

      <div className={css.section}>
        <header>{strings.editorTabSettingsSectionFontTitle}</header>
        <FontFamilySelector />
      </div>

      <Grid {...containerProps}>
        <Grid item xs={2} className={css.section}>
          <header>{strings.editorTabSettingsSectionSizeTitle}</header>
          <FontSizeSelector />
        </Grid>

        <Grid {...gridItemProps} {...alignEnd}><ColorPicker /></Grid>
        <Grid {...gridItemProps} {...alignEnd}><TextAlign /></Grid>
        <Grid {...gridItemProps} {...alignEnd}><BackgroundColorPicker /></Grid>
      </Grid>
    </div>
  );
};

export default NumberingView;
