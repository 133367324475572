import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EOrderStatus, EArticleStatuses, TStat } from 'types';
import { ROUTES, STATUS_TO_STRINGS } from 'constants/constants';
import Tabs from 'components/library/Tabs';
import textCss from 'assets/styles/Text.module.css';
import Button from 'components/library/Button';
import { statsButtonActions, statsTabsLabels } from '../../../../constants';
import css from './UserStatistics.module.css';

interface IUserStatistics extends React.HTMLProps<HTMLDivElement> {
  userId: string,
  stats: [TStat<EArticleStatuses>[], TStat<EOrderStatus>[]] | []
}
const UserStatistics:React.FC<IUserStatistics> = React.memo(({ userId, stats = [] }) => {
  const navigate = useNavigate();
  const navigateCallbacks = useMemo(() => [
    (e: any, status?: EArticleStatuses | EOrderStatus) => navigate(['', ROUTES.ARTICLES, `?customerId=${userId}`].join('/'), { state: { status } }),
    (e: any, status?: EArticleStatuses | EOrderStatus) => navigate(['', ROUTES.ORDERS, `?customerId=${userId}`].join('/'), { state: { status } }),
  ], [navigate, userId]);
  const [index, selectTab] = useState<number>(0);
  if (!stats.length) return null;
  return (
    <div className={css.stats}>
      <Tabs
        value={index}
        onChange={(ev, value) => selectTab(value)}
        orientation="horizontal"
        tabs={statsTabsLabels}
        tabClass={css.tabClass}
      />
      {stats[index].map(stat => (
        <div key={stat.status} className={css.statRow} onClick={e => navigateCallbacks[index](e, stat.status)}>
          <span className={textCss.pMedium2}>{STATUS_TO_STRINGS[stat.status]()}</span>
          <span className={textCss.h4}>{stat.count}</span>
        </div>
      ))}
      <Button className={css.statsButton} onClick={navigateCallbacks[index]} buttonType="primary">{statsButtonActions[index]()}</Button>
    </div>
  );
});

export default UserStatistics;
