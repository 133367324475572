/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextAlignRight(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM10 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H10C9.45 15 9 15.45 9 16C9 16.55 9.45 17 10 17ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM10 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H10C9.45 7 9 7.45 9 8C9 8.55 9.45 9 10 9ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextAlignRight;
