import React, { useContext } from 'react';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';

import Modal from 'components/library/Modal';
import strings from 'constants/localization';
import Input from 'components/library/Input';
import { useUserPatch } from 'hooks/useUser';
import AlertContext from 'contexts/ContextAlert';
import UserContext from 'contexts/ContextUser';
import { TUser } from 'types';
import { getBackendErrors } from 'helpers';
import { getFieldError, prepareData } from './helpers';
import { VALIDATION_SCHEMA, IChangePasswordValues, INITIAL_VALUES } from './constants';
import css from './ChangePasswordModal.module.css';

interface IChangePasswordModal {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const ChangePasswordModal: React.FC<IChangePasswordModal> = ({
  isOpen, setIsOpen
}) => {
  const patchUser = useUserPatch();
  const user = useContext(UserContext).user as TUser;
  const { push: pushAlert } = useContext(AlertContext);

  const handleSubmit = (values: IChangePasswordValues, formikHelpers: FormikHelpers<IChangePasswordValues>) => {
    const data = prepareData(user, values);
    patchUser.mutate(data, {
      onSuccess: () => {
        pushAlert({
          message: strings.profileChangePasswordModalSuccessNotificationText,
          severity: 'success'
        });
        closeModal();
      },
      onError: err => {
        pushAlert({
          message: err.response?.data.data?.reason || strings.errorResponse5,
          severity: 'error'
        });
        const backendErrors: FormikErrors<IChangePasswordValues> = getBackendErrors<IChangePasswordValues>(err);
        formikHelpers.setErrors(backendErrors);
      },
      onSettled: () => {
        formikHelpers.setSubmitting(false);
      }
    });
  };
  const formik = useFormik<IChangePasswordValues>({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA(),
    onSubmit: handleSubmit
  });
  const onOk = () => {
    formik.handleSubmit();
  };
  const closeModal = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      onOk={onOk}
      onCancel={closeModal}
      title={`${strings.profileChangePasswordModalTitle}`}
      okLabel={strings.actionLabelSave}
      cancelLabel={strings.actionLabelBack}
      className={css.registerModal}
      okButtonProps={{ disabled: formik.isSubmitting }}
    >
      <form noValidate className={css.form}>
        <Input
          {...formik.getFieldProps('currentPassword')}
          withError
          error={getFieldError('currentPassword', formik.touched, formik.errors)}
          className={css.input}
          label={strings.inputCurrentPasswordLabel}
          type="password"
          placeholder={strings.inputPasswordPlaceholder}
        />
        <Input
          {...formik.getFieldProps('newPassword')}
          withError
          error={getFieldError('newPassword', formik.touched, formik.errors)}
          className={css.input}
          label={strings.inputNewPasswordLabel}
          type="password"
          placeholder={strings.inputPasswordConfirmPlaceholder}
          autoComplete="new-password"
        />
        <Input
          {...formik.getFieldProps('confirmNewPassword')}
          withError={!!getFieldError('confirmNewPassword', formik.touched, formik.errors)}
          error={getFieldError('confirmNewPassword', formik.touched, formik.errors)}
          className={css.input}
          label={strings.inputPasswordConfirmLabel}
          type="password"
          placeholder={strings.inputPasswordConfirmPlaceholder}
        />
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
