import React, { useRef, useState } from 'react';
import { EOrderStatus, ERoles, TLocalizedConstString } from 'types';
import Popover from 'components/library/Popover';
import strings from 'constants/localization';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import Tooltip from 'components/library/Tooltip';
import css from './StatusColumn.module.css';

type TStatusItem = {
  status: EOrderStatus,
  label: TLocalizedConstString,
}

const statuses:{ [key in EOrderStatus]: TStatusItem } = {
  [EOrderStatus.CREATED]: {
    status: EOrderStatus.CREATED,
    label: () => strings.orderStatusCreated,
  },
  [EOrderStatus.RECEIVED]: {
    status: EOrderStatus.RECEIVED,
    label: () => strings.orderStatusReceived,
  },
  [EOrderStatus.SEND_TO_PRINTER]: {
    status: EOrderStatus.SEND_TO_PRINTER,
    label: () => strings.orderStatusSendToPrinter,
  },
  [EOrderStatus.IN_PRINTING]: {
    status: EOrderStatus.IN_PRINTING,
    label: () => strings.orderStatusInPrinting,
  },
  [EOrderStatus.IN_DELIVERY]: {
    status: EOrderStatus.IN_DELIVERY,
    label: () => strings.orderStatusInDelivery,
  },
  [EOrderStatus.ASSUMED_DELIVERED]: {
    status: EOrderStatus.ASSUMED_DELIVERED,
    label: () => strings.orderStatusAssumedDelivered,
  },
  [EOrderStatus.ARCHIVED]: {
    status: EOrderStatus.ARCHIVED,
    label: () => strings.orderStatusArchived,
  },
  [EOrderStatus.ISSUE]: {
    status: EOrderStatus.ISSUE,
    label: () => strings.orderStatusIssue,
  }
};

const getStatusOptions = (currentStatus: EOrderStatus) => {
  if (currentStatus === EOrderStatus.CREATED) return [statuses[EOrderStatus.RECEIVED], statuses[EOrderStatus.ISSUE]];
  if (currentStatus === EOrderStatus.RECEIVED) return [statuses[EOrderStatus.SEND_TO_PRINTER]];
  if (currentStatus === EOrderStatus.SEND_TO_PRINTER) return [statuses[EOrderStatus.IN_PRINTING]];
  if (currentStatus === EOrderStatus.IN_PRINTING) return [statuses[EOrderStatus.IN_DELIVERY]];
  if (currentStatus === EOrderStatus.IN_DELIVERY) return [statuses[EOrderStatus.ASSUMED_DELIVERED]];
  if (currentStatus === EOrderStatus.ASSUMED_DELIVERED) return [statuses[EOrderStatus.ARCHIVED]];
  if (currentStatus === EOrderStatus.ARCHIVED) return [statuses[EOrderStatus.CREATED]];
  return null;
};

interface IStatusItem extends React.HTMLProps<HTMLDivElement>{
  item: TStatusItem,
  readyToPrint?: boolean,
  currentStatus?: EOrderStatus,
  onClickStatus?: (status: EOrderStatus) => void
  short?: boolean,
  className?: string,
}

export const StatusItem:React.FC<IStatusItem> = ({
  className, item, readyToPrint, currentStatus, onClickStatus, short
}) => {
  const disabled = currentStatus !== undefined && currentStatus === EOrderStatus.RECEIVED && !readyToPrint;
  return (
    <div
      className={cls(className, textCss.pMedium3, css.container, !short && css.pill, !short && {
        [css.created]: item.status === EOrderStatus.CREATED,
        [css.received]: item.status === EOrderStatus.RECEIVED,
        [css.sendToPrinter]: item.status === EOrderStatus.SEND_TO_PRINTER,
        [css.inPrinting]: item.status === EOrderStatus.IN_PRINTING,
        [css.inDelivery]: item.status === EOrderStatus.IN_DELIVERY,
        [css.delivered]: item.status === EOrderStatus.ASSUMED_DELIVERED,
        [css.issue]: item.status === EOrderStatus.ISSUE,
        [css.archived]: item.status === EOrderStatus.ARCHIVED,
        [css.disabled]: disabled
      })}
      onClick={() => !disabled && onClickStatus && onClickStatus(item.status)}
    >
      {item.label()}
      {short ? (
        <div
          className={cls(css.short, {
            [css.created]: item.status === EOrderStatus.CREATED,
            [css.received]: item.status === EOrderStatus.RECEIVED,
            [css.sendToPrinter]: item.status === EOrderStatus.SEND_TO_PRINTER,
            [css.inPrinting]: item.status === EOrderStatus.IN_PRINTING,
            [css.inDelivery]: item.status === EOrderStatus.IN_DELIVERY,
            [css.delivered]: item.status === EOrderStatus.ASSUMED_DELIVERED,
            [css.issue]: item.status === EOrderStatus.ISSUE,
            [css.archived]: item.status === EOrderStatus.ARCHIVED,
          })}
        />
      ) : null}
    </div>
  );
};

const StatusItemTooltipWrapper:React.FC<IStatusItem> = props => {
  const disabled = props.currentStatus !== undefined
    && props.currentStatus === EOrderStatus.RECEIVED
    && !props.readyToPrint;
  return disabled ? (
    <Tooltip title={strings.orderPageNotReadyTooltip}>
      <StatusItem {...props} />
    </Tooltip>
  ) : <StatusItem {...props} />;
};

export interface IStatusColumn {
  id: string,
  readyToPrint: boolean,
  status: EOrderStatus,
  onChangeStatus: (status: EOrderStatus, id: string) => void,
  role: ERoles,
}
const StatusColumn = ({
  id, status, onChangeStatus, readyToPrint, role
}: IStatusColumn) => {
  const [state, setState] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleChangeStatus = (value: EOrderStatus) => {
    onChangeStatus(value, id);
    setState(false);
  };
  const activeStatus = statuses[status];
  const options = getStatusOptions(status);
  return (
    <div
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        if (options) {
          setState(!state);
        }
      }}
    >
      <StatusItem item={activeStatus} />
      {[ERoles.MANAGER, ERoles.PRINTER].includes(role) && options && (
        <Popover
          open={state}
          anchorEl={ref.current}
          onClose={() => setState(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className={css.content}>
            {options.map(item => (
              <StatusItemTooltipWrapper
                key={item.status}
                item={item}
                currentStatus={status}
                readyToPrint={readyToPrint}
                onClickStatus={handleChangeStatus}
              />
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default StatusColumn;
