import { TCell, TRow } from 'components/PageOrders/components/OrderEditor/types';
import strings from 'constants/localization';
import { ETabs } from './types';

export const getCellValue = (cell: TCell): string => {
  if (typeof cell === 'string') {
    return cell;
  }

  return cell.value;
};

export const getDataSample = (data: TRow[], columnIndex: number | null, rowIndex: number = 1): string => {
  if (columnIndex === null) {
    return '';
  }

  return getCellValue(data[rowIndex][columnIndex]);
};

export const getModalTitle = (tab: ETabs) => {
  switch (tab) {
    case ETabs.FILE_LIST:
      return strings.editorExcelModalFileListTabTitle;
    case ETabs.ORDER_DATA:
      return strings.editorExcelModalOrderDataTabTitle;
    case ETabs.DELIVERY_DATA:
      return strings.editorExcelModalDeliveryDataTabTitle;
    case ETabs.TICKET_TYPE:
      return strings.editorExcelModalTicketTypeTabTitle;
    default:
      return strings.editorExcelModalOrderDataTabTitle;
  }
};

export default { getCellValue, getDataSample };
