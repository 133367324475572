import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import { Grid } from '@mui/material';
import css from './ObjectRotate.module.css';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';

export enum ERotateDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

const ROTATE_ANGLE = 90;

const ObjectRotate:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const isResetDisabled = useMemo(() => activeObject.angle === 0, [activeObject.angle]);

  const handleResetRotation = () => {
    if (activeObject && canvas.current) {
      activeObject.rotate(0);
      const ctx = canvas.current.getContext();
      // remove dash line
      if (ctx) ctx.setLineDash([0]);
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const handleRotate = (direction: ERotateDirection) => {
    if (activeObject && canvas.current) {
      let { angle } = activeObject;

      angle += (direction === ERotateDirection.RIGHT) ? ROTATE_ANGLE : -ROTATE_ANGLE;
      if (angle % ROTATE_ANGLE !== 0) {
        const coefficient = (direction === ERotateDirection.RIGHT)
          ? Math.floor(angle / ROTATE_ANGLE)
          : Math.ceil(angle / ROTATE_ANGLE);
        angle = coefficient * ROTATE_ANGLE;
      }

      // (360 + 90) % 360 = 90
      activeObject.rotate(angle % 360);

      const ctx = canvas.current.getContext();
      // remove dash line
      if (ctx) ctx.setLineDash([0]);
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  return (
    <Grid className={css.container} container columns={4} spacing="26px">
      <Grid item xs={1}>
        <Tooltip placement="top" title={strings.editorTabSettingsObjectRotateLeftTooltip} arrow>
          <SidebarButton
            className={css.button}
            buttonType="white"
            onClick={() => handleRotate(ERotateDirection.LEFT)}
          >
            <Icon className={css.rotateLeftIcon} type={ICON_TYPES.rotate} />
          </SidebarButton>
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Tooltip placement="top" title={strings.editorTabSettingsObjectRotateRightTooltip} arrow>
          <SidebarButton
            className={css.button}
            buttonType="white"
            onClick={() => handleRotate(ERotateDirection.RIGHT)}
          >
            <Icon type={ICON_TYPES.rotate} />
          </SidebarButton>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <SidebarButton
          disabled={isResetDisabled}
          buttonType="white"
          onClick={handleResetRotation}
          className={css.resetButton}
        >
          {strings.editorTabSettingsObjectAlignResetRotateButton}
        </SidebarButton>
      </Grid>
    </Grid>
  );
};

export default ObjectRotate;
