/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconOpacity(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <g clipPath="url(#clip0)">
        <path d="M12.5303 0.219735C12.2373 -0.0732449 11.7627 -0.0732449 11.4697 0.219735C11.124 0.565434 3 8.76124 3 15C3 19.9629 7.0371 24 12 24C16.9628 24 20.9999 19.9629 20.9999 15C21 8.76124 12.876 0.565434 12.5303 0.219735ZM12 1.83473C13.6098 3.55704 18.3689 8.96274 19.3206 13.5H4.67961C5.63205 8.96549 10.391 3.55758 12 1.83473ZM7.54641 21.0001H16.4536C15.2045 21.93 13.6733 22.5 12 22.5C10.3266 22.5 8.79542 21.93 7.54641 21.0001ZM17.9616 19.5001H6.03833C5.68615 19.0348 5.37302 18.5406 5.13571 18.0001H18.8642C18.6269 18.5406 18.3138 19.0348 17.9616 19.5001ZM19.3483 16.5001H4.6516C4.55275 16.0152 4.49998 15.5137 4.49998 15.0001H19.5C19.5 15.5137 19.4472 16.0152 19.3483 16.5001Z" fill="#2C3E66" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconOpacity;
