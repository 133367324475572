/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconFlagUSA(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.9469 13.1221H0.0541992V13.1224H23.9469V13.1221Z" fill="#C42126" />
      <path d="M22.7015 6.57141H1.29858V6.57177H22.7015V6.57141Z" fill="#C42126" />
      <path d="M23.5516 8.75476H0.447266V8.75512H23.5516V8.75476Z" fill="#C42126" />
      <path d="M5.15762 21.8562H18.8426L18.8434 21.8555H5.15686L5.15762 21.8562Z" fill="#C42126" />
      <path d="M11.9996 0C11.7639 0 11.5301 0.00851213 11.298 0.021787H12.7014C12.4691 0.00851213 12.2353 0 11.9996 0Z" fill="#C42126" />
      <path d="M23.9498 10.939H0.0494385V10.9393H23.9498V10.939Z" fill="#C42126" />
      <path d="M15.5465 4.38791H21.2766C20.596 3.55935 19.805 2.82589 18.9286 2.20496H14.6259C14.9819 2.83136 15.2881 3.57049 15.5465 4.38791Z" fill="#E7E7E7" />
      <path d="M14.6259 2.20561H18.9287C17.1499 0.945053 15.0143 0.155654 12.7016 0.0220947H12.3322C13.4207 0.159099 13.9207 0.964712 14.6259 2.20561Z" fill="#DC3027" />
      <path d="M23.5525 8.7551C23.3391 7.99418 23.053 7.26411 22.7014 6.57214H16.1091C16.2427 7.27303 16.3481 8.00512 16.4261 8.7551H23.5525Z" fill="#E7E7E7" />
      <path d="M15.7385 6.57149H22.7014C22.3046 5.79106 21.8279 5.05872 21.2769 4.38818H15.1946C15.4091 5.06708 15.5912 5.79942 15.7385 6.57149Z" fill="#DC3027" />
      <path d="M16.6783 13.1223H23.9456C23.9801 12.7531 23.9996 12.3788 23.9996 12.0006C23.9996 11.6427 23.9809 11.2894 23.9502 10.9392H16.6801C16.7037 11.6653 16.7033 12.3967 16.6783 13.1223Z" fill="#E7E7E7" />
      <path d="M23.9507 10.9386C23.885 10.1888 23.7499 9.45934 23.5526 8.75562H16.1201C16.1948 9.47048 16.2441 10.2019 16.2681 10.9386H23.9507Z" fill="#DC3027" />
      <path d="M15.9838 17.4885H22.6699C23.0265 16.7973 23.3172 16.0667 23.5351 15.3052H16.307C16.2274 16.0563 16.1194 16.7882 15.9838 17.4885Z" fill="#E7E7E7" />
      <path d="M23.535 15.305C23.7364 14.6015 23.8762 13.872 23.9457 13.1221H16.4518C16.4262 13.8593 16.3755 14.5905 16.2993 15.305H23.535Z" fill="#DC3027" />
      <path d="M18.8432 21.8553C19.7322 21.2369 20.5344 20.5027 21.2258 19.6724H15.5956C15.3324 20.4932 15.0199 21.2311 14.6575 21.8553H18.8432Z" fill="#E7E7E7" />
      <path d="M15.3838 19.6721H21.2258C21.7833 19.0023 22.2672 18.27 22.6698 17.4888H15.9346C15.7851 18.2614 15.6012 18.9941 15.3838 19.6721Z" fill="#DC3027" />
      <path d="M11.5651 23.9915C11.5408 23.9908 11.5166 23.9897 11.4923 23.9889C11.5166 23.9896 11.5408 23.9908 11.5651 23.9915Z" fill="#DC3027" />
      <path d="M11.9999 23.9997C14.5439 23.9997 16.9012 23.2056 18.8426 21.8556H14.4996C13.7288 23.1836 13.2203 23.9997 11.9999 23.9997Z" fill="#DC3027" />
      <path d="M11.9999 24.0002C11.8542 24.0002 11.7094 23.9968 11.5651 23.9917C11.7109 23.9966 11.8558 24.0002 11.9999 24.0002Z" fill="#C42126" />
      <path d="M15.0232 2.20496H5.07121C4.19471 2.82589 3.40384 3.5593 2.72302 4.38791H15.9436C15.6852 3.57049 15.3792 2.83136 15.0232 2.20496Z" fill="#F3F4F5" />
      <path d="M5.07141 2.20561H15.0234C14.318 0.964712 13.4208 0.159099 12.3323 0.0220947H11.2983C8.98557 0.155654 6.84999 0.945053 5.07141 2.20561Z" fill="#E73625" />
      <path d="M0.447632 8.7551H16.8046C16.7265 8.00497 16.6212 7.27303 16.4876 6.57214H1.29874C0.947061 7.26416 0.660992 7.99418 0.447632 8.7551Z" fill="#F3F4F5" />
      <path d="M2.72297 4.38794C2.17236 5.05847 1.69528 5.79077 1.29871 6.57126H16.4874C16.3401 5.79934 16.1581 5.06704 15.9436 4.38794C15.9436 4.38794 2.72297 4.38794 2.72297 4.38794Z" fill="#E73625" />
      <path d="M0 11.9998C0 12.3779 0.0197096 12.7522 0.0540115 13.1214H16.9511C16.9762 12.3957 16.9765 11.6645 16.9529 10.9385H0.0494514C0.018747 11.2885 0 11.6418 0 11.9998Z" fill="#F3F4F5" />
      <path d="M0.0494385 10.9386H16.9527C16.9286 10.2017 16.8791 9.47053 16.8047 8.75562H0.44733C0.250082 9.45949 0.115002 10.1888 0.0494385 10.9386Z" fill="#E73625" />
      <path d="M16.7988 15.3049H0.464966C0.682886 16.0666 0.973718 16.797 1.33011 17.4882H16.4756C16.6113 16.7881 16.719 16.0562 16.7988 15.3049Z" fill="#F3F4F5" />
      <path d="M0.464838 15.305H16.7987C16.8747 14.5905 16.9256 13.8591 16.9512 13.1221H0.0540771C0.123593 13.8722 0.263638 14.6015 0.464838 15.305Z" fill="#E73625" />
      <path d="M5.15681 21.8553H14.9867C15.3492 21.231 15.6618 20.4932 15.9249 19.6724H2.77417C3.46558 20.5027 4.26759 21.2369 5.15681 21.8553Z" fill="#F3F4F5" />
      <path d="M1.3302 17.4886C1.73285 18.2698 2.21673 19.0022 2.77417 19.672H15.9249C16.142 18.994 16.326 18.2613 16.4755 17.4886H1.3302Z" fill="#E73625" />
      <path d="M11.4922 23.9884C11.5164 23.9892 11.5407 23.9903 11.5649 23.9911C11.7093 23.9962 11.8541 23.9996 11.9998 23.9996C13.2202 23.9996 14.2153 23.1835 14.9862 21.8555H5.15747C6.96868 23.1152 9.14272 23.8905 11.4922 23.9884Z" fill="#E73625" />
      <path d="M11.7519 0.00622559C5.60258 0.131324 0.590553 4.87932 0.0511475 10.9188H11.7521V0.00622559H11.7519Z" fill="#283991" />
      <path d="M2.40077 6.79846L2.58358 7.36138H3.17589L2.69672 7.70946L2.87973 8.27278L2.40077 7.92449L1.92196 8.27278L2.10462 7.70946L1.62561 7.36138H2.21791L2.40077 6.79846Z" fill="#EFEFEF" />
      <path d="M2.40077 8.78723L2.58358 9.3505H3.17589L2.69672 9.69859L2.87973 10.2619L2.40077 9.91362L1.92196 10.2619L2.10462 9.69859L1.62561 9.3505H2.21791L2.40077 8.78723Z" fill="#EFEFEF" />
      <path d="M4.38987 3.81519L4.57272 4.3783H5.16482L4.68581 4.72659L4.86867 5.28951L4.38987 4.94162L3.91086 5.28951L4.09371 4.72659L3.6145 4.3783H4.2068L4.38987 3.81519Z" fill="#EFEFEF" />
      <path d="M4.38987 5.80444L4.57272 6.36756H5.16482L4.68581 6.71544L4.86867 7.27876L4.38987 6.93088L3.91086 7.27876L4.09371 6.71544L3.6145 6.36756H4.2068L4.38987 5.80444Z" fill="#EFEFEF" />
      <path d="M4.38987 7.79285L4.57272 8.35617H5.16482L4.68581 8.70385L4.86867 9.26717L4.38987 8.91893L3.91086 9.26717L4.09371 8.70385L3.6145 8.35617H4.2068L4.38987 7.79285Z" fill="#EFEFEF" />
      <path d="M0.890681 9.26736L0.70767 8.70404L1.18668 8.35615H0.594733L0.581458 8.31506C0.516452 8.51611 0.457728 8.71959 0.403564 8.92535L0.411925 8.91912L0.890681 9.26736Z" fill="#EFEFEF" />
      <path d="M1.92202 6.28442L2.40083 5.93618L2.87983 6.28442L2.69682 5.72109L3.17583 5.37285H2.58353L2.40067 4.80994L2.21782 5.37285H1.99954C1.95901 5.43426 1.91979 5.49603 1.88037 5.55835L2.10473 5.72109L1.92202 6.28442Z" fill="#EFEFEF" />
      <path d="M4.39006 2.95206L4.86887 3.29995L4.68601 2.73703L5.16482 2.38879H4.82281C4.53233 2.60595 4.25209 2.83578 3.98285 3.07828L3.91064 3.29995L4.39006 2.95206Z" fill="#EFEFEF" />
      <path d="M5.90002 2.30573L6.37903 1.95749L6.85784 2.30573L6.67498 1.74241L7.15378 1.39452H6.56169L6.53686 1.31873C6.33622 1.42123 6.13765 1.52788 5.9436 1.64138L6.08268 1.74236L5.90002 2.30573Z" fill="#EFEFEF" />
      <path d="M6.3792 2.8208L6.56206 3.38356H7.15416L6.67515 3.7318L6.85801 4.29512L6.3792 3.94683L5.90019 4.29512L6.08305 3.7318L5.60388 3.38356H6.19614L6.3792 2.8208Z" fill="#EFEFEF" />
      <path d="M6.3792 4.81006L6.56206 5.37282H7.15416L6.67515 5.72106L6.85801 6.28439L6.3792 5.9361L5.90019 6.28439L6.08305 5.72106L5.60388 5.37282H6.19614L6.3792 4.81006Z" fill="#EFEFEF" />
      <path d="M6.3792 6.79846L6.56206 7.36138H7.15416L6.67515 7.70946L6.85801 8.27278L6.3792 7.92449L5.90019 8.27278L6.08305 7.70946L5.60388 7.36138H6.19614L6.3792 6.79846Z" fill="#EFEFEF" />
      <path d="M6.3792 8.78723L6.56206 9.3505H7.15416L6.67515 9.69859L6.85801 10.2619L6.3792 9.91362L5.90019 10.2619L6.08305 9.69859L5.60388 9.3505H6.19614L6.3792 8.78723Z" fill="#EFEFEF" />
      <path d="M8.36809 1.82593L8.55094 2.38905H9.14304L8.66424 2.73734L8.84689 3.30025L8.36809 2.95237L7.88892 3.30025L8.07194 2.73734L7.59277 2.38905H8.18502L8.36809 1.82593Z" fill="#EFEFEF" />
      <path d="M8.36809 3.81519L8.55094 4.3783H9.14304L8.66424 4.72659L8.84689 5.28951L8.36809 4.94162L7.88892 5.28951L8.07194 4.72659L7.59277 4.3783H8.18502L8.36809 3.81519Z" fill="#EFEFEF" />
      <path d="M8.36809 5.80444L8.55094 6.36756H9.14304L8.66424 6.71544L8.84689 7.27876L8.36809 6.93088L7.88892 7.27876L8.07194 6.71544L7.59277 6.36756H8.18502L8.36809 5.80444Z" fill="#EFEFEF" />
      <path d="M8.36809 7.79285L8.55094 8.35617H9.14304L8.66424 8.70385L8.84689 9.26717L8.36809 8.91893L7.88892 9.26717L8.07194 8.70385L7.59277 8.35617H8.18502L8.36809 7.79285Z" fill="#EFEFEF" />
      <path d="M10.3573 0.831543L10.5402 1.39466H11.1323L10.6533 1.74254L10.8362 2.30587L10.3573 1.95758L9.87818 2.30587L10.0612 1.74254L9.58203 1.39466H10.1741L10.3573 0.831543Z" fill="#EFEFEF" />
      <path d="M10.3573 2.8208L10.5402 3.38356H11.1323L10.6533 3.7318L10.8362 4.29512L10.3573 3.94683L9.87818 4.29512L10.0612 3.7318L9.58203 3.38356H10.1741L10.3573 2.8208Z" fill="#EFEFEF" />
      <path d="M10.3573 4.81006L10.5402 5.37282H11.1323L10.6533 5.72106L10.8362 6.28439L10.3573 5.9361L9.87818 6.28439L10.0612 5.72106L9.58203 5.37282H10.1741L10.3573 4.81006Z" fill="#EFEFEF" />
      <path d="M10.3573 6.79846L10.5402 7.36138H11.1323L10.6533 7.70946L10.8362 8.27278L10.3573 7.92449L9.87818 8.27278L10.0612 7.70946L9.58203 7.36138H10.1741L10.3573 6.79846Z" fill="#EFEFEF" />
      <path d="M10.3573 8.78723L10.5402 9.3505H11.1323L10.6533 9.69859L10.8362 10.2619L10.3573 9.91362L9.87818 10.2619L10.0612 9.69859L9.58203 9.3505H10.1741L10.3573 8.78723Z" fill="#EFEFEF" />
    </svg>
  );
}

export default IconFlagUSA;
