import React, { useEffect, useState } from 'react';
import fabric from '../../../../../../../../components/Editor/helpers/fabric';
import Loader from '../../../../../../../../../library/Loader';
import css from './CodeContent.module.css';

export interface ICodeContent {
  object: typeof fabric.CodeObject,
}

const CodeContent: React.FC<ICodeContent> = ({ object }) => {
  const [src, setSrc] = useState<string|null>(null);

  useEffect(() => {
    fabric.util.enlivenObjects(object.objects, (enlivenedObjects: any[]) => {
      const newObject = new fabric.CodeObject([], object);
      newObject.remove(...newObject._objects);
      newObject.add(...enlivenedObjects);
      newObject.scaleToWidth(2000);
      newObject.rotate(0);
      setSrc(newObject.toDataURL({ format: 'png' }));
    }, 'fabric');
  }, [object.content, object.id]);

  return (
    <div className={css.container}>
      { src ? <img alt={object.name} src={src} /> : <Loader /> }
      { object.content }
    </div>
  );
};

export default CodeContent;
