import React, {
  BaseSyntheticEvent,
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { ETablePageViews, TUser } from 'types';
import { IAlertContext } from 'contexts/ContextAlert';
import {
  getArticlesStatusOnMount, isAppEventbriteManager, isCustomer, navigateWithState, showEmptyState,
} from 'helpers';
import {
  MIN_PAGE, PAGE_SIZE_10, ROUTES, TITLE_ORDER_OPTIONS
} from 'constants/constants';
import { TSort } from 'components/library/Table/types';
import useFindArticles from 'hooks/Articles/useFindArticles';
import { IArticlesDisplayProps, IGetArticlesParams } from 'types/Articles';
import useDeleteArticle from 'hooks/Articles/useDeleteArticle';
import strings from 'constants/localization';
import PageContent from 'components/library/PageContent';
import Button from 'components/library/Button';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import Loader from 'components/library/Loader';
import EmptyState, { EmptyStates } from 'components/library/EmptyState';
import Pagination from 'components/library/Pagination';
import Modal from 'components/library/Modal';
import Input from 'components/library/Input';
import textCss from 'assets/styles/Text.module.css';
import { IArticleListViewContext } from 'contexts/ContextArticleListView';
import { NavigateFunction } from 'react-router/lib/hooks';
import { Location } from 'history';
import css from './PageArticles.module.css';
import ArticlesAddNewSkuBtn from './components/ArticlesAddNewSkuBtn';
import ArticlesTableView from './components/ArticlesTableView';
import ArticlesGridView from './components/ArticlesGridView';

export interface IStateParams {
  customerId: string
  page?: number
  search: string
  status?: TSort
  order?: TSort
}

interface IPageArticles {
  state: IStateParams
  setListViewQuery: (newView: ETablePageViews) => void
  navigate: NavigateFunction
  location: Location
  alertContext: IAlertContext
  articleListViewContext: IArticleListViewContext
  user: Partial<TUser> | null
}
const PageArticles: React.FC<IPageArticles> = props => {
  const {
    state,
    setListViewQuery,
    navigate,
    location,
    alertContext,
    articleListViewContext,
    user,
  } = props;
  const {
    customerId,
  } = state;

  const isNotCustomer = useMemo<boolean>(
    () => !isCustomer(user?.userPermissions || []),
    [user]
  );

  const isNotAppEventbriteManager = useMemo<boolean>(
    () => !isAppEventbriteManager(user?.userPermissions || []),
    [user]
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteArticleId, setDeleteArticleId] = useState<string>('');

  const [page, setPage] = useState<number>(state.page || MIN_PAGE);
  useEffect(() => setPage(state.page || MIN_PAGE), [state.page]);

  const onChangePage = useCallback((event:any, value:number) => {
    if (page !== value) {
      setPage(value);
      navigateWithState(navigate, location, { page: value });
    }
  }, [page, setPage, location]);

  const [searchActive, setSearchActive] = useState<boolean>(!!state.search);
  const [searchText, setSearchText] = useState<string>(state.search);
  useEffect(() => {
    setSearchText(state.search);
    if (state.search) {
      setSearchActive(true);
    }
  }, [state.search]);

  const handleSearchTextChange = useCallback((e: BaseSyntheticEvent): void => {
    const { value } = e.target;
    setPage(MIN_PAGE);
    setSearchText(value);
    navigateWithState(navigate, location, { page: MIN_PAGE, search: value }, { replace: true });
  }, [setPage, setSearchText, location]);

  const [order, setOrder] = useState<TSort>(state.order || TITLE_ORDER_OPTIONS[2].value);
  useEffect(() => setOrder(state.order || TITLE_ORDER_OPTIONS[2].value), [state.order]);

  const handleSetOrder = useCallback((value: TSort): void => {
    setOrder(value);
    navigateWithState(navigate, location, { order: value });
  }, [location, setOrder]);

  const [status, setStatus] = useState<TSort>(state.status || getArticlesStatusOnMount(isNotCustomer, state.status).value);
  useEffect(() => setStatus(state.status || getArticlesStatusOnMount(isNotCustomer, state.status).value));
  const handleSetStatus = useCallback((value: TSort): void => {
    setPage(MIN_PAGE);
    setStatus(value);
    navigateWithState(navigate, location, { status: value });
  }, [location, setStatus, setPage]);

  const { view, setView } = articleListViewContext;
  const isTableView = useMemo(
    () => view === ETablePageViews.TABLE,
    [view]
  );

  const {
    isLoading,
    data = { data: [], total: 0 },
  } = useFindArticles({
    customerId,
    pspId: user?.pspId,
    search: searchText,
    skip: (page - 1) * PAGE_SIZE_10,
    limit: PAGE_SIZE_10,
    sort: order,
    status,
  } as IGetArticlesParams);

  const {
    isLoading: isDeleting,
    mutateAsync,
  } = useDeleteArticle();

  const handleSetView = (): void => {
    const newView = isTableView ? ETablePageViews.GRID : ETablePageViews.TABLE;
    setView(newView);
    setListViewQuery(newView);
  };

  const toggleSearchActive = () => {
    setSearchActive(prev => !prev);
  };

  const toggleShowModal = () => {
    setShowModal(prev => !prev);
  };

  const handleArticleDelete = useCallback(
    () => mutateAsync(deleteArticleId)
      .then(() => {
        alertContext.push({ severity: 'success', message: strings.successResponseArticleDeleted });
        if (data.data.length - 1 <= 0 && page > MIN_PAGE) {
          setPage(MIN_PAGE);
        }
      })
      .catch(() => {
        // TODO: handle special error cases after backend refactoring
        alertContext.push({ severity: 'error', message: strings.errorResponseUnableToDeleteArticle });
      })
      .finally(() => {
        setShowModal(false);
      }),
    [deleteArticleId, page, data.data.length]
  );

  const handleArticleClick: IArticlesDisplayProps['handleArticleClick'] = articleId => {
    if (isNotCustomer) {
      navigate(`${articleId}?customerId=${customerId}`);
    } else {
      navigate(`${ROUTES.BASE}${ROUTES.ORDERS}/${ROUTES.ROUTE_ADD}`, { state: { articleId } });
    }
  };

  const commonViewComponentsProps: IArticlesDisplayProps = {
    isNotCustomer,
    isNotAppEventbriteManager,
    articles: data.data,
    toggleModal: toggleShowModal,
    setDeleteArticleId,
    handleArticleClick,
  };

  return (
    <PageContent pageTitle={strings.articlesPageTitle}>
      <div className={css.container}>
        <div className={css.header}>
          <Button
            type="button"
            buttonType="primary"
            buttonStyle="circle"
            onClick={handleSetView}
          >
            <Icon type={isTableView ? ICON_TYPES.list : ICON_TYPES.grid} />
          </Button>
          <div className={css.right}>
            <Input
              value={searchText}
              searchActive={searchActive}
              placeholder={strings.inputSearchTextPlaceholder}
              className={isNotCustomer ? css.search : ''} // margin is not needed if 'add button' hidden
              disabled={isDeleting}
              onChange={handleSearchTextChange}
              toggleSearchActive={toggleSearchActive}
            />
            {isNotCustomer && <ArticlesAddNewSkuBtn customerId={customerId} />}
          </div>
        </div>
        <div className={css.content}>
          {isTableView && (
            <ArticlesTableView
              order={order}
              setOrder={handleSetOrder}
              status={status}
              setStatus={handleSetStatus}
              {...commonViewComponentsProps}
            />
          )}
          {!isTableView && (
            <ArticlesGridView {...commonViewComponentsProps} />
          )}
        </div>
        {isLoading && <div><Loader /></div> }
        {showEmptyState(!isLoading, data.data.length, !searchText) && (
          <EmptyState
            label={strings.emptyStateNoArticlesTitle}
            description={isNotCustomer ? strings.emptyStateNoArticlesDescription : ''}
            variant={EmptyStates.NO_SKU}
          >
            {isNotCustomer && <ArticlesAddNewSkuBtn customerId={customerId} />}
          </EmptyState>
        )}
        {showEmptyState(!isLoading, data.data.length, !!searchText) && (
          <div className={css.noContent}>
            <EmptyState label={strings.emptyStateTitleNoResults} description={strings.emptyStateTitleNoResultsDescription} variant={EmptyStates.NO_RESULTS} />
          </div>
        )}
        {!isLoading && data.data.length > 0 && (
          <Pagination
            className={css.pagination}
            page={page}
            count={Math.ceil(data.total / PAGE_SIZE_10) || undefined}
            disabled={isLoading || isDeleting}
            onChange={onChangePage}
          />
        )}
        <Modal
          isOpen={showModal}
          title={strings.articlesDeleteSKU}
          onRequestClose={toggleShowModal}
          onOk={handleArticleDelete}
          onCancel={toggleShowModal}
        >
          <div className={textCss.pMedium3}>{strings.articlesDeleteConfirm}</div>
        </Modal>
      </div>
    </PageContent>
  );
};

export default PageArticles;
