import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { SingleValue } from 'react-select';

import { TIncludedCustomer, TUser } from 'types';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Select from 'components/library/Select';
import Checkbox from 'components/library/Checkbox';
import { getUserFullName } from 'helpers';
import SimpleHeader from 'components/library/Table/components/SimpleHeader';
import Tooltip from 'components/library/Tooltip';
import { TReassignedCustomers } from '../types';
import css from '../BlockUserModal.module.css';

const useColumns = (
  managers: TUser[],
  onManagerSelect: (manager: SingleValue<TUser>, customerId: TUser['id']) => void,
  reassignedCustomers: TReassignedCustomers
):ColumnDef<TIncludedCustomer>[] => useMemo<ColumnDef<TIncludedCustomer>[]>(() => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        hovered
      />
    ),
    meta: {
      className: css.column
    },
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        wrapperClassName={css.checkboxCell}
      />
    )
  },
  {
    id: 'name',
    header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadCustomer} /></div>,
    meta: {
      className: css.column
    },
    cell: ({ row: { original } }) => {
      const { companyName } = original;
      const fullName = getUserFullName(original);
      return (
        <div className={css.columnNames}>
          <div className={css.displayFlex}>
            <Tooltip title={companyName} placement="top">
              <span className={textCss.h4} title={companyName}>{companyName}</span>
            </Tooltip>
          </div>
          <div className={css.displayFlex}>
            <Tooltip title={fullName} placement="top">
              <span className={textCss.pMedium2} title={fullName}>{fullName}</span>
            </Tooltip>
          </div>
        </div>
      );
    }
  },
  {
    id: 'contacts',
    header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadContacts} /></div>,
    meta: {
      className: css.column
    },
    cell: ({ row: { original } }) => {
      const { email, phoneNumber } = original;
      return (
        <div className={css.columnNames}>
          <div className={css.displayFlex}>
            <Tooltip title={email as string} placement="top">
              <span className={textCss.pMedium2}>{email}</span>
            </Tooltip>
          </div>
          {phoneNumber && (
            <div className={css.displayFlex}>
              <Tooltip title={phoneNumber} placement="top">
                <span className={textCss.pLight3}>{phoneNumber}</span>
              </Tooltip>
            </div>
          )}
        </div>
      );
    }
  },
  {
    id: 'managers',
    header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadManager} /></div>,
    meta: {
      className: css.column,
    },
    cell: ({ row: { original } }) => (
      <Select
        onChange={user => onManagerSelect(user, original.id)}
        value={reassignedCustomers[original.id]}
        options={managers}
        getOptionLabel={user => getUserFullName(user)}
        getOptionValue={user => user.id}
        isSearchable
      />
    )
  },
], [reassignedCustomers]);

export default useColumns;
