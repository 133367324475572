import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';
import { useLocation, useNavigate } from 'react-router-dom';
import { EOrderStatus, ERoles } from 'types';
import UserContext from 'contexts/ContextUser';
import useQueryParams from 'hooks/useQueryParams';
import { isCustomer, isManager } from 'helpers';
import AlertContext from 'contexts/ContextAlert';
import Orders, { IStateParams } from './Orders';

const OrdersContainer = () => {
  const contextUser = useContext(UserContext);
  const query = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const alertContext = useContext(AlertContext);

  const user = get(contextUser, 'user');
  const permissions = get(user, 'userPermissions', []);
  const pspId = get(contextUser, 'user.pspId', '');
  const role = user?.role as ERoles;

  const params = useMemo<IStateParams>(() => ({
    managerId: query.get('managerId') || undefined,
    customerId: query.get('customerId') || undefined,
    status: get(location.state, 'status') || query.get('status') as EOrderStatus,
    page: parseInt(get(location.state, 'page') || query.get('page') || '', 10),
    limit: get(location.state, 'limit'),
    search: get(location.state, 'search', ''),
    order: get(location.state, 'order'),
  }), [location, query]);

  if (isManager(permissions)) {
    params.managerId = get(user, 'id');
  }
  if (isCustomer(permissions)) {
    params.customerId = get(user, 'id');
  }

  return useMemo(() => (
    <Orders
      navigate={navigate}
      location={location}
      alertContext={alertContext}
      pspId={pspId}
      state={params}
      user={user}
      role={role}
    />
  ), [user, pspId, params, role, location]);
};

export default OrdersContainer;
