/* eslint-disable max-len */
import { ILanguages } from '../types';
import ICustomersStrings from '../types/CustomersStrings';

const strings:ILanguages<ICustomersStrings> = {
  en: {
    customersPageTitle: 'Events',
    customersPageButtonAddCustomer: 'Add Event',

    customersPageAddNewCustomerModalTitle: 'Add New Event',
    customersPageAddNewCustomerModalButtonNext: 'Next',

    customersPageSKUCellAddNewLabel: 'Add New',

    customersPageButtonViewAllCustomer: 'View all Events',
  },
  de: {
    customersPageTitle: 'Veranstaltungen',
    customersPageButtonAddCustomer: 'Veranstaltung hinzufügen',

    customersPageAddNewCustomerModalTitle: 'Kontaktdaten anlegen',
    customersPageAddNewCustomerModalButtonNext: 'Weiter',

    customersPageSKUCellAddNewLabel: 'Neu',

    customersPageButtonViewAllCustomer: 'Zur Veranstaltungsübersicht',
  },
  ru: {
    customersPageTitle: 'Мероприятия',
    customersPageButtonAddCustomer: 'Добавить мероприятие',

    customersPageAddNewCustomerModalTitle: 'Добавить новое мероприятие',
    customersPageAddNewCustomerModalButtonNext: 'Далее',

    customersPageSKUCellAddNewLabel: 'Новый',

    customersPageButtonViewAllCustomer: 'Посмотреть все мероприятия',
  },
};

export default strings;
