import React from 'react';
import cls from 'classnames';
import get from 'lodash/get';
import Label from 'components/library/Label';
import textCss from 'assets/styles/Text.module.css';
import strings from 'constants/localization';
import Select from 'components/library/Select';
import {
  TOptionValue, TPaper, TQuantity
} from 'types';
import { addValuesAfterComma } from 'helpers';
import FormItem from './FormItem';
import css from './OrderForm.module.css';
import { getSizeDescription } from '../../helper';
import { IOrderForm } from './types';

const OrderForm:React.FC<IOrderForm> = props => {
  const {
    orderComment,
    orderId,
    quantity,
    skuIdTitle,
    // productType,
    ticketType,
    options,
    size,
    isOrderCreation,
    paper,
    optionsState,
    displayOptions,
    price,
    papers,
    quantities,
    units,
    currency,
    printItemTitle,
    onChangeOption,
    onChangeQuantity,
    onChangePaper,
  } = props;
  return (
    <div className={css.container}>
      <div className={css.left}>
        {/* region: basic info */}
        <Label
          text={strings.orderPageFormProductDescriptionTitle}
          className={cls(textCss.pLight1, css.label)}
        />
        {!isOrderCreation
          ? (
            <FormItem
              value={orderId}
              label={strings.orderPageFormProductDescOrderId}
            />
          )
          : null}
        { ticketType && (
          <FormItem
            value={ticketType}
            label={strings.orderPageFormProductDescTicketTypeName}
          />
        )}
        <FormItem
          value={skuIdTitle}
          label={strings.orderPageFormProductDescSkuId}
        />
        {/* <FormItem */}
        {/*   value={PRODUCT_TYPES_LOCALIZATION[productType]()} */}
        {/*   label={strings.orderPageFormProductDescProductType} */}
        {/* /> */}
        <FormItem
          value={printItemTitle}
          label={strings.orderPageFormProductDescProductName}
        />
        <FormItem
          value={paper?.value}
          description={paper?.description}
          label={strings.orderPageFormProductDescPaper}
        >
          {isOrderCreation && papers.length && (
            <Select<TPaper>
              isSearchable={papers.length === 1 ? false : undefined}
              components={papers.length === 1 ? { DropdownIndicator: () => null } : undefined}
              menuIsOpen={papers.length === 1 ? false : undefined}
              menuPlacement={papers.length > 2 ? 'top' : undefined}
              className={css.select}
              defaultValue={papers[0]}
              value={paper}
              getOptionLabel={e => e.value}
              getOptionValue={e => e.id}
              onChange={onChangePaper}
              options={papers}
            />
          )}
        </FormItem>
        <FormItem
          value={size?.name}
          description={getSizeDescription(size, units)}
          label={strings.orderPageFormProductDescSize}
        />
        {/* endregion: basic info */}
        {displayOptions
          ? (
            <>
              <Label
                text={strings.orderPageFormItemOptionsTitle}
                className={cls(textCss.pLight1, css.label)}
              />
              {options.map(option => {
                const isOneOption = get(option, 'values.length', 0) <= 1;
                if (!option.values?.length) return null;
                return (
                  <FormItem key={option.option.name} label={option.option.name} value={optionsState[option.id]?.value?.value}>
                    {isOrderCreation && (
                      <Select<TOptionValue>
                        menuPlacement="top"
                        isSearchable={isOneOption ? false : undefined}
                        components={isOneOption ? { DropdownIndicator: () => null } : undefined}
                        menuIsOpen={isOneOption ? false : undefined}
                        getOptionLabel={e => e.value}
                        getOptionValue={e => e.id}
                        value={optionsState[option.id]?.value || null}
                        onChange={value => onChangeOption(option, value)}
                        options={option.values}
                      />
                    )}
                  </FormItem>
                );
              })}
            </>
          )
          : null }
      </div>
      <div className={css.right}>
        <Label
          text={strings.orderPageFormPricingTitle}
          className={cls(textCss.pLight1, css.label)}
        />
        <div className={css.pricing}>
          <FormItem label={strings.orderPageFormPricingQuantity} value={quantity?.value}>
            {isOrderCreation && quantities.length && (
              <Select<TQuantity>
                menuPlacement={quantities.length > 3 ? 'top' : undefined}
                className={css.select}
                isSearchable={quantities.length === 1 ? false : undefined}
                components={quantities.length === 1 ? { DropdownIndicator: () => null } : undefined}
                menuIsOpen={quantities.length === 1 ? false : undefined}
                defaultValue={quantities[0]}
                value={quantity}
                getOptionLabel={e => e.value.toString()}
                getOptionValue={e => e.id}
                onChange={onChangeQuantity}
                options={quantities}
              />
            )}
          </FormItem>
          <FormItem
            label={strings.orderPageFormPricingTotalPrice}
            value={addValuesAfterComma(String(price))}
            currency={currency}
          />
          <span />
        </div>
        {orderComment && (
          <FormItem
            value={orderComment}
            label={strings.orderPageFormProductDescSize}
          />
        )}
      </div>
    </div>
  );
};

export default OrderForm;
