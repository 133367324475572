import React, {
  useContext, useEffect, useMemo, useState
} from 'react';
import {
  Outlet, useLocation, useNavigate,
} from 'react-router-dom';

import useGetUser from 'hooks/useUser';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import UserContext from 'contexts/ContextUser';
import AlertContext from 'contexts/ContextAlert';
import { getBottomMenuItems, getTopMenuItems } from 'helpers';
import css from './Layout.module.css';
import Loader from '../library/Loader';
import MenuItem, { IMenuItem } from './components/MenuItem';
import Tooltip from '../library/Tooltip';

function Layout() {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const {
    data = null, isLoading, error
  } = useGetUser({ userId: userContext.user?.id });
  const itemsTop = useMemo<IMenuItem[]>(() => {
    if (data?.userPermissions) {
      return getTopMenuItems(data?.userPermissions, userContext.user?.id);
    }
    return [];
  }, [data?.userPermissions]);
  const itemsBottom = useMemo<IMenuItem[]>(() => getBottomMenuItems(), []);

  useEffect(() => {
    if (error?.response?.status === 401) {
      alertContext.push({ severity: 'error', message: strings.errorResponse401 });
      navigate(ROUTES.USER_LOGIN, { replace: true, state: { from: location } });
      return;
    }
    if (error?.response?.status === 403 && error.response?.data?.data?.reason === 'blocked') {
      alertContext.push({ severity: 'error', message: strings.errorResponse403Blocked });
      navigate(`${ROUTES.USER_LOGIN}/${ROUTES.USER_BLOCKED}`);
    }
  }, [error]);

  useEffect(() => {
    if (data && loading && !isLoading) {
      userContext.setUser(data);
      setLoading(false);
    }
  }, [data, loading, isLoading]);

  useEffect(() => {
    if (userContext.user && data && data !== userContext.user) {
      userContext.setUser(data);
    }
  }, [data, userContext.user]);

  useEffect(() => {
    if (!userContext.user) {
      navigate(ROUTES.USER_LOGIN, { replace: true, state: { from: location } });
    }
  }, [userContext.user]);

  return (
    <div className={css.container}>
      <div className={css.sidebar}>
        <Tooltip title={process.env.REACT_APP_VERSION} className={css.logoContainer}>
          <Icon type={ICON_TYPES.logo} className={css.logo} />
        </Tooltip>
        <div className={css.itemsContainer}>
          <div className={css.itemsTop}>
            {itemsTop.map(item => <MenuItem key={item.title()} {...item} />)}
          </div>
          <div className={css.itemsBottom}>
            {itemsBottom.map(item => <MenuItem key={item.title()} {...item} />)}
          </div>
        </div>
      </div>
      <div className={css.content}>
        {isLoading || loading
          ? (
            <div className={css.loader}>
              <Loader />
            </div>
          )
          : <Outlet />}
      </div>
    </div>
  );
}

export default React.memo(Layout);
