import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import textCss from 'assets/styles/Text.module.css';
import { ROUTES } from 'constants/constants';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import {
  EArticleStatuses, ETemplateSides, EUnits, TArticle, TArticleDocument,
} from 'types';
import ContextAlert from 'contexts/ContextAlert';
import classNames from 'classnames';
import css from './TemplateTab.module.css';
import Side from './components/Side';
import { IArticleFormValues, TEMPLATE_FORM } from '../../constants';
import { IPdfBox } from '../../../../../components/Editor/contexts/EditorContext';

export const getFieldName = (fieldName: ETemplateSides): string => `${TEMPLATE_FORM}.${fieldName === ETemplateSides.FRONT ? 'frontSide' : 'backSide'}`;

export interface IUploadStatus {
  [ETemplateSides.FRONT]: boolean,
  [ETemplateSides.BACK]: boolean,
}

const INITIAL_UPLOAD_STATUS = {
  [ETemplateSides.FRONT]: false,
  [ETemplateSides.BACK]: false,
};

export interface ITabTemplate {
  customerId: string | undefined,
  formik: FormikProps<IArticleFormValues>,
  article: TArticle,
}

const TabTemplate: React.FC<ITabTemplate> = props => {
  const { push } = useContext(ContextAlert);
  const { id } = useParams();
  const navigate = useNavigate();
  const { formik, article, customerId } = props;
  const { printItem } = article;
  const { setFieldValue, values } = formik;
  const {
    productInfo: { title: articleTitle },
    template: { frontSide, backSide },
    productSetup: { size },
  } = values;
  const handleSideDelete = (deleteSide: ETemplateSides) => setFieldValue(getFieldName(deleteSide), undefined);
  const handleSideUpload = (newSide: TArticleDocument) => setFieldValue(getFieldName(newSide.side), newSide);

  const unit = useMemo<EUnits>(() => printItem.units as EUnits, [printItem.units]);
  const pdfBox = useMemo<IPdfBox>(() => {
    const { width, height, bleed } = size ?? { width: 0, height: 0, bleed: 0 };
    return {
      width, height, bleed, unit
    };
  }, [unit, size]);

  const handleClickOpenEditor = () => navigate(
    `${ROUTES.BASE}${ROUTES.ARTICLES}/${id || ROUTES.ROUTE_ADD}/${ROUTES.TEMPLATE_EDITOR}?customerId=${customerId}`,
    {
      state: {
        pdfBox, frontSide, backSide, articleTitle
      },
    }
  );

  const [uploadStatus, setUploadStatus] = useState<IUploadStatus>(INITIAL_UPLOAD_STATUS);
  const handleChangeUploadStatus = (side: ETemplateSides, status: boolean) => setUploadStatus({ ...uploadStatus, [side]: status });

  const isSizeSelected = useMemo<boolean>(() => !!size, [size]);
  const isEditorDisabled = useMemo<boolean>(
    () => values.footer.status.value !== EArticleStatuses.DRAFT || !isSizeSelected,
    [values.footer.status.value, isSizeSelected]
  );
  const isOpenEditorButtonDisabled = useMemo<boolean>(() => isEditorDisabled || Object.values(uploadStatus).some(v => v), [isEditorDisabled, uploadStatus]);

  useEffect(() => {
    if (formik.errors.template) {
      push({ severity: 'error', message: formik.errors.template as string });
    }
  }, [formik.errors.template]);

  const sizeDescription = useMemo<string>(
    () => size
      ? `${size.name} (${size.width} x ${size.height} ${unit})`
      : strings.templateTabSelectSizeAtTheProductSetup,
    [size]
  );

  return (
    <div className={css.container}>
      <header className={css.header}>
        <section>
          <span className={textCss.pLight1}>{strings.articleFormTabTemplate}</span>
          <span className={classNames(textCss.pMedium3, css.info)}>{ sizeDescription }</span>
        </section>
        <section>
          <Button
            disabled={isEditorDisabled || isOpenEditorButtonDisabled}
            onClick={handleClickOpenEditor}
            type="button"
            buttonType="primary"
          >{ strings.openEditorButton }</Button>
        </section>
      </header>
      <div className={css.sideContainer}>
        <Side
          pdfBox={pdfBox}
          disabled={isEditorDisabled}
          onSideUpload={handleSideUpload}
          onSideDelete={handleSideDelete}
          onChangeUploadStatus={handleChangeUploadStatus}
          side={ETemplateSides.FRONT}
          json={frontSide?.canvas}
          sideData={frontSide}
          thumbnail={frontSide?.thumbnail}
        />
        <Side
          pdfBox={pdfBox}
          disabled={isEditorDisabled}
          onSideUpload={handleSideUpload}
          onSideDelete={handleSideDelete}
          onChangeUploadStatus={handleChangeUploadStatus}
          side={ETemplateSides.BACK}
          json={backSide?.canvas}
          sideData={backSide}
          thumbnail={backSide?.thumbnail}
        />
      </div>
    </div>
  );
};

export default TabTemplate;
