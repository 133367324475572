/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconError(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99996 5.83366C10.4583 5.83366 10.8333 6.20866 10.8333 6.66699V10.0003C10.8333 10.4587 10.4583 10.8337 9.99996 10.8337C9.54163 10.8337 9.16663 10.4587 9.16663 10.0003V6.66699C9.16663 6.20866 9.54163 5.83366 9.99996 5.83366ZM9.99163 1.66699C5.39163 1.66699 1.66663 5.40033 1.66663 10.0003C1.66663 14.6003 5.39163 18.3337 9.99163 18.3337C14.6 18.3337 18.3333 14.6003 18.3333 10.0003C18.3333 5.40033 14.6 1.66699 9.99163 1.66699ZM9.99996 16.667C6.31663 16.667 3.33329 13.6837 3.33329 10.0003C3.33329 6.31699 6.31663 3.33366 9.99996 3.33366C13.6833 3.33366 16.6666 6.31699 16.6666 10.0003C16.6666 13.6837 13.6833 16.667 9.99996 16.667ZM10.8333 14.167H9.16663V12.5003H10.8333V14.167Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconError;
