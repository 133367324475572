import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import cls from 'classnames';

import ArticlesControls from 'components/PageArticles/components/ArticlesControls';
import ArticlesStatus from 'components/PageArticles/components/ArticlesStatus';
import FilterHeader from 'components/library/Table/components/FilterHeader';
import SimpleHeader from 'components/library/Table/components/SimpleHeader';
import Tooltip from 'components/library/Tooltip';

import { ETablePageViews, TGetArticlesItem } from 'types';
import { IArticlesDisplayProps, IArticlesTableFilters } from 'types/Articles';
import { STATUS_ORDER_OPTIONS, TITLE_ORDER_OPTIONS } from 'constants/constants';
import strings from 'constants/localization';
import { displayArticleQuantities, getPreviewImageSrc } from 'helpers';
import textCss from 'assets/styles/Text.module.css';
import css from './ArticlesTableView.module.css';

interface IUseColumns extends IArticlesTableFilters, Omit<IArticlesDisplayProps, 'articles' | 'handleArticleClick'> {

}
const useColumns = ({
  status, setStatus, order, setOrder, ...deleteArticleActions
}: IUseColumns): ColumnDef<TGetArticlesItem>[] => useMemo<ColumnDef<TGetArticlesItem>[]>(
  () => [
    {
      id: 'pdf',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadPreview} /></div>,
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => (
        <div className={css.pdfPreviewContainer}>
          <img src={getPreviewImageSrc(props.row.original.templates)} alt={strings.pdfPreview} />
        </div>
      ),
    },
    {
      id: 'title',
      header: () => (
        <FilterHeader
          orderOptions={TITLE_ORDER_OPTIONS}
          order={order}
          className={css.columnHeadFilter}
          title={strings.columnHeadProduct}
          setOrder={setOrder}
        />
      ),
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => {
        const { title, description } = props.row.original;
        return (
          <div className={css.columnNames}>
            <div className={css.displayFlex}>
              <Tooltip title={title} placement="top">
                <span className={textCss.h4}>{title}</span>
              </Tooltip>
            </div>
            <div className={css.displayFlex}>
              <Tooltip title={description} placement="top">
                <span className={textCss.pLight2}>{description}</span>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      id: 'status',
      header: () => (
        <FilterHeader
          orderOptions={STATUS_ORDER_OPTIONS}
          order={status}
          className={css.columnHeadFilter}
          title={strings.columnHeadStatus}
          setOrder={setStatus}
        />
      ),
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => (
        <div className={css.centeredCell}>
          <ArticlesStatus status={props.row.original.status} isTableView />
        </div>
      )
    },
    {
      id: 'quantities',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadAvailableQtys} /></div>,
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => {
        const qties = displayArticleQuantities(props.row.original.papers);
        return (
          <div className={css.centeredCell}>
            <Tooltip title={qties} placement="top">
              <span>{qties}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: 'ticketType',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadTicketType} /></div>,
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => {
        const ticketType = props.row.original?.integrationTicketType || '';
        return (
          <div className={css.centeredCell}>
            <Tooltip title={ticketType} placement="top">
              <span>{ticketType}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      id: 'actions',
      header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadActions} /></div>,
      meta: {
        className: cls(css.column, { [css.withStatus]: deleteArticleActions.isNotCustomer })
      },
      cell: props => {
        const { id, status: articleStatus, customerId } = props.row.original;
        return (
          <div className={css.centeredCell}>
            <ArticlesControls
              view={ETablePageViews.TABLE}
              articleId={id}
              status={articleStatus}
              {...deleteArticleActions}
              customerId={customerId}
            />
          </div>
        );
      },
    },
  ],
  [order, status]
);

export default useColumns;
