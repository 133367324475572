import React, {
  ChangeEventHandler, Dispatch, RefObject, SetStateAction, useMemo
} from 'react';
import { ColumnDef } from '@tanstack/react-table';
import cls from 'classnames';

import Icon, { ICON_TYPES } from 'components/library/Icon';
import Input from 'components/library/Input';

import { TPrintItemForm } from 'types';
import { TOptionalPricingTableRow } from 'types/Portfolio';
import { MIN_PAGE } from 'constants/constants';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import { FormikHelpers } from 'formik';
import css from './OptionalPricing.module.css';
import { handlePriceFieldChange, preventEnterClick, twoCharactersAfterCommaOnBlur } from '../../helpers';

interface IOptionalPricingColumns {
  firstFieldWithError: number,
  fieldWithErrorRef: RefObject<HTMLInputElement> | null,
  quantities: string[],
  page: number,
  pagesAmount: number,
  handleChange: ChangeEventHandler<HTMLInputElement>,
  setPage: Dispatch<SetStateAction<number>>,
  handleBlur: (e: React.FocusEvent<any>)=> void,
  setFieldValue: FormikHelpers<TPrintItemForm>['setFieldValue']
}

const useColumns = ({
  quantities, page, pagesAmount, handleChange, setPage, handleBlur, setFieldValue, firstFieldWithError, fieldWithErrorRef
}: IOptionalPricingColumns): ColumnDef<TOptionalPricingTableRow>[] => useMemo<ColumnDef<TOptionalPricingTableRow>[]>(
  () => [
    {
      id: 'showNested',
      header: () => <span />,
      meta: {
        className: css.column
      },
      cell: ({ row }) => row.getCanExpand() && (
        <div
          style={{
            paddingLeft: `${row.depth * 2}rem`
          }}
        >
          <div
            className={cls(css.expandContainer, css.option)}
            onClick={row.getToggleExpandedHandler()}
          >
            <Icon className={cls({ [css.expanded]: !row.getIsExpanded() })} type={ICON_TYPES.arrowUp} />
          </div>
        </div>
      ),
    },
    {
      id: 'optionLabel',
      header: strings.columnHeadOptionLabel,
      meta: {
        className: css.column
      },
      cell: props => <div className={cls(textCss.pMedium2, css.option)}>{props.row.original.optionLabel}</div>,
    },
    {
      id: 'optionValue',
      header: strings.columnHeadOptionValue,
      meta: {
        className: css.column
      },
      cell: props => <div className={cls(textCss.pMedium2, css.option)}>{props.row.original.optionValue}</div>,
    },
    {
      id: 'lineArrowLeft',
      header: () => page !== MIN_PAGE
        ? (
          <Icon
            className={cls(css.icon, css.rightArrowToLeft)}
            type={ICON_TYPES.lineArrowRight}
            onClick={() => setPage(page - 1)}
          />
        ) : <span />,
      meta: {
        className: css.iconColumn,
      },
      cell: () => <span />
    },
    ...quantities.map((quantity: string, idx) => ({
      id: quantity,
      header: strings.formatString(strings.columnHeadQtyWithValue, quantity),
      meta: {
        className: css.dynamicColumn
      },
      cell: ({ row: { original: { inputData } } }) => {
        const {
          inputName, inputValue, inputError, inputOrder, disabled
        } = inputData[idx];
        const onBlur = (e: React.FocusEvent<HTMLInputElement>) => twoCharactersAfterCommaOnBlur({
          e, inputName, handleBlur, setFieldValue
        });
        const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => handlePriceFieldChange(e, handleChange);
        const obj: { ref?: RefObject<HTMLInputElement> | null } = {};
        if (firstFieldWithError === inputOrder) {
          obj.ref = fieldWithErrorRef;
        }
        return (
          <form noValidate>
            <Input
              {...obj}
              inputClassName={css.input}
              name={inputName}
              value={inputValue}
              withError
              error={inputError}
              disabled={disabled}
              onChange={changeHandler}
              onBlur={onBlur}
              onKeyDown={preventEnterClick}
              symbol={<span className={css.symbol}>€</span>}
            />
          </form>
        );
      }
    } as ColumnDef<TOptionalPricingTableRow>)),
    {
      id: 'lineArrowRight',
      header: () => pagesAmount !== page
        ? (
          <Icon
            className={css.icon}
            type={ICON_TYPES.lineArrowRight}
            onClick={() => setPage(page + 1)}
          />
        ) : <span />,
      meta: {
        className: css.iconColumn,
      },
      cell: () => <span />
    },
  ],
  [quantities, page, pagesAmount, handleChange, setPage, handleBlur]
);

export default useColumns;
