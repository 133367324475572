import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import Popover from 'components/library/Popover';
import { useDebounceCallback } from '@react-hook/debounce';
import strings from 'constants/localization';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import Icon, { ICON_TYPES } from '../../../../../../../library/Icon';
import SidebarButton from '../../../components/SidebarButton';
import SidebarSlider from '../../../components/SidebarSlider';
import css from './BarcodeHeight.module.css';

const sliderProps = {
  min: 10,
  max: 150,
  step: 5,
};

const BarcodeHeight:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const [value, setValue] = useState<number>(activeObject.barcodeHeight);

  useEffect(() => handleChange(value), [value]);

  // update value, when activeObject changed
  useEffect(() => setValue(activeObject.barcodeHeight), [activeObject.barcodeHeight]);

  const handleChange = useDebounceCallback(async (newValue: number) => {
    if (!canvas.current || activeObject.barcodeHeight === newValue) {
      return;
    }

    activeObject.set({ barcodeHeight: newValue });
    await activeObject.generate();
    canvas.current.fire('object:modified', { target: activeObject });
  }, 500);

  return (
    <>
      <Tooltip placement="top" title={strings.editorTabSettingsBarcodeHeightTooltip} arrow>
        <SidebarButton
          ref={ref}
          isActive={popoverState}
          onClick={() => setPopoverState(!popoverState)}
        >
          <Icon type={ICON_TYPES.height} />
        </SidebarButton>
      </Tooltip>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <SidebarSlider
          onChange={setValue}
          value={value}
          withInput
          {...sliderProps}
        />
      </Popover>
    </>
  );
};

export default BarcodeHeight;
