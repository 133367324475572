import React, {
  useCallback, useContext, useMemo, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import { FormikProps } from 'formik';

import textCss from 'assets/styles/Text.module.css';
import Icon from 'components/library/Icon';
import Button from 'components/library/Button';
import strings from 'constants/localization';
import Popover from 'components/library/Popover';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import {
  EOrderStatus, EArticleStatuses, TStat, TUser
} from 'types';
import ContextAlert from 'contexts/ContextAlert';
import { navigateBack } from 'helpers';
import UserContext from 'contexts/ContextUser';
import Modal from 'components/library/Modal';
import { useUserDelete } from 'hooks/useUser';
import BlockUserModal from 'components/Modals/BlockUserModal';
import css from './SidebarContent.module.css';
import { checkPermission, EActions } from '../../permission';
import {
  IFormSidebarValues, IFormsValues, SIDEBAR_FORM
} from '../../constants';
import { getBlockUserLabel, getRemoveUserLabel, onFileInputHandler } from '../../helpers';
import UserStatistics from './components/UserStatistics';
import Inputs from './components/Inputs';
import GeneralUserInfo from './components/GeneralUserInfo';

export interface ISidebar extends React.HTMLProps<HTMLDivElement> {
  user: TUser,
  isSelf: boolean,
  editable: boolean,
  allowEditMeta: boolean,
  formik: FormikProps<IFormsValues>,
}

const getFieldName = (fieldName: keyof IFormSidebarValues): string => `${SIDEBAR_FORM}.${fieldName}`;

const SidebarContent:React.FC<ISidebar> = ({
  formik, isSelf, editable, user, allowEditMeta
}) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const deleteUser = useUserDelete();
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const [userBlockModal, setUserBlockModal] = useState<boolean>(false);
  const [userDeleteModal, setUserDeleteModal] = useState<boolean>(false);
  const ref = useRef<SVGSVGElement>(null);
  const { push: pushAlert } = useContext(ContextAlert);
  const { getFieldProps, setFieldValue } = formik;
  const {
    id, blocked, email, phoneNumber, role,
    articleStats = [], orderStats = [], userPermissions = [],
  } = user || {};
  const removeUserLabel: string = getRemoveUserLabel(role);
  const blockUserLabel: string = getBlockUserLabel(blocked, role);
  const { value: avatar } = getFieldProps(getFieldName('avatar'));

  const onChangeAvatar = useCallback(
    () => editable && onFileInputHandler(setFieldValue, getFieldName('avatar'), pushAlert),
    [editable]
  );
  const stats = useMemo<[TStat<EArticleStatuses>[], TStat<EOrderStatus>[]] | []>(() => {
    if (!checkPermission(EActions.VIEW_STATS, userContext.user?.userPermissions || [], role, isSelf)) return [];
    return [articleStats, orderStats];
  }, [articleStats, orderStats, userPermissions]);

  const onDeleteUser = (): void => {
    deleteUser.mutate(id, {
      onSuccess: () => {
        navigateBack(navigate);
      },
      onError: err => {
        pushAlert({
          message: err.response?.data.data?.reason || strings.errorResponse5,
          severity: 'error'
        });
      }
    });
  };

  return (
    <div className={css.container}>
      <div className={css.person}>
        {allowEditMeta && !isSelf
          ? (
            <Icon
              passedRef={ref}
              type={E_ICON_TYPE.dots}
              className={cls(css.dots, { [css.none]: allowEditMeta && !editable })}
              onClick={() => setPopoverState(!popoverState)}
            />
          ) : null}
        <div
          className={cls(
            css.avatar,
            {
              [css.nonEditable]: !editable,
              [css.blocked]: blocked
            }
          )}
          onClick={onChangeAvatar}
        >
          {!avatar
            ? <div className={css.defaultAvatar}><Icon type={E_ICON_TYPE.bag} /></div>
            : <img alt="avatar" src={avatar} /> }
        </div>
        <GeneralUserInfo user={user} />
        {allowEditMeta && !isSelf && (
          <Popover
            open={popoverState}
            anchorEl={ref.current}
            onClose={() => setPopoverState(false)}
          >
            <div className={css.actions}>
              <Button
                className={textCss.pMedium3}
                buttonType="tertiary"
                onClick={() => {
                  setUserBlockModal(true);
                  setPopoverState(false);
                }}
              >
                {blockUserLabel}
              </Button>
              {!userPermissions.length && (
                <Button
                  className={textCss.pMedium3}
                  buttonType="tertiary"
                  onClick={() => {
                    setUserDeleteModal(true);
                    setPopoverState(false);
                  }}
                >
                  {removeUserLabel}
                </Button>
              )}
            </div>
          </Popover>
        )}
      </div>

      <Inputs email={email} phoneNumber={phoneNumber} />
      <UserStatistics userId={id} stats={stats} />
      <BlockUserModal
        isOpen={userBlockModal}
        closeModal={() => setUserBlockModal(false)}
        userToBlock={user}
      />
      <Modal
        isOpen={userDeleteModal}
        onRequestClose={() => setUserDeleteModal(false)}
        onOk={onDeleteUser}
        onCancel={() => setUserDeleteModal(false)}
        title={removeUserLabel}
      >
        <span className={textCss.pMedium3}>
          {strings.profileSidebarUserDeleteModalConfirmation}
        </span>
      </Modal>
    </div>
  );
};

export default React.memo(SidebarContent);
