import React from 'react';
import cls from 'classnames';
import textCss from 'assets/styles/Text.module.css';
import { FormikProps } from 'formik';
import Input from 'components/library/Input';
import strings from 'constants/localization';
import {
  COMPANY_INFO_FORM, IFormCompanyInfoValues, IFormsValues, TCompanyInfoDisabled
} from '../constants';
import css from './Tab.module.css';

interface ITabCompanyInfo {
  formik: FormikProps<IFormsValues>,
  readOnly: boolean
  disabled: TCompanyInfoDisabled
}

const TabCompanyInfo:React.FC<ITabCompanyInfo> = ({ disabled, formik, readOnly = true }) => {
  const {
    getFieldProps,
    touched: { COMPANY_INFO_FORM: touched },
    errors: { COMPANY_INFO_FORM: errors }
  } = formik;
  const getFieldName = (fieldName: keyof IFormCompanyInfoValues): string => `${COMPANY_INFO_FORM}.${fieldName}`;
  const getFieldError = (fieldName: keyof IFormCompanyInfoValues): string | undefined => (
    touched && touched[fieldName] && errors && errors[fieldName]) || undefined;
  return (
    <>
      <span className={cls(textCss.pLight1)}>{strings.profileTabCompanyInfo}</span>
      <form noValidate>
        <Input
          {...getFieldProps(getFieldName('companyName'))}
          readOnly={readOnly}
          disabled={disabled.companyName}
          withError
          error={getFieldError('companyName')}
          className={css.input}
          label={strings.profilePageInputCompanyNameLabel}
          type="text"
          placeholder={strings.profilePageInputCompanyNameLabel}
        />
        <Input
          {...getFieldProps(getFieldName('position'))}
          readOnly={readOnly}
          disabled={disabled.position}
          withError
          error={getFieldError('position')}
          className={css.input}
          label={strings.profilePageInputPositionLabel}
          type="text"
          placeholder={strings.profilePageInputPositionLabel}
          labelProps={{ optional: true }}
        />
        <Input
          {...getFieldProps(getFieldName('sendOrderSheetsTo'))}
          withError
          readOnly={readOnly}
          disabled={disabled.sendOrderSheetsTo}
          error={getFieldError('sendOrderSheetsTo')}
          className={css.input}
          label={strings.profilePageInputSendOrderSheetsToLabel}
          type="email"
          placeholder={strings.inputEmailPlaceholder}
        />
      </form>
    </>
  );
};

export default TabCompanyInfo;
