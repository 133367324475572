import React, { useContext } from 'react';
import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import strings from 'constants/localization';
import ContextAlert from 'contexts/ContextAlert';
import { fileToFabricSide } from './helpers/fabric';
import css from './Editor.module.css';
import useEditorData from './hooks/useEditorData';
import Viewport from './components/Viewport/Viewport';
import Toolbar from '../../components/Editor/components/Toolbar/Toolbar';
import UploadArea from './components/UploadArea';
import UploadingLoader from './components/UploadingLoader';

const Editor:React.FC = () => {
  const { push } = useContext(ContextAlert);
  const {
    side,
    canvas,
    mode,
    json,
    setJson,
    pdfBox,
    pdfDocument,
    setPdfDocument,
    viewport,
    setObjects,
    isChanged,
    uploadingStatus,
    setUploadingStatus,
    setIsChanged,
    setCanvasLoadingStatus
  } = useEditorData();

  const uploadAreaHandleChange = async (file: File) => {
    if (file && !pdfDocument) {
      setUploadingStatus(true);
      try {
        const data = await fileToFabricSide(file, pdfBox, side);

        setJson(data.canvas);
        setPdfDocument(data.document);
        setIsChanged(true);
        setUploadingStatus(false);
        push({ severity: 'success', message: strings.templateLoadingStatusDone });
      } catch (e) {
        setUploadingStatus(false);
        const { message } = e as Error;
        push({ severity: 'error', message, timeout: 5000 });
      }
    }
  };

  const renderElement = (): JSX.Element => {
    if (uploadingStatus) return <UploadingLoader status={uploadingStatus} />;
    if (pdfDocument) {
      return (
        <Viewport
          side={side}
          mode={mode}
          viewport={viewport}
          json={json}
          setObjects={setObjects}
          pdfDocument={pdfDocument}
          canvas={canvas}
          setCanvasLoadingStatus={setCanvasLoadingStatus}
        />
      );
    }
    return <UploadArea side={side} handleChange={uploadAreaHandleChange} />;
  };

  return (
    <>
      <div className={css.container} style={{ width: viewport.width }}>
        <Toolbar />
        <div className={css.contentWrapper} style={{ width: viewport.width, height: viewport.height }}>
          { renderElement() }
        </div>
      </div>
      <UnsavedChangesModal areChangesExist={isChanged} />
    </>
  );
};

export default Editor;
