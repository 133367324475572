import React, { useMemo, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import Popover from 'components/library/Popover';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import strings from 'constants/localization';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import css from './TextSpacing.module.css';
import SidebarButton from '../../../components/SidebarButton';
import SidebarSlider from '../../../components/SidebarSlider';

const lineHeightSliderProps = {
  icon: ICON_TYPES.textLineHeight,
  min: 0.5,
  max: 6.5,
  step: 0.01,
};

const CHAR_SPACING_FACTOR = 25;
const charSpacingSliderProps = {
  icon: ICON_TYPES.textCharSpacing,
  min: -7,
  max: 30,
};

enum ESpacingFields {
  LineHeight = 'lineHeight',
  CharSpacing = 'charSpacing',
}

const TextSpacing:React.FC = () => {
  const [popoverState, setPopoverState] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);

  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChange = (field: ESpacingFields, newValue: number | number[]) => {
    if (canvas.current) {
      const value = Array.isArray(newValue) ? newValue[0] : newValue;

      activeObject.set({ [field]: value });
      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  const charSpacingValue = useMemo(() => activeObject.charSpacing / CHAR_SPACING_FACTOR, [activeObject.charSpacing]);

  return (
    <>
      <Tooltip placement="top" title={strings.editorTabSettingsTextSpacingTooltip} arrow>
        <SidebarButton
          ref={ref}
          isActive={popoverState}
          onClick={() => setPopoverState(!popoverState)}
        >
          <Icon type={ICON_TYPES.textSpacing} />
        </SidebarButton>
      </Tooltip>
      <Popover
        open={popoverState}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setPopoverState(false)}
        PaperProps={{ className: css.paper }}
      >
        <div className={css.container}>
          <SidebarSlider
            onChange={newValue => handleChange(ESpacingFields.LineHeight, newValue)}
            value={activeObject.lineHeight}
            withInput
            {...lineHeightSliderProps}
          />
          <SidebarSlider
            onChange={newValue => handleChange(ESpacingFields.CharSpacing, (newValue * CHAR_SPACING_FACTOR))}
            value={charSpacingValue}
            withInput
            {...charSpacingSliderProps}
          />
        </div>
      </Popover>
    </>
  );
};

export default TextSpacing;
