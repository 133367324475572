import _uniqWith from 'lodash/uniqWith';
import _groupBy from 'lodash/groupBy';
import _isEqual from 'lodash/isEqual';
import _uniqueId from 'lodash/uniqueId';

import { TPrintItemNestedField, TPrintItemOptionalPricing, TPrintItemOptionPortfolio } from 'types';
import {
  TOptionalPricingTableRow, TPrintItemFormTablesInput, TPrintItemPricingsError, TPrintItemPricingsTouched
} from 'types/Portfolio';
import { MIN_PAGE } from 'constants/constants';
import { createInputData } from '../../helpers';
import { OPTIONAL_PRICINGS } from '../../constants';

export const OPTIONAL_PRICINGS_PAGE_SIZE = 5;

export const groupOptionalPricingsByLabel = (
  uniqueQuantitiesSliced: string[],
  optPricingsErrors: TPrintItemPricingsError[],
  touched: TPrintItemPricingsTouched[],
  optPricings: TPrintItemOptionalPricing[],
  options: TPrintItemOptionPortfolio[],
): { [key: string]: TOptionalPricingTableRow[] } => {
  const optionalPricingsWithLabels = optPricings.map(optPricing => {
    const { option: optPricingOption } = optPricing;
    const generalOption = options.find(opt => opt.key === optPricingOption.key);
    return { ...optPricing, option: { ...optPricingOption, label: generalOption?.name } };
  });
  const uniqOptionalPricings = _uniqWith(optionalPricingsWithLabels.map(({ option: { value, label = '' } }) => {
    const inputData: TPrintItemFormTablesInput[] = [];
    uniqueQuantitiesSliced.forEach((quantity: string) => {
      // possibly optimizable if create idx counting formula using quantityIdx, optionIdx and page
      const optPricingIdx = optionalPricingsWithLabels
        .findIndex(({ quantity: optPricingQty, option: optPricingOpt }) => optPricingQty.value === quantity
          && optPricingOpt.value === value && optPricingOpt.label === label);
      inputData.push(
        createInputData(
          optPricingIdx,
          optPricingsErrors,
          touched,
          optionalPricingsWithLabels,
          optionalPricingsWithLabels[optPricingIdx]?.id,
          OPTIONAL_PRICINGS
        ) as TPrintItemFormTablesInput
      );
    });
    return ({
      id: value,
      optionLabel: label,
      optionValue: value,
      inputData
    });
  }), _isEqual);
  return _groupBy(uniqOptionalPricings, 'optionLabel');
};

export const findPageNumber = (
  quantities: TPrintItemNestedField[],
  optionalPricings: TPrintItemOptionalPricing[],
  optPricingsErrors: TPrintItemPricingsError[],
  currentPage: number | null = 0,
): number => {
  const moreThanOnePage = (quantities?.length || 0) > OPTIONAL_PRICINGS_PAGE_SIZE;
  if (moreThanOnePage) {
    const optPricingErrorIdx = optPricingsErrors?.findIndex(err => err);
    if (optPricingErrorIdx >= 0) { // error idx goes to corresponding optional pricing, so idx cant be wrong
      const { quantity: { value: optPricingQuantityValue } } = optionalPricings[optPricingErrorIdx];
      const quantityColumnErrorIdx = quantities?.findIndex(quantityItem => quantityItem.value === optPricingQuantityValue);
      const pageSizeComparingToIdx = OPTIONAL_PRICINGS_PAGE_SIZE - 1;
      if (quantityColumnErrorIdx > pageSizeComparingToIdx) {
        const pageToSet = Math.ceil(quantityColumnErrorIdx / pageSizeComparingToIdx);
        if (!!currentPage || currentPage !== pageToSet) {
          return pageToSet;
        }
      }
    }
  }
  return MIN_PAGE;
};

export const getNewOptionalPricings = (
  quantities: TPrintItemNestedField[],
  options: TPrintItemOptionPortfolio[],
  optionalPricings: TPrintItemOptionalPricing[],
  currency = '€'
): TPrintItemOptionalPricing[] => {
  const optionValues = options.map(option => option.values.map(value => ({ label: option.name, ...value }))).flat();
  return optionValues
    .map(({
      value, key, id, ...optionRest
    }, optionValueIdx) => quantities
      .map(({ value: quantityValue, ...quantityRest }, quantityIdx) => {
        const existedOptionalPricing: TPrintItemOptionalPricing | undefined = optionalPricings.find(({
          option: optPricingOption, quantity: optPricingQuantity
        }) => optPricingOption.value === value && optPricingQuantity.value === quantityValue
            && (id ? id === optPricingOption.id : key === optPricingOption.key));
        const order = optionValueIdx * quantities.length + quantityIdx;
        return existedOptionalPricing
          ? { ...existedOptionalPricing, order }
          : {
            option: {
              ...optionRest, value, key, id
            },
            quantity: { ...quantityRest, value: quantityValue },
            price: '',
            key: _uniqueId(),
            currency,
            order
          } as TPrintItemOptionalPricing;
      }))
    .flat();
};
