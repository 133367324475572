import React, { useMemo } from 'react';
import { FieldInputProps } from 'formik';
import Input from 'components/library/Input';
import strings from 'constants/localization';
import containerCss from '../../Tab.module.css';
import { INTEGRATION_TOKEN } from '../index';

interface IEventbriteConnectionForm {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>,
  isSubmitting: boolean
}

const EventbriteConnectionForm:React.FC<IEventbriteConnectionForm> = props => {
  const {
    getFieldProps,
    isSubmitting,
  } = props;

  const disabled = useMemo(() => isSubmitting, [isSubmitting]);

  return (
    <Input
      {...getFieldProps(INTEGRATION_TOKEN)}
      label={strings.inputTokenLabel}
      className={containerCss.input}
      disabled={disabled}
    />
  );
};

export default EventbriteConnectionForm;
