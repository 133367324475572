import React from 'react';
import cls from 'classnames';
import css from './Sidabar.module.css';

interface ISidebar extends React.HTMLProps<HTMLDivElement>{}
const Sidebar:React.FC<ISidebar> = props => {
  const { className, children, ...rest } = props;
  return (
    <div className={cls(css.sidebar, className)} {...rest}>
      <div className={css.content}>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
