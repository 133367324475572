/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextLineHeight(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M10.5 10.5C10.5 10.0858 10.8358 9.75 11.25 9.75H21.75C22.1642 9.75 22.5 10.0858 22.5 10.5C22.5 10.9142 22.1642 11.25 21.75 11.25H11.25C10.8358 11.25 10.5 10.9142 10.5 10.5Z" fill="#A6CBDF" />
      <path d="M11.25 21.75C11.25 21.3358 11.5858 21 12 21H21.75C22.1642 21 22.5 21.3358 22.5 21.75C22.5 22.1642 22.1642 22.5 21.75 22.5H12C11.5858 22.5 11.25 22.1642 11.25 21.75Z" fill="#A6CBDF" />
      <path d="M19.2035 19.7901C19.3146 20.0679 19.5837 20.25 19.8828 20.25C20.4101 20.25 20.7642 19.7092 20.5535 19.2259L17.9917 13.3503C17.8328 12.9857 17.4728 12.75 17.0751 12.75H16.6749C16.2772 12.75 15.9172 12.9857 15.7583 13.3503L13.1965 19.2259C12.9858 19.7092 13.3399 20.25 13.8672 20.25C14.1663 20.25 14.4354 20.0679 14.5465 19.7901L14.9625 18.75H18.7875L19.2035 19.7901ZM15.5625 17.25L16.875 13.9725L18.1875 17.25H15.5625Z" fill="#A6CBDF" />
      <path d="M19.2035 8.54008C19.3146 8.81786 19.5837 9 19.8828 9C20.4101 9 20.7642 8.45924 20.5535 7.97595L17.9917 2.10034C17.8328 1.73573 17.4728 1.5 17.0751 1.5H16.6749C16.2772 1.5 15.9172 1.73573 15.7583 2.10034L13.1965 7.97595C12.9858 8.45924 13.3399 9 13.8672 9C14.1663 9 14.4354 8.81786 14.5465 8.54008L14.9625 7.5H18.7875L19.2035 8.54008ZM15.5625 6L16.875 2.7225L18.1875 6H15.5625Z" fill="#A6CBDF" />
      <path d="M4.5 11.8725L5.90625 13.2787C6.19857 13.5711 6.67226 13.5719 6.96562 13.2806C7.26044 12.9879 7.26129 12.5113 6.9675 12.2175L5.20711 10.4571C4.81658 10.0666 4.18342 10.0666 3.79289 10.4571L2.02875 12.2212C1.73643 12.5136 1.73559 12.9873 2.02688 13.2806C2.31963 13.5754 2.79621 13.5763 3.09 13.2825L4.5 11.8725Z" fill="#A6CBDF" />
      <path d="M4.5 20.3776L3.09375 18.9713C2.80143 18.679 2.32774 18.6782 2.03438 18.9694C1.73956 19.2622 1.73871 19.7388 2.0325 20.0326L3.79289 21.793C4.18342 22.1835 4.81658 22.1835 5.20711 21.793L6.97125 20.0288C7.26357 19.7365 7.26441 19.2628 6.97312 18.9694C6.68037 18.6746 6.20379 18.6738 5.91 18.9676L4.5 20.3776Z" fill="#A6CBDF" />
    </svg>
  );
}

export default IconTextLineHeight;
