import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { ROUTES } from 'constants/constants';
import strings from 'constants/localization';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import css from './PageLogin.module.css';

interface IWrapper extends React.HTMLProps<HTMLDivElement>{}
export const Wrapper:React.FC<IWrapper> = ({ children }) => (
  <div className={css.wrapperContainer}>
    <div className={css.navigate}>
      <span className={cls(css.linkLabel, textCss.pMedium2)}>{strings.loginPageRequestLabelBackTo}&nbsp;</span>
      <NavLink to={ROUTES.USER_LOGIN} className={cls(css.link, textCss.pMedium2)}>{strings.loginPageLoginTitle}</NavLink>
    </div>
    <div className={css.innerContainer}>
      {children}
    </div>
  </div>
);

function PageLogin() {
  return (
    <div className={css.container}>
      <div className={css.leftSide}>
        <div className={css.logoContainer}>
          <Icon type={ICON_TYPES.logoWithText} />
        </div>
        <div className={css.imageContainer}>
          <Icon type={ICON_TYPES.login} className={css.banner} />
        </div>
      </div>
      <div className={css.rightSide}>
        <Outlet />
      </div>
    </div>
  );
}

export default PageLogin;
