import React from 'react';
import { EIntegrationServices, TExcelMatchings } from 'types';
import ChooseTicketModalContentInput from './ChooseTicketModalContentInput';
import ChooseTicketModalContentApi from './ChooseTicketModalContentApi';
import ChooseTicketModalContentExcel from './ChooseTicketModalContentExcel';
import { ITicketType } from './components/TicketTypeSelect';

export interface IChooseTicketModalContentContainer {
  onMatched?: () => void,
  integrationService: EIntegrationServices,
  chosenTicket: ITicketType | null,
  matchingsTicket: ITicketType | null,
  setChosenTicket: React.Dispatch<React.SetStateAction<ITicketType | null>>
  setExcelMatchings: React.Dispatch<React.SetStateAction<TExcelMatchings | null>>
  integrationToken: string | null | undefined,
}

const ChooseTicketModalContentContainer: React.FC<IChooseTicketModalContentContainer> = props => {
  const { integrationService } = props;
  switch (integrationService) {
    case EIntegrationServices.MANUAL:
      return <ChooseTicketModalContentExcel {...props} />;
    case EIntegrationServices.EVENTBRITE:
    case EIntegrationServices.PAYLOGIC:
      return <ChooseTicketModalContentApi service={integrationService} {...props} />;
    case EIntegrationServices.CODE_KARUSSEL:
    case EIntegrationServices.API:
    default:
      return <ChooseTicketModalContentInput {...props} />;
  }
};

export default ChooseTicketModalContentContainer;
