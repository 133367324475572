/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconTextAlignLeft(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M14 15H4C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17H14C14.55 17 15 16.55 15 16C15 15.45 14.55 15 14 15ZM14 7H4C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9H14C14.55 9 15 8.55 15 8C15 7.45 14.55 7 14 7ZM4 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 21H20C20.55 21 21 20.55 21 20C21 19.45 20.55 19 20 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21ZM3 4C3 4.55 3.45 5 4 5H20C20.55 5 21 4.55 21 4C21 3.45 20.55 3 20 3H4C3.45 3 3 3.45 3 4Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconTextAlignLeft;
