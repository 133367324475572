import React, { Dispatch, SetStateAction } from 'react';

import Icon, { ICON_TYPES } from 'components/library/Icon';

import textCss from 'assets/styles/Text.module.css';
import css from './CustomLabel.module.css';

interface ICustomLabel {
  shouldShowComment: boolean,
  label: string,
  iconType: keyof typeof ICON_TYPES,
  setShowComment: Dispatch<SetStateAction<boolean>>,
}

const CustomLabel: React.FC<ICustomLabel> = ({
  iconType, label, setShowComment, shouldShowComment
}) => (
  <div className={css.customLabel}>
    <label className={textCss.h4}>{label}</label>
    <Icon className={css.icon} type={ICON_TYPES[iconType]} onClick={() => setShowComment(shouldShowComment)} />
  </div>
);

export default CustomLabel;
