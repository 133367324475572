/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconCode(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24 4.64841V0H19.3516V1.62108H4.64841V0H0V4.64841H4.64841V3.02733H19.3516V4.64841H20.9727V19.3516H19.3516V20.9727H4.64841V19.3516H0V24H4.64841V22.3789H19.3516V24H24V19.3516H22.3789V4.64841H24Z"
        fill="#2C3E66"
      />
      <path d="M1.62109 15.3027H3.02734V17.5039H1.62109V15.3027Z" fill="#2C3E66" />
      <path d="M1.62109 10.8994H3.02734V13.1006H1.62109V10.8994Z" fill="#2C3E66" />
      <path d="M1.62109 6.49609H3.02734V8.6973H1.62109V6.49609Z" fill="#2C3E66" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5003 6C12.8354 6 13.107 6.35039 13.107 6.78261V17.2174C13.107 17.6496 12.8354 18 12.5003 18C12.1652 18
        11.8936 17.6496 11.8936 17.2174V6.78261C11.8936 6.35039 12.1652 6 12.5003 6ZM15.1969 6C14.6757 6 14.2531 6.54504
        14.2531 7.21739V16.7826C14.2531 17.455 14.6757 18 15.1969 18C15.7182 18 16.1407 17.455 16.1407
        16.7826V7.21739C16.1407 6.54504 15.7182 6 15.1969 6ZM18.5002 17.2277V6.77231C18.496 6.34483 18.226 6
        17.8936 6C17.5585 6 17.2868 6.35039 17.2868 6.78261V17.2174C17.2868 17.6496 17.5585 18 17.8936 18C18.226 18
        18.496 17.6552 18.5002 17.2277Z"
        fill="#2C3E66"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75105 6.25105C5.9118 6.09031 6.12981 6 6.35714 6H9.78571C10.013 6 10.2311 6.09031 10.3918 6.25105C10.5526
        6.4118 10.6429 6.62981 10.6429 6.85714V10.2857C10.6429 10.513 10.5526 10.7311 10.3918 10.8918C10.2311 11.0526
        10.013 11.1429 9.78571 11.1429H6.35714C6.12981 11.1429 5.9118 11.0526 5.75105 10.8918C5.59031 10.7311 5.5 10.513
        5.5 10.2857V6.85714C5.5 6.62981 5.59031 6.4118 5.75105 6.25105ZM5.5 13.7143V17.1429C5.5 17.3702 5.59031 17.5882
        5.75105 17.7489C5.9118 17.9097 6.12981 18 6.35714 18H9.78571C10.013 18 10.2311 17.9097 10.3918 17.7489C10.5526
        17.5882 10.6429 17.3702 10.6429 17.1429V13.7143C10.6429 13.487 10.5526 13.2689 10.3918 13.1082C10.2311 12.9474
        10.013 12.8571 9.78571 12.8571H6.35714C6.12981 12.8571 5.9118 12.9474 5.75105 13.1082C5.59031 13.2689 5.5 13.487
        5.5 13.7143ZM7.21429 9.42857H8.92857V7.71429H7.21429V9.42857ZM7.21429 16.2857H8.92857V14.5714H7.21429V16.2857Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconCode;
