import React from 'react';
import strings from 'constants/localization';
import { ECanvasObjectTypes } from 'types';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import useEditorData from '../../../hooks/useEditorData';
import css from './AddCodeObjectButton.module.css';
import { EViewportModes } from '../../../constants';
import getUniqueNameNumber from '../../../helpers/uniqueObjectName';
import createQrCode from '../../../helpers/qr';
import SidebarButton from '../../../../../TemplateEditorArticle/components/Sidebar/components/SidebarButton';
import fabric, { groupSVGToCodeObject } from '../../../helpers/fabric';

interface ICodeButton {
  isDisabled: boolean,
}

const ModeButton:React.FC<ICodeButton> = ({ isDisabled = true }) => {
  const {
    canvas,
    objects,
    setObjects,
    pdfDocument,
    setMode,
    setIsChanged,
  } = useEditorData();

  const handleClick = async () => {
    if (canvas.current && pdfDocument) {
      setMode(EViewportModes.Edit);

      const width = Math.min(pdfDocument.width, pdfDocument.height) * 0.6;
      const svg = await createQrCode(strings.editorObjectCodeDefaultContent, { width });

      fabric.loadSVGFromString(svg, (objs, options) => {
        const objectsWithType = objects.filter(({ type }) => type === ECanvasObjectTypes.CODE);
        const name = `${strings.editorObjectCodeTitle} #`;
        const order = getUniqueNameNumber(objectsWithType, name.length, 1);
        options.name = `${name}${order}`;
        options.content = strings.editorObjectCodeDefaultContent;

        const loadedObjects = groupSVGToCodeObject(objs, options);
        if (canvas.current) {
          canvas.current.add(loadedObjects);
          canvas.current.viewportCenterObject(loadedObjects);
          canvas.current.setActiveObject(loadedObjects);
          setObjects(canvas.current.getObjects());
          canvas.current.requestRenderAll();
          setIsChanged(true);
          canvas.current.renderAll();
        }
      });
    }
  };

  return (
    <SidebarButton
      buttonType="transparent"
      disabled={isDisabled}
      onClick={handleClick}
      className={css.button}
    >
      <Icon type={ICON_TYPES.code} />
    </SidebarButton>
  );
};

export default ModeButton;
