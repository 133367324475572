import React from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import cls from 'classnames';

import { getCustomersTableSliderParams } from 'helpers';
import textCss from 'assets/styles/Text.module.css';
import { EArticleStatuses, EOrderStatus, TStat } from 'types';
import { STATUS_TO_STRINGS } from 'constants/constants';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import css from './CustomersTableCellSlider.module.css';

interface ICustomersTableCellSlider<T extends EOrderStatus | EArticleStatuses> {
  link: string,
  sliderItemClass?: string,
  children?: React.ReactNode,
  items: TStat<T>[],
}
function CustomersTableCellSlider<T extends EOrderStatus | EArticleStatuses>({
  children, link, items, sliderItemClass
}: ICustomersTableCellSlider<T>) {
  const slider = React.useRef<Slider>(null);
  const onPreviousCLick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (slider?.current) {
      slider.current.slickPrev();
    }
  };

  const onNextCLick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (slider?.current) {
      slider.current.slickNext();
    }
  };

  return (
    <div className={css.sliderContainer} onClick={e => e.stopPropagation()}>
      <div className={cls(css.iconArrow, css.prevArrow)} onClick={onPreviousCLick}>
        <Icon type={E_ICON_TYPE.arrowUp} />
      </div>
      <Slider
        ref={slider}
        className={css.slider}
        {...getCustomersTableSliderParams({
          arrows: false,
          dots: false,
        })}
      >
        {children}
        {items.map(({ status, count }) => (
          <div key={status} className={sliderItemClass}>
            <NavLink to={link} state={{ status }}>
              <div>{count}</div>
              <div className={cls(textCss.pLight3, css.status)}>{STATUS_TO_STRINGS[status]()}</div>
            </NavLink>
          </div>
        ))}
      </Slider>
      <div className={cls(css.iconArrow, css.nextArrow)} onClick={onNextCLick}>
        <Icon type={E_ICON_TYPE.arrowUp} />
      </div>
    </div>
  );
}

export default CustomersTableCellSlider;
