/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconNoPdf(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.85 80.8453L16.8699 81.2641C15.2681 81.4893 13.7871 80.3733 13.562 78.7715L5.40982 20.7658C5.18471 19.164 6.30072 17.683 7.9025 17.4579L65.1831 9.40763C66.7849 9.18251 68.2659 10.2985 68.491 11.9003C68.491 11.9003 69.0122 15.6092 69.1915 16.8849" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.702 77.616L18.9959 78.0006C17.5414 78.2074 16.1985 77.196 15.9964 75.7418L8.67797 23.0787C8.47588 21.6245 9.49115 20.278 10.9456 20.0712L62.9591 12.6784C64.4136 12.4717 65.7565 13.483 65.9586 14.9373L66.5875 19.4628C66.6215 19.7076 68.8683 35.962 73.3278 68.2258C73.5517 69.8459 72.4346 71.3429 70.8327 71.5694C70.8155 71.5718 70.7983 71.5741 70.781 71.5762L21.702 77.616Z" fill="#EAF7FD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.8398 80.7061C20.9166 81.253 20.5356 81.7587 19.9887 81.8356L17.0085 82.2544C14.8598 82.5564 12.8732 81.0593 12.5712 78.9106L4.41902 20.9049C4.11704 18.7562 5.6141 16.7696 7.76279 16.4676L65.0434 8.41734C67.1921 8.11536 69.1788 9.61242 69.4807 11.7611L70.1813 16.7457C70.2581 17.2926 69.8771 17.7983 69.3302 17.8752C68.7833 17.952 68.2776 17.571 68.2007 17.0241L67.5003 12.0398C67.352 10.9849 66.3766 10.2496 65.3218 10.3979L8.04114 18.4481C6.98626 18.5964 6.2513 19.5717 6.39956 20.6266L14.5517 78.6323C14.7 79.6872 15.6753 80.4221 16.7302 80.2739L19.7103 79.855C20.2572 79.7782 20.7629 80.1592 20.8398 80.7061Z" fill="#BCDEF0" />
      <path d="M69.8002 20.2611L70.1663 22.5459" stroke="#BCDEF0" strokeWidth="2.5" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28.742 24.2392C28.973 22.0422 30.9412 20.4483 33.1382 20.6792L88.5342 26.5016C90.7313 26.7325 92.3251 28.7007 92.0942 30.8978L86.1953 87.0219C85.9644 89.219 83.9961 90.8128 81.7991 90.5819L26.4031 84.7596C24.2061 84.5287 22.6122 82.5604 22.8432 80.3634L28.742 24.2392Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M88.3252 28.4906L32.9292 22.6683C31.8307 22.5528 30.8465 23.3497 30.7311 24.4483L24.8322 80.5724C24.7167 81.6709 25.5137 82.6551 26.6122 82.7705L82.0082 88.5929C83.1067 88.7083 84.0908 87.9114 84.2063 86.8129L90.1052 30.6887C90.2206 29.5902 89.4237 28.6061 88.3252 28.4906ZM33.1382 20.6792C30.9412 20.4483 28.973 22.0422 28.742 24.2392L22.8432 80.3634C22.6122 82.5604 24.2061 84.5287 26.4031 84.7596L81.7991 90.5819C83.9961 90.8128 85.9644 89.219 86.1953 87.0219L92.0942 30.8978C92.3251 28.7007 90.7313 26.7325 88.5342 26.5016L33.1382 20.6792Z" fill="#BCDEF0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.7138 30.4872C34.8869 28.8395 36.3631 27.6441 38.0109 27.8172L82.2886 32.471C83.9364 32.6442 85.1318 34.1204 84.9586 35.7682L81.0702 72.764C80.897 74.4118 79.4208 75.6072 77.773 75.434L33.4953 70.7802C31.8475 70.607 30.6522 69.1308 30.8253 67.4831L34.7138 30.4872Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37.0309 59.4323L43.4446 55.1065C45.0782 54.0046 47.271 54.2567 48.612 55.7004L53.0703 60.5001C53.4283 60.8856 54.0238 60.9277 54.4325 60.5965L64.5433 52.4039C66.3911 50.9067 69.1294 51.3428 70.4206 53.3399L77.0811 63.6417L78.1306 65.3931L77.6512 71.068C77.6038 71.6286 77.1035 72.0398 76.5443 71.9777L35.3709 67.4031C34.8293 67.343 34.4356 66.8602 34.4855 66.3176L35.0142 60.5763L37.0309 59.4323Z" fill="#EAF7FD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M82.0795 34.4601L37.8019 29.8063C37.2526 29.7486 36.7605 30.147 36.7028 30.6963L32.8144 67.6921C32.7567 68.2414 33.1551 68.7334 33.7044 68.7912L77.9821 73.4449C78.5313 73.5027 79.0234 73.1042 79.0811 72.5549L82.9695 35.5591C83.0273 35.0099 82.6288 34.5178 82.0795 34.4601ZM38.0109 27.8172C36.3631 27.6441 34.8869 28.8395 34.7138 30.4872L30.8253 67.4831C30.6522 69.1308 31.8475 70.607 33.4953 70.7802L77.773 75.434C79.4208 75.6072 80.897 74.4118 81.0702 72.764L84.9586 35.7682C85.1318 34.1204 83.9364 32.6442 82.2886 32.471L38.0109 27.8172Z" fill="#BCDEF0" />
      <path d="M50.404 42.3714C50.1504 44.7844 47.9887 46.5349 45.5757 46.2813C43.1627 46.0277 41.4122 43.866 41.6658 41.453C41.9194 39.04 44.0811 37.2895 46.4941 37.5431C48.9071 37.7967 50.6576 39.9584 50.404 42.3714Z" fill="#EAF7FD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M46.389 38.5376C44.5252 38.3418 42.8556 39.6938 42.6597 41.5576C42.4638 43.4213 43.8159 45.0909 45.6796 45.2868C47.5433 45.4827 49.213 44.1307 49.4089 42.2669C49.6047 40.4032 48.2527 38.7335 46.389 38.5376ZM40.6706 41.3485C40.982 38.3862 43.6358 36.2373 46.598 36.5486C49.5603 36.8599 51.7093 39.5137 51.3979 42.476C51.0866 45.4382 48.4328 47.5872 45.4705 47.2759C42.5083 46.9645 40.3593 44.3108 40.6706 41.3485Z" fill="#BCDEF0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M68.8131 52.7144C68.1424 51.8868 66.9278 51.7595 66.1002 52.4301L54.8684 61.5311C54.1604 62.1048 53.1289 62.0318 52.5088 61.3641L47.2562 55.7094C46.6107 55.0144 45.5559 54.8921 44.7686 55.4202L36.127 61.3879C35.6725 61.7017 35.0497 61.5877 34.7359 61.1333C34.422 60.6788 34.536 60.056 34.9905 59.7422L43.6371 53.7711L43.6462 53.7648C45.2507 52.6826 47.4044 52.9302 48.7216 54.3482L53.8038 59.8196L64.8411 50.8762C66.5269 49.5102 69.001 49.7695 70.367 51.4553C70.4552 51.5643 70.5376 51.6778 70.6137 51.7956L70.62 51.8053L69.7913 52.3276C70.62 51.8054 70.62 51.8053 70.62 51.8053L78.721 64.6598C79.0155 65.1271 78.8754 65.7445 78.4082 66.039C77.941 66.3335 77.3235 66.1934 77.029 65.7262L68.9313 52.877C68.8948 52.8208 68.8553 52.7666 68.8131 52.7144Z" fill="#BCDEF0" />
    </svg>
  );
}

export default IconNoPdf;
