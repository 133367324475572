/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconBlocked(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="128" height="128" rx="64" fill="#FF2800" fillOpacity="0.1" />
      <circle cx="64" cy="64" r="50" fill="#FF2800" />
      <path d="M77.3832 54.1798L67.4396 64.1235L77.3832 74.0672C78.2948 74.9787 78.2948 76.4703 77.3832 77.3818C76.4717 78.2933 74.9802 78.2933 74.0687 77.3818L64.125 67.4381L54.1813 77.3818C53.2698 78.2933 51.7783 78.2933 50.8667 77.3818C49.9552 76.4703 49.9552 74.9787 50.8667 74.0672L60.8104 64.1235L50.8667 54.1798C49.9552 53.2683 49.9552 51.7768 50.8667 50.8653C51.7783 49.9538 53.2698 49.9538 54.1813 50.8653L64.125 60.809L74.0687 50.8653C74.9802 49.9538 76.4717 49.9538 77.3832 50.8653C78.2948 51.7768 78.2948 53.2683 77.3832 54.1798Z" fill="white" />
    </svg>

  );
}

export default IconBlocked;
