import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import Layout from 'components/Layout';
import Portfolio from 'components/PagePortfolio';
import AddEditPrintItemContainer from 'components/PagePortfolio/AddEditPrintItem';
import Profile from 'components/PageProfile';
import Customers from 'components/PageCustomers';
import Orders from 'components/PageOrders';
import OrderEditor from 'components/PageOrders/components/OrderEditor';
import Managers from 'components/PageManagers';
import Printers from 'components/PagePrinters';
import UserLoginFrame from 'components/PageLogin';
import EventbriteLoginManual from 'components/EventbriteLogin/manual';
import EventbriteLoginIFrame from 'components/EventbriteLogin/iframe';
import EventbriteLoginOAuth from 'components/EventbriteLogin/oauth';
import UserLogin from 'components/UserLogin';
import UserResetPassword from 'components/UserResetPassword';
import UserForgotPassword from 'components/UserForgotPassword';
import UserBlocked from 'components/UserBlocked';
import Logout from 'components/Logout';
import ProtectedRoutes from 'components/ProtectedRoute';
import PageArticles from 'components/PageArticles';
import AddEditArticle from 'components/PageArticles/AddEditArticle';
import Error from 'components/library/Error';
import TemplateEditorArticle from 'components/PageArticles/TemplateEditorArticle';
import SelectPrintItem from 'components/PageArticles/AddEditArticle/components/SelectPrintItem';
import UserRestricted from 'components/UserRestricted';

import { ERoles } from 'types';
import { ROUTES } from 'constants/constants';
import { ArticleContextProvider } from 'contexts/ContextArticle';

function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.USER_LOGIN} element={<UserLoginFrame />}>
        <Route index element={<UserLogin />} />
        <Route path={ROUTES.EVENTBRITE}>
          <Route index element={<EventbriteLoginManual />} />
          <Route path={ROUTES.IFRAME} element={<EventbriteLoginIFrame />} />
          <Route path={ROUTES.OAUTH} element={<EventbriteLoginOAuth />} />
        </Route>
        <Route path={ROUTES.USER_FORGOT_PASSWORD} element={<UserForgotPassword />} />
        <Route path={ROUTES.USER_RESET_PASSWORD} element={<UserResetPassword />} />
        <Route path={ROUTES.USER_BLOCKED} element={<UserBlocked />} />
        <Route path={ROUTES.USER_RESTRICTED} element={<UserRestricted />} />
      </Route>
      <Route path={ROUTES.BASE} element={<Layout />}>
        <Route path={ROUTES.BASE} element={<ProtectedRoutes />}>
          <Route index element={<Navigate to={ROUTES.PROFILE} replace />} />
          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route path={ROUTES.PORTFOLIO}>
            <Route index element={<Portfolio />} />
            <Route path={ROUTES.ROUTE_ADD} element={<AddEditPrintItemContainer create />} />
            <Route path={ROUTES.PARAM_ID} element={<AddEditPrintItemContainer />} />
          </Route>
          <Route path={ROUTES.PRINTERS}>
            <Route index element={<Printers />} />
            <Route path={ROUTES.ROUTE_ADD} element={<Profile targetRole={ERoles.PRINTER} create />} />
            <Route path={ROUTES.PARAM_ID} element={<Profile targetRole={ERoles.PRINTER} />} />
          </Route>
          <Route path={ROUTES.CUSTOMERS}>
            <Route index element={<Customers />} />
            <Route path={ROUTES.ROUTE_ADD} element={<Profile targetRole={ERoles.CUSTOMER} create />} />
            <Route path={ROUTES.PARAM_ID} element={<Profile targetRole={ERoles.CUSTOMER} />} />
          </Route>
          <Route path={ROUTES.ORDERS}>
            <Route index element={<Orders />} />
            <Route path={ROUTES.ROUTE_ADD} element={<OrderEditor isOrderCreation />} />
            <Route path={ROUTES.PARAM_ID} element={<OrderEditor />} />
          </Route>
          <Route path={ROUTES.MANAGERS} element={<Managers />} />
          <Route path={ROUTES.ARTICLES}>
            <Route index element={<PageArticles />} />
            <Route path={ROUTES.ROUTE_ADD}>
              <Route
                index
                element={(
                  <ArticleContextProvider>
                    <AddEditArticle create />
                  </ArticleContextProvider>
                )}
              />
              <Route
                path={ROUTES.ARTICLES_SELECT_PRINT_ITEM}
                element={(
                  <ArticleContextProvider>
                    <SelectPrintItem />
                  </ArticleContextProvider>
                )}
              />
              <Route
                path={ROUTES.TEMPLATE_EDITOR}
                element={(
                  <ArticleContextProvider>
                    <TemplateEditorArticle />
                  </ArticleContextProvider>
                )}
              />
            </Route>
            <Route
              path={ROUTES.PARAM_ID}
              element={(
                <ArticleContextProvider>
                  <AddEditArticle />
                </ArticleContextProvider>
              )}
            />
            <Route
              path={`${ROUTES.PARAM_ID}/${ROUTES.TEMPLATE_EDITOR}`}
              element={(
                <ArticleContextProvider>
                  <TemplateEditorArticle />
                </ArticleContextProvider>
              )}
            />
          </Route>
          <Route path={ROUTES.MANAGERS}>
            <Route index element={<Managers />} />
            <Route path={ROUTES.ROUTE_ADD} element={<Profile targetRole={ERoles.MANAGER} create />} />
            <Route path={ROUTES.PARAM_ID} element={<Profile targetRole={ERoles.MANAGER} />} />
          </Route>
        </Route>
      </Route>
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
      <Route path={ROUTES.ALL} element={<Error />} />
    </Routes>
  );
}

export default AppRoutes;
