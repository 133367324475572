import { useMutation } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { TGetArticlesItem } from 'types';
import { useInvalidateFindArticles } from './useFindArticles';
import { useInvalidateUserGet, useInvalidateUsersFind } from '../useUser';

const client = instance.getClient();

export const DELETE_ARTICLE_QUERY = ['articles', 'delete'];

export const deleteArticle = (id: string): Promise<TGetArticlesItem> => client
  .delete(`/articles/${id}`)
  .then(res => res.data);

const useDeleteArticle = () => {
  const invalidateArticles = useInvalidateFindArticles();
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TGetArticlesItem, IResponseError, string>(
    [...DELETE_ARTICLE_QUERY],
    id => deleteArticle(id),
    {
      onSuccess: () => {
        invalidateArticles();
        invalidateUsers();
        invalidateUserGet();
      },
    }
  );
};

export default useDeleteArticle;
