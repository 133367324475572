import React, { useContext } from 'react';
import {
  Navigate, useLocation, useParams
} from 'react-router-dom';

import { TArticle, TPrintItemsItem, TUser } from 'types';
import useQueryParams from 'hooks/useQueryParams';
import useGetArticle from 'hooks/Articles/useGetArticle';
import UserContext from 'contexts/ContextUser';
import Loader from 'components/library/Loader';
import Error from 'components/library/Error';
import { ROUTES } from 'constants/constants';
import ArticleContext from 'contexts/ContextArticle';
import { createArticleFromPrintItem } from './helpers';
import ArticleForm from './components/ArticleForm';
import css from './AddEditArticle.module.css';
import { isCustomer } from '../../../helpers';

interface IAddEditArticle {
  create?: boolean
}
const AddEditArticle: React.FC<IAddEditArticle> = ({ create }) => {
  const query = useQueryParams();
  const location = useLocation();
  const user = useContext(UserContext).user as TUser;
  const articleContext = useContext(ArticleContext);
  const { id: articleId } = useParams();

  const state = location.state as { articleId: string, customerId: string };

  const customerId = state?.customerId || query.get('customerId') || undefined;
  const id = articleId || state?.articleId;
  const {
    data, isLoading, error
  } = useGetArticle({
    id, pspId: user.pspId, includeBase: true, customerId, copy: create || undefined
  });

  if (isCustomer(user.userPermissions)) {
    return (
      <Navigate to={`/${ROUTES.ARTICLES}${location.search}`} replace />
    );
  }
  if (create && !id && !articleContext.selectedPrintItem) {
    return (
      <Navigate to={ROUTES.ARTICLES_SELECT_PRINT_ITEM + location.search} replace />
    );
  }

  if (isLoading) {
    return (
      <div className={css.loaderContainer}>
        <Loader />
      </div>
    );
  }
  if (error) {
    return (
      <Error
        code={error.status}
        title={error.response?.data.className}
        description={error.response?.data.data?.reason || error.response?.data.message}
      />
    );
  }
  const article: TArticle = create
    ? createArticleFromPrintItem(articleContext.selectedPrintItem as TPrintItemsItem, customerId, data)
    : data as TArticle;
  return (
    <ArticleForm
      article={article}
      create={create}
      customerId={customerId}
      id={id}
    />
  );
};

export default AddEditArticle;
