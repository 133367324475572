import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Icon, { ICON_TYPES } from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import strings from 'constants/localization';
import { TLocalizedConstString } from 'types';
import css from './ObjectAlign.module.css';
import useEditorData from '../../../../../../components/Editor/hooks/useEditorData';
import SidebarButton from '../../../components/SidebarButton';

export enum EPosition {
  LEFT= 'left',
  RIGHT = 'right',
  CENTER_V = 'center_v',
  CENTER_H = 'center_h',
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface IAlignButtonProps {
  title: TLocalizedConstString,
  position: EPosition,
  icon: E_ICON_TYPE,
}

const AlignButtons: IAlignButtonProps[] = [
  {
    title: () => strings.editorTabSettingsObjectAlignLeftTooltip,
    position: EPosition.LEFT,
    icon: ICON_TYPES.alignLeft,
  },
  {
    title: () => strings.editorTabSettingsObjectAlignCenterHTooltip,
    position: EPosition.CENTER_H,
    icon: ICON_TYPES.alignCenterH,
  },
  {
    title: () => strings.editorTabSettingsObjectAlignRightTooltip,
    position: EPosition.RIGHT,
    icon: ICON_TYPES.alignRight,
  },
  {
    title: () => strings.editorTabSettingsObjectAlignTopTooltip,
    position: EPosition.TOP,
    icon: ICON_TYPES.alignTop,
  },
  {
    title: () => strings.editorTabSettingsObjectAlignCenterVTooltip,
    position: EPosition.CENTER_V,
    icon: ICON_TYPES.alignCenterV,
  },
  {
    title: () => strings.editorTabSettingsObjectAlignBottomTooltip,
    position: EPosition.BOTTOM,
    icon: ICON_TYPES.alignBottom,
  },
];

const ObjectAlign:React.FC = () => {
  const {
    canvas,
    activeObject,
  } = useEditorData();

  const handleChangePosition = (type: EPosition) => {
    if (activeObject && canvas.current) {
      const zoomFactor = (1 / canvas.current.getZoom());
      const {
        left, top, height, width
      } = activeObject.getBoundingRect();

      const point = activeObject.getPointByOrigin('center', 'center');
      const halfWidth = width / 2;
      const halfHeight = height / 2;

      switch (type) {
        case EPosition.LEFT:
          point.setX(halfWidth);
          point.setY(top + halfHeight);
          break;
        case EPosition.RIGHT:
          point.setX(canvas.current.getWidth() - halfWidth);
          point.setY(top + halfHeight);
          break;
        case EPosition.TOP:
          point.setX(left + halfWidth);
          point.setY(halfHeight);
          break;
        case EPosition.BOTTOM:
          point.setX(left + halfWidth);
          point.setY(canvas.current.getHeight() - halfHeight);
          break;
        case EPosition.CENTER_V:
          activeObject.viewportCenterV();
          break;
        case EPosition.CENTER_H:
          activeObject.viewportCenterH();
          break;
        default:
          break;
      }

      if ([EPosition.LEFT, EPosition.RIGHT, EPosition.TOP, EPosition.BOTTOM].includes(type)) {
        const zoomedPoint = point.multiplyEquals(zoomFactor);
        activeObject.setPositionByOrigin(zoomedPoint, 'center', 'center');
      }

      canvas.current.fire('object:modified', { target: activeObject });
    }
  };

  return (
    <div className={css.container}>
      {
        AlignButtons.map(({ title, position, icon }) => (
          <Tooltip key={position} placement="top" title={title()} arrow>
            <SidebarButton
              buttonType="white"
              onClick={() => handleChangePosition(position)}
            >
              <Icon type={icon} />
            </SidebarButton>
          </Tooltip>
        ))
      }
    </div>
  );
};

export default ObjectAlign;
