import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import cls from 'classnames';

import { ERoles, TUser } from 'types';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import ActionHeader from 'components/library/Table/components/ActionHeader';
import { IColumnsActions, TSort } from 'components/library/Table/types';
import SkusCell from './components/SkusCell';
import OrdersCell from './components/OrdersCell';
import ManagerCell from './components/ManagerCell';
import FilterHeader from '../library/Table/components/FilterHeader';
import css from './PageCustomers.module.css';
import { getUserFullName } from '../../helpers';
import SimpleHeader from '../library/Table/components/SimpleHeader';
import Tooltip from '../library/Tooltip';
import { EEntityType } from './index';

enum EColumnId {
  NAME = 'name',
  CONTACTS = 'contacts',
  MANAGER = 'manager',
  SKUS = 'skus',
  ORDERS = 'orders',
  ACTIONS = 'actions',
}

const ROLE_TO_COLUMNS: Record<ERoles, EColumnId[]> = {
  [ERoles.ADMIN]: [],
  [ERoles.PRINTER]: Object.values(EColumnId),
  [ERoles.MANAGER]: Object.values(EColumnId).filter(col => col !== EColumnId.MANAGER),
  [ERoles.CUSTOMER]: [],
};

const ROLE_TO_COLUMN_STYLE: Record<ERoles, string> = {
  [ERoles.ADMIN]: '',
  [ERoles.PRINTER]: '',
  [ERoles.MANAGER]: css.managerViewColumn,
  [ERoles.CUSTOMER]: '',
};

interface IUseColumns extends IColumnsActions {
  order: TSort,
  setOrder: (v:TSort) => void,
  role: ERoles,
  type: EEntityType,
}
const useColumns = ({
  onBlock, filterBlocked, setFilterBlocked, setOrder, order, navigateToCustomerProfile, role, type
}:IUseColumns):ColumnDef<TUser>[] => useMemo<ColumnDef<TUser>[]>(
  () => {
    const columnNameTitle = type === EEntityType.CUSTOMER ? strings.columnHeadCustomer : strings.columnHeadEvent;
    const columns: ColumnDef<TUser>[] = [
      {
        id: EColumnId.NAME,
        header: () => <FilterHeader order={order} className={css.columnName} title={columnNameTitle} setOrder={setOrder} />,
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: ({ row: { original } }) => {
          const { companyName, id } = original;
          const fullName = getUserFullName(original);
          const onCLick = () => {
            if (navigateToCustomerProfile) {
              navigateToCustomerProfile(id);
            }
          };
          return (
            <div className={css.columnNames} onClick={onCLick}>
              <div className={css.displayFlex}>
                <Tooltip title={companyName} placement="top">
                  <span className={textCss.h4}>{companyName}</span>
                </Tooltip>
              </div>
              <div className={css.displayFlex}>
                <Tooltip title={fullName} placement="top">
                  <span className={textCss.pMedium2}>{fullName}</span>
                </Tooltip>
              </div>
            </div>
          );
        }
      },
      {
        id: EColumnId.CONTACTS,
        header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadContacts} /></div>,
        meta: {
          className: cls(css.column, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: ({ row: { original: { id, phoneNumber, email } } }) => {
          const onCLick = () => {
            if (navigateToCustomerProfile) {
              navigateToCustomerProfile(id);
            }
          };
          return (
            <div className={css.columnNames} onClick={onCLick}>
              <div className={css.displayFlex}>
                <Tooltip title={email as string} placement="top">
                  <span className={textCss.pMedium2}>{email}</span>
                </Tooltip>
              </div>
              {phoneNumber && (
                <div className={css.displayFlex}>
                  <Tooltip title={phoneNumber} placement="top">
                    <span className={textCss.pLight3}>{phoneNumber}</span>
                  </Tooltip>
                </div>
              )}
            </div>
          );
        }
      },
      {
        id: EColumnId.MANAGER,
        header: () => <div className={css.displayFlex}><SimpleHeader title={strings.columnHeadManager} /></div>,
        meta: {
          className: css.column
        },
        cell: props => <ManagerCell customerItem={props.row.original} />,
      },
      {
        id: EColumnId.SKUS,
        header: () => <SimpleHeader title={strings.columnHeadSKUs} />,
        meta: {
          className: cls(css.column, css.cellCentered, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => <SkusCell customerItem={props.row.original} />,
      },
      {
        id: EColumnId.ORDERS,
        header: () => <SimpleHeader title={strings.columnHeadOrders} />,
        meta: {
          className: cls(css.column, css.cellCentered, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => <OrdersCell customerItem={props.row.original} />,
      },
      {
        id: EColumnId.ACTIONS,
        header: () => <ActionHeader className={css.columnAction} setFilterBlocked={setFilterBlocked} filterBlocked={filterBlocked} />,
        meta: {
          className: cls(css.column, css.cellCentered, ROLE_TO_COLUMN_STYLE[role])
        },
        cell: props => {
          const { row: { original } } = props;
          return (
            <div
              className={cls(css.cellAction, { [css.locked]: original.blocked })}
              onClick={ev => {
                ev.stopPropagation();
                onBlock(original.id, original);
              }}
            >
              <Icon type={original.blocked ? E_ICON_TYPE.locked : E_ICON_TYPE.unlocked} />
            </div>
          );
        }
      },
    ];
    return columns.filter(({ id }) => ROLE_TO_COLUMNS[role].includes(id as EColumnId));
  },
  [filterBlocked, order]
);

export default useColumns;
