/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconBag(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.9633 14.6364C3.9633 13.3477 5.04768 12.303 6.38532 12.303H41.6147C42.9523 12.303 44.0367 13.3477 44.0367 14.6364V23.3333H34.789V21C34.789 19.9456 33.9018 19.0909 32.8073 19.0909C31.7129 19.0909 30.8257 19.9456 30.8257 21V23.3333H17.1743V21C17.1743 19.9456 16.2871 19.0909 15.1927 19.0909C14.0982 19.0909 13.211 19.9456 13.211 21V23.3333H3.9633V14.6364Z" fill="#BCDEF0" />
      <path d="M34.789 27.1515H44.0367V35.8485C44.0367 37.1371 42.9523 38.1818 41.6147 38.1818H6.38532C5.04768 38.1818 3.9633 37.1371 3.9633 35.8485V27.1515H13.211V29.4848C13.211 30.5392 14.0982 31.3939 15.1927 31.3939C16.2871 31.3939 17.1743 30.5392 17.1743 29.4848V27.1515H30.8257V29.4848C30.8257 30.5392 31.7129 31.3939 32.8073 31.3939C33.9018 31.3939 34.789 30.5392 34.789 29.4848V27.1515Z" fill="#BCDEF0" />
      <path d="M19.5963 0C16.0698 0 13.211 2.75413 13.211 6.15152V8.48485H17.1743V6.15152C17.1743 4.86285 18.2587 3.81818 19.5963 3.81818H28.4037C29.7413 3.81818 30.8257 4.86285 30.8257 6.15152V8.48485H34.789V6.15152C34.789 2.75413 31.9302 0 28.4037 0H19.5963Z" fill="#BCDEF0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.789 8.48485H41.6147C45.1412 8.48485 48 11.239 48 14.6364V23.3333H44.0367V14.6364C44.0367 13.3477 42.9523 12.303 41.6147 12.303H6.38532C5.04768 12.303 3.9633 13.3477 3.9633 14.6364V23.3333H0V14.6364C0 11.239 2.85881 8.48485 6.38532 8.48485H13.211H17.1743H30.8257H34.789ZM0 27.1515V35.8485C0 39.2459 2.85881 42 6.38532 42H41.6147C45.1412 42 48 39.2459 48 35.8485V27.1515H44.0367V35.8485C44.0367 37.1371 42.9523 38.1818 41.6147 38.1818H6.38532C5.04768 38.1818 3.9633 37.1371 3.9633 35.8485V27.1515H0Z" fill="#BCDEF0" />
    </svg>
  );
}

export default IconBag;
