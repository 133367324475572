/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconDescription(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64Z" fill="#EAF7FD" />
      <g filter="url(#filter0_d_7330_32846)">
        <path d="M76 103L90.7931 88.2069V27.6897C90.7931 26.2102 89.5829 25 88.1034 25H39.6897C38.2102 25 37 26.2102 37 27.6897V100.31C37 101.79 38.2102 103 39.6897 103H76Z" fill="white" />
      </g>
      <path d="M52 61.5C52 60.6716 52.6716 60 53.5 60H74.5C75.3284 60 76 60.6716 76 61.5C76 62.3284 75.3284 63 74.5 63H53.5C52.6716 63 52 62.3284 52 61.5Z" fill="#BCDEF0" />
      <path d="M47 75.5C47 74.6716 47.6716 74 48.5 74H80.4655C81.2939 74 81.9655 74.6716 81.9655 75.5C81.9655 76.3284 81.2939 77 80.4655 77H48.5C47.6716 77 47 76.3284 47 75.5Z" fill="#BCDEF0" />
      <path d="M47 68.5C47 67.6716 47.6716 67 48.5 67H80.4655C81.2939 67 81.9655 67.6716 81.9655 68.5C81.9655 69.3284 81.2939 70 80.4655 70H48.5C47.6716 70 47 69.3284 47 68.5Z" fill="#BCDEF0" />
      <path d="M47 82.5C47 81.6716 47.6716 81 48.5 81H80.4655C81.2939 81 81.9655 81.6716 81.9655 82.5C81.9655 83.3284 81.2939 84 80.4655 84H48.5C47.6716 84 47 83.3284 47 82.5Z" fill="#BCDEF0" />
      <path d="M47 89.5C47 88.6716 47.6716 88 48.5 88H68.3621C69.1905 88 69.8621 88.6716 69.8621 89.5C69.8621 90.3284 69.1905 91 68.3621 91H48.5C47.6716 91 47 90.3284 47 89.5Z" fill="#BCDEF0" />
      <path d="M54 36.8945V50.9474L64 56L74 50.9474V36.875L64 32L54 36.8945Z" fill="#E2C8AC" />
      <path d="M78.7932 88.207C77.3137 88.207 76.1035 89.4172 76.1035 90.8967V103L90.8966 88.207H78.7932Z" fill="#BCDEF0" />
      <path d="M54 36.8889L64 43L74 36.8889L64 32L54 36.8889Z" fill="#EDD9BE" />
      <path d="M54 50.9333L64 56V43L54 36.8906V50.9333Z" fill="#CEB195" />
      <path d="M60 41L70 35L67 33.5L57 39L60 41Z" fill="#CEB195" />
      <defs>
        <filter id="filter0_d_7330_32846" x="32" y="25" width="73.7931" height="98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.243137 0 0 0 0 0.4 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7330_32846" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7330_32846" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default IconDescription;
