import React, {
  useEffect, useMemo, useState
} from 'react';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import { ExpandedState, getExpandedRowModel } from '@tanstack/react-table';

import Table from 'components/library/Table';

import {
  IPrintItemFormik, TOptionalPricingTableRow, TPrintItemPricingsError, TPrintItemPricingsTouched
} from 'types/Portfolio';
import { MIN_PAGE } from 'constants/constants';
import usePrevious from 'hooks/usePrevious';
import useColumns from './useColumns';
import { createTableSubRows, pricingsPagesHook } from '../../helpers';
import { findPageNumber, groupOptionalPricingsByLabel, OPTIONAL_PRICINGS_PAGE_SIZE } from './helpers';

const OptionalPricing: React.FC<IPrintItemFormik> = ({ formik }) => {
  const {
    errors: {
      optionalPricings: optionalPricingsErrors
    },
    values: {
      itemInformation: { quantities },
      options,
      optionalPricings,
    },
    touched: { optionalPricings: optionalPricingsTouched },
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [page, setPage] = useState<number>(findPageNumber(
    quantities,
    optionalPricings,
    optionalPricingsErrors as TPrintItemPricingsError[]
  ));

  const prevExpanded = usePrevious<ExpandedState | undefined>(expanded);

  const firstFieldWithError = useMemo<number>(
    () => Array.isArray(optionalPricingsErrors) ? optionalPricingsErrors.findIndex(err => err) : -1,
    [optionalPricingsErrors]
  );

  const pagesAmount = useMemo<number>(
    () => Math.ceil((_uniqBy(optionalPricings, item => item.quantity.value).length || MIN_PAGE) / OPTIONAL_PRICINGS_PAGE_SIZE),
    [optionalPricings]
  );

  const uniqueQuantitiesSliced = useMemo<string[]>(
    () => _uniq(quantities.map(({ value }) => value)).slice((page - 1) * OPTIONAL_PRICINGS_PAGE_SIZE, page * OPTIONAL_PRICINGS_PAGE_SIZE),
    [quantities, page]
  );

  const dataOptionalPricing = useMemo<TOptionalPricingTableRow[]>(
    () => {
      if (optionalPricings.length) {
        const optionalPricingsByLabel = groupOptionalPricingsByLabel(
          uniqueQuantitiesSliced,
          optionalPricingsErrors as TPrintItemPricingsError[],
          optionalPricingsTouched as TPrintItemPricingsTouched[],
          optionalPricings,
          options
        );
        return createTableSubRows(optionalPricingsByLabel);
      }
      return [];
    },
    [optionalPricings, uniqueQuantitiesSliced, optionalPricingsErrors, optionalPricingsTouched, options]
  );

  const fieldWithErrorRef = pricingsPagesHook({
    isSubmitting,
    prevExpanded,
    errIdx: firstFieldWithError,
    pricings: dataOptionalPricing,
    setExpanded,
  });

  const columns = useColumns({
    quantities: uniqueQuantitiesSliced,
    page,
    pagesAmount,
    handleChange,
    setPage,
    handleBlur,
    setFieldValue,
    fieldWithErrorRef,
    firstFieldWithError
  });

  // set page after submit attemp (init case is calculated in useState directly)
  useEffect(() => {
    if (isSubmitting) {
      const newPage = findPageNumber(
        quantities,
        optionalPricings,
        optionalPricingsErrors as TPrintItemPricingsError[],
        page
      );
      if (newPage !== page) {
        setPage(newPage);
      }
    }
  }, [isSubmitting, quantities, optionalPricings, optionalPricingsErrors, page]);

  return (
    <Table<TOptionalPricingTableRow>
      columns={columns}
      data={dataOptionalPricing}
      state={{ expanded }}
      onExpandedChange={setExpanded}
      getSubRows={row => row.subRows}
      getExpandedRowModel={getExpandedRowModel()}
    />
  );
};

export default React.memo(OptionalPricing);
