import React from 'react';
import { TUser } from '../../../../types';
import strings from '../../../../constants/localization';
import textCss from '../../../../assets/styles/Text.module.css';
import { getBlockUserMessage, isManagerGettingBlocked } from '../helpers';
import ReassignTableWrapper from './ReassignTableWrapper';
import { TReassignedCustomers } from '../types';

export interface IModalContent {
  userToBlock: TUser,
  reassignedCustomers: TReassignedCustomers,
  setReassignedCustomers: React.Dispatch<React.SetStateAction<TReassignedCustomers>>
}
const ModalContent: React.FC<IModalContent> = ({
  userToBlock, reassignedCustomers, setReassignedCustomers
}) => {
  const {
    blocked, role, customers, pspId, id
  } = userToBlock;

  if (!isManagerGettingBlocked(role, blocked)) {
    return (
      <span className={textCss.pMedium3}>
        {getBlockUserMessage(userToBlock)}
      </span>
    );
  }

  if (!customers?.length) {
    return (
      <span className={textCss.pMedium3}>
        {strings.modalBlockManagerWithoutCustomersMessage}
      </span>
    );
  }

  return (
    <ReassignTableWrapper
      pspId={pspId}
      customers={customers}
      managerId={id}
      reassignedCustomers={reassignedCustomers}
      setReassignedCustomers={setReassignedCustomers}
    />
  );
};

export default ModalContent;
