/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconArrowUp(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8703 14.7076C16.7778 14.8003 16.6679 14.8738 16.5469 14.924C16.426 14.9742 16.2963 15 16.1653 15C16.0343 15 15.9046 14.9742 15.7837 14.924C15.6627 14.8738 15.5528 14.8003 15.4603 14.7076L11.5803 10.8276L7.7003 14.7076C7.60772 14.8001 7.49781 14.8736 7.37684 14.9237C7.25588 14.9738 7.12623 14.9996 6.9953 14.9996C6.86437 14.9996 6.73472 14.9738 6.61376 14.9237C6.49279 14.8736 6.38288 14.8001 6.2903 14.7076C6.19772 14.615 6.12428 14.5051 6.07417 14.3841C6.02407 14.2631 5.99828 14.1335 5.99828 14.0026C5.99828 13.8716 6.02407 13.742 6.07417 13.621C6.12428 13.5 6.19772 13.3901 6.2903 13.2976L10.8803 8.70756C10.9728 8.61485 11.0827 8.5413 11.2037 8.49112C11.3246 8.44094 11.4543 8.41511 11.5853 8.41511C11.7163 8.41511 11.846 8.44094 11.9669 8.49112C12.0879 8.5413 12.1978 8.61485 12.2903 8.70756L16.8803 13.2976C17.2603 13.6776 17.2603 14.3176 16.8703 14.7076Z"
        fill="#2C3E66"
      />
    </svg>
  );
}

export default IconArrowUp;
