import React from 'react';
import { IIconProps } from './types';

function IconBlock(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.5C4.89543 8.5 4 9.39543 4 10.5V20.5C4 21.6046 4.89543 22.5 6 22.5H18C19.1046 22.5 20 21.6046 20
        20.5V10.5C20 9.39543 19.1046 8.5 18 8.5H6ZM18 10.5H6V20.5H18V10.5Z"
        fill="#22983C"
      />
      <path d="M15 15.5L10 12.5V18.5L15 15.5Z" fill="#22983C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5C9.23858 1.5 7 3.73858 7 6.5L9 6.5C9 4.84315 10.3431 3.5 12 3.5C13.6569 3.5 15 4.84315 15
        6.5V8.5H17V6.5C17 3.73858 14.7614 1.5 12 1.5Z"
        fill="#22983C"
      />
    </svg>
  );
}

export default IconBlock;
