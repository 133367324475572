/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignRight(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M21 2C21.55 2 22 2.45 22 3V21C22 21.55 21.55 22 21 22C20.45 22 20 21.55 20 21V3C20 2.45 20.45 2 21 2ZM3.5 10H16.5C17.33 10 18 9.33 18 8.5C18 7.67 17.33 7 16.5 7H3.5C2.67 7 2 7.67 2 8.5C2 9.33 2.67 10 3.5 10ZM9.5 17H16.5C17.33 17 18 16.33 18 15.5C18 14.67 17.33 14 16.5 14H9.5C8.67 14 8 14.67 8 15.5C8 16.33 8.67 17 9.5 17Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignRight;
