import React, { useCallback, useContext, useMemo } from 'react';
import { TArticleDocument } from 'types';
import Slider from './Slider';
import OrderContext from '../../ContextOrder';

const SliderContainer = () => {
  const {
    state, setStateRaw, setCanvasLoadingStatus, canvasLoadingStatus
  } = useContext(OrderContext);

  const {
    frontSide, backSide, currentTab, selectedOrderItem, zoom
  } = state;

  const setCurrentCanvasSideLoadingStatus = useCallback((value: boolean) => {
    setCanvasLoadingStatus(prevState => ({ ...prevState, [state.currentTab]: value }));
  }, [state.currentTab]);
  const sides = useMemo(() => [frontSide, backSide].filter(e => e), [frontSide, backSide]) as TArticleDocument[];

  const updateItemsToSave = useCallback((canvas:TArticleDocument['canvas']) => setStateRaw(prev => ({
    ...prev,
    orderItemsToSave: prev.orderItemsToSave.map(item => {
      if (item.id === prev.selectedOrderItem?.id) {
        return {
          ...item,
          items: item.items.map(object => {
            const newObject = canvas.objects.find(obj => obj.id === object.id);
            return newObject || object;
          })
        };
      }
      return item;
    })
  })), [setStateRaw]);

  const changeSlide = useCallback((prev:number, next: number) => {
    const side = sides[next];
    if (side) {
      setStateRaw(prevState => ({ ...prevState, currentTab: side.side }));
    }
  }, [setStateRaw, sides]);

  return useMemo(() => (
    <Slider
      canvasLoadingStatus={canvasLoadingStatus}
      activeSide={currentTab}
      changeSlide={changeSlide}
      orderItem={selectedOrderItem}
      sides={sides}
      updateItemsToSave={updateItemsToSave}
      zoom={zoom}
      setCanvasLoadingStatus={setCurrentCanvasSideLoadingStatus}
    />
  ), [sides, currentTab, canvasLoadingStatus, selectedOrderItem, setStateRaw, zoom, setCanvasLoadingStatus]);
};

export default SliderContainer;
