import React from 'react';

import {
  ETablePageViews, TIntegrationMatchingsFieldsItem, TPrintItemOptionPortfolio, TPrintItemsItem
} from 'types';
import { TSelectPrintItemInfoMapper } from 'types/Articles';
import strings from 'constants/localization';
import textCss from 'assets/styles/Text.module.css';

export const generateSideBarOptions = (options: TPrintItemOptionPortfolio[], className: string) => options
  .map(({ values, id: optionId, name }) => (
    <div key={optionId} className={className}>
      <div className={textCss.pMedium2}>• {name}</div>
      <div className={textCss.pLight2}>
        {values.map(({ value, id: optionValueId }) => <div key={optionValueId}>{value}</div>)}
      </div>
    </div>
  ));

export const generateSelectPrintItemSideBarInfo = (printItem: TPrintItemsItem, className: string): TSelectPrintItemInfoMapper[] => {
  const {
    sizes, papers, quantities, options
  } = printItem;
  return [
    {
      title: strings.size,
      value: sizes!.map(({ name }) => name).join(', '),
    },
    {
      title: strings.paper,
      value: papers!.map(({ id, value }) => <div key={id}>{value}</div>),
    },
    {
      title: strings.quantities,
      value: quantities!.map(({ value }) => value).join(' / '),
    },
    {
      title: strings.options,
      value: generateSideBarOptions(options!, className),
    },
  ];
};

export type TGetListView = {
  view: ETablePageViews,
  isFromUrl: boolean,
}
export const getArticleListView = (params: URLSearchParams, contextView: ETablePageViews): TGetListView => {
  const urlView = params.get('view');
  const isValidView: boolean = Object.values(ETablePageViews).includes(urlView as ETablePageViews);
  if (isValidView) {
    return { view: urlView as ETablePageViews, isFromUrl: true };
  }
  return { view: contextView, isFromUrl: false };
};

export const getObjectIsUsedInMatchings = (
  objId: string | undefined,
  frontMatchings: TIntegrationMatchingsFieldsItem,
  backMatchings: TIntegrationMatchingsFieldsItem
): boolean => {
  if (objId) {
    const allMatchings: TIntegrationMatchingsFieldsItem = {
      ...frontMatchings,
      ...backMatchings,
    };
    return !!Object.keys(allMatchings).find(item => item === objId);
  }
  return false;
};
