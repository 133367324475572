/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconCustomers(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="4.5" transform="matrix(-1 0 0 1 14.7998 7.8999)" stroke="white" strokeWidth="1.8" />
      <path d="M7.46762 11.0442C5.82042 10.8656 4.62985 9.38551 4.80841 7.73831C4.98698 6.09111 6.46705 4.90054 8.11426 5.0791" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.7998 20.8999C21.7998 18.3999 18.7998 15.3999 14.7998 15.3999C10.7998 15.3999 7.7998 18.3999 7.7998 20.8999" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.2998 20.3999C2.2998 19.0509 4.2998 16.3999 6.2998 15.8999" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconCustomers;
