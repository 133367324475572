/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconAlignLeft(props: IIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path d="M3 22C2.45 22 2 21.55 2 21V3C2 2.45 2.45 2 3 2C3.55 2 4 2.45 4 3V21C4 21.55 3.55 22 3 22ZM20.5 7H7.5C6.67 7 6 7.67 6 8.5C6 9.33 6.67 10 7.5 10H20.5C21.33 10 22 9.33 22 8.5C22 7.67 21.33 7 20.5 7ZM14.5 14H7.5C6.67 14 6 14.67 6 15.5C6 16.33 6.67 17 7.5 17H14.5C15.33 17 16 16.33 16 15.5C16 14.67 15.33 14 14.5 14Z" fill="#2C3E66" />
    </svg>
  );
}

export default IconAlignLeft;
