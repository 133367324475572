import React from 'react';
import { useNavigate } from 'react-router-dom';

import strings from 'constants/localization';
import { navigateBack } from 'helpers';
import Button from '../../Button';

interface ICancelButton {
  navigateRoute?: string,
  fallbackRoute?: string
}
const FooterCancelButton: React.FC<ICancelButton> = ({
  navigateRoute, fallbackRoute
}) => {
  const navigate = useNavigate();

  const onClickCancel = () => {
    if (navigateRoute) {
      return navigate(navigateRoute);
    }
    navigateBack(navigate, fallbackRoute);
  };

  return (
    <Button buttonType="quaternary" onClick={onClickCancel}>{strings.actionLabelCancel}</Button>
  );
};

export default FooterCancelButton;
