import React, {
  createContext, useMemo, useState,
} from 'react';
import Alert from 'components/library/Alert';

export interface IAlertAction {
  message: string,
  severity: 'error' | 'success' | 'info' | 'warning',
  timeout?: number,
  onClose: () => void
}

export interface IAlertContext {
  push: (action:Omit<IAlertAction, 'onClose'>) => void
}
const AlertContext = createContext<IAlertContext>({
  push: () => {},
});

const TIME = 3000;

export const AlertContextProvider:React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [payload, setPayload] = useState<IAlertAction | null>(null);
  const value = useMemo(() => {
    let timer:ReturnType<typeof setTimeout> | null = null;
    const push = (action:Omit<IAlertAction, 'onClose'>) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => setPayload(null), action.timeout && action.timeout > 0 ? action.timeout : TIME);
      setPayload({
        onClose: () => {
          if (timer) clearTimeout(timer);
          setPayload(null);
        },
        ...action
      });
    };
    return { push };
  }, []);

  return (
    <AlertContext.Provider value={value}>
      {children}
      {payload ? <Alert {...payload} /> : null}
    </AlertContext.Provider>
  );
};

export default AlertContext;
