import { useMutation, useQuery, useQueryClient } from 'react-query';

import instance from 'lib/apiClient';
import {
  TIntegrationConnection, TCreateIntegrationConnection, TGetIntegrationConnection, TPatchIntegrationConnection
} from 'types/Integration';
import { IResponseError, IFindResponse } from 'constants/types';
import { useInvalidateUserGet } from 'hooks/useUser';

const client = instance.getClient();

type TManagerId = string | null | undefined;

export const getIntegrationConnections = (managerId: TManagerId):Promise<IFindResponse<TIntegrationConnection[]>> => client
  .get(`/integration-connection?managerId=${managerId}`)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_CONNECTIONS_GET = ['integrationConnections', 'get'];
export function useGetIntegrationConnections(managerId: TManagerId) {
  return useQuery<IFindResponse<TIntegrationConnection[]>, IResponseError>(
    QUERY_KEY_INTEGRATION_CONNECTIONS_GET,
    () => getIntegrationConnections(managerId),
  );
}

export const useInvalidateGetIntegrationConnections = () => {
  const rqClient = useQueryClient();
  return (...additionalParams: string[]) => rqClient.invalidateQueries([...QUERY_KEY_INTEGRATION_CONNECTIONS_GET, ...additionalParams]);
};

export const getIntegrationConnection = (data: TGetIntegrationConnection):Promise<TIntegrationConnection> => client
  .get(`/integration-connection/${data.id}?managerId=${data.managerId}`)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_CONNECTION_GET = ['integrationConnection', 'get'];
export function useGetIntegrationConnection(data: TGetIntegrationConnection) {
  return useQuery<TIntegrationConnection, IResponseError>(
    [...QUERY_KEY_INTEGRATION_CONNECTION_GET, data.id, data.managerId],
    () => getIntegrationConnection(data),
    {
      enabled: !!data.id && !!data.managerId,
    }
  );
}

export const createIntegrationConnection = (data: TCreateIntegrationConnection, managerId: TManagerId): Promise<TIntegrationConnection> => client
  .post(`/integration-connection?managerId=${managerId}`, data)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_CONNECTION_CREATE = ['integrationConnection', 'create'];
export function useCreateIntegrationConnection(managerId: TManagerId) {
  const invalidateIntegrationConnections = useInvalidateGetIntegrationConnections();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TIntegrationConnection, IResponseError, TCreateIntegrationConnection>(
    [...QUERY_KEY_INTEGRATION_CONNECTION_CREATE, managerId],
    data => createIntegrationConnection(data, managerId),
    {
      retry: false,
      onSuccess: () => Promise.all([invalidateIntegrationConnections(), invalidateUserGet(managerId!)]),
    }
  );
}

export const patchIntegrationConnection = ({ id, ...data }: TPatchIntegrationConnection, managerId: TManagerId): Promise<TIntegrationConnection> => client
  .patch(`/integration-connection/${id}?managerId=${managerId}`, data)
  .then(res => res.data);

export const QUERY_KEY_INTEGRATION_CONNECTION_PATCH = ['integrationConnection', 'patch'];
export function usePatchIntegrationConnection(managerId: TManagerId) {
  const invalidateIntegrationConnections = useInvalidateGetIntegrationConnections();
  const invalidateUserGet = useInvalidateUserGet();
  return useMutation<TIntegrationConnection, IResponseError, TPatchIntegrationConnection>(
    [...QUERY_KEY_INTEGRATION_CONNECTION_PATCH, managerId],
    data => patchIntegrationConnection(data, managerId),
    {
      retry: false,
      onSuccess: () => Promise.all([invalidateIntegrationConnections(), invalidateUserGet(managerId!)]),
    }
  );
}

export default useGetIntegrationConnections;
