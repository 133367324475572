import React, {
  useCallback, useContext, useMemo, useState, useTransition
} from 'react';
import {
  ECanvasObjectTypes, EUnits, TOrderItem, TParam
} from 'types';
import max from 'lodash/max';
import Button from 'components/library/Button';
import Select from 'components/library/Select';
import Icon from 'components/library/Icon';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import strings from 'constants/localization';
import Modal from 'components/library/Modal';
import textCss from 'assets/styles/Text.module.css';
import cls from 'classnames';
import ScaleSelector
  from 'components/PageArticles/components/Editor/components/Toolbar/components/ScaleSelector/ScaleSelector';
import { FIELDS_TO_EXPORT } from 'components/PageArticles/components/Editor/components/Viewport/Canvas';
import { FabricObject } from 'components/PageArticles/components/Editor/hooks/useFabricObjects/useFabricObjects';
import AlertContext from 'contexts/ContextAlert';
import css from './OrderItems.module.css';
import ModalContentUploadList from './ModalContentUploadList';
import OrderItemParam from './OrderItemParam';
import OrderContext from '../../ContextOrder';
import Slider from '../Slider';
import { processTableItems } from '../../helper';
import Loader from '../../../../../library/Loader';
import fabric from '../../../../../PageArticles/components/Editor/helpers/fabric';
import { calculateImgDPI } from '../../../../../../helpers';
import { DPI_IMAGE_WARNING_THRESHOLD, MAX_INPUT_LENGTH_15 } from '../../../../../../constants/constants';

type TOrderItemSelectValue = { label: string, value: string, item: TOrderItem, __isNew__?:boolean }

const OrderItems:React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [, startTransition] = useTransition();
  const { state, setState } = useContext(OrderContext);
  const { push } = useContext(AlertContext);
  const orderItems = useMemo(() => state.orderItems.map(e => ({
    label: e.title,
    value: e.id,
    item: e
  })), [state.orderItems]);
  const [orderItem, setOrderItemRaw] = useState<TOrderItemSelectValue | null>(orderItems.find(e => e.item === state.selectedOrderItem) || null);

  const orderItemValue = state.selectedOrderItem;
  const setOrderItem = (value:TOrderItemSelectValue | null) => {
    let valueToSet = value;
    if (value?.__isNew__) {
      const item:TOrderItem = {
        id: value.label,
        title: value.label,
        items: state.params.map(param => ({ ...param }))
      };
      valueToSet = {
        label: value.label,
        value: value.label,
        item,
      };
    }

    if (valueToSet) {
      setOrderItemRaw(valueToSet);
      startTransition(() => {
        if (valueToSet) {
          setState({
            selectedOrderItem: valueToSet.item,
            orderItems: value?.__isNew__ ? [...state.orderItems, valueToSet.item] : state.orderItems,
            orderItemsToSave: value?.__isNew__ ? [...state.orderItemsToSave, valueToSet.item] : state.orderItemsToSave,
            changes: true,
          });
        }
      });
    }
  };
  const [modalExistItems, setModalExistItems] = useState<boolean>(false);
  const [modalUploadList, setModalUploadList] = useState<boolean>(false);

  const onClickUpload = useCallback((next?:boolean) => {
    if (next) setModalUploadList(true);
    if (state.orderItems.length) {
      setModalExistItems(prev => !prev);
    } else {
      setModalUploadList(true);
    }
  }, [setModalExistItems, state.orderItems]);

  const printSize = useMemo(() => ({ width: state.size?.width || 0, height: state.size?.height || 0 }), [state.size]);
  const units = useMemo(() => state.printItem?.units as EUnits, [state.printItem?.units]);

  const getImageDpi = useCallback((object:typeof fabric.ImageObject) => calculateImgDPI(object, printSize, units), [printSize, units]);
  const imageDpiNotification = useCallback((dpi:number) => {
    if (dpi < DPI_IMAGE_WARNING_THRESHOLD) {
      push({ severity: 'warning', message: strings.orderPageDpiNotificationWarning });
    }
  }, [push]);

  const getImagesDpiNotification = useCallback((objects: Omit<TParam[], 'text' | 'content'>) => {
    if (!objects.length) return push({ severity: 'success', message: strings.orderPageDpiExelNotificationSuccess });
    const unsatisfactoryDpi = objects.some(({ dpi = 96 }) => dpi < DPI_IMAGE_WARNING_THRESHOLD);
    const highDpi = objects.every(({ dpi = 96 }) => dpi > DPI_IMAGE_WARNING_THRESHOLD);
    if (unsatisfactoryDpi) {
      return push({ severity: 'warning', message: strings.orderPageDpiExelNotificationWarning });
    }
    if (highDpi) {
      return push({ severity: 'success', message: strings.orderPageDpiExelNotificationSuccess });
    }
  }, [push]);

  const onSave = useCallback(async (items: TOrderItem[]) => {
    setLoading(true);
    setModalUploadList(false);
    const processedItems = await processTableItems(items, state.params, getImageDpi);
    const imageObjects = processedItems.map(obj => obj.items.filter(it => it.type === ECanvasObjectTypes.IMAGE && it?.dpi)).flat();
    getImagesDpiNotification(imageObjects);
    const objects = processedItems.map(item => ({
      ...item,
      items: item.items.map(object => {
        const fabricObject = object as FabricObject['object'];
        const param = fabricObject.toObject
          ? fabricObject.toObject(FIELDS_TO_EXPORT) as TParam
          : fabricObject as TParam;
        const originalParam = state.params.find(p => p.id === object.id);
        return { ...param, side: originalParam?.side };
      })
    }));
    const selected = objects[0];
    setState({
      orderItems: objects,
      orderItemsToSave: objects,
      selectedOrderItem: selected,
      changes: true
    });
    setOrderItemRaw({ item: selected, label: selected.title, value: selected.title });
    setLoading(false);
  }, [setState, state.params, getImageDpi, getImagesDpiNotification]);

  const changeActiveItem = useCallback((direction: 1 | -1) => {
    const itemIndex = state.selectedOrderItem ? state.orderItems.findIndex(item => item.id === state.selectedOrderItem?.id) : -1;
    if (itemIndex < 0) return;
    let nextIndex = 0;
    if (direction < 0) {
      nextIndex = itemIndex - 1 < 0 ? state.orderItems.length - 1 : itemIndex - 1;
    }
    if (direction > 0) {
      nextIndex = itemIndex + 1 >= state.orderItems.length ? 0 : itemIndex + 1;
    }
    const item = state.orderItems[nextIndex];
    startTransition(() => {
      setState({
        selectedOrderItem: item,
      });
    });
    setOrderItemRaw({
      value: item.title,
      label: item.title,
      item
    });
  }, [state.selectedOrderItem, state.orderItems]);
  const onRemoveSelectedItem = useCallback(() => {
    if (state.orderItems.length > 1) {
      const newOrderItems = state.orderItems.filter(i => i.id !== state.selectedOrderItem?.id);
      const first = newOrderItems[0];
      startTransition(() => {
        setState({
          orderItems: newOrderItems,
          orderItemsToSave: state.orderItemsToSave.filter(i => i.id !== state.selectedOrderItem?.id),
          selectedOrderItem: first,
          changes: true
        });
      });
      setOrderItemRaw({
        item: first,
        label: first.title,
        value: first.title,
      });
    }
  }, [state.selectedOrderItem, state.orderItems]);
  const onCreateItem = () => {
    const ids = state.orderItems
      .map(e => {
        const matches = e?.id.match(/#(\d+)/);
        if (matches) return +matches[1];
        return null;
      })
      .filter(e => e);
    const maxId = max(ids);
    const id = maxId ? `#${maxId + 1}` : `#${state.orderItems.length}`;
    setOrderItem({
      __isNew__: true, label: id, value: id, item: { items: [], id: '', title: '' }
    });
  };

  return (
    <div className={css.container}>
      {loading && (
        <div className={css.loader}>
          <Loader />
        </div>
      )}
      <div className={css.topControls}>
        <div className={css.left}>
          <Button buttonType="primary" buttonStyle="square" onClick={onCreateItem}>
            <Icon type={E_ICON_TYPE.add} />
          </Button>
          <Button disabled={orderItems.length <= 1} buttonType="secondary" buttonStyle="square" onClick={onRemoveSelectedItem}>
            <Icon type={E_ICON_TYPE.delete} />
          </Button>
          <Select<{ label: string, value: string, item: TOrderItem }>
            isCreatable
            className={css.select}
            onInputChange={v => v.length > MAX_INPUT_LENGTH_15 ? v.substring(0, MAX_INPUT_LENGTH_15) : v}
            onChange={setOrderItem}
            value={orderItem}
            options={orderItems}
          />
          {orderItems.length > 1 && <span className={cls(textCss.pMedium2, css.itemsCount)}>{strings.orderPageOf} {orderItems.length}</span>}
          <div className={css.delimiter} />
          <ScaleSelector
            zoom={state.zoom}
            setZoom={value => setState({ zoom: value })}
          />
        </div>
        <Button
          buttonType="secondary"
          className={css.buttonUpload}
          onClick={() => onClickUpload()}
        >
          <Icon type={E_ICON_TYPE.upload} strokeWidth={1.4} />
          {strings.orderPageUploadMultipleItems}
        </Button>
      </div>
      <div className={css.content}>
        <div className={css.left}>
          <Slider />
        </div>
        <div className={css.right}>
          {orderItemValue && <OrderItemParam getImageDpi={getImageDpi} imageDpiNotification={imageDpiNotification} />}
        </div>
      </div>
      <div className={css.bottomControls}>
        {orderItems.length > 1 && (
          <>
            <Button buttonType="tertiary" buttonStyle="square" onClick={() => changeActiveItem(-1)}>
              <Icon type={E_ICON_TYPE.lineArrowRight} className={css.arrowLeft} />
            </Button>
            <Button buttonType="tertiary" buttonStyle="square" onClick={() => changeActiveItem(1)}>
              <Icon type={E_ICON_TYPE.lineArrowRight} />
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={modalExistItems}
        title={strings.orderPageModalTitleUploadItemsExists}
        onCancel={() => onClickUpload()}
        onRequestClose={() => onClickUpload()}
        onOk={() => onClickUpload(true)}
      >
        {strings.orderPageModalContentUploadItemsExists}
      </Modal>
      <Modal
        title={strings.orderPageModalUploadListTitle}
        isOpen={modalUploadList}
        onRequestClose={() => setModalUploadList(false)}
      >
        <ModalContentUploadList params={state.params} onSave={onSave} />
      </Modal>
    </div>
  );
};

export default OrderItems;
