import { FabricObject } from '../useFabricObjects';

export default async (object: FabricObject['object'], data: any): Promise<FabricObject['object']> => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id, type, content, ...rest
  } = data;

  if (content) {
    await setContent(object, content);
  }

  if (Object.values(rest).length) {
    applyForceRest(object, rest);
  }

  return new Promise(resolve => {
    resolve(object);
  });
};

const setContent = (object: FabricObject['object'], content: string): Promise<void> => object.changeContent(content);

const applyForceRest = (object: FabricObject['object'], rest: any) => {
  object.set(rest);
  object.setCoords();
};
