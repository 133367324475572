/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconManagers(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6001 6.69995V4.19995C17.6001 3.09538 16.7047 2.19995 15.6001 2.19995H4.6001C3.49553 2.19995 2.6001 3.09538 2.6001 4.19995V17.2C2.6001 18.3045 3.49553 19.2 4.6001 19.2H10.6001" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="17.6001" cy="12.7" r="3" stroke="white" strokeWidth="1.8" />
      <path d="M13.6001 22.2C13.6001 22.2 13.6001 18.2 17.6001 18.2C21.6001 18.2 21.6001 22.2 21.6001 22.2" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.6001 6.19995H13.6001" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.6001 9.69995L10.6001 9.69995" stroke="white" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconManagers;
