import { NavLink, To } from 'react-router-dom';
import React from 'react';
import cls from 'classnames';

import Icon from 'components/library/Icon';
import Tooltip from 'components/library/Tooltip';
import { E_ICON_TYPE } from 'components/library/Icon/types';
import { TLocalizedConstString } from 'types';
import css from './MenuItem.module.css';

interface IMenuItemLink extends Pick<IMenuItem, 'to' | 'external'> {
  children: React.ReactNode,
}
const MenuItemLink: React.FC<IMenuItemLink> = ({
  to, external, children
}) => external
  ? (
    <a href={external} target="blank" className={css.link}>
      {children}
    </a>
  )
  : (
    <NavLink
      className={({ isActive }) => cls(css.link, { [css.active]: isActive })}
      to={to}
    >
      {children}
    </NavLink>
  );

export interface IMenuItem {
  type: E_ICON_TYPE,
  to: To,
  title: TLocalizedConstString,
  external?: string
}
const MenuItem: React.FC<IMenuItem> = ({
  title, type, to, external
}) => (
  <Tooltip title={title()} placement="right">
    <MenuItemLink to={to} external={external}>
      <div className={css.iconWrapper}>
        <Icon type={type} />
      </div>
    </MenuItemLink>
  </Tooltip>
);

export default MenuItem;
