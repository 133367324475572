/* eslint-disable max-len */
import React from 'react';
import { IIconProps } from './types';

function IconOption(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0C99.3462 0 128 28.6538 128 64Z" fill="#EAF7FD" />
      <g filter="url(#filter0_d_7330_32828)">
        <path d="M76 103L90.7931 88.2069V27.6897C90.7931 26.2102 89.5829 25 88.1034 25H39.6897C38.2102 25 37 26.2102 37 27.6897V100.31C37 101.79 38.2102 103 39.6897 103H76Z" fill="white" />
      </g>
      <path d="M60.1338 63.9915C60.1338 63.1559 60.8112 62.4785 61.6467 62.4785H75.4312C76.2668 62.4785 76.9441 63.1559 76.9441 63.9915C76.9441 64.827 76.2668 65.5044 75.4312 65.5044H61.6467C60.8112 65.5044 60.1338 64.827 60.1338 63.9915Z" fill="#BCDEF0" />
      <path d="M78.9609 63.9915C78.9609 63.1559 79.6383 62.4785 80.4739 62.4785C81.3094 62.4785 81.9868 63.1559 81.9868 63.9915C81.9868 64.827 81.3094 65.5044 80.4739 65.5044C79.6383 65.5044 78.9609 64.827 78.9609 63.9915Z" fill="#BCDEF0" />
      <path d="M60.1338 81.9788C60.1338 81.1432 60.8112 80.4658 61.6467 80.4658H75.4312C76.2668 80.4658 76.9441 81.1432 76.9441 81.9788C76.9441 82.8143 76.2668 83.4917 75.4312 83.4917H61.6467C60.8111 83.4917 60.1338 82.8143 60.1338 81.9788Z" fill="#BCDEF0" />
      <path d="M78.9609 81.9788C78.9609 81.1432 79.6383 80.4658 80.4739 80.4658C81.3094 80.4658 81.9868 81.1432 81.9868 81.9788C81.9868 82.8143 81.3094 83.4917 80.4739 83.4917C79.6383 83.4917 78.9609 82.8143 78.9609 81.9788Z" fill="#BCDEF0" />
      <path d="M60.1338 73.0686C60.1338 72.233 60.8112 71.5557 61.6467 71.5557H75.4312C76.2668 71.5557 76.9441 72.233 76.9441 73.0686C76.9441 73.9042 76.2668 74.5815 75.4312 74.5815H61.6467C60.8111 74.5815 60.1338 73.9042 60.1338 73.0686Z" fill="#BCDEF0" />
      <path d="M78.9609 73.0686C78.9609 72.233 79.6383 71.5557 80.4739 71.5557C81.3094 71.5557 81.9868 72.233 81.9868 73.0686C81.9868 73.9042 81.3094 74.5815 80.4739 74.5815C79.6383 74.5815 78.9609 73.9042 78.9609 73.0686Z" fill="#BCDEF0" />
      <path d="M47.0215 62.9658C47.0215 61.8612 47.9169 60.9658 49.0215 60.9658H54.0234C55.128 60.9658 56.0234 61.8613 56.0234 62.9658V65.0175C56.0234 66.1221 55.128 67.0175 54.0234 67.0175H49.0215C47.9169 67.0175 47.0215 66.1221 47.0215 65.0175V62.9658Z" fill="#A6CBDF" />
      <path d="M47.0215 80.9522C47.0215 79.8476 47.9169 78.9521 49.0215 78.9521H54.0234C55.128 78.9521 56.0234 79.8476 56.0234 80.9521V83.0039C56.0234 84.1084 55.128 85.0039 54.0234 85.0039H49.0215C47.9169 85.0039 47.0215 84.1084 47.0215 83.0039V80.9522Z" fill="#A6CBDF" />
      <path d="M47.0215 72.043C47.0215 70.9384 47.9169 70.043 49.0215 70.043H54.0234C55.128 70.043 56.0234 70.9384 56.0234 72.043V74.0947C56.0234 75.1993 55.128 76.0947 54.0234 76.0947H49.0215C47.9169 76.0947 47.0215 75.1993 47.0215 74.0947V72.043Z" fill="#A6CBDF" />
      <path d="M54 36.8945V50.9474L64 56L74 50.9474V36.875L64 32L54 36.8945Z" fill="#E2C8AC" />
      <path d="M78.7932 88.207C77.3137 88.207 76.1035 89.4172 76.1035 90.8967V103L90.8966 88.207H78.7932Z" fill="#BCDEF0" />
      <path d="M54 36.8889L64 43L74 36.8889L64 32L54 36.8889Z" fill="#EDD9BE" />
      <path d="M54 50.9333L64 56V43L54 36.8906V50.9333Z" fill="#CEB195" />
      <path d="M60 41L70 35L67 33.5L57 39L60 41Z" fill="#CEB195" />
      <defs>
        <filter id="filter0_d_7330_32828" x="32" y="25" width="73.7932" height="98" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="5" dy="10" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.243137 0 0 0 0 0.4 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7330_32828" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7330_32828" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default IconOption;
