import React, { useMemo } from 'react';
import cls from 'classnames';
import { SingleValue } from 'react-select';
import { ColumnDef } from '@tanstack/react-table';

import Icon, { ICON_TYPES } from 'components/library/Icon';
import Select from 'components/library/Select';
import { getObjectTitle } from 'components/PageOrders/components/OrderEditor/helper';

import strings from 'constants/localization';
import { EIntegrationServices, ETemplateSides, TExcelMatchings } from 'types';
import { TMatchingTableDataItem } from 'types/Integration';
import textCss from 'assets/styles/Text.module.css';
import css from './FieldsMatchingModal.module.css';
import { TMatchings } from '..';
import { ECodeObjectTitle } from '../../../../Viewport/fabric/codeObject';
import { getOrderFields } from '../helpers';

type TSelectMatchingFieldItem = {
  label: string,
  value: string,
}

interface IIntegrationFieldsMatchingTable {
  setIntegrationMatchings: React.Dispatch<React.SetStateAction<TMatchings>>
  integrationService: EIntegrationServices,
  excelMatchings: TExcelMatchings | null
}

const useIntegrationMatchingTable = ({
  setIntegrationMatchings,
  integrationService,
  excelMatchings
}: IIntegrationFieldsMatchingTable): ColumnDef<TMatchingTableDataItem>[] => {
  const selectValues = useMemo<TSelectMatchingFieldItem[]>(
    () => {
      if (integrationService === EIntegrationServices.MANUAL && excelMatchings !== null) {
        return excelMatchings.columns.map(({ index, label }) => ({ label, value: `excel.${index}` }));
      }

      return getOrderFields(integrationService).map(({ label, path }) => ({ label: label(), value: path }));
    },
    [integrationService, excelMatchings]
  );

  const onSelectFieldChange = (value: SingleValue<TSelectMatchingFieldItem>, objectId: string, side: ETemplateSides) => {
    const { value: chosenValue } = value || {};
    setIntegrationMatchings(prev => {
      const changedSide = {
        ...prev[side],
        [objectId]: chosenValue
      };
      // handle clearing
      if (!chosenValue) {
        delete changedSide[objectId];
      }
      return { ...prev, [side]: changedSide };
    });
  };

  return useMemo<ColumnDef<TMatchingTableDataItem>[]>(
    () => [
      {
        id: 'showNested',
        header: () => <span />,
        meta: {
          className: css.column
        },
        cell: ({ row }) => row.getCanExpand() && (
          <div
            style={{
              paddingLeft: `${row.depth * 2}rem`
            }}
          >
            <div
              className={cls(css.expandContainer, css.row)}
              onClick={row.getToggleExpandedHandler()}
            >
              <Icon className={cls({ [css.expanded]: !row.getIsExpanded() })} type={ICON_TYPES.arrowUp} />
            </div>
          </div>
        ),
      },
      {
        id: 'side',
        header: strings.orderPageModalUploadFileMatchTableColumnSideHeader,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { side } } }) => <div>{side.label}</div>
      },
      {
        id: 'title',
        header: strings.orderPageModalUploadFileMatchTableColumnTitleHeader,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { objectTitle: { title, name, type } } } }) => (
          <>
            <div className={textCss.pMedium2}>{name}</div>
            <div className={cls(textCss.pMedium3, css.fieldTitleType)}>
              {getObjectTitle(type, title as ECodeObjectTitle)}
            </div>
          </>
        )
      },
      {
        id: 'ticketField',
        header: strings.integrationMatchingFieldsModalTicketFieldTitle,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { id, side, chosenOrderFieldPath } } }) => (
          <Select
            name="ticketField"
            className={css.select}
            options={selectValues}
            value={selectValues.find(v => chosenOrderFieldPath === v.value)}
            isClearable
            onChange={v => onSelectFieldChange(v, id, side.value)}
          />
        ),
      },
      {
        id: 'value',
        header: strings.orderPageModalUploadFileMatchTableColumnSampleDataHeader,
        meta: {
          className: css.column
        },
        cell: ({ row: { original: { sample } } }) => <div className={css.sample}>{sample}</div>
      },
    ],
    []
  );
};

export default useIntegrationMatchingTable;
