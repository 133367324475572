import { TPrintItemNestedFieldsMapper } from 'types/Portfolio';
import { TCustomersFilters } from 'types/Customers';
import {
  ELanguages,
  EOrderStatus,
  EPermissions,
  ERoles,
  EUnits,
  TFilterBlocked,
  TSelectUnit,
  EArticleStatuses,
  EProductTypes,
  TPageSizeOptionsItem,
  EPrintItemNestedFields,
  EPrintItemCommentFields,
  TArticleStatusOption,
  TLocalizedConstString,
  TRoutesLocalization,
  EPathKeyType,
  EOrientation,
  EIntegrationServices,
} from 'types';
import { IRadioOption } from 'components/library/Radio';
import { TSort } from 'components/library/Table/types';
import strings from './localization';

export const ROUTES = {
  BASE: '/',
  PROFILE: '/profile',
  PORTFOLIO: 'portfolio',
  ORDERS: 'orders',
  CUSTOMER: '/customer',
  CUSTOMERS: 'customers',
  PARAM_ID: ':id',
  ROUTE_ADD: 'add',
  MANAGERS: 'managers',
  PRINTERS: 'printers',
  LOGOUT: 'logout',
  USER_LOGIN: '/login',
  USER_RESET_PASSWORD: 'reset-password',
  USER_FORGOT_PASSWORD: 'forgot-password',
  USER_BLOCKED: 'blocked',
  ARTICLES: 'articles',
  ARTICLES_SELECT_PRINT_ITEM: 'select-product',
  TEMPLATE_EDITOR: 'template-editor',
  ADD_TO_ORDER: 'add-to-order',
  ALL: '*',
  USER_RESTRICTED: 'restricted',
  EVENTBRITE: 'eventbrite',
  IFRAME: 'iframe',
  OAUTH: 'oauth'
};

export const ROUTES_LOCALIZATION: TRoutesLocalization = {
  [ROUTES.CUSTOMERS]: {
    [EPathKeyType.LIST]: () => strings.routeCustomers,
    [EPathKeyType.ADD]: () => strings.profilePageTitleCustomer,
    [EPathKeyType.EDIT]: () => strings.profilePageTitleCustomer,
  },
  [ROUTES.MANAGERS]: {
    [EPathKeyType.LIST]: () => strings.routeManagers,
    [EPathKeyType.ADD]: () => strings.profilePageTitleManager,
    [EPathKeyType.EDIT]: () => strings.profilePageTitleManager,
  },
  [ROUTES.PRINTERS]: {
    [EPathKeyType.LIST]: () => strings.routePrinters,
    [EPathKeyType.ADD]: () => strings.profilePageTitlePrinter,
    [EPathKeyType.EDIT]: () => strings.profilePageTitlePrinter,
  },
  [ROUTES.ORDERS]: {
    [EPathKeyType.LIST]: () => strings.routeOrders,
    [EPathKeyType.ADD]: () => strings.orderPageTitleAdd,
    [EPathKeyType.EDIT]: () => strings.orderPageTitleOrder,
  },
  [ROUTES.PORTFOLIO]: {
    [EPathKeyType.LIST]: () => strings.routePortfolio,
    [EPathKeyType.ADD]: () => strings.addPrintItemPageTitle,
    [EPathKeyType.EDIT]: () => strings.editPrintItemPageTitle,
  },
  [ROUTES.ARTICLES]: {
    [EPathKeyType.LIST]: () => strings.routeSKUs,
    [EPathKeyType.ADD]: () => strings.articlesAddEditPageTitle,
    [EPathKeyType.EDIT]: () => strings.articlesAddEditPageTitle,
  },
  [ROUTES.PROFILE.slice(1)]: {
    [EPathKeyType.LIST]: () => strings.profilePageTitle,
    [EPathKeyType.ADD]: () => strings.profilePageTitle,
    [EPathKeyType.EDIT]: () => strings.profilePageTitle,
  }
};

export const DEFAULT_CUSTOMERS_FILTERS: TCustomersFilters = {
  names: [],
  surnames: [],
  companies: [],
  blocked: 'all',
};

export const ARTICLE_STATUSES_LOCALIZATION: {[key in EArticleStatuses]: TLocalizedConstString} = {
  [EArticleStatuses.DRAFT]: () => strings.articleStatusDraft,
  [EArticleStatuses.PUBLISHED]: () => strings.articleStatusPublished,
};

// TODO: localization changed, maybe in orders needed full names of product types?
// example: Variable Data Product -> Variable
export const PRODUCT_TYPES_LOCALIZATION: {[key in EProductTypes]: TLocalizedConstString} = {
  [EProductTypes.STATIC_PRODUCT]: () => strings.productTypeStatic,
  [EProductTypes.VD_PRODUCT]: () => strings.productTypeVDP,
};

export const DEFAULT_DEBOUNCE_DELAY: number = 300;

export const MIN_PAGE = 1;
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_50 = 50;

export const PAGE_SIZE_OPTIONS: TPageSizeOptionsItem[] = [PAGE_SIZE_10, PAGE_SIZE_20, PAGE_SIZE_50].map(value => ({ value, label: value }));
export const UNIT_OPTIONS: TSelectUnit[] = Object.values(EUnits).map(value => ({ value, label: value }));

export const STALE_TIME = 1 * 60 * 1000; // 1 min

export const STATUS_TO_STRINGS:{[key in EOrderStatus | EArticleStatuses]: TLocalizedConstString } = {
  [EOrderStatus.CREATED]: () => strings.orderStatusCreated,
  [EOrderStatus.RECEIVED]: () => strings.orderStatusReceived,
  [EOrderStatus.SEND_TO_PRINTER]: () => strings.orderStatusSendToPrinter,
  [EOrderStatus.IN_PRINTING]: () => strings.orderStatusInPrinting,
  [EOrderStatus.IN_DELIVERY]: () => strings.orderStatusInDelivery,
  [EOrderStatus.ASSUMED_DELIVERED]: () => strings.orderStatusAssumedDelivered,
  [EOrderStatus.ISSUE]: () => strings.orderStatusIssue,
  [EOrderStatus.ARCHIVED]: () => strings.orderStatusArchived,
  [EArticleStatuses.DRAFT]: () => strings.articleStatusDraft,
  [EArticleStatuses.PUBLISHED]: () => strings.articleStatusPublished,
};

export const LANG_TO_STRINGS: {[key in ELanguages]: TLocalizedConstString} = {
  [ELanguages.EN]: () => strings.langEN,
  [ELanguages.DE]: () => strings.langDE,
  [ELanguages.RU]: () => strings.langRU,
};

export const ROLE_TO_STRINGS: {[key in ERoles]: TLocalizedConstString} = {
  [ERoles.CUSTOMER]: () => strings.roleCustomerLabel,
  [ERoles.MANAGER]: () => strings.roleManagerLabel,
  [ERoles.PRINTER]: () => strings.rolePrinterLabel,
  [ERoles.ADMIN]: () => strings.roleAdminLabel,
};

export const ROLE_TO_LIST_ROUTE: {[key in ERoles]: string} = {
  [ERoles.ADMIN]: ROUTES.PROFILE,
  [ERoles.PRINTER]: ROUTES.BASE + ROUTES.PRINTERS,
  [ERoles.MANAGER]: ROUTES.BASE + ROUTES.MANAGERS,
  [ERoles.CUSTOMER]: ROUTES.BASE + ROUTES.CUSTOMERS,
};

export const ROLE_TO_LIST_ROUTE_LABEL: {[key in ERoles]: TLocalizedConstString} = {
  [ERoles.ADMIN]: () => strings.profileUserRegisterModalCancelProfileButton,
  [ERoles.PRINTER]: () => strings.profileUserRegisterModalCancelPrinterButton,
  [ERoles.MANAGER]: () => strings.profileUserRegisterModalCancelManagerButton,
  [ERoles.CUSTOMER]: () => strings.profileUserRegisterModalCancelCustomerButton,
};

export const ROLE_TO_ADD_ROUTE: {[key in ERoles]: string} = {
  [ERoles.ADMIN]: ROUTES.PROFILE,
  [ERoles.PRINTER]: ROUTES.BASE + [ROUTES.PRINTERS, ROUTES.ROUTE_ADD].join('/'),
  [ERoles.MANAGER]: ROUTES.BASE + [ROUTES.MANAGERS, ROUTES.ROUTE_ADD].join('/'),
  [ERoles.CUSTOMER]: ROUTES.BASE + [ROUTES.CUSTOMERS, ROUTES.ROUTE_ADD].join('/'),
};

export const BLOCK_FILTER_OPTIONS: TFilterBlocked[] = [
  {
    label: () => strings.filterAll,
    domValue: 'all',
    value: undefined
  },
  {
    label: () => strings.filterUnblocked,
    domValue: 'unblocked',
    value: false
  },
  {
    label: () => strings.filterBlocked,
    domValue: 'blocked',
    value: true
  }
];

export const NAME_ORDER_OPTIONS: IRadioOption<TSort>[] = [
  {
    label: () => strings.sortByNameAsc,
    domValue: 'sortByNameAsc',
    value: ['name', 1]
  },
  {
    label: () => strings.sortByNameDesc,
    domValue: 'sortByNameDesc',
    value: ['name', -1]
  },
  {
    label: () => strings.sortByDateDesc,
    domValue: 'sortByDateDesc',
    value: ['createdAt', -1]
  }
];

export const TITLE_ORDER_OPTIONS: IRadioOption<TSort>[] = [
  {
    label: () => strings.sortByNameAsc,
    domValue: 'sortByNameAsc',
    value: ['title', 1]
  },
  {
    label: () => strings.sortByNameDesc,
    domValue: 'sortByNameDesc',
    value: ['title', -1]
  },
  {
    label: () => strings.sortByDateDesc,
    domValue: 'sortByDateDesc',
    value: ['createdAt', -1]
  }
];

// TODO: remake order values into strings after generic is ready
export const STATUS_ORDER_OPTIONS: IRadioOption<TSort>[] = [
  {
    label: ARTICLE_STATUSES_LOCALIZATION[EArticleStatuses.PUBLISHED],
    domValue: EArticleStatuses.PUBLISHED,
    value: ['status', 1]
  },
  {
    label: ARTICLE_STATUSES_LOCALIZATION[EArticleStatuses.DRAFT],
    domValue: EArticleStatuses.DRAFT,
    value: ['status', 0]
  },
  {
    label: () => strings.articleStatusAll,
    domValue: 'All',
    value: ['status', -1]
  },
];

export const EDITABLE_PERMISSIONS:EPermissions[] = [
  EPermissions.ASSIGN_MANAGERS,
  EPermissions.CHANGE_ORDER_STATUS,
  EPermissions.EDIT_MANAGER_CUSTOMERS,
  EPermissions.EDIT_PRINTER_CUSTOMERS,
  EPermissions.VIEW_AND_EDIT_PRINTER_CUSTOMERS,
  EPermissions.VIEW_MANAGER_CUSTOMERS,
  EPermissions.VIEW_PRINTER_CUSTOMERS
];

export const PRINT_ITEM_NESTED_FIELDS_MAPPER: TPrintItemNestedFieldsMapper[] = [
  {
    field: EPrintItemNestedFields.PAPERS,
    commentField: EPrintItemCommentFields.PAPER_COMMENT,
    label: () => strings.inputPaperLabel,
    placeholder: () => strings.inputPaperPlaceholder,
  },
  {
    field: EPrintItemNestedFields.QUANTITIES,
    commentField: EPrintItemCommentFields.QUANTITY_COMMENT,
    label: () => strings.inputQuantityLabel,
    placeholder: () => strings.inputQuantityPlaceholder,
  },
];

export const ARTICLE_STATUS_OPTIONS: TArticleStatusOption[] = Object.values(EArticleStatuses).map(status => ({
  label: ARTICLE_STATUSES_LOCALIZATION[status],
  value: status
}));
export const DEFAULT_ARTICLE_STATUS = ARTICLE_STATUS_OPTIONS[0];

export const DPI_IMAGE_ERROR_THRESHOLD = 150;
export const DPI_IMAGE_WARNING_THRESHOLD = 300;
export const MAX_INPUT_LENGTH_15 = 15;

export const ORIENTATION_TO_STRINGS: {[key in EOrientation]: TLocalizedConstString} = {
  [EOrientation.LANDSCAPE]: () => strings.editorOrientationLandscapeLabel,
  [EOrientation.PORTRAIT]: () => strings.editorOrientationPortraitLabel,
  [EOrientation.SQUARE]: () => strings.editorOrientationSquareLabel,
};

export const SUPPORT_LINK: string = 'https://www.orgafly.com/kontakt/';

export const INTEGRATION_BASE_API_LINKS: {[key in EIntegrationServices]: string} = {
  [EIntegrationServices.EVENTBRITE]: 'https://www.eventbriteapi.com/v3/',
  [EIntegrationServices.PAYLOGIC]: '',
  [EIntegrationServices.CODE_KARUSSEL]: '',
  [EIntegrationServices.MANUAL]: '',
  [EIntegrationServices.API]: '',
};
