import { useMutation } from 'react-query';

import instance from 'lib/apiClient';
import { IResponseError } from 'constants/types';
import { TArticle, TArticleCreateUpdate } from 'types';
import { useInvalidateFindArticles } from './useFindArticles';
import { useInvalidateUserGet, useInvalidateUsersFind } from '../useUser';
import { useInvalidateGetArticle } from './useGetArticle';

const client = instance.getClient();

export const CREATE_ARTICLE_QUERY = ['articles', 'create'];

export const createArticle = (article: TArticleCreateUpdate, pspId?: string): Promise<TArticle> => client
  .post(`/articles?pspId=${pspId}`, article)
  .then(res => res.data);

const useArticleCreate = (pspId?: string) => {
  const invalidateArticle = useInvalidateGetArticle();
  const invalidateArticles = useInvalidateFindArticles();
  const invalidateUsers = useInvalidateUsersFind();
  const invalidateUserGet = useInvalidateUserGet();

  return useMutation<TArticle, IResponseError, TArticleCreateUpdate>(
    [...CREATE_ARTICLE_QUERY],
    data => createArticle(data, pspId),
    {
      onSuccess: () => {
        invalidateArticle();
        invalidateArticles();
        invalidateUsers();
        invalidateUserGet();
      },
    }
  );
};

export default useArticleCreate;
