import * as Yup from 'yup';

import { ITab } from 'components/library/Tabs';
import { EPrintItemTabs } from 'types/Portfolio';
import strings from 'constants/localization';

export const {
  ITEM_INFORMATION,
  OPTIONS,
  PRICINGS,
  OPTIONAL_PRICINGS,
} = EPrintItemTabs;

export const NUMBER_WITH_COMMA_REGEX = /(^[\d]{1,7}[,]{0,1}?[\d]{0,2}?$)|(^$)/;
export const NUMBER_REGEX = /(^[1-9]{1}[\d]{0,6}?$)|(^$)/;
export const NUMBER_TYPING_REGEX = /(^[\d]{1,}[,]{0,1}?[\d]{0,}$)|(^$)/;

export const PRINT_ITEM_FORM_TABS: ITab<EPrintItemTabs>[] = [
  {
    label: () => strings.addPrintItemPageItemInformationTab,
    value: EPrintItemTabs.ITEM_INFORMATION,
  },
  {
    label: () => strings.addPrintItemPageOptionsTab,
    value: EPrintItemTabs.OPTIONS,
  },
  {
    label: () => strings.addPrintItemPagePricingTab,
    value: EPrintItemTabs.PRICINGS,
  },
  {
    label: () => strings.addPrintItemPageOptionalPricingTab,
    value: EPrintItemTabs.OPTIONAL_PRICINGS,
  },
];

export const PrintItemFormSchema = () => Yup.object().shape({
  itemInformation: Yup.object().shape({
    title: Yup.string()
      .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
      .required(strings.inputValidateRequiredField),
    description: Yup.string().max(120, strings.formatString(strings.inputValidateStringLength, 120) as string),
    paperComment: Yup.string().max(50, strings.formatString(strings.inputValidateStringLength, 50) as string),
    quantityComment: Yup.string().max(50, strings.formatString(strings.inputValidateStringLength, 50) as string),
    units: Yup.string()
      .matches(/(mm|cm)/)
      .required(strings.inputValidateRequiredField),
    sizes: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().uuid(),
          name: Yup.string()
            .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
            .required(strings.inputValidateRequiredField),
          width: Yup.string().matches(NUMBER_WITH_COMMA_REGEX, strings.inputValidateNumberFormat).required(strings.inputValidateRequiredField),
          height: Yup.string().matches(NUMBER_WITH_COMMA_REGEX, strings.inputValidateNumberFormat).required(strings.inputValidateRequiredField),
          bleed: Yup.string().matches(NUMBER_WITH_COMMA_REGEX, strings.inputValidateNumberFormat).required(strings.inputValidateRequiredField),
          order: Yup.number().nullable(true),
        }).noUnknown()
      )
      .test(
        'unique-size-name',
        strings.inputValidateUniqueSizeName,
        (data, schemaCtx) => {
          try {
            const sizeIdxWithError: number = data!.map(({ name }) => name?.toLowerCase()).findIndex((name, idx, arr) => arr.indexOf(name) !== idx);
            if (sizeIdxWithError >= 0) {
              return schemaCtx.createError({ path: `${ITEM_INFORMATION}.sizes[${sizeIdxWithError}].name`, message: strings.inputValidateUniqueSizeName });
            }
            return true;
          } catch {
            return false;
          }
        }
      ),
    quantities: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().uuid(),
          value: Yup.string()
            .matches(NUMBER_REGEX)
            .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
            .required(strings.inputValidateRequiredField),
          order: Yup.number().nullable(true),
        }).noUnknown()
      )
      .min(1, strings.inputValidateMinOneQuantity),
    papers: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().uuid(),
          value: Yup.string()
            .max(50, strings.formatString(strings.inputValidateMultiSelectStringsLength, 50) as string)
            .required(strings.inputValidateRequiredField),
          order: Yup.number().nullable(true),
        }).noUnknown()
      )
      .min(1, strings.inputValidateMinOnePaper),
  }),
  options: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().uuid(),
        name: Yup.string()
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required(strings.inputValidateRequiredField),
        comment: Yup.string().max(50, strings.formatString(strings.inputValidateStringLength, 50) as string),
        order: Yup.number().nullable(true),
        values: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().uuid(),
            value: Yup.string()
              .max(50, strings.formatString(strings.inputValidateMultiSelectStringsLength, 50) as string)
              .required(strings.inputValidateRequiredField),
          })
        ).min(1, strings.inputValidateMinOneOptionValue).required()
      }).noUnknown()
    ),
  pricings: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().uuid(),
      price: Yup.string()
        .max(10, strings.formatString(strings.inputValidateStringLength, 10) as string)
        .matches(NUMBER_WITH_COMMA_REGEX, strings.inputValidateNumberFormat)
        .required(strings.inputValidateRequiredField),
      currency: Yup.string().max(255).required(),
      size: Yup.object().shape({
        id: Yup.string().uuid(),
        name: Yup.string()
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required(strings.inputValidateRequiredField),
        width: Yup.string().matches(NUMBER_WITH_COMMA_REGEX).required(strings.inputValidateRequiredField),
        height: Yup.string().matches(NUMBER_WITH_COMMA_REGEX).required(strings.inputValidateRequiredField),
        bleed: Yup.string().matches(NUMBER_WITH_COMMA_REGEX).required(strings.inputValidateRequiredField),
      }).required(),
      paper: Yup.object().shape({
        value: Yup.string()
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required(strings.inputValidateRequiredField),
      }),
      quantity: Yup.object().shape({
        value: Yup.string()
          .matches(NUMBER_REGEX)
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required(strings.inputValidateRequiredField),
      }),
    }).noUnknown()
  ).min(1, strings.inputValidateMinOne),
  optionalPricings: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().uuid(),
      price: Yup.string()
        .max(10, strings.formatString(strings.inputValidateStringLength, 10) as string)
        .matches(NUMBER_WITH_COMMA_REGEX, strings.inputValidateNumberFormat)
        .required(strings.inputValidateRequiredField),
      currency: Yup.string().max(255).required(),
      option: Yup.object().shape({
        value: Yup.string()
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required(),
      }),
      quantity: Yup.object().shape({
        value: Yup.string()
          .matches(NUMBER_REGEX)
          .max(255, strings.formatString(strings.inputValidateStringLength, 255) as string)
          .required()
      }),
    }).noUnknown()
  ),
});

export const PrintItemFormAddOptionSchema = () => Yup.object().shape({
  title: Yup.string()
    .max(50, strings.formatString(strings.inputValidateStringLength, 50) as string)
    .required(strings.inputValidateRequiredField)
});
