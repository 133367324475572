import React from 'react';
import { IIconProps } from './types';

function IconVariableProduct(props: IIconProps) {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 17.1571V18.7C13.5 18.9761 13.7239 19.2 14 19.2H15.5429C15.6755 19.2 15.8027 19.1473
        15.8964 19.0535L22.1464 12.8035C22.3417 12.6082 22.3417 12.2917 22.1464 12.0964L20.6036
        10.5535C20.4083 10.3582 20.0917 10.3582 19.8964 10.5535L13.6464 16.8035C13.5527 16.8973 13.5
        17.0244 13.5 17.1571Z"
        fill="#2C3E66"
      />
      <path
        d="M18.5 8V5.5C18.5 4.39543 17.6046 3.5 16.5 3.5H5.5C4.39543 3.5 3.5 4.39543 3.5 5.5V18.5C3.5
        19.6046 4.39543 20.5 5.5 20.5H11.5"
        stroke="#2C3E66"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 7.5H14.5" stroke="#2C3E66" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 11L11.5 11" stroke="#2C3E66" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default IconVariableProduct;
